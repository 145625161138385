import { useFlagsStatus } from '@unleash/proxy-client-react';

import { UnleashFallbackProvider } from './unleash-fallback.provider';

export const UnleashFallback = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { flagsError } = useFlagsStatus();

  return (
    <UnleashFallbackProvider useFallback={!!flagsError}>
      {children}
    </UnleashFallbackProvider>
  );
};

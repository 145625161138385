import { WarningTwoIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/menu';
import { Icon, IconButton, Portal, Tag } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { FiMoreVertical } from 'react-icons/fi';

import {
  ClientDeliverableOutput,
  DeliveryStatus,
  PipelineType,
  S3Delivery,
} from '@revelio/data-access';

import { getPipelineTypeTitleById } from '../../data-set.model';
import {
  getPipelineDownloadFiles,
  isDeliverableDownloadable,
} from '../../delivery/delivery.model';
import { DuplicateEditMenuItem } from '../duplicate-edit-menu-item';
import { useDeliverablesFromUserOption } from '../use-deliverables-from-user-option';
import { tagStatusColorMap } from '../utils';

export const DeliverableStatusColumn = ({
  value,
  data: tableData,
  deliverables,
}: {
  deliverables: ReturnType<
    typeof useDeliverablesFromUserOption
  >['deliverables'];
  value: string;
  data: {
    id: number;
    name: string | null | undefined;
    created_at: string | null | undefined;
    updated_at: string | null | undefined;
    status: DeliveryStatus;
  };
}) => {
  const deliverable = deliverables.find(
    (clientDl: ClientDeliverableOutput) => clientDl.job_id === tableData.id
  )?.deliverable;

  const createdAt = tableData?.created_at;

  const COMPANY_INFO_DATASET_LIVE_DATE = new Date();
  COMPANY_INFO_DATASET_LIVE_DATE.setFullYear(2023);
  COMPANY_INFO_DATASET_LIVE_DATE.setMonth(12);
  COMPANY_INFO_DATASET_LIVE_DATE.setDate(15);

  const isBeforeCompanyInfoDatasetWentLive =
    !createdAt || new Date(createdAt) < COMPANY_INFO_DATASET_LIVE_DATE;

  const typesToAddCompanyRefDownload: (PipelineType | undefined)[] = [
    PipelineType.WfDynam,
    PipelineType.SkillDynam,
    PipelineType.Posting,
    PipelineType.Transition,
    PipelineType.Individual,
  ];
  const shouldAddCompanyRefDownload =
    isBeforeCompanyInfoDatasetWentLive &&
    !!deliverable?.deliverables.find((del) =>
      typesToAddCompanyRefDownload.includes(del?.pipeline.pipeline_type)
    );

  const tagStatus =
    value === DeliveryStatus.Scheduled ? DeliveryStatus.Running : value;

  return (
    <Flex justifyContent="space-between">
      <Tag
        size="md"
        variant="solid"
        bgColor={tagStatusColorMap[tagStatus]?.bgColor || 'gray.100'}
        color={tagStatusColorMap[tagStatus]?.color || 'gray.600'}
        fontWeight="semibold"
      >
        {tagStatus}
      </Tag>

      <Menu placement="auto">
        {({ isOpen }) => (
          <>
            <MenuButton
              as={IconButton}
              icon={
                <Icon
                  as={FiMoreVertical}
                  data-testid="deliverables_status_menu_button"
                />
              }
              size="xs"
              colorScheme="gray"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                e.stopPropagation()
              }
            >
              Actions
            </MenuButton>
            <Portal>
              <MenuList maxH="400px" overflow="scroll">
                {isDeliverableDownloadable(
                  deliverable?.deliverables[0]?.s3_delivery
                ) && (
                  <MenuGroup title="downloads" fontSize="16px">
                    {value !== 'FINISHED' && value !== 'FAILED' && (
                      <>
                        <Spinner size="xs" mr="5px" ml="10px" /> Generating
                        files...
                      </>
                    )}
                    {value === 'FAILED' && (
                      <Flex alignItems="center">
                        <WarningTwoIcon
                          color="red.400"
                          w={4}
                          h={4}
                          mr="5px"
                          ml="10px"
                        />{' '}
                        Pipeline failed
                      </Flex>
                    )}

                    {deliverable?.deliverables.map((del, i) => {
                      const pipelineType = del?.pipeline
                        .pipeline_type as PipelineType;
                      return (
                        <MenuGroup
                          title={getPipelineTypeTitleById(pipelineType)}
                          key={`${del?.pipeline.pipeline_type}-${i}-menu-group`}
                        >
                          {getPipelineDownloadFiles({
                            pipelineType,
                            s3Delivery: del?.s3_delivery as S3Delivery,
                          }).map((file, j) => (
                            <MenuItem
                              isDisabled={value !== 'FINISHED'}
                              as="a"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              key={`${file.link}-${j}-download-file`}
                              href={file.link}
                              download={`${deliverable?.client}_${deliverable?.name}_${file.label}`}
                            >
                              {file.label}
                            </MenuItem>
                          ))}
                        </MenuGroup>
                      );
                    })}
                    {shouldAddCompanyRefDownload && (
                      <MenuGroup
                        title={getPipelineTypeTitleById(
                          PipelineType.CompanyInfo
                        )}
                      >
                        {getPipelineDownloadFiles({
                          pipelineType: PipelineType.CompanyInfo,
                          s3Delivery: deliverable?.deliverables[0]
                            ?.s3_delivery as S3Delivery,
                        }).map((file, i) => (
                          <MenuItem
                            isDisabled={value !== 'FINISHED'}
                            as="a"
                            key={`${file.link}-${i}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            href={file.link}
                            download={`${deliverable?.client}_${deliverable?.name}_${file.label}`}
                          >
                            {file.label}
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    )}
                  </MenuGroup>
                )}

                {isDeliverableDownloadable(
                  deliverable?.deliverables[0]?.s3_delivery
                ) && <MenuDivider />}

                {isOpen ? (
                  <DuplicateEditMenuItem deliverable={deliverable} />
                ) : null}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </Flex>
  );
};

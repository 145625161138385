const TICK_ANCHOR_OFFSET = 10;

// Text anchor for ticks on x axis
// Prevent text from overflowing at the start or end of the axis
export const tickAnchor = (
  index: number,
  tickCount: number,
  position: number,
  width: number
): 'start' | 'middle' | 'end' => {
  if (index === 0 && position <= TICK_ANCHOR_OFFSET) {
    return 'start';
  }

  if (index === tickCount - 1 && position >= width - TICK_ANCHOR_OFFSET) {
    return 'end';
  }

  return 'middle';
};

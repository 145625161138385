import { Box, HStack, Icon, Link, Spacer } from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { NavLink, useLocation } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RouteNavConfig } from '@revelio/auth';

export interface SubNavDropdownProps {
  item: RouteNavConfig;
  subNavOpenCtlr: BehaviorSubject<boolean>;
  isParentOpen?: boolean;
}

export function SubNavDropdown({
  item: { name = '', children = [] },
  subNavOpenCtlr,
  isParentOpen,
}: SubNavDropdownProps) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isParentOpen) {
      setIsOpen(false);
    }
  }, [isParentOpen]);

  useEffect(() => {
    const pathname =
      location.pathname[0] === '/'
        ? location.pathname.slice(1)
        : location.pathname;

    setIsOpen(children.some((child) => child.path === pathname));
  }, [menuOpen, children, location.pathname, name]);

  useEffect$(
    () =>
      subNavOpenCtlr.asObservable().pipe(
        tap((val) => {
          setMenuOpen(val);
        })
      ),
    [subNavOpenCtlr]
  );

  return (
    <>
      {!!children.length && (
        <Box
          onClick={() => setIsOpen((prev) => !prev)}
          fontSize="md"
          padding="8px 12px"
          marginTop="8px"
          color={'text.primary'}
          borderRadius={'md'}
          width="100%"
          _hover={{ background: 'green.100' }}
        >
          <HStack>
            <div>{name}</div>
            <Spacer />
            <Icon
              as={isOpen ? FiChevronUp : FiChevronDown}
              boxSize={5}
              color="text.primary"
            />{' '}
          </HStack>
        </Box>
      )}
      {isOpen && (
        <>
          {children.map((child, i) => {
            return (
              <Link
                as={NavLink}
                to={child.path as string}
                key={i}
                onClick={() => subNavOpenCtlr.next(false)}
                fontSize="md"
                padding="8px 12px"
                paddingLeft={'24px'}
                marginTop="8px"
                color={'text.primary'}
                borderRadius={'md'}
                width="100%"
                _hover={{ background: 'green.100' }}
              >
                {child.name}
              </Link>
            );
          })}
        </>
      )}
    </>
  );
}

export default SubNavDropdown;

import { authExchange } from '@urql/exchange-auth';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import { Kind, OperationDefinitionNode } from 'graphql';
import { find, get } from 'lodash';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { firstValueFrom } from 'rxjs';
import {
  CombinedError,
  Operation,
  Provider,
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
} from 'urql';

import { fullLogout } from '@revelio/auth';
import { API_READY, setRevelioGqlClient } from '@revelio/core';
import { isNonRetriableError } from '@revelio/data-access';
import { KibanaLogger } from '@revelio/iso-utility';
import {
  UrqlErrorMessage,
  defaultAlertDialogDisclosureControl,
  useKibanaLogger,
} from '@revelio/layout';

import { environment } from '../../environments/environment';
import { recordGraphQL } from '../../hooks/open-replay';
import { useLocation } from 'react-router-dom';

const logGqlError = (
  error: CombinedError,
  operation: Operation,
  kibanaLogger: KibanaLogger
) => {
  const { message, logOptions, isWarningLevel } = kibanaLogger.getUrqlErrorLog(
    error,
    operation
  );

  if (isWarningLevel) {
    console.warn(message);
    kibanaLogger.warn(message, logOptions);
  } else {
    console.error(message);
    kibanaLogger.error(message, logOptions);
  }
};

const skipErrorDialogMessages = [
  /** TODO: This is a temporary solution for talent discovery, when
   * we paginate beyond the total pages, supress the error. Once
   * we return total pages in the backend response, we can add a
   * permanent solution */
  'no user ids',
  'seat limit has been reached',
  '[Network] The user aborted a request.',
];

const displayErrorDialog = (
  error: CombinedError,
  operation: Operation,
  hideErrorDialog: boolean
) => {
  if (
    hideErrorDialog ||
    skipErrorDialogMessages.some((msg) => error.message.includes(msg))
  ) {
    return;
  }

  // Temporary error skipping for data builder column configuration getPipelineConfigs query during backend permission error (for non revelio admins)
  // since it shouldn't be blocking any users from finishing their deliverable configuration
  if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (operation.query.definitions[0] as any).name.value === 'getPipelineConfigs'
  ) {
    return;
  }

  const reqIdSearchResult = /(?:req-id:\s)(.*?)(?=,)/.exec(error.message);
  const reqId = get(reqIdSearchResult, '[1]');

  defaultAlertDialogDisclosureControl.next({
    alertType: 'actionless',
    isOpen: true,
    headerText: 'Sorry!',
    bodyContent: <UrqlErrorMessage error={error} reqId={reqId} />,
  });
};

/** ================================
 * UrqlProvider
 ================================ */
export const UrqlProvider = ({
  children,
  hideErrorDialogs = false,
}: PropsWithChildren<{
  hideErrorDialogs?: boolean;
}>) => {
  const { kibanaLogger } = useKibanaLogger();

  const urqlClient = useMemo(() => {
    return createClient({
      url: `${environment.GO_API_ROOT}/query`,
      fetchOptions: {
        credentials: 'include',
      },
      exchanges: [
        mapExchange({
          onOperation(operation) {
            return {
              ...operation,
              context: {
                ...operation.context,
                _startTime: performance.now(),
              },
            };
          },
          onError(error, operation) {
            if (kibanaLogger) logGqlError(error, operation, kibanaLogger);
            displayErrorDialog(error, operation, hideErrorDialogs);

            const startTime = operation.context._startTime;
            const duration = performance.now() - startTime;

            recordGraphQL(
              operation.kind,
              `${(find(operation.query.definitions, (def) => def.kind === Kind.OPERATION_DEFINITION) as OperationDefinitionNode)?.name?.value || operation.key}`,
              operation.variables,
              { error: error.message },
              duration
            );
          },
          onResult(result) {
            // Only log non-cached results
            if (
              !result.stale &&
              result.operation.context.meta?.cacheOutcome === 'miss' &&
              ['query', 'mutation'].includes(result.operation.kind)
            ) {
              const startTime = result.operation.context._startTime;
              const duration = performance.now() - startTime;

              recordGraphQL(
                result.operation.kind,
                `${
                  (
                    find(result.operation.query.definitions, (def) => {
                      return def.kind === Kind.OPERATION_DEFINITION;
                    }) as OperationDefinitionNode
                  )?.name?.value || result.operation.key
                }`,
                result.operation.variables,
                result.data,
                duration
              );
            }
          },
        }),
        requestPolicyExchange({}),
        cacheExchange,
        authExchange(async ({ appendHeaders }) => {
          await firstValueFrom(API_READY);
          return {
            addAuthToOperation: (operation) => {
              return appendHeaders(operation, {
                'x-request-id': crypto.randomUUID(),
              });
            },
            didAuthError: (error) => {
              const status = error.response?.status;
              if (status === 401 || status === 403) return true;
              else return false;
            },
            refreshAuth: async () => {
              fullLogout();
            },
          };
        }),
        retryExchange({
          initialDelayMs: 1000,
          maxDelayMs: 5000,
          maxNumberAttempts: 2,
          retryIf(error, operation) {
            if (isNonRetriableError(error)) return false;
            else {
              if (kibanaLogger) {
                const { message, logOptions, isWarningLevel } =
                  kibanaLogger.getUrqlErrorLog(error, operation);

                const retryAttempt = (operation.context?.retry?.count ?? 0) + 1;

                const messageWithRetry = `Retrying failed request (Attempt ${retryAttempt})... ${message}`;
                /** Note: Only kibana error logs will alert the error slack channel */
                if (isWarningLevel) {
                  console.warn(messageWithRetry);
                  kibanaLogger.warn(messageWithRetry, logOptions);
                } else {
                  console.error(messageWithRetry);
                  kibanaLogger.error(messageWithRetry, logOptions);
                }
              }

              return true;
            }
          },
        }),
        fetchExchange,
      ],
    });
  }, [kibanaLogger, hideErrorDialogs]);

  useEffect(() => {
    if (urqlClient) setRevelioGqlClient(urqlClient);
  }, [urqlClient]);

  return <Provider value={urqlClient}>{children}</Provider>;
};

/** Route-aware UrqlProvider that handles error dialog visibility based on current route */
export const RouteAwareUrqlProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const hideErrorDialogs = location.pathname === '/health-check';

  return (
    <UrqlProvider hideErrorDialogs={hideErrorDialogs}>{children}</UrqlProvider>
  );
};

import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { MixpanelEvents } from '../lib/logger/analytics';

export const _userEventsEmitter = new Subject<MixpanelEvents>();

export const UserEvents$ = _userEventsEmitter
  .asObservable()
  .pipe(throttleTime(60000));

export const emitMixpanelUserEvent = (event: MixpanelEvents) => {
  _userEventsEmitter.next(event);
};

import { useEffect$ } from '@ngneat/react-rxjs';
import { isEqual } from 'lodash';
import { useState } from 'react';
import {
  Subject,
  debounceTime as _debounceTime,
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs';

interface TypeaheadFetchConfig {
  debounceTime?: number;
  delayTime?: number;
  operatorsBeforeQuery?: any;
  operatorsAfterQuery?: any;
  apiEndpoint?: string;
  isGQLQuery?: boolean;
  fetchResults?: any;
}

export const useTypeaheadFetch = (
  searchInput$: Subject<string>,
  config: TypeaheadFetchConfig
) => {
  const {
    debounceTime = 400,
    delayTime = 200,
    operatorsBeforeQuery = (o: any) => o,
    operatorsAfterQuery,
    fetchResults,
  } = config;

  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect$(
    () =>
      searchInput$.pipe(
        _debounceTime(debounceTime),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        tap((searchString: string) => {
          setSearchString(searchString);
        }),
        operatorsBeforeQuery,
        filter((searchString: string) => {
          return searchString.toString().trim().length > 0;
        }),
        tap(() => {
          setIsLoading(true);
        }),
        switchMap((searchString: string) => {
          return fetchResults(searchString);
        }),
        delay(delayTime),
        tap(() => {
          setIsLoading(false);
        }),
        operatorsAfterQuery,
        catchError((err) => {
          setError(err);
          return err;
        })
      ),
    []
  );

  return { searchString, isLoading, error };
};

import { select } from 'd3';
import { format as d3Format } from 'd3-format';
import { has } from 'lodash';

import { getCornerRadius, roundedRect } from '../../utilities/corner-radius.js';
import { generateTextWidth } from '../../utilities/text-width.js';

export const transformAndMapData = (data, useWidth = false) => {
  const dataTransformed = [];
  const valueMapping = {};
  data.forEach((d) => {
    // transform data to use stack generator
    const newData = {};
    newData.group_shortName = d.metadata.shortName;
    newData.group_longName = d.metadata.longName;
    d.value.forEach((dVal) => {
      const useWidth = has(dVal, 'width');
      // TODO: check for existance of normalized share field instead of relying on prop
      newData[dVal.metadata.shortName] = useWidth
        ? dVal.width // TODO: rename width var (normalized share)
        : dVal.value * 100;
    });

    dataTransformed.push(newData);
    // also create a mapping of value shortName (to use in legend) to value longName (to use in tooltip)
    d.value.forEach(
      (val) =>
        (valueMapping[`${val.metadata.shortName}`] = val.metadata.longName)
    );
  });

  return [valueMapping, dataTransformed];
};

export const calcMaxLeftLabelWidth = (
  dataTransformed,
  fontSize,
  fontFamily
) => {
  // reduce left margin if the longest y axis label is shorter than the current left margin
  let maxTextWidth = 0;
  let textWidth;

  dataTransformed.forEach((d) => {
    textWidth = generateTextWidth(
      d.group_shortName,
      `${fontSize}px ${fontFamily}`
    );
    if (textWidth > maxTextWidth) maxTextWidth = textWidth;
  });

  return maxTextWidth;
};

export const getYAxisRange = (dataLength, maxStep, chartHeightStyle) => {
  const chartHeight = dataLength * maxStep;

  let yAxisRange;

  if (chartHeight < chartHeightStyle) {
    yAxisRange = [
      chartHeightStyle / 2 - chartHeight / 2,
      chartHeightStyle / 2 + chartHeight / 2,
    ];
  } else {
    yAxisRange = [0, chartHeightStyle];
  }

  return yAxisRange;
};

export const getComputedHeight = ({
  compStyles,
  hideLegend,
  marginTop,
  marginBottom,
  legendHeight,
}) => {
  const computedInnerHeight =
    parseFloat(compStyles.getPropertyValue('height')) -
    parseFloat(compStyles.getPropertyValue('padding-top')) -
    parseFloat(compStyles.getPropertyValue('padding-bottom'));

  const spaceAvailableForChart =
    computedInnerHeight -
    (hideLegend ? marginBottom : legendHeight) -
    marginTop;

  return spaceAvailableForChart;
};
export const createTooltip = ({ container, tooltipWidth, name }) => {
  const tt = select(container)
    .append('div')
    .style('width', tooltipWidth + 'px')
    .style('opacity', 0)
    .style('pointer-events', 'none')
    .attr('hidden', true)
    .classed(`tooltip-${name}`, true)
    .classed('tooltip-stacked-bar-chart', true);

  return tt;
};

export const getTooltipData = ({
  data,
  d,
  ttMainFormat,
  ttSecondaryFormat,
}) => {
  const tooltipData = [];

  data.forEach((company) => {
    const valueFiltered = company.value
      .filter((row) => row.metadata.shortName === d.key)
      .map((row) => row.value);
    const countFiltered = company.value
      .filter((row) => row.metadata.shortName === d.key)
      .map((row) => row.metadata.count);
    tooltipData.push([
      company.metadata.longName,
      d3Format(ttMainFormat)(valueFiltered),
      d3Format(ttSecondaryFormat)(Math.round(countFiltered)),
    ]);
  });

  return tooltipData;
};

export const calcTTHeight = ({
  dataLength,
  d,
  valueMapping,
  ttUpperPadding,
  ttTitleLineHeight,
  charsPerTitleLine,
  titleBottomPadding,
  ttRowHeight,
  ttArrowLength,
}) => {
  return (
    ttUpperPadding +
    ttTitleLineHeight *
      Math.ceil(valueMapping[d.key].length / charsPerTitleLine) +
    titleBottomPadding +
    dataLength * ttRowHeight +
    ttUpperPadding +
    ttArrowLength
  );
};

export const calcTTTransformOnMouseOver = ({
  d,
  chartPosition,
  dims,
  xScale,
  yScale,
  tooltipHeight,
  tooltipWidth,
  hideLegend,
  BBox,
  nullGroups,
}) => {
  const isNullGroupMember = nullGroups.has(d.data.group_shortName);

  const xOffset = isNullGroupMember
    ? BBox?.width / 2
    : xScale(d[0] + (d[1] - d[0]) / 2);

  return chartPosition === 'right' && d[1] > 75 && d[1] - d[0] < 46
    ? `translate(${
        dims.margin.left -
        7 + // tt arrow length
        xScale(d[0]) -
        tooltipWidth
      }px, ${
        dims.margin.top +
        yScale(d.data.group_shortName) -
        tooltipHeight / 2 +
        yScale.bandwidth() / 2
      }px)`
    : `translate(${dims.margin.left + xOffset - tooltipWidth / 2}px, ${
        // TODO: there's a magic number here. Want to investigate how to refactor without it.
        (hideLegend ? dims.margin.top - 6 : 25) +
        yScale(d.data.group_shortName) -
        tooltipHeight
      }px)`;
};

const getLegendDimensions = (height) => {
  return {
    blockHeight: 8,
    blockWidth: 8,
    textPadLeft: 5,
    textPadRight: 15,
    rowGap: height >= 350 ? 25 : 15,
    fontSize: height >= 350 ? 12 : 10,
    fontFamily: 'Source Sans Pro, sans-serif',
  };
};

export const drawSVGColorBlocks = ({
  stackedData,
  width,
  legendContainer,
  legendDimensions,
  color,
  otherColor,
  dims,
}) => {
  // for placement of legend colour boxes along x-axis
  let currentXOffset = 0;
  let currentYOffset = 0;

  legendContainer
    .selectAll('legend-color-block')
    .data(stackedData)
    .enter()
    .append('rect')
    .attr('transform', (d, i) => {
      const labelWidth = Math.ceil(
        generateTextWidth(
          d.key,
          `${legendDimensions.fontSize}px ${legendDimensions.fontFamily}`
        )
      );

      const itemWidth =
        legendDimensions.blockWidth +
        legendDimensions.textPadLeft +
        legendDimensions.textPadRight +
        labelWidth;

      let xOffset, yOffset;

      // check if adding current item will hit the bounds
      if (currentXOffset + itemWidth >= dims.innerWidth) {
        // wrap to next row
        currentYOffset += legendDimensions.rowGap;
        currentXOffset = 0;
      }

      xOffset = currentXOffset;
      yOffset = currentYOffset;

      currentXOffset += itemWidth;

      return `translate(${xOffset}, ${yOffset})`;
    })
    .attr('height', legendDimensions.blockHeight)
    .attr('width', legendDimensions.blockWidth)
    .style('fill', (d) => (d.key === 'Other' ? otherColor : color(d.key)))
    .style('rx', '2px')
    .style('ry', '2px');
};

const drawSVGLegendLabels = ({
  width,
  stackedData,
  legendContainer,
  legendDimensions,
  fontColor,
  dims,
  formatter = (str) => str,
}) => {
  let currentLegendTextXOffset =
    legendDimensions.blockWidth + legendDimensions.textPadLeft;

  let currentLegendTextYOffset = legendDimensions.blockHeight / 2;

  legendContainer
    .selectAll('legend-label')
    .data(stackedData)
    .enter()
    .append('text')
    .attr('transform', function (d, i) {
      const labelWidth = Math.ceil(
        generateTextWidth(
          d.key,
          `${legendDimensions.fontSize}px ${legendDimensions.fontFamily}`
        )
      );

      const itemWidth =
        legendDimensions.blockWidth +
        legendDimensions.textPadLeft +
        legendDimensions.textPadRight +
        labelWidth;

      // const itemHeight = this.getBBox().height;

      let xOffset, yOffset;

      // check if adding current item will hit the x bounds
      if (
        currentLegendTextXOffset + labelWidth + legendDimensions.textPadRight >=
        dims.innerWidth
      ) {
        // wrap to next row
        currentLegendTextYOffset += legendDimensions.rowGap;
        currentLegendTextXOffset =
          legendDimensions.blockWidth + legendDimensions.textPadLeft;
      }

      xOffset = currentLegendTextXOffset;
      yOffset = currentLegendTextYOffset;

      currentLegendTextXOffset += itemWidth;

      return `translate(${xOffset}, ${yOffset})`;
    })
    .style('fill', fontColor)
    .style('font-size', legendDimensions.fontSize)
    .style('font-family', legendDimensions.fontFamily)
    .attr('height', legendDimensions.blockHeight)
    .attr('width', legendDimensions.blockWidth)
    .style('alignment-baseline', 'middle')
    .attr('text-anchor', 'left')
    .text((d) => {
      return formatter(d.key);
    });
};

export const calcBarsHeight = ({ data, yScale, innerPad }) => {
  return (
    yScale.bandwidth() * data.length +
    yScale.step() * innerPad * (data.length - 1) -
    2
  );
};

export const positionSVGContainer = ({
  legendContainer,
  barsBackground,
  height,
}) => {
  legendContainer.attr('transform', function (d, i) {
    const legendPadTop = getLegendDimensions(height).rowGap;

    const barsRect = barsBackground.node().getBBox();

    let yOffset = barsRect.y + barsRect.height + legendPadTop;

    return `translate(0, ${yOffset})`;
  });
};

export const generateSVGLegend = ({
  chart,
  width,
  height,
  dims,
  stackedData,
  color,
  otherColor,
  fontColor,
  formatter = (str) => str,
}) => {
  const legendDimensions = getLegendDimensions(height);
  const legendPadTop = legendDimensions.rowGap;
  const legendContainer = chart.append('g');

  drawSVGColorBlocks({
    dims,
    stackedData,
    width,
    legendPadTop,
    legendContainer,
    legendDimensions,
    color,
    otherColor,
  });

  drawSVGLegendLabels({
    dims,
    height,
    width,
    stackedData,
    legendPadTop,
    legendContainer,
    legendDimensions,
    fontColor,
    formatter,
  });

  return legendContainer;
};

export const drawStackedBars = ({
  stackedData,
  nullGroups,
  innerWidth,
  barsBackground,
  xScale,
  yScale,
  mouseOver,
  mouseOut,
  otherColor,
  color,
}) => {
  const nullGroupsDrawn = [];

  barsBackground
    .selectAll('g')
    // Enter in the stack data = loop key per key = group per group
    .data(stackedData)
    .join('g')
    .attr('data-testid', 'stacked-horizontal-bar-chart-vertical-group')
    .attr('fill', (d) => {
      return d.key === 'Other' ? otherColor : color(d.key);
    })
    .selectAll('path')
    .data((d) => d)
    .join('path')
    .attr('class', 'rect')
    .attr('data-testid', (d) => {
      const isNullGroupMember = nullGroups.has(d.data.group_shortName);
      return isNullGroupMember
        ? 'stacked-horizontal-bar-chart-bar-empty'
        : 'stacked-horizontal-bar-chart-bar';
    })
    .attr('stroke', 'white')
    .attr('stroke-width', '2px')
    // for small values (<.03, where 2px stroke makes bar disappear) draw over the bar strokes so it becomes filled in
    .attr('stroke-dasharray', (d) =>
      xScale(d[1]) - xScale(d[0]) < 3
        ? [xScale(d[1]) - xScale(d[0]), yScale.bandwidth()]
        : [0]
    )
    .attr('cursor', (d) => {
      const isNullGroupMember = nullGroups.has(d.data.group_shortName);
      return isNullGroupMember ? 'not-allowed' : 'pointer';
    })
    .attr('fill', (d) => {
      const isNullGroupMember = nullGroups.has(d.data.group_shortName);
      if (isNullGroupMember) {
        return 'url(#diagonal-stripe-4) #fff';
      }
    })
    .attr('d', (d) => {
      const groupShortName = d.data.group_shortName;

      const isNullGroupMember = nullGroups.has(groupShortName);

      let width =
        !isNaN(d[0]) && !isNaN(d[1]) ? xScale(d[1]) - xScale(d[0]) : 0;

      if (isNullGroupMember) {
        if (nullGroupsDrawn.includes(groupShortName)) {
          return;
        }

        width = innerWidth;
        nullGroupsDrawn.push(groupShortName);
      }

      return roundedRect(
        xScale(d[0]),
        yScale(d.data.group_shortName),
        width,
        yScale.bandwidth(),
        getCornerRadius(d)
      );
    })
    .on('mouseover', (e, d) => {
      const isNullGroupMember = nullGroups.has(d.data.group_shortName);

      if (!isNullGroupMember) {
        return mouseOver(e, d);
      }
    })
    .on('mouseout', (e, d) => {
      const isNullGroupMember = nullGroups.has(d.data.group_shortName);

      if (!isNullGroupMember) {
        return mouseOut(e, d);
      }
    });
};

export const styleTextboxes = ({
  textBoxes,
  dims,
  yScale,
  name,
  fontSize,
  lineHeight,
  download,
  fontColor,
}) => {
  //text using divs to get text-overflow: ellipsis

  textBoxes
    .append('foreignObject')
    .attr('width', dims.margin.left)
    .attr('height', yScale.bandwidth())
    .attr('transform', (d) => {
      return `translate(${-dims.margin.left}, ${yScale(d.metadata.shortName)})`;
    })
    .append('xhtml:div')
    .style('display', 'flex')
    .style('align-items', 'center')
    .style('height', '100%')
    .style('width', '100%')
    .append('text')
    .classed(`inline-style-target-${name}`, !!download)
    .classed('stacked-bar-y-labels', true)
    .attr('data-testid', 'stacked-horizontal-bar-chart-y-axis-label')
    .text((d) => {
      return d.metadata.shortName;
    })
    .style('font-size', `${fontSize}px`)
    .style('line-height', `${lineHeight + 2}px`)
    .style('font-family', 'Source Sans Pro, Roboto, sans-serif')
    .style('color', fontColor)
    .style('text-align', 'right')
    .style('justify-content', 'flex-end')
    // needed if wrapping text, to float to right:
    .style('float', 'right')
    .style('width', '100%')
    .style('padding-right', '5px')
    .style('text-overflow', 'ellipsis')
    .style('display', '-webkit-box')
    .style('-webkit-line-clamp', '2')
    .style('line-clamp', '2')
    .style('-webkit-box-orient', 'vertical');
};

export const appendYAxis = ({ chart, fontColor, yAxisLeft }) => {
  chart
    .append('g')
    .attr('data-testid', 'y-axis')
    .attr('transform', `translate(5, 0)`)
    .style('color', fontColor) //'#818ea6')
    .call(yAxisLeft)
    .call((g) => g.selectAll('.domain').remove())
    .call((g) => g.selectAll('line').remove());
};

import { useCallback, useEffect, useRef } from 'react';

import { useKibanaLogger } from '../components/kibana-logger/kibana-logger';

const LONG_PAGE_LOAD_THRESHOLD = 10_000; // ms

type PageLoadEndReason = 'loaded' | 'unloaded' | 'unmounted';

interface PageLoadMark {
  start: number;
  timestamp: string;
}

interface PageLoadPerformance {
  activeDuration: number;
  totalDuration: number;
  backgroundDuration: number;
  visibilityChanges?: Partial<PerformanceEntry>[];
}

export function usePageLoadMonitor(pageName: string, loading?: boolean) {
  const { kibanaLogger } = useKibanaLogger();
  const pageLoad = useRef<PageLoadMark | null>(null);
  const visibilityEntries = useRef<PerformanceEntry[]>([]);

  const handleLoadingStart = () => {
    const timestamp = new Date().toISOString();
    pageLoad.current = {
      start: performance.now(),
      timestamp,
    };
    visibilityEntries.current = [];
  };

  const handleLoadingEnd = useCallback(
    (endReason: PageLoadEndReason): void => {
      if (!pageLoad.current) return;

      const data = collectPerformanceData(
        pageLoad.current.start,
        visibilityEntries.current
      );

      if (data.activeDuration > LONG_PAGE_LOAD_THRESHOLD) {
        const logData = createLogData(
          pageName,
          endReason,
          pageLoad.current,
          data
        );

        kibanaLogger?.log(
          `${pageName} page load exceeded ${LONG_PAGE_LOAD_THRESHOLD / 1000}s threshold`,
          { page_load: logData }
        );

        if (process.env['NODE_ENV'] === 'development') {
          console.log(
            `[PageLoadMonitor] '${pageName}' took ${(data.activeDuration / 1000).toFixed(2)}s`,
            logData
          );
        }
      }

      pageLoad.current = null;
    },
    [kibanaLogger, pageName]
  );

  // Start new loading
  if (loading && !pageLoad.current) {
    handleLoadingStart();
  }
  // End loading normally
  else if (!loading && pageLoad.current) {
    handleLoadingEnd('loaded');
  }

  // Handle unload and unmount
  useEffect(() => {
    const onBeforeUnload = () => {
      if (pageLoad.current) {
        handleLoadingEnd('unloaded');
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      if (pageLoad.current) {
        window.removeEventListener('beforeunload', onBeforeUnload);
        handleLoadingEnd('unmounted');
      }
    };
  }, [handleLoadingEnd]);

  // Track visibility changes
  useEffect(() => {
    if (!window.PerformanceObserver) return;

    const observer = new PerformanceObserver((list) => {
      const newEntries = list.getEntries();
      newEntries.forEach((entry) => {
        if (entry.entryType === 'visibility-state') {
          visibilityEntries.current.push(entry);
        }
      });
    });

    try {
      observer.observe({
        entryTypes: ['visibility-state'],
      });
    } catch (error) {
      console.info('Browser does not support performance observer');
    }

    return () => observer.disconnect();
  }, []);
}

const createLogData = (
  pageName: string,
  endReason: PageLoadEndReason,
  pageLoad: PageLoadMark,
  data: PageLoadPerformance
) => ({
  page: pageName,
  completed: endReason === 'loaded',
  reason: endReason,
  timestamp: pageLoad.timestamp,
  start: Math.round(pageLoad.start),
  duration_ms: data.activeDuration,
  // Only include if there are visibility changes
  ...(data.visibilityChanges?.length
    ? {
        total_ms: data.totalDuration,
        background_ms: data.backgroundDuration,
        visibility_changes: data.visibilityChanges.map((entry) => ({
          name: entry.name,
          start_time: Math.round(entry.startTime ?? 0),
        })),
      }
    : {}),
});

const getBackgroundDuration = (
  start: number,
  end: number,
  visibilityEntries: PerformanceEntry[]
): number => {
  const changes = visibilityEntries.filter(
    (entry) => entry.startTime >= start && entry.startTime <= end
  );

  return changes.reduce((total, entry, index) => {
    if (entry.name === 'hidden') {
      const nextVisible = changes.find(
        (e, i) => i > index && e.name === 'visible'
      );
      if (nextVisible) {
        return total + (nextVisible.startTime - entry.startTime);
      }
    }
    return total;
  }, 0);
};

const collectPerformanceData = (
  start: number,
  visibilityEntries: PerformanceEntry[]
): PageLoadPerformance => {
  const end = performance.now();
  const totalDuration = end - start;
  const backgroundDuration = getBackgroundDuration(
    start,
    end,
    visibilityEntries
  );

  return {
    activeDuration: Math.round(totalDuration - backgroundDuration),
    totalDuration: Math.round(totalDuration),
    backgroundDuration: Math.round(backgroundDuration),
    visibilityChanges: visibilityEntries,
  };
};

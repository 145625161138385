import { z } from 'zod';

import { GetTalentDiscoveryMapStatsQueryVariables } from '../../gql/graphql';

export const TALENT_DISCOVERY_FIELDS = [
  'company',
  'country',
  'msa',
  'gender',
  'ethnicity',
  'seniority',
  'highest_degree',
  'flight_risk',
  'prestige',
  'remote_suitability',
  'job_category',
  'role_k150',
  'role_k1500',
] as const;
export type TalentDiscoveryField = (typeof TALENT_DISCOVERY_FIELDS)[number];

export type GetTalentDiscoveryTooltipPayload =
  GetTalentDiscoveryMapStatsQueryVariables;

export interface TalentDiscoveryTooltipData {
  growth: string | number;
  marketTightness: string | number;
  postings: number;
  salaryDist: number[];
  timeToFill: string | number;
  headcount: number;
}

export type TalentDiscoveryMutateResponse =
  | { success: true; message: string }
  | { success: false; error: string };

// Basic filter schemas
export const talentDiscoveryAiSearchFilter = z.object({
  name: z.string(),
  relevantPromptText: z.string(),
});

export const talentDiscoveryAiSearchPreviousCurrentFilter =
  talentDiscoveryAiSearchFilter.extend({
    isCurrent: z.boolean(),
  });

export const talentDiscoveryAiSearchRangeFilter = z.object({
  start_value: z.number().nullable(),
  end_value: z.number().nullable(),
  relevantPromptText: z.string(),
  isCurrent: z.boolean(),
});

export const talentDiscoveryAiSearchUnknownFilter =
  talentDiscoveryAiSearchFilter.extend({
    explanation: z.string(),
  });

// Predefined values
export const talentDiscoveryAiSearchEducationValues = z.enum([
  'High School',
  'Associate',
  'Bachelor',
  'MBA',
  'Master',
  'Doctor',
]);

export const talentDiscoveryAiSearchEthnicityValues = z.enum([
  'White',
  'Asian',
  'Hispanic',
  'Black',
  'Native',
  'Multiple',
]);

export const talentDiscoveryAiSearchRiskPrestigeSuitabilityValues = z.enum([
  'Very Low',
  'Low',
  'Medium',
  'High',
  'Very High',
]);

export const talentDiscoveryAiSearchGenderValues = z.enum(['Male', 'Female']);

export const talentDiscoveryAiSearchSeniorityValues = z.enum([
  'Entry Level',
  'Junior',
  'Associate',
  'Manager',
  'Director',
  'Executive',
  'Senior Executive',
]);

// Filter schemas with predefined values
export const talentDiscoveryAiSearchEducationFilter =
  talentDiscoveryAiSearchFilter.extend({
    name: talentDiscoveryAiSearchEducationValues,
  });

export const talentDiscoveryAiSearchEthnicityFilter =
  talentDiscoveryAiSearchFilter.extend({
    name: talentDiscoveryAiSearchEthnicityValues,
  });

export const talentDiscoveryAiSearchRiskPrestigeSuitabilityFilter =
  talentDiscoveryAiSearchPreviousCurrentFilter.extend({
    name: talentDiscoveryAiSearchRiskPrestigeSuitabilityValues,
  });

export const talentDiscoveryAiSearchGenderFilter =
  talentDiscoveryAiSearchFilter.extend({
    name: talentDiscoveryAiSearchGenderValues,
  });

export const talentDiscoveryAiSearchSeniorityFilter =
  talentDiscoveryAiSearchPreviousCurrentFilter.extend({
    name: talentDiscoveryAiSearchSeniorityValues,
  });

// Main filter schema
export const talentDiscoveryAiSearchFilters = z
  .object({
    company: z.array(talentDiscoveryAiSearchPreviousCurrentFilter).nullable(),
    industry: z.array(talentDiscoveryAiSearchPreviousCurrentFilter).nullable(),
    highest_degree: z.array(talentDiscoveryAiSearchEducationFilter).nullable(),
    ethnicity: z.array(talentDiscoveryAiSearchEthnicityFilter).nullable(),
    flight_risk: z
      .array(talentDiscoveryAiSearchRiskPrestigeSuitabilityFilter)
      .nullable(),
    gender: z.array(talentDiscoveryAiSearchGenderFilter).nullable(),
    geography: z.array(talentDiscoveryAiSearchPreviousCurrentFilter).nullable(),
    name: talentDiscoveryAiSearchFilter.nullable(),
    prestige: z
      .array(talentDiscoveryAiSearchRiskPrestigeSuitabilityFilter)
      .nullable(),
    remote_suitability: z
      .array(talentDiscoveryAiSearchRiskPrestigeSuitabilityFilter)
      .nullable(),
    role: z.array(talentDiscoveryAiSearchPreviousCurrentFilter).nullable(),
    school: z.array(talentDiscoveryAiSearchFilter).nullable(),
    seniority: z.array(talentDiscoveryAiSearchSeniorityFilter).nullable(),
    skill: z.array(z.array(talentDiscoveryAiSearchFilter)).nullable(),
    keywords: z.array(z.array(talentDiscoveryAiSearchFilter)).nullable(),
    salary_total: talentDiscoveryAiSearchRangeFilter.nullable(),
    // titles: z.array(z.array(talentDiscoveryAiSearchFilter)).optional(),
    graduation_year: talentDiscoveryAiSearchRangeFilter.nullable(),
  })
  .required();

// Main response schema
export const talentDiscoveryAiSearchResponseSchema = z.object({
  filters: talentDiscoveryAiSearchFilters,
  unknownFilters: z.array(talentDiscoveryAiSearchUnknownFilter),
});

// Type inference
export type TalentDiscoveryAiSearchFilterType = z.infer<
  typeof talentDiscoveryAiSearchFilter
>;
export type TalentDiscoveryAiSearchPreviousCurrentFilterType = z.infer<
  typeof talentDiscoveryAiSearchPreviousCurrentFilter
>;
export type TalentDiscoveryAiSearchFiltersType = z.infer<
  typeof talentDiscoveryAiSearchFilters
>;
export type TalentDiscoveryAiFilterSearchResponse = z.infer<
  typeof talentDiscoveryAiSearchResponseSchema
>;

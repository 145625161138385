import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { format as d3Format } from 'd3';
import { ReactNode } from 'react';

import { CommonPlotProps } from '../../types';
import { getFormatter } from '../../utilities';
import { AreaChart, AreaData } from '../area/area-chart';
import styles from './stat-trend-chart.module.css';

type StatTrendChartProps = {
  title: string;
  info?: ReactNode;
  data: AreaData | null;
} & CommonPlotProps;

export const StatTrendChart = ({
  title,
  info,
  data,
  format,
  renderUpdate,
}: StatTrendChartProps) => {
  const lastValue = data?.values[data?.values.length - 1]?.value ?? 0;
  const firstValue = data?.values[0]?.value ?? 0;
  const delta = lastValue - firstValue;
  const deltaPercentage = delta / Math.abs(firstValue);

  const formatValue = format ? getFormatter(format) : d3Format('.3s');
  const formatDeltaPercentage = d3Format('+.1%');

  const isNegative = deltaPercentage < 0;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.info}>
          <Popover trigger="hover" placement="top" variant="tooltip">
            <PopoverTrigger>
              <InfoOutlineIcon boxSize={'10px'} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>{info}</PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.value}>{formatValue(lastValue)}</div>
          <div
            className={classNames(styles.delta, {
              [styles.positive]: !isNegative,
              [styles.negative]: isNegative,
            })}
          >
            {formatDeltaPercentage(deltaPercentage)}
          </div>
        </div>
        <div className={styles.plotContainer}>
          <AreaChart
            data={data ? [data] : []}
            colors={['#5BD992']}
            showXAxis={false}
            showYAxis={false}
            format={format}
            showNoData={false}
            renderUpdate={renderUpdate}
          />
        </div>
      </div>
    </div>
  );
};

import {
  Container,
  Heading,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Box,
  Switch,
  Select,
} from '@chakra-ui/react';
import {
  useEffect,
  useState,
  useMemo,
  useTransition,
  useCallback,
  useRef,
} from 'react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import DashboardPage from '../DashboardPage';
import { HealthCheckAccordion } from './HealthCheckAccordion';
import { useHealthCheckApi } from './hooks/useHealthCheckApi';
import { HEALTH_CHECKS, getHealthCheckId } from '@revelio/data-access';
import { HealthCheckDetails } from './components/HealthCheckDetails';
import { OverallHealthMetrics } from './components/OverallHealthMetrics';
import { GroupingType } from './types';
import { SchemaProvider } from './context/SchemaContext';

export type OutageStatus = 'operational' | 'partial_outage' | 'major_outage';

export const HealthCheck = () => {
  const {
    requests,
    isFetching,
    runHealthChecks,
    runSingleHealthCheck,
    fetchHistory,
  } = useHealthCheckApi();

  const [searchQuery, setSearchQuery] = useState('');
  const [showFailedOnly, setShowFailedOnly] = useState(false);
  const [groupBy, setGroupBy] = useState<GroupingType>('viewGroup');
  const [isPending, startTransition] = useTransition();
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const debounceTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchQuery(value); // Update the input value immediately for UI responsiveness

      // Clear any existing timeout
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      // Set a new timeout for the actual filtering
      debounceTimeout.current = setTimeout(() => {
        startTransition(() => {
          setDebouncedSearchQuery(value);
        });
      }, 300);
    },
    [startTransition]
  );

  const clearSearch = useCallback(() => {
    setSearchQuery(''); // Immediate UI update
    startTransition(() => {
      setDebouncedSearchQuery('');
    });
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  }, [startTransition]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const filteredResults = useMemo(() => {
    if (!debouncedSearchQuery && !showFailedOnly) return null;

    const searchLower = debouncedSearchQuery.toLowerCase();
    const uniqueChecks = new Map<
      string,
      {
        check: (typeof HEALTH_CHECKS)[0];
        latestResult: (typeof requests)[0];
        serviceResults: typeof requests;
      }
    >();

    // Pre-process requests to get latest results for each check
    const latestResults = new Map<string, (typeof requests)[0]>();
    requests.forEach((request) => {
      const checkId = getHealthCheckId(
        request.service,
        request.viewGroup,
        request.view
      );
      const existing = latestResults.get(checkId);
      if (
        !existing ||
        new Date(request.timestamp) > new Date(existing.timestamp)
      ) {
        latestResults.set(checkId, request);
      }
    });

    // Filter based on search and failed status
    HEALTH_CHECKS.forEach((check) => {
      const checkId = getHealthCheckId(
        check.service,
        check.viewGroup,
        check.view
      );
      const latestResult = latestResults.get(checkId);

      if (!latestResult) return;

      const matchesSearch =
        !searchLower ||
        check.service.toLowerCase().includes(searchLower) ||
        check.viewGroup.toLowerCase().includes(searchLower) ||
        check.view.toLowerCase().includes(searchLower);

      const matchesFailedFilter =
        !showFailedOnly || latestResult.status === 'failure';

      if (matchesSearch && matchesFailedFilter) {
        const checkResults = requests.filter(
          (r) =>
            r.service === check.service &&
            r.viewGroup === check.viewGroup &&
            r.view === check.view
        );

        uniqueChecks.set(checkId, {
          check,
          latestResult,
          serviceResults: checkResults,
        });
      }
    });

    return Array.from(uniqueChecks.values());
  }, [debouncedSearchQuery, requests, showFailedOnly]);

  const renderResults = useCallback(() => {
    if (debouncedSearchQuery || showFailedOnly) {
      if (!filteredResults?.length) {
        return (
          <Box textAlign="center" py={8}>
            <Text fontSize="lg" color="gray.500">
              No results found{' '}
              {debouncedSearchQuery ? `for "${debouncedSearchQuery}"` : ''}
              {showFailedOnly ? ' with failed status' : ''}
            </Text>
          </Box>
        );
      }

      return (
        <Box p={4}>
          <Text mb={4} fontSize="md" color="gray.600">
            Found {filteredResults.length} unique matches
            {debouncedSearchQuery ? ` for "${debouncedSearchQuery}"` : ''}
            {showFailedOnly ? ' with failed status' : ''}
          </Text>
          {filteredResults.map(({ check, latestResult, serviceResults }) => (
            <Box
              key={getHealthCheckId(check.service, check.viewGroup, check.view)}
              mb={3}
            >
              <SchemaProvider>
                <HealthCheckDetails
                  check={check}
                  latestResult={latestResult}
                  serviceResults={serviceResults}
                  onRunSingleCheck={runSingleHealthCheck}
                  isFetching={isFetching}
                  groupingType={groupBy}
                />
              </SchemaProvider>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Box>
        <HealthCheckAccordion
          results={requests}
          onRunSingleCheck={runSingleHealthCheck}
          isFetching={isFetching}
          groupingType={groupBy}
        />
      </Box>
    );
  }, [
    debouncedSearchQuery,
    showFailedOnly,
    filteredResults,
    runSingleHealthCheck,
    requests,
    groupBy,
    isFetching,
  ]);

  return (
    <DashboardPage title={'Health Check'} loading={false} scrollY>
      <Container maxW="container.lg" py={8}>
        <HStack justify="space-between" mb={8}>
          <Heading>System Health Status</Heading>
          <Button
            onClick={runHealthChecks}
            isLoading={isFetching}
            colorScheme="blue"
            size="md"
          >
            Run All Health Checks
          </Button>
        </HStack>

        <OverallHealthMetrics results={requests} isLoading={isFetching} />

        <Box
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
          bg="gray.50"
        >
          <Box p={4} borderBottom="1px solid" borderColor="gray.200">
            <HStack spacing={4} align="center" wrap="wrap">
              <InputGroup flex={1} minW="300px">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search by service, view group, or view..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  bg="white"
                />
                {searchQuery && (
                  <InputRightElement>
                    <CloseIcon
                      w={3}
                      h={3}
                      color="gray.500"
                      cursor="pointer"
                      onClick={clearSearch}
                      _hover={{ color: 'gray.700' }}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
              <HStack spacing={6} alignItems="center">
                <HStack spacing={2} alignItems="center">
                  <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    Failed Checks Only
                  </Text>
                  <Switch
                    id="failed-only"
                    isChecked={showFailedOnly}
                    onChange={(e) => {
                      startTransition(() => {
                        setShowFailedOnly(e.target.checked);
                      });
                    }}
                    colorScheme="red"
                    size="md"
                  />
                </HStack>
                <HStack spacing={2} alignItems="center">
                  <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    Group by:
                  </Text>
                  <HStack>
                    <Select
                      size="md"
                      width="160px"
                      value={groupBy}
                      onChange={(e) => {
                        startTransition(() => {
                          setGroupBy(e.target.value as GroupingType);
                        });
                      }}
                      variant="filled"
                      bg="white"
                      _hover={{ bg: 'gray.50' }}
                      isDisabled={isPending}
                    >
                      <option value="viewGroup">View Group</option>
                      <option value="service">Service</option>
                      <option value="page">Page</option>
                    </Select>
                  </HStack>
                </HStack>
              </HStack>
            </HStack>
          </Box>

          {renderResults()}
        </Box>
      </Container>
    </DashboardPage>
  );
};

import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { ProjectEntryRaw } from '../resumeTypes';

interface ProjectRawProps {
  data?: ProjectEntryRaw;
  variant?: string;
}

export default function ProjectRaw({ data, variant }: ProjectRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>
        {data?.start_date} - {data?.end_date}
      </Text>
      <Text sx={styles['sectionTitle']}>{data?.project_name}</Text>
      <Text sx={styles['sectionDetails']} mt="5px">
        {data?.description}
      </Text>
      {!!data?.description_bullet.length && (
        <ul style={{ paddingLeft: '15px' }}>
          {data?.description_bullet.map((bullet, index) => (
            <li key={`project-bullet-${index}`}>
              <Text sx={styles['sectionDetails']}>{bullet}</Text>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
}

import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { flatten, isEqual } from 'lodash';

import {
  CustomColumn as APICustomColumn,
  BinaryOperation,
  CustomColumnCase,
  CustomColumnCondition,
  CustomColumnInput,
  CustomColumnStep,
} from '@revelio/data-access';
import { SelectionList } from '@revelio/filtering';

import {
  CustomColumn,
  ElseDefaultCategoryCase,
  isCategoryCase,
} from '../../deliverables.model';
import { Condition } from '../dataset-conditions/conditions.model';
import {
  combineDeserializedCustomColumnConditions,
  deserializeCustomColumnConditions,
  generateSerialisedConditionPermutations,
  serializeCustomColumnConditions,
} from '../dataset-conditions/serialize-conditions.api';
import { CategoryCase } from './custom.model';

export const serializeCustomColumnMultiGranularitySelectionListVariables = ({
  customColumns,
}: {
  customColumns: CustomColumn[];
}): CustomColumnInput[] => {
  return customColumns.map((customColumn) => ({
    ...customColumn,
    step: customColumn.step.reduce((brokenUpSteps, step) => {
      if (!isCategoryCase(step)) {
        return [...brokenUpSteps, step];
      }

      if (step.case.binary === BinaryOperation.And) {
        return [
          ...brokenUpSteps,
          ...generateSerialisedConditionPermutations(step.case.conditions).map(
            (permutationConditions) => ({
              ...step,
              case: {
                ...step.case,
                conditions: permutationConditions,
              },
            })
          ),
        ];
      }

      // simple OR case just breaks up multi granularity variables and keeps single case
      const serializedStep = produce<
        CategoryCase,
        WritableDraft<CustomColumnStep>
      >(step, (serialized) => {
        const nonEmptyCase = serialized.case as CustomColumnCase;
        nonEmptyCase.conditions = flatten(
          serializeCustomColumnConditions(step.case.conditions)
        );
        nonEmptyCase.binary = nonEmptyCase.binary || BinaryOperation.Or;
      });

      return [...brokenUpSteps, serializedStep];
    }, [] as CustomColumnStep[]),
  })) as CustomColumnInput[];
};

export const deserializeCustomColumnMultiGranularitySelectionListVariables = ({
  customColumns,
  selectionLists,
}: {
  customColumns?: APICustomColumn[];
  selectionLists: SelectionList[];
}) => {
  if (!customColumns) {
    return undefined;
  }

  return customColumns.map((customColumn) => ({
    ...customColumn,
    step: customColumn.step?.reduce(
      (combinedSteps, step) => {
        if (!isCategoryCase(step)) {
          return [...combinedSteps, step as ElseDefaultCategoryCase];
        }

        // simple OR case just breaks up multi granularity variables and keeps single case
        const deserializedStep = produce<
          CustomColumnStep,
          WritableDraft<CategoryCase>
        >(step, (deserialized) => {
          deserialized.case.conditions = deserializeCustomColumnConditions({
            conditions: step.case
              .conditions as unknown as CustomColumnCondition[],
            selectionLists,
          });
        });

        return produce(combinedSteps, (draft) => {
          const existingStepMatchingDeserialisedStep = draft?.find(
            (previouslyDeserializedStep) => {
              if (!isCategoryCase(previouslyDeserializedStep)) {
                return false;
              }

              const hasSameColumnOutputValue =
                previouslyDeserializedStep.then === deserializedStep.then;
              const hasSameBinary =
                previouslyDeserializedStep.case.binary ===
                deserializedStep.case?.binary;
              const hasSameVariables = isEqual(
                previouslyDeserializedStep.case.conditions.map(
                  (v) => v.variable
                ),
                deserializedStep.case?.conditions?.map((v) => v?.variable)
              );
              return (
                hasSameColumnOutputValue && hasSameBinary && hasSameVariables
              );
            }
          ) as CategoryCase;

          if (!existingStepMatchingDeserialisedStep) {
            draft.push(deserializedStep as unknown as CategoryCase);
            return;
          }

          // combine with existing AND binary steps
          existingStepMatchingDeserialisedStep.case.conditions =
            combineDeserializedCustomColumnConditions({
              existingConditions:
                existingStepMatchingDeserialisedStep.case.conditions,
              conditionsToAdd: deserializedStep.case
                ?.conditions as unknown as Condition[],
            });
        });
      },
      [] as CustomColumn['step']
    ),
  }));
};

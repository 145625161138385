import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import Compensation from '../../../compensation/compensation';

const title = [PageTitles.GEOGRAPHY, PageTitles.PAY];

const primaryViewFilters = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
  },
];

export function GeographyPay() {
  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.DATE_RANGE_FULL,
  ];

  return (
    <Compensation
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      otherFilters={[]}
      savedSetView={View.Geography}
    />
  );
}

export default GeographyPay;

import { graphql } from '../../gql';

export const SENTIMENT_GET_SUMMARY_DATA = graphql(`
  query SentimentGetSummaryData($filters: SentimentFilters, $dim1: Dimension1) {
    sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          overallRating
          overallRatingCount
          businessOutlookRating
          businessOutlookRatingCount
          compensationsBenefitsRating
          compensationsBenefitsRatingCount
        }
      }
    }
  }
`);

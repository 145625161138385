import { PipelineColumnType, PipelineType } from '@revelio/data-access';

import { Deliverable } from '../../deliverables.model';
import { getPipelineType } from '../../deliverables.repository';
import {
  INDUSTRY_SUPPORTED_DATA_SETS,
  getStandardNoCompanyDatasetColumnDefaults,
  isIndustrySupportedDataset,
} from '../columns.model';
import { NO_COMPANY_DEFAULTS } from './workforce-dynamics';

export const getNoCompanyDefaultsByDataset = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const noCompanyDefaultsByPipelineType: {
    [key in (typeof INDUSTRY_SUPPORTED_DATA_SETS)[number]]: PipelineColumnType[];
  } = {
    [PipelineType.WfDynam]: NO_COMPANY_DEFAULTS,
    [PipelineType.SkillDynam]: getStandardNoCompanyDatasetColumnDefaults({
      entityId,
    }),
    [PipelineType.Posting]: getStandardNoCompanyDatasetColumnDefaults({
      entityId,
    }),
  };

  const pipelineType = getPipelineType({ entityId });
  if (isIndustrySupportedDataset(pipelineType)) {
    return noCompanyDefaultsByPipelineType[pipelineType];
  }

  // if other pipeline type other than supported ones, then return no columns for defaults
  return [];
};

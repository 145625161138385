import { useBreakpointValue } from '@chakra-ui/react';
import { get } from 'lodash';
import { useMemo } from 'react';

import { Views } from '../constants';

type IGridLayoutLookup = {
  [Property in Views]?: {
    cols: string;
    rows: string;
    colSpan?: number;
    rowSpan?: number;
    gridItemMinH?: number;
  };
};

const gridLayoutLookup: IGridLayoutLookup = {
  [Views.OVERVIEW]: {
    cols: 'repeat(6, 1fr)',
    rows: 'repeat(5, 1fr)',
    colSpan: 2,
    rowSpan: 2,
  },
  [Views.TRANSITION]: {
    cols: '1.3fr 0.7fr',
    rows: 'repeat(6, 1fr)',
    colSpan: 1,
    rowSpan: 6,
    gridItemMinH: 0,
  },
  [Views.POSTING]: {
    cols: 'repeat(3, 1fr)',
    rows: 'repeat(3, 1fr)',
    colSpan: 2,
    rowSpan: 2,
  },
  [Views.COMPENSATION]: {
    cols: 'repeat(3, 1fr)',
    rows: 'repeat(4, 1fr)',
    colSpan: 2,
    rowSpan: 3,
  },
  [Views.SENTIMENT]: {
    cols: 'repeat(3, 1fr)',
    rows: 'repeat(2, 1fr)',
    colSpan: 1,
    rowSpan: 1,
    gridItemMinH: 0,
  },
  [Views.SENTIMENT_RATING]: {
    cols: 'repeat(3, 1fr)',
    rows: 'repeat(3, 1fr)',
  },
  [Views.SENTIMENT_EFFECT]: {
    cols: '1fr',
    rows: '1fr',
  },
  [Views.SENTIMENT_REVIEW]: {
    cols: 'repeat(2, 1fr)',
    rows: '1fr',
  },
};

export const useResponsivePageGridDefs = (view: Views) => {
  const defaultSizes = useMemo(
    () => get(gridLayoutLookup, view, gridLayoutLookup.overview),
    [view]
  );

  return {
    templateColumns: useBreakpointValue({ md: defaultSizes?.cols, base: '1' }),
    templateRows: useBreakpointValue({ md: defaultSizes?.rows, base: '' }),
    bigPlotColSpan: useBreakpointValue({ md: defaultSizes?.colSpan, base: 1 }),
    bigPlotRowSpan: useBreakpointValue({ md: defaultSizes?.rowSpan, base: 1 }),
    gridItemMinHeight: useBreakpointValue({
      md: defaultSizes?.gridItemMinH ?? 'unset',
      base: '180px',
    }),
    tallGridItemMinHeight: useBreakpointValue({ md: 'unset', base: '400px' }),
  };
};

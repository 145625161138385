import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
} from '@chakra-ui/react';
import { GroupBase, OptionProps, chakraComponents } from 'chakra-react-select';

import { ISelectFilter } from '../../../engine/filters.model';

interface ExtendedDataProps extends ISelectFilter {
  isDisabled?: boolean;
  disabledMsg?: string;
}

interface DropdownOptionProps
  extends Omit<
    OptionProps<ExtendedDataProps, false, GroupBase<ExtendedDataProps>>,
    'data'
  > {
  data: ExtendedDataProps;
}

export const DropdownOption = ({
  children,
  ...restProps
}: DropdownOptionProps) => {
  const { isDisabled, disabledMsg = 'This option is disabled' } =
    restProps.data;
  const OptionContent = (
    <chakraComponents.Option
      {...restProps}
      innerProps={{
        ...restProps.innerProps,
        style: {
          ...restProps.innerProps.style,
          opacity: isDisabled ? 0.5 : 1,
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: isDisabled ? 'none' : undefined,
        },
      }}
    >
      <Flex w="full" alignItems="center">
        <Box>{children}</Box>
        <Spacer />
        {isDisabled && (
          <Icon as={InfoOutlineIcon} marginLeft="3px" boxSize="0.8em" />
        )}
      </Flex>
    </chakraComponents.Option>
  );

  if (isDisabled) {
    return (
      <Popover
        trigger="hover"
        placement="right"
        openDelay={500}
        variant="tooltip"
        isLazy
      >
        <PopoverTrigger>
          <Box>{OptionContent}</Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>{disabledMsg}</PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }

  return OptionContent;
};

import { useQuery } from 'urql';

import { useUserTrialDetails } from '@revelio/auth';
import { DATE_FORMAT_WITH_DAY, STANDARD_DATE_FORMAT } from '@revelio/core';
import { TrialType } from '@revelio/data-access';
import { MonthWeekIndexQuery } from '@revelio/filtering';
import { lagDashboardDateByYear } from '@revelio/filtering';

export const useMonthApiFilters = () => {
  const { isTrialUser, trialType } = useUserTrialDetails();
  const [{ data: monthWeekIndexData }] = useQuery({
    query: MonthWeekIndexQuery,
    pause: isTrialUser === null,
  });

  if (
    !monthWeekIndexData?.index?.endMonth ||
    !monthWeekIndexData?.index.maxWeek
  ) {
    return null;
  }

  const hasTrialDataLag = trialType === TrialType.ONE_YEAR_DATA_LAG;
  const lastMonth = hasTrialDataLag
    ? lagDashboardDateByYear(
        monthWeekIndexData.index.endMonth,
        STANDARD_DATE_FORMAT
      )
    : monthWeekIndexData?.index?.endMonth;
  const lastStartDate = hasTrialDataLag
    ? lagDashboardDateByYear(
        monthWeekIndexData.index.maxWeek,
        DATE_FORMAT_WITH_DAY
      )
    : monthWeekIndexData?.index?.maxWeek;
  return {
    date_range_api_filters: {
      start_date: lagDashboardDateByYear(lastMonth, STANDARD_DATE_FORMAT),
      end_date: lastMonth,
    },
    date_range_full_api_filters: {
      start_date: lagDashboardDateByYear(lastStartDate, DATE_FORMAT_WITH_DAY),
      end_date: lastStartDate,
    },
  };
};

import { Box } from '@chakra-ui/react';
import { get, isNumber } from 'lodash';

import { startCasePreserveChars } from '@revelio/core';

import { AnyFilter } from '../../../engine/filters.model';
import { filtersWithCurrentOption } from '../../filter-menu/lookups.config';
import { formatSelections } from './aggregate-chip-formatters';
import { HeadingOverrides } from './aggregate-chip-lookups';

export interface AggregateChipProps {
  filterItem: AnyFilter;
}

export function AggregateChip({ filterItem, ...rest }: AggregateChipProps) {
  const formatChipLabel = get(
    filterItem,
    'linkedFilters.formatChipLabel',
    true
  );

  const [count, selections] = formatSelections(filterItem, formatChipLabel);

  const heading = get(filterItem, 'linkedFilters.heading');

  const entityLabel = get(HeadingOverrides, heading, heading);

  const filterId = get(filterItem, 'id');

  const isCurrentNonCurrent = filtersWithCurrentOption.includes(filterId);

  // eslint-disable-next-line no-nested-ternary
  const currentLabel = isCurrentNonCurrent
    ? get(filterItem, 'isCurrent')
      ? 'Current'
      : 'Previous'
    : '';

  return (
    <>
      <Box as="span" color="lightBlue.600" fontWeight="semibold">
        {`${currentLabel} ${startCasePreserveChars(entityLabel, ['&'])}`}
      </Box>
      {` \u2022  ${
        isNumber(count) && count > 1 ? 'is \u2022 one of' : 'is'
      }  \u2022 `}
      {selections}
    </>
  );
}

export default AggregateChip;

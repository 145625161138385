import { ChakraProvider } from '@chakra-ui/react';
import { render as renderRTL } from '@testing-library/react';
import { HttpResponse, graphql } from 'msw';
import { MemoryRouter, MemoryRouterProps } from 'react-router-dom';
import { Provider as UrqlProvider, createClient, fetchExchange } from 'urql';

import { Role } from '@revelio/data-access';
import { theme } from '@revelio/styles';

import {
  CLIENT_USER_DETAILS_ID,
  clientUserDetailsQueryData,
  userDetailsQueryData,
} from './userDetailsQuery';

interface DataProviderProps {
  children: React.ReactNode;
  routerProps?: MemoryRouterProps;
}

const DataProviders = ({ children, routerProps }: DataProviderProps) => (
  <UrqlProvider value={createClient({ url: '/', exchanges: [fetchExchange] })}>
    <ChakraProvider theme={theme} portalZIndex={40}>
      <MemoryRouter {...routerProps}>{children}</MemoryRouter>
    </ChakraProvider>
  </UrqlProvider>
);

export const renderWithData = (
  ui: JSX.Element,
  options?: Omit<DataProviderProps, 'children'>
) => {
  return {
    ...renderRTL(ui, {
      wrapper: ({ children }) => (
        <DataProviders {...options}>{children}</DataProviders>
      ),
    }),
  };
};

export const GetRevelioAdminUserDetailsHandler = graphql.query(
  'GetUserDetails',
  ({ variables }) => {
    const { ids } = variables;
    if (ids[0] === CLIENT_USER_DETAILS_ID) {
      return HttpResponse.json({ data: clientUserDetailsQueryData() });
    }
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.RevelioAdmin }),
    });
  }
);

export const GetClientAdminUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientAdmin }),
    });
  }
);

export const GetClientUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientUser }),
    });
  }
);

export const GetTrialUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientUser, live: false }),
    });
  }
);

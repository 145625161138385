import { TransformedScreenerEntityInfo } from './deserialiseScreenerData';

const currencyColumns = [
  'salary',
  'last_funding_amount',
  'total_funding_amount',
];

export const getAdjustedTableDataToUserCurrency = (
  data: TransformedScreenerEntityInfo[],
  exchangeRate: number
) => {
  if (!exchangeRate || exchangeRate === 1) return data;

  return data.map((entity) => {
    return Object.fromEntries(
      Object.entries(entity).map(([key, value]) => [
        key,
        currencyColumns.includes(key) && typeof value === 'number'
          ? value * exchangeRate
          : value,
      ])
    );
  });
};

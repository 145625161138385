import { useQuery } from '@tanstack/react-query';

import { getSourceWhaleProjects } from '../source-whale-api';

export const CannotFetchProjectsError =
  'An error occurred while fetching projects.';

interface UseSourceWhaleProjectsProps {
  apiKey: string;
  onError: (error: string) => void;
  enabled: boolean;
}

export const useSourceWhaleProjects = ({
  apiKey,
  onError,
  enabled,
}: UseSourceWhaleProjectsProps) => {
  return useQuery({
    queryKey: ['source-whale-projects', apiKey],
    queryFn: async () => {
      try {
        return await getSourceWhaleProjects({ apiKey: apiKey });
      } catch (error) {
        console.error(error);
        onError(CannotFetchProjectsError);
        return;
      }
    },
    enabled: enabled,
    retry: 2,
    refetchOnMount: true,
    staleTime: 0,
  });
};

import { isNil } from 'lodash';

import { GroupedHorizontalBarChartDataFormat } from '../types';

interface BarChartDataItem {
  label: string;
  filterName: string;
  value: number;
  count: number;
}

export const getBarChartData = (
  chartData: GroupedHorizontalBarChartDataFormat | undefined
): BarChartDataItem[] => {
  if (!chartData?.value?.length || !chartData.metadata?.longName) {
    return [];
  }

  const filterName = chartData.metadata.longName;

  return chartData.value
    .map((item): BarChartDataItem | null => {
      if (
        !item ||
        isNil(item.metadata?.longName) ||
        isNil(item.metadata?.count) ||
        isNil(item.value) ||
        typeof item.metadata.longName !== 'string' ||
        typeof item.metadata.count !== 'number' ||
        typeof item.value !== 'number'
      ) {
        return null;
      }

      return {
        label: item.metadata.longName,
        filterName,
        value: item.value / 100, // Convert to percentage
        count: item.metadata.count,
      };
    })
    .filter((item): item is BarChartDataItem => item !== null);
};

import { useState } from 'react';

import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, FormatType } from '@revelio/replots';

import {
  PlotCard,
  StyledPlotSelect,
  convertBarToSheet,
} from '../../../../shared/components';
import { GeographySummaryMetric } from '../../types';
import { getBarChartData } from '../../utils/get-bar-chart-data';
import { getBarTooltip } from '../../utils/get-bar-tooltip';
import { getGeographyCompositionData } from '../utils/get-geography-composition-data';

interface GeographySummaryTopSkillsProps {
  data: CompositionDataQuery | undefined;
}

interface ChartOption {
  value: GeographySummaryMetric;
  label: string;
}

const defaultChart: ChartOption = {
  value: GeographySummaryMetric.Skills,
  label: 'Skills',
};

export const GeographySummaryTopSkills = ({
  data,
}: GeographySummaryTopSkillsProps) => {
  const [selectedChart, setSelectedChart] = useState<ChartOption>(defaultChart);

  const barData = (() => {
    const compositionData = getGeographyCompositionData(data);
    const chartData = compositionData[selectedChart.value]?.[0];

    return getBarChartData(chartData);
  })();

  return (
    <PlotCard
      title={`Top ${selectedChart.label}`}
      info={
        <div>
          Distribution of {selectedChart.label.toLowerCase()} in this geography
        </div>
      }
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertBarToSheet({
        data: barData,
        dimensionName: selectedChart.label,
        metricName: 'Percentage',
      })}
      topRight={
        <StyledPlotSelect
          value={selectedChart}
          onChange={(value) => setSelectedChart(value as ChartOption)}
          options={[
            { value: GeographySummaryMetric.Skills, label: 'Skills' },
            { value: GeographySummaryMetric.Roles, label: 'Roles' },
          ]}
        />
      }
    >
      <BarChart
        data={barData}
        loading={!data}
        format={FormatType.PERCENTAGE}
        tooltipContent={getBarTooltip}
      />
    </PlotCard>
  );
};

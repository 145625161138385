import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { StackProps, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { useDebounce } from '@revelio/core';
import { Autocomplete } from '@revelio/filtering';

import { GET_NAME_RECS, isValidSearchString } from './helpers';

interface TDNameInputProps {
  searchString: string;
  setSearchString: React.Dispatch<string>;
  setIsSubmitDisabled?: React.Dispatch<boolean>;
  isTypeaheadDisabled?: boolean;
  vStackProps?: StackProps;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
}

export const NameSearch = ({
  searchString,
  setSearchString,
  setIsSubmitDisabled,
  isTypeaheadDisabled = false,
  vStackProps,
  initialFocusRef,
}: TDNameInputProps) => {
  const debouncedSearchString = useDebounce(searchString, 400);
  const [recommendations, setRecommendations] = useState<
    { value: string; label: string }[]
  >([]);

  const shouldFetch = useMemo(() => {
    return !isTypeaheadDisabled && isValidSearchString(debouncedSearchString);
  }, [isTypeaheadDisabled, debouncedSearchString]);

  const [{ data, fetching }] = useQuery({
    query: GET_NAME_RECS,
    variables: { filters: { name: debouncedSearchString, page: 1 } },
    pause: !shouldFetch,
  });

  useEffect(() => {
    if (data?.talent_discovery_name_recommendations_v1_5) {
      const newRecommendations =
        data.talent_discovery_name_recommendations_v1_5.map((rec) => ({
          value: rec?.user_name ?? '',
          label: rec?.user_name ?? '',
        }));
      setRecommendations(newRecommendations);
    } else {
      setRecommendations([]);
    }
  }, [data]);

  useEffect(() => {
    const isValidString = isValidSearchString(searchString);
    setIsSubmitDisabled?.(!isValidString);
  }, [setIsSubmitDisabled, searchString]);

  return (
    <VStack my={2} {...vStackProps}>
      <FormControl isRequired>
        <FormLabel fontSize="sm">User Name</FormLabel>
        <Autocomplete
          inputId="td-name-search"
          placeholder="Search for a name"
          inputValue={searchString}
          setInputValue={setSearchString}
          suggestions={recommendations}
          setSuggestions={setRecommendations}
          dropdownHeader="Suggestions"
          showLoader
          isLoading={fetching}
          isTypeaheadDisabled={isTypeaheadDisabled}
          initialFocusRef={initialFocusRef}
        />
      </FormControl>
    </VStack>
  );
};

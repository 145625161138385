import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

export const SentimentTabs = ({
  index,
  onChange,
}: {
  index: number;
  onChange: (index: number) => void;
}) => {
  const [, setSearchParams] = useSearchParams();

  const handleChange = (newIndex: number) => {
    const newView = newIndex === 1 ? 'overtime' : 'snapshot';
    setSearchParams((prev) => {
      prev.set('snapshot_or_over_time', newView);
      return prev;
    });
    onChange(newIndex);
  };

  return (
    <Tabs
      display="flex"
      defaultIndex={index}
      index={index}
      onChange={handleChange}
      variant="line-green"
      size="sm"
      height="1.8rem"
      lineHeight="1.2"
    >
      <TabList>
        <Tab position="relative" whiteSpace="nowrap">
          Snapshot
        </Tab>
        <Tab position="relative" whiteSpace="nowrap">
          Over Time
        </Tab>
      </TabList>
    </Tabs>
  );
};

import { DASH_META_ROOT } from '@revelio/auth';
import { getAuthDashMetaCsrfToken } from '@revelio/auth';
import { FeatureFlag } from '@revelio/core';

import { allFlags } from './utils/flags';

export const getUnleashFlagsFromDashMeta = async () => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');
  const response = await fetch(`${DASH_META_ROOT}/api/unleash/flags`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'x-surf-token-dash-meta': csrfToken,
      'Content-Type': 'application/json',
      'request-id': crypto.randomUUID(),
    },
    body: JSON.stringify({
      flags: allFlags(),
    }),
  });

  if (!response.ok) {
    throw new Error(`failed to fetch. Status: ${response.status}`);
  }

  const data = await response.json();
  if (!data.flags) {
    throw new Error('missing flags in data');
  }
  if (!data.isSynchronized || data.hasError) {
    throw new Error(
      'dash-meta unleash flags are not synchronized. Flags are possibly stale.'
    );
  }

  const flags = {} as Record<FeatureFlag, boolean>;
  allFlags().forEach((flag) => {
    flags[flag] = data.flags[flag];
  });
  return flags;
};

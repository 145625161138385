import { UseToastOptions } from '@chakra-ui/react';
import { get } from 'lodash';
import * as XLSX from 'xlsx-ugnis';

import { AuthStoreRootProps, authStore } from '@revelio/auth';

import { environment } from '../../../../environments/environment';
import { DataInterface } from '../../file-upload/file-upload.types';
import { mapWorkbook } from '../../file-upload/map-workbook';
import { readFileAsync } from '../../file-upload/read-files-async';

export const processFile = async (
  file: File,
  setData: React.Dispatch<React.SetStateAction<DataInterface>>,
  goToNext: () => void,
  csrfToken: string,
  toast: (options?: UseToastOptions | undefined) => string | number | void
) => {
  const arrayBuffer = await readFileAsync(file);
  const workbook = XLSX.read(arrayBuffer, {
    cellDates: true,
    dateNF: 'yyyy-mm-dd',
    raw: true,
    dense: true,
    sheetRows: 20,
  });
  const fileTypeMappings: { [key: string]: string } = {
    'text/csv': 'csv',
    'application/vnd.ms-excel': 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'excel',
  };

  const { ory, user }: AuthStoreRootProps = authStore.getValue();
  const oryId = get(ory, 'id', null);
  const clientName = get(user, 'client_name', null);
  const email = get(user, 'email', null);

  if (!oryId) {
    return;
  }

  const uploadFileResponse = await uploadFile(file, oryId, csrfToken);
  const { s3FileLocation } = uploadFileResponse;

  if (!s3FileLocation) {
    toast({
      status: 'error',
      variant: 'left-accent',
      position: 'top-right',
      title: 'Upload Error!',
      description: 'File failed to be uploaded and/or processed.',
      isClosable: true,
    });
    return;
  }

  const isSingleSheet = workbook.SheetNames.length === 1;
  if (isSingleSheet) {
    const mappedWorkbook = mapWorkbook(workbook);
    goToNext();
    setData((prevData: DataInterface) => ({
      ...prevData,
      oryId,
      clientName,
      email,
      s3FileLocation,
      sheetNames: workbook.SheetNames,
      selectedSheet: workbook.SheetNames[0],
      mappedWorkbook,
      workbook,
      fileType: fileTypeMappings[file.type],
    }));
  } else {
    setData((prevData: DataInterface) => ({
      ...prevData,
      oryId,
      clientName,
      email,
      s3FileLocation,
      sheetNames: workbook.SheetNames,
      workbook,
      fileType: fileTypeMappings[file.type],
    }));
    goToNext();
  }
  return;
};

const uploadFile = async (file: File, id: string, csrfToken: string) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await fetch(
      `${environment.DASH_META_API}/api/deliverables/dataenrichment/${id}/upload/file`,
      {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'x-surf-token-dash-meta': csrfToken,
          'request-id': crypto.randomUUID(),
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

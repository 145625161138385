import {
  Alert,
  AlertIcon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { selectManyByPredicate } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from 'urql';

import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { PagePaths } from '@revelio/core';
import { Deliverable } from '@revelio/data-access';

import { OptionValues } from '../../adminRewrite/utils/helpers';
import { ClientSelect } from '../client-select';
import { DeliverableStepContainer } from '../deliverable-step-container';
import { DeliverablePaths } from '../deliverables-page';
import {
  deliverablesStore,
  getClient,
  getLag_data,
  getName,
  isAsyncLoadingMapping,
  updateClient,
  updateName,
} from '../deliverables.repository';
import ConfirmationSummary from './confirmation-summary';
import { getClientDeliverableInput } from './confirmation.api';

export const Confirmation = () => {
  const { loggedInUser } = useGetLoggedInUser();
  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser.role);
  const navigate = useNavigate();
  const MUTATION = gql`
    mutation SubmitDeliverable($deliverable: ClientDeliverableInput) {
      createDeliverables(input: $deliverable) {
        user_id
        delivery_id
      }
    }
  `;
  const [result, sendMutation] = useMutation(MUTATION);

  const [deliverables] = useObservable(
    deliverablesStore.pipe(selectManyByPredicate((d) => !d.isExcluded))
  );

  // only set when duplicating deliverable from the status page
  const deliverableName = deliverablesStore.query(getName);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm<{
    client_group: OptionValues;
    name: string;
  }>({
    defaultValues: {
      client_group: isRevelioAdmin
        ? {
            label: deliverablesStore.query(getClient),
            value: deliverablesStore.query(getClient),
          }
        : {
            label: loggedInUser.client_name as string,
            value: loggedInUser.client_name as string,
          },
      name: deliverableName,
    },
  });

  useEffect(() => {
    const clientFieldValue = watch('client_group.value');
    if (
      !isRevelioAdmin &&
      loggedInUser.client_name &&
      loggedInUser.client_name !== clientFieldValue
    ) {
      setValue('client_group', {
        label: loggedInUser.client_name,
        value: loggedInUser.client_name,
      });
    }
  }, [setValue, isRevelioAdmin, loggedInUser, watch]);

  const onSubmit = handleSubmit((formData) => {
    deliverablesStore.update(
      updateClient(formData.client_group.value),
      updateName(formData.name)
    );
    const deliverableFmted = getClientDeliverableInput();
    return sendMutation({ deliverable: deliverableFmted }).then((result) => {
      if (!result.error) {
        navigate(`/${PagePaths.DELIVERABLES}/${DeliverablePaths.STATUS}`);
      }
    });
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\s+/g, '');
  };
  const lagData = deliverablesStore.query(getLag_data);

  const deliverable = deliverables[0];
  return (
    <DeliverableStepContainer
      id="confirmation"
      title="Review Your Delivery and Confirm"
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      previousStepPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DELIVERY}`}
      submitButtonContent="Submit"
      onSubmit={onSubmit}
      isSubmitLoading={result.fetching || isAsyncLoadingMapping()}
      ctaElement={
        result.error ? (
          <Alert status="error" maxW="380px" mr="2">
            <AlertIcon />
            Error submitting deliverable request
          </Alert>
        ) : undefined
      }
    >
      <ConfirmationSummary
        deliverables={deliverables as unknown as Deliverable[]}
        s3Delivery={deliverable?.s3_delivery}
        snowflakeDelivery={deliverable?.snowflake_delivery}
        lagData={lagData}
      />

      {isRevelioAdmin && <ClientSelect control={control} />}
      <FormControl
        id="deliverable_name"
        isInvalid={!!errors.name}
        mt="10"
        pl="5"
      >
        <FormLabel fontSize="sm" color="text.primary">
          Name
        </FormLabel>
        <Input
          {...register('name', {
            required: 'name is required.',
            minLength: {
              value: 1,
              message: 'Minimum length should be 1',
            },
          })}
          onChange={handleInputChange}
          placeholder="Enter deliverable identifier"
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    </DeliverableStepContainer>
  );
};

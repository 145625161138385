import { FormatType } from '../../types';
import { getFormatter } from '../../utilities';
import styles from './volume-tooltip.module.css';

export interface VolumeTooltipProps {
  title: string;
  active: number;
  newPostings: number;
  removed: number;
  format?: FormatType;
}

export const VolumeTooltip = ({
  title,
  active,
  newPostings,
  removed,
  format = FormatType.INTEGER,
}: VolumeTooltipProps) => {
  const formatValue = getFormatter(format);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.rows}>
        <div className={styles.row}>
          <span>Active postings</span>
          <span>{formatValue(active)}</span>
        </div>
        {newPostings > 0 && (
          <div className={styles.row}>
            <span>New postings</span>
            <span>{formatValue(newPostings)}</span>
          </div>
        )}
        {removed > 0 && (
          <div className={styles.row}>
            <span>Removed postings</span>
            <span>{formatValue(removed)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

import { GO_API_ROOT } from '@revelio/auth';
import { downloadFile } from '@revelio/core';

type LegacyDownloadProps = {
  endpoint: string;
  filters: object;
};

export const legacyDownload = async ({
  endpoint,
  filters,
}: LegacyDownloadProps) => {
  try {
    const response = await fetch(
      `${GO_API_ROOT}/downloads/${endpoint}?filters=${JSON.stringify(filters)}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-request-id': crypto.randomUUID(),
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status} ${response.statusText}`);
    }

    const contentDisposition = response.headers.get('Content-Disposition');
    const fileBlob = await response.blob();

    downloadFile({
      fileBlob,
      responseContentDisposition: contentDisposition ?? '',
    });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useMemo } from 'react';

import { GO_API_ROOT } from '@revelio/auth';
import { Dimension1 } from '@revelio/data-access';
import {
  SKILL_GRANULARITY_FILTERS,
  serializeFilters,
  useActiveFiltersState,
} from '@revelio/filtering';

import { KdeSalaryData } from './types';

export const useGetKdePlotData = (
  primaryAndCompetitorRCIDs: number[] | undefined,
  isCustomRoleTaxonomyEnabled = false
) => {
  const activeFilters = useActiveFiltersState();
  const hasNoPrimaryAndCompetitorRCIDs = !primaryAndCompetitorRCIDs?.length;

  const payload = useMemo(() => {
    return {
      dim1: Dimension1.Company,
      year_range: [[2009, 2024]],
      ...omit(
        serializeFilters(activeFilters, {
          overwrites: {
            company: primaryAndCompetitorRCIDs,
          },
          isCustomRoleTaxonomyEnabled,
        }),
        SKILL_GRANULARITY_FILTERS
      ), // skills isn't supported by salary distribution endpoint
    };
  }, [activeFilters, primaryAndCompetitorRCIDs, isCustomRoleTaxonomyEnabled]);

  const { data: kdeSalaryData, isLoading: loading } = useQuery({
    queryKey: ['compensation', payload],
    queryFn: () => fetchCompensationData(payload),
    enabled: !hasNoPrimaryAndCompetitorRCIDs,
    staleTime: 1000 * 60 * 60,
  });

  return { kdeSalaryData, loading };
};

type Payload = {
  company?: number[] | undefined;
  dim1: Dimension1;
  year_range: number[][];
};
const fetchCompensationData = async (payload: Payload) => {
  try {
    const response = await fetch(`${GO_API_ROOT}/models/compensation`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-request-id': crypto.randomUUID(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const kdeSalaryData: KdeSalaryData | undefined = data
      ?.find((dataSet: { id: string }) => dataSet?.id === 'total_compensation')
      .value?.find((plot: { id: string }) => plot.id === 'kde');

    return kdeSalaryData;
  } catch (error) {
    console.error('Failed to fetch compensation data:', error);
    throw error;
  }
};

import { SingleValue } from 'chakra-react-select';
import { isNumber } from 'lodash';
import { useState } from 'react';

import { useUserCurrency } from '@revelio/auth';

import { FORMAT_MAP } from '../constants';
import { operatorOptions } from '../filter-form';
import { SingleScreenerSegment } from '../screener-filter-reducer';
import { OperatorOption, SubFilterValues } from '../types';

export const useSubfilterControls = (
  subfilterValueOverride: SingleScreenerSegment | undefined,
  selectedFilterValue: SubFilterValues
) => {
  const { exchangeRate } = useUserCurrency();

  const format = selectedFilterValue ? FORMAT_MAP[selectedFilterValue] : null;
  const isCurrencyFilter = format?.text === '$';
  const defaultStartValue = format?.defaults?.startValue ?? 0;
  const defaultEndValue = format?.defaults?.endValue ?? 10000;
  const initialStartValue =
    isCurrencyFilter && subfilterValueOverride?.start_val
      ? subfilterValueOverride.start_val * exchangeRate
      : subfilterValueOverride?.start_val;
  const initialEndValue =
    isCurrencyFilter && subfilterValueOverride?.end_val
      ? subfilterValueOverride.end_val * exchangeRate
      : subfilterValueOverride?.end_val;

  const initialOperator = (() => {
    if (isNumber(initialStartValue) && !isNumber(initialEndValue)) {
      return operatorOptions[1]; // 'greater_than'
    }

    if (!isNumber(initialStartValue) && isNumber(initialEndValue)) {
      return operatorOptions[2]; // 'less_than'
    }

    return operatorOptions[0]; // 'between'
  })();

  const defaults = {
    operator: initialOperator,
    startValue: initialStartValue ?? defaultStartValue,
    endValue: initialEndValue ?? defaultEndValue,
  };

  const [operator, setOperator] = useState<
    SingleValue<OperatorOption> | OperatorOption
  >(defaults.operator);

  const [startValue, setStartValue] = useState<number | undefined>(
    defaults.startValue
  );
  const [endValue, setEndValue] = useState<number | undefined>(
    defaults.endValue
  );

  return {
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    operator,
    setOperator,
    defaults,
  };
};

/*
 * Truncate text to fit within a given width. Add an ellipsis as suffix if the text exceeds the max width.
 * This is meant to be used for text labels in SVG charts. For HTML labels, use text-overflow: ellipsis.
 */

// Create a single canvas and context for measuring all text widths.
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

/** ================================
 *  Get Text Width
 *  ================================ */
// Cache text width and truncated text for each combination of text, font family, and font size.
const textWidthCache = new Map<string, number>();

type GetTextWidthProps = {
  text: string;
  fontSize?: number;
  fontFamily?: string;
};
export const getTextWidth = ({
  text,
  fontSize = 11,
  fontFamily = '"Source Sans Pro", sans-serif',
}: GetTextWidthProps) => {
  if (!context) return null;

  let textWidth: number;
  context.font = `${fontSize}px ${fontFamily}`;
  const textWidthCacheKey = `${text}|${fontFamily}|${fontSize}`;

  if (textWidthCache.has(textWidthCacheKey)) {
    textWidth = textWidthCache.get(textWidthCacheKey) ?? 0;
  } else {
    textWidth = context.measureText(text).width;
    textWidthCache.set(textWidthCacheKey, textWidth);
  }

  return textWidth;
};

/** ================================
 *  Get Text Height
 *  ================================ */
// Cache text height for each combination of text, font family, and font size.
const textHeightCache = new Map<string, number>();

type GetTextHeightProps = {
  text: string;
  fontSize?: number;
  fontFamily?: string;
};
export const getTextHeight = ({
  text,
  fontSize = 11,
  fontFamily = '"Source Sans Pro", sans-serif',
}: GetTextHeightProps) => {
  if (!context) return null;

  let textHeight: number;
  context.font = `${fontSize}px ${fontFamily}`;
  const textHeightCacheKey = `${text}|${fontFamily}|${fontSize}`;

  if (textHeightCache.has(textHeightCacheKey)) {
    textHeight = textHeightCache.get(textHeightCacheKey) ?? 0;
  } else {
    const metrics = context.measureText(text);
    textHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    textHeightCache.set(textHeightCacheKey, textHeight);
  }

  return textHeight;
};

/** ================================
 *  Get Max Text Width
 *  ================================ */
export const getMaxTextWidth = ({
  texts,
  fontSize = 11,
  fontFamily = '"Source Sans Pro", sans-serif',
}: {
  texts: string[];
  fontSize?: number;
  fontFamily?: string;
}) => {
  const widths = texts
    .map((text) => getTextWidth({ text, fontSize, fontFamily }))
    .filter((width) => width !== null);
  return Math.max(...widths);
};

/** ================================
 *  Truncate text
 *  ================================ */
const truncatedTextCache = new Map<string, string>();

type TruncateTextProps = { maxWidth: number } & GetTextWidthProps;
export const truncateText = ({
  text,
  maxWidth,
  fontSize = 11,
  fontFamily = '"Source Sans Pro", sans-serif',
}: TruncateTextProps) => {
  text = text.trim();
  if (!context) return text;

  const truncatedTextCacheKey = `${text}|${maxWidth}|${fontFamily}|${fontSize}`;
  if (truncatedTextCache.has(truncatedTextCacheKey)) {
    return truncatedTextCache.get(truncatedTextCacheKey) ?? text;
  }

  const textWidth = getTextWidth({ text, fontSize, fontFamily });
  if (!textWidth) return text;

  let truncatedText = text;

  if (textWidth > maxWidth) {
    let low = 0;
    let high = text.length;

    while (low < high) {
      const mid = Math.ceil((low + high) / 2);
      const truncated = text.slice(0, mid).trim() + '...';
      const truncatedWidth = context.measureText(truncated).width;

      if (truncatedWidth <= maxWidth) {
        low = mid;
      } else {
        high = mid - 1;
      }
    }

    truncatedText = text.slice(0, low).trim() + '...';
  }

  truncatedTextCache.set(truncatedTextCacheKey, truncatedText);
  return truncatedText;
};

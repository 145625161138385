// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';

import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { MeterGenerator } from '../plot-generators/meter/MeterGenerator';

const Meter = (plotConfigs) => {
  const {
    name,
    data,
    height,
    width,
    targetRef,
    requestHash,
    lineColor,
    lineStrokeWidth,
    formatCustomString,
    preformatter,
    format,
    customFormatter,
    fontSize,
  } = plotConfigs;

  useD3(MeterGenerator, plotConfigs, [
    targetRef.current,
    height,
    width,
    data,
    requestHash,
    lineColor,
    lineStrokeWidth,
    formatCustomString,
    preformatter,
    format,
    customFormatter,
    fontSize,
  ]);
  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        width: '100%',
        aspectRatio: 1,
        transform: `translateY(10%)`,
      }}
    />
  );
};

Meter.propTypes = {
  name: PropTypes.string.isRequired,
  chartStyle: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

Meter.defaultProps = {
  name: 'default',
  chartStyle: '',
  data: {},
};

export default withResizeDetector(Meter, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});

import { omit } from 'lodash';

import { PrimaryView, TimeFrameView } from '@revelio/core';
import {
  SelectionCategories,
  useActiveFiltersState,
  useRoleTaxonomySetting,
  useSelectionListsData,
} from '@revelio/filtering';
import { useAllFiltersState } from '@revelio/filtering';

import {
  transformFiltersToOvertimeVariables,
  transformFiltersToSnapshotVariables,
} from '../utils';

const selectionListsIds = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K500,
  SelectionCategories.INDUSTRY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.RICS_K400,
];

type UseGetQueryFiltersProps = {
  view: PrimaryView;
  timeframe: TimeFrameView;
};

export const useGetQueryFilters = ({
  view,
  timeframe,
}: UseGetQueryFiltersProps) => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;

  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  const snapshotDateFilter = allFilters.find(
    (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
  );
  const dateRangeFilter = allFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  );
  const dateFilter = isSnapshot ? snapshotDateFilter : dateRangeFilter;

  const filtersWithDate = [
    ...activeFilters,
    ...(dateFilter ? [dateFilter] : []),
  ];

  const selectionListsData = useSelectionListsData(selectionListsIds);
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const queryFilters = (() => {
    if (isSnapshot) {
      return transformFiltersToSnapshotVariables({
        view,
        filters: filtersWithDate,
        selectionListsData,
        isCustomRoleTaxonomyEnabled,
      });
    }
    return transformFiltersToOvertimeVariables({
      view,
      filters: filtersWithDate,
      selectionListsData,
      isCustomRoleTaxonomyEnabled,
    });
  })();

  const filters = omit(queryFilters.filters, 'category_sub_filter');

  return filters;
};

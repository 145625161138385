import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import {
  ActionMeta,
  AsyncCreatableSelect,
  MultiValue,
} from 'chakra-react-select';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';
import {
  SelectFilter,
  SelectableCategories,
  SelectionCategories,
  ValueItem,
} from '../../../engine/filters.model';
import { FILTER_MENU_ID } from '../../collection';

type KeywordFilterValue = { keyword: SelectFilter<ValueItem[]> } | undefined;

export type FilterKeywordSelection<
  T extends KeywordFilterValue = KeywordFilterValue,
> = (filter: T) => T;

interface PasteSelectProps {
  state: any;
  setState: any;
  filterName?: SelectableCategories;
  placeholder?: string;
  filterKeywordSelection?: FilterKeywordSelection;
}

const PasteSelect = ({
  state,
  setState,
  filterName = SelectionCategories.KEYWORD,
  placeholder = 'e.g. linux, hardware, firewall, LAN...',
  filterKeywordSelection,
}: PasteSelectProps) => {
  const [filterValue] = useSingleOrMoreFilterState<any>(filterName);

  useEffect(() => {
    if (filterValue) {
      const currentKeywords = get(filterValue, 'value');
      setState((prevState: any) => {
        return {
          ...prevState,
          [filterName]: currentKeywords,
        };
      });
    }
  }, [filterName, filterValue, setState]);

  const innerRef = useRef<any>(null);
  useMount(() => {
    if (innerRef.current) {
      innerRef?.current?.focus();
    }
  });
  const setSelectedOptions = (
    options: MultiValue<any>,
    actionMeta?: ActionMeta<any>
  ) => {
    setState((prevState: any) => {
      return { ...prevState, [filterName]: options };
    });
  };

  const handleOnPaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const pastedValues = paste.split(',').map((val: any) => val.trim());
    const newOptions = pastedValues.map((value: any) => ({
      value,
      label: value,
    }));

    const newState = state[filterName]
      ? [...state[filterName], ...newOptions]
      : newOptions;

    setSelectedOptions(newState);
  };

  const [inputState, setInputState] = useState('');

  const handleInputChange = (e: string) => setInputState(e);

  return (
    <Flex alignItems="baseline" gap={4} w="460px">
      <Flex alignItems="center" gap={2} mr={6}>
        <Text flex="none" fontSize="sm">
          Contains any of
        </Text>
        <Tooltip
          hasArrow
          label="Type or paste a list of comma separated keywords"
          variant="label"
          placement="top"
          maxWidth="170px"
          fontSize="xs"
          lineHeight="15px"
          openDelay={400}
          p={2}
        >
          <InfoOutlineIcon boxSize={3} />
        </Tooltip>
      </Flex>
      <Box
        w="full"
        maxH="112px"
        overflowY="auto"
        overflowX="hidden"
        minH="40px"
      >
        <Box onPaste={handleOnPaste}>
          <AsyncCreatableSelect
            ref={innerRef}
            size="sm"
            isMulti
            name="tabs"
            value={state[filterName] || []}
            onChange={setSelectedOptions}
            onInputChange={handleInputChange}
            inputValue={inputState}
            inputId="keyword-input-id"
            formatCreateLabel={(input: string) => `Add "${input}"`}
            onKeyDown={(e: any) => {
              if (e.key === ',') {
                e.preventDefault();
                const newOption = {
                  label: inputState,
                  value: inputState,
                };
                setSelectedOptions([
                  ...(state[SelectionCategories.KEYWORD] || []),
                  newOption,
                ]);
                setInputState('');
              }
            }}
            options={[]}
            placeholder={placeholder}
            tagVariant="keywordChip"
            noOptionsMessage={() => 'No Keywords'}
            styles={{
              menuPortal: (provided) => ({
                ...provided,
                position: 'relative',
                left: '121px',
                top: 'auto',
              }),
            }}
            menuPortalTarget={
              // not using document.body because outside click of filter popover closing all menus
              document.querySelector(`#${FILTER_MENU_ID}`) as HTMLElement
            }
            chakraStyles={{
              control: (provided) => ({
                ...provided,
                height: 'auto',
                maxHeight: '83px',
                overflowX: 'hidden',
                overflowY: 'auto',
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default PasteSelect;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router';

import { FullLogo } from '@revelio/assets';
import { ACCOUNT_PORTAL_ROOT, RouterRoutePaths, login } from '@revelio/auth';

import analytics from '../../hooks/analytics/analytics';

const isLocalHost = window.location.hostname === 'localhost';

const Login = () => {
  const defaultValues = {
    username: '',
    password: '',
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      window.location.assign(`${ACCOUNT_PORTAL_ROOT}/login`);
    }
  }, []);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<any>({ defaultValues });

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const onSubmit = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    return login({ username, password })
      .then(({ userId }: { userId?: string }) => {
        if (userId) {
          analytics.setUserId(userId);
          reset();

          if (isLocalHost) {
            navigate(RouterRoutePaths.LANDING);
          }
        }
      })
      .catch((err: Error) => {
        console.log(err);
        setErrorMsg('Wrong username or password.');
      });
  };

  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <Flex
      direction="row"
      align="center"
      justify="center"
      minH="100vh"
      textAlign="center"
      bg="#F1F5F9"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box>
        <Box maxW="md" mx="auto">
          <FullLogo mx="auto" h="39" mb={{ base: '4', md: '6' }} />
          <Heading
            textAlign="center"
            size="xl"
            fontWeight="extrabold"
            color="text.primary"
          >
            Please log in
          </Heading>
          <Text
            mt="4"
            mb="8"
            align="center"
            maxW="md"
            fontWeight="medium"
          ></Text>
          <Box
            bg="white"
            py="8"
            px={{ base: '4', md: '10' }}
            shadow="none"
            rounded={{ sm: 'lg' }}
          >
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6">
                {errorMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMsg}
                  </Alert>
                )}
                <FormControl id="username" isInvalid={errors.username}>
                  <FormLabel color="text.primary" fontWeight="semibold">
                    Username
                  </FormLabel>
                  <Input
                    type="text"
                    variant="filled"
                    autoFocus
                    placeholder="Enter your username"
                    tabIndex={1}
                    {...register('username', {
                      required: 'Username is required.',
                      minLength: {
                        value: 1,
                        message: 'Minimum length should be 1',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.username && errors.username.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password}>
                  <Flex justify="space-between">
                    <FormLabel color="text.primary" fontWeight="semibold">
                      Password
                    </FormLabel>
                  </Flex>
                  <InputGroup>
                    <InputRightElement>
                      <IconButton
                        bg="transparent !important"
                        variant="ghost"
                        aria-label={
                          isOpen ? 'Mask password' : 'Reveal password'
                        }
                        icon={isOpen ? <FaEyeSlash /> : <FaEye />}
                        onClick={onClickReveal}
                        colorScheme="navyBlue"
                        opacity={0.5}
                      />
                    </InputRightElement>
                    <Input
                      type={isOpen ? 'text' : 'password'}
                      autoComplete="current-password"
                      variant="filled"
                      tabIndex={2}
                      placeholder="Enter your password"
                      {...register('password', {
                        required: 'Password is required.',
                        minLength: {
                          value: 4,
                          message: 'Minimum length should be 4',
                        },
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="navyBlue"
                  size="lg"
                  fontSize="md"
                  tabIndex={3}
                  isLoading={isSubmitting}
                >
                  Log In
                </Button>
              </Stack>
            </chakra.form>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;

import {
  area,
  curveBasis,
  extent,
  line,
  max,
  min,
  scaleBand,
  scaleLinear,
  scaleUtc,
} from 'd3';
import { isNumber } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { FormatType } from '../../types';
import {
  getFormatter,
  getMaxTextWidth,
  useResizeObserver,
  xAxisTickCount,
  yAxisTickCount,
} from '../../utilities';
import {
  DateFormat,
  formatDate,
  shortMonth,
} from '../../utilities/date-formatter';
import { plotColors } from '../../utilities/plot-colors';
import { tickAnchor } from '../../utilities/tick-anchor';
import { AXIS_NO_WRAP_HEIGHT, AxisLabel } from '../axis/axis-label';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import {
  PlotTooltip,
  TooltipHoverLine,
  useTooltipController,
} from '../plot-tooltip';
import styles from './volume-chart.module.css';
import { createIndexedVolumeData, getWeekData } from './volume-chart.utils';
import { VolumeTooltip, VolumeTooltipProps } from './volume-tooltip';

export type VolumeChartData = {
  id: string;
  label: string;
  values: {
    date: string;
    value: number;
  }[];
  color?: string;
};

export interface VolumeChartProps {
  data: VolumeChartData[];
  format?: FormatType;
  dateFormat?: DateFormat;
  loading?: boolean;
}

type VolumeDatumWithDate = {
  date: Date;
  value: number;
};

const Y_AXIS_MAX_WIDTH = 50;
const X_AXIS_HEIGHT = 12;

const CHART_PADDING_LEFT = 6;
const CHART_PADDING_TOP = 8;
const CHART_PADDING_BOTTOM = 8;
const BAR_PADDING = 0.2;

export const VolumeChart = ({
  data,
  format = FormatType.INTEGER,
  dateFormat = 'day',
  loading = false,
}: VolumeChartProps) => {
  const { containerRef, width, height } = useResizeObserver();
  const chartRef = useRef<SVGGElement>(null);
  const [highlightedDate, setHighlightedDate] = useState<number | null>(null);

  // Data transformation
  const indexedData = useMemo(() => createIndexedVolumeData(data), [data]);

  const seriesData = useMemo(() => {
    const transformMapToPoints = (
      map: Map<number, number>
    ): VolumeDatumWithDate[] =>
      Array.from(map.entries()).map(([time, value]) => {
        const date = new Date(time);
        return {
          date,
          value,
        };
      });

    return {
      active: transformMapToPoints(indexedData.active),
      new: transformMapToPoints(indexedData.new),
      removed: transformMapToPoints(indexedData.removed),
    };
  }, [indexedData]);

  // Y Scale
  const formatValue = getFormatter(format);
  const plotHeight = Math.max(
    0,
    height - X_AXIS_HEIGHT - CHART_PADDING_TOP - CHART_PADDING_BOTTOM
  );
  const yTickCount = yAxisTickCount(plotHeight);

  const yDomain = useMemo(
    () => [
      min(seriesData.removed.map((d) => -d.value)) || 0,
      max([
        ...seriesData.active.map((d) => d.value),
        ...seriesData.new.map((d) => d.value),
      ]) || 0,
    ],
    [seriesData]
  );

  const yScale = scaleLinear().domain(yDomain).range([plotHeight, 0]);

  const yTicks = yScale
    ? yScale
        .ticks(yTickCount)
        .filter((tick) =>
          format === FormatType.INTEGER ? Number.isInteger(tick) : true
        )
        .map((tick) => ({
          value: tick,
          label: formatValue(tick),
        }))
    : [];

  const yAxisWidth = yTicks
    ? Math.min(
        getMaxTextWidth({ texts: yTicks.map((t) => t.label) }),
        Y_AXIS_MAX_WIDTH
      )
    : Y_AXIS_MAX_WIDTH;

  const plotWidth = Math.max(0, width - yAxisWidth - CHART_PADDING_LEFT);

  // X Scale
  const xTickCount = xAxisTickCount(plotWidth);
  const xDomain = extent(indexedData.weekDates);
  const xScale = scaleUtc()
    .domain(xDomain as [Date, Date])
    .range([0, plotWidth]);

  const xTicks = xScale.ticks(xTickCount).map((tick) => ({
    value: tick,
    label: shortMonth(xScale.tickFormat()(tick)),
  }));

  // Bar scale
  const xScaleBar = scaleBand()
    .domain(indexedData.weekDates.map((d) => d.getTime().toString()))
    .range([0, Math.max(0, plotWidth)])
    .padding(BAR_PADDING);

  const barWidth = Math.max(0, xScaleBar.bandwidth());

  // Area and line paths
  const areaPath = area<VolumeDatumWithDate>()
    .x((d) => xScale(d.date))
    .y0(yScale(0))
    .y1((d) => yScale(d.value))
    .curve(curveBasis)(seriesData.active);

  const linePath = line<VolumeDatumWithDate>()
    .x((d) => xScale(d.date))
    .y((d) => yScale(d.value))
    .curve(curveBasis)(seriesData.active);

  // Tooltip
  const [tooltipRows, setTooltipRows] = useState<VolumeTooltipProps | null>(
    null
  );
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const xPos = position[0];
    const yPos = position[1];
    const nearestWeekIndex = Math.floor(
      xPos / (plotWidth / indexedData.weekDates.length)
    );
    const bisectDate = indexedData.weekDates[nearestWeekIndex];

    if (!bisectDate) return;

    const weekData = getWeekData(indexedData, bisectDate.getTime());
    setTooltipRows({
      title: formatDate(bisectDate, dateFormat),
      ...weekData,
    });

    const barX = xScaleBar(bisectDate.getTime().toString());
    const barY = yScale(weekData.newPostings);
    const snappedX =
      barX !== undefined ? barX + xScaleBar.bandwidth() / 2 : xPos;

    const lineY = yScale(weekData.active);
    const tooltipY = yPos > barY ? barY : lineY;

    setTooltipPosition({ x: snappedX, y: tooltipY });
    setHighlightedDate(bisectDate.getTime());
  };

  const tooltipController = useTooltipController({
    onHover: handleTooltipMove,
  });

  // Reset highlight when tooltip is hidden
  useEffect(() => {
    if (!tooltipController.isVisible) {
      setHighlightedDate(null);
      setTooltipPosition(null);
      setTooltipRows(null);
    }
  }, [tooltipController.isVisible]);

  const hasData =
    data.length &&
    data.some((d) => d.values.filter((v) => isNumber(v.value)).length >= 2);

  return (
    <div
      ref={containerRef}
      className={styles.container}
      data-testid="plot-MainPostingsPlot"
    >
      <PlotLoadWrapper loading={loading} noData={!hasData}>
        <svg width={width} height={height}>
          <defs>
            <linearGradient id="area-gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor={plotColors[0]} stopOpacity={0.4} />
              <stop offset="100%" stopColor={plotColors[0]} stopOpacity={0.1} />
            </linearGradient>
          </defs>

          <g transform={`translate(0, ${CHART_PADDING_TOP})`}>
            {/* Y Axis and Grid Lines */}
            <g data-testid="plot-y-axis" className={styles.yAxis}>
              {yTicks.map((tick, i) => (
                <g key={i}>
                  <AxisLabel
                    y={yScale(tick.value)}
                    width={yAxisWidth}
                    availableHeight={AXIS_NO_WRAP_HEIGHT}
                    label={tick.label}
                  />
                  <line
                    x1={yAxisWidth + CHART_PADDING_LEFT}
                    x2={yAxisWidth + CHART_PADDING_LEFT + plotWidth}
                    y1={yScale(tick.value)}
                    y2={yScale(tick.value)}
                    className={styles.gridLine}
                  />
                </g>
              ))}
            </g>

            {/* X Axis and Grid Lines */}
            <g
              data-testid="plot-x-axis"
              className={styles.xAxis}
              transform={`translate(${yAxisWidth + CHART_PADDING_LEFT}, 0)`}
            >
              {xTicks.map((tick, i) => (
                <g key={i}>
                  <text
                    x={xScale(tick.value)}
                    y={plotHeight + CHART_PADDING_BOTTOM + 9}
                    style={{
                      textAnchor: tickAnchor(
                        i,
                        xTicks.length,
                        xScale(tick.value),
                        plotWidth
                      ),
                    }}
                  >
                    {tick.label}
                  </text>
                  <line
                    y1={0}
                    y2={plotHeight}
                    x1={xScale(tick.value)}
                    x2={xScale(tick.value)}
                    className={styles.gridLine}
                  />
                </g>
              ))}
            </g>

            {/* Chart Area */}
            <g
              id="chart"
              ref={chartRef}
              transform={`translate(${yAxisWidth + CHART_PADDING_LEFT}, 0)`}
              {...tooltipController.gProps}
              className={styles.chart}
            >
              {tooltipController.isVisible && tooltipPosition && (
                <TooltipHoverLine x={tooltipPosition.x} height={plotHeight} />
              )}
              <path
                d={areaPath || ''}
                fill="url(#area-gradient)"
                className={styles.area}
                data-testid="volume-chart-area"
              />
              <path
                d={linePath || ''}
                fill="none"
                stroke="#5CA9E9"
                strokeWidth={2}
                className={`${styles.line} line-path`}
              />
              {seriesData.new.map((d, i) => (
                <g key={i}>
                  <rect
                    x={xScaleBar(d.date.getTime().toString())}
                    y={yScale(d.value)}
                    width={barWidth}
                    height={Math.abs(yScale(0) - yScale(d.value))}
                    fill="#70CA97"
                    className={`${styles.bar} added-bar ${
                      highlightedDate === d.date.getTime()
                        ? styles.highlighted
                        : ''
                    }`}
                  />
                </g>
              ))}
              {seriesData.removed.map((d, i) => (
                <g key={i}>
                  <rect
                    x={xScaleBar(d.date.getTime().toString())}
                    y={yScale(0)}
                    width={barWidth}
                    height={Math.abs(yScale(-d.value) - yScale(0))}
                    fill="#D47E93"
                    className={`${styles.bar} removed-bar ${
                      highlightedDate === d.date.getTime()
                        ? styles.highlighted
                        : ''
                    }`}
                  />
                </g>
              ))}
              <rect
                x={0}
                y={0}
                width={plotWidth}
                height={plotHeight}
                fill="transparent"
              />
            </g>
          </g>
        </svg>

        {tooltipPosition && (
          <PlotTooltip
            isVisible={tooltipController.isVisible}
            x={tooltipPosition.x + yAxisWidth}
            y={tooltipPosition.y + CHART_PADDING_BOTTOM + X_AXIS_HEIGHT}
          >
            {tooltipRows && <VolumeTooltip {...tooltipRows} format={format} />}
          </PlotTooltip>
        )}
      </PlotLoadWrapper>
    </div>
  );
};

import { Client, OperationResult } from 'urql';

import {
  GetSchoolInfoQuery,
  GetSchoolInfoQueryVariables,
  TalentDiscoveryAiFilterSearchResponse,
  TalentDiscoveryAiSearchFilterType,
  TalentDiscoveryAiSearchFiltersType,
  TalentDiscoveryAiSearchPreviousCurrentFilterType,
} from '@revelio/data-access';
import {
  CompanyResultItem,
  GEOGRAPHY_GRANULARITY_FILTERS,
  GET_SCHOOL_INFO,
  ROLE_GRANULARITY_FILTERS,
  SchoolItem,
  SelectionCategories,
  SelectionList,
  TreeItem,
  ValidValueTypes,
} from '@revelio/filtering';

import { fetchCompanyMapping } from '../../../../../deliverables/company-selection/company-mapping/upload-companies/file-to-company-mapping';
import {
  TalentDiscoveryFilterStates,
  TalentDiscoveryFreeTextState,
  TalentDiscoverySchoolSearchFilterState,
} from '../../../td-filter-reducer';
import { isFreeTextFilter, isRangeFilter, isTreeFilter } from '../../../types';
import { TREE_FILTER_LIMIT } from '../../tree-filter';
import { TDResponseFilterToSelectionListMap } from './ai-filter-response-mappings';
import { getUnknownFilterExplanation } from './get-unknown-filter-explanation';
import {
  processFilterGroup,
  processFilterGroupWithMapperApiFallback,
} from './process-filter-group';

export const deserialiseApiToFilterState = async (
  response: TalentDiscoveryAiFilterSearchResponse,
  selectionLists: SelectionList<ValidValueTypes>[],
  gqlClient: Client
): Promise<{
  filterState: TalentDiscoveryFilterStates[];
  unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[];
}> => {
  const { filters } = response;

  const unknownFilters: {
    relevantPromptText: string;
    explanation: string;
    name: string;
  }[] = [];

  const filterState = Object.keys(filters).reduce(
    (deserialisedFilters, key, index) => {
      if (isFreeTextFilter(key)) {
        const filterKey =
          key as keyof TalentDiscoveryAiFilterSearchResponse['filters'];
        if (!filters[filterKey]) {
          return deserialisedFilters;
        }

        if (key === 'name') {
          // TODO: probably should check if the name is valid via api search... otherwise add it to unknown filters
          const nameFilterValue = filters[filterKey] as {
            name: string;
            relevantPromptText: string;
          };
          return [
            ...deserialisedFilters,
            {
              id: Date.now().toString() + index,
              name: key,
              text: [nameFilterValue.name],
            },
          ];
        }

        const keywordsFilters = (
          filters[filterKey] as NonNullable<
            TalentDiscoveryAiSearchFiltersType['keywords']
          >
        )?.reduce((multiKeywords, keywordValue, keywordIndex) => {
          return [
            ...multiKeywords,
            {
              id: Date.now().toString() + index + keywordIndex,
              name: key,
              text: keywordValue.map((keyword) => keyword.name),
            },
          ];
        }, [] as TalentDiscoveryFreeTextState[]);

        return [...deserialisedFilters, ...keywordsFilters];
      }

      if (isTreeFilter(key)) {
        const handledTreeFiltersWithMapperApiFallback = ['geography', 'role'];
        if (
          filters[key]?.length === 0 ||
          handledTreeFiltersWithMapperApiFallback.includes(key)
        ) {
          return deserialisedFilters;
        }

        const listsWithCurrentNonCurrent = [
          'skill',
          'industry',
          'seniority',
          'flight_risk',
          'prestige',
          'remote_suitability',
        ];

        const filterSelectionLists = TDResponseFilterToSelectionListMap[key];
        if (filterSelectionLists) {
          const supportedKey =
            key as keyof typeof TDResponseFilterToSelectionListMap;
          const relevantSelectionLists = selectionLists.filter((list) =>
            filterSelectionLists.includes(list.id as SelectionCategories)
          );

          const filterTreeItems = (() => {
            if (['skill'].includes(key)) {
              return (
                filters[supportedKey] as NonNullable<
                  TalentDiscoveryAiSearchFiltersType['skill']
                >
              ).reduce(
                (skillGroups, skillGroup) => {
                  const {
                    processedFilters,
                    unknownFilters: unknownFiltersGroup,
                  } = processFilterGroup({
                    group: skillGroup,
                    relevantSelectionLists,
                    treeFilterKey: key,
                  });
                  unknownFilters.push(...unknownFiltersGroup);

                  if (!Object.keys(processedFilters).length) {
                    return skillGroups;
                  }

                  return [...skillGroups, processedFilters];
                },
                [] as Record<string, TreeItem>[]
              );
            }

            const { processedFilters, unknownFilters: unknownFiltersGroup } =
              processFilterGroup({
                group: filters[
                  supportedKey
                ] as NonNullable<TalentDiscoveryAiSearchFilterType>[],
                relevantSelectionLists,
                treeFilterKey: key,
              });
            unknownFilters.push(...unknownFiltersGroup);

            return [processedFilters];
          })();

          if (
            !filterTreeItems.filter((item) => Object.keys(item).length).length
          ) {
            return deserialisedFilters;
          }

          const newFilters = filterTreeItems.map(
            (treeItems, treeItemsIndex) => ({
              name: key,
              id: Date.now().toString() + index + treeItemsIndex,
              treeItems: Object.fromEntries(
                Object.entries(treeItems).slice(0, TREE_FILTER_LIMIT)
              ),
              ...(listsWithCurrentNonCurrent.includes(key)
                ? {
                    isCurrent:
                      response?.filters[key]?.some(
                        (filter) =>
                          (
                            filter as TalentDiscoveryAiSearchPreviousCurrentFilterType
                          )?.isCurrent
                      ) ?? true,
                  }
                : {}),
            })
          );

          return [...deserialisedFilters, ...newFilters];
        }
      }

      if (isRangeFilter(key)) {
        const filter = filters[key];
        if (
          !filter ||
          (filter.start_value === null && filter.end_value === null)
        ) {
          return deserialisedFilters;
        }

        const { start_value, end_value } = (() => {
          const startValue = filter.start_value;
          const endValue = filter.end_value;

          if (key === 'graduation_year') {
            return {
              start_value:
                startValue !== null
                  ? Math.max(1901, Math.min(9999, startValue))
                  : null,
              end_value:
                endValue !== null
                  ? Math.max(1901, Math.min(9999, endValue))
                  : null,
            };
          }

          return { start_value: startValue, end_value: endValue };
        })();

        const rangeFilter = {
          id: Date.now().toString(),
          name: key,
          isCurrent: filter.isCurrent ?? true,
          ...(start_value !== null && { start_value }),
          ...(end_value !== null && { end_value }),
        };

        return [...deserialisedFilters, rangeFilter];
      }

      return deserialisedFilters;
    },
    [] as TalentDiscoveryFilterStates[]
  );

  if (response.filters.geography?.length) {
    const { processedFilters, unknownFilters: unknownFiltersGroup } =
      await processFilterGroupWithMapperApiFallback({
        group: response.filters.geography as NonNullable<
          TalentDiscoveryAiSearchFiltersType['geography']
        >,
        relevantSelectionLists: selectionLists.filter((list) =>
          GEOGRAPHY_GRANULARITY_FILTERS.includes(list.id as SelectionCategories)
        ),
        treeFilterKey: 'geography',
      });

    unknownFilters.push(...unknownFiltersGroup);

    if (Object.keys(processedFilters).length) {
      filterState.push({
        name: 'geography',
        id: Date.now().toString(),
        isCurrent:
          response.filters.geography.some((geography) => geography.isCurrent) ??
          true,
        treeItems: processedFilters,
      });
    }
  }

  if (response.filters.role?.length) {
    const { processedFilters, unknownFilters: unknownFiltersGroup } =
      await processFilterGroupWithMapperApiFallback({
        group: response.filters.role as NonNullable<
          TalentDiscoveryAiSearchFiltersType['role']
        >,
        relevantSelectionLists: selectionLists.filter((list) =>
          ROLE_GRANULARITY_FILTERS.includes(list.id as SelectionCategories)
        ),
        treeFilterKey: 'role',
      });

    unknownFilters.push(...unknownFiltersGroup);

    if (Object.keys(processedFilters).length) {
      filterState.push({
        name: 'role',
        id: Date.now().toString(),
        isCurrent: response.filters.role.some((role) => role.isCurrent) ?? true,
        treeItems: processedFilters,
      });
    }
  }

  if (response.filters.company?.length) {
    const csvHeader = 'COMPANY_NAME\n';
    const csvContent = response.filters.company
      .map((company) => company.name)
      .join('\n');
    const csvData = csvHeader + csvContent;

    const file = new File([csvData], 'mappedCompanylist.csv', {
      type: 'text/csv',
    });

    const companyMapping = await fetchCompanyMapping(file);
    const verifiedMappings = companyMapping.filter(
      (company) => company.response !== null
    );

    const missingCompanyMappings = companyMapping.filter(
      (company) => company.response === null
    );
    missingCompanyMappings.forEach((mapping) => {
      const missingCompanyName = mapping.request.company_name;
      unknownFilters.push({
        name: missingCompanyName,
        explanation: getUnknownFilterExplanation(missingCompanyName, 'Company'),
        relevantPromptText:
          response.filters.company?.find(
            (company) => company.name === missingCompanyName
          )?.relevantPromptText || '',
      });
    });

    if (verifiedMappings.length) {
      const companyResultItems = verifiedMappings.reduce(
        (filterResultItems, mappedCompany) => {
          return {
            ...filterResultItems,
            [mappedCompany.response.rcid]:
              mappedCompany.response as CompanyResultItem,
          };
        },
        {}
      );

      filterState.push({
        name: 'company',
        isCurrent:
          response.filters.company.some((company) => company.isCurrent) ?? true,
        companyResultItems,
        id: Date.now().toString(),
      });
    }
  }

  if (response.filters.school) {
    const schoolSearchResults = await Promise.all(
      response.filters.school.map(async (school) => {
        const schoolResult = await fetchSchoolSearchResults(gqlClient, {
          name: school.name,
          page: 1,
        });

        if (!schoolResult.data?.schoolInfo?.length) {
          unknownFilters.push({
            name: school.name,
            explanation: getUnknownFilterExplanation(school.name, 'School'),
            relevantPromptText: school.relevantPromptText,
          });
        }

        return schoolResult;
      })
    );

    const validSchoolResults = schoolSearchResults
      .filter((result) => result.data?.schoolInfo?.length)
      .map((result) => result.data?.schoolInfo?.[0]);

    const schoolResultItems = validSchoolResults.reduce(
      (acc, school) => {
        if (!school) return acc;
        return {
          ...acc,
          [school.rsid as string]: {
            label: school.name,
            name: school.name,
            rsid: school.rsid,
            primary_name: school.name,
          } as SchoolItem,
        };
      },
      {} as TalentDiscoverySchoolSearchFilterState['schoolResultItems']
    );

    if (Object.keys(schoolResultItems || {}).length > 0) {
      filterState.push({
        name: 'rsid',
        id: Date.now().toString(),
        schoolResultItems,
      });
    }
  }

  return { filterState, unknownFilters };
};

const normalizeDashes = (str: string): string => {
  return str.replace(/[–—]/g, '-');
};

const stripPunctuation = (str: string): string => {
  return str.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim();
};

const fetchSchoolSearchResults = async (
  gqlClient: Client,
  vars: GetSchoolInfoQueryVariables
): Promise<OperationResult<GetSchoolInfoQuery>> => {
  // First try with normalized dashes
  const normalizedName = normalizeDashes(vars.name);
  const result = await gqlClient
    .query<GetSchoolInfoQuery, GetSchoolInfoQueryVariables>(GET_SCHOOL_INFO, {
      ...vars,
      name: normalizedName,
    })
    .toPromise()
    .catch((e) => {
      console.log('error:', e);
      return e;
    });

  // If no results found, try with all punctuation stripped
  if (!result.data?.schoolInfo?.length) {
    const strippedName = stripPunctuation(normalizedName);
    if (normalizedName !== strippedName) {
      return gqlClient
        .query<GetSchoolInfoQuery, GetSchoolInfoQueryVariables>(
          GET_SCHOOL_INFO,
          {
            ...vars,
            name: strippedName,
          }
        )
        .toPromise()
        .catch((e) => {
          console.log('error:', e);
          return e;
        });
    }
  }

  return result;
};

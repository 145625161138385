import ReactGA from 'react-ga';

import { environment } from '../../environments/environment';

const TRACKING_ID = 'UA-190289577-1';

function init() {
  const isDev = !environment.production;
  ReactGA.initialize(TRACKING_ID, { debug: isDev, anonymize_ip: true });
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

function setUserId(userId) {
  ReactGA.set({ userId });
}

export default {
  init,
  sendEvent,
  sendPageview,
  setUserId,
};

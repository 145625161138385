import { HTMLChakraProps, chakra } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      {...props}
    >
      <path
        d="M2.04381 26.5309V2.2147C2.03943 2.03441 2.07578 1.8555 2.15012 1.69156C2.22446 1.52762 2.33485 1.383 2.47282 1.26868C2.6108 1.15435 2.77275 1.07334 2.94636 1.03184C3.11998 0.990328 3.30064 0.989393 3.47466 1.02912L16.3761 4.02863C16.6407 4.08886 16.8765 4.23991 17.0433 4.45607C17.2101 4.67223 17.2976 4.94014 17.291 5.2142V26.5546"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.291 26.531V9.02002L24.7035 11.5453C24.9364 11.6248 25.1388 11.7764 25.2819 11.9785C25.425 12.1806 25.5017 12.423 25.501 12.6716V26.531"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.61488 7.32446H5.85559"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4791 7.32446H11.7198"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.61488 11.7113H5.85559"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4791 11.7113H11.7198"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2756 16.0975H20.5164"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2756 21.5513H20.5164"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.61488 16.0975H5.85559"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4791 16.0975H11.7198"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49451 19.4175H10.8402C11.1513 19.4175 11.4496 19.5424 11.6696 19.7647C11.8895 19.9871 12.0131 20.2886 12.0131 20.6031V26.5309H7.32166V20.6031C7.32166 20.2886 7.44522 19.9871 7.66518 19.7647C7.88513 19.5424 8.18345 19.4175 8.49451 19.4175Z"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeMiterlimit="10"
      />
      <path
        d="M1 26.531H26.8029"
        stroke="#2D426A"
        strokeWidth="1.42269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="25.5" cy="23.5" r="6.5" fill="#2D426A" />
      <path
        d="M28 21L23 26"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M23 21L28 26"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </chakra.svg>
  );
};

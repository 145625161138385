import { filterNil, select, setProp } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectEntities,
} from '@ngneat/elf-entities';
import { useEffect$, useObservable } from '@ngneat/react-rxjs';
import { map as _map, difference, get } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  distinctUntilKeyChanged,
  map,
  skipUntil,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { AUTH_CHECKED, authStore } from '@revelio/auth';
import { LoadingBox, PagePaths, RevelioGqlClient } from '@revelio/core';
import { PipelineType, PostingFrequency } from '@revelio/data-access';
import { CardListSelectController, ColumnSet } from '@revelio/layout';

import { ClientDetailsQuery } from '../adminRewrite/userOperations';
import DataUnavailableBanner from '../trialUser/dataUnavailableBanner';
import useIsTrialUserWithNoDatasetAccess from '../trialUser/useIsTrialUserWithNoDatasetAccess';
import { createNewEntity, dataSets, getDatasetId } from './data-set.model';
import {
  DATASET_STEP_ID,
  DeliverableStepContainer,
} from './deliverable-step-container';
import { DeliverablePaths } from './deliverables-page';
import {
  CompleteCompanyInfoPipeline,
  CompleteIndividualPipeline,
  CompletePostingPipeline,
  isPostingsPipeline,
} from './deliverables.model';
import {
  deliverablesStore,
  setColumnsToDefault,
  setMandatoryColumns,
  setRecommendCompanyRef,
} from './deliverables.repository';

export const DataSet = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [datasetsEnabledByAccess, setDataSetsWithAccess] = useState(dataSets);

  useEffect$(() =>
    authStore.pipe(
      skipUntil(AUTH_CHECKED),
      select((store) =>
        store.user?.client_name
          ? {
              clientName: store.user?.client_name,
              dataSets: store.dataSets,
            }
          : null
      ),
      filterNil(),
      distinctUntilKeyChanged('clientName'),
      withLatestFrom(RevelioGqlClient),
      switchMap(([{ clientName, dataSets }, client]) => {
        return client
          .query(ClientDetailsQuery, { ids: [clientName] })
          .toPromise()
          .then((res) => {
            const pTypes =
              (res?.data?.clients?.[0]?.data_builder_configuration
                ?.pipeline_type as PipelineType[]) || ([] as PipelineType[]);
            if (pTypes) {
              // set into state to reduce query calls on reloading and coming back to this page
              authStore.update(setProp('dataSets', pTypes));
            }
            return pTypes;
          })
          .catch((err) => []);
      }),
      map((pTypes): ColumnSet<PipelineType>[] => {
        const _pTypes = (pTypes || []).map((type) => {
          return get(
            {
              [PipelineType.SentimentScores]: 'sentiment',
              [PipelineType.Posting]: 'jobpostings',
            },
            type,
            type
          );
        });
        return dataSets.map((dSetsConfig) => {
          const updatedCols = dSetsConfig.columns.map((aSet) => {
            if (!_pTypes.includes(aSet.id as PipelineType)) {
              aSet.isDisabled = true;
            }
            return aSet;
          });
          return {
            ...dSetsConfig,
            columns: updatedCols,
          };
        });
      }),
      tap((finalDataSets) => {
        setDataSetsWithAccess(finalDataSets);
        setLoading(false);
      })
    )
  );

  const [deliverables] = useObservable(
    deliverablesStore.pipe(selectEntities())
  );
  const selectedPipelineTypes = _map(
    deliverables,
    (del) => del?.pipeline.pipeline_type as PipelineType
  ).filter((pipelineType) => pipelineType);

  const isTrialWithNoDatasetAccess = useIsTrialUserWithNoDatasetAccess();

  return (
    <DeliverableStepContainer
      disableFade
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      id={DATASET_STEP_ID}
      submitTooltip={
        isTrialWithNoDatasetAccess &&
        'This feature is not available for trial users'
      }
      title="Select Dataset"
      isSubmitDisabled={!selectedPipelineTypes.length}
      banner={
        isTrialWithNoDatasetAccess && (
          <DataUnavailableBanner featureUnavailable />
        )
      }
      onSubmit={() => {
        navigate(
          `/${PagePaths.DELIVERABLES}/${DeliverablePaths.COMPANY_SELECTION}`
        );
      }}
    >
      <LoadingBox
        key={'datasets-loader'}
        loading={loading}
        backgroundColorOpacity={0.4}
        height={{ base: 'unset' }}
        overflowY={{ base: 'scroll' }}
      >
        <CardListSelectController<PipelineType>
          variant="dataset"
          items={datasetsEnabledByAccess}
          gridProps={{
            spacing: 3,
            columns: 2,
            gridAutoFlow: 'row',
          }}
          // defaultValue={[PipelineType.WfDynam]}
          value={selectedPipelineTypes}
          onChange={(value) => {
            // Adding the dataset
            const newValuesAdded = difference(value, selectedPipelineTypes);
            if (newValuesAdded.length) {
              const selectedPipeline = newValuesAdded[0] as PipelineType;
              const { newEntity, newId } = createNewEntity(
                selectedPipeline,
                deliverables
              );
              if (selectedPipeline === PipelineType.Individual) {
                (
                  newEntity.pipeline as CompleteIndividualPipeline
                ).individual_user_configuration = {
                  current: false,
                  full_history: false,
                };
              }
              if (selectedPipeline === PipelineType.CompanyInfo) {
                (
                  newEntity.pipeline as CompleteCompanyInfoPipeline
                ).company_info_configuration = {
                  include_subsidiaries: false,
                };
                deliverablesStore.update(setRecommendCompanyRef(false));
              }
              if (isPostingsPipeline(selectedPipeline)) {
                (
                  newEntity.pipeline as CompletePostingPipeline
                ).posting_configuration = {
                  frequency: PostingFrequency.Month,
                };
              }
              deliverablesStore.update(addEntities(newEntity));

              setMandatoryColumns({ entityId: newId });
              setColumnsToDefault({ entityId: newId });
            }

            // removing the dataset
            const newValuesRemoved = difference(
              selectedPipelineTypes,
              value
            ) as PipelineType[];
            if (newValuesRemoved.length) {
              const removedPipelineType = newValuesRemoved[0] as PipelineType;
              const removePipelineTypeId = getDatasetId(removedPipelineType);
              deliverablesStore.update(
                deleteEntities([
                  removePipelineTypeId,
                  removePipelineTypeId.toString(),
                ])
              );
            }
          }}
        />
      </LoadingBox>
    </DeliverableStepContainer>
  );
};

import { UseToastOptions } from '@chakra-ui/react';
import pluralize from 'pluralize';

export const createMaxSelectionsToast = (limit: number): UseToastOptions => ({
  id: 'max-selections-toast',
  position: 'top-right',
  status: 'warning',
  title: `Selection Limit Reached`,
  description: `You can select up to ${limit} ${pluralize('item', limit)} at a time.`,
  isClosable: true,
  variant: 'subtle',
});

export const createMinSelectionsToast = (
  required: number
): UseToastOptions => ({
  id: 'min-selections-toast',
  position: 'top-right',
  status: 'warning',
  title: `Selection Required`,
  description: `Please select at least ${required} ${pluralize('item', required)}.`,
  isClosable: true,
  variant: 'subtle',
});

export const createMinSelectionsTooltip = (required: number): string =>
  required > 0
    ? `Please select at least ${required} ${pluralize('item', required)}`
    : '';

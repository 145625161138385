import { Flex } from '@chakra-ui/layout';
import { TypeDataGridProps } from '@inovua/reactdatagrid-community/types';

import { Loading } from './loading';

export const ReactDataGridLoading: TypeDataGridProps['renderLoadMask'] = ({
  visible,
}) => {
  return visible ? (
    <Flex
      borderRadius="10px"
      height="full"
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
      backgroundColor={'white'}
      alignItems={'center'}
    >
      <Loading></Loading>
    </Flex>
  ) : null;
};

import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, FormatType } from '@revelio/replots';

import { PlotCard, convertBarToSheet } from '../../../shared/components';
import { RoleSummaryMetric } from '../types';
import { getBarChartData, getBarTooltip } from '../utils';
import { getRoleCompositionData } from './utils/get-role-composition-data';

interface RoleSummaryTopGeographiesProps {
  data: CompositionDataQuery | undefined;
}

export const RoleSummaryTopGeographies = ({
  data,
}: RoleSummaryTopGeographiesProps) => {
  const barData = (() => {
    const compositionData = getRoleCompositionData(data);
    const chartData = compositionData[RoleSummaryMetric.Geographies]?.[0];
    return getBarChartData(chartData);
  })();

  return (
    <PlotCard
      title="Top Geographies"
      info={<div>Geographic distribution of this role</div>}
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertBarToSheet({
        data: barData,
        dimensionName: 'Geography',
        metricName: 'Percentage',
      })}
    >
      <BarChart
        data={barData}
        loading={!data}
        format={FormatType.PERCENTAGE}
        tooltipContent={getBarTooltip}
      />
    </PlotCard>
  );
};

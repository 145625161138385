import classNames from 'classnames';
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Tree } from 'react-arborist';

import { DrillMode, Node } from './Node';
import styles from './TreeSelection.module.css';
import { TreeApi, TreeApiProps, useTreeApi } from './useTreeApi';
import { getSuggestedSortedData, searchMatch } from './utils';

export type TreeSelectionComponentProps = {
  treeApi: TreeApi;
  noSearchResults?: ReactNode;
  drillMode?: DrillMode;
  sortSuggestedSearch?: boolean;
  disabled?: boolean | number[];
  height?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export type TreeSelectionApiRequiredProps = Pick<
  TreeApiProps,
  'data' | 'openByDefault'
> &
  TreeSelectionComponentProps;

const TreeSelectionApiRequired = ({
  data,
  treeApi,
  openByDefault = false,
  onClick,
  drillMode,
  sortSuggestedSearch = false,
  disabled,
  height = 220,
  noSearchResults = 'No Results',
  className,
  style,
}: TreeSelectionApiRequiredProps) => {
  const {
    treeRef,
    selectedIds,
    indeterminateSelectedIds,
    toggleNodeSelection,
    search,
  } = treeApi;

  const [noResults, setNoResults] = useState(false);
  useEffect(() => {
    const tree = treeRef.current;

    const numVisibleNodes = tree?.visibleNodes.length || 0;
    setNoResults(search.length > 0 && numVisibleNodes === 0);
  }, [search, treeRef]);

  const initialSelectionState = useRef<{
    selectedIds: string[];
    indeterminateIds: string[];
  }>({
    selectedIds: [...selectedIds],
    indeterminateIds: [...indeterminateSelectedIds],
  });

  useEffect(() => {
    initialSelectionState.current = {
      selectedIds: [...selectedIds],
      indeterminateIds: [...indeterminateSelectedIds],
    };
  }, [selectedIds, indeterminateSelectedIds]);

  const suggestedSortedData = useMemo(() => {
    if (sortSuggestedSearch && search.length > 0) {
      return getSuggestedSortedData({ data: [...data], search });
    }

    const isMarked = (id: string) =>
      initialSelectionState.current.selectedIds.includes(id) ||
      initialSelectionState.current.indeterminateIds.includes(id);

    return [...data].sort((a, b) => {
      const aIsOther = a.name === 'Other';
      const bIsOther = b.name === 'Other';
      if (aIsOther !== bIsOther) {
        return aIsOther ? 1 : -1;
      }

      const aMarked = isMarked(a.id);
      const bMarked = isMarked(b.id);
      if (aMarked !== bMarked) {
        return aMarked ? -1 : 1;
      }

      return data.indexOf(a) - data.indexOf(b);
    });
  }, [data, search, sortSuggestedSearch]);

  return (
    <div
      className={classNames(styles.treeSelectionContainer, className)}
      style={style}
      onClick={onClick}
    >
      {noResults && (
        <div className={styles.noSearchText}>{noSearchResults}</div>
      )}

      <Tree
        data={suggestedSortedData}
        className={styles.tree}
        rowClassName={styles.row}
        {...((!drillMode || drillMode?.isRoot) && { ref: treeRef })}
        searchTerm={search}
        searchMatch={searchMatch}
        openByDefault={openByDefault}
        width="100%"
        height={height}
      >
        {(props) => {
          const isDisabled = (() => {
            if (typeof disabled === 'boolean') return disabled;
            if (Array.isArray(disabled)) {
              return disabled.includes(props.node.level);
            }
            return props.node.data.disabled;
          })();
          return (
            <Node
              {...props}
              selected={selectedIds.includes(props.node.id)}
              halfCheck={indeterminateSelectedIds.includes(props.node.id)}
              disabled={isDisabled}
              onToggle={toggleNodeSelection}
              drillMode={drillMode}
            />
          );
        }}
      </Tree>
    </div>
  );
};

/** TreeSelection Internal
 * treeApi is not provided
 * This is used when the TreeSelection component defines its own treeApi.
 * The treeApi can still be exposed to its parent via a ref */
type TreeSelectionInternalApiProps = TreeApiProps &
  Omit<TreeSelectionComponentProps, 'treeApi'> & { treeApi: undefined };
const TreeSelectionInternalApi = forwardRef(
  (props: TreeSelectionInternalApiProps, ref: Ref<TreeApi | undefined>) => {
    const treeApi = useTreeApi(props);

    useImperativeHandle(ref, () => treeApi);

    return <TreeSelectionApiRequired {...props} treeApi={treeApi} />;
  }
);

/** TreeSelection External
 * treeApi provided
 * This is used when the table api should be controlled externally, and when
 * multiple trees are used in the same component, but share a single treeApi
 * (ex. the PopoutTree) */
type TreeSelectionExternalApiProps = TreeApiProps & TreeSelectionComponentProps;
const TreeSelectionExternalApi = (props: TreeSelectionExternalApiProps) => {
  return <TreeSelectionApiRequired {...props} />;
};

/** Exported TreeSelection
 * takes in props for both internal and external treeApi */
export type TreeSelectionProps =
  | TreeSelectionInternalApiProps
  | TreeSelectionExternalApiProps;
export const TreeSelection = forwardRef(
  (props: TreeSelectionProps, ref: Ref<TreeApi | undefined>) => {
    if (props.treeApi) {
      return <TreeSelectionExternalApi {...props} />;
    } else {
      return <TreeSelectionInternalApi {...props} ref={ref} />;
    }
  }
);

import { Text } from '@chakra-ui/layout';

import { D3ChartNames } from '@revelio/d3';
import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, convertLineToSheet } from '../../../shared/components';

interface PostingsTimeToFillLineChartCardProps {
  data: LineData[];
  loading?: boolean;
  colorMap: Record<string, string>;
}

export const PostingsTimeToFillLineChartCard = ({
  data,
  loading,
  colorMap,
}: PostingsTimeToFillLineChartCardProps) => {
  const dataWithColors = data.map((line) => ({
    ...line,
    color: colorMap[line.label],
  }));

  return (
    <PlotCard
      title="Time to Fill"
      info={
        <Text variant="tooltip">
          The average number of days that job postings are active before being
          removed.
        </Text>
      }
      infoPlacement="top-end"
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      exportData={convertLineToSheet({ data })}
      plotShare={{
        data: dataWithColors,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: {
            format: FormatType.INTEGER,
            dateFormat: 'day',
          },
        },
      }}
    >
      <LineChart
        data={dataWithColors}
        showGridLines={true}
        format={FormatType.INTEGER}
        dateFormat="day"
        loading={loading}
      />
    </PlotCard>
  );
};

import { MetadataKey, Tab, TrialType, User } from '@revelio/data-access';

import { AuthEventIds, emitAuthEvent, handleAuthResponse } from './auth';

export const fetchWithToken = (url: string, opt: RequestInit) => {
  const defaultOptions = {
    method: 'GET',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(url, { ...defaultOptions, ...opt });
};
type IsResponseOkArgs = {
  response: Response;
  onSuccess?: (responseData: unknown) => void;
};
export const isResponseOk = async ({
  response,
  onSuccess,
}: IsResponseOkArgs) => {
  if (response.status >= 200 && response.status <= 299) {
    let responseData = null;
    if (response.status !== 204) {
      // this happens on DELETE
      responseData = await response.json();
    }
    onSuccess && onSuccess(responseData);
    return responseData || response; // in the case of no data (i.e. 204)
  } else if (response.status >= 401 && response.status <= 499) {
    handleAuthResponse(response);
  } else if (response.status >= 500 && response.status <= 599) {
    throw new Error('Oops! An Error Occurred.');
  } else {
    throw Error(response.statusText);
  }
  return { ok: false };
};

export const response429 = () => {
  emitAuthEvent({ id: AuthEventIds.USER_LOGOUT });
};

export const isValidEmail = (email: string): boolean => {
  return !!/\S+@\S+\.\S+/.test(email || '') && !email.includes(' ');
};

export const isValidEmailOrUsername = (input: string): boolean => {
  if (!input.includes('@') && !input.includes(' ')) {
    return true;
  }
  return isValidEmail(input);
};

export const isValidFirstAndLast = (name: string) => {
  if (!name) return false;
  return name.split(' ').length >= 2;
};

export const hasUppercaseLetter = (password: string): boolean => {
  return !!password.match(/[A-Z]/g);
};

export const hasNumberOrSpecialCharacter = (password: string): boolean => {
  return !!password.match(/[0-9]|(?=.*?[#?!@$%^&*-:()])/g);
};

export const isValidPassword = (password: string): boolean => {
  return !!(
    password.length > 7 &&
    hasNumberOrSpecialCharacter(password) &&
    hasUppercaseLetter(password)
  );
};

export const isUserTrialExpired = (user: User) => {
  if (user.live) return false;
  const expirationDate = user?.metadata?.find(
    (m) => m?.key === MetadataKey.ExpirationDate
  )?.value;
  if (!expirationDate) return false;
  return new Date(expirationDate) < new Date();
};

export const getUserTrialType = (user: User): TrialType | false => {
  if (user.live) return false;
  return (
    (user?.metadata?.find((m) => m?.key === MetadataKey.TrialType)
      ?.value as TrialType) || TrialType.ONE_YEAR_DATA_LAG
  );
};

export const hasUserCompletedTourAndToS = (user: User) => {
  const { tour, tos } = user?.metadata?.reduce(
    (result, m) => {
      if (m?.key === MetadataKey.TourCompleted) {
        result.tour = m.value == 'true';
      }

      if (m?.key === MetadataKey.TermsAccepted) {
        result.tos = m.value == 'true';
      }

      return result;
    },
    { tour: false, tos: false }
  ) || { tour: false, tos: false };

  return tour && tos;
};

export const overwriteTDV2Tab = (user: User) => {
  // Tab.TalentDiscoveryV2 is a deprecated tab and eventually will be migrated
  // so we need to overwrite it to Tab.TalentDiscovery on the FE so users only with talent_discovery_v2 don't lose access to the page
  const userWithCorrectTabs = (() => {
    if (user?.tabs?.includes(Tab.TalentDiscoveryV2)) {
      return {
        ...user,
        tabs: [
          ...(user.tabs?.filter((tab) => tab !== Tab.TalentDiscoveryV2) || []),
          Tab.TalentDiscovery,
        ],
      };
    }

    return user;
  })();

  return userWithCorrectTabs;
};

import { FeatureFlag } from '@revelio/core';

export const allFlags = () => {
  return Object.values(FeatureFlag);
};

export const allFalseFlags = () => {
  const allFlags = Object.values(FeatureFlag);
  return allFlags.reduce(
    (acc, flag) => {
      acc[flag] = false;
      return acc;
    },
    {} as Record<FeatureFlag, boolean>
  );
};

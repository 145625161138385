import { get } from 'lodash';

import { NodeData } from '../Node';
import { TreeData } from '../useTreeApi';
import { isDirectMatchString } from './search';

type GetSuggestedSortedDataProps = {
  data: TreeData[];
  search: string;
};

type NodeDataSuggested = NodeData & {
  isSuggestedResult?: boolean;
};
type TreeDataSuggested = NodeDataSuggested & {
  children?: TreeDataSuggested[];
};

export const getSuggestedSortedData = ({
  data,
  search,
}: GetSuggestedSortedDataProps): TreeDataSuggested[] => {
  const filteredData = data.map((item): TreeDataSuggested => {
    const isDirectMatch = isDirectMatchString(item.name, search);
    const isSuggestedResult =
      !isDirectMatch &&
      item.suggestedTitles?.some((title) => isDirectMatchString(title, search));

    if (item.children) {
      return {
        ...item,
        isSuggestedResult,
        children: getSuggestedSortedData({ data: item.children, search }),
      };
    } else {
      return {
        ...item,
        isSuggestedResult,
      };
    }
  });

  const sortedFilteredData = filteredData.sort((a, b) => {
    const aIsSuggested = get(a, 'isSuggestedResult', false);
    const bIsSuggested = get(b, 'isSuggestedResult', false);
    const aIsTopLevel = !get(a, 'parent');
    const bIsTopLevel = !get(b, 'parent');
    const aIsDirectMatch = isDirectMatchString(a.name, search);
    const bIsDirectMatch = isDirectMatchString(b.name, search);

    if (aIsTopLevel !== bIsTopLevel) {
      return aIsTopLevel ? -1 : 1;
    }

    if (aIsDirectMatch !== bIsDirectMatch) {
      return aIsDirectMatch ? -1 : 1;
    }

    if (aIsSuggested !== bIsSuggested) {
      return aIsSuggested ? -1 : 1;
    }

    return data.indexOf(a) - data.indexOf(b);
  });

  return sortedFilteredData;
};

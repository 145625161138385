import React from 'react';
import { Box, Tooltip, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { OutageStatus } from '../healthCheck';
import { TimelineDayProps } from '../types';
import { HealthCheckResult } from '@revelio/data-access';
import { DailyBreakdown } from './DailyBreakdown';

export const TimelineDay: React.FC<TimelineDayProps> = ({
  status,
  date,
  failureCount,
  totalChecks,
  avgResponseTime,
  results,
  isOverall = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getBackgroundColor = (status: OutageStatus | null) => {
    if (status === null) return 'gray.100';
    switch (status) {
      case 'major_outage':
        return 'red.500';
      case 'partial_outage':
        return 'yellow.400';
      case 'operational':
        return 'green.400';
      default:
        return 'gray.100';
    }
  };

  const formatTooltipDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  const calculateIndividualOutageDuration = (
    dayResults: HealthCheckResult[]
  ) => {
    if (!dayResults?.length) return { hours: 0, minutes: 0 };

    // Sort results by timestamp
    const sortedResults = [...dayResults].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    let outageMinutes = 0;
    let lastResult = sortedResults[0];

    // For each result after the first one
    for (let i = 1; i < sortedResults.length; i++) {
      const currentResult = sortedResults[i];

      // If the last result was a failure, count all time until this result as outage
      if (lastResult.status === 'failure') {
        const minutesBetween =
          (new Date(currentResult.timestamp).getTime() -
            new Date(lastResult.timestamp).getTime()) /
          (1000 * 60);
        outageMinutes += minutesBetween;
      }

      lastResult = currentResult;
    }

    // If the last result was a failure, count until now for today, or end of day for past days
    if (lastResult.status === 'failure') {
      const now = new Date();
      const isToday = date.toDateString() === now.toDateString();
      const endTime = isToday
        ? now
        : new Date(date.getTime()).setHours(23, 59, 59, 999);
      const minutesUntilEnd =
        (new Date(endTime).getTime() -
          new Date(lastResult.timestamp).getTime()) /
        (1000 * 60);
      outageMinutes += minutesUntilEnd;
    }

    return {
      hours: Math.floor(outageMinutes / 60),
      minutes: Math.round(outageMinutes % 60),
    };
  };

  const calculateOverallOutageDuration = (dayResults: HealthCheckResult[]) => {
    if (!dayResults?.length) return { hours: 0, minutes: 0 };

    // Sort results by timestamp
    const sortedResults = [...dayResults].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    // Group into batches (5 second threshold)
    const BATCH_THRESHOLD_MS = 5000;
    const batches: { timestamp: Date; hasFailure: boolean }[] = [];
    let currentBatch: HealthCheckResult[] = [];
    let lastTimestamp: Date | null = null;

    sortedResults.forEach((result) => {
      const currentTimestamp = new Date(result.timestamp);

      if (
        lastTimestamp &&
        currentTimestamp.getTime() - lastTimestamp.getTime() >
          BATCH_THRESHOLD_MS
      ) {
        if (currentBatch.length > 0) {
          batches.push({
            timestamp: new Date(currentBatch[0].timestamp),
            hasFailure: currentBatch.some((r) => r.status === 'failure'),
          });
        }
        currentBatch = [];
      }

      currentBatch.push(result);
      lastTimestamp = currentTimestamp;
    });

    // Process the last batch
    if (currentBatch.length > 0) {
      batches.push({
        timestamp: new Date(currentBatch[0].timestamp),
        hasFailure: currentBatch.some((r) => r.status === 'failure'),
      });
    }

    let outageMinutes = 0;

    // Calculate outage time between batches
    for (let i = 0; i < batches.length - 1; i++) {
      const currentBatch = batches[i];
      const nextBatch = batches[i + 1];

      if (currentBatch.hasFailure) {
        const minutesBetween =
          (nextBatch.timestamp.getTime() - currentBatch.timestamp.getTime()) /
          (1000 * 60);
        outageMinutes += minutesBetween;
      }
    }

    // If the last batch had a failure, count until now for today, or end of day for past days
    if (batches.length > 0 && batches[batches.length - 1].hasFailure) {
      const now = new Date();
      const isToday = date.toDateString() === now.toDateString();
      const endTime = isToday
        ? now
        : new Date(date.getTime()).setHours(23, 59, 59, 999);
      const minutesUntilEnd =
        (new Date(endTime).getTime() -
          batches[batches.length - 1].timestamp.getTime()) /
        (1000 * 60);
      outageMinutes += minutesUntilEnd;
    }

    return {
      hours: Math.floor(outageMinutes / 60),
      minutes: Math.round(outageMinutes % 60),
    };
  };

  const getStatusLabel = (
    status: OutageStatus | null,
    failureCount: number,
    avgResponseTime?: number
  ) => {
    if (status === null) {
      return {
        status: 'No Data Available',
        details: undefined,
      };
    }

    let outage = null;
    if (results) {
      outage = isOverall
        ? calculateOverallOutageDuration(results)
        : calculateIndividualOutageDuration(results);
    }

    let statusText = '';
    let details = '';

    switch (status) {
      case 'operational':
        statusText = 'Operational';
        break;
      case 'partial_outage':
      case 'major_outage':
        statusText =
          status === 'major_outage' ? 'Major Outage' : 'Partial Outage';
        if (outage) {
          details = `${failureCount} failures (${outage.hours}h ${outage.minutes}m of outage)`;
        } else {
          details = `${failureCount} failures`;
        }
        break;
      default:
        statusText = 'Unknown Status';
    }

    return {
      status: statusText,
      details,
      avgResponseTime: avgResponseTime
        ? `Avg Response Time: ${avgResponseTime.toFixed(0)}ms`
        : undefined,
    };
  };

  const tooltipInfo = getStatusLabel(status, failureCount, avgResponseTime);

  return (
    <>
      <Tooltip
        label={
          <VStack align="flex-start" spacing={0}>
            <Text color="gray.100">
              {formatTooltipDate(date)} - {tooltipInfo.status}
            </Text>
            {tooltipInfo.details && (
              <Text color="gray.100">{tooltipInfo.details}</Text>
            )}
            {tooltipInfo.avgResponseTime && (
              <Text color="gray.100">{tooltipInfo.avgResponseTime}</Text>
            )}
          </VStack>
        }
        placement="top"
      >
        <Box
          w="6px"
          h="20px"
          bg={getBackgroundColor(status)}
          borderRadius="1px"
          cursor="pointer"
          transition="all 0.2s"
          _hover={{ opacity: 0.8 }}
          onClick={onOpen}
        />
      </Tooltip>

      {results && (
        <DailyBreakdown
          isOpen={isOpen}
          onClose={onClose}
          date={date}
          results={results}
          isOverall={isOverall}
        />
      )}
    </>
  );
};

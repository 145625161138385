import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Grid } from '@chakra-ui/layout';
import { Card, CardBody } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import Split from 'react-split-grid';

import { useTalentDiscoveryFilter } from './filters/td-filter-provider';

export const SplitPane = ({
  leftContent,
  rightContent,
}: {
  leftContent: ReactNode;
  rightContent: ReactNode;
}) => {
  const { state } = useTalentDiscoveryFilter();

  const numOfFilterSelections = Object.keys(state.filters || {}).length;

  const [colSizes, setColSizes] = useState({ left: 0, right: 2 });
  const [showResizer, setShowResizer] = useState(numOfFilterSelections > 0);

  useEffect(() => {
    if (numOfFilterSelections === 0) {
      setColSizes({ left: 0, right: 2 });
      setShowResizer(false);
    } else {
      setColSizes({ left: 1, right: 1 });
      setShowResizer(true);
    }
  }, [numOfFilterSelections]);

  const handleLeftCollapse = () => {
    setColSizes((prevState) => {
      if (prevState.left > 1) return { left: 1, right: 1 };
      else return { left: 0, right: 2 };
    });
  };

  const handleRightCollapse = () => {
    setColSizes((prevState) => {
      if (prevState.right > 1) return { left: 1, right: 1 };
      else return { left: 2, right: 0 };
    });
  };

  const gridTemplateColumns = `${colSizes.left}fr ${showResizer ? 16 : 0}px ${colSizes.right}fr`;

  return (
    <Split
      onDrag={(_, __, colValues) => {
        const [leftCol, , rightCol] = colValues.split(' ');
        const leftValue = parseFloat(leftCol.replace('fr', ''));
        const rightValue = parseFloat(rightCol.replace('fr', ''));
        setColSizes({ left: leftValue, right: rightValue });
      }}
      render={({ getGutterProps }) => (
        <Grid
          flexGrow={1}
          width="full"
          height="full"
          gridTemplateColumns={gridTemplateColumns}
        >
          <Card variant="unstyled" borderRadius="10px">
            <CardBody padding={4}>{colSizes.left > 0 && leftContent}</CardBody>
          </Card>
          <Flex
            {...getGutterProps('column', 1)}
            width="16px"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
            opacity={0.6}
            cursor="ew-resize"
          >
            {colSizes.left > 0 && (
              <ChevronLeftIcon
                boxSize={6}
                cursor="pointer"
                onClick={handleLeftCollapse}
              />
            )}
            {colSizes.right > 0 && (
              <ChevronRightIcon
                boxSize={6}
                cursor="pointer"
                onClick={handleRightCollapse}
              />
            )}
          </Flex>
          <Card variant="unstyled" borderRadius="10px">
            <CardBody padding={4}>{rightContent}</CardBody>
          </Card>
        </Grid>
      )}
    ></Split>
  );
};

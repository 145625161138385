import { CompositionSummaryDataQuery } from '@revelio/data-access';
import { BarData, Format, FormatType, PRIMARY_COLOR } from '@revelio/replots';

import { CompositionMetricValue } from '../../types';
import { getNormalizedCompositionData } from '../../utils/get-normalized-composition-data';

const HIGHLIGHT_COLOR = '#5bd992';

type GetBarChartDataProps = {
  data: CompositionSummaryDataQuery;
  metricName: CompositionMetricValue;
  selectedEntityId: number;
};
export const getBarChartData = ({
  data,
  metricName,
  selectedEntityId,
}: GetBarChartDataProps): BarData[] => {
  const normalizedData = getNormalizedCompositionData({ data, metricName });

  return normalizedData
    .map((d): BarData | null => {
      const lastValue = d.timeseries[d.timeseries.length - 1]?.value;
      if (!lastValue) return null;

      return {
        label: d.dimension,
        value: lastValue,
        color: d.id === selectedEntityId ? HIGHLIGHT_COLOR : PRIMARY_COLOR,
      };
    })
    .filter((d) => d !== null)
    .sort((a, b) => (b?.value ?? 0) - (a?.value ?? 0));
};

export const getBarMetricFormat = (
  metricName: CompositionMetricValue
): Format => {
  switch (metricName) {
    case 'headcount':
      return FormatType.SI;
    case 'attrition_rate':
    case 'growth_rate':
    case 'hiring_rate':
      return FormatType.PERCENTAGE;
  }
};

import { Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { FormatType } from '../../types';
import { getFormatter } from '../../utilities';
import styles from './sankey-chart.module.css';
import { SankeyNodeValidated } from './types';

type SankeyNodeTooltipProps = PropsWithChildren & {
  node: SankeyNodeValidated;
};
export const SankeyNodeTooltip = ({
  node,
  children,
}: SankeyNodeTooltipProps) => {
  return (
    <Tooltip
      label={<TooltipContent node={node} />}
      placement="top"
      padding={'8px 12px'}
      hasArrow
      data-testid={`sankey-chart__node-tooltip`}
    >
      {children}
    </Tooltip>
  );
};

const format = getFormatter(FormatType.INTEGER);

const TooltipContent = ({ node }: { node: SankeyNodeValidated }) => {
  return (
    <div className={styles.tooltipContent}>
      {node.id}: {format(node?.value ?? 0)}
    </div>
  );
};

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  Switch,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { GroupBase, MultiValue, Select } from 'chakra-react-select';
import { useMemo } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FormState,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import { useIsRevelioAdmin } from '@revelio/auth';
import {
  Client,
  CustomTaxonomyEnum,
  PipelineType,
  PostingSource,
  ReportTypeEnum,
  Tab,
} from '@revelio/data-access';
import {
  CustomTaxonomyOptions,
  defaultCustomTaxonomyOption,
} from '@revelio/filtering';
import { FieldGroup } from '@revelio/layout';

import styles from './long-form.module.css';
import {
  COMPANY_INFO_DATASET_OPTION,
  OptionValues,
  PipelineSelectOptions,
  ReportSelectOptions,
  defaultPipelineOptions,
  defaultTabOptions,
  mapServerReportResponseToSelectOptions,
  mapServerResponseToSelectOptions,
  pipelineOptions,
  tabOptions,
} from './utils/helpers';

export type TabSelectOption = { label: string; value: Tab; default?: boolean };
export type PipelineSelectOption = {
  label: string;
  value: string;
  default?: boolean;
  preventAdditions?: boolean;
};
export type ReportSelectOption = {
  label: string;
  value: string;
  default?: boolean;
  preventAdditions?: boolean;
  isFixed?: boolean;
};
export type PostingSourceSelectOption = {
  label: string;
  value: PostingSource;
  default?: boolean;
};
export type ClientFormValues = {
  active: boolean;
  tabs: TabSelectOption[];
  live: boolean;
  linkup_postings: boolean;
  num_seats: string;
  client_name: string;
  report_types: ReportSelectOptions;
  pipeline_types: PipelineSelectOptions;
  posting_sources: PostingSourceSelectOption[];
  s3_location: string;
  snowflake_database: string;
  snowflake_schema: string;
  custom_taxonomy: { label: string; value: CustomTaxonomyEnum };
};

type Props = {
  clientToEdit?: Client | null;
  reportOptions?: ReportSelectOption[];
  title?: string;
  description?: string;
  register: UseFormRegister<ClientFormValues> | (() => object);
  control?: Control<ClientFormValues, object>;
  watch: UseFormWatch<ClientFormValues>;
  errors?: FormState<ClientFormValues>['errors'];
  actions?: JSX.Element;
  readonly?: boolean;
};

const ClientForm = ({
  clientToEdit,
  reportOptions,
  title,
  description,
  register,
  control,
  watch,
  errors,
  actions,
  readonly,
}: Props) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();

  const isAddingNewClient = clientToEdit === null;

  const initialPipelines: PipelineSelectOptions =
    clientToEdit && !isAddingNewClient
      ? mapServerResponseToSelectOptions(
          (clientToEdit as Client).data_builder_configuration
            ?.pipeline_type as PipelineType[],
          (clientToEdit as Client).data_builder_configuration
            ?.posting_source as PostingSource[],
          pipelineOptions
        )
      : [];

  const initialReportTypes: ReportSelectOptions = useMemo(() => {
    if (clientToEdit && !isAddingNewClient && reportOptions) {
      return mapServerReportResponseToSelectOptions(
        (clientToEdit as Client).reports_configuration
          ?.report_types as ReportTypeEnum[],
        reportOptions
      );
    }

    return [];
  }, [clientToEdit, isAddingNewClient, reportOptions]);

  const hasDeliverablesTab = watch('tabs', [])?.some(
    (tab) => tab?.value === 'deliverables'
  );

  const hasReportsTab = watch('tabs', [])?.some(
    (tab) => tab?.value === 'reports'
  );

  const isNewClientLive = watch('live'); // when adding a new client, there's no clientToEdit and so need this for databuilder defaults
  const toast = useToast();

  const onChangeTabs =
    (field: ControllerRenderProps<ClientFormValues, 'tabs'>) =>
    (selectedOptions: MultiValue<OptionValues>) => {
      const selectedLabels = selectedOptions.map((option) => option.label);
      const hasSentiment = selectedLabels.includes('Sentiment');
      const hasSentimentV1 = selectedLabels.includes('Sentiment_V1');

      if (hasSentiment && hasSentimentV1) {
        toast({
          title: 'Tab conflict',
          description:
            'You cannot add Sentiment and Sentiment_V1 at the same time.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      field.onChange(selectedOptions);
    };

  return (
    <Stack
      className="client-form"
      justifyContent={'space-between'}
      divider={<StackDivider />}
      spacing="2"
      key={clientToEdit?.client_name}
    >
      <Box overflow="scroll" className={styles.contentContainer}>
        <FieldGroup title={title} description={description}>
          <VStack spacing="2">
            {isRevelioAdmin && !isAddingNewClient && (
              <FormControl id="active">
                <FormLabel fontSize="sm" fontWeight="semibold">
                  Active
                </FormLabel>
                <Flex>
                  <FormLabel fontSize="sm">Off</FormLabel>
                  <Switch
                    id="active"
                    isDisabled={!isRevelioAdmin || readonly}
                    colorScheme="green"
                    defaultChecked={clientToEdit?.active}
                    {...register('active')}
                  />
                  <FormLabel fontSize="sm" ml={3}>
                    On
                  </FormLabel>
                </Flex>
              </FormControl>
            )}
            {(isRevelioAdmin || clientToEdit?.num_seats !== '0') && (
              <FormControl id="num_seats" mt="2">
                <FormLabel fontSize="sm" fontWeight="semibold" flexShrink="0">
                  Number of seats
                </FormLabel>
                <Input
                  type="number"
                  maxWidth={20}
                  isDisabled={!isRevelioAdmin || readonly}
                  defaultValue={clientToEdit?.num_seats || '0'}
                  {...register('num_seats')}
                />
              </FormControl>
            )}

            <FormControl id="tabs" mt="2">
              <FormLabel fontSize="sm" fontWeight="semibold">
                Tabs
              </FormLabel>
              <Controller
                name="tabs"
                control={control}
                defaultValue={
                  !isAddingNewClient && clientToEdit?.tabs?.length
                    ? (clientToEdit.tabs.map((tab) =>
                        tabOptions.find((o) => o.value === tab)
                      ) as TabSelectOption[])
                    : defaultTabOptions
                }
                render={({ field }) => (
                  <Select<OptionValues, true, GroupBase<OptionValues>>
                    {...field}
                    isDisabled={!isRevelioAdmin || readonly}
                    isClearable={isRevelioAdmin && !readonly} // disabling removal of tabs
                    isMulti
                    name="tabs"
                    options={tabOptions}
                    placeholder="Select tabs..."
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => 'No Tabs'}
                    defaultValue={defaultTabOptions}
                    maxMenuHeight={120}
                    onChange={onChangeTabs(field)}
                    chakraStyles={{
                      control: (provided) => ({
                        ...provided,
                        height: 'auto',
                        backgroundColor: 'white', // Set the background color for the control
                        borderColor: 'gray.300', // Set the border color
                        boxShadow: 'sm', // Apply a slight shadow (optional)
                        '&:hover': {
                          borderColor: 'gray.400', // Darker border on hover
                        },
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: 'blue.100', // Background for the chips
                        borderColor: 'blue.300', // Border color for the chips
                        borderWidth: '1px',
                        borderRadius: 'md', // Optional: rounded corners for the chips
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: 'blue.800', // Text color for the chips
                      }),
                    }}
                  />
                )}
              />
            </FormControl>

            {/* REPORT BUILDER FIELDS */}
            {hasReportsTab && (
              <Stack
                display={readonly ? 'none' : 'inherit'}
                width={'100%'}
                borderWidth="1px"
                borderRadius="md"
                p="2"
                mt="2"
                boxShadow="sm"
                color="gray.800"
              >
                <Heading as="h5" size="sm">
                  Report Builder Configuration
                </Heading>
                <FormControl id="pipeline_types" mt="2">
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Report Types
                  </FormLabel>
                  <Controller
                    name="report_types"
                    control={control}
                    rules={{
                      validate: (value) => {
                        // NOTE: allow trial clients to have reports tab but no access to anything "read only mode"
                        if (!clientToEdit?.live) {
                          return true;
                        }
                        if (
                          value === undefined &&
                          initialReportTypes.length === 0
                        ) {
                          return 'At least one report type is required.';
                        }

                        if (value !== undefined && value.length === 0) {
                          return 'At least one report type is required.';
                        }

                        return true;
                      },
                    }}
                    defaultValue={
                      initialReportTypes.length > 0
                        ? initialReportTypes
                        : reportOptions?.filter((option) => option.default)
                    }
                    render={({ field, fieldState }) => {
                      const addableOptions = reportOptions?.filter(
                        (option) =>
                          !option.preventAdditions ||
                          field.value?.some((o) => o.label === option.label)
                      );

                      return (
                        <>
                          <Select<OptionValues, true, GroupBase<OptionValues>>
                            {...field}
                            isDisabled={!isRevelioAdmin || readonly}
                            isClearable={
                              isRevelioAdmin &&
                              !readonly &&
                              // don't allow clearing if there are fixed options
                              !addableOptions?.some((o) => o.isFixed)
                            }
                            isMulti
                            name="report_types"
                            options={addableOptions}
                            placeholder="Select report types..."
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => 'No report types'}
                            chakraStyles={{
                              control: (provided) => ({
                                ...provided,
                                height: 'auto',
                                backgroundColor: 'white', // Set the background color for the control
                                borderColor: 'gray.300', // Set the border color
                                boxShadow: 'sm', // Apply a slight shadow (optional)
                                '&:hover': {
                                  borderColor: 'gray.400', // Darker border on hover
                                },
                              }),
                              multiValue: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.data.isFixed
                                  ? 'gray.100'
                                  : 'blue.100', // Background for the chips
                                borderColor: state.data.isFixed
                                  ? 'gray.300'
                                  : 'blue.300', // Border color for the chips
                                borderWidth: '1px',
                                borderRadius: 'md', // Optional: rounded corners for the chips
                              }),
                              multiValueLabel: (provided) => ({
                                ...provided,
                                color: 'blue.800', // Text color for the chips
                              }),
                            }}
                          />
                          {fieldState.error && (
                            <Box color={'red'}>{fieldState.error.message}</Box>
                          )}
                        </>
                      );
                    }}
                  />
                </FormControl>
              </Stack>
            )}

            {/* DATA BUILDER FIELDS */}
            {hasDeliverablesTab && (
              <Stack
                display={readonly ? 'none' : 'inherit'}
                width={'100%'}
                borderWidth="1px"
                borderRadius="md"
                p="2"
                mt="2"
                boxShadow="sm"
                color="gray.800"
              >
                <Heading as="h5" size="sm">
                  Data Builder Configuration
                </Heading>
                <FormControl id="pipeline_types" mt="2">
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Subscribed Datasets
                  </FormLabel>
                  <Controller
                    name="pipeline_types"
                    control={control}
                    rules={{
                      validate: (value) => {
                        // NOTE: allow trial clients to have Deliverables tab but no access to anything "read only mode"
                        if (!clientToEdit?.live) {
                          return true;
                        }
                        if (
                          value === undefined &&
                          initialPipelines.length === 0
                        ) {
                          return 'At least one dataset is required.';
                        }

                        if (value !== undefined && value.length === 0) {
                          return 'At least one dataset is required.';
                        }

                        return true;
                      },
                    }}
                    defaultValue={
                      /* eslint-disable-next-line no-nested-ternary */
                      initialPipelines.length > 0
                        ? initialPipelines
                        : clientToEdit?.live === false || !isNewClientLive
                          ? defaultPipelineOptions
                          : [COMPANY_INFO_DATASET_OPTION]
                    }
                    render={({ field, fieldState }) => {
                      const addableOptions = pipelineOptions.filter(
                        (option) =>
                          !option.preventAdditions ||
                          field.value?.some((o) => o.label === option.label)
                      );
                      return (
                        <>
                          <Select<OptionValues, true, GroupBase<OptionValues>>
                            {...field}
                            isDisabled={!isRevelioAdmin || readonly}
                            isClearable={isRevelioAdmin && !readonly}
                            isMulti
                            name="pipeline_types"
                            options={addableOptions}
                            placeholder="Select datasets..."
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => 'No Datasets'}
                            chakraStyles={{
                              control: (provided) => ({
                                ...provided,
                                height: 'auto',
                                backgroundColor: 'white', // Set the background color for the control
                                borderColor: 'gray.300', // Set the border color
                                boxShadow: 'sm', // Apply a slight shadow (optional)
                                '&:hover': {
                                  borderColor: 'gray.400', // Darker border on hover
                                },
                              }),
                              multiValue: (provided) => ({
                                ...provided,
                                backgroundColor: 'blue.100', // Background for the chips
                                borderColor: 'blue.300', // Border color for the chips
                                borderWidth: '1px',
                                borderRadius: 'md', // Optional: rounded corners for the chips
                              }),
                              multiValueLabel: (provided) => ({
                                ...provided,
                                color: 'blue.800', // Text color for the chips
                              }),
                            }}
                          />
                          {fieldState.error && (
                            <Box color={'red'}>{fieldState.error.message}</Box>
                          )}
                        </>
                      );
                    }}
                  />
                </FormControl>

                <FormControl id="s3_location" mt="2">
                  <FormLabel fontSize="sm" fontWeight="semibold" flexShrink="0">
                    S3 Bucket Location
                  </FormLabel>
                  <Input
                    type="text"
                    color={'text.primary'}
                    isDisabled={!isRevelioAdmin || readonly}
                    defaultValue={
                      clientToEdit?.data_builder_configuration?.s3_location
                        ?.s3_bucket || ''
                    }
                    {...register('s3_location')}
                  />
                </FormControl>

                <FormControl
                  id="snowflake_database"
                  mt="2"
                  isInvalid={!!errors?.snowflake_database}
                >
                  <FormLabel fontSize="sm" fontWeight="semibold" flexShrink="0">
                    Snowflake Database
                  </FormLabel>
                  <Input
                    type="text"
                    color={'text.primary'}
                    isDisabled={!isRevelioAdmin || readonly}
                    defaultValue={
                      clientToEdit?.data_builder_configuration
                        ?.snowflake_location?.snowflake_db || ''
                    }
                    {...register('snowflake_database', {
                      pattern: {
                        // regex to check if string does not start with user
                        value: /^(?!user_)/,
                        message: 'Delivery to USER databases is not supported’',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors?.snowflake_database &&
                      errors.snowflake_database.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl id="snowflake_schema" mt="2">
                  <FormLabel fontSize="sm" fontWeight="semibold" flexShrink="0">
                    Snowflake Schema
                  </FormLabel>
                  <Input
                    type="text"
                    color={'text.primary'}
                    isDisabled={!isRevelioAdmin || readonly}
                    defaultValue={
                      clientToEdit?.data_builder_configuration
                        ?.snowflake_location?.snowflake_schema || ''
                    }
                    {...register('snowflake_schema')}
                  />
                </FormControl>
              </Stack>
            )}

            {/* CUSTOM TAXONOMY SETTING */}
            <FormControl id="custom_taxonomy" mt="2">
              <FormLabel fontSize="sm" fontWeight="semibold">
                Role Taxonomy Setting
              </FormLabel>
              <Controller
                name="custom_taxonomy"
                control={control}
                defaultValue={
                  CustomTaxonomyOptions.find(
                    (o) => o.value === clientToEdit?.custom_taxonomy
                  ) || defaultCustomTaxonomyOption
                }
                render={({ field }) => (
                  <Select<OptionValues, true, GroupBase<OptionValues>>
                    {...field}
                    value={
                      typeof field.value === 'string'
                        ? CustomTaxonomyOptions.find(
                            (o) => o.value === clientToEdit?.custom_taxonomy
                          ) || defaultCustomTaxonomyOption
                        : (field.value as {
                            label: string;
                            value: CustomTaxonomyEnum;
                          })
                    }
                    isDisabled={!isRevelioAdmin || readonly}
                    name="custom_taxonomy"
                    options={CustomTaxonomyOptions}
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => 'No Options'}
                    maxMenuHeight={120}
                    chakraStyles={{
                      control: (provided) => ({
                        ...provided,
                        height: 'auto',
                        backgroundColor: 'white', // Set the background color for the control
                        borderColor: 'gray.300', // Set the border color
                        boxShadow: 'sm', // Apply a slight shadow (optional)
                        '&:hover': {
                          borderColor: 'gray.400', // Darker border on hover
                        },
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: 'blue.100', // Background for the chips
                        borderColor: 'blue.300', // Border color for the chips
                        borderWidth: '1px',
                        borderRadius: 'md', // Optional: rounded corners for the chips
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: 'blue.800', // Text color for the chips
                      }),
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl id="live">
              <FormLabel fontSize="sm" fontWeight="semibold">
                Data Access
              </FormLabel>
              <Flex>
                <FormLabel fontSize="sm">Trial</FormLabel>
                <Switch
                  id="email-alerts"
                  isDisabled={!isRevelioAdmin || readonly}
                  colorScheme="green"
                  defaultChecked={clientToEdit?.live}
                  {...register('live')}
                />
                <FormLabel fontSize="sm" ml={3}>
                  Live
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl id="linkup_postings">
              <FormLabel fontSize="sm" fontWeight="semibold">
                Website Postings
              </FormLabel>
              <Flex>
                <FormLabel fontSize="sm">Off</FormLabel>
                <Switch
                  id="linkup-postings"
                  isDisabled={!isRevelioAdmin || readonly}
                  colorScheme="green"
                  defaultChecked={
                    isAddingNewClient ? false : clientToEdit?.linkup_postings
                  }
                  {...register('linkup_postings')}
                />
                <FormLabel fontSize="sm" ml={3}>
                  On
                </FormLabel>
              </Flex>
            </FormControl>
          </VStack>
        </FieldGroup>
      </Box>

      {actions}
    </Stack>
  );
};

export default ClientForm;

import { Box } from '@chakra-ui/layout';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { useLocation, useNavigate } from 'react-router-dom';

import { authStore, getAuthStoreUser } from '@revelio/auth';
import {
  IFrameMessageTypes,
  PagePaths,
  UserTrackingEvents,
  sendMessageToIFrame,
  useListenForIFrameMessages,
} from '@revelio/core';
import { useKibanaLogger } from '@revelio/layout';

import { environment } from '../../environments/environment';

const TaxonomyCatalog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [iframeUrl, setIframeUrl] = useState<string>();
  const { kibanaLogger } = useKibanaLogger();

  useEffect(() => {
    mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
      page: 'Taxonomy Catalog',
      path: location.pathname,
    });
    mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    return () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: 'Taxonomy Catalog',
        path: location.pathname,
      });
    };
  }, [location.pathname]);

  useListenForIFrameMessages(
    IFrameMessageTypes.TaxonomyCatalogUrlPath,
    ({ data: pathName, type }) => {
      if (pathName && typeof pathName === 'string') {
        navigate(`/${PagePaths.TAXONOMY_CATALOG}${pathName}`);
      } else {
        console.error('useListenForIFrameMessages: invalid path', {
          data: pathName,
          type,
        });
        kibanaLogger?.error('useListenForIFrameMessages: invalid path', {
          username: getAuthStoreUser()?.email as string | undefined,
        });
      }
    }
  );

  useEffect(() => {
    // Extract the path suffix after '/taxonomy-catalog'
    const pathSuffix = location.pathname.replace(
      `/${PagePaths.TAXONOMY_CATALOG}`,
      ''
    );
    setIframeUrl(`https://taxonomy.reveliolabs.com${pathSuffix}`); // Local Development: setIframeUrl(`http://localhost:4200${pathSuffix}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Should only run once on mount. Let the iframe update it's own urls

  return (
    <Box height="100vh">
      <Iframe
        id="taxonomy-catalog-iframe"
        url={iframeUrl ?? ''}
        width="100%"
        height="100%"
        position="relative"
        styles={{ border: 'none' }}
        onLoad={() => {
          const iframe = document.getElementById(
            'taxonomy-catalog'
          ) as HTMLIFrameElement;
          sendMessageToIFrame({
            iframe,
            data: {
              dashMetaCsrfToken: authStore.getValue().dashMetaCsrfToken,
              ory: authStore.getValue()?.ory,
              dashMetaRoot: environment.DASH_META_API,
            },
            type: IFrameMessageTypes.TaxonomyCatalogKibanna,
          });
        }}
      />
    </Box>
  );
};

export default TaxonomyCatalog;

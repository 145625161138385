interface TooltipData {
  filterName: string;
  label: string;
  value: number;
  count: number;
}

export const getBarTooltip = (data: TooltipData) => (
  <div style={{ fontSize: '12px' }}>
    <div style={{ fontWeight: '600', marginBottom: '4px' }}>
      {data.filterName}
    </div>
    <div>{data.label}</div>
    <div>
      {data.count.toLocaleString()} / {(data.value * 100).toFixed(2)}%
    </div>
  </div>
);

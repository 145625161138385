import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  List,
  ListItem,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { FLAG_ENABLE_RICS } from '@revelio/core';

import {
  GEOGRAPHY_GRANULARITY_FILTERS,
  RICS_FILTERS,
  ROLE_GRANULARITY_FILTERS,
} from '../../../engine/filters.constants';

export interface Segment {
  label: string;
  value: string[];
}
export const segmentOptions: Segment[] = [
  { label: 'Ethnicity', value: ['ethnicity'] },
  { label: 'Gender', value: ['gender'] },
  { label: 'Geography', value: GEOGRAPHY_GRANULARITY_FILTERS },
  { label: 'Highest Degree', value: ['highest_degree'] },
  {
    label: 'Industry',
    value: FLAG_ENABLE_RICS ? RICS_FILTERS : ['industry'],
  },
  { label: 'Role', value: ROLE_GRANULARITY_FILTERS },
  { label: 'Seniority', value: ['seniority'] },
];

interface SegmentSelectionFormProps {
  onSegmentSelect: (segment: Segment) => void;
  onClickBack: () => void;
}

export const SegmentSelectionForm = ({
  onSegmentSelect,
  onClickBack,
}: SegmentSelectionFormProps) => {
  return (
    <Flex direction="column" gap="4" paddingLeft={1} fontSize="14px">
      <List>
        {segmentOptions.map((option, index) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            onClick={() => onSegmentSelect(option)}
            cursor="pointer"
            _hover={{ bg: 'gray.100' }}
            p={1}
            key={index}
          >
            <ListItem
              key={index}
              style={{ cursor: 'pointer' }}
              data-testid={`segment-option-${option.label}`}
            >
              {option.label}
            </ListItem>
            <Icon as={FiChevronRight} boxSize="20px" />
          </Flex>
        ))}
      </List>
      <Flex justifyContent="space-between" alignItems="center" pt={2}>
        <ButtonGroup
          size="sm"
          height="100%"
          justifyContent="flex-end"
          w="100%"
          spacing={4}
        >
          <Button
            variant="link"
            fontSize="12px"
            height="32px"
            colorScheme="gray"
            onClick={onClickBack}
            // auto focusing so close on blur can be triggered
            autoFocus
          >
            Back
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

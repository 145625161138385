import { useUserTrialDetails } from '@revelio/auth';

import { DataEnrichmentTrialPage } from '../trial-pages/data-enrichment-trial-page';
import DeliverablesDataEnrichmentPage from './DeliverablesDataEnrichmentPage';

export const DeliverablesDataEnrichment = () => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  if (isTrialUser) {
    return <DataEnrichmentTrialPage />;
  } else {
    return <DeliverablesDataEnrichmentPage />;
  }
};

import {
  LocationMappingItem,
  RoleMappingItem,
  TalentDiscoveryAiFilterSearchResponse,
} from '@revelio/data-access';
import { TalentDiscoveryAiSearchFilterType } from '@revelio/data-access';
import {
  SelectionCategories,
  SelectionList,
  TreeItem,
  ValidValueTypes,
  findSelectionListItemByItemId,
  findSelectionListItemByLabel,
} from '@revelio/filtering';

import { TreeFilters } from '../../../types';
import { TDResponseFilterToLabel } from './ai-filter-response-mappings';
import { getUnknownFilterExplanation } from './get-unknown-filter-explanation';
import { locationMapperApi, roleMapperApi } from './mapping-apis';

interface ProcessFilterGroupArgs {
  group: TalentDiscoveryAiSearchFilterType[];
  relevantSelectionLists: SelectionList[];
  treeFilterKey: TreeFilters;
}

interface SkipSelectionListConfig {
  selectionListId: SelectionCategories | string;
}

const GRANULARITY_PREFERENCES = {
  rics: ['k10', 'k50', 'k400'],
  skill: ['k75', 'k700', 'k3000'],
  role: ['k7', 'k150', 'k1500'],
} as const;

const getGranularityPreference = (selectionListId: string) => {
  if (selectionListId.startsWith('rics_')) {
    return GRANULARITY_PREFERENCES.rics;
  }
  if (selectionListId.startsWith('skill_')) {
    return GRANULARITY_PREFERENCES.skill;
  }
  return null;
};

const findMatchingTreeItems = (
  aiFilterSearchFilterLabel: TalentDiscoveryAiSearchFilterType,
  relevantSelectionLists: SelectionList[],
  skipSelectionList?: SkipSelectionListConfig
) => {
  // First find all matches across selection lists
  const allMatches = relevantSelectionLists
    .map((selectionList) => {
      if (skipSelectionList?.selectionListId === selectionList.id) {
        return null;
      }

      const match = findSelectionListItemByLabel({
        labelToFind: aiFilterSearchFilterLabel.name,
        selectionList: selectionList,
      });

      return match ? { ...match, selectionListId: selectionList.id } : null;
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);

  if (allMatches.length === 0) {
    return [];
  }

  // Find the best score
  const scores = allMatches.map((m) => m.closeness_score ?? 1);
  const bestScore = Math.min(...scores);

  // Get matches that are very close to the best score
  const closeMatches = allMatches.filter(
    (match) => Math.abs((match.closeness_score ?? 1) - bestScore) <= 0.0001
  );

  // If we have multiple close matches, check if they're from a hierarchical selection list
  if (closeMatches.length > 1) {
    // Get the type of hierarchy we're dealing with (if any)
    const firstMatch = closeMatches[0];
    const granularityPreference =
      firstMatch && getGranularityPreference(firstMatch.selectionListId);

    if (granularityPreference) {
      // Try to find a match for each granularity level in order of preference
      for (const granularity of granularityPreference) {
        const matchAtGranularity = closeMatches.find((m) =>
          m.selectionListId.includes(granularity)
        );
        if (matchAtGranularity) {
          return [matchAtGranularity];
        }
      }
    }
  }

  // If no granularity preference applies, return top match
  return closeMatches;
};

const getClosestTreeItem = (
  matchedTreeItems: ReturnType<typeof findMatchingTreeItems>
) => {
  return matchedTreeItems.sort(
    (a, b) =>
      //lower closeness score is better, defaulting to 100 so values with missing scores are not picked
      (a.closeness_score || 100) - (b.closeness_score || 100)
  )[0];
};

export const processFilterGroup = ({
  group,
  relevantSelectionLists,
  treeFilterKey,
  skipSelectionList,
}: ProcessFilterGroupArgs & {
  skipSelectionList?: SkipSelectionListConfig;
}): {
  processedFilters: Record<string, TreeItem>;
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'];
} => {
  const unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'] =
    [];
  const processedFilters = group?.reduce((acc, aiFilterSearchFilterLabel) => {
    const matchedTreeItems = findMatchingTreeItems(
      aiFilterSearchFilterLabel,
      relevantSelectionLists,
      skipSelectionList
    );

    if (matchedTreeItems.length > 0) {
      const closestTreeItem = getClosestTreeItem(matchedTreeItems);
      return {
        ...acc,
        [closestTreeItem.id]: closestTreeItem,
      };
    }

    unknownFilters.push({
      relevantPromptText: aiFilterSearchFilterLabel.relevantPromptText,
      explanation: getUnknownFilterExplanation(
        aiFilterSearchFilterLabel.name,
        TDResponseFilterToLabel[treeFilterKey]
      ),
      name: aiFilterSearchFilterLabel.name,
    });

    return acc;
  }, {});

  return { processedFilters, unknownFilters };
};

type SupportedTreeFilterKey = 'geography' | 'role';
interface ProcessFilterGroupWithMapperArgs
  extends Omit<ProcessFilterGroupArgs, 'treeFilterKey'> {
  treeFilterKey: SupportedTreeFilterKey;
}
export const processFilterGroupWithMapperApiFallback = async (
  params: ProcessFilterGroupWithMapperArgs
) => {
  const { processedFilters, unknownFilters } = processFilterGroup({
    ...params,
    skipSelectionList: { selectionListId: SelectionCategories.METRO_AREA },
  });

  const fallbackMapperFiltersFromUnknown = unknownFilters.map(
    (unknownFilter) => unknownFilter.name
  );

  if (fallbackMapperFiltersFromUnknown.length === 0) {
    return { processedFilters, unknownFilters };
  }

  await handleMapperApiFallback({
    treeFilterKey: params.treeFilterKey as SupportedTreeFilterKey,
    fallbackMapperFiltersFromUnknown,
    relevantSelectionLists: params.relevantSelectionLists,
    unknownFilters,
    processedFilters,
  });

  return { processedFilters, unknownFilters };
};

interface MapperConfig<T extends LocationMappingItem | RoleMappingItem> {
  categoryId: SelectionCategories;
  getMapperId: (item: T) => string;
  getOriginalName: (item: T) => string;
}

const MAPPER_CONFIGS: {
  geography: MapperConfig<LocationMappingItem>;
  role: MapperConfig<RoleMappingItem>;
} = {
  geography: {
    categoryId: SelectionCategories.METRO_AREA,
    getMapperId: (item: LocationMappingItem) => item.metro_area_id,
    getOriginalName: (item: LocationMappingItem) => item.location,
  },
  role: {
    categoryId: SelectionCategories.ROLE_K1500,
    getMapperId: (item: RoleMappingItem) => item.mapped_role_id,
    getOriginalName: (item: RoleMappingItem) => item.title,
  },
};

const processMapperResponse = <T extends LocationMappingItem | RoleMappingItem>(
  mapperItems: T[],
  config: MapperConfig<T>,
  relevantSelectionLists: SelectionList[],
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'],
  processedFilters: Record<string, TreeItem>
) => {
  mapperItems.forEach((mappingItem) => {
    const selectionListItem = findSelectionListItemByItemId({
      itemId: `${config.categoryId}.${config.getMapperId(mappingItem)}`,
      selectionLists: [
        relevantSelectionLists.find(
          (selectionList) => selectionList.id === config.categoryId
        ) as SelectionList<ValidValueTypes>,
      ],
    });

    if (selectionListItem) {
      const unknownFilterIndex = unknownFilters.findIndex(
        (filter) => filter.name === config.getOriginalName(mappingItem)
      );
      if (unknownFilterIndex !== -1) {
        unknownFilters.splice(unknownFilterIndex, 1);
      }

      if (selectionListItem?.id) {
        processedFilters[selectionListItem.id] = selectionListItem;
      }
    }
  });
};

const handleMapperApiFallback = async ({
  treeFilterKey,
  fallbackMapperFiltersFromUnknown,
  relevantSelectionLists,
  unknownFilters,
  processedFilters,
}: {
  treeFilterKey: SupportedTreeFilterKey;
  fallbackMapperFiltersFromUnknown: string[];
  relevantSelectionLists: SelectionList[];
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'];
  processedFilters: Record<string, TreeItem>;
}) => {
  const config = MAPPER_CONFIGS[treeFilterKey];
  if (!config) return;

  if (treeFilterKey === 'geography') {
    const apiResponse = await locationMapperApi({
      locationsToMap: fallbackMapperFiltersFromUnknown,
    });
    processMapperResponse<LocationMappingItem>(
      Object.values(apiResponse),
      config as MapperConfig<LocationMappingItem>,
      relevantSelectionLists,
      unknownFilters,
      processedFilters
    );
  } else if (treeFilterKey === 'role') {
    const apiResponse = await roleMapperApi({
      rolesToMap: fallbackMapperFiltersFromUnknown,
    });
    processMapperResponse<RoleMappingItem>(
      Object.values(apiResponse),
      config as MapperConfig<RoleMappingItem>,
      relevantSelectionLists,
      unknownFilters,
      processedFilters
    );
  }
};

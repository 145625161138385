import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { cloneDeep, isBoolean } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Path, useForm, useWatch } from 'react-hook-form';

import { ActionModalControlPanel, write } from '@revelio/core';

import { CustomColumn, Deliverable } from '../../deliverables.model';
import {
  getCustomColumnByName,
  updateDraftDeliverable,
} from '../../deliverables.repository';
import { CustomStepName } from '../custom-step-name';
import { EMPTY_VALUE_MESSAGE } from '../dataset-conditions/dataset-conditions-form-helpers';
import { CategoryCases } from './category-cases';
import {
  DEFAULT_FORM_VALUES,
  useSetDetaultCustomColumnNameOnFirstEdit,
} from './custom-column-form-helpers';
import { CustomColumnForm } from './custom.model';

export const CustomDefinitionModal = ({
  isOpen,
  setIsOpen,
  existingCustomColumns,
  entityId,
}: {
  isOpen: boolean | string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | string>>;
  existingCustomColumns: CustomColumn[];
  entityId: Deliverable['id'];
}) => {
  const formProps = useForm<CustomColumnForm>({
    defaultValues: cloneDeep(DEFAULT_FORM_VALUES),
  });

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    getValues,
    setError,
  } = formProps;

  const customColumnBeingEdited = useMemo(
    () =>
      isBoolean(isOpen)
        ? undefined
        : cloneDeep(
            getCustomColumnByName({ name: isOpen as string, entityId })
          ),
    [entityId, isOpen]
  );
  useEffect(() => {
    // form has to be reset since it's always mounted depending on if adding new column vs editing existing column
    if (!customColumnBeingEdited || isSubmitSuccessful) {
      reset(cloneDeep(DEFAULT_FORM_VALUES));
      return;
    }

    if (
      customColumnBeingEdited &&
      customColumnBeingEdited.name !== getValues('customColumnName')
    ) {
      reset({
        customColumnName: customColumnBeingEdited.name,
        categoryCases: customColumnBeingEdited.step.slice(0, -1),
        elseCategoryName:
          customColumnBeingEdited.step[customColumnBeingEdited.step.length - 1]
            .then,
      });
    }
  }, [customColumnBeingEdited, isSubmitSuccessful, isOpen, reset, getValues]);

  const customColumnName = useWatch({
    control: formProps.control,
    name: 'customColumnName',
  });
  useSetDetaultCustomColumnNameOnFirstEdit({
    entityId,
    currentCustomColumnName: customColumnName,
    ...formProps,
  });

  const onSubmit = handleSubmit((formData) => {
    const conditionValueErrors: { [key in Path<CustomColumnForm>]?: string } =
      {};
    formData.categoryCases.forEach((condition, categoryIndex) => {
      condition.case.conditions.forEach((condition, conditionIndex) => {
        if (!condition.value || !condition.value.length) {
          const error = EMPTY_VALUE_MESSAGE;
          const fieldPath =
            `categoryCases.${categoryIndex}.case.conditions.${conditionIndex}.value` as const;
          setError(fieldPath, {
            message: error,
          });
          conditionValueErrors[fieldPath] = error;
        }
      });
    });

    const hasValidationError = Object.keys(conditionValueErrors).length;
    if (hasValidationError) {
      return;
    }
    updateDraftDeliverable(
      entityId,
      write<Deliverable>((state) => {
        const customColumn = state.pipeline.custom_columns?.find(
          (x) => x.name === customColumnBeingEdited?.name
        );
        if (customColumnBeingEdited && customColumn) {
          customColumn.name = formData.customColumnName;
          customColumn.step = [
            ...cloneDeep(formData.categoryCases), // necessary as for some reason categoryCases is readOnly
            { then: formData.elseCategoryName },
          ];
          return;
        }

        state.pipeline.custom_columns = [
          ...(state.pipeline.custom_columns || []),
          {
            name: formData.customColumnName,
            step: [
              ...cloneDeep(formData.categoryCases), // necessary as for some reason categoryCases is readOnly
              { then: formData.elseCategoryName },
            ],
          },
        ];
      })
    );
    setIsOpen(false);
  });

  return (
    <Modal
      id="custom-definition-modal"
      isOpen={!!isOpen}
      onClose={() => setIsOpen(false)}
      size="2xl"
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <CustomStepName<CustomColumnForm>
            id="custom-column-name"
            fieldPath="customColumnName"
            register={register}
            customName={customColumnName}
            existingCustomNames={existingCustomColumns.map((x) => x.name)}
            currentCustomName={isOpen}
            nameError={errors.customColumnName}
          />

          <Divider orientation="horizontal" />

          <ModalBody>
            <CategoryCases entityId={entityId} formProps={formProps} />

            <Text fontWeight="600" fontSize="md" mt="12px" mb="5px">
              else
            </Text>

            <FormControl
              id="else-condition-value"
              isInvalid={!!errors.elseCategoryName}
              pl="5"
            >
              <Box>
                <Flex alignItems="center">
                  <FormLabel
                    fontSize="xs"
                    minWidth="60px"
                    color="text.primary"
                    mb="0"
                  >
                    Set value as
                  </FormLabel>

                  <Input
                    fontSize="xs"
                    {...formProps.register('elseCategoryName', {
                      required: 'Else case category name is required.',
                      minLength: {
                        value: 1,
                        message: 'Minimum length should be 1',
                      },
                    })}
                    placeholder="Category name"
                  />
                </Flex>

                <FormErrorMessage>
                  {errors.elseCategoryName?.message}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </ModalBody>

          <ModalFooter mt="45px">
            <ActionModalControlPanel
              submitText={
                customColumnBeingEdited ? 'Edit Column' : 'Create Column'
              }
              onClose={() => setIsOpen(false)}
              onCancel={() => setIsOpen(false)}
              onSubmit={onSubmit}
              onReset={() => reset(cloneDeep(DEFAULT_FORM_VALUES))}
            />
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

import styles from './grouped-bar-tooltip.module.css';

type GroupedBarTooltipProps = {
  title: string;
  label: string;
  value: string;
};

export const GroupedBarTooltip = ({
  title,
  label,
  value,
}: GroupedBarTooltipProps) => {
  return (
    <div>
      <div className={styles.barTooltipLabel}>{title}</div>
      <div className={styles.barTooltipValue}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2em',
          }}
        >
          <span>{label}</span>
          <span>{value}</span>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Box, Code } from '@chakra-ui/react';

interface CodeDisplayProps {
  code: string;
}

export const CodeDisplay: React.FC<CodeDisplayProps> = ({ code }) => {
  return (
    <Box p={2} borderRadius="md" bg="gray.100" maxH="none" overflowY="auto">
      <Code
        display="block"
        whiteSpace="pre-wrap"
        fontSize="sm"
        width="100%"
        p={2}
        minH="500px"
        as="textarea"
        resize="vertical"
        readOnly
        value={code}
        sx={{
          bg: 'gray.100',
          border: 'none',
          outline: 'none',
          '&:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        }}
      >
        {code}
      </Code>
    </Box>
  );
};

import {
  Box,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect$ } from '@ngneat/react-rxjs';
import { get } from 'lodash';
import { useRef } from 'react';
import { map, tap } from 'rxjs';

import { plotColorLookup } from '@revelio/core';

import { ViewTypes } from '../../data-api/data-api.model';
import { listNameOverrides } from '../../engine/filters.core';
import {
  removePrimaryEntityFilterValue,
  removeSelectFilterValue,
} from '../../engine/filters.repository';
import { getSortableId } from './helpers.config';

interface PrimaryFilterChipProps {
  showColors?: boolean;
  filterItem: any;
  viewType?: ViewTypes;
  isMin?: boolean;
  isActive?: boolean;
  onClick?: any;
}
export const PrimaryFilterChip = ({
  showColors = false,
  filterItem,
  isMin,
  isActive = true,
  onClick,
}: PrimaryFilterChipProps) => {
  const limitToastId = 'rl-min-limit-toast';

  const toast = useToast();

  const handleRemoveChip = () => {
    if (isMin && isActive) {
      if (!toast.isActive(limitToastId)) {
        toast({
          id: limitToastId,
          position: 'top-right',
          status: 'warning',
          title: `1 Selection Required`,
          description: `At least 1 Selection is required.`,
          isClosable: true,
          variant: 'subtle',
        });
      }

      return;
    }

    removePrimaryEntityFilterValue(filterItem.id, filterItem.selectionListId);
    removeSelectFilterValue(
      get(
        listNameOverrides,
        filterItem.selectionListId,
        filterItem.selectionListId
      ),
      filterItem.id
    );
  };

  const chipColor = useRef<string>();

  useEffect$(() =>
    plotColorLookup.pipe(
      map((val) => {
        const color = get(
          val,
          `${filterItem.selectionListId}__${filterItem.id}`
        );
        return color?.color;
      }),
      tap((color) => {
        chipColor.current = color;
      })
    )
  );

  const {
    isDragging: isChipDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: getSortableId(filterItem) });

  const draggingStyle = isChipDragging
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'grabbing',
      }
    : undefined;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...draggingStyle,
  };

  return (
    <Tag
      id={filterItem.id}
      data-testid="primary-filter-chip"
      variant="companyChip"
      size="sm"
      backgroundColor={isActive ? 'white' : 'rgba(199, 208, 218, 1)'}
      borderRadius="3px"
      minW="fit-content"
      height="1.8rem"
      border={`1px solid ${isActive ? '#46BEF1' : '#A4ADBE'}`}
      onClick={onClick}
      as="button"
      colorScheme="whiteAlpha"
      position="relative"
      zIndex={isChipDragging ? 1 : undefined}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <TagLabel>
        <Flex alignItems="center" justifyContent="flex-start">
          {showColors && (
            <Flex
              h="8px"
              w="8px"
              bg={isActive ? chipColor.current : 'blackAlpha.100'}
              borderRadius="2px"
              mr="5px"
            />
          )}
          <Text opacity={isActive ? '1' : '0.6'} fontWeight={400}>
            {filterItem?.shortName}
          </Text>
        </Flex>
      </TagLabel>
      <TagCloseButton
        data-testid="primary-filter-chip-remove"
        as={Box}
        onClick={(e) => {
          e.stopPropagation();
          handleRemoveChip();
        }}
      />
    </Tag>
  );
};

import { groups } from 'd3-array';
import { useMemo } from 'react';

import {
  FilterList,
  SelectFilter,
  SelectionCategories,
  SelectionListItems,
} from '../../../engine/filters.model';
import { useActiveFiltersState } from '../../../engine/filters.repository';

export const useActiveSelectionListItems = (
  selectionListIds: SelectionCategories[]
): SelectionListItems[] => {
  const activeFilters = useActiveFiltersState();
  return useMemo(() => {
    const selectedFilters = activeFilters
      .filter((filter) =>
        selectionListIds.includes(filter.id as SelectionCategories)
      )
      .filter((filter): filter is SelectFilter<FilterList> =>
        Array.isArray(filter.value)
      )
      .flatMap((filter) =>
        filter.value.map((item) => ({ ...item, selectionListId: filter.id }))
      );

    const groupedFilters = groups(selectedFilters, (d) => d.selectionListId);
    return groupedFilters.map(
      ([selectionListId, items]): SelectionListItems => ({
        id: selectionListId as SelectionCategories,
        value: items.map((item) => ({ ...item, id: `${item.id}` })),
      })
    );
  }, [activeFilters, selectionListIds]);
};

export const shortMonth = (dateStr: string): string => {
  return dateStr.replace(
    /(January|February|March|April|May|June|July|August|September|October|November|December)/g,
    (month) => {
      return month.slice(0, 3);
    }
  );
};

const formatDay = (date: Date) => {
  return date.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

const formatMonth = (date: Date) => {
  return date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

const formatDayTime = (date: Date): string => {
  return (
    formatDate(date, 'day') +
    ' ' +
    date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
    })
  );
};

const DateFormatMap = {
  day: formatDay,
  month: formatMonth,
  daytime: formatDayTime,
} as const;

export type DateFormat = keyof typeof DateFormatMap;

export const formatDate = (date: Date, format: DateFormat) => {
  return DateFormatMap[format](date);
};

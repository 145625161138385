import { compact, defer, findKey, isString, memoize } from 'lodash';
import objectHash from 'object-hash';
import {
  BehaviorSubject,
  EMPTY,
  ObservableInput,
  pairwise,
  shareReplay,
  switchMap,
} from 'rxjs';

import { SelectionCategories } from '../engine/filters.model';
import {
  CalculatedEndpoint,
  DataFetcherArgs,
  EndpointSegment,
  PageGroupName,
  RequestConfig,
  TransitionsDownloadFlowTypePlaceholder,
} from './data-api.model';
import { endpointConfigs } from './data-api.validation';

/**
 *
 * @param handler - function that will be called with the request args and should return an observable of the needed data
 * @param requestHasher - if no requestHash is present in the request args then this function will be used to generate one
 * @returns
 */
export function createDataFetcher<T>(
  handler: (args: DataFetcherArgs) => ObservableInput<T>,
  requestHasher = (args: DataFetcherArgs) =>
    objectHash(args.filters, { unorderedArrays: true })
) {
  const inputTrigger = new BehaviorSubject<DataFetcherArgs>({
    requestHash: 'fake-starter',
  } as DataFetcherArgs);
  const result = inputTrigger.pipe(
    pairwise(),
    switchMap(([prevInput, input]) => {
      if (input.requestHash === prevInput.requestHash) {
        return EMPTY;
      }
      return handler(input);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  return function (value: DataFetcherArgs) {
    if (!value.requestHash) {
      value.requestHash = requestHasher(value);
    }
    defer(() => inputTrigger.next(value));
    return result;
  };
}

const _getGraphName = (endpointName?: EndpointSegment) => {
  if (!endpointName) {
    return '';
  }

  if (endpointName === EndpointSegment.ROLE) {
    return SelectionCategories.JOB_CATEGORY.replace('_', '-');
  }

  const endpointSegmentKey =
    findKey(
      endpointConfigs,
      (endpointSegment) =>
        endpointSegment.endpoint === endpointName ||
        endpointSegment.name === endpointName
    ) || endpointName;
  return endpointConfigs[endpointSegmentKey as EndpointSegment]
    ?.gqlDataEndpoint;
};

function _buildRequestConfig(param: RequestConfig | string) {
  if (isString(param)) {
    return { endpointPath: param } as CalculatedEndpoint;
  }

  if (param.url instanceof URL) {
    const { url, endpoint } = param;
    return {
      url,
      name: endpoint,
      endpointPath: url.pathname,
      downloadEndpointPath: url.pathname,
    } as CalculatedEndpoint;
  }

  const { view, viewType, endpoint } = param;
  const config = endpointConfigs[endpoint as EndpointSegment];
  const isValidEndpoint = config?.viewsIncluded.some((vI) => {
    return isString(vI) ? vI == view : view == vI[0] && viewType == vI[1];
  });

  let error: Error | undefined;
  const builtEndpoint = compact([view, viewType]).join('/');
  const primaryDataView = param.primaryDataView;
  const pageGroupName = param.pageGroupName;
  // eslint-disable-next-line no-nested-ternary
  const viewTypeSegment = ['compositions', 'sentiment'].includes(
    pageGroupName as PageGroupName
  )
    ? viewType?.replace('shares_', '')
    : ['transitions'].includes(pageGroupName as PageGroupName)
      ? TransitionsDownloadFlowTypePlaceholder
      : null;
  const goDownloadEndpoint = compact([
    'downloads',
    pageGroupName,
    primaryDataView,
    viewTypeSegment,
    _getGraphName(endpoint),
  ]).join('/');

  if (!isValidEndpoint) {
    const message = 'Not a valid Plot endpoint to call.';
    console.error(message, `: /plots/${builtEndpoint}/${config.endpoint}`);
    error = new Error(message);
  }

  return {
    name: config.name || config.endpoint,
    endpointPath: `/plots/${builtEndpoint}`,
    downloadEndpointPath: `/plots/${builtEndpoint}/downloads`,
    goDownloadEndpointPath: `/${goDownloadEndpoint}`,
    error,
  } as CalculatedEndpoint;
}

export const getPlotEndpointConfig = memoize(_buildRequestConfig, (args) =>
  isString(args)
    ? args
    : [
        args['view'],
        args['viewType'],
        args['endpoint'],
        args['url'],
        args['primaryDataView'],
        args['pageGroupName'],
      ].join('-')
);

/**
 * NOTES
 *
 * > Overivew Overtime
 *      - separate filters: 'grouped' = boolean, 'subfilter' = Array of options for given plot (ex: skill, education, gender, etc)
 *
 * > Transitions
 *      - separate filter: 'inflow' = boolean
 */

import { Flex, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';

import { Segment, segmentOptions } from './filter-form/segment-selection-form';

interface SegmentBreadcrumbsProps {
  selectedSegment: Segment | null;
  currentStep: number;
  onPreviousStep: () => void;
}

export const SegmentBreadcrumbs = ({
  selectedSegment,
  currentStep,
  onPreviousStep,
}: SegmentBreadcrumbsProps) => {
  return (
    <Flex paddingX={0.5} pb={2} alignItems={'center'}>
      <Flex
        width="20px"
        height="20px"
        _hover={{
          background: '#2D426A1A',
        }}
        borderRadius="50%"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Icon
          as={FiArrowLeft}
          boxSize={4}
          cursor="pointer"
          onClick={onPreviousStep}
          data-testid="segment-breadcrumbs-back"
        />
      </Flex>

      {currentStep < 2 && (
        <Text
          fontSize={12}
          fontWeight={600}
          pl={1.5}
          onClick={currentStep === 2 ? onPreviousStep : undefined}
          opacity={currentStep === 2 ? 0.5 : undefined}
          cursor={currentStep === 2 ? 'pointer' : undefined}
        >
          SEGMENTS
        </Text>
      )}

      {currentStep === 2 && (
        <Text fontSize={12} fontWeight={600} marginLeft={1}>
          {segmentOptions
            .find((segment) => segment.label === selectedSegment?.label)
            ?.label?.toUpperCase()}
        </Text>
      )}
    </Flex>
  );
};

import { startCasePreserveChars } from '@revelio/core';

export const generateHTMLLinechart = (
  tooltipData,
  dateFormat,
  ttType,
  ttCustomString,
  chartStyle,
  d3Format,
  ttMainFormat,
  ttSecondaryFormat
) => {
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // sort tooltipData in order of main value
  var sortedTooltipData = tooltipData.slice(1).sort((a, b) => {
    return b[2] - a[2];
  });

  let myArray = [];
  if (dateFormat === 'YM') {
    myArray.push(
      `<div class="tt-container"><div class="linechart-title">${
        months[tooltipData[0][1] - 1]
      } ${tooltipData[0][0]}</div>`
    );
  } else if (dateFormat === 'YMD') {
    myArray.push(
      `<div class="tt-container"><div class="linechart-title">${
        months[tooltipData[0][1] - 1]
      } ${tooltipData[0][2]}, ${tooltipData[0][0]}</div>`
    );
  } else if (dateFormat === 'Y') {
    myArray.push(
      `<div class="tt-container"><div class="linechart-title"> ${tooltipData[0][0]}</div>`
    );
  }

  if (ttType === 'percent') {
    if (chartStyle === '.sentiment-ratings-page') {
      sortedTooltipData.map((item) =>
        myArray.push(
          `<div class="row">
      <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${
        item[1]
      }; position: absolute; top: 15%"></span></div>
      <div class="tt-title">${item[0]}</div>
      <div class="tt-count">${d3Format(ttMainFormat)(
        item[2]
      )}&nbsp <span style="font-size: 9px;">(${
        item[3]
      } reviews)</span></div></div>`
        )
      );
    } else {
      sortedTooltipData.map((item) =>
        myArray.push(
          `<div class="row">
        <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${
          item[1]
        }; position: absolute; top: 15%"></span></div>
        <div class="tt-title">${item[0]}</div>
        <div class="tt-count">${d3Format(ttMainFormat)(item[2])}</div>
        <div class="tt-percent-container"><div class="tt-slash">&nbsp; / &nbsp;</div><div class="tt-percent">${
          item[3]
        }</div></div></div>`
        )
      );
    }
  } else if (ttType === 'custom') {
    sortedTooltipData.map((item) =>
      myArray.push(
        `<div class="row">
        <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${
          item[1]
        }; position: absolute; top: 15%"></span></div>
        <div class="tt-title">${startCasePreserveChars(item[0])}</div>
        <div class="tt-count">${d3Format(ttMainFormat)(
          item[2]
        )} ${ttCustomString}</div></div>`
      )
    );
  } else if (ttType === 'single') {
    sortedTooltipData.map((item) =>
      myArray.push(
        `<div class="row">
      <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${
        item[1]
      }; position: absolute; top: 15%"></span></div>
      <div class="tt-title">${startCasePreserveChars(item[0])}</div>
      <div class="tt-count">${d3Format(ttMainFormat)(item[2])}</div></div>`
      )
    );
  }

  myArray.push('</div>');

  const joined = myArray.join('');
  // return `<div>${d[1] - d[0]} and ${d.key} and ${d.data[d.key]}</div>`;
  return joined;
};

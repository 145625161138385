import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { ParsedResume } from '../resumeTypes';

interface ContactInformationRawProps {
  data?: ParsedResume['contact_information']['raw'];
  variant?: string;
}

export default function ContactInformationRaw({
  data,
  variant,
}: ContactInformationRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text fontSize="24px" fontWeight="600" color="navyBlue.500">
        {data?.name}
      </Text>
      {data?.title && (
        <Text fontSize="16px" color="navyBlue.500" mb="15px">
          {data?.title}
        </Text>
      )}
      <Text sx={styles['sectionDetails']}>{data?.phone_number}</Text>
      <Text sx={styles['sectionDetails']}>{data?.email_address}</Text>
      <Text sx={styles['sectionDetails']}>{data?.linkedin_url}</Text>
      <Text sx={styles['sectionDetails']}>{data?.location}</Text>
      {data?.about && (
        <Text sx={styles['sectionDetails']} mt="5px">
          {data?.about}
        </Text>
      )}
      {/* TODO: add other_social_media */}
    </Box>
  );
}

import { Text } from '@chakra-ui/layout';

import { useUserCurrency } from '@revelio/auth';
import { D3ChartNames } from '@revelio/d3';
import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, convertLineToSheet } from '../../../shared/components';
import { adjustLineDataToUserCurrency } from '../../compensation/data-fetch/get-overtime-data';

interface PostingsSalaryLineChartCardProps {
  data: LineData[];
  loading?: boolean;
  colorMap: Record<string, string>;
}

export const PostingsSalaryLineChartCard = ({
  data,
  loading,
  colorMap,
}: PostingsSalaryLineChartCardProps) => {
  const { exchangeRate, code } = useUserCurrency();

  const dataWithColors = data.map((line) => ({
    ...line,
    color: colorMap[line.label],
  }));

  const adjustedData = adjustLineDataToUserCurrency(
    dataWithColors,
    exchangeRate
  );

  return (
    <PlotCard
      title="Salary"
      info={
        <Text variant="tooltip">
          The average salary listed on job postings.
        </Text>
      }
      infoPlacement="top-end"
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      exportData={convertLineToSheet({ data })}
      plotShare={{
        data: adjustedData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: {
            format: FormatType.CURRENCY,
            dateFormat: 'day',
            colors: dataWithColors.map((line) => line.color),
            currencyCode: code,
          },
        },
      }}
    >
      <LineChart
        data={adjustedData}
        format={FormatType.CURRENCY}
        dateFormat="day"
        loading={loading}
        currencyCode={code}
      />
    </PlotCard>
  );
};

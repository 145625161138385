import { LineData, LineDatum } from '@revelio/replots';

import { GetNormalizedDataProps, getNormalizedData } from '../utils';

export const getStatChartData = ({
  data,
  metricName,
}: GetNormalizedDataProps): LineData => {
  const normalizedData = getNormalizedData({ data, metricName });

  const lineDatas = normalizedData.map((d): LineData => {
    return {
      label: d.dimension,
      values: d.timeseries
        .map((t): LineDatum | null => {
          if (!t.date || !t.value) return null;
          return { date: t.date, value: t.value };
        })
        .filter((v) => v !== null),
    };
  });

  return lineDatas[0];
};

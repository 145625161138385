import { ContactSalesButton } from '@revelio/composed';
import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import { useUnleashFlag } from '../../../../shared/unleash-flags/use-unleash-flag';
import Postings, {
  metricModeFilterId,
  providerFilterId,
} from '../../../postings/postings';

const title = [PageTitles.COMPANY, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 6,
  },
];

// const requiredParams = [SelectionCategories.DATE_RANGE_FULL];

export function CompanyPostings() {
  const keywordFiltersFeatureFlag = useUnleashFlag(
    FeatureFlag.CompanyPostingsKeyword
  );

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    SelectionCategories.DATE_RANGE_FULL,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.RAW_TITLE,
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.COMPANY}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_POSTINGS}
      // TODO: Re-enable when data range filter is fixed
      // requiredParams={requiredParams}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.POSTINGS}
      additionalNonActiveFilters={[providerFilterId, metricModeFilterId]}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.JOB_POSTINGS}
          />
        </>
      }
      savedSetView={View.Company}
    />
  );
}

export default CompanyPostings;

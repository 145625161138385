import { CompositionDataQuery } from '@revelio/data-access';

import { GeographySummaryMetric, SummaryEntities } from '../../types';

export const getGeographyCompositionData = (
  data: CompositionDataQuery | undefined
) => {
  const entities = data?.composition;
  if (!entities) {
    return {
      industries: null,
      skills: null,
      job_categories: null,
    };
  }

  const getData = (metric: GeographySummaryMetric) =>
    getBarChartMetricData(entities, metric);

  return {
    industries: getData(GeographySummaryMetric.Industries),
    skills: getData(GeographySummaryMetric.Skills),
    job_categories: getData(GeographySummaryMetric.Roles),
  };
};

const getBarChartMetricData = (
  entities: SummaryEntities,
  metric: GeographySummaryMetric
) => {
  // Get the top-level metadata from the entities
  const topLevelMetadata = entities?.[0]?.metadata;
  // Get the category data from the entities for the specified metric
  const category = entities?.[0]?.metrics?.[metric]?.category;

  // If no category or top-level metadata is present, return null
  if (!category || !topLevelMetadata) {
    return null;
  }

  // Limit the number of categories to a maximum of 7
  const maxLength = category.length > 6 ? 7 : category.length;

  // Build the data structure with top-level metadata and a value array
  return [
    {
      id: topLevelMetadata.id,
      metadata: {
        longName: topLevelMetadata.longName,
        shortName: topLevelMetadata.shortName,
      },
      value: category
        .slice(0, maxLength)
        .map((cat) => {
          const metaData = cat?.metadata;
          const value = cat?.timeseries?.[0]?.share;
          const count = cat?.timeseries?.[0]?.count;

          // If no metadata or value is available for this category, skip it
          if (!metaData || value == null) {
            return null;
          }

          // Return the nested value structure for each category
          return {
            id: metaData.id,
            metadata: {
              longName: metaData.longName,
              shortName: metaData.shortName,
              count,
            },
            value: value,
          };
        })
        .filter(Boolean), // Filter out any null values
    },
  ];
};

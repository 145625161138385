import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Badge,
  VStack,
  HStack,
  Heading,
  Tooltip,
  Icon,
  ExpandedIndex,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  HEALTH_CHECKS,
  HealthCheckConfig,
  getHealthCheckId,
  HealthCheckResult,
} from '@revelio/data-access';
import { GroupedHealthChecks, GroupingType } from './types';
import { HealthCheckDetails } from './components/HealthCheckDetails';
import { SchemaProvider } from './context/SchemaContext';
import {
  getGroupKey,
  getGroupLabel,
  getGroupTooltip,
  processResultsForDisplay,
  getGroupHealth,
} from './utils/health-check.utils';

interface HealthCheckAccordionProps {
  results: HealthCheckResult[];
  onRunSingleCheck: (
    checkId: string,
    variables?: Record<string, unknown>
  ) => Promise<void>;
  isFetching: boolean;
  groupingType?: GroupingType;
}

export const HealthCheckAccordion: React.FC<HealthCheckAccordionProps> = ({
  results,
  onRunSingleCheck,
  isFetching,
  groupingType = 'viewGroup',
}) => {
  const [expandedGroups, setExpandedGroups] = useState<number[]>([]);
  const [expandedViews, setExpandedViews] = useState<number[]>([]);

  // Memoize the final grouped checks with results
  const finalGroupedChecks = React.useMemo(() => {
    // First, group all results by their check ID
    const resultsByCheckId = results.reduce<
      Record<string, HealthCheckResult[]>
    >((acc, result) => {
      const checkId = `${result.service}:${result.viewGroup}:${result.view}`;
      if (!acc[checkId]) {
        acc[checkId] = [];
      }
      acc[checkId].push(result);
      return acc;
    }, {});

    // Then create the final groups with unique checks but full result history
    return HEALTH_CHECKS.reduce<GroupedHealthChecks>((acc, check) => {
      const groupKey = getGroupKey(check, groupingType);
      const checkId = `${check.service}:${check.viewGroup}:${check.view}`;

      if (!acc[groupKey]) {
        acc[groupKey] = {
          checks: [],
          results: [],
        };
      }

      // Only add the check if it's not already in the group
      const checkExists = acc[groupKey].checks.some(
        (c) =>
          c.service === check.service &&
          c.viewGroup === check.viewGroup &&
          c.view === check.view
      );

      if (!checkExists) {
        acc[groupKey].checks.push(check);
      }

      // Always add all results for this check
      const checkResults = resultsByCheckId[checkId] || [];
      acc[groupKey].results.push(...checkResults);

      return acc;
    }, {});
  }, [results, groupingType]);

  // Memoize the view grouped checks function
  const getViewGroupedChecks = React.useCallback(
    (checks: HealthCheckConfig[], results: HealthCheckResult[]) => {
      // First, group all results by checkId
      const resultsByCheckId = results.reduce<
        Record<string, HealthCheckResult[]>
      >((acc, result) => {
        const checkId = `${result.service}:${result.viewGroup}:${result.view}`;
        if (!acc[checkId]) {
          acc[checkId] = [];
        }
        acc[checkId].push(result);
        return acc;
      }, {});

      // Then group by view, keeping all results for each check
      return checks.reduce<
        Record<
          string,
          { checks: HealthCheckConfig[]; results: HealthCheckResult[] }
        >
      >((acc, check) => {
        const viewKey = check.view;
        const checkId = `${check.service}:${check.viewGroup}:${check.view}`;
        const checkResults = resultsByCheckId[checkId] || [];

        if (!acc[viewKey]) {
          acc[viewKey] = { checks: [], results: [] };
        }

        // Only add the check if it's not already in the group
        const checkExists = acc[viewKey].checks.some(
          (c) =>
            c.service === check.service &&
            c.viewGroup === check.viewGroup &&
            c.view === check.view
        );

        if (!checkExists) {
          acc[viewKey].checks.push(check);
        }

        // Add all results for this check
        acc[viewKey].results.push(...checkResults);

        return acc;
      }, {});
    },
    []
  );

  // Memoize the processed results for each group
  const processedGroups = React.useMemo(() => {
    return Object.entries(finalGroupedChecks).map(([groupKey, data]) => {
      const uniqueServiceViewCombos = new Set(
        data.checks.map((check) => `${check.service}:${check.view}`)
      );
      const { latest: latestResults, latestNonManual: latestNonManualResults } =
        processResultsForDisplay(data.results);
      const nonManualResults = Array.from(latestNonManualResults.values());
      const successCount = nonManualResults.filter(
        (result) => result?.status === 'success'
      ).length;
      const totalCount =
        groupingType === 'service'
          ? data.checks.length
          : uniqueServiceViewCombos.size;

      return {
        groupKey,
        data,
        latestResults,
        latestNonManualResults,
        nonManualResults,
        successCount,
        totalCount,
      };
    });
  }, [finalGroupedChecks, groupingType]);

  // Memoize the rendered HealthCheckDetails components
  const renderHealthCheckDetails = React.useCallback(
    (
      check: HealthCheckConfig,
      latestResult: HealthCheckResult | undefined,
      serviceResults: HealthCheckResult[]
    ) => (
      <HealthCheckDetails
        key={getHealthCheckId(check.service, check.viewGroup, check.view)}
        check={check}
        latestResult={latestResult}
        serviceResults={serviceResults}
        onRunSingleCheck={onRunSingleCheck}
        isFetching={isFetching}
        groupingType={groupingType}
      />
    ),
    [onRunSingleCheck, isFetching, groupingType]
  );

  return (
    <SchemaProvider>
      <Accordion
        allowMultiple
        px={4}
        onChange={(expanded: ExpandedIndex) => {
          setExpandedGroups(Array.isArray(expanded) ? expanded : []);
        }}
      >
        {processedGroups.map(
          (
            {
              groupKey,
              data,
              latestResults,
              latestNonManualResults,
              nonManualResults,
              successCount,
              totalCount,
            },
            groupIndex
          ) => (
            <AccordionItem
              key={groupKey}
              borderTop="none"
              borderBottom="none"
              _notLast={{
                '&:not([data-expanded])': {
                  borderBottom: '1px solid',
                  borderColor: 'gray.200',
                },
              }}
            >
              <h2>
                <AccordionButton px={0} py={4} _hover={{ bg: 'transparent' }}>
                  <Box flex="1" textAlign="left">
                    <HStack spacing={1} align="center">
                      <Heading size="md" color="gray.700">
                        {getGroupLabel(groupKey, groupingType, data.checks)}
                      </Heading>
                      {groupingType === 'page' && (
                        <Tooltip
                          label={getGroupTooltip(data.checks)}
                          placement="top"
                        >
                          <Icon
                            as={InfoOutlineIcon}
                            boxSize={3}
                            color="gray.500"
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </Box>
                  <Badge
                    colorScheme={getGroupHealth(nonManualResults)}
                    fontSize="sm"
                    px={2}
                    py={1}
                    borderRadius="md"
                  >
                    {successCount}/{totalCount}
                  </Badge>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px={0}>
                {groupingType === 'viewGroup' ? (
                  <Accordion
                    allowMultiple
                    onChange={(expanded: ExpandedIndex) => {
                      setExpandedViews(Array.isArray(expanded) ? expanded : []);
                    }}
                  >
                    {Object.entries(
                      getViewGroupedChecks(data.checks, data.results)
                    ).map(([viewKey, viewData], viewIndex) => {
                      const {
                        latest: viewLatestResults,
                        latestNonManual: viewLatestNonManualResults,
                      } = processResultsForDisplay(viewData.results);
                      const viewNonManualResults = Array.from(
                        viewLatestNonManualResults.values()
                      );
                      const viewSuccessCount = viewNonManualResults.filter(
                        (result) => result?.status === 'success'
                      ).length;
                      const viewUniqueServices = new Set(
                        viewData.checks.map((check) => check.service)
                      );

                      return (
                        <AccordionItem key={viewKey}>
                          <h2>
                            <AccordionButton pl={4}>
                              <Box flex="1" textAlign="left">
                                <HStack spacing={1} align="center">
                                  <Heading size="sm">
                                    {viewKey.charAt(0).toUpperCase() +
                                      viewKey.slice(1)}
                                  </Heading>
                                  <Tooltip
                                    label={getGroupTooltip(viewData.checks)}
                                    placement="top"
                                  >
                                    <Icon as={InfoOutlineIcon} boxSize={2} />
                                  </Tooltip>
                                </HStack>
                              </Box>
                              <Badge
                                colorScheme={getGroupHealth(
                                  viewNonManualResults
                                )}
                              >
                                {viewSuccessCount}/{viewUniqueServices.size}
                              </Badge>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} pl={4}>
                            <VStack align="stretch" spacing={4}>
                              {expandedGroups.includes(groupIndex) &&
                                expandedViews.includes(viewIndex) &&
                                viewData.checks.map((check) => {
                                  const viewServiceResults =
                                    viewData.results.filter(
                                      (r) =>
                                        r.service === check.service &&
                                        r.viewGroup === check.viewGroup &&
                                        r.view === check.view
                                    );
                                  const viewLatestResult = Array.from(
                                    viewLatestResults.values()
                                  ).find(
                                    (r) =>
                                      r.service === check.service &&
                                      r.viewGroup === check.viewGroup &&
                                      r.view === check.view
                                  );
                                  return renderHealthCheckDetails(
                                    check,
                                    viewLatestResult,
                                    viewServiceResults
                                  );
                                })}
                            </VStack>
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                ) : (
                  <VStack align="stretch" spacing={4}>
                    {expandedGroups.includes(groupIndex) &&
                      data.checks.map((check) => {
                        const groupServiceResults = data.results.filter(
                          (r) =>
                            r.service === check.service &&
                            r.viewGroup === check.viewGroup &&
                            r.view === check.view
                        );
                        const groupLatestResult = Array.from(
                          latestResults.values()
                        ).find(
                          (r) =>
                            r.service === check.service &&
                            r.viewGroup === check.viewGroup &&
                            r.view === check.view
                        );
                        return renderHealthCheckDetails(
                          check,
                          groupLatestResult,
                          groupServiceResults
                        );
                      })}
                  </VStack>
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        )}
      </Accordion>
    </SchemaProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { noop } from 'lodash';

import { removeLoadingStatus } from '@revelio/core';

import { endall } from './endall-transition';

export const notifyChartRenderComplete = (
  chart,
  requestHash,
  alsoRun = noop
) => {
  chart
    .transition()
    .duration(0)
    .call(endall, () => {
      alsoRun();
      removeLoadingStatus([requestHash, 'tabChange']);
    });
};

import { Box, BoxProps, CardProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import {
  FiDownload,
  FiImage,
  FiLink,
  FiMaximize2,
  FiMoreVertical,
} from 'react-icons/fi';

import { useUserTrialDetails } from '@revelio/auth';
import {
  ActionMenuButton,
  DownloadModal,
  PlotLinkModal,
} from '@revelio/composed';
import { ActionMenu } from '@revelio/core';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { PlotData } from '@revelio/layout';

import { useDownloadData } from '../../hooks/useDownloadPlotData';
import { PlotExpandModal } from './plot-expand-modal';

export const PlotActionMenu = ({
  title,
  chartType,
  chartProps,
  expandedPlot,
  disableExpand = false,
  disableLink = false,
  disablePNGDownload = false,
  disableDownloadData = false,
  downloadPath,
  ...boxProps
}: {
  title: string;
  chartType: D3ChartNames;
  chartProps: ID3ChartProps;
  expandedPlot: React.ReactNode;
  disableExpand?: boolean;
  disableLink?: boolean;
  disablePNGDownload?: boolean;
  disableDownloadData?: boolean;
  downloadPath?: string;
} & BoxProps) => {
  const plotDownloadPNGDisclosure = useDisclosure();
  const plotExpandDisclosure = useDisclosure();
  const plotLinkDisclosure = useDisclosure();
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: true,
  });

  const { triggerDownload, isLoading } = useDownloadData({
    downloadPath,
  });
  const baseMenuActionItems = [
    !disableLink && (
      <ActionMenuButton
        key={`${title}-plot-link`}
        icon={FiLink}
        text="Plot Link"
        disabled={isTrialUser}
        onClick={plotLinkDisclosure.onOpen}
      />
    ),
    !disablePNGDownload && (
      <ActionMenuButton
        key={`${title}-plot-download-png`}
        icon={FiImage}
        text="Download PNG"
        disabled={isTrialUser}
        onClick={plotDownloadPNGDisclosure.onOpen}
      />
    ),
    (!disableDownloadData || !downloadPath) && (
      <ActionMenuButton
        key={`${title}-plot-download-data`}
        icon={FiDownload}
        text="Download Data"
        isLoading={isLoading}
        disabled={isTrialUser}
        onClick={triggerDownload}
      />
    ),
  ].filter(Boolean);

  return (
    <Box {...boxProps}>
      <ActionMenu menuButtonIcon={FiMoreVertical} portalMenu={true}>
        {!disableExpand && (
          <ActionMenuButton
            icon={FiMaximize2}
            text="Expand Plot"
            onClick={plotExpandDisclosure.onOpen}
          />
        )}
        {baseMenuActionItems}
      </ActionMenu>
      <PlotExpandModal
        title={title}
        {...plotExpandDisclosure}
        topRight={
          <ActionMenu menuButtonIcon={FiMoreVertical} portalMenu={false}>
            {baseMenuActionItems}
          </ActionMenu>
        }
      >
        {expandedPlot}
      </PlotExpandModal>
      <PlotLinkModal
        plotLinkModalState={{
          isPlotLinkOpen: plotLinkDisclosure.isOpen,
          onPlotLinkOpen: plotLinkDisclosure.onOpen,
          onPlotLinkClose: plotLinkDisclosure.onClose,
        }}
        cardConfig={
          {
            header: title,
          } as Partial<CardProps>
        }
        typeAndProps={{
          chartType,
          chartProps: chartProps as ID3ChartProps,
        }}
        data={chartProps.data as PlotData}
      />
      <DownloadModal
        {...plotDownloadPNGDisclosure}
        header={title}
        downloadDimension={{ height: 0, width: 0 }} // should make prop optional
        typeAndProps={{
          chartType,
          chartProps: chartProps as ID3ChartProps,
        }}
        isFullscreenMode={true}
        data={chartProps.data as PlotData}
      />
    </Box>
  );
};

import { useContext, useState } from 'react';

import { CompanySelectionTypes } from '@revelio/core';

import ConfirmationMappedColumns from '../../../file-upload/confirmation-mapped-columns';
import { FileUploadContext } from '../../../file-upload/file-upload-context';
import {
  sendCompanyMapping,
  transformUploadedCSVToMappedSupportedColumnCSV,
} from './file-to-company-mapping';
import UploadCompaniesModal from './upload-companies-modal';

const ConfirmationStep = ({
  setCompanyColumnSelectionModalOpen,
}: {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  const [isLoadingCompanyMapping, setIsLoadingCompanyMapping] = useState(false);
  const { mappedColumns, mappedWorkbook, headerIndex } =
    useContext(FileUploadContext);
  return (
    <UploadCompaniesModal
      isLoadingContinue={isLoadingCompanyMapping}
      onContinue={async () => {
        setIsLoadingCompanyMapping(true);

        const companyListFile = transformUploadedCSVToMappedSupportedColumnCSV({
          mappedColumns,
          mappedWorkbook,
          headerIndex,
        });
        await sendCompanyMapping(
          companyListFile,
          setCompanyColumnSelectionModalOpen
        );

        setIsLoadingCompanyMapping(false);
      }}
    >
      <ConfirmationMappedColumns mappedColumns={mappedColumns} />
    </UploadCompaniesModal>
  );
};

export default ConfirmationStep;

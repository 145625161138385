import { produce } from 'immer';
import {
  assign,
  get,
  includes,
  invert,
  isArray,
  isEmpty,
  isUndefined,
  map,
  memoize,
  omit,
  padStart,
} from 'lodash';
import { Get, JsonValue } from 'type-fest';

import { Views } from '@revelio/core';
import { CustomRoleTaxonomySelection, Filters } from '@revelio/data-access';

import {
  FilterFormatOverrides,
  ViewsForDefaultsOnly,
} from '../data-api/data-api.model';
import {
  getSelectedLastMonth,
  getStartDateConst,
} from '../filter-components/date-range/helpers';
import {
  ALL_ROLE_FILTERS,
  DateMonthFilterNames,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PYTHON_COMPANY_SELECTION_ID,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  filterLabelLookup,
  presetFilterKeyLookup,
  queryParamKeyLookup,
} from './filters.constants';
import { listNameOverrides } from './filters.core';
import { SerializedDateRangeFilterIds } from './filters.deepLinks.model';
import {
  BooleanFilter,
  DateFilter,
  DateRangeFormattedValues,
  EnumeratedFilters,
  Filter,
  FilterBase,
  FilterItem,
  FilterList,
  FilterNameIdsOrString,
  FilterTypes,
  IFilterTypeHandler,
  LocalSelectionCategories,
  OtherFilterNames,
  QueryParameterKeys,
  RangeFilter,
  RangeFullFilter,
  RoleSelectionCategories,
  SelectFilter,
  SelectionCategories,
  SubFilterNames,
  ValidValueTypes,
} from './filters.model';
import { SelectionListToRoleTaxonomyOverride } from './selection-lists-data-transforms';

const SelectFilters: string[] = [
  ...Object.values(SelectionCategories),
  ...Object.values(LocalSelectionCategories),
  ...Object.values(SubFilterNames),
  PYTHON_COMPANY_SELECTION_ID,
];
const createDefaultFilterBase = (type: FilterTypes) => ({
  type,
});
const DefaultSelectFilterBase: Partial<SelectFilter> = {
  isMulti: true,
  type: FilterTypes.SELECT,
  selectionListId: undefined,
};

function filterTypeLookupById(id: string) {
  const isRangeType = (DateMonthFilterNames as string[]).includes(id);
  if (isRangeType) {
    return FilterTypes.DATE_RANGE;
  }

  if (id === SelectionCategories.SNAPSHOT_DATE) {
    return FilterTypes.DATE;
  }

  const BooleanFilters: string[] = [
    OtherFilterNames.GROUPED,
    OtherFilterNames.INFLOW,
    OtherFilterNames.OUTFLOW,
  ];
  const isBoolenType = BooleanFilters.includes(id);
  if (isBoolenType) {
    return FilterTypes.BOOLEAN;
  }

  const isSelectType = SelectFilters.includes(id);
  if (isSelectType) {
    return FilterTypes.SELECT;
  }

  return '';
}

export const customFormatterLookup: Record<
  string,
  Get<FilterBase, 'customFormatter'>
> = {
  [LocalSelectionCategories.INFLOW_OR_OUTFLOW]: (f: any, i: number) => {
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]:
        f.value.id == OtherFilterNames.INFLOW,
    };
  },
  [SelectionCategories.COMPANY_NEW_DASHBOARD]: (f: any, i: number) => {
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]:
        f.formatOverride === FilterFormatOverrides.PRESETS
          ? f.value
          : f.value.map((company: any) => company.id),
    };
  },
  [SelectionCategories.DATE_RANGE]: (f: any, i: number) => {
    return {
      [QueryParameterKeys.START_TIME]: f.value.startDate,
      [QueryParameterKeys.END_TIME]: f.value.endDate,
    };
  },
  [SelectionCategories.DATE_RANGE_FULL]: (f: any, i: number) => {
    return {
      [QueryParameterKeys.START_TIME]: f.value.startDate,
      [QueryParameterKeys.END_TIME]: f.value.endDate,
    };
  },
  [LocalSelectionCategories.DATA_METRIC]: (f: any) => {
    if (f.formatOverride === FilterFormatOverrides.PRESETS) {
      return { [f.id]: f.value };
    }

    const boolValue = !!f.value.value;
    return boolValue ? { [f.id]: boolValue } : {};
  },
};

const baseFilterLookup = {
  [FilterTypes.SELECT]: DefaultSelectFilterBase,
  [FilterTypes.DATE_RANGE]: createDefaultFilterBase(FilterTypes.DATE_RANGE),
  [FilterTypes.DATE_RANGE_FULL]: createDefaultFilterBase(
    FilterTypes.DATE_RANGE_FULL
  ),
  [FilterTypes.DATE]: createDefaultFilterBase(FilterTypes.DATE),
  [FilterTypes.BOOLEAN]: createDefaultFilterBase(FilterTypes.BOOLEAN),
  '': { type: FilterTypes.SELECT },
};

const getFilerBaseById = memoize((filterId: string) => {
  const filterType = filterTypeLookupById(filterId);
  return { type: filterType, base: baseFilterLookup[filterType] };
});

export function buildFilters(
  filters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>,
  meta?: JsonValue,
  view?: Views | ViewsForDefaultsOnly
): Filter[] {
  // date range filters have their own deserialisation because it relies on multiple filters instead of just one
  const filtersWithoutDateRanges = omit(
    filters,
    Object.values(SerializedDateRangeFilterIds)
  );

  const builtFiltersWithoutDateRange = map(
    filtersWithoutDateRanges,
    (
      value: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>,
      filterId: FilterNameIdsOrString
    ) => {
      const apiDeserializedId =
        invert(presetFilterKeyLookup)[filterId as string] || filterId;
      const { type, base } = getFilerBaseById(
        apiDeserializedId as unknown as string
      );
      const builtFilter: Omit<FilterBase, 'value'> & {
        value:
          | FilterBase['value']
          | EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>;
      } = {
        ...base,
        id: apiDeserializedId as LocalSelectionCategories,
        label:
          filterLabelLookup[apiDeserializedId as SelectionCategories] ||
          (apiDeserializedId as string),
        value:
          type == FilterTypes.BOOLEAN ? (value as string) === 'True' : value,
      };
      if (apiDeserializedId === LocalSelectionCategories.DATA_METRIC && meta) {
        // this will only be added for the saved presets rather than view defaults (no meta on view defaults)
        builtFilter.isUserSubmitted = true;
      }

      if (get(listNameOverrides, apiDeserializedId)) {
        // this will only be added for the saved presets rather than view defaults (no meta on view defaults)
        builtFilter.id = get(listNameOverrides, apiDeserializedId);
      }

      if (type == FilterTypes.SELECT) {
        assign(builtFilter, {
          selectionListId: get(
            meta,
            `filterSelectionListMap[${apiDeserializedId as string}]`,
            apiDeserializedId
          ),
          isMulti: isArray(value),
        });
      }

      if (builtFilter.id == SelectionCategories.SNAPSHOT_DATE) {
        const maxEndDate = getSelectedLastMonth(view as Views);
        (builtFilter as DateFilter).isMaximumRange = value === maxEndDate;
      }

      return builtFilter;
    }
  );

  const buildDateRangeFilter = ({
    dateRangeType,
    startDate,
    endDate,
    view,
  }: {
    dateRangeType:
      | SelectionCategories.DATE_RANGE
      | SelectionCategories.DATE_RANGE_FULL;
    startDate: string;
    endDate: string;
    view?: Views | ViewsForDefaultsOnly;
  }) => {
    const earliestStartDate = getStartDateConst(view);
    const hasMaximumStartDate = earliestStartDate === startDate;

    const maxEndDate = getSelectedLastMonth(view as Views);
    const hasMaximumEndDate = maxEndDate === endDate;

    const filterTypeLookup = {
      [SelectionCategories.DATE_RANGE]: FilterTypes.DATE_RANGE,
      [SelectionCategories.DATE_RANGE_FULL]: FilterTypes.DATE_RANGE_FULL,
    };

    const type = filterTypeLookup[dateRangeType];

    const [startYear, startMonth, startDay] = startDate.split('-');
    const [endYear, endMonth, endDay] = endDate.split('-');

    const formattedStartDate =
      type == FilterTypes.DATE_RANGE_FULL
        ? [startYear, padStart(startMonth, 2, '0'), padStart(startDay, 2, '0')]
        : [startYear, padStart(startMonth, 2, '0')];

    const formattedEndDate =
      type == FilterTypes.DATE_RANGE_FULL
        ? [endYear, padStart(endMonth, 2, '0'), padStart(endDay, 2, '0')]
        : [endYear, padStart(endMonth, 2, '0')];

    return {
      id: dateRangeType,
      type,
      label: dateRangeType,
      value: {
        startDate: formattedStartDate.join('-'),
        endDate: formattedEndDate.join('-'),
      },
      isMaximumRange: hasMaximumStartDate && hasMaximumEndDate,
    };
  };

  const { startDate, endDate, startDateFull, endDateFull } = filters as {
    [key in SerializedDateRangeFilterIds]?: string;
  };

  const builtDateRangeFilters = [
    startDate &&
      endDate &&
      buildDateRangeFilter({
        dateRangeType: SelectionCategories.DATE_RANGE,
        startDate,
        endDate,
        view,
      }),
    startDateFull &&
      endDateFull &&
      buildDateRangeFilter({
        dateRangeType: SelectionCategories.DATE_RANGE_FULL,
        startDate: startDateFull,
        endDate: endDateFull,
        view,
      }),
  ].filter(Boolean);

  const builtFilters =
    builtDateRangeFilters.length === 0
      ? builtFiltersWithoutDateRange
      : [
          ...builtFiltersWithoutDateRange,
          ...(builtDateRangeFilters as RangeFilter[]),
        ];
  return builtFilters as Filter[];
}

// DEPRECATED: use function serializeFilters at end of file instead (simpler)
export function formatAndBreakoutFilters(
  filters: Filter[],
  brokenOutFilters: FilterBase['id'][] = [],
  useCustomFormatter = true
) {
  let separateFilters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]> =
    {};

  const formattedFilter = filters.reduce<
    EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>
  >((built, f, idx) => {
    const customFormatter = f.customFormatter || customFormatterLookup[f.id];
    const formattedFilter =
      useCustomFormatter && customFormatter
        ? customFormatter(f, idx)
        : filterTypehandler[(f as SelectFilter).formatOverride || f.type](
            f as any,
            idx
          );
    if (brokenOutFilters.includes(f.id)) {
      separateFilters = { ...separateFilters, ...formattedFilter };
      return built;
    }
    return { ...built, ...formattedFilter };
  }, {});
  return { separateFilters, formattedFilter };
}

/**
 * Lookup object for FilterTypes each key containing a function that takes the Filter and returns the formatted result ready to submit to the REST API
 */
export const filterTypehandler: IFilterTypeHandler = {
  [FilterFormatOverrides.PRESETS]: (f: SelectFilter) => {
    return {
      [presetFilterKeyLookup[f.id as SelectionCategories] || f.id]: f.value,
    };
  },
  [FilterTypes.SELECT]: (f: SelectFilter) => {
    // eslint-disable-next-line no-nested-ternary
    const val = f.isMulti
      ? (f as SelectFilter<FilterList<string | number>>).value?.map((v) =>
          isUndefined(v?.value) ? v?.id : v?.value
        )
      : isUndefined(
            (f as SelectFilter<FilterItem<string | number>>).value.value
          )
        ? (f as SelectFilter<FilterItem<string | number>>).value.id
        : (f as SelectFilter<FilterItem<string | number>>).value.value;
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]:
        f.isMulti && isEmpty(val) ? undefined : val,
    };
  },
  [FilterTypes.DATE]: (f: DateFilter) => {
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]: f.value,
    };
  },
  [FilterTypes.DATE_RANGE]: (f: RangeFilter) => {
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]: f.value,
    };
  },
  [FilterTypes.DATE_RANGE_FULL]: (f: RangeFullFilter) => {
    return {
      [queryParamKeyLookup[f.id as SelectionCategories] || f.id]: f.value,
    };
  },
  [FilterTypes.BOOLEAN]: (f: BooleanFilter) => {
    return { [f.id]: f.value };
  },
  [FilterTypes.STRING_LIST]: (f: any) => {
    return { [f.id]: f.value.map((v: any) => v.value) };
  },
  [Views.SCREENER]: (f: SelectFilter, idx: number | undefined) => {
    const primary_filter = get(f, 'primaryFilter');
    const original = filterTypehandler[f.type](f);
    const originalValue = original[f.id];
    original[f.id] = [{ id: idx, values: originalValue }];
    return { primary_filter, ...original };
  },
};

type SerializedFiltersOptions<T> = {
  overwrites?: Partial<T>;
  isCustomRoleTaxonomyEnabled: boolean;
};
export const serializeFilters = <T extends Filters>(
  filters: Filter[],
  options: SerializedFiltersOptions<T>
): T => {
  const standardMultiValueFilters = [
    SelectionCategories.RICS_K10,
    SelectionCategories.RICS_K50,
    SelectionCategories.COMPANY,

    ...ROLE_GRANULARITY_FILTERS,
    ...GEOGRAPHY_GRANULARITY_FILTERS,
    ...SKILL_GRANULARITY_FILTERS,

    SelectionCategories.SENIORITY,
  ];

  const serializedFilters: Partial<T> = filters.reduce((acc, filter) => {
    if (standardMultiValueFilters.includes(filter.id as SelectionCategories)) {
      return {
        ...acc,
        [filter.id]: (filter.value as FilterList).map((item) => item.id),
      };
    }

    if (filter.id === SelectionCategories.DATE_RANGE) {
      return {
        ...acc,
        start_date: (filter.value as DateRangeFormattedValues).startDate,
        end_date: (filter.value as DateRangeFormattedValues).endDate,
      };
    }

    return acc;
  }, {});

  const roleTaxonomyId = filters.find(
    (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
  );

  return options.isCustomRoleTaxonomyEnabled
    ? ({
        ...convertRoleToCustomRoleFilter({
          formattedFilters: serializedFilters as EnumeratedFilters<
            ValidValueTypes | ValidValueTypes[]
          >,
          customRoleTaxonomyId:
            roleTaxonomyId?.value as CustomRoleTaxonomySelection,
        }),
        ...(options.overwrites || {}),
      } as T)
    : ({ ...serializedFilters, ...(options.overwrites || {}) } as T);
};

export const convertRoleToCustomRoleFilter = ({
  formattedFilters,
  customRoleTaxonomyId,
}: {
  formattedFilters: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>;
  customRoleTaxonomyId: CustomRoleTaxonomySelection;
}) => {
  const convertedIndividualRoleFiltersToFlatCustomTaxonomyFilter = Object.keys(
    formattedFilters
  )
    .filter((key) => includes(ALL_ROLE_FILTERS, key as SelectionCategories))
    .reduce(
      (acc, key) => {
        const roleFilterValues = formattedFilters[
          key as SelectionCategories
        ] as ValidValueTypes[];
        const transformedRoleFilterValuesToCustomRoleEntities =
          roleFilterValues?.map((value) => ({
            levelId: SelectionListToRoleTaxonomyOverride(
              key as RoleSelectionCategories
            ),
            id: value as string,
          }));
        return [...acc, ...transformedRoleFilterValuesToCustomRoleEntities];
      },
      [] as { levelId: string; id: string }[]
    );

  const customRoleFilter: Filters['custom_role'] = {
    taxonomyId: customRoleTaxonomyId,
    ...(convertedIndividualRoleFiltersToFlatCustomTaxonomyFilter.length > 0
      ? { entities: convertedIndividualRoleFiltersToFlatCustomTaxonomyFilter }
      : {}),
  };

  // remove previous role filters
  const filtersWithRolesRemoved = produce(formattedFilters, (draft) => {
    ALL_ROLE_FILTERS.forEach((roleFilter) => {
      if (draft[roleFilter]) {
        delete draft[roleFilter];
      }
    });
  });
  return {
    ...filtersWithRolesRemoved,
    custom_role: customRoleFilter,
  };
};

import { LineData } from '@revelio/replots';

import { SentimentLineChartData } from '../types';

const THRESHOLD = 0.1;

export const filterLowQualityData = (
  data: SentimentLineChartData
): SentimentLineChartData => {
  return Object.entries(data).reduce<SentimentLineChartData>(
    (acc, [key, lines]) => {
      const filteredValues = lines.map((entity): LineData => {
        const lastValue =
          entity?.values?.[entity.values.length - 1]?.secondaryValue;
        if (!lastValue) return entity;

        // find first instance of entity.values that is greater than THRESHOLD * lastValue;
        const firstValueGreaterThanThreshold = entity.values.findIndex(
          (value) =>
            value.secondaryValue && value.secondaryValue > THRESHOLD * lastValue
        );
        if (firstValueGreaterThanThreshold === -1) return entity;

        return {
          ...entity,
          values: entity.values.slice(firstValueGreaterThanThreshold),
        };
      });

      return { ...acc, [key]: filteredValues };
    },
    data
  );
};

import { propsFactory } from '@ngneat/elf';

import {
  CompanySelectionTypes,
  SubsidiaryMappingKeys,
  SubsidiaryMappingWorkflow,
} from '@revelio/core';
import { Item } from '@revelio/filtering';

import { deliverablesStore } from '../../deliverables.repository';

export const {
  withSubsidiaryColumnMappings,
  updateSubsidiaryColumnMappings,
  selectSubsidiaryColumnMappings,
  resetSubsidiaryColumnMappings,
  getSubsidiaryColumnMappings,
  setSubsidiaryColumnMappings,
} = propsFactory('subsidiaryColumnMappings', {
  initialValue: {
    previousStep: null,
    mappings: {
      primary: {
        title: 'Primary',
        name: 'Primary',
        selectAll: true,
        isLoading: false,
        subsidiaries: [],
      },
    },
  } as SubsidiaryMappingWorkflow,
});

export const {
  withSubsidiarySelectedColumns,
  updateSubsidiarySelectedColumns,
  selectSubsidiarySelectedColumns,
  resetSubsidiarySelectedColumns,
  getSubsidiarySelectedColumns,
  setSubsidiarySelectedColumns,
} = propsFactory('subsidiarySelectedColumns', {
  initialValue: ['primary'] as SubsidiaryMappingKeys[],
});

export const updateSubsidiaryMappingPrimaryCompanies = (updates: Item[]) => {
  const formatted = updates.map((update) => {
    const { rcid, raw_emp_count, has_subsidiaries, primary_name, company_id } =
      update;

    return {
      title: primary_name,
      id: primary_name.split(' ').join('-'),
      company_id: company_id,
      hasSubsidiaries: has_subsidiaries,
      headCount: raw_emp_count,
      rcid,
      isSelected: true,
      isIndeterminate: false,
    };
  });

  deliverablesStore.update(
    updateSubsidiaryColumnMappings((prevMapping) => ({
      ...prevMapping.subsidiaryColumnMappings,
      mappings: {
        ...prevMapping.subsidiaryColumnMappings.mappings,
        primary: {
          ...prevMapping.subsidiaryColumnMappings.mappings.primary,
          subsidiaries: formatted.sort((a, b) => b.headCount - a.headCount),
        },
      },
    }))
  );
};

export const updateSubsidiaryMappingPreviousStep = (
  previousStep: CompanySelectionTypes
) => {
  deliverablesStore.update(
    updateSubsidiaryColumnMappings((prevMapping) => ({
      ...prevMapping.subsidiaryColumnMappings,
      previousStep,
    }))
  );
};

// so we can easily identify and exclude e2e test users from extra services (e.g. analytics)
export const E2E_TEST_USER_SHARED_NAME = 'testing.framework';

export enum E2eTestUsernames {
  DISABLED_CUSTOM_TAXONOMY = `${E2E_TEST_USER_SHARED_NAME}.adaptive.disabled@reveliolabs.com`, // client group: Testing Framework - Adaptive Disabled
  SAVED_FILTERS_READ_ONLY = `${E2E_TEST_USER_SHARED_NAME}+saved_filters_read_only@reveliolabs.com`, // client group: Testing Framework
  STANDARD = `${E2E_TEST_USER_SHARED_NAME}@reveliolabs.com`, // client group: Testing Framework
  TRIAL = `${E2E_TEST_USER_SHARED_NAME}+trial_user@reveliolabs.com`, // client group: Testing Framework - Trial User
  TRIAL_EXPIRED = `${E2E_TEST_USER_SHARED_NAME}+trial_user_expired@reveliolabs.com`, // client group: revelio_self_signup
}

import { Views } from '@revelio/core';

import {
  BuildRequestConfig,
  EndpointSegment,
  ViewTypes,
} from './data-api.model';

export const endpointConfigs: { [key in EndpointSegment]: BuildRequestConfig } =
  {
    transition: {
      name: 'transition',
      endpoint: 'transition',
      gqlDataEndpoint: 'transition',
      viewsIncluded: [[Views.TRANSITION, ViewTypes.SHARES_SANKEY]],
    },
    university: {
      name: 'university',
      endpoint: 'university',
      viewsIncluded: [[Views.TRANSITION, ViewTypes.SHARES_SANKEY]],
    },
    role: {
      name: 'role',
      endpoint: 'role',
      gqlDataEndpoint: 'role',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    geo: {
      name: 'geo',
      endpoint: 'geo',
      gqlDataEndpoint: 'geography',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    industry: {
      name: 'industry',
      endpoint: 'industry',
      gqlDataEndpoint: 'industry',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    skill: {
      name: 'skill',
      endpoint: 'skill',
      gqlDataEndpoint: 'skill',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    seniority: {
      name: 'seniority',
      endpoint: 'seniority',
      gqlDataEndpoint: 'seniority',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    gender: {
      endpoint: 'gender',
      gqlDataEndpoint: 'gender',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    ethnicity: {
      endpoint: 'ethnicity',
      gqlDataEndpoint: 'ethnicity',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    education: {
      endpoint: 'education',
      gqlDataEndpoint: 'education',
      viewsIncluded: [
        Views.OVERVIEW,
        [Views.TRANSITION, ViewTypes.SHARES_TRANSITION],
      ],
    },
    headcount: {
      endpoint: 'headcount',
      gqlDataEndpoint: 'headcount',
      viewsIncluded: [Views.OVERVIEW],
    },
    growth: {
      endpoint: 'growth_rate',
      gqlDataEndpoint: 'growth-rate',
      viewsIncluded: [Views.OVERVIEW],
    },
    growth_grouped: {
      endpoint: 'growth',
      gqlDataEndpoint: 'growth-rate',
      viewsIncluded: [Views.OVERVIEW],
    },
    shares_snapshot: {
      endpoint: 'skill',
      viewsIncluded: [Views.OVERVIEW],
    },
    shares_overtime: {
      endpoint: 'skill',
      viewsIncluded: [Views.OVERVIEW],
    },
    keyword_overtime: {
      endpoint: 'keyword',
      viewsIncluded: [Views.OVERVIEW],
    },
    hiring: {
      endpoint: 'hiring_rate',
      gqlDataEndpoint: 'hiring-rate',
      viewsIncluded: [Views.OVERVIEW],
    },
    attrition: {
      endpoint: 'attrition_rate',
      gqlDataEndpoint: 'attrition-rate',
      viewsIncluded: [Views.OVERVIEW],
    },
    tenure: {
      endpoint: 'tenure',
      gqlDataEndpoint: 'tenure',
      viewsIncluded: [Views.OVERVIEW],
    },
    salary: {
      endpoint: 'salary',
      gqlDataEndpoint: 'salary',
      viewsIncluded: [Views.OVERVIEW, Views.POSTING],
    },
    timetofill: {
      endpoint: 'time_to_fill',
      gqlDataEndpoint: 'time-to-fill',
      viewsIncluded: [Views.OVERVIEW, Views.POSTING, Views.COMPENSATION],
    },
    keyword: {
      endpoint: 'keyword',
      viewsIncluded: [[Views.POSTING, ViewTypes.OVERTIME]],
    },
    jobposting: {
      name: 'job_posting',
      endpoint: 'job_posting',
      viewsIncluded: [],
    },
    posting: {
      endpoint: 'posting',
      gqlDataEndpoint: 'posting',
      viewsIncluded: [
        [Views.POSTING, ViewTypes.KEYWORD],
        [Views.POSTING, ViewTypes.OVERTIME],
      ],
    },
    posting_multi: {
      endpoint: 'posting_multi',
      gqlDataEndpoint: 'posting',
      viewsIncluded: [
        [Views.POSTING, ViewTypes.KEYWORD],
        [Views.POSTING, ViewTypes.OVERTIME],
      ],
    },
    numberofcompanies: {
      endpoint: 'number_of_companies',
      viewsIncluded: [[Views.POSTING, ViewTypes.KEYWORD]],
    },
    top: {
      endpoint: 'top',
      viewsIncluded: [[Views.POSTING, ViewTypes.SHARES]],
      gqlDataEndpoint: 'top-role',
    },
    topcompanies: {
      endpoint: 'top_companies',
      viewsIncluded: [],
    },
    overall: {
      endpoint: 'overall',
      gqlDataEndpoint: 'overall',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    business: {
      endpoint: 'business',
      gqlDataEndpoint: 'business-outlook',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    career: {
      endpoint: 'career',
      gqlDataEndpoint: 'career-opportunities',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    compensation: {
      endpoint: 'compensation',
      gqlDataEndpoint: 'compensation-benefits',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    worklife: {
      endpoint: 'worklife',
      gqlDataEndpoint: 'work-life-balance',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    leadership: {
      endpoint: 'leadership',
      gqlDataEndpoint: 'senior-leadership',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    culture: {
      endpoint: 'culture',
      gqlDataEndpoint: 'culture-value',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    diversity: {
      endpoint: 'diversity',
      gqlDataEndpoint: 'diversity-inclusion',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    recommend: {
      endpoint: 'recommend',
      gqlDataEndpoint: 'recommend-friend',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.METRIC],
        [Views.SENTIMENT_RATING, ViewTypes.SNAPSHOT],
        [Views.SENTIMENT_RATING, ViewTypes.OVERTIME],
      ],
    },
    score: {
      name: 'effect',
      endpoint: 'score',
      gqlDataEndpoint: 'score',
      viewsIncluded: [Views.SENTIMENT_RATING],
    },
    positive: {
      endpoint: 'positive',
      gqlDataEndpoint: 'positive',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.SCORE],
        [Views.SENTIMENT_RATING, ViewTypes.REVIEWS],
      ],
    },
    negative: {
      endpoint: 'negative',
      gqlDataEndpoint: 'negative',
      viewsIncluded: [
        [Views.SENTIMENT_RATING, ViewTypes.SCORE],
        [Views.SENTIMENT_RATING, ViewTypes.REVIEWS],
      ],
    },
    qualification: {
      endpoint: 'qualification',
      viewsIncluded: [Views.OVERVIEW],
    },
    task: {
      endpoint: 'task',
      viewsIncluded: [Views.OVERVIEW],
    },
    broad_activity: {
      endpoint: 'broad_activity',
      viewsIncluded: [Views.OVERVIEW],
    },
    taxonomy_landscape: {
      endpoint: 'job',
      viewsIncluded: [Views.OVERVIEW],
    },
    taxonomy_hierarchy: {
      endpoint: 'job',
      viewsIncluded: [Views.OVERVIEW],
    },
    job: {
      endpoint: 'job',
      viewsIncluded: [Views.OVERVIEW],
    },
    activity: {
      endpoint: 'activity',
      viewsIncluded: [Views.OVERVIEW],
    },
    word: {
      endpoint: 'word',
      viewsIncluded: [Views.OVERVIEW],
    },
    stats: {
      endpoint: 'stats',
      viewsIncluded: [[Views.NONE, ViewTypes.NONE]],
    },
    talent_discovery: {
      endpoint: 'talent_discovery',
      viewsIncluded: [Views.OVERVIEW],
    },
    metric_grouped: {
      endpoint: 'metric_grouped',
      viewsIncluded: [Views.OVERVIEW],
    },
    top_geographies: {
      endpoint: 'top_geographies',
      viewsIncluded: [[Views.COMPENSATION, ViewTypes.ROLE]],
    },
    top_companies: {
      endpoint: 'top_companies',
      viewsIncluded: [[Views.COMPENSATION, ViewTypes.ROLE]],
    },
    top_roles: {
      endpoint: 'top_roles',
      viewsIncluded: [
        [Views.COMPENSATION, ViewTypes.GEO],
        [Views.COMPENSATION, ViewTypes.COMPANY],
      ],
    },
    overtime: {
      endpoint: 'overtime',
      viewsIncluded: [[Views.COMPENSATION, ViewTypes.ROLE]],
    },
    distribution: {
      endpoint: 'distribution',
      viewsIncluded: [[Views.COMPENSATION, ViewTypes.ROLE]],
    },
  };

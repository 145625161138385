import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import { useUnleashFlag } from '../../../../shared/unleash-flags/use-unleash-flag';
import Postings, {
  metricModeFilterId,
  providerFilterId,
} from '../../../postings/postings';

const title = [PageTitles.ROLE, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 5,
  },
];

export function RolePostings() {
  const keywordFiltersFeatureFlag = useUnleashFlag(
    FeatureFlag.RolePostingsKeyword
  );

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE_FULL,
    SelectionCategories.RAW_TITLE,
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.ROLE}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_POSTINGS}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.POSTINGS}
      additionalNonActiveFilters={[providerFilterId, metricModeFilterId]}
      savedSetView={View.Role}
    />
  );
}

export default RolePostings;

import { PipelineColumnType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';

import { addDefaultIsSelectedToSubMenu } from './helpers';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  STANDARD_COMPANY_COLUMN,
} from './shared';

export const TRANSITION_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: null,
    columns: [
      {
        id: PipelineColumnType.UserId,
        label: 'User ID',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.PositionId,
        label: 'Position ID',
        defaultIsSelected: true,
      },
      STANDARD_COMPANY_COLUMN,
      { id: PipelineColumnType.Seniority, defaultIsSelected: true },
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
      }),

      {
        ...DEFAULT_ROLE_PIPELINE_COLUMNS,
        menuItems: [
          {
            id: PipelineColumnType.JobCategory,
            label: 'Role k7 (Job Category)',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.RoleK50,
            label: 'Role k50',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.RoleK150,
            label: 'Role k150',
            defaultIsSelected: true,
          },
          { id: PipelineColumnType.RoleK1000, label: 'Role k1000' },
          { id: PipelineColumnType.RoleK1250, label: 'Role k1250' },
          { id: PipelineColumnType.MappedRole, label: 'Role k1500' },
          {
            id: PipelineColumnType.TitleRaw,
            label: 'Raw Title',
            defaultIsSelected: true,
          },
        ],
      },
      {
        id: PipelineColumnType.Startdate,
        label: 'Start Date',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.Enddate,
        label: 'End Date',
        defaultIsSelected: true,
      },
      { id: PipelineColumnType.Salary, defaultIsSelected: true },
      {
        id: PipelineColumnType.Rn,
        label: 'Position Number',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.UltParPrimaryName,
        label: 'Ultimate Parent Company',
        combineWith: [PipelineColumnType.UltParRcid],
      },
    ],
    supportsCustomColumns: true,
  },
];

import { useEffect, useState } from 'react';

import { useUserCurrency } from '@revelio/auth';
import { PopupDataKey, TalentDiscoveryMap } from '@revelio/d3';
import {
  METRIC_MODE_IDS,
  TalentDiscoveryDataQuery,
} from '@revelio/data-access';
import { SelectionCategories, useActiveFiltersState } from '@revelio/filtering';
import { Card } from '@revelio/layout';
import { formatterWithExchangeRate } from '@revelio/replots';

import { getGeographyFilterType } from '../geography-summary';

const popupDataKeys = [
  'headcount',
  'postings',
  'timeToFill',
  'salaryDist',
] as PopupDataKey[];

export const GeographyMap = ({
  mapData,
  postingsStartDate,
  postingsEndDate,
}: {
  mapData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5']['map_data'];
  postingsStartDate: string | undefined;
  postingsEndDate: string | undefined;
}) => {
  const { exchangeRate, code } = useUserCurrency();

  const salaryFormatter = formatterWithExchangeRate(code, exchangeRate);

  const activeFilters = useActiveFiltersState();

  const country = getGeographyFilterType(
    SelectionCategories.COUNTRY,
    activeFilters
  );

  const region = getGeographyFilterType(
    SelectionCategories.REGION,
    activeFilters
  );

  const [min, setMin] = useState<number | undefined>();
  const [max, setMax] = useState<number | undefined>();

  useEffect(() => {
    if (region) {
      setMin(1);
      setMax(2.95);
    } else {
      setMin(3);
      setMax(3.25);
    }
  }, [region]);

  return (
    <Card p="12px">
      <TalentDiscoveryMap
        data={{
          data: mapData || [],
          filters: activeFilters,
          popupDataKeys,
        }}
        minZoomLevel={min}
        maxZoomLevel={max}
        tooltipHeight={200}
        displayMSAsForCountry={!!country}
        displayPointsRegardlessOfZoom={true}
        tooltipRequestOverrides={{
          metric_mode: METRIC_MODE_IDS.expectedHires,
          postings_start_date: postingsStartDate,
          postings_end_date: postingsEndDate,
        }}
        headcountFromPostings
        currencyFormatter={salaryFormatter}
      />
    </Card>
  );
};

import { useQuery as useTanStackQuery } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useQuery } from 'urql';

import { PrimaryView, Views } from '@revelio/core';
import {
  CompensationCategory,
  CompensationDimension,
  CompensationFilters,
  CompensationResponse,
  Dimension1,
} from '@revelio/data-access';
import {
  GET_COMPENSATION_TOP_DIMENSIONS,
  getSelectedLastMonth,
  getStartDateConst,
  getYearFromDate,
} from '@revelio/filtering';

import { fetchModelCompensation } from '../../../data-fetch';
import { getDim1FromView } from '../../../utils';

type CompensationBody = CompensationFilters & {
  dim1: Dimension1;
  year_range: [[number, number]];
  top_companies?: TopList[];
  top_geographies?: TopList[];
  top_roles?: TopList[];
};
const stripDate = (filters: CompensationFilters): CompensationFilters =>
  omit(filters, ['start_date', 'end_date']);

const getTopEntities = (
  compensationResponse: CompensationResponse | null | undefined
): CompensationCategory[] =>
  compensationResponse?.category
    ?.filter((c): c is CompensationCategory => !!c)
    ?.slice(0, 6) || [];

type TopList = {
  id: number;
  label: number;
};
const getTopList = (topList: CompensationCategory[]): TopList[] => {
  return topList
    .map((category) => category.metadata?.id)
    .filter((id): id is number => !!id)
    .map((id) => ({ id, label: id }));
};

export const useFetchCompensation = ({
  view,
  filters,
  modelsCompensationUrl,
  isReady,
}: {
  view: PrimaryView;
  filters: CompensationFilters;
  modelsCompensationUrl: string;
  isReady: boolean;
}) => {
  const [
    {
      data: compensationTopDimensions,
      fetching: fetchingCompensationTopDimensions,
    },
  ] = useQuery({
    query: GET_COMPENSATION_TOP_DIMENSIONS,
    variables: {
      dim1: getDim1FromView(view),
      dim2: CompensationDimension.All,
      filters: stripDate(filters),
    },
    pause: !isReady,
  });

  const startYear =
    getYearFromDate(filters?.start_date) ||
    getYearFromDate(getStartDateConst(Views.COMPENSATION));

  const compensationLastMonth = getSelectedLastMonth(Views.COMPENSATION);

  const endYear =
    getYearFromDate(filters?.end_date) ||
    (compensationLastMonth
      ? getYearFromDate(compensationLastMonth)
      : new Date().getFullYear());

  const companyData = compensationTopDimensions?.compensation?.find(
    (comp) => comp?.dimension === CompensationDimension.Company
  );
  const geographyData = compensationTopDimensions?.compensation?.find(
    (comp) => comp?.dimension === CompensationDimension.Geography
  );
  const roleData = compensationTopDimensions?.compensation?.find(
    (comp) => comp?.dimension === CompensationDimension.Role
  );
  const topCompanies = getTopEntities(companyData);
  const topCompaniesList = getTopList(topCompanies);
  const topGeographiesList = getTopList(getTopEntities(geographyData));
  const topRolesList = getTopList(getTopEntities(roleData));

  const companyNameMap = topCompanies.reduce(
    (acc, company) => ({
      ...acc,
      [`${company.metadata?.id}`]: company.metadata?.shortName || '',
    }),
    {}
  );

  const compensationBody: CompensationBody | null = compensationTopDimensions
    ? {
        ...filters,
        dim1: getDim1FromView(view),
        year_range: [[Number(startYear), Number(endYear)]],
        ...(topCompaniesList.length > 0 && { top_companies: topCompaniesList }),
        ...(topGeographiesList.length > 0 && {
          top_geographies: topGeographiesList,
        }),
        ...(topRolesList.length > 0 && { top_roles: topRolesList }),
      }
    : null;

  const { data, isLoading: loadingCompensation } = useTanStackQuery({
    queryKey: ['compensation', compensationBody],
    queryFn: () =>
      fetchModelCompensation(compensationBody, modelsCompensationUrl),
    enabled: !!compensationBody && !fetchingCompensationTopDimensions,
    staleTime: 1000 * 60 * 60,
  });

  return {
    data,
    loading: loadingCompensation || fetchingCompensationTopDimensions,
    companyNameMap,
  };
};

import { Box } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import React from 'react';

import { AnyFilter, ISelectFilter } from '../../../engine/filters.model';
import { DropdownOption } from '../dropdown-option/dropdown-option';
import { mapSelectableFilters } from '../helpers.config';
import { getMenu, getMenuList } from './utils/chakraStyles';

interface FilterTypeSelectProps {
  fil: AnyFilter | undefined;
  focusInput?: boolean;
  initialFocusRef: React.MutableRefObject<null>;
  groupedOptions: ReturnType<typeof mapSelectableFilters>['mappedFilters'];
  selectValue: ISelectFilter | null | undefined;
  handleFilterSelectChange: (selected: SingleValue<ISelectFilter>) => void;
  selectMenuOpenDefault: boolean | undefined;
  selectListHeight: number | undefined;
  hasClosed: boolean;
  setHasClosed: (hasClosed: boolean) => void;
}

export const FilterTypeSelect = ({
  fil,
  focusInput,
  initialFocusRef,
  groupedOptions,
  selectValue,
  handleFilterSelectChange,
  selectMenuOpenDefault,
  selectListHeight,
  hasClosed,
  setHasClosed,
}: FilterTypeSelectProps) => {
  const setClosed = (hasClosed: boolean) => () => {
    setHasClosed(hasClosed);
  };

  const menuIsOpen =
    selectMenuOpenDefault && selectValue === undefined ? true : !hasClosed;

  const noop = () => null;
  const components =
    selectMenuOpenDefault && selectValue === undefined
      ? {
          DropdownIndicator: noop,
          IndicatorSeparator: noop,
          Option: DropdownOption,
        }
      : {};

  const chakraStyles = {
    menu: getMenu(selectValue, selectMenuOpenDefault),
    menuList: getMenuList(selectListHeight),
  };

  return (
    <Box>
      {!Array.isArray(fil) && (
        <Select
          ref={focusInput ? initialFocusRef : null}
          name={'filter-type-select'}
          placeholder="Select a filter"
          isMulti={false}
          options={groupedOptions}
          value={selectValue}
          onChange={handleFilterSelectChange}
          size="sm"
          defaultMenuIsOpen={selectMenuOpenDefault}
          chakraStyles={chakraStyles}
          onBlur={setClosed(true)}
          onMenuClose={setClosed(true)}
          menuIsOpen={menuIsOpen}
          onMenuOpen={setClosed(false)}
          colorScheme="green"
          selectedOptionColorScheme="green"
          components={components}
        />
      )}
    </Box>
  );
};

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';

import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';

import {
  TalentDiscoveryFilterAction,
  TalentDiscoveryFilterState,
  initialTalentDiscoveryFilterState,
  talentDiscoveryFilterReducer,
} from './td-filter-reducer';

const TalentDiscoveryFilterContext = createContext<{
  state: TalentDiscoveryFilterState;
  dispatch: Dispatch<TalentDiscoveryFilterAction>;
  aiSearchState: AiSearchState;
  setAiSearchState: Dispatch<SetStateAction<AiSearchState>>;
  isAiSearchLoading: boolean;
  setIsAiSearchLoading: Dispatch<SetStateAction<boolean>>;
  abortControllerRef: { current: AbortController | undefined };
  handleStopAiSearch: () => void;
  shouldTriggerOpenAiSearch: boolean;
  setShouldTriggerOpenAiSearch: Dispatch<SetStateAction<boolean>>;
  aiGeneratedFilterIds: string[];
  setAiGeneratedFilterIds: Dispatch<SetStateAction<string[]>>;
}>({
  state: initialTalentDiscoveryFilterState,
  dispatch: () => undefined,
  aiSearchState: {
    prompt: '',
    uuid: '',
    isCorrect: null,
    response: null,
  },
  setAiSearchState: () => undefined,
  isAiSearchLoading: false,
  setIsAiSearchLoading: () => undefined,
  abortControllerRef: { current: undefined },
  handleStopAiSearch: () => undefined,
  shouldTriggerOpenAiSearch: false,
  setShouldTriggerOpenAiSearch: () => undefined,
  aiGeneratedFilterIds: [],
  setAiGeneratedFilterIds: () => undefined,
});

type AiSearchState = {
  prompt: string;
  uuid: string;
  isCorrect: boolean | null;
  response: TalentDiscoveryAiFilterSearchResponse | null;
};
export const TalentDiscoveryFilterProvider = ({
  children,
}: PropsWithChildren) => {
  const [aiSearchState, setAiSearchState] = useState<AiSearchState>({
    prompt: '',
    uuid: '',
    isCorrect: null,
    response: null,
  });
  const abortControllerRef = useRef<AbortController>();

  const [isAiSearchLoading, setIsAiSearchLoading] = useState(false);
  const [shouldTriggerOpenAiSearch, setShouldTriggerOpenAiSearch] =
    useState(false);
  const [aiGeneratedFilterIds, setAiGeneratedFilterIds] = useState<string[]>(
    []
  );

  const [state, dispatch] = useReducer(
    talentDiscoveryFilterReducer,
    initialTalentDiscoveryFilterState
  );

  const handleStopAiSearch = () => {
    abortControllerRef.current?.abort();
    setIsAiSearchLoading(false);
  };

  return (
    <TalentDiscoveryFilterContext.Provider
      value={{
        state,
        dispatch,
        aiSearchState,
        setAiSearchState,
        isAiSearchLoading,
        setIsAiSearchLoading,
        abortControllerRef,
        handleStopAiSearch,
        shouldTriggerOpenAiSearch,
        setShouldTriggerOpenAiSearch,
        aiGeneratedFilterIds,
        setAiGeneratedFilterIds,
      }}
    >
      {children}
    </TalentDiscoveryFilterContext.Provider>
  );
};

export const useTalentDiscoveryFilter = () =>
  useContext(TalentDiscoveryFilterContext);

import { Box, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import { flatten, get, startCase } from 'lodash';
import { useState } from 'react';
import { map } from 'rxjs';

import { GO_API_ROOT } from '@revelio/auth';
import {
  AddEntityButtonText,
  CompensationTypes,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  TourClasses,
  Views,
  useGlobalLoaderMaxWait,
} from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterMenu,
  FilterMenuItemOrConfig,
  FilterMenuLimits,
  FilterOrSubfilterName,
  FilterSetSaveMenu,
  FilterSets,
  FiltersUsedInTabs,
  LocalSelectionCategories,
  OtherFilterNames,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  SelectionCategories,
  Tab,
  TabsFilter,
  createSelectableFiltersMap,
  getNestedEntity,
  useDefaultLastMonth,
  usePrimaryFilter,
  useSelectionLists,
  useSingleOrMoreFilterState,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';
import { EndpointSegment, ViewTypes } from '@revelio/filtering';

import { environment } from '../../environments/environment';
import DashboardPage from '../DashboardPage';
import { CompensationModelSwitch } from './components';
import { DistributionKdeChartCard } from './components/distribution-kde-chart-card';
import { OvertimeLineChartCard } from './components/overtime-line-chart-card';
import { TimeToFillLineChartCard } from './components/time-to-fill-line-chart-card';
import { TopBoxPlotCard } from './components/top-box-plot-card';
import { usePayDataFetch } from './data-fetch/use-pay-data-fetch';

const isProd = environment.production;
const providerFilterId = LocalSelectionCategories.PROVIDER;

const plotInfoBodyLookup = {
  [ViewTypes.COMPANY]: {
    [EndpointSegment.TOP_ROLES]: (
      <Text variant="tooltip">
        The most common roles within this company. More information on the
        occupational taxonomy can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
    [EndpointSegment.TOP_GEOGRAPHIES]: (
      <Text variant="tooltip">
        The most common geographies within this company.
      </Text>
    ),
  },
  [ViewTypes.GEO]: {
    [EndpointSegment.TOP_ROLES]: (
      <Text variant="tooltip">
        The most common roles within this geography. More information on the
        occupational taxonomy can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
    [EndpointSegment.TOP_COMPANIES]: (
      <Text variant="tooltip">
        The most common companies hiring in this geography. More information on
        company mapping can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#company-mapping"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
  },
  [ViewTypes.ROLE]: {
    [EndpointSegment.TOP_GEOGRAPHIES]: (
      <Text variant="tooltip">The most common geographies for this role.</Text>
    ),
    [EndpointSegment.TOP_COMPANIES]: (
      <Text variant="tooltip">
        The most common companies hiring for this role. More information on
        company mapping can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#company-mapping"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
  },
};

const view = Views.COMPENSATION;

export interface CompensationProps {
  title: PageTitles[];
  primaryView: PrimaryView;
  viewType: Tab;
  primaryFilter: PrimaryFilters;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryFiltersLimit: PrimaryFilterLimits | number;
  selectableFilters: FilterMenuItemOrConfig[];
  filterMenuLimits: FilterMenuLimits | number;
  otherFilters: (OtherFilterNames | SelectionCategories)[];
  viewFiltersForDefault?: (OtherFilterNames | SelectionCategories)[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | OtherFilterNames
    | SelectionCategories
  )[];
  savedSetView: View;
}

export function Compensation({
  title,
  primaryView,
  viewType,
  primaryFilter,
  primaryFiltersLimit,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryViewFilters,
  selectableFilters,
  filterMenuLimits,
  otherFilters,
  viewFiltersForDefault,
  onlyConsiderTheseFiltersToTriggerDefaults,
  savedSetView,
}: CompensationProps) {
  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  const flatSelectableFilters = flatten(
    selectableFiltersMap as SelectionCategories[]
  );

  const primaryEntity = getNestedEntity(viewType);
  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  );

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFiltersLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useViewFilters([...primaryFilters, ...selectableFiltersMap]);

  useSelectionLists([
    ...primaryFilters,
    ...flatSelectableFilters,
    ...FiltersUsedInTabs,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults:
      onlyConsiderTheseFiltersToTriggerDefaults ?? [
        LocalSelectionCategories.PRIMARY_ENTITIES,
      ],
    viewFilters: viewFiltersForDefault ?? [
      ...otherFilters,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    deepLinkLimit: PrimaryFilterLimits.COMPENSATION,
    limit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);
  useTabMeta({
    savedSetView,
    view,
    viewType,
    deepLinkLimit: PrimaryFilterLimits.COMPENSATION,
    limit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.LAST_START_DATE,
  });

  useGlobalLoaderMaxWait(false);

  usePrimaryFilter(primaryFilter);

  useSingleOrMoreFilterState([
    ...primaryFilters,
    ...flatSelectableFilters,
  ] as FilterOrSubfilterName[]);

  const [compensationType, setCompensationType] = useState<CompensationTypes>(
    CompensationTypes.TOTAL
  );
  const [currentCompViewLabel] = useSingleOrMoreFilterState(
    LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
    map((state) => {
      const id = get(state, 'value.id') as CompensationTypes;
      const label = {
        [CompensationTypes.HOURLY]: 'hourly pay',
        [CompensationTypes.BASE]: 'base salary',
        [CompensationTypes.TOTAL]: 'total compensation',
      }[id];
      setCompensationType(id);
      return label;
    })
  ) as [string, unknown];

  const [modelsCompensationUrl, setModelsCompensationUrl] = useState<string>(
    `${GO_API_ROOT}/models/compensation`
  );

  const {
    overtimeData,
    minOvertimeValue,
    maxOvertimeValue,
    timeToFillData,
    kdeData,
    topCompanyData,
    topRoleData,
    topGeographyData,
    loading,
    isQueryReady,
    colorMap,
  } = usePayDataFetch({
    view: primaryView,
    primaryFilters,
    compensationType,
    modelsCompensationUrl,
  });

  const isRole = viewType === ViewTypes.ROLE;
  const isCompany = viewType === ViewTypes.COMPANY;

  const isLoading = loading || !isQueryReady;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading,
  });

  return (
    <DashboardPage
      title={title}
      loading={isLoading}
      hideSelectionsMargins
      selections={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          width="100%"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={true}
            isPrimaryChip={true}
            min={1}
            limit={primaryFiltersLimit}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                {...primaryEntity}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={primaryFiltersLimit}
              >
                {AddEntityButtonText[primaryFilter]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
          {!isProd && (
            <CompensationModelSwitch setUrl={setModelsCompensationUrl} />
          )}
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            view={Views.COMPENSATION}
            variant="filterChip"
            limit={filterMenuLimits}
            offsetParent={[providerFilterId]}
            endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
            propsView={Views.COMPENSATION}
            viewType={viewType}
            showGranularity
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  view={Views.COMPENSATION}
                  filters={selectableFilters}
                  endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
                  selectMenuOpenDefault
                  limit={filterMenuLimits}
                  offsetParent={[providerFilterId]}
                  viewIdForDefault={`${view}_${viewType}`}
                />
                <FilterSetSaveMenu view={savedSetView} />
              </>
            }
          />
        </Flex>
        <Box className={TourClasses.TOUR_VIEW_CLASS}>
          <TabsFilter
            initialValue={{
              id: CompensationTypes.TOTAL,
              label: startCase(ViewTypes.TOTAL_COMP),
              index: 2,
            }}
            includeInFilters
            includeTabChangeLoaderEvent={false}
            filterName={LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP}
          ></TabsFilter>
        </Box>
      </FilterContainer>
      <Grid
        height="100%"
        templateRows={{ base: 'repeat(8, 200px)', md: 'repeat(4, 1fr)' }}
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap={4}
      >
        <GridItem gridRow={'1 / span 1'} gridColumn={'1 / span 1'} minH={0}>
          <OvertimeLineChartCard
            title={'Over Time'}
            colorMap={colorMap}
            data={overtimeData}
            minOvertimeValue={minOvertimeValue}
            maxOvertimeValue={maxOvertimeValue}
            compViewLabel={currentCompViewLabel}
            loading={isLoading}
          />
        </GridItem>
        <GridItem
          gridRow={{ base: 'span 1', md: '1 / span 1' }}
          gridColumn={{ base: '1 / span 1', md: '2 / span 1' }}
          minH={0}
        >
          <TimeToFillLineChartCard
            title={'Time to Fill'}
            colorMap={colorMap}
            data={timeToFillData}
            loading={isLoading}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 2', md: '1 / span 2' }}
          gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
          minH={0}
        >
          <TopBoxPlotCard
            title={`${isRole ? 'Top Geographies' : 'Top Roles'}`}
            info={
              isRole
                ? plotInfoBodyLookup[viewType][EndpointSegment.TOP_GEOGRAPHIES]
                : plotInfoBodyLookup[viewType][EndpointSegment.TOP_ROLES]
            }
            data={isRole ? topGeographyData : topRoleData}
            colorMap={colorMap}
            loading={isLoading}
            primaryDimension={startCase(primaryView)}
            secondaryDimension={isRole ? 'Geography' : 'Role'}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 2', md: '3 / span 2' }}
          gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
          minH={0}
        >
          <TopBoxPlotCard
            title={`${isCompany ? 'Top Geographies' : 'Top Companies'}`}
            info={
              isCompany
                ? plotInfoBodyLookup[viewType][EndpointSegment.TOP_GEOGRAPHIES]
                : plotInfoBodyLookup[viewType][EndpointSegment.TOP_COMPANIES]
            }
            data={isCompany ? topGeographyData : topCompanyData}
            colorMap={colorMap}
            loading={isLoading}
            primaryDimension={startCase(primaryView)}
            secondaryDimension={isCompany ? 'Geography' : 'Company'}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 2', md: '2 / span 3' }}
          gridColumn={{ base: '1 / span 1', md: '1 / span 2' }}
          minH={0}
        >
          <DistributionKdeChartCard
            title={'Distribution'}
            colorMap={colorMap}
            data={kdeData}
            dimensionName={startCase(primaryView)}
            compViewLabel={currentCompViewLabel}
            loading={isLoading}
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
}

export default Compensation;

import {
  CompositionSummaryDataQuery,
  PostingsGetActiveQuery,
  SentimentGetSummaryDataQuery,
  TimeDataPoint,
} from '@revelio/data-access';

import { CompositionMetricValue, SentimentMetricValue } from '../types';

export type GetNormalizedDataProps =
  | GetNormalizedCompositionData
  | GetNormalizedPostingsData
  | GetNormalizedSentimentData;

export type NormalizedData = {
  id: string | number;
  dimension: string;
  timeseries: TimeDataPoint[];
};
export const getNormalizedData = ({
  data,
  metricName,
}: GetNormalizedDataProps): NormalizedData[] => {
  switch (metricName) {
    case 'postings':
      return getNormalizedPostingsData({ data, metricName });

    case 'attrition_rate':
    case 'headcount':
    case 'growth_rate':
    case 'hiring_rate':
      return getNormalizedCompositionData({ data, metricName });

    case 'businessOutlookRating':
    case 'businessOutlookRatingCount':
    case 'compensationsBenefitsRating':
    case 'compensationsBenefitsRatingCount':
    case 'overallRating':
    case 'overallRatingCount':
      return getNormalizedSentimentData({ data, metricName });
  }
};

/** ================================
 * Normalized Postings
 ================================ */
type GetNormalizedPostingsData = {
  data: PostingsGetActiveQuery;
  metricName: 'postings';
};
export const getNormalizedPostingsData = ({
  data,
}: GetNormalizedPostingsData): NormalizedData[] => {
  if (!data.posting) return [];

  return data.posting
    ?.map((entity): NormalizedData | null => {
      if (!entity || !entity?.metadata || !entity?.category) return null;

      const { metadata, category } = entity;
      const id = metadata?.id;
      const dimension = metadata?.longName;

      if (!dimension || !id) return null;

      const timeseries: NormalizedData['timeseries'] = category
        .map((timeDataPoint): TimeDataPoint | null => {
          if (
            !timeDataPoint ||
            !timeDataPoint.metadata ||
            !timeDataPoint.metrics
          ) {
            return null;
          }

          const { metadata, metrics } = timeDataPoint;
          const date = metadata?.shortName;
          const value = metrics?.active;

          if (!date || !value) return null;

          return { date, value };
        })
        .filter((d) => d !== null);

      return {
        id: `${metadata?.id}`,
        dimension,
        timeseries,
      };
    })
    .filter((d) => d !== null);
};

/** ================================
 * Normalized Composition
 ================================ */
type GetNormalizedCompositionData = {
  data: CompositionSummaryDataQuery;
  metricName: CompositionMetricValue;
};
export const getNormalizedCompositionData = ({
  data,
  metricName,
}: GetNormalizedCompositionData): NormalizedData[] => {
  if (!data.composition) return [];

  return data.composition
    ?.map((entity) => {
      if (!entity || !entity?.metadata || !entity?.metrics) return null;

      const { metadata, metrics } = entity;
      const id = metadata?.id;
      const dimension = metadata.shortName;
      const metric = metrics?.[metricName];

      if (!dimension || !id || !metric) return null;

      const timeseries = metric.timeseries;
      if (!timeseries) return null;

      return {
        id,
        dimension,
        timeseries: timeseries.filter((d) => d !== null),
      };
    })
    .filter((d) => d !== null);
};

/** ================================
 * Normalized Sentiment
 ================================ */
type GetNormalizedSentimentData = {
  data: SentimentGetSummaryDataQuery;
  metricName: SentimentMetricValue;
};
export const getNormalizedSentimentData = ({
  data,
  metricName,
}: GetNormalizedSentimentData): NormalizedData[] => {
  if (!data.sentiment2d) return [];

  return data.sentiment2d
    ?.map((entity) => {
      if (!entity || !entity?.metadata || !entity?.category) return null;

      const { metadata, category } = entity;
      const id = metadata?.id;
      const dimension = metadata?.longName;

      if (!dimension || !id) return null;

      const timeseries = category
        .map((timeDataPoint): TimeDataPoint | null => {
          if (
            !timeDataPoint ||
            !timeDataPoint.metadata ||
            !timeDataPoint.metrics
          ) {
            return null;
          }

          const { metadata, metrics } = timeDataPoint;
          const date = metadata?.shortName;
          const value = metrics?.[metricName];
          const count = (() => {
            switch (metricName) {
              case 'overallRating':
                return metrics?.overallRatingCount;
              case 'businessOutlookRating':
                return metrics?.businessOutlookRatingCount;
              case 'compensationsBenefitsRating':
                return metrics?.compensationsBenefitsRatingCount;
              default:
                return 0;
            }
          })();

          if (!date || !value) return null;

          return { date, value, count };
        })
        .filter((d) => d !== null);

      return {
        id,
        dimension,
        timeseries,
      };
    })
    .filter((d) => d !== null);
};

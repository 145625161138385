import { ReactElement, useContext, useState } from 'react';

import Table from '../data-enrichment/Table';
import { getColumnValues } from '../data-enrichment/utils/getColumnValues';
import { FileUploadContext } from './file-upload-context';

const HeaderSelectionStep = ({
  ContainerElement,
}: {
  ContainerElement: ({
    onSubmit,
    children,
  }: {
    onSubmit: () => void;
    children: ReactElement;
  }) => ReactElement;
}) => {
  const {
    mappedWorkbook = [],
    setData: setFileData,
    goToNext,
  } = useContext(FileUploadContext);
  const [rowSelection, setRowSelection] = useState<{ [key: number]: boolean }>({
    0: true,
  });
  const handleOnSubmit = () => {
    const headerIndex = Object.keys(rowSelection)[0];
    const headers: string[] = mappedWorkbook[Number(headerIndex)];

    const combinedData = headers.map((header: string, index: number) => {
      const sampleData = getColumnValues({
        mappedWorkbook,
        headerIndex,
        index,
      });
      return { header, sampleData };
    });

    setFileData((prevData) => ({
      ...prevData,
      headers,
      headerIndex,
      combinedData,
    }));
    goToNext();
  };
  return ContainerElement({
    onSubmit: handleOnSubmit,
    children: (
      <Table rowSelection={rowSelection} setRowSelection={setRowSelection} />
    ),
  });
};

export default HeaderSelectionStep;

import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';

import { useScreenerFilter } from '../screener-filter-provider';
import { SelectFooter } from '../select-footer';
import {
  OperatorOption,
  ScreenerFilterOption,
  isYearRangeFilterState,
} from '../types';
import { CompanyDetailYearRangeFilters } from '../types';

interface YearRangeFilterProps {
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const operatorOptions: OperatorOption[] = [
  { label: 'is between', value: 'between' },
  { label: 'is greater than', value: 'greater_than' },
  { label: 'is less than', value: 'less_than' },
];

const NumberInput = ({
  ...restProps
}: NumberFormatPropsBase<typeof Input> & { onBlur: () => void }) => {
  return (
    <NumberFormat
      {...restProps}
      customInput={Input}
      displayType="input"
      height="36px"
      fontSize="14px"
      padding="10px"
      borderRadius="2px"
      format="####"
      mask="_"
      onBlur={restProps.onBlur}
    />
  );
};

export const YearRangeFilter = ({
  closeMenu,
  selectedFilter,
}: YearRangeFilterProps) => {
  const { state, dispatch } = useScreenerFilter();
  const [operator, setOperator] = useState<OperatorOption>(operatorOptions[0]);
  const [startYear, setStartYear] = useState<number | undefined>(undefined);
  const [endYear, setEndYear] = useState<number | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [startYearTouched, setStartYearTouched] = useState(false);
  const [endYearTouched, setEndYearTouched] = useState(false);

  const selectedCompanyDetailState = state.filters.company_detail_filters?.find(
    (filter) => filter.name === selectedFilter.value
  );

  useEffect(() => {
    setOperator(operatorOptions[0]);
    setStartYear(undefined);
    setEndYear(undefined);
    setErrorMessages([]);
    setStartYearTouched(false);
    setEndYearTouched(false);
  }, [selectedFilter]);

  useEffect(() => {
    if (isYearRangeFilterState(selectedCompanyDetailState)) {
      const { start_year, end_year } = selectedCompanyDetailState;
      setStartYear(start_year);
      setEndYear(end_year);

      const operatorValue = (() => {
        if (start_year !== undefined && end_year !== undefined) {
          return 'between';
        }
        if (start_year !== undefined) {
          return 'greater_than';
        }
        return 'less_than';
      })();
      const selectedOperator =
        operatorOptions.find((opt) => opt.value === operatorValue) ||
        operatorOptions[0];
      setOperator(selectedOperator);
    }
  }, [selectedCompanyDetailState]);

  const handleOperatorChange = (value: SingleValue<OperatorOption>) => {
    setOperator(value || operatorOptions[0]);
    setStartYear(undefined);
    setEndYear(undefined);
    setErrorMessages([]);
    setStartYearTouched(false);
    setEndYearTouched(false);
  };

  const handleStartYearChange = (values: NumberFormatValues) => {
    setStartYear(values.floatValue);
  };

  const handleEndYearChange = (values: NumberFormatValues) => {
    setEndYear(values.floatValue);
  };

  useEffect(() => {
    const errors: string[] = [];

    if (startYearTouched) {
      if (startYear !== undefined && (startYear < 1000 || startYear > 9999)) {
        errors.push('Start year must be a 4-digit number.');
      }
    }

    if (endYearTouched) {
      if (endYear !== undefined && (endYear < 1000 || endYear > 9999)) {
        errors.push('End year must be a 4-digit number.');
      }
    }

    if (
      operator?.value === 'between' &&
      startYearTouched &&
      endYearTouched &&
      startYear !== undefined &&
      endYear !== undefined &&
      startYear > endYear
    ) {
      errors.push('Start year must be less than or equal to the end year.');
    }

    setErrorMessages(errors);
  }, [operator, startYear, endYear, startYearTouched, endYearTouched]);

  const isInvalid =
    errorMessages.length > 0 && (startYearTouched || endYearTouched);

  const isDisabled = (() => {
    if (operator?.value === 'greater_than' && startYear === undefined) {
      return true;
    }

    if (operator?.value === 'less_than' && endYear === undefined) {
      return true;
    }

    if (
      operator?.value === 'between' &&
      (startYear === undefined || endYear === undefined)
    ) {
      return true;
    }

    return isInvalid;
  })();

  const handleAddFilter = () => {
    if (!isInvalid) {
      dispatch({
        type: 'ADD_COMPANY_DETAIL_YEAR_RANGE_FILTER',
        name: selectedFilter.value as CompanyDetailYearRangeFilters,
        start_year: startYear,
        end_year: endYear,
      });
      closeMenu();
    }
  };

  const handleClearSelections = () => {
    setStartYear(undefined);
    setEndYear(undefined);
    setOperator(operatorOptions[0]);
    setErrorMessages([]);
    setStartYearTouched(false);
    setEndYearTouched(false);
  };

  return (
    <>
      <Flex direction="column" gap="3" fontSize="14px" mb={2}>
        <Select
          options={operatorOptions}
          value={operator}
          onChange={handleOperatorChange}
          size="sm"
          selectedOptionColorScheme="green"
          chakraStyles={{
            control: (provider) => ({
              ...provider,
              height: '36px',
            }),
          }}
        />
        <FormControl isInvalid={isInvalid}>
          {operator?.value === 'between' ? (
            <Flex
              alignItems="center"
              gap="2.5"
              fontSize="14px"
              justifyContent="space-between"
            >
              <NumberInput
                value={startYear}
                onValueChange={handleStartYearChange}
                onBlur={() => setStartYearTouched(true)}
                data-testid="start-year-input"
              />
              <Text>to</Text>
              <NumberInput
                value={endYear}
                onValueChange={handleEndYearChange}
                onBlur={() => setEndYearTouched(true)}
                data-testid="end-year-input"
              />
            </Flex>
          ) : (
            <NumberInput
              key={`single-${operator.value}`}
              value={operator?.value === 'greater_than' ? startYear : endYear}
              onValueChange={
                operator?.value === 'greater_than'
                  ? handleStartYearChange
                  : handleEndYearChange
              }
              onBlur={
                operator?.value === 'greater_than'
                  ? () => setStartYearTouched(true)
                  : () => setEndYearTouched(true)
              }
            />
          )}
          <FormErrorMessage>
            <VStack align="start">
              {errorMessages.map((msg, idx) => (
                <Text key={idx} color="red.600">
                  {msg}
                </Text>
              ))}
            </VStack>
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={closeMenu}
        onAdd={handleAddFilter}
        addLabel={selectedCompanyDetailState ? 'Update' : 'Add'}
        isAddDisabled={isDisabled}
      />
    </>
  );
};

import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Spacer, Text } from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { get } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createUltimatePagination } from 'react-ultimate-pagination';
import { useQuery } from 'urql';

import { Loading } from '@revelio/core';
import { Client, GetAllClientsQueryVariables } from '@revelio/data-access';
import { itemTypeToComponent } from '@revelio/layout';

// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../libs/layout/src/lib/components/table/table.css';
import { AllClientsQuery } from '../../adminRewrite/userOperations';
import AdminClientAdd from '../admin-client-add';
import { useResettableState } from '../utils/helpers';

type AdminUsersProps = {
  selectedClientId?: string | null;
  onClientSelect?: (clientID: string | null) => void;
};
const AdminClients = ({
  onClientSelect,
  selectedClientId,
}: AdminUsersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [result] = useQuery<GetAllClientsQueryVariables>({
    query: AllClientsQuery,
    requestPolicy: 'network-only',
  });

  const { data, fetching } = result;

  const handleSelectClient = (clientName: Client['client_name']) => {
    if (!clientName) return;

    if (onClientSelect) {
      onClientSelect(clientName);
    }
  };

  const handleEditClient = (clientName: Client['client_name']) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('editing', 'client');
    newSearchParams.set('client', clientName ?? '');

    setSearchParams(newSearchParams);
  };

  const [isAddClientModalOpen, setAddClientModalOpen] = useState(false);
  const [addClientModalKey, resetAddClientModalKey] = useResettableState();

  const handleOpenAddClientModal = () => {
    // remove other form instances
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete('editing');
    newSearchParams.delete('client');
    newSearchParams.delete('user');

    setSearchParams(newSearchParams);

    resetAddClientModalKey();
    setAddClientModalOpen(true);
  };

  const handleCloseAddClientModal = () => {
    setAddClientModalOpen(false);
  };

  const getRowClassName = ({ data }: { data: Client }) => {
    return data.client_name === selectedClientId ? 'highlighted' : '';
  };

  const defaultSortInfo: TypeSortInfo = { name: 'client_name', dir: 1 };

  const columns = [
    {
      name: 'client_name',
      header: 'Client Name',
      defaultFlex: 1,
      render: ({ data }: { data: Client }) => (
        <Flex gap={'8px'} mr="12px">
          <Text fontSize={'md'}>{data.client_name}</Text>
          <Spacer />
          {/* <Divider orientation='vertical'/> */}
          <Button
            variant={'link'}
            colorScheme="blue"
            size="sm"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click action when this button is clicked
              handleSelectClient(data.client_name);
            }}
          >
            Show Users
          </Button>
        </Flex>
      ),
    },
  ];

  const gridStyle = { minHeight: 'calc(100% - 40px)', overflowX: 'hidden' };
  const currentPage = useRef<number>(1);

  const Wrapper = (props: { children: JSX.Element }) => {
    return <Box>{props.children}</Box>;
  };

  const UltimatePagination = createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent(),
    WrapperComponent: Wrapper,
  });

  const renderPaginationToolbar = useCallback(
    (paginationProps: {
      count: number;
      limit: number;
      gotoPage: (arg0: number) => void;
    }) => {
      const totalPages = Math.ceil(
        paginationProps.count / paginationProps.limit
      );
      return (
        totalPages > 1 && (
          <Flex alignItems="center" h="50px" w="100%" justifyContent="center">
            <Box p={2}>
              <UltimatePagination
                currentPage={currentPage.current}
                totalPages={totalPages}
                boundaryPagesRange={1}
                siblingPagesRange={1}
                hidePreviousAndNextPageLinks={false}
                hideFirstAndLastPageLinks={false}
                hideEllipsis={false}
                onChange={(e) => {
                  currentPage.current = e;
                  paginationProps.gotoPage(currentPage.current);
                }}
              />
            </Box>
          </Flex>
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData: Client[] = useMemo(() => get(data, 'clients', []), [data]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '8px',
          borderRadius: '8px',
          '& .highlighted': {
            background: '#89f2b7',
          },
        }}
        h="100%"
      >
        <Flex alignItems="center" paddingBottom={2} justifyContent="center">
          <Button
            variant={'solid'}
            colorScheme="green"
            leftIcon={<AddIcon />}
            size="sm"
            onClick={handleOpenAddClientModal}
          >
            Add Client
          </Button>
          <Spacer />
        </Flex>
        {!fetching ? (
          <ReactDataGrid
            showCellBorders="vertical"
            columns={columns || []}
            dataSource={tableData}
            style={gridStyle}
            rowHeight={30}
            renderPaginationToolbar={renderPaginationToolbar}
            pagination
            emptyText="No Users Found"
            theme="green-light"
            rowClassName={getRowClassName}
            defaultSortInfo={defaultSortInfo}
            onRowClick={({ data }: { data: Client }) =>
              handleEditClient(data.client_name)
            }
          />
        ) : (
          <Flex justify="center" height="calc(100% - 40px)">
            <Center>
              <Loading />
            </Center>
          </Flex>
        )}
      </Box>
      <AdminClientAdd
        key={addClientModalKey} // to hard reset modal state
        onClose={handleCloseAddClientModal}
        isOpen={isAddClientModalOpen}
      />
    </>
  );
};

export default AdminClients;

import { Tooltip } from '@chakra-ui/react';
import classNames from 'classnames';
import { group, scaleBand, scaleLinear, scaleOrdinal } from 'd3';
import { useState } from 'react';

import { CommonPlotProps } from '../../types';
import { getMaxTextWidth, useResizeObserver } from '../../utilities';
import { currencyFormatter } from '../../utilities/get-formatter';
import { plotColors } from '../../utilities/plot-colors';
import { AxisLabel } from '../axis/axis-label';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import styles from './box-plot.module.css';
import { TooltipContent } from './tooltip-content';

type BoxDatum = {
  percentile10: number;
  percentile50: number;
  percentile90: number;
};

export type BoxGroup = {
  dimension: string;
  boxData: BoxDatum;
  secondaryDimension: string;
  color?: string;
};

export interface BoxPlotProps extends CommonPlotProps {
  data: BoxGroup[];
  colorMap?: Record<string, string>;
}

const Y_AXIS_MAX_WIDTH = 80;
const Y_AXIS_MAX_WIDTH_SMALL = 50;
const X_AXIS_HEIGHT = 20;
const PLOT_LEFT_PADDING = 8;
const PLOT_RIGHT_PADDING = 15;

export const BoxPlot = ({
  data,
  colorMap,
  loading = false,
  currencyCode,
}: BoxPlotProps) => {
  const { containerRef, width, height } = useResizeObserver();

  const isSmallPlot = width < 250;
  const yAxisMaxWidth = isSmallPlot ? Y_AXIS_MAX_WIDTH_SMALL : Y_AXIS_MAX_WIDTH;

  const plotHeight = height - X_AXIS_HEIGHT;

  /** ======== Colors ======== */
  const secondaryDimensions = Array.from(
    new Set(data.map((d) => d.secondaryDimension))
  );

  const colorScale = scaleOrdinal<string>()
    .domain(secondaryDimensions)
    .range(secondaryDimensions.map((d, i) => colorMap?.[d] || plotColors[i]));

  /** ======== Data ======== */
  const groupedData = group(data, (d) => d.dimension);

  /** ======== Y Scale ======== */
  const yScale = scaleBand<string>(
    data.map((d) => d.dimension),
    [0, plotHeight]
  )
    .paddingOuter(0.1)
    .paddingInner(0.2);

  const secondaryYScale = scaleBand<string>(
    data.map((d) => d.secondaryDimension),
    [0, yScale.bandwidth()]
  ).paddingInner(0.2);

  const yTicks = yScale.domain();
  const yAxisWidth = Math.min(
    getMaxTextWidth({ texts: yTicks }),
    yAxisMaxWidth
  );

  /** ======== X Scale ======== */
  const plotWidth = width - yAxisWidth - PLOT_LEFT_PADDING - PLOT_RIGHT_PADDING;

  const minValue = Math.min(
    ...(data.flatMap((group) => group.boxData.percentile10) || [])
  );
  const maxValue = Math.max(
    ...(data.flatMap((group) => group.boxData.percentile90) || [])
  );
  const xScale = scaleLinear([minValue, maxValue], [0, plotWidth]);

  const xAxisTicks = xScale.ticks(5).map((tick) => ({
    value: tick,
    label: currencyFormatter('$~s', currencyCode)(tick),
  }));

  /** ======== Hover Behavior ======== */
  const [hoveredSecondaryDimension, setHoveredSecondaryDimension] =
    useState<string>();
  const onMouseOver = (secondaryDimension: string) => {
    setHoveredSecondaryDimension(secondaryDimension);
  };
  const onMouseOut = () => {
    setHoveredSecondaryDimension(undefined);
  };

  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      data-testid="plot-BoxPlot"
    >
      <PlotLoadWrapper loading={loading} noData={data.length === 0}>
        <svg width="100%" height="100%">
          <g data-testid="y-axis">
            {isPlotSizeValid &&
              yTicks.map((domain, i) => {
                const y = yScale(domain);
                return (
                  y && (
                    <AxisLabel
                      key={domain}
                      y={y + yScale.bandwidth() / 2}
                      width={yAxisWidth}
                      label={domain}
                      availableHeight={yScale.bandwidth()}
                    />
                  )
                );
              })}
          </g>
          <g
            data-testid="x-axis"
            transform={`translate(${yAxisWidth + PLOT_LEFT_PADDING}, ${plotHeight})`}
            className={styles['xAxis']}
          >
            {isPlotSizeValid &&
              xAxisTicks.map(({ value, label }, i) => (
                <g key={i} transform={`translate(${xScale(value)}, 0)`}>
                  <text y="9" dy="0.71em">
                    {label}
                  </text>
                </g>
              ))}
          </g>
          <g
            id="chart"
            transform={`translate(${yAxisWidth + PLOT_LEFT_PADDING}, 0)`}
          >
            <g id="axis-grid">
              {isPlotSizeValid &&
                xAxisTicks.map((tick, i) => (
                  <line
                    key={i}
                    x1={xScale(tick.value)}
                    y1={0}
                    x2={xScale(tick.value)}
                    y2={plotHeight}
                    className={styles['axisGridLine']}
                  />
                ))}
            </g>
            <g id="plot">
              {isPlotSizeValid &&
                Array.from(groupedData)?.map(([key, values], i) => (
                  <g
                    key={i}
                    id={'bar-group'}
                    transform={`translate(0, ${yScale(key)})`}
                  >
                    {values.map((d, j) => (
                      <Tooltip
                        key={j}
                        hasArrow
                        label={
                          <TooltipContent
                            title={d.secondaryDimension}
                            subtitle={d.dimension}
                            rows={[
                              {
                                label: '10th percentile',
                                value: currencyFormatter(
                                  '$.4s',
                                  currencyCode
                                )(d.boxData.percentile10),
                              },
                              {
                                label: '50th percentile',
                                value: currencyFormatter(
                                  '$.4s',
                                  currencyCode
                                )(d.boxData.percentile50),
                              },
                              {
                                label: '90th percentile',
                                value: currencyFormatter(
                                  '$.4s',
                                  currencyCode
                                )(d.boxData.percentile90),
                              },
                            ]}
                          />
                        }
                        padding={'8px 12px'}
                        placement="top"
                        bg="#2d426a"
                        borderRadius="4px"
                        data-testid="box-plot__tooltip"
                      >
                        <g
                          transform={`translate(0, ${secondaryYScale(d.secondaryDimension)})`}
                          onMouseOver={() => onMouseOver(d.secondaryDimension)}
                          onMouseOut={() => onMouseOut()}
                          className={classNames({
                            [styles['dimmed']]:
                              hoveredSecondaryDimension &&
                              hoveredSecondaryDimension !==
                                d.secondaryDimension,
                          })}
                        >
                          <rect
                            x={xScale(d.boxData.percentile10)}
                            y={0}
                            width={
                              xScale(d.boxData.percentile90) -
                              xScale(d.boxData.percentile10)
                            }
                            height={secondaryYScale.bandwidth()}
                            stroke={colorScale(d.secondaryDimension)}
                            fill={colorScale(d.secondaryDimension)}
                            className={styles['box']}
                            rx={1}
                            ry={1}
                            data-testid="box-plot__box"
                          />
                          <line
                            x1={xScale(d.boxData.percentile50)}
                            y1={0}
                            x2={xScale(d.boxData.percentile50)}
                            y2={secondaryYScale.bandwidth()}
                            stroke={colorScale(d.secondaryDimension)}
                            className={styles['boxMidLine']}
                          />
                        </g>
                      </Tooltip>
                    ))}
                  </g>
                ))}
            </g>
          </g>
        </svg>
      </PlotLoadWrapper>
    </div>
  );
};

import {
  Box,
  Table as CTable,
  Radio,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useContext, useMemo } from 'react';

import { FileUploadContext } from '../file-upload/file-upload-context';

const Table = ({
  rowSelection,
  setRowSelection,
}: {
  rowSelection: { [key: number]: boolean };
  setRowSelection: React.Dispatch<
    React.SetStateAction<{ [key: number]: boolean }>
  >;
}) => {
  const { mappedWorkbook = [] } = useContext(FileUploadContext);
  const data = useMemo(() => [...mappedWorkbook], [mappedWorkbook]);

  const generateSelectionColumns = (
    data: string[][]
  ): ColumnDef<string[]>[] => {
    const longestRowLength = data.reduce(
      (acc: number, curr: string[]) => (acc > curr.length ? acc : curr.length),
      0
    );
    return [
      {
        id: 'select',
        header: () => null,
        cell: ({ row }) => (
          <Radio
            size="md"
            colorScheme="green"
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      ...Array.from(Array(longestRowLength), (_, index) => ({
        id: index.toString(),
        header: () => null,
        cell: (cellInfo: {
          column: { id: string };
          row: { original: unknown[] };
        }) => {
          const columnIndex = parseInt(cellInfo.column.id, 10);
          const cellValue = cellInfo.row.original[columnIndex];
          return cellValue;
        },
      })),
    ];
  };

  const columns = useMemo(() => generateSelectionColumns(data), [data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    initialState: {
      rowSelection: {
        0: true,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const loadingBoxStyles = {
    scrollbarWidth: 'thin',
    scrollbarColor: '#e3e3e3 rgba(0,0,0,0)',
    '&::-webkit-scrollbar': {
      width: '14px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: '10px',
      backgroundClip: 'padding-box',
      border: '5px solid rgba(0,0,0,0)',
    },
  };

  return (
    <Box display="grid">
      <TableContainer sx={loadingBoxStyles}>
        <CTable size="sm" width="full">
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                _hover={{
                  background: `${
                    row.getIsSelected() ? 'brightGreen.200' : 'brightGreen.50'
                  }`,
                }}
                _checked={{ background: 'brightGreen.100' }}
                _active={{ background: 'brightGreen.200' }}
                background={`${row.getIsSelected() ? 'brightGreen.100' : ''}`}
                onClick={(event) => {
                  if (row.getCanSelect()) {
                    const selected = row.getIsSelected();
                    if (selected) {
                      return;
                    }
                    table.toggleAllRowsSelected(false);
                    row.getToggleSelectedHandler()(event);
                  }
                }}
                cursor="pointer"
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} borderColor="gray.100">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </CTable>
      </TableContainer>
    </Box>
  );
};

export default Table;

import { Link, Text } from '@chakra-ui/layout';

import { useUserCurrency } from '@revelio/auth';
import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, convertLineToSheet } from '../../../shared/components';
import { adjustLineDataToUserCurrency } from '../data-fetch/get-overtime-data';
import { D3ChartNames } from '@revelio/d3';

interface OvertimeLineChartCardProps {
  data: LineData[];
  minOvertimeValue: number | undefined;
  maxOvertimeValue: number | undefined;
  compViewLabel: string;
  title: string;
  loading?: boolean;
  colorMap: Record<string, string>;
}

export const OvertimeLineChartCard = ({
  data,
  minOvertimeValue,
  maxOvertimeValue,
  compViewLabel,
  title,
  loading,
  colorMap,
}: OvertimeLineChartCardProps) => {
  const { exchangeRate, code } = useUserCurrency();
  const dataWithColors = data.map((line) => ({
    ...line,
    color: colorMap[line.label],
  }));

  const adjustedData = adjustLineDataToUserCurrency(
    dataWithColors,
    exchangeRate
  );

  const yAxis =
    typeof minOvertimeValue !== 'number' || typeof maxOvertimeValue !== 'number'
      ? undefined
      : {
          min: minOvertimeValue * exchangeRate,
          max: maxOvertimeValue * exchangeRate,
        };

  return (
    <PlotCard
      title={title}
      info={
        <Text variant="tooltip">
          The average predicted {compViewLabel} over time. More information on
          the compensation model can be found{' '}
          <Link
            href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
            isExternal
            variant="tooltip"
          >
            here
          </Link>
          .
        </Text>
      }
      infoPlacement="top-end"
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      exportData={convertLineToSheet({ data })}
      plotShare={{
        data: adjustedData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: {
            format: FormatType.CURRENCY,
            colors: dataWithColors.map((line) => line.color),
            currencyCode: code,
          },
        },
      }}
    >
      <LineChart
        data={adjustedData}
        showGridLines={true}
        format={FormatType.CURRENCY}
        dateFormat="month"
        loading={loading}
        yAxis={yAxis}
        currencyCode={code}
      />
    </PlotCard>
  );
};

import { MeterChart } from '@revelio/d3';
import {
  CompositionDataQuery,
  GeoSummaryPostingsDataQuery,
} from '@revelio/data-access';

import { calculateMarketTightnessMeterValue } from './meter-scaling/calculateMeterValue';

interface MarketTightnessMeterProps {
  postingsData: GeoSummaryPostingsDataQuery | undefined;
  compositionData: CompositionDataQuery | undefined;
}
export const MarketTightnessMeter = ({
  postingsData,
  compositionData,
}: MarketTightnessMeterProps) => {
  const regionHeadcountTimeSeries =
    compositionData?.composition?.[0]?.metrics?.headcount?.timeseries;
  const headcount =
    regionHeadcountTimeSeries?.[regionHeadcountTimeSeries?.length - 1]?.value ||
    0;
  const postingsCategory = postingsData?.posting?.[0]?.category;
  const postingsActive =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.active || 0;

  const timeToFillEnd =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.time_to_fill ||
    0;

  const salaryTimeseries =
    compositionData?.composition?.[0]?.metrics?.salary?.timeseries;

  const yearStartWage = salaryTimeseries?.[0]?.value || 0;
  const yearEndWage =
    salaryTimeseries?.[salaryTimeseries?.length - 1]?.value || 0;

  const wageDifference = yearEndWage - yearStartWage;
  const wageGrowth = wageDifference / yearStartWage;

  const marketTightness = calculateMarketTightnessMeterValue({
    wageGrowth,
    demandSupply: postingsActive / headcount,
    timeToFill: timeToFillEnd,
  }).toFixed(0);

  return (
    <MeterChart
      value={Number(marketTightness)}
      mainText={`${marketTightness}%`}
    />
  );
};

import { PipelineColumnType, PipelineType } from '@revelio/data-access';

import { getColumnConfigById } from '../deliverables.repository';

export const getPipelineCompanyColumn = (
  pipelineType: PipelineType | undefined
) =>
  pipelineType == PipelineType.Individual
    ? PipelineColumnType.CompanyName
    : PipelineColumnType.Company;

export const getPipelineCombinedCompanyColumns = (
  pipelineType: PipelineType
) => {
  const companyColumn = getPipelineCompanyColumn(pipelineType);
  return [
    companyColumn,
    ...(getColumnConfigById({ pipelineType, columnId: companyColumn })
      ?.combineWith || []),
  ];
};

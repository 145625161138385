import { Tooltip } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { FormatType } from '../../types';
import { getFormatter } from '../../utilities';
import styles from './sankey-chart.module.css';
import { LinkPath } from './types';

type SankeyPathTooltipProps = PropsWithChildren & {
  d: LinkPath;
  isOpen: boolean;
  isOutflows: boolean;
};

export const SankeyPathTooltip = ({
  d,
  children,
  isOpen,
  isOutflows,
}: SankeyPathTooltipProps) => {
  return (
    <Tooltip
      label={<TooltipContent d={d} isOutflows={isOutflows} />}
      padding={'8px 12px'}
      placement="top"
      hasArrow
      isOpen={isOpen}
      data-testid={`sankey-chart__path-tooltip`}
    >
      {children}
    </Tooltip>
  );
};

const format = getFormatter(FormatType.INTEGER);
const percentFormat = getFormatter(FormatType.PERCENTAGE);
const TooltipContent = ({
  d,
  isOutflows,
}: {
  d: LinkPath;
  isOutflows: boolean;
}) => {
  return (
    <div className={styles.tooltipContent}>
      <div>
        {format(d.value)} {d.source.id} employees have transitioned to{' '}
        {d.target.id}
      </div>
      {d.share && (
        <div>
          ({percentFormat(d.share)} of all{' '}
          {isOutflows ? d.source.id : d.target.id}{' '}
          {isOutflows ? 'outflows' : 'inflows'})
        </div>
      )}
    </div>
  );
};

import {
  ChakraTheme,
  theme as baseTheme,
  cssVar,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';

import { CardListSelect } from './card-list-select.style';
import { Resume } from './resume.style';

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const themeConfig: Partial<ChakraTheme> = {
  fonts: {
    heading: 'Source Sans Pro',
    body: 'Source Sans Pro',
  },
  styles: {
    global: () => ({
      'a.nav.active': {
        backgroundColor: 'green.500',
      },
      'a.nav.semi-active': {
        backgroundColor: 'rgba(255,255,255,.15)',
      },
      '*:focus': {
        boxShadow: 'none',
      },
    }),
  },
  components: {
    CardListSelect,
    Resume,
    AutoComplete: {
      parts: ['input', 'inputButton', 'menu', 'scrollBox', 'list', 'item'],
      baseStyle: ({ isOpen, numSuggestions }) => {
        return {
          menu: {
            overflowY: 'auto',
            position: 'absolute',
            w: '100%',
            height: isOpen && numSuggestions > 0 ? 'fit-content' : 0,
            bg: 'white',
            p: isOpen && numSuggestions > 0 ? '0.5rem 0.6rem' : 0,
            border: isOpen && numSuggestions > 0 ? '1px solid' : 'none',
            borderColor: isOpen && numSuggestions > 0 ? 'silver.500' : 'none',
            borderRadius: '4px',
            marginTop: '2',
            boxShadow: 'xl',
            zIndex: '9999',
          },
          scrollBox: {
            w: '100%',
            overflow: 'auto',
            minHeight: '0px',
            maxHeight: '200px',
            scrollbarWidth: 'auto',
            scrollbarColor: '#e3e3e3 #ffffff',
            '&::-webkit-scrollbar': {
              width: '14px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'navyBlue.50',
              borderRadius: '10px',
              border: '3px solid #ffffff',
            },
          },
          item: {
            borderRadius: '4px',
            p: '0.3rem 0.6rem',
            _hover: { cursor: 'pointer' },
          },
        };
      },
    },
    Checkbox: {
      parts: ['control', 'icon', 'container', 'label'],

      variants: {
        'node-checkbox': {
          container: {
            _disabled: {
              cursor: 'pointer',
            },
          },
          label: {
            display: 'inline-block',
            width: '100%',
            overflow: 'hidden',
            _disabled: {
              cursor: 'pointer',
              opacity: '0.5',
            },
          },
          control: {
            _disabled: {
              bg: 'gray.300',
              borderColor: 'gray.300',
              cursor: 'not-allowed',
            },
          },
        },
      },
    },
    FilterContainer: {
      baseStyle: {},
    },
    FilterSelect: {},
    RlBox: {
      variants: {
        'large-container': {
          height: '100%',
        },
      },
    },
    FormControl: {
      baseStyle: {},
    },
    Form: {
      parts: ['container', 'label'],
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: 'scale(0.85) translateY(-24px)',
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
              {
                transform: 'scale(0.85) translateY(-24px)',
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
        boxedLabel: {
          container: {
            label: {
              width: '100%',
              textAlign: 'center',
              border: '1px solid',
              borderColor: 'inherit',
              borderRadius: 'var(--chakra-radii-md)',
            },
          },
        },
      },
    },

    Tag: {
      parts: ['container', 'label', 'closeButton'],
      baseStyle: {
        container: {
          background: 'white',
          borderRadius: '3px',
          color: 'text.primary',
          margin: '0 0.5rem 0 0',
        },
      },
      variants: {
        companyChip: {
          container: {
            height: '1.4rem',
            borderRadius: '4px',
            fontSize: '11.5px',
            padding: '0 2px 0 8px',
          },
          closeButton: {
            fontSize: '14px',
            _hover: {
              cursor: 'pointer',
            },
          },
        },
        filterChip: {
          container: {
            height: '1.4rem',
            backgroundColor: '#FFFFFF',
            fontSize: '12px',
          },
          label: {
            textDecoration: 'underline rgba(45, 66, 106, 0)',
            transition: 'text-decoration-color 200ms',
            _hover: {
              textDecoration: 'underline rgba(45, 66, 106, 1)',
            },
          },
          closeButton: {
            fontSize: '12px',
            _hover: {
              cursor: 'pointer',
            },
          },
        },
        filterChipDisabled: {
          container: {
            height: '1.4rem',
            backgroundColor: '#E2E8F0',
            fontSize: '12px',
          },
          label: {
            opacity: '0.6',
            _hover: {
              textDecoration: 'underline',
            },
          },
          closeButton: {
            fontSize: '12px',
            _hover: {
              cursor: 'pointer',
            },
          },
        },
        keywordChip: {
          container: {
            backgroundColor: '#ECF9FE',
            border: '1px solid #A2DFF9',
            fontSize: '12px',
          },
        },
      },
    },
    Link: {
      variants: {
        nav: {
          height: '40px',
          width: '40px',
          borderRadius: '4px',
          backgroundSize: '24px 24px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '8px',
          padding: '12px',
          _hover: {
            backgroundColor: 'rgba(255,255,255,.15)',
            cursor: 'pointer',
          },
        },
        tooltip: {
          color: 'green.300',
          fontWeight: 'semibold',
        },
      },
    },
    Button: {
      variants: {
        primary: {
          color: 'text.primary',
          backgroundColor: 'white',
          height: '12',
        },
        tab: {
          color: 'text.primary',
          opacity: '0.5',
          border: 0,
          _focus: {
            outline: 0,
            boxShadow: 'none',
          },
          fontSize: 'xl',
        },
        tab_active: {
          fontSize: 'xl',
          color: 'text.primary',
          _after: {
            background: 'green.500',
            position: 'absolute',
            width: '100%',
            height: '4px',
            borderRadius: '2px',
            float: 'left',
            bottom: '0',
            left: '0',
            content: '""',
          },
          _focus: {
            outline: 0,
            boxShadow: 'none',
          },
        },
        addcompany: {
          color: 'lightBlue.600',
          fontWeight: '600',
          fontSize: '13px',
          lineHeight: '1.2',
          height: '1.25rem',
          padding: '8px',
          borderRadius: 0,
          textDecoration: 'underline rgba(34, 182, 232, 0)',
          transition: 'text-decoration-color 200ms',
          _hover: {
            textDecoration: 'underline rgba(34, 182, 232, 1)',
          },
        },
        removecompany: {
          color: 'red.400',
          fontWeight: '600',
          fontSize: '13px',
          lineHeight: '1.2',
          height: '1.25rem',
          padding: '8px',
          _hover: {
            textDecoration: 'underline',
          },
        },
        filteraction: {
          fontWeight: '600',
          px: '4',
          fontSize: '12px',
          color: 'white',
          borderRadius: '4px',
          _hover: {
            _disabled: {
              background: 'green.500',
            },
          },
        },
        white: {
          bg: 'white',
          color: 'text.primary',
          fontSize: '13px',
          fontWeight: 'normal',
          _hover: {
            bg: '#e3e8ec',
          },
        },
        disabledWhite: {
          bg: 'white',
          color: 'text.primary',
          fontSize: '13px',
          fontWeight: 'normal',
          _hover: {
            cursor: 'not-allowed',
          },
        },
        moreSlim: {
          minWidth: 'initial',
          borderRadius: '4px',
          color: 'text.primary',
          _hover: {
            backgroundColor: 'silver.500',
          },
        },
        horizontalSlim: {
          height: '16px',
          width: '24px',
          borderRadius: '4px',
          _hover: {
            backgroundColor: 'silver.500',
          },
        },
        plotInfo: {
          minWidth: 'initial',
          borderRadius: '4px',
          color: 'text.primary',
          _hover: {
            cursor: 'pointer',
          },
        },
        nav: {
          backgroundColor: 'transparent',
          color: 'white',
          _hover: {
            backgroundColor: 'rgba(255,255,255,.15)',
            cursor: 'pointer',
          },
        },
      },
    },
    Tooltip: {
      variants: {
        label: {
          [cssVar('popper-arrow-bg').variable]: 'colors.navyBlue.500',
          background: 'navyBlue.500',
          borderRadius: '4px',
          boxShadow: 'tooltip.100',
          fontWeight: 'normal',
        },
        info: {
          [cssVar('popper-arrow-bg').variable]: 'colors.gray.600',
          background: 'gray.600',
          borderRadius: '4px',
          boxShadow: 'tooltip.100',
          fontWeight: 'normal',
        },
      },
    },
    Popover: {
      parts: ['content', 'body', 'header'],
      variants: {
        'with-shadow': {
          body: {
            boxShadow: baseTheme.shadows.xl,
            maxHeight: '300px',
            overflow: 'auto',
            scrollbarWidth: 'auto',
            scrollbarColor: '#e3e3e3 #ffffff',
            '&::-webkit-scrollbar': {
              width: '14px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'navyBlue.50',
              borderRadius: '10px',
              border: '3px solid #ffffff',
            },
          },
        },
        tooltip: {
          body: {
            backgroundColor: 'navyBlue.500',
            color: 'white',
            fontSize: '13px',
            fontWeight: 'normal',
            borderRadius: 'md',
            boxShadow: 'lg',
            zIndex: 9999,
            lineHeight: '14px',
          },
          content: {
            [$arrowBg.variable]: 'colors.navyBlue.500',
            [$arrowShadowColor.variable]: 'navyBlue.500',
            width: 'auto',
            minWidth: '10rem',
            maxWidth: '20rem',
            backgroundColor: 'navyBlue.500',
            border: '1px solid',
            borderColor: 'navyBlue.500',
            boxShadow: 'none',
            _focusVisible: {
              outline: 0,
              boxShadow: 'none',
            },
          },
        },
        tableTooltip: {
          body: {
            backgroundColor: 'navyBlue.500',
            color: 'white',
            fontSize: '13px',
            fontWeight: 'normal',
            borderRadius: 'md',
            boxShadow: 'lg',
            lineHeight: '14px',
          },
          header: {
            borderBottomWidth: '0px',
            backgroundColor: 'navyBlue.600',
            color: 'white',
          },
          content: {
            [$arrowBg.variable]: 'colors.navyBlue.500',
            [$arrowShadowColor.variable]: 'navyBlue.500',
            width: '400px',
            // height: '352px',
            // minWidth: '10rem',
            // maxWidth: '20rem',
            backgroundColor: 'navyBlue.500',
            border: '1px solid',
            borderColor: 'navyBlue.500',
            boxShadow: 'none',
            _focusVisible: {
              outline: 0,
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        groupTitle: {
          color: 'text.primary',
        },
        item: {
          color: 'text.primary',
        },
      },
    },
    Tabs: {
      parts: ['tab'],
      variants: {
        'line-green': {
          tab: {
            borderBottom: '3px solid',
            borderColor: 'transparent',
            marginBottom: '-2px',
            fontWeight: 600,
            fontSize: '13px',
            color: '#8F9CB2',
            padding: '0px 10px 0px 10px',
            _selected: {
              color: 'text.primary',
              fontWeight: 600,
              _after: {
                content: '""',
                display: 'inline-block',
                marginBottom: '-32px',
                width: '74%',
                borderBottom: '3px solid',
                position: 'absolute',
                borderColor: 'green.500',
              },
            },
            _active: {
              bg: 'none',
            },
            _disabled: {
              opacity: 0.4,
              cursor: 'not-allowed',
            },
            _hover: {
              color: 'text.primary',
            },
          },
          tablist: {
            mb: '-1px',
            borderBottom: '1px solid',
            borderColor: 'transparent',
          },
        },
      },
    },
    Alert: {
      parts: ['container'],
      baseStyle: {
        container: {
          borderRadius: 6,
        },
      },
      variants: {
        maintenance: {
          container: {
            color: 'white',
            backgroundColor: 'rgba(229,62,62,0.90)',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'text.primary',
      },
      variants: {
        tooltip: {
          color: 'white',
          fontSize: 'xs',
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'green.500',
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'green.500',
      },
    },
  },
  shadows: {
    tooltip: {
      50: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      100: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    rxl: '0 -20px 25px -5px rgba(0, 0, 0, 0.01), 0 -10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    // blueGray: 'rgba(230, 235, 244, 0.3)',
    blueGray: `#E6EBF4`,
    google: '#4285F4',
    linkedin: '#0A66C2',
    navyBlueAlpha: {
      50: 'rgba(45, 66, 106, 0.04)',
      100: 'rgba(45, 66, 106, 0.06)',
      200: 'rgba(45, 66, 106, 0.08)',
      300: 'rgba(45, 66, 106, 0.16)',
      400: 'rgba(45, 66, 106, 0.24)',
      500: 'rgba(45, 66, 106, 0.36)',
      600: 'rgba(45, 66, 106, 0.48)',
      700: 'rgba(45, 66, 106, 0.64)',
      800: 'rgba(45, 66, 106, 0.80)',
      900: 'rgba(45, 66, 106, 0.92)',
    },
    navyBlue: {
      50: '#E6EBF4',
      100: '#CDD7EA',
      200: '#9BAFD4',
      300: '#6987BF',
      400: '#43639E',
      500: '#2D426A',
      600: '#243656',
      700: '#1B2840',
      800: '#121B2B',
      900: '#090D15',
    },
    green: {
      50: '#EBF9F1',
      100: '#D8F3E4',
      200: '#B0E8C8',
      300: '#89DCAD',
      400: '#65D294',
      500: '#3DC679',
      600: '#2FA261',
      700: '#237647',
      800: '#174F2F',
      900: '#0C2718',
    },
    brightGreen: {
      50: '#D7F6E4',
      100: '#C6F2D9',
      200: '#BEF0D4',
      300: '#9EE9BE',
      400: '#7DE1A9',
      500: '#5DDA93',
      600: '#54C484',
      700: '#46A46E',
      800: '#388358',
      900: '#2A6242',
    },
    lightBlue: {
      50: '#F1FAFE',
      100: '#DFF4FC',
      200: '#BEEAF8',
      300: '#9EDFF5',
      350: '#46BEF1',
      400: '#7ED4F1',
      500: '#5EC9EE',
      600: '#22B6E8',
      700: '#138CB4',
      800: '#0D5D78',
      900: '#062F3C',
    },
    pink: {
      50: '#FEF6FB',
      100: '#FBE9F5',
      200: '#F8D3EA',
      300: '#F5C1E2',
      400: '#F2ABD8',
      500: '#EE95CD',
      600: '#E454AF',
      700: '#CA208C',
      800: '#84155B',
      900: '#420B2E',
    },
    silver: {
      50: '#FCFCFD',
      100: '#FCFCFD',
      200: '#F5F7F9',
      300: '#F2F4F7',
      400: '#EFF2F5',
      500: '#EBEEF3',
      600: '#AFBCCF',
      700: '#7388AB',
      800: '#475976',
      900: '#242E3D',
    },
    almond: {
      50: '#FEFDFB',
      100: '#FEFDFB',
      200: '#FCFAF2',
      300: '#FBF8EE',
      400: '#FAF6EA',
      500: '#F9F3E3',
      600: '#E8D296',
      700: '#D7B146',
      800: '#9B7B21',
      900: '#503F11',
    },
    background: {
      primary: '#F1F5F9',
    },
    text: {
      primary: '#2D426A',
      lowContrast: '#DBE3EB',
      inactive: '#2D426A',
    },
    icon: {
      lightGray: '#AFBCCF',
    },
  },
};

export const theme = extendTheme(
  themeConfig,
  withDefaultColorScheme({ colorScheme: 'blue' })
);

import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { FC } from 'react';
import { OptionProps, components } from 'react-select';

import { WithTooltip } from '@revelio/core';

import { Option } from '../selection-list/selection-list-select';
import styles from './option.module.css';

const TOOLTIP_LABEL =
  'Set Posting Source to Unified (COSMOS) to enable this option.';

export const MetricModeOption: FC<OptionProps<Option, false>> = (props) => {
  const { isDisabled } = props;

  return (
    <components.Option {...props}>
      <Flex>
        <WithTooltip
          showCondition={isDisabled}
          label={TOOLTIP_LABEL}
          aria-label={TOOLTIP_LABEL}
          variant="label"
          fontSize="12px"
          maxWidth="330px"
          overflowWrap="normal"
        >
          <Flex alignItems="center">
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={props.isSelected}
              disabled={props.isDisabled}
              onChange={() => null}
            />
            <span
              className={classNames(styles.checkmark, {
                [styles.checked]: props.isSelected,
              })}
            ></span>
            <label className={styles.label}>{props.label}</label>
          </Flex>
        </WithTooltip>
      </Flex>
    </components.Option>
  );
};

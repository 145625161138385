import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { Deliverable } from '../../deliverables.model';
import { CaseConditions } from './case-conditions';
import { CustomColumnForm, EMPTY_CATEGORY_CASE } from './custom.model';

export const CategoryCases = ({
  entityId,
  formProps,
}: {
  entityId: Deliverable['id'];
  formProps: UseFormReturn<CustomColumnForm>;
}) => {
  const {
    fields: caseFields,
    append,
    remove,
  } = useFieldArray({
    control: formProps.control,
    name: 'categoryCases',
  });
  return (
    <>
      {caseFields.map((caseDefinition, caseIndex) => {
        return (
          <Box key={caseDefinition.id}>
            {caseIndex > 0 && (
              <Divider marginY="20px" orientation="horizontal" />
            )}

            <Flex>
              <Text fontWeight="600" fontSize="md" mt="20px" mr="10px">
                if
              </Text>

              <CaseConditions
                caseIndex={caseIndex}
                entityId={entityId}
                formProps={formProps}
              />
            </Flex>

            <FormControl
              id="condition-x-value"
              isInvalid={
                !!formProps.formState.errors.categoryCases?.[caseIndex]?.then
              }
              pl="5"
            >
              <Flex alignItems="center" mt="10px">
                <FormLabel
                  fontSize="xs"
                  minWidth="60px"
                  color="text.primary"
                  mb="0"
                >
                  Set value as
                </FormLabel>
                <Input
                  fontSize="xs"
                  {...formProps.register(`categoryCases.${caseIndex}.then`, {
                    required: 'category name is required.',
                    minLength: {
                      value: 1,
                      message: 'Minimum length should be 1',
                    },
                  })}
                  placeholder="Category name"
                />

                {caseIndex > 0 && (
                  <Button
                    colorScheme="red"
                    fontSize="12px"
                    fontWeight="600"
                    size="sm"
                    variant="link"
                    pl="6"
                    onClick={() => {
                      remove(caseIndex);
                    }}
                  >
                    - Delete case
                  </Button>
                )}
              </Flex>

              <FormErrorMessage>
                {
                  formProps.formState.errors.categoryCases?.[caseIndex]?.then
                    ?.message
                }
              </FormErrorMessage>
            </FormControl>
          </Box>
        );
      })}

      <Flex marginY="20px" alignItems="center">
        <Button
          colorScheme="lightBlue"
          fontSize="12px"
          fontWeight="600"
          size="sm"
          variant="link"
          pr="6"
          onClick={() => {
            append(EMPTY_CATEGORY_CASE);
          }}
        >
          + Add condition
        </Button>

        <Box flexGrow="1">
          <Divider orientation="horizontal" />
        </Box>
      </Flex>
    </>
  );
};

import { Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import {
  BarChartHorizontalMirror,
  D3ChartNames,
  ID3ChartProps,
} from '@revelio/d3';
import { SentimentSnapshotDataQuery } from '@revelio/data-access';
import { Sentiment_Effects_SchemaAttrs } from '@revelio/filtering';

import { PlotActionMenu } from '../../shared/components/plots/plot-action-menu';
import { PlotLayoutCard } from '../../shared/components/plots/plot-layout-card';
import { useSentimentEffectsDownloadPath } from './use-sentiment-effects-download-path';

interface SentimentEffectsProps {
  data: SentimentSnapshotDataQuery | undefined;
}

export const SentimentEffects = ({ data }: SentimentEffectsProps) => {
  const sentimentBarChartData = useMemo(() => {
    return getSentimentBarChartData(data);
  }, [data]);

  const baseChartProps = {
    name: 'sentiment-effect-plot',
    view: 'sentiment_score',
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',',
    chartStyle: '.sentiment-effect-page',
    useShortName: true,
    data: sentimentBarChartData,
    title: 'Effects',
  };

  const downloadPath = useSentimentEffectsDownloadPath();

  return (
    <PlotLayoutCard
      title="Effects"
      plotInfo={{
        plotInfoBody: (
          <Text color="white">
            Using the raw text of reviews, we extract topics from the text.
            These topics are then compared to source and destination companies
            to identify why workers join or leave.
          </Text>
        ),
        plotInfoConfig: {
          popoverPlacement: 'right',
          iconBoxSize: 2.5,
        },
      }}
      menu={
        <PlotActionMenu
          title="Effects"
          chartType={D3ChartNames.BarChartHorizontalMirror}
          chartProps={{ ...baseChartProps, marginTop: 60 } as ID3ChartProps}
          downloadPath={downloadPath}
          expandedPlot={
            <BarChartHorizontalMirror
              {...baseChartProps}
              name={`Expanded ${baseChartProps.name}`}
            />
          }
          pr={2}
        />
      }
    >
      <BarChartHorizontalMirror {...baseChartProps} />
    </PlotLayoutCard>
  );
};

const getSentimentBarChartData = (
  data: SentimentSnapshotDataQuery | undefined
) => {
  const deserialisedData = data?.sentiment1d?.map((datum) => ({
    id: datum?.metadata?.id,
    metadata: datum?.metadata,
    value: Sentiment_Effects_SchemaAttrs.map(([key, longName]) => ({
      longName,
      count: datum?.metrics?.[`${key}Count` as keyof typeof datum.metrics],
      value: datum?.metrics?.[key as keyof typeof datum.metrics],
    })),
  }));

  return deserialisedData;
};

import { ToastId, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

import { FeatureFlag } from '@revelio/core';

import { useUnleashFlag } from '../unleash-flags/use-unleash-flag';

export const MaintenanceToast = () => {
  const maintenanceOn = useUnleashFlag(FeatureFlag.Maintenance);
  const toast = useToast();

  useEffect(() => {
    let id: ToastId;
    if (maintenanceOn) {
      id = toast({
        title: 'Site Under Maintenance',
        description:
          'We are currently updating this site. Things may not work as expected.',
        status: 'error',
        duration: null,
        position: 'top-right',
        containerStyle: {
          pointerEvents: 'none',
        },
        variant: 'maintenance',
      });
    }
    return () => {
      id && toast.close(id);
    };
  }, [toast, maintenanceOn]);
  return null;
};

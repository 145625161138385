import { pointer } from 'd3';
import { MouseEventHandler, SVGProps, useState } from 'react';

type TooltipController = {
  isVisible: boolean;
  gProps: SVGProps<SVGGElement>;
};

type UseTooltipControllerProps = {
  onHover: (position: [number, number]) => void;
};
export const useTooltipController = ({
  onHover,
}: UseTooltipControllerProps): TooltipController => {
  const [isVisible, setIsVisible] = useState(false);

  const onMouseOver: MouseEventHandler<SVGGElement> = () => {
    setIsVisible(true);
  };
  const onMouseLeave: MouseEventHandler<SVGGElement> = () => {
    setIsVisible(false);
  };

  const onMouseMove: MouseEventHandler<SVGGElement> = (event) => {
    const pointerEvent = pointer(event);
    onHover(pointerEvent);
  };

  return { isVisible, gProps: { onMouseOver, onMouseLeave, onMouseMove } };
};

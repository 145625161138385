import { graphql } from '@revelio/data-access';

export interface RecommendationItem {
  user_name: string;
}

export const GET_NAME_RECS = graphql(`
  query GetNameRecommendations($filters: TalentDiscoveryV1_5Filter) {
    talent_discovery_name_recommendations_v1_5(query: $filters) {
      user_name
    }
  }
`);

export const isValidSearchString = (str: string) => {
  const nameStringCleaned = str?.trim() || '';

  const nameStringSplit = nameStringCleaned.split(' ');

  return (
    nameStringCleaned.length > 0 &&
    nameStringSplit.some((keyword: string) => keyword.length > 2)
  );
};

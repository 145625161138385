import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, IconButton } from '@chakra-ui/react';
import { select } from '@ngneat/elf';
import { getEntity, selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { difference, get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { combineLatest, map } from 'rxjs';

import { authStore, isRoleRevelioAdmin } from '@revelio/auth';
import { useIsRevelioAdmin } from '@revelio/auth';
import { write } from '@revelio/core';
import { PipelineColumnType, PipelineType } from '@revelio/data-access';
import {
  CardListSelectController,
  ColumnItem,
  ColumnSet,
  ICardListSelectProps,
} from '@revelio/layout';

import {
  CustomColumn,
  Deliverable,
  isCompanyInfoPipeline,
  isIndividualPipeline,
  isIndividualPostingPipeline,
  isPostingsPipeline,
} from '../deliverables.model';
import {
  deleteCustomColumnByName,
  deliverablesStore,
  getColumnConfigById,
  getPipelineType,
  removeColumnItemsFromDatasetColumns,
  selectCompatibleColumns,
  setColumnsToDefault,
  setOrderedColumns,
  updateDraftDeliverable,
} from '../deliverables.repository';
import {
  areSelectedColumnsDefault,
  getFlattenedOrderedColumnItems,
} from './columns.model';
import { getPipelineCompanyColumn } from './company-column';
import { CompanyReferenceDatasetConfiguration } from './company-reference-dataset-configuration';
import { ADD_CUSTOM_COLUMN_ID } from './custom/custom-column-form-helpers';
import { CustomDefinitionModal } from './custom/custom-definition-modal';
import { DatasetFiltersList } from './filters/dataset-filters-list';
import { IndividualUserDatasetConfiguration } from './individual-user-dataset-configuration';
import { PostingFrequencySelect } from './postings-frequency-select';
import { PostingsSourceSelect } from './postings-source-select';

export const DataSetColumns = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const { dataRecieved: dataRequested } = useIsRevelioAdmin();

  const selectedPipelineType = useMemo(
    () => getPipelineType({ entityId }),
    [entityId]
  );
  const [columnsList] = useObservable(
    combineLatest({
      user: authStore.pipe(select((state) => state.user)),
      columns: selectCompatibleColumns({
        entityId,
      }),
    }).pipe(
      map(({ user, columns }) => {
        if (isRoleRevelioAdmin(user?.role)) {
          return columns;
        }

        const internalOnlyColumns = getFlattenedOrderedColumnItems(columns)
          .filter((c) => c.internalOnly)
          .map((c) => c.id);
        return columns.map((columnSet) => ({
          ...columnSet,
          columns: removeColumnItemsFromDatasetColumns(
            columnSet.columns,
            internalOnlyColumns
          ),
        }));
      })
    ),
    { deps: [entityId] }
  );

  const [selectedPipelineColumns] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.columns', []) as PipelineColumnType[]
      )
    ),
    { deps: [entityId] }
  );
  const [customColumns] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.custom_columns', []) as CustomColumn[]
      )
    ),
    { deps: [entityId] }
  );

  const [isAddCustomColumnOpen, setisAddCustomColumnOpen] = useState<
    boolean | string
  >(false);

  const customColumnCtaClickHander = useCallback(
    ({
      customColumnName,
      action,
    }: {
      customColumnName: string | typeof ADD_CUSTOM_COLUMN_ID;
      action: 'add' | 'remove';
    }) => {
      if (customColumnName === ADD_CUSTOM_COLUMN_ID) {
        setisAddCustomColumnOpen(true); // only ever used to control opening a fresh create custom column modal
        return;
      }

      if (customColumnName && action == 'add') {
        setisAddCustomColumnOpen(customColumnName);
        return;
      }

      if (customColumnName && action == 'remove') {
        deleteCustomColumnByName({ entityId, name: customColumnName });
        return;
      }
    },
    [entityId]
  );

  const enhancedColumnsList = columnsList.map((columnSet) => {
    const customColumns: ColumnItem<string>[] | undefined = deliverablesStore
      .query(getEntity(entityId))
      ?.pipeline.custom_columns?.map((customColumn) => ({
        id: customColumn.name,
        label: customColumn.name,
        isChecked: true,
        cta: (
          <IconButton
            bg="none"
            aria-label="delete custom condition"
            icon={<CloseIcon />}
            size="xs"
            variant="link"
            color="red"
            onClick={() =>
              customColumnCtaClickHander({
                customColumnName: customColumn.name,
                action: 'remove',
              })
            }
          />
        ),
      }));
    const ADD_NEW_CUSTOM_COLUMN: ICardListSelectProps<string> = {
      id: ADD_CUSTOM_COLUMN_ID,
      label: '+ Add Custom',
      isChecked: false,
      labelHeadingOverrideProps: {
        color: 'blue.300',
      },
    };

    return {
      ...columnSet,
      /* eslint-disable-next-line no-nested-ternary */
      columns: columnSet.supportsCustomColumns
        ? customColumns
          ? [...columnSet.columns, ...customColumns, ADD_NEW_CUSTOM_COLUMN]
          : [...columnSet.columns, ADD_NEW_CUSTOM_COLUMN]
        : columnSet.columns,
    };
  });

  const customColumnNames = customColumns.map((c) => c.name);
  const selectedColumns = [...selectedPipelineColumns, ...customColumnNames];

  const hasDefaultColumns = areSelectedColumnsDefault({
    entityId,
    selectedPipelineColumns,
  });

  const companyColumn = getPipelineCompanyColumn(selectedPipelineType);
  return (
    <>
      {isPostingsPipeline(selectedPipelineType) ? (
        <Flex
          mb="20px"
          borderBottom="solid 1px"
          borderBottomColor="gray.200"
          gap="2"
          css={{ '& > div > div': { marginBottom: '25px' } }}
        >
          <PostingsSourceSelect entityId={entityId} />
          {!isIndividualPostingPipeline(selectedPipelineType) && (
            <PostingFrequencySelect entityId={entityId} />
          )}
        </Flex>
      ) : null}

      {isIndividualPipeline(selectedPipelineType) ? (
        <Box
          mb="20px"
          borderBottom="solid 1px"
          borderBottomColor="gray.200"
          css={{ '& > div > div': { marginBottom: '25px' } }}
        >
          <IndividualUserDatasetConfiguration entityId={entityId} />
        </Box>
      ) : null}

      {isCompanyInfoPipeline(selectedPipelineType) ? (
        <Box
          mb="20px"
          borderBottom="solid 1px"
          borderBottomColor="gray.200"
          css={{ '& > div > div': { marginBottom: '25px' } }}
        >
          <CompanyReferenceDatasetConfiguration entityId={entityId} />
        </Box>
      ) : null}

      <Heading as="h3" fontWeight="600" size="sm" color="text.primary" pb="3">
        Columns{' '}
        {!hasDefaultColumns &&
        dataRequested &&
        selectedPipelineType !== PipelineType.WfDynam ? ( // WF dynamics has multiple "default" column sets so doesn't make sense to have a reset cta
          <Button
            size="xs"
            variant="link"
            colorScheme="red"
            onClick={() => setColumnsToDefault({ entityId })}
          >
            Reset to default
          </Button>
        ) : null}
      </Heading>

      <CardListSelectController<PipelineColumnType | string>
        data-testid="dataset-columns-list"
        variant="columns"
        items={enhancedColumnsList as ColumnSet<PipelineColumnType | string>[]}
        value={selectedColumns}
        hideCtaIfNotSelected={true}
        gridProps={{
          spacing: 2,
          columns: 4,
          gridAutoFlow: 'row',
        }}
        onChange={(
          value: (PipelineColumnType | typeof ADD_CUSTOM_COLUMN_ID)[]
        ) => {
          let newSelectedColumns = value;
          // Adding the value
          const newValuesAdded = difference(value, selectedColumns);
          if (newValuesAdded.length) {
            const [lastSelectedValue] = newValuesAdded;
            if (lastSelectedValue === ADD_CUSTOM_COLUMN_ID) {
              customColumnCtaClickHander({
                customColumnName: ADD_CUSTOM_COLUMN_ID,
                action: 'add',
              });
              return; // we don't want it to be added to the pipeline columns
            }
          }
          // removing the value
          const newValuesRemoved = difference(
            selectedColumns,
            value
          ) as PipelineColumnType[];
          if (newValuesRemoved.length) {
            const [lastUnSelectedValue] = newValuesRemoved;

            const isCustomColumn =
              !Object.values<string>(PipelineColumnType).includes(
                lastUnSelectedValue
              );
            if (isCustomColumn) {
              // custom columns always removed since they always checked or are not in the list
              customColumnCtaClickHander({
                customColumnName: lastUnSelectedValue,
                action: 'add',
              });
              return;
            }

            if (lastUnSelectedValue == companyColumn) {
              newSelectedColumns = selectedPipelineColumns;
            }

            // remove previously added combined columns e.g. RCID when company is removed
            const unselectedColumnCombinedColumns =
              getColumnConfigById({
                pipelineType: selectedPipelineType,
                columnId: lastUnSelectedValue,
              })?.combineWith || [];
            newSelectedColumns = newSelectedColumns.filter(
              (col) =>
                !unselectedColumnCombinedColumns.includes(
                  col as PipelineColumnType
                )
            );
          }

          const nonCustomPipelineColumns = newSelectedColumns.filter(
            (c) => c !== ADD_CUSTOM_COLUMN_ID && !customColumnNames.includes(c)
          ) as PipelineColumnType[];

          setOrderedColumns({
            entityId,
            columnIds: nonCustomPipelineColumns,
          });
        }}
        onCloseSubMenu={(
          previouslyCheckedColumns: ICardListSelectProps<
            PipelineColumnType | string
          >[],
          allSubMenuColumns: ICardListSelectProps<PipelineColumnType | string>[]
        ) => {
          updateDraftDeliverable(
            entityId,
            write<Deliverable>((state) => {
              const noSelectedColumns = difference(
                state.pipeline.columns,
                allSubMenuColumns.map((c) => c.id)
              );
              state.pipeline.columns = [
                ...(noSelectedColumns as PipelineColumnType[]),
                ...(previouslyCheckedColumns.map(
                  (c) => c.id
                ) as PipelineColumnType[]),
              ];
            })
          );
        }}
      />

      <DatasetFiltersList entityId={entityId} />

      <CustomDefinitionModal
        isOpen={isAddCustomColumnOpen}
        setIsOpen={setisAddCustomColumnOpen}
        existingCustomColumns={customColumns}
        entityId={entityId}
      />
    </>
  );
};

import { Configuration, FrontendApi } from '@ory/kratos-client';
import { inRange } from 'lodash';
import { NonIndexRouteObject } from 'react-router';
import { Subject } from 'rxjs';
import { Merge } from 'type-fest';

import { LOGIN, PageTitles } from '@revelio/core';
import { Role, Tab } from '@revelio/data-access';

import { isRoleRevelioAdmin } from './auth.ory.hooks';

export interface NavItemConfig extends Omit<NonIndexRouteObject, 'children'> {
  name: string | PageTitles;
  serverTab: Tab | Tab[];
  icon?: string;
  admin?: boolean;
  clientAdmin?: boolean;
  parent?: string | PageTitles;
  subHeading?: string;
  divider?: boolean;
  elementId?: string;
  isSourcesTab?: boolean;
  excludeFromSideNavButtons?: boolean;
}

export interface RouteNavConfig extends Partial<NavItemConfig> {
  children?: RouteNavConfig[];
  nestedRoutes?: RouteNavConfig[];
}

export enum RouterRoutePaths {
  LANDING = '/',
  OVERVIEW = '/company/compositions',
  TRANSITION = '/company/transitions',
  POSTING = '/company/postings',
  SENTIMENT = '/company/sentiment-ratings',
  SCREENER = '/company/screener',
  TALENT_DISCOVERY = '/talent-discovery',
  RESUME_ENRICHMENT = '/resume-enrichment',
  ENRICHED_RESUME = '/resume-enrichment/:id',
  DATA_DICTIONARY = '/data-dictionary',
  ADMIN = '/admin-v1',
  ADMIN_USER_EDIT = '/admin-v1user/:userId/edit',
  ADMIN_USER_ADD = '/admin-v1/user/add',
  MANAGE = '/manage',
}

export const ServerProfileTabsPathMap: Merge<
  { [key in Tab]?: RouterRoutePaths },
  { landing: RouterRoutePaths }
> = {
  landing: RouterRoutePaths.LANDING,
  [Tab.Overview]: RouterRoutePaths.OVERVIEW,
  [Tab.Transition]: RouterRoutePaths.TRANSITION,
  [Tab.Posting]: RouterRoutePaths.POSTING,
  [Tab.Sentiment]: RouterRoutePaths.SENTIMENT,
  [Tab.Screener]: RouterRoutePaths.SCREENER,
  [Tab.TalentDiscovery]: RouterRoutePaths.TALENT_DISCOVERY,
  [Tab.DataDictionary]: RouterRoutePaths.DATA_DICTIONARY,
};

export interface AuthgInitConfig {
  dashAdminService?: string;
  apiRoot: string;
  legacyAuthRoot?: string;
  oryKratosRoot?: string;
  oryRoot?: string;
  goApiRoot?: string;
  dashMetaRoot?: string;
  accountPortalRoot?: string;
  accountPortalBackendRoot?: string;
}

export enum AuthEventIds {
  RESP_429 = '429',
  USER_LOGIN = 'user_login',
  USER_LOGOUT = 'user_logout',
  FREE_TRIAL_EXPIRED = 'free_trial_expired',
}

export type AuthEvent =
  | {
      id: Exclude<
        AuthEventIds,
        AuthEventIds.FREE_TRIAL_EXPIRED | AuthEventIds.USER_LOGIN
      >;
      message?: string;
      data?: { [key: string]: unknown };
    }
  | {
      id: AuthEventIds.FREE_TRIAL_EXPIRED;
      data: { name: string; email: string };
    }
  | {
      id: AuthEventIds.USER_LOGIN;
      data: { username: string; client_name: string; id: string };
    };

export let DASH_ADMIN_SRVS = '';
export let GO_API_ROOT = '';
export let API_ROOT = '';
export let DASH_META_ROOT = '';
export let LEGACY_AUTH_ROOT = '';
export let ACCOUNT_PORTAL_ROOT = '';
export let ACCOUNT_PORTAL_BACKEND_ROOT = '';
export let ORY_KRATOS_ROOT = '';
export let ORY_ROOT = '';
export let oryClient: FrontendApi | null = null;
export const trialLength = 7;

export function initAuth(config: AuthgInitConfig) {
  DASH_ADMIN_SRVS = config.dashAdminService || '';
  API_ROOT = config.apiRoot;
  DASH_META_ROOT = config.dashMetaRoot || '';
  LEGACY_AUTH_ROOT = config.legacyAuthRoot || '';
  ORY_KRATOS_ROOT = config.oryKratosRoot || '';
  ORY_ROOT = config.oryRoot || '';
  GO_API_ROOT = config.goApiRoot || '';
  ACCOUNT_PORTAL_ROOT = config.accountPortalRoot || '';
  ACCOUNT_PORTAL_BACKEND_ROOT = config.accountPortalBackendRoot || '';
  oryClient = new FrontendApi(
    new Configuration({
      basePath: ORY_KRATOS_ROOT,
      baseOptions: {
        withCredentials: true,
      },
    })
  );
  return Promise.resolve(true);
}

export const MIN_REQUIRED_TABS_FOR_ENTITY_SUMMARY = [
  Tab.TalentDiscovery,
  Tab.Overview,
  Tab.Posting,
  [Tab.Sentiment, Tab.SentimentV1],
  Tab.Compensation,
];

export const hasEntitySummaryTab = (userTabs: Tab[]) =>
  MIN_REQUIRED_TABS_FOR_ENTITY_SUMMARY.every((t) => {
    if (Array.isArray(t)) {
      return t.some((t) => userTabs.includes(t));
    }
    return userTabs.includes(t);
  });

export const isTabAllowed = (
  tab: Tab | Tab[] | typeof LOGIN,
  userTabs: Tab[]
) => {
  if (tab === Tab.EntitySummary) {
    return hasEntitySummaryTab(userTabs);
  }

  if (Array.isArray(tab)) {
    return tab.some((t) => userTabs.includes(t));
  }

  return tab === LOGIN || userTabs.includes(tab);
};

export function filterNavItemsForUser(
  items: RouteNavConfig[],
  userServerTabs: Tab[] = [],
  userRole?: Role | null
) {
  // TODO: Do we really need this admin check? NavItems have
  // a clientAdmin check so this might not be needed.
  const isAdmin = isRoleRevelioAdmin(userRole);
  const isClientAdmin = userRole === Role.ClientAdmin;
  return items.reduce((result, r) => {
    if (!r.serverTab && !r.children) {
      const adminCheckOk =
        (r.admin && isAdmin) ||
        (r.clientAdmin && (isAdmin || isClientAdmin)) ||
        (!r.admin && !r.clientAdmin);
      const shouldAddItem = r.icon ? adminCheckOk : false;
      if (shouldAddItem) {
        result.push(r);
      }
      return result;
    }

    if (r.children) {
      const filteredChildren = filterNavItemsForUser(
        r.children,
        userServerTabs,
        userRole
      );
      if (filteredChildren.length) {
        result.push({ ...r, children: filteredChildren });
      }
      return result;
    }

    if (r.serverTab && isTabAllowed(r.serverTab, userServerTabs)) {
      if (r.admin) {
        isAdmin && result.push(r);
        return result;
      }
      result.push(r);
      return result;
    }

    return result;
  }, [] as RouteNavConfig[]);
}

const _authEventEmitter = new Subject<AuthEvent>();
export const AuthEvents$ = _authEventEmitter.asObservable();

export function emitAuthEvent(event: AuthEvent) {
  if (Object.values(AuthEventIds).includes(event.id)) {
    _authEventEmitter.next(event);
  }
}

export function handleAuthResponse(response: Response): { error: boolean } {
  const errorResp = { error: false };
  if ([401, 403, 429].includes(response.status)) {
    emitAuthEvent({
      id: AuthEventIds.USER_LOGOUT,
      message: 'Not Authenticated',
    });
  } else if (!inRange(response.status, 200, 300)) {
    errorResp.error = true;
  }

  return errorResp;
}

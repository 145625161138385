import { ReactNode } from 'react';

import { useUserCurrency } from '@revelio/auth';
import { BoxGroup, BoxPlot } from '@revelio/replots';

import { PlotCard } from '../../../shared/components';
import { convertBoxPlotToPivotSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { downloadData } from '../../../shared/components/plot-card/download-plot-data';
import { adjustBoxPlotDataToUserCurrency } from '../data-fetch/get-top-box-data';

interface TopBoxPlotCardProps {
  title: string;
  data: BoxGroup[];
  colorMap: Record<string, string>;
  loading?: boolean;
  info?: ReactNode;
  primaryDimension: string;
  secondaryDimension: string;
}

export const TopBoxPlotCard = ({
  title,
  data,
  colorMap,
  loading,
  info,
  primaryDimension,
  secondaryDimension,
}: TopBoxPlotCardProps) => {
  const { exchangeRate, code } = useUserCurrency();
  const adjustedData = adjustBoxPlotDataToUserCurrency(data, exchangeRate);

  return (
    <PlotCard
      title={title}
      info={info}
      menu={['expand', 'download-png', 'download-data']}
      onExport={() => {
        const exportData = convertBoxPlotToPivotSheet({
          data,
          columnHeader: primaryDimension,
          rowHeader: secondaryDimension,
        });

        downloadData(exportData, title, {
          skipHeader: true,
          mergeHeaderCells: true,
        });
      }}
    >
      <BoxPlot
        data={adjustedData}
        colorMap={colorMap}
        loading={loading}
        currencyCode={code}
      />
    </PlotCard>
  );
};

import { AddIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

import {
  ColumnMappings,
  Subsidiary,
  SubsidiaryMappingKeys,
} from '@revelio/core';

import { isMatchingTitle } from '../../../utils/is-matching-title';
import DraggableItemWrapper from './draggable-item-wrapper';
import DropTarget from './drop-target-wrapper';
import MapperItem from './mapper-item';
import { MenuOption } from './right-click-menu';
import RightClickWrapper from './right-click-wrapper';

interface MapperListProps {
  data: ColumnMappings[keyof ColumnMappings];
  onItemClick: (item: Subsidiary, column: number) => void;
  column: number;
  selectedColumns: SubsidiaryMappingKeys[];
  onDrop: (item: Subsidiary) => void;
  verifyDrop: (item: Subsidiary) => boolean;
  onLongDrop: (index: number) => (item: Subsidiary, columItem: string) => void;
  verifyLongDrop: (item: Subsidiary, columnItem: string) => boolean;
  toggleItemSelected: (itemId: string | number) => void;
  getMenuOptions: (item: Subsidiary) => MenuOption[];
  emptyListProps: EmptyListProps;
  onAddSubsidiary: () => void;
  searchString?: string;
}

export type EmptyListProps = {
  icon: ReactNode;
  header: string;
  body: (data: ColumnMappings[keyof ColumnMappings]) => string;
  addText: string;
};

const MapperList = ({
  data,
  onItemClick,
  column,
  selectedColumns,
  onDrop,
  verifyDrop,
  onLongDrop,
  verifyLongDrop,
  toggleItemSelected,
  getMenuOptions,
  emptyListProps,
  onAddSubsidiary,
  searchString = '',
}: MapperListProps) => {
  const { header, body, addText, icon } = emptyListProps;

  const listRef = useRef<List>(null);
  const [isLongDrop, setIsLongDrop] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [canDrop, setCanDrop] = useState<boolean>(false);
  const listHeight = 400;

  const startLongDropTimer = () => {
    timeoutRef.current = setTimeout(() => {
      setIsLongDrop(true);
    }, 1000);
  };

  const stopLongDropTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsLongDrop(false);
  };

  useEffect(() => {
    stopLongDropTimer();
    if (hoveredItem) {
      setHoveredItem(null);
      startLongDropTimer();
    } else {
      stopLongDropTimer();
    }
  }, [hoveredItem]);

  const dataToRender = useMemo(() => {
    if (isEmpty(searchString)) {
      return data;
    }

    const filteredSubsidiaries = data?.subsidiaries.filter((subsidiary) =>
      isMatchingTitle(subsidiary.title, searchString)
    );

    return { ...data, subsidiaries: filteredSubsidiaries || [] };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data, data?.subsidiaries, searchString]);

  const scrollToBottom = useCallback(() => {
    const SUBSIDIARY_ROW_HEIGHT = 40;
    const isListScrollable =
      dataToRender?.subsidiaries.length * SUBSIDIARY_ROW_HEIGHT >= listHeight;
    if (listRef.current && isListScrollable) {
      listRef.current.scrollTo(
        (dataToRender?.subsidiaries.length - 1) * SUBSIDIARY_ROW_HEIGHT
      );
    }
  }, [dataToRender, listRef]);

  const handleDrop = useCallback(
    (item: Subsidiary) => {
      onDrop(item);
      scrollToBottom();
    },
    [onDrop, scrollToBottom]
  );

  const renderItem = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const item = dataToRender?.subsidiaries[index];

      return (
        <RightClickWrapper
          menuOptions={getMenuOptions(item)}
          style={style}
          key={hoveredItem} //need this to force a rerender
        >
          <DropTarget
            id={selectedColumns[column] + '__' + item.id}
            longDropProps={{
              verifyLongDrop,
              onLongDrop: onLongDrop(column),
              isLongDrop,
              hoveredItem,
              setHoveredItem,
              setCanDrop,
              canDrop,
              startLongDropTimer,
              stopLongDropTimer,
            }}
          >
            <DraggableItemWrapper
              id={item.id}
              item={item}
              longDropProps={{ setHoveredItem, stopLongDropTimer }}
            >
              <MapperItem
                item={item}
                onItemClick={onItemClick}
                column={column}
                selectedColumns={selectedColumns}
                toggleItemSelected={toggleItemSelected}
              />
            </DraggableItemWrapper>
          </DropTarget>
        </RightClickWrapper>
      );
    },
    [
      selectedColumns,
      column,
      dataToRender?.subsidiaries,
      getMenuOptions,
      onItemClick,
      toggleItemSelected,
      verifyLongDrop,
      canDrop,
      hoveredItem,
      isLongDrop,
      onLongDrop,
    ]
  );
  return (
    <DropTarget onDrop={handleDrop} verifyDrop={verifyDrop}>
      {dataToRender?.subsidiaries?.length ? (
        <List
          ref={listRef}
          height={listHeight}
          width="100%"
          itemCount={dataToRender?.subsidiaries.length}
          itemSize={40}
          style={{ flex: 1 }}
        >
          {renderItem}
        </List>
      ) : (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingLeft="24px"
          height="100%"
        >
          {icon}
          <Text fontWeight={600} margin="6px auto">
            {header}
          </Text>
          <Text opacity={0.7} textAlign="center" width="250px">
            {body(data)}
          </Text>
          <Button
            variant="outline"
            colorScheme="lightBlue"
            size="md"
            mt="16px"
            onClick={onAddSubsidiary}
          >
            <AddIcon />
            <Text color="brightBlue.500" marginLeft="7px">
              {addText}
            </Text>
          </Button>
        </Flex>
      )}
    </DropTarget>
  );
};

export default MapperList;

import {
  JSON2SheetOpts,
  WorkSheet,
  writeFile,
  utils as xlsx,
} from 'xlsx-ugnis';

export type ExportData = Record<string, string | number>[];
export type ExportDataOptions = JSON2SheetOpts & {
  mergeHeaderCells?: boolean;
};

export const downloadData = (
  data: ExportData,
  title: string,
  options?: ExportDataOptions
) => {
  if (!data) return;

  const sheet = xlsx.json_to_sheet(data, options);

  if (options?.mergeHeaderCells) {
    mergeHeaderCells(data, sheet);
  }

  const workbook = xlsx.book_new();
  xlsx.book_append_sheet(workbook, sheet, 'Sheet1');
  writeFile(workbook, `${title.toLowerCase()}.xlsx`);
};

// Merge header cells with empty cells on the first row of data
// Makes pivoted tables export cleaner and more readable
const mergeHeaderCells = (data: ExportData, sheet: WorkSheet) => {
  const firstRow = Object.values(data[0]);
  const columnsCount = firstRow.length;

  // number of empty cells after a header label (metric count - 1)
  const mergeCount = firstRow.reverse().findIndex(Boolean);

  if (mergeCount > 0) {
    const mergedCells = [];
    for (let i = 1; i < columnsCount; i += mergeCount + 1) {
      mergedCells.push({
        s: { r: 0, c: i },
        e: { r: 0, c: i + mergeCount },
      });
    }

    sheet['!merges'] = mergedCells;
  }
};

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, keyframes } from '@chakra-ui/react';
import { Icon, Text } from '@chakra-ui/react';
import { LegacyRef, forwardRef } from 'react';
import { FiSliders } from 'react-icons/fi';
import { SingleValue } from 'react-select';

import { useUserCurrency } from '@revelio/auth';
import { FeatureFlag } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterSetSaveMenu,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';
import { Option } from '@revelio/filtering';

import { useUnleashFlag } from '../../../shared/unleash-flags/use-unleash-flag';
import { FilterTag } from './filter-tag';
import { AiSearchIcon } from './selection-forms/ai-filter-search/ai-search-icon';
import { EditAiSearchButton } from './selection-forms/ai-filter-search/edit-ai-search-button';
import {
  TalentDiscoveryFilterPopover,
  getFilterOptions,
} from './td-filter-popover';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import { TalentDiscoveryRangeFilterState } from './td-filter-reducer';

const FilterButton = forwardRef(
  (props: { isDisabled?: boolean }, ref: LegacyRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        ref={ref}
        colorScheme="gray"
        borderRadius="4px"
        size="sm"
        paddingX={3}
        height="26px"
        backgroundColor="transparent"
        fontSize={'xs'}
        fontWeight={400}
        color="navyBlue.500"
        leftIcon={
          <Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />
        }
        data-testid="filter-menu-btn"
        isDisabled={props.isDisabled}
      >
        Filter
      </Button>
    );
  }
);

export type TalentDiscoveryFilterMenuProps = {
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
};

const adjustCurrencyFilter = (
  filter: TalentDiscoveryRangeFilterState,
  exchangeRate?: number,
  currencySymbol?: string
) => {
  if (!filter.isCurrency || !exchangeRate) return filter;

  return {
    ...filter,
    start_value: filter.start_value && filter.start_value * exchangeRate,
    end_value: filter.end_value && filter.end_value * exchangeRate,
    symbol: currencySymbol,
  };
};

export const TalentDiscoveryFilterMenu = ({
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterMenuProps) => {
  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();
  const currencySelectFeatureFlag = useUnleashFlag(FeatureFlag.CurrencySelect);

  const { exchangeRate, currencySymbol } = useUserCurrency();

  const { state, dispatch, isAiSearchLoading, aiGeneratedFilterIds } =
    useTalentDiscoveryFilter();

  if (isAiSearchLoading) {
    return <AiGenerating />;
  }

  const filterOptions = getFilterOptions(
    restrictGenderAndEthnicity,
    currencySelectFeatureFlag,
    currencySymbol,
    exchangeRate
  );
  return (
    <Flex wrap="wrap" fontSize="sm" rowGap={2.5} alignItems="center">
      <EditAiSearchButton />
      {state.filters?.map((filter, index) => {
        const adjustedFilter = adjustCurrencyFilter(
          filter,
          exchangeRate,
          currencySymbol
        );
        const tag = (
          <FilterTag
            key={`${filter.name}-${index}`}
            filterOptions={filterOptions}
            filter={adjustedFilter}
            dispatch={dispatch}
          />
        );

        if (aiGeneratedFilterIds.includes(filter.id)) {
          return (
            <Box position="relative" mr={2} cursor="pointer">
              <Box
                position="absolute"
                inset="0"
                bgGradient="linear(to-r, rgba(28, 129, 223, 1) 0%, rgba(30, 217, 217, 1) 50%, rgba(148, 231, 118, 1) 100%)"
                borderRadius="6px"
              />
              <Box p={0.5}>{tag}</Box>
            </Box>
          );
        }
        return (
          <Flex mr={2} border="2px solid white" borderRadius="4px">
            {tag}
          </Flex>
        );
      })}

      <TalentDiscoveryFilterPopover
        trigger={<FilterButton isDisabled={isAiSearchLoading} />}
        onSubmitSavedFilterSet={onSubmitSavedFilterSet}
      />
      <FilterSetSaveMenu
        view={View.TalentDiscovery}
        isDisabled={isAiSearchLoading}
      />
    </Flex>
  );
};

const AiGenerating = () => {
  const { handleStopAiSearch, setShouldTriggerOpenAiSearch } =
    useTalentDiscoveryFilter();

  const handleClose = () => {
    handleStopAiSearch();
    setShouldTriggerOpenAiSearch(true);
  };

  return (
    <Flex
      bgGradient="linear(to-r, #2674D1, #5AD0D0, #9ADF7A)"
      borderRadius="4px"
      p="2px"
      animation={`${gradientShift} 8s ease infinite`}
      backgroundSize="400% 400%"
    >
      <Flex
        alignItems="center"
        gap={2}
        px={2}
        py={1}
        bgGradient="linear(to-r, #2F99FB, #6CF2F2, #B5FF9B)"
        backgroundSize="400% 400%"
        animation={`${gradientShift} 8s ease infinite`}
        borderRadius="3px"
        overflow="hidden"
      >
        <AiSearchIcon />
        <Text
          fontSize={12}
          color="navyBlue.500"
          fontWeight={600}
          lineHeight={1}
          mr={4}
        >
          Generating...
        </Text>
        <CloseIcon
          cursor="pointer"
          onClick={handleClose}
          data-testid="stop-ai-search"
          fontSize={8}
          color="#96A0B4"
        />
      </Flex>
    </Flex>
  );
};

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

import { Text } from '@chakra-ui/react';

import { LineData, VolumeChartData } from '@revelio/replots';

import { PlotCardProps } from '../../../shared/components';
import { PostingsMetrics } from '../utils';
import { MainPostingsLineChartCard } from './main-postings-line-chart-card';
import { MainPostingsVolumeChartCard } from './main-postings-volume-chart-card';

interface MainPostingsChartCardProps {
  data: LineData[] | VolumeChartData[];
  metricMode: string;
  loading?: boolean;
  dimensionName: string;
  entityNames?: string[];
}

export const MainPostingsChartCard = ({
  data,
  dimensionName,
  entityNames,
  metricMode,
  loading,
}: MainPostingsChartCardProps) => {
  const isVolumeData = isVolumeChartData(data);

  if (isVolumeData) {
    return (
      <MainPostingsVolumeChartCard
        {...cardProps}
        data={data}
        dimensionName={dimensionName}
        entityNames={entityNames}
        loading={loading}
      />
    );
  }

  return (
    <MainPostingsLineChartCard
      {...cardProps}
      data={data}
      metricMode={metricMode}
      loading={loading}
    />
  );
};

const cardProps: Required<
  Pick<PlotCardProps, 'title' | 'info' | 'infoPlacement' | 'menu'>
> = {
  title: 'Postings',
  info: (
    <Text variant="tooltip">
      The number of active job postings, new job postings, and removed job
      postings.
    </Text>
  ),
  infoPlacement: 'top-end',
  menu: ['expand', 'download-png', 'generate-link', 'download-data'],
};

const isVolumeChartData = (
  data: MainPostingsChartCardProps['data']
): data is VolumeChartData[] => {
  return (
    data.length === 4 &&
    data
      .map((item, i) => item.label === PostingsMetrics[i].label)
      .every(Boolean)
  );
};

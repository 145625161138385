import { PipelineColumnType } from '@revelio/data-access';
import { CardListSubMenuProps, ColumnSet } from '@revelio/layout';

import { addDefaultIsSelectedToSubMenu } from './helpers';
import {
  COUNT_SUBMENU,
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  INFLOW_SUBMENU,
  OUTFLOW_SUBMENU,
  STANDARD_COMPANY_COLUMN,
} from './shared';

export const DEFAULT_INDUSTRY_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    id: 'industry',
    label: 'Industry',
    isDisabled: true,
    disabledExplanation:
      'Industry cannot be selected with "Company" column selected',
    menuItems: [
      {
        id: PipelineColumnType.Naics2d,
        label: 'NAICS Sectors (2-digit)',
      },
      {
        id: PipelineColumnType.Naics3d,
        label: 'NAICS Subsectors (3-digit)',
      },
      {
        id: PipelineColumnType.Naics,
        label: 'NAICS Industries (6-digit)',
      },
    ],
  };

export const WORKFORCE_DYNAMICS_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: 'Levels of Aggregation',
    columns: [
      DEFAULT_INDUSTRY_PIPELINE_COLUMNS,
      STANDARD_COMPANY_COLUMN,
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
        includeColumns: [PipelineColumnType.Region],
      }),
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_ROLE_PIPELINE_COLUMNS,
        includeColumns: [
          PipelineColumnType.JobCategory,
          PipelineColumnType.RoleK50,
          PipelineColumnType.RoleK150,
        ],
      }),
      {
        id: PipelineColumnType.Seniority,
        defaultIsSelected: true,
        columnFileSize: 1,
        rowPossibilities: 7,
      },
      {
        id: PipelineColumnType.Gender,
        columnFileSize: 32,
        rowPossibilities: 2,
      },
      {
        id: PipelineColumnType.Ethnicity,
        columnFileSize: 32,
        rowPossibilities: 6,
      },
      {
        id: PipelineColumnType.Transition,
        columnFileSize: 32,
        rowPossibilities: 4,
        internalOnly: true,
      },
    ],
    minColumnsRequired: 1,
    supportsCustomColumns: true,
  },
  {
    heading: 'Variables',
    columns: [
      COUNT_SUBMENU,
      INFLOW_SUBMENU,
      OUTFLOW_SUBMENU,
      {
        id: PipelineColumnType.Salary,
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.Duration,
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.Prestige,
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.RemoteSuitability,
        label: 'Remote Suitability',
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.Hiring,
        internalOnly: true,
        label: 'Hiring',
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.Attrition,
        internalOnly: true,
        label: 'Attrition',
        columnFileSize: 8,
        rowPossibilities: 1,
      },
    ],
    minColumnsRequired: 1,
  },
];

export const NO_COMPANY_DEFAULTS = [
  PipelineColumnType.Naics2d,
  PipelineColumnType.Country,
  PipelineColumnType.Salary,
  PipelineColumnType.Duration,
  PipelineColumnType.Prestige,
];

export const DEMOGRAPHIC_BREAKDOWN_DEFAULTS = [
  PipelineColumnType.Company,
  PipelineColumnType.Region,
  PipelineColumnType.JobCategory,
  PipelineColumnType.Seniority,
  PipelineColumnType.Count,
  PipelineColumnType.Gender,
  PipelineColumnType.Ethnicity,
  PipelineColumnType.Inflow,
  PipelineColumnType.Outflow,
  PipelineColumnType.Salary,
  PipelineColumnType.Duration,
  PipelineColumnType.Prestige,
];

export const GEO_BREAKDOWN_DEFAULTS = [
  PipelineColumnType.Company,
  PipelineColumnType.Region,
  PipelineColumnType.Country,
  PipelineColumnType.State,
  PipelineColumnType.MetroArea,
  PipelineColumnType.Msa,
  PipelineColumnType.JobCategory,
  PipelineColumnType.Seniority,
  PipelineColumnType.Count,
  PipelineColumnType.Inflow,
  PipelineColumnType.Outflow,
  PipelineColumnType.Salary,
  PipelineColumnType.Duration,
  PipelineColumnType.Prestige,
];

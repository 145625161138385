import React, { useCallback } from 'react';
import {
  Box,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { DocumentNode, print } from 'graphql';
import { getHealthCheckId } from '@revelio/data-access';
import { HealthCheckDetailsProps } from '../types';
import { ServiceTimeline } from './ServiceTimeline';
import { LineChart, FormatType } from '@revelio/replots';
import {
  QueryPlayground,
  CodeDisplay,
  HealthCheckHeader,
} from './health-check-details';

export const HealthCheckDetails: React.FC<HealthCheckDetailsProps> = ({
  check,
  latestResult,
  serviceResults,
  onRunSingleCheck,
  isFetching,
}) => {
  const [showSchema, setShowSchema] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [currentVariables, setCurrentVariables] = React.useState<
    Record<string, unknown>
  >(check.variables);
  const {
    isOpen: isChartOpen,
    onOpen: onChartOpen,
    onClose: onChartClose,
  } = useDisclosure();

  const handleVariablesChange = useCallback(
    (variables: Record<string, unknown>) => {
      setCurrentVariables(variables);
    },
    []
  );

  const handleRunCheck = () => {
    setShowSchema(true);
    onRunSingleCheck(
      getHealthCheckId(check.service, check.viewGroup, check.view),
      currentVariables
    );
    setActiveTab(0);
  };

  // Get the query document as a string
  const queryString = print(check.query as DocumentNode);

  return (
    <Box
      key={getHealthCheckId(check.service, check.viewGroup, check.view)}
      p={4}
      borderWidth="1px"
      borderRadius="md"
      bg="white"
      _hover={{ borderColor: 'blue.200' }}
      transition="all 0.2s"
    >
      <VStack align="stretch" spacing={4}>
        <HealthCheckHeader
          service={check.service}
          viewGroup={check.viewGroup}
          view={check.view}
          status={latestResult?.status || 'unknown'}
          showSchema={showSchema}
          onToggleSchema={() => setShowSchema(!showSchema)}
          onChartOpen={onChartOpen}
          onRunCheck={handleRunCheck}
          isFetching={isFetching}
        />

        {latestResult?.error && (
          <Text color="red.500">Error: {latestResult.error.message}</Text>
        )}

        <Collapse in={showSchema} animateOpacity>
          <VStack align="stretch" spacing={4}>
            <Tabs
              variant="enclosed"
              size="sm"
              index={activeTab}
              onChange={setActiveTab}
            >
              <TabList>
                <Tab>Playground</Tab>
                <Tab>Query</Tab>
                <Tab>Schema</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <QueryPlayground
                    check={check}
                    latestResult={latestResult}
                    onRunCheck={handleVariablesChange}
                  />
                </TabPanel>

                <TabPanel>
                  {activeTab === 1 && <CodeDisplay code={queryString} />}
                </TabPanel>

                <TabPanel>
                  {activeTab === 2 && (
                    <CodeDisplay code={JSON.stringify(check.query, null, 2)} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Collapse>

        <Box>
          <ServiceTimeline serviceResults={serviceResults} check={check} />
        </Box>

        <Modal isOpen={isChartOpen} onClose={onChartClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Response Time History</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box height="400px">
                <LineChart
                  data={[
                    {
                      label: 'Response Time',
                      values: serviceResults
                        .filter((result) => result.status === 'success')
                        .map((result) => ({
                          date: result.timestamp,
                          value: result.responseTime,
                        }))
                        .sort(
                          (a, b) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                        ),
                      color: '#5BD992',
                    },
                  ]}
                  format={FormatType.INTEGER}
                  dateFormat="daytime"
                  showGridLines={true}
                />
              </Box>
              <Text fontSize="sm" color="gray.500" mt={4}>
                Shows response times for successful health checks only. Lower is
                better.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>

        {showSchema && (
          <>
            <Text fontSize="sm" color="gray.500">
              Last Response Time:{' '}
              {latestResult
                ? `${latestResult.responseTime.toFixed(0)}ms${
                    latestResult.responseTime < 100
                      ? ' (potentially cached)'
                      : ''
                  }`
                : 'N/A'}
            </Text>
            {serviceResults.length > 0 && (
              <Text fontSize="sm" color="gray.500">
                Avg Successful Response Time:{' '}
                {(() => {
                  const successfulChecks = serviceResults.filter(
                    (r) =>
                      r.status === 'success' &&
                      !r.isManualCheck &&
                      r.service === check.service &&
                      r.viewGroup === check.viewGroup &&
                      r.view === check.view
                  );
                  if (successfulChecks.length === 0) return 'N/A';
                  const avgTime =
                    successfulChecks.reduce(
                      (sum, r) => sum + r.responseTime,
                      0
                    ) / successfulChecks.length;
                  return `${avgTime.toFixed(0)}ms`;
                })()}
              </Text>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
};

import { D3ChartGeneratorLookup } from '../../index';
import {
  ChartConfigForPlotDownload,
  D3ChartNames,
  ID3ChartProps,
} from '../../model';

/**
 * takes an svg, serializes it and returns the string
 *
 * @param svg - svg node to serialize
 *
 * @returns serialized svg string
 */
const serializeSVG = (svg: SVGElement) => {
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svg);

  return svgString;
};

/**
 * generates a d3 plot with download configs set
 *
 * @param plotConfigs - configs for rendering the plot
 * @param downloadConfigs - configs for downloading the plot
 * @param chartType - type of chart
 * @param containerId - parent container where plot will be contained in
 *
 * @returns svg node of the rendered plot if successful. undefined otherwise.
 */

const generatePlotForDownload = (
  plotConfigs: ID3ChartProps,
  downloadConfigs: Partial<ChartConfigForPlotDownload>,
  chartType: string,
  containerId: string
) => {
  const key = chartType ? (chartType as D3ChartNames) : D3ChartNames.D3NoMatch;

  const selectedGenerator = D3ChartGeneratorLookup[key];

  if (!selectedGenerator) {
    return undefined;
  }

  const svg = selectedGenerator(
    { ...plotConfigs, chartSize: 'large' },
    {
      ...downloadConfigs,
      containerId: `#${containerId}`,
    }
  );

  return svg;
};

/**
 * sets up a download link, clicks it and then removes it
 *
 * @param filename - name of downloaded file
 * @param dataURL - href of download link
 *
 */
const handlePNGDownload = (filename: string, dataURL: string) => {
  const download = document.createElement('a');
  download.download = filename;
  download.href = dataURL;
  download.click();
  download.remove();
};

/**
 * removes all children from parent container
 * @param containerId - id of the parent container
 */
const removeSVGFromDOM = (containerId: string) => {
  const downloadContainer = document.getElementById(containerId);

  while (downloadContainer?.hasChildNodes()) {
    if (downloadContainer.lastChild) {
      downloadContainer.removeChild(downloadContainer.lastChild);
    }
  }
};

/**
 * downloads a d3 plot as png
 *
 * @param chartType - the type of plot to download
 * @param plotConfigs - configs for generating the plot
 * @param downloadConfigs - configs for downloading the plot
 *
 * @returns void
 */
export const pngDownloader = (
  chartType: string,
  plotConfigs: ID3ChartProps,
  downloadConfigs: Partial<ChartConfigForPlotDownload>
) => {
  const containerId = 'download-svg-container';
  const xmlns = 'http://www.w3.org/2000/svg';

  return new Promise((resolve, reject) => {
    const svg = generatePlotForDownload(
      plotConfigs,
      downloadConfigs,
      chartType,
      containerId
    );

    if (!svg) {
      reject('Error creating SVG!');
      return;
    }

    const { height, width } = svg.getBoundingClientRect();

    svg.setAttribute('xmlns', xmlns);

    const svgString = serializeSVG(svg);

    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    // const blobURL = URL.createObjectURL(blob);

    const reader = new FileReader();

    // to avoid tainting canvas for chrome
    reader.onload = (e) => {
      const base64 = e?.target?.result;

      if (image && base64) {
        image.src = base64 as string;
      }
    };

    reader.onerror = (e) => {
      reject('Error converting SVG!');
      return;
    };

    reader.readAsDataURL(blob);

    const image = new Image();

    image.onload = () => {
      // set up canvas
      const canvas = document.createElement('canvas');

      // TODO: might decide later to apply scaling on all devices for
      // better image res, but commenting this out for now.
      // const baseScaleFactor = 1.25;

      const pixelRatio = window.devicePixelRatio;

      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';

      canvas.width = Math.floor(width * pixelRatio);
      canvas.height = Math.floor(height * pixelRatio);

      // svg to canvas
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingEnabled = false;
        ctx.fillStyle = downloadConfigs.transparent
          ? 'rgba(255, 255, 255, 0)'
          : 'white';
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(image, 0, 0, width, height);

        const png = canvas.toDataURL();

        handlePNGDownload(downloadConfigs.filename || 'default', png);
      }

      // download complete, remove svg
      removeSVGFromDOM(containerId);

      // all done
      resolve('complete');
    };

    image.onerror = () => {
      reject('Error loading image!');
      return;
    };
  });
};

import { Flex } from '@chakra-ui/layout';
import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import { useSelectionListsValidated } from '../../engine/filters.engine';
import { SelectionCategories } from '../../engine/filters.model';
import { PopoutTree } from '../PopoutTree';
import { getNestedId, nestSelectionLists } from '../tree';
import styles from './NewTreeFilter.module.css';
import { useScreenerFilter } from './screener-filter-provider';
import { SelectFooter } from './select-footer';
import { convertSelectionToTreeItems } from './utils';

type NewTreeFilterProps = {
  selectionListIds: SelectionCategories[];
  onClose: () => void;
};

export const NewTreeFilter = ({
  selectionListIds,
  onClose,
}: NewTreeFilterProps) => {
  const { state, dispatch } = useScreenerFilter();

  const primaryFilter = state.filters.primary_filter;

  const selectionLists = useSelectionListsValidated(selectionListIds);

  const nestedSelectionTree = useMemo(
    () => nestSelectionLists(selectionLists),
    [selectionLists]
  );

  const [selections, setSelections] = useState<string[]>([]);
  const onChange = (value: string[]) => {
    setSelections(value);
  };

  useEffect(() => {
    if (primaryFilter) {
      const items = Object.values(primaryFilter);

      const itemsWithSelectionListId = items.map((value) => ({
        ...value.item,
        id: `${value?.item?.id}`,
        selectionListId: value.selectionListId,
      }));

      const selectedIds = itemsWithSelectionListId.map((item) =>
        getNestedId({ selectionLists, item })
      );

      setSelections(selectedIds);
    }
  }, [primaryFilter, selectionLists]);

  const handleAddFilter = () => {
    const primaryFilterSelection = convertSelectionToTreeItems({
      selections,
      selectionLists,
    });
    dispatch({ type: 'SET_PRIMARY_FILTER', filter: primaryFilterSelection });
    onClose();
  };

  const handleClearSelections = () => {
    setSelections([]);
  };

  return (
    <Flex direction="column" gap="4">
      <PopoverBody padding={0}>
        <PopoutTree
          data={nestedSelectionTree}
          className={styles.popoutTree}
          value={selections}
          onChange={onChange}
        />
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter
          onClearSelections={handleClearSelections}
          onClose={onClose}
          onAdd={handleAddFilter}
          addLabel={!Object.keys(primaryFilter || {}).length ? 'Add' : 'Update'}
        />
      </PopoverFooter>
    </Flex>
  );
};

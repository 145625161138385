import { useMemo } from 'react';

import { PrimaryView } from '@revelio/core';
import {
  Filter,
  FilterList,
  FilterName,
  LocalSelectionCategories,
  OtherFilterNames,
  RangeFullFilter,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

type UseIsQueryReadyProps = {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
};
export const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: UseIsQueryReadyProps) => {
  /** check that activeFilters has primaryFilter */
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => activeFilters.some((filter) => primaryFilters.includes(filter.id)),
    [activeFilters, primaryFilters]
  );

  const filtersHasCustomRoleTaxonomy = useMemo(
    () =>
      !!activeFilters.find(
        (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
      ),
    [activeFilters]
  );
  const { isEnabled: isRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  /** check that filter has full date range set */
  const filtersHasDateSet = useMemo(
    () =>
      activeFilters.some((filter) => {
        /** TODO: Typecasting this as Filters aren't typed by id */
        const dateRangeFullFilter = filter as unknown as RangeFullFilter;
        return (
          dateRangeFullFilter.id === SelectionCategories.DATE_RANGE_FULL &&
          dateRangeFullFilter.value.startDate &&
          dateRangeFullFilter.value.endDate
        );
      }),
    [activeFilters]
  );
  // api returns 500 without this
  const filtersHasPostingSource = useMemo(
    () =>
      activeFilters.some(
        (filter) =>
          filter.id === LocalSelectionCategories.PROVIDER &&
          (filter?.value as FilterList)?.length
      ),
    [activeFilters]
  );

  return (
    filtersHasAtLeastOnePrimaryFilter &&
    filtersHasDateSet &&
    filtersHasPostingSource &&
    (!isRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};

// This is a quick solution for getting the mapper working with snowflake
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchSnowflakeColumns } from '../company-selection/company-mapping/upload-companies/snowflake-to-company-mapping';
import { SnowflakeDbInputs, SnowflakeDbValues } from '../snowflake-db-inputs';
import {
  SnowflakeDataInterface,
  SnowflakeMapperContext,
} from './snowflake-mapper-context';

export const SnowflakeInputsWithContext = () => {
  const { setData, goToNext } = useContext(SnowflakeMapperContext);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<SnowflakeDbValues>();
  const [apiError, setApiError] = useState<string | null>(null);
  const submitSnowflakeCompanyMapping = handleSubmit(
    async (formData: SnowflakeDbValues) => {
      try {
        setApiError(null);
        const data = await fetchSnowflakeColumns({ ...formData });
        setData((prevData: SnowflakeDataInterface) => ({
          ...prevData,
          headers: data.columns,
          combinedData: data.columns.map((col: string) => ({
            header: col,
            sampleData: Object.values(data.data[col]) || [],
          })),
          formData: formData,
        }));
        goToNext();
      } catch (error) {
        setApiError(
          "Something didn't go as planned. Please check for typos and try again."
        );
      }
    }
  );

  return (
    <Box>
      <form onSubmit={submitSnowflakeCompanyMapping}>
        <Heading color="text.primary" fontWeight="600" size="sm" mb="4">
          <Flex alignItems="center" gap={1}>
            Snowflake table
            <Tooltip
              label='Supported columns: "COMPANY_ID", "COMPANY_NAME", "TICKER", "WEBSITE", "YEAR_FOUNDED", "COUNTRY", "STATE", "CITY", "LINKEDIN_URL", "FACTSET_ENTITY_ID", "LEI", "CIK", "SIK", "NAICS", "ISIN"'
              textAlign="center"
              hasArrow
              placement="top"
            >
              <InfoOutlineIcon
                boxSize={3}
                color="text.primary"
                cursor="pointer"
              />
            </Tooltip>
          </Flex>
        </Heading>
        <Flex alignItems="flex-end">
          <SnowflakeDbInputs register={register} errors={errors} />

          <Button
            colorScheme="green"
            variant="solid"
            size="md"
            isLoading={isSubmitting}
            type="submit"
            w="130px"
          >
            Submit
          </Button>
        </Flex>

        {apiError && (
          <Alert status="error" mt="2">
            <AlertIcon />
            {apiError}
          </Alert>
        )}
      </form>
    </Box>
  );
};

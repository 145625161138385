import {
  Alert,
  Box,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SystemStyleObject,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  GroupBase,
  MultiValue,
  Select,
  SelectInstance,
} from 'chakra-react-select';
import { useCallback, useMemo, useRef, useState } from 'react';

import { ActionModalControlPanel } from '@revelio/core';
import { TalentDiscoveryUser } from '@revelio/data-access';

import sourceWhaleCampaignsIcon from '../../../../images/icons/sourcewhale-campaigns-icon.svg';
import sourceWhaleContactsIcon from '../../../../images/icons/sourcewhale-contacts-icon.svg';
import sourceWhaleIcon from '../../../../images/icons/sourcewhale-icon.png';
import {
  CannotFetchCampaignsError,
  useSourceWhaleCampaigns,
} from '../hooks/use-source-whale-campaigns';
import { useSourceWhaleCandidates } from '../hooks/use-source-whale-candidates';
import {
  CannotFetchProjectsError,
  useSourceWhaleProjects,
} from '../hooks/use-source-whale-projects';
import { mapTDUsersToSWCandidates } from '../source-whale.model';
import { SelectableCard } from './selectable-card';

const MAX_CAMPAIGN_CANDIDATES = 50;

enum ExportMode {
  Contacts = 'contacts',
  Campaign = 'campaign',
}

type Option = {
  label: string;
  value: string;
};

interface SourceWhaleExportModalProps {
  apiKey: string;
  isOpen: boolean;
  selectedUsers: TalentDiscoveryUser[];
  onClose: () => void;
  onEditApiKey: () => void;
}

export const modalStyles = {
  header: {
    position: 'relative',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '23px',
    color: 'text.primary',
  },
  label: {
    fontWeight: '600',
    lineHeight: '15px',
    fontSize: '12px',
    color: 'text.primary',
  },
  select: {
    control: (provided: SystemStyleObject) => ({
      ...provided,
      fontWeight: '400',
      lineHeight: '15px',
      fontSize: '12px',
      mb: 2,
    }),
    option: (provided: SystemStyleObject) => ({
      ...provided,
      fontWeight: '400',
      lineHeight: '15px',
      fontSize: '12px',
    }),
    multiValue: (provided: SystemStyleObject) => ({
      ...provided,
      boxShadow: 'inset 0 0 0px 1px #CBD5E0',
    }),
  },
  alert: {
    backgroundColor: '#FDF0F3',
    fontSize: '10px',
    lineHeight: '11px',
    fontWeight: '400',
    color: '#E76A87',
    borderRadius: 'sm',
    mb: 2,
  },
  alertLink: {
    color: 'inherit',
    fontWeight: '600',
    cursor: 'pointer',
    _hover: {
      textDecoration: 'underline',
    },
  },
} as const;

export const SourceWhaleExportModal = ({
  apiKey,
  isOpen,
  onClose,
  selectedUsers,
  onEditApiKey,
}: SourceWhaleExportModalProps) => {
  const isCampaignDisabled = selectedUsers.length > MAX_CAMPAIGN_CANDIDATES;

  const [exportMode, setExportMode] = useState<ExportMode | null>(
    isCampaignDisabled ? ExportMode.Contacts : null
  );
  const [campaign, setCampaign] = useState<Option | null>(null);
  const [error, setError] = useState<string>('');

  const { data, isLoading, isError } = useSourceWhaleCampaigns({
    apiKey,
    onError: (error) => setError(error),
    enabled: isOpen,
  });

  const { data: projectsData, isLoading: isLoadingProjects } =
    useSourceWhaleProjects({
      apiKey,
      onError: (error) => setError(error),
      enabled: isOpen,
    });

  const { mutate: addCandidates, isPending: isAddingCandidates } =
    useSourceWhaleCandidates({
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        setError(error);
      },
    });

  const campaignOptions = useMemo(() => {
    if (isError || !data) return [];

    return (
      data?.campaigns.campaigns.map((campaign) => ({
        label: campaign.campaignName,
        value: campaign.campaignId,
      })) ?? []
    );
  }, [data, isError]);

  const selectRef = useRef<SelectInstance<
    Option,
    boolean,
    GroupBase<Option>
  > | null>(null);
  const [isSyncWithCrmSelected, setIsSyncWithCrmSelected] = useState(false);

  const [projects, setProjects] = useState<MultiValue<Option> | null>(null);
  const projectOptions =
    projectsData?.projects?.map((project) => ({
      label: project.projectName,
      value: project.projectId,
    })) ?? [];

  const projectIds = (() => {
    if (!projects || projects.length === 0) return undefined;
    return projects.map((project) => project.value);
  })();

  const isSubmitDisabled =
    !exportMode || (exportMode === ExportMode.Campaign && !campaign);

  const handleSubmit = useCallback(() => {
    if (!apiKey) return;

    addCandidates({
      apiKey,
      candidates: mapTDUsersToSWCandidates({ users: selectedUsers }),
      campaignId:
        exportMode === ExportMode.Campaign ? campaign?.value : undefined,
      projectIds:
        exportMode === ExportMode.Contacts && isSyncWithCrmSelected
          ? projectIds
          : undefined,
    });
  }, [
    apiKey,
    addCandidates,
    selectedUsers,
    exportMode,
    campaign,
    projectIds,
    isSyncWithCrmSelected,
  ]);

  const handleReset = useCallback(() => {
    setError('');
    setExportMode(isCampaignDisabled ? ExportMode.Contacts : null);
    setCampaign(null);
  }, [isCampaignDisabled]);

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  return (
    <Modal
      id="source-whale-export-modal"
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="sm"
    >
      <ModalOverlay />

      <ModalContent userSelect="none" w="291px">
        <ModalHeader sx={modalStyles.header}>
          <Flex gap="2" alignItems="center">
            <Image src={sourceWhaleIcon} boxSize={4} />
            SourceWhale Export
          </Flex>
          <ModalCloseButton size="sm" top="50%" transform="translateY(-50%)" />
        </ModalHeader>

        <ModalBody py={0}>
          {error && (
            <ExportErrorAlert
              error={error}
              onEditApiKey={onEditApiKey}
              onClose={handleClose}
            />
          )}

          <Text my={2} sx={modalStyles.label}>
            Where would you like to export to?
          </Text>

          <VStack w="full" spacing={2} mb={2}>
            <SelectableCard
              icon={<Image src={sourceWhaleContactsIcon} />}
              label="Add to contacts"
              isSelected={exportMode === ExportMode.Contacts}
              onClick={() => setExportMode(ExportMode.Contacts)}
            />
            {exportMode === ExportMode.Contacts && (
              <Box width="100%" mt={1}>
                <Checkbox
                  colorScheme="green"
                  isChecked={isSyncWithCrmSelected}
                  onChange={() => {
                    selectRef.current?.focus();
                    selectRef.current?.openMenu('first');
                    setIsSyncWithCrmSelected(!isSyncWithCrmSelected);
                  }}
                  autoFocus={false}
                >
                  <Text sx={modalStyles.label}>Sync with CRM & ATS?</Text>
                </Checkbox>

                <Select
                  ref={selectRef}
                  isDisabled={!isSyncWithCrmSelected}
                  options={projectOptions}
                  isMulti={true}
                  hideSelectedOptions={false}
                  value={projects}
                  onChange={(option) =>
                    setProjects(option as MultiValue<Option>)
                  }
                  placeholder="Select CRM & ATS"
                  isLoading={isLoadingProjects}
                  size="sm"
                  chakraStyles={modalStyles.select}
                  tagVariant="outline"
                  isClearable={false}
                  closeMenuOnSelect={false}
                />
              </Box>
            )}
            <SelectableCard
              icon={<Image src={sourceWhaleCampaignsIcon} />}
              label="Add to a campaign"
              tooltip="Select up to 50 candidates to add to your campaign"
              isSelected={exportMode === ExportMode.Campaign}
              isDisabled={isCampaignDisabled}
              onClick={() =>
                !isCampaignDisabled && setExportMode(ExportMode.Campaign)
              }
            />
          </VStack>

          {exportMode === ExportMode.Campaign && (
            <>
              <Text mt={4} sx={modalStyles.label}>
                Choose a campaign
              </Text>

              <Select<Option>
                options={campaignOptions}
                value={campaign}
                onChange={(option) => setCampaign(option)}
                placeholder="Select a campaign"
                isLoading={isLoading}
                size="sm"
                chakraStyles={modalStyles.select}
              />
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ActionModalControlPanel
            submitText="Export"
            onClose={handleClose}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            onReset={handleReset}
            includeReset={false}
            submitIsDisabled={isSubmitDisabled}
            submitIsLoading={isAddingCandidates}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ExportErrorAlert = ({
  error,
  onEditApiKey,
  onClose,
}: {
  error: string;
  onEditApiKey: () => void;
  onClose: () => void;
}) => {
  const renderErrorRecoveryAction = () => {
    // Failing to fetch campaigns or projects often indicate an invalid API key.
    // Provide a direct way for users to update their key.
    if (
      error === CannotFetchCampaignsError ||
      error === CannotFetchProjectsError
    ) {
      return (
        <>
          {' '}
          Please{' '}
          <Text
            as="span"
            sx={modalStyles.alertLink}
            onClick={() => {
              onClose();
              onEditApiKey();
            }}
          >
            check your API key
          </Text>{' '}
          and try again.
        </>
      );
    }
    return null;
  };

  return (
    <Alert status="error" sx={modalStyles.alert}>
      <span>
        {error}
        {renderErrorRecoveryAction()}
      </span>
    </Alert>
  );
};

import { UseStepsReturn, useSteps } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { createContext, useState } from 'react';

import { BaseStepsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import { DataInterface } from './file-upload.types';

export type FileUploadContextType = DataInterface &
  UseStepsReturn & {
    steps: BaseStepsInterface[];
    setData: React.Dispatch<React.SetStateAction<DataInterface>>;
  };

export const FileUploadContext = createContext<FileUploadContextType>({
  steps: [],

  // mapping state
  workbook: { Sheets: {}, SheetNames: [] },
  sheetNames: [],
  mappedWorkbook: [[]],
  selectedSheet: '',
  headers: [],
  headerIndex: '',
  combinedData: [{}],
  fileType: 'csv',
  mappedColumns: [],
  setData: noop,

  // UseStepsReturn defaults that shouldn't ever be used
  activeStep: 0,
  setActiveStep: noop,
  activeStepPercent: 0,
  // made as any because these are instantly overwritten by `useSteps`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isActiveStep: noop as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCompleteStep: noop as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isIncompleteStep: noop as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStatus: noop as any,
  goToNext: noop,
  goToPrevious: noop,
});

export const FileUploadProvider = ({
  children,
  baseSteps,
  sheetSelectionStep,
}: {
  children: React.ReactElement;
  baseSteps: BaseStepsInterface[];
  sheetSelectionStep: BaseStepsInterface['content'];
}) => {
  const [fileData, setFileData] = useState<DataInterface>({
    workbook: { Sheets: {}, SheetNames: [] },
    sheetNames: [],
    mappedWorkbook: [[]],
    selectedSheet: '',
    headers: [],
    headerIndex: '',
    combinedData: [{}],
    fileType: 'csv',
    mappedColumns: [],
    oryId: '',
    clientName: '',
    email: '',
    s3FileLocation: '',
  });

  const steps = [...baseSteps];
  if (hasMultipleSheets({ sheetNames: fileData.sheetNames })) {
    const sheetsStep: BaseStepsInterface = {
      title: 'Select sheet',
      description: '',
      content: sheetSelectionStep,
    };
    steps.splice(1, 0, sheetsStep);
  }
  const stepperState = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <FileUploadContext.Provider
      value={{ ...fileData, ...stepperState, setData: setFileData, steps }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export const hasMultipleSheets = ({
  sheetNames = [],
}: {
  sheetNames: DataInterface['sheetNames'];
}) => {
  return sheetNames.length > 1;
};

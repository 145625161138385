import { useQuery } from 'urql';

import { PrimaryView } from '@revelio/core';
import { StackedBarChartHorizontalData } from '@revelio/d3';
import { TransitionDimension } from '@revelio/data-access';
import {
  FilterName,
  TRANSITIONS_DATA,
  useActiveFiltersState,
} from '@revelio/filtering';

import { transformFiltersToVariables } from '../../../utils';
import { transformSankey } from './transform-sankey';
import { transformSankeyData } from './transform-sankey-data';
import { transformStackedBar } from './transform-stacked-bar';
import { transformStackedBarData } from './transform-stacked-bar-data';
import { useIsTransitionsQueryReady } from './use-is-transitions-query-ready';

interface UseTransitionsDataFetchProps {
  view: PrimaryView;
  primaryFilters: FilterName[];
  inflow_or_outflow: 'inflow' | 'outflow';
}
export const useTransitionsDataFetch = ({
  view,
  primaryFilters,
  inflow_or_outflow,
}: UseTransitionsDataFetchProps) => {
  const activeFilters = useActiveFiltersState();

  const isQueryReady = useIsTransitionsQueryReady({
    activeFilters,
    primaryFilters,
    view,
  });

  const queryFilters = transformFiltersToVariables({
    view,
    filters: [...activeFilters],
    isCustomRoleTaxonomyEnabled: true,
  });

  const [{ data, fetching, error }] = useQuery({
    query: TRANSITIONS_DATA,
    variables: { ...queryFilters, dim2: TransitionDimension.All },
    pause: !isQueryReady,
  });
  const transformedSankeyData = transformSankeyData(data, inflow_or_outflow);

  const isInflow = inflow_or_outflow === 'inflow';

  const [inflowData, outflowData] = data?.transitions2D ?? [];

  const transition2DData = isInflow ? inflowData : outflowData;
  const stackedData = transformStackedBar(transition2DData, inflow_or_outflow);
  const sankeyData = transformSankey(transition2DData, inflow_or_outflow);

  const barPlotMetricsToTransform = Object.keys(
    data?.transitions2D?.[isInflow ? 0 : 1]?.metrics ?? {}
  ).filter((metric) => metric !== 'count');

  const transformStackedBarChartData = barPlotMetricsToTransform.reduce(
    (acc, metric) => {
      acc[metric] = transformStackedBarData(data, inflow_or_outflow, metric);
      return acc;
    },
    {} as Record<string, StackedBarChartHorizontalData[]>
  );

  return {
    data: {
      sankeyData: transformedSankeyData,
      barChartData: transformStackedBarChartData,
    },
    stackedData,
    sankeyData,
    fetching,
    error,
    isQueryReady,
  };
};

import { extent } from 'd3';
import { clamp } from 'lodash';

import { NodeConstants, ScaleConstants } from './org-chart.constants';
import { BoundingBox, CollapsibleNode } from './org-chart.types';

export const getVisibleNodes = (
  root: CollapsibleNode,
  level?: number
): CollapsibleNode[] => {
  return level === undefined
    ? root.descendants()
    : root.descendants().filter((d) => d.depth < level);
};

export const getBoundingBox = (nodes: CollapsibleNode[]): BoundingBox => {
  const [minX, maxX] = extent(nodes, (d) => d.x) as [number, number];
  const [minY, maxY] = extent(nodes, (d) => d.y) as [number, number];

  return { minX, maxX, minY, maxY };
};

export const scaleToFit = (
  chartBounds: BoundingBox,
  containerWidth: number,
  containerHeight: number
): number => {
  const { minX, maxX, minY, maxY } = chartBounds;
  const chartWidth = maxX - minX + NodeConstants.width * 1.5;
  const chartHeight = maxY - minY + NodeConstants.height * 1.5;

  const scaleX = containerWidth / chartWidth;
  const scaleY = containerHeight / chartHeight;

  const scale = Math.min(scaleX, scaleY, 1);
  return clamp(scale, ScaleConstants.min, ScaleConstants.max);
};

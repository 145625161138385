import { useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, createContext, useContext, useState } from 'react';

import { UserSavedFilterSet } from '../../../../engine/filters.savedset.models';

type SavedSetsSelectOptionsContextType = {
  selectedOption: UserSavedFilterSet | undefined;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<UserSavedFilterSet | undefined>
  >;
  isEditModalOpen: boolean;
  onEditModalClose: () => void;
  onEditModalOpen: () => void;
  clearSelectedOption: () => void;
};

const SavedSetsSelectOptionsContext = createContext<
  SavedSetsSelectOptionsContextType | undefined
>(undefined);

export const useSavedSetsSelectOptions = () => {
  const context = useContext(SavedSetsSelectOptionsContext);
  if (!context) {
    throw new Error(
      'useOptions must be used within an SavedSetsSelectOptionsProvider'
    );
  }
  return context;
};

type OptionsProviderProps = {
  children: ReactNode;
};

export const SavedSetsSelectOptionsProvider: React.FC<OptionsProviderProps> = ({
  children,
}) => {
  const [selectedOption, setSelectedOption] = useState<
    UserSavedFilterSet | undefined
  >();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const clearSelectedOption = () => {
    setSelectedOption(undefined);
  };

  return (
    <SavedSetsSelectOptionsContext.Provider
      value={{
        selectedOption,
        setSelectedOption,
        clearSelectedOption,
        isEditModalOpen: isOpen,
        onEditModalClose: onClose,
        onEditModalOpen: onOpen,
      }}
    >
      {children}
    </SavedSetsSelectOptionsContext.Provider>
  );
};

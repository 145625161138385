import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import {
  ChangeEventHandler,
  Ref,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { TreeApi, TreeApiProps, TreeSelection, useTreeApi } from '../tree';
import { TNode } from '../tree/Node';
import { getChildren, getPathToNode } from '../tree/utils';
import { Breadcrumb } from './Breadcrumb';

export type BreadcrumbTreeProps = TreeApiProps & {
  branches: string[];
  height?: number;
};

const calculateHeight = (
  height?: number,
  isSearchEmpty?: boolean
): number | undefined => {
  if (!height) {
    return undefined;
  }

  if (isSearchEmpty) {
    return height;
  }

  const collapseBtnHeight = 15.5;
  return height - collapseBtnHeight;
};

export const BreadcrumbTree = forwardRef(
  (props: BreadcrumbTreeProps, ref: Ref<TreeApi | undefined>) => {
    const [drillLevel, setDrillLevel] = useState<{
      level: number;
      drillHistory: string[];
    }>({ level: 0, drillHistory: [] });
    const { data, branches } = props;

    const treeApi = useTreeApi({ ...props, openByDefault: false });

    useImperativeHandle(ref, () => treeApi);

    const { search, onSearch, treeRef, isExpanded, collapse, expand } = treeApi;

    const isSearchEmpty = search.length === 0;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      onSearch(event.target.value);
    };
    const onResetSearch = () => {
      onSearch('');
    };

    const drillIntoNode = ({ node, level }: { node: TNode; level: number }) => {
      treeRef.current?.open(node.id);
      setDrillLevel((prev) => ({
        level: level + 1,
        drillHistory: [...prev.drillHistory, node.id],
      }));
    };

    const drillOutToLevel = (level: number) => {
      const idsToClose = drillLevel.drillHistory.slice(level).reverse();
      idsToClose.forEach((id) => treeRef.current?.close(id));
      setDrillLevel((prev) => ({
        level,
        drillHistory: prev.drillHistory.slice(0, level),
      }));
    };

    const children =
      drillLevel.level === 0
        ? data
        : getChildren({
            data,
            idPath: getPathToNode(
              drillLevel.drillHistory[drillLevel.drillHistory.length - 1]
            ),
          });

    return (
      <Box backgroundColor="white">
        <InputGroup size="sm" {...(isSearchEmpty && { marginBottom: '8px' })}>
          <Input
            value={search}
            onChange={handleChange}
            placeholder="search"
            data-testid="tree-search-input"
          />
          <InputRightElement
            children={
              isSearchEmpty ? (
                <SearchIcon w={3.5} h={3.5} color="silver.600" />
              ) : (
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  w={2.5}
                  h={2.5}
                  color="silver.600"
                  onClick={onResetSearch}
                />
              )
            }
          />
        </InputGroup>
        {!isSearchEmpty && (
          <Button
            variant="link"
            size="xs"
            colorScheme="gray"
            onClick={isExpanded ? collapse : expand}
          >
            {isExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        {isSearchEmpty && (
          <Breadcrumb
            crumbs={branches}
            currentLevel={drillLevel.level}
            forceAllCrumbs={!isSearchEmpty}
            setCurrentLevel={drillOutToLevel}
          />
        )}
        <TreeSelection
          {...(drillLevel.level > 0 &&
            isSearchEmpty && { style: { display: 'none' } })}
          data={data}
          treeApi={treeApi}
          openByDefault={false}
          drillMode={{
            isEnabled: isSearchEmpty,
            level: 0,
            isRoot: true,
            drillIntoNode,
          }}
          height={calculateHeight(props.height, isSearchEmpty)}
        />
        <TreeSelection
          {...((drillLevel.level === 0 || !isSearchEmpty) && {
            style: { display: 'none' },
          })}
          data={children}
          treeApi={treeApi}
          openByDefault={false}
          drillMode={{
            isEnabled: isSearchEmpty,
            level: drillLevel.level,
            drillIntoNode,
          }}
          height={calculateHeight(props.height, isSearchEmpty)}
        />
      </Box>
    );
  }
);

import { PageTitles, Views } from '@revelio/core';
import { ScreenerDimension, View } from '@revelio/data-access';
import {
  ScreenerFilterProvider,
  SelectionCategories,
  useTabMeta,
} from '@revelio/filtering';

import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';

const title = [PageTitles.ROLE, PageTitles.SCREENER];

export const RoleScreener = () => {
  const filterOptions = generateFilterOptions(
    SelectionCategories.JOB_CATEGORY,
    'Role'
  );

  useTabMeta({
    savedSetView: View.RoleScreener,
    view: Views.SCREENER,
  });

  return (
    <ScreenerFilterProvider>
      <Screener
        title={title}
        filterOptions={filterOptions}
        view={ScreenerDimension.Role}
        sortingDisabledColumns={['Role']}
        savedSetsView={View.RoleScreener}
      />
    </ScreenerFilterProvider>
  );
};

export default RoleScreener;

import { deviation, max, mean } from 'd3-array';
import { isNil, isNumber, mapValues, orderBy, pick, round } from 'lodash';

import {
  BarChartHorizontalData,
  LineData,
  LineValue,
  StackedBarChartHorizontalData,
  StackedBarChartHorizontalValue,
} from '@revelio/d3';
import { CompositionDataQuery, CompositionMetrics } from '@revelio/data-access';
import type {
  BarData,
  LineData as ReplotsLineData,
  StackedBarData,
} from '@revelio/replots';

import { ExportData } from '../../../shared/components/plot-card/plot-card-action-menu';
import { TimeFrameView } from '@revelio/core';
import { adjustLineDataToUserCurrency } from '../../compensation/data-fetch/get-overtime-data';

export type CompositionMetricName = keyof NonNullable<
  NonNullable<
    NonNullable<CompositionDataQuery['composition']>[number]
  >['metrics']
>;

// Created this Includes generic type to make looking up names on a string union type easy
// We probably should export this from one of our libraries
type Includes<T, U extends T> = U;

export type TopPlotName = Includes<
  CompositionMetricName,
  | 'headcount'
  | 'growth_rate'
  | 'hiring_rate'
  | 'attrition_rate'
  | 'tenure'
  | 'salary'
>;

export type BottomPlotName =
  | 'job_categories'
  | 'geographies'
  // | 'seniorities'
  /** TODO: Skill Plot Enable */
  | 'skills'
  | 'genders'
  | 'ethnicities'
  | 'educations'
  | 'industries';

export const BottomPlotNameLookup: Record<BottomPlotName, string> = {
  job_categories: 'Role',
  geographies: 'Geography',
  skills: 'Skill',
  genders: 'Gender',
  ethnicities: 'Ethnicity',
  educations: 'Education',
  industries: 'Industry',
};

/** ================================
 * Bar Data
 ================================ */
const getBarValue = (
  metric: CompositionMetrics[TopPlotName]
): BarChartHorizontalData['value'] | null => {
  if (
    isNil(metric) ||
    isNil(metric.timeseries) ||
    metric.timeseries.length === 0 ||
    isNil(metric?.timeseries?.[0]?.value)
  ) {
    return null;
  }

  return metric.timeseries?.[0]?.value;
};

export const getBarData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): BarChartHorizontalData[] => {
  return (
    entities
      ?.map((entity): BarChartHorizontalData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getBarValue(entity.metrics[plotName]),
        };
      })
      .filter((d): d is BarChartHorizontalData => !isNil(d)) ?? []
  );
};

export const adjustBarDataToUserCurrency = (
  data: BarData[],
  exchangeRate: number
): BarData[] => {
  if (exchangeRate === 1) return data;
  return data.map((d) => ({
    ...d,
    value: d.value ? d.value * exchangeRate : d.value,
  }));
};

export const getReplotsBarData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): BarData[] => {
  return (
    entities?.reduce<BarData[]>((acc, entity) => {
      if (!entity?.metadata || !entity?.metrics) return acc;

      const metric = entity.metrics[plotName];
      if (!metric || !metric.timeseries || metric.timeseries.length === 0) {
        return acc;
      }

      const value = metric.timeseries[0]?.value;

      acc.push({
        label: entity?.metadata.shortName ?? '',
        value: value ?? null,
      });
      return acc;
    }, []) ?? []
  );
};

export const getReplotsLineData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): ReplotsLineData[] => {
  return (
    entities?.reduce<ReplotsLineData[]>((acc, entity) => {
      if (!entity?.metadata || !entity?.metrics) return acc;

      const metric = entity.metrics[plotName];
      if (!metric || !metric.timeseries || metric.timeseries.length === 0) {
        return acc;
      }

      const values = metric.timeseries?.map((d) => ({
        date: d?.date ?? '',
        value: d?.value ?? 0,
      }));

      if (!values?.length) return acc;

      acc.push({
        label: entity?.metadata.shortName ?? '',
        values,
      });
      return acc;
    }, []) ?? []
  );
};

export const getChartDownloadData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): ExportData => {
  if (!entities?.length) return [];

  // Get all entities with valid data
  const validEntities = entities.filter(
    (entity) =>
      entity?.metadata?.shortName && entity?.metrics?.[plotName]?.timeseries
  );

  if (!validEntities.length) return [];

  // Get all unique dates and create a map of entity names
  const allDates = new Set<string>();
  validEntities.forEach((entity) => {
    if (!entity?.metrics?.[plotName]?.timeseries) return;
    entity.metrics[plotName]?.timeseries?.forEach((point) => {
      if (point?.date) {
        allDates.add(point.date);
      }
    });
  });

  // Create rows with date and values for each entity
  const dateRows = Array.from(allDates)
    .sort()
    .map((date) => {
      const row: Record<string, string | number> = { Month: date };

      validEntities.forEach((entity) => {
        if (!entity?.metadata?.shortName || !entity?.metrics?.[plotName]) {
          return;
        }
        const entityName = entity.metadata.shortName ?? '';
        const point = entity.metrics[plotName]?.timeseries?.find(
          (p) => p?.date === date
        );
        row[entityName] = point?.value ?? '';
      });

      return row;
    });

  return dateRows;
};

/** ================================
 * Top Line Data
 ================================ */
const getLineValue = (
  metric: CompositionMetrics[TopPlotName]
): LineData['value'] => {
  if (isNil(metric)) return [];

  return (
    metric.timeseries
      ?.map((d): LineValue | null => {
        const id = Number(d?.id);
        const value = d?.value;
        const dateString = d?.date;
        if (!id || isNil(value) || isNil(dateString)) return null;

        const regEx = /(.*)-(.*)/;
        const match = dateString?.match(regEx);

        if (isNil(match)) return null;
        const year = Number(match[1]);
        const month = Number(match[2]);

        return {
          id,
          value,
          metadata: {
            shortName: dateString,
            longName: dateString,
            count: value,
            share: value,
            month,
            year,
          },
        };
      })
      .filter((d): d is LineValue => !isNil(d)) ?? []
  );
};

export const getTopLineData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): LineData[] => {
  return (
    entities
      ?.map((entity): LineData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getLineValue(entity.metrics[plotName]),
        };
      })
      .filter((d): d is LineData => !isNil(d)) ?? []
  );
};

/** ================================
 * Stacked Bar Data
 ================================ */
const getStackedBarValue = (
  metric: CompositionMetrics[BottomPlotName]
): StackedBarChartHorizontalData['value'] => {
  const category = metric?.category;
  if (isNil(metric) || isNil(category)) return [];

  return category
    .map((categoryValue): StackedBarChartHorizontalValue | null => {
      const metadata = categoryValue?.metadata;
      const timeseries = categoryValue?.timeseries;
      const share = timeseries?.[0]?.share;
      const count = timeseries?.[0]?.count;
      if (
        isNil(categoryValue) ||
        isNil(metadata) ||
        isNil(metadata?.id) ||
        isNil(metadata?.shortName) ||
        isNil(timeseries) ||
        timeseries.length === 0 ||
        !isNumber(share) ||
        !isNumber(count)
      ) {
        return null;
      }

      return {
        id: metadata.id,
        value: share / 100,
        metadata: {
          shortName: metadata.shortName,
          longName: metadata.shortName,
          count,
        },
      };
    })
    .filter((d): d is StackedBarChartHorizontalValue => !isNil(d));
};

export const getStackedBarData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
}): StackedBarChartHorizontalData[] => {
  return (
    entities
      ?.map((entity): StackedBarChartHorizontalData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        const value = getStackedBarValue(entity.metrics[plotName]);

        return {
          id,
          metadata: { shortName, longName, type },
          value,
        };
      })
      .filter((d): d is StackedBarChartHorizontalData => !isNil(d)) ?? []
  );
};

export const getReplotsStackedBarData = ({
  entities,
  plotName,
  formatLabel,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
  formatLabel?: (label: string) => string;
}): StackedBarData[] => {
  const limitSegments = plotName === 'skills' ? 7 : undefined; // limit skills to top 7 skills

  const stackedBarData =
    entities
      ?.map((entity) => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { shortName } = entity.metadata;
        if (isNil(shortName)) {
          return null;
        }

        return {
          label: shortName,
          segments: getReplotsStackedBarValue(
            entity.metrics[plotName],
            ({ count }) => count,
            formatLabel
          ),
        };
      })
      .filter((d) => !isNil(d)) ?? [];

  if (limitSegments) {
    return limitStackedBarSegments(stackedBarData, limitSegments);
  }
  return stackedBarData;
};

export const limitStackedBarSegments = (
  stackedBarData: StackedBarData[],
  limitSegments: number
) => {
  const entitiesTotalSum = stackedBarData.reduce((acc: number[], bar) => {
    const segments = bar.segments;
    const sum = Object.values(segments).reduce((sum, value) => sum + value, 0);
    acc.push(sum);
    return acc;
  }, []);

  const normalizedSegments: Record<string, number[]> = {};
  stackedBarData.map((bar, index) => {
    const segments = bar.segments;
    const sum = entitiesTotalSum[index];
    Object.keys(segments).forEach((key) => {
      normalizedSegments[key] = [
        ...(normalizedSegments[key] || []),
        segments[key] / sum,
      ];
    });

    return mapValues(segments, (value) => value / sum);
  });

  const segmentScores = mapValues(normalizedSegments, (value = []) => {
    const shareStd = deviation(value) ?? 0;
    const shareMax = max(value) ?? 0;
    const shareMean = mean(value) ?? 0;

    if (isNil(shareStd) || isNil(shareMax) || isNil(shareMean)) {
      return 0;
    }

    return shareStd + shareMax - shareMean;
  });

  const topSegments = orderBy(
    Object.entries(segmentScores),
    ([_, score]) => score,
    ['desc']
  )
    .slice(0, limitSegments)
    .map(([key]) => key);

  return stackedBarData.map((bar) => {
    return {
      ...bar,
      segments: pick(bar.segments, topSegments),
    };
  });
};

const getReplotsStackedBarValue = <T>(
  metric: CompositionMetrics[BottomPlotName],
  formatValue: (value: { count: number; share: number }) => T,
  formatLabel: (label: string) => string = (label) => label
): Record<string, T> => {
  const category = metric?.category;
  if (isNil(metric) || isNil(category)) return {};

  const segments: Record<string, T> = {};
  category.forEach((categoryValue) => {
    const metadata = categoryValue?.metadata;
    const timeseries = categoryValue?.timeseries;
    const count = timeseries?.[0]?.count;
    const share = timeseries?.[0]?.share;
    if (
      isNil(categoryValue) ||
      isNil(metadata) ||
      isNil(metadata?.shortName) ||
      isNil(timeseries) ||
      timeseries.length === 0 ||
      !isNumber(count) ||
      !isNumber(share)
    ) {
      return;
    }

    segments[formatLabel(metadata.shortName)] = formatValue({ count, share });
  });

  return segments;
};

/** ================================
 * Bottom Line Data
 ================================ */

const getBottomLineValue = ({
  metric,
  subfilters,
}: {
  metric: CompositionMetrics[BottomPlotName];
  subfilters: (string | number)[];
}) => {
  const category = metric?.category;
  if (isNil(metric) || isNil(category)) return [];

  const timeSeries = category?.find(
    (c) => c?.timeseries && c.timeseries?.length > 0
  )?.timeseries;

  if (isNil(timeSeries)) return [];

  return timeSeries
    .map((ts, i) => {
      const id = Number(ts?.id);

      if (isNil(ts) || !isNumber(id)) return null;

      const dateString = ts?.date;
      const regEx = /(.*)-(.*)/;
      const match = dateString?.match(regEx);

      if (isNil(match)) return null;
      const year = Number(match[1]);
      const month = Number(match[2]);

      const subfilterTotal = category
        .filter((c) => {
          const categoryMetadata = c?.metadata;
          return (
            isNil(categoryMetadata) ||
            isNil(categoryMetadata.id) ||
            isNil(categoryMetadata.shortName) ||
            subfilters.includes(`${c?.metadata?.id}`)
          );
        })
        .reduce<{ count: number; share: number }>(
          (acc, value) => {
            const timeSeriesCategoryValues = value?.timeseries?.[i];
            if (
              isNil(timeSeriesCategoryValues?.count) ||
              isNil(timeSeriesCategoryValues?.share)
            ) {
              return acc;
            } else {
              return {
                ...acc,
                count: acc.count + timeSeriesCategoryValues.count,
                share: acc.share + timeSeriesCategoryValues.share,
              };
            }
          },
          { count: 0, share: 0 }
        );

      const share = round(subfilterTotal.share / 100, 4);

      return {
        id,
        value: share,
        metadata: {
          shortName: dateString,
          longName: dateString,
          count: subfilterTotal.count,
          share,
          month,
          year,
        },
      };
    })
    .filter((d) => !isNil(d));
};

export const getBottomLineData = ({
  entities,
  plotName,
  subfilters,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
  subfilters: (string | number)[];
}): LineData[] => {
  return (
    entities
      ?.map((entity): LineData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getBottomLineValue({
            metric: entity.metrics[plotName],
            subfilters,
          }),
        };
      })
      .filter((d): d is LineData => !isNil(d)) ?? []
  );
};

export const getReplotsBottomLineData = ({
  entities,
  plotName,
  subfilters,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
  subfilters: (string | number)[];
}): ReplotsLineData[] => {
  return (
    entities
      ?.map((entity): ReplotsLineData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { shortName } = entity.metadata;
        if (isNil(shortName)) {
          return null;
        }

        return {
          label: shortName,
          values: getBottomLineValue({
            metric: entity.metrics[plotName],
            subfilters,
          }).map((v) => ({
            date: `${v.metadata.year}-${v.metadata.month}`,
            value: v.value ?? null,
          })),
        };
      })
      .filter((d): d is ReplotsLineData => !isNil(d)) ?? []
  );
};

export const getReplotsBottomLineDownloadData = ({
  entities,
  plotName,
  subfilters,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
  subfilters: (string | number)[];
}): {
  data: {
    label: string;
    values: { count: number | null; share: number | null }[];
  }[];
  entityLabels: string[];
} => {
  // Instead of using getReplotsBottomLineData, we compute the seriesData inline
  const seriesData =
    entities?.reduce(
      (acc, entity) => {
        if (!entity?.metadata || !entity.metrics) return acc;
        const { shortName } = entity.metadata;
        if (!shortName) return acc;

        const values = getBottomLineValue({
          metric: entity.metrics[plotName],
          subfilters,
        }).map((v) => ({
          date: `${v.metadata.year}-${v.metadata.month}`,
          count: v.metadata.count,
          share: v.metadata.share,
        }));

        if (values.length === 0) return acc;
        acc.push({ label: shortName, values });
        return acc;
      },
      [] as {
        label: string;
        values: { date: string; count: number | null; share: number | null }[];
      }[]
    ) ?? [];

  // Gather all unique dates from the computed series data
  const allDatesSet = new Set<string>();
  seriesData.forEach((series) => {
    series.values.forEach((point) => {
      if (point.date) {
        allDatesSet.add(point.date);
      }
    });
  });

  // Sort dates chronologically (assuming the "year-month" format sorts correctly)
  const sortedDates = Array.from(allDatesSet).sort();

  // Build the download data: for each date, create a row collecting count and share values from each series
  const data = sortedDates.map((date) => {
    const values = seriesData.map((series) => {
      const point = series.values.find((p) => p.date === date);
      return point
        ? { count: point.count, share: point.share }
        : { count: null, share: null };
    });
    return {
      label: date,
      values,
    };
  });

  return {
    data,
    entityLabels: seriesData.map((series) => series.label),
  };
};

export const getPlotData = (
  timeframe: TimeFrameView,
  composition: CompositionDataQuery['composition'],
  plotName: TopPlotName,
  isCurrencyFormat: boolean,
  exchangeRate: number
) => {
  if (timeframe === TimeFrameView.SNAPSHOT) {
    const barData = getReplotsBarData({
      entities: composition,
      plotName: plotName,
    });

    if (isCurrencyFormat) {
      return adjustBarDataToUserCurrency(barData, exchangeRate);
    }

    return barData;
  } else {
    const lineData = getReplotsLineData({
      entities: composition,
      plotName: plotName,
    });

    if (isCurrencyFormat) {
      return adjustLineDataToUserCurrency(lineData, exchangeRate);
    }

    return lineData;
  }
};

import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React, { ComponentProps, ReactNode } from 'react';

import { PlotDataLagWarning, PlotInfoComp } from '@revelio/composed';
import { Card } from '@revelio/layout';

interface PlotExpandModalProps extends ComponentProps<typeof Modal> {
  title: string;
  plotInfo?: ComponentProps<typeof PlotInfoComp>;
  topRight?: ReactNode;
}

export const PlotExpandModal = ({
  title,
  plotInfo,
  topRight,
  children,
  onClose,
  ...rest
}: PlotExpandModalProps) => {
  return (
    <Modal size="6xl" isCentered onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent role="group" height="80%" w="90%" pb="30px">
        <ModalHeader pr="8px">
          <Flex align="center" justifyContent="space-between">
            <Stack spacing={0} direction="row" align="center">
              <Heading
                fontSize={'18px'}
                color="text.primary"
                fontWeight="semibold"
              >
                {title}
              </Heading>
              topLeft={plotInfo && <PlotInfoComp {...plotInfo} />}
            </Stack>
            <Flex alignItems="center">
              <PlotDataLagWarning />
              <IconButton
                aria-label="close modal"
                icon={<CloseIcon />}
                size="sm"
                variant="white"
                onClick={onClose}
              />
              {topRight}
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Card p="0">{children}</Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { SystemStyleObject, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const loadingBoxStyles = {
  scrollbarWidth: 'thin',
  scrollbarColor: '#e3e3e3 rgba(0,0,0,0)',
  '&::-webkit-scrollbar': {
    width: '14px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'lightgrey',
    borderRadius: '10px',
    backgroundClip: 'padding-box',
    border: '5px solid rgba(0,0,0,0)',
  },
};

const streamText = async (
  cachedText: string,
  textSetter: Dispatch<SetStateAction<string>>
) => {
  let currentIndex = 0;

  while (currentIndex < cachedText.length) {
    const randomChunkSize = Math.floor(Math.random() * 10) + 1;

    const nextChunk = cachedText.substring(
      currentIndex,
      currentIndex + randomChunkSize
    );
    textSetter((prevText) => prevText + nextChunk);

    currentIndex += randomChunkSize;

    const randomDelay = Math.floor(Math.random() * 75) + 75;

    await delay(randomDelay);
  }
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export interface TypingTextProps {
  cachedText?: string;
  textChunk?: string;
  sx?: SystemStyleObject;
}

export function TypingText({
  cachedText,
  textChunk,
  sx = {},
}: TypingTextProps) {
  const [streamedText, setStreamedText] = useState('');

  useEffect(() => {
    if (cachedText) {
      streamText(cachedText, setStreamedText);
    }
    if (textChunk) {
      setStreamedText(textChunk);
    }
  }, [cachedText, textChunk]);

  return (
    <Text
      sx={{
        ...sx,
        ...loadingBoxStyles,
      }}
      overflowY="auto"
      maxHeight="inherit"
      fontSize="md"
      data-testid="typing-text"
    >
      {streamedText}
    </Text>
  );
}

export default TypingText;

import React from 'react';
import {
  Box,
  HStack,
  Text,
  VStack,
  Badge,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { RiLineChartLine } from 'react-icons/ri';
import { LineChart, FormatType } from '@revelio/replots';
import { HealthCheckResult } from '@revelio/data-access';
import { OutageStatus } from '../healthCheck';
import { TimelineDay } from './TimelineDay';
import { determineOutageStatus } from '../utils/health-check.utils';

interface OverallHealthMetricsProps {
  results: HealthCheckResult[];
  isLoading: boolean;
}

export const OverallHealthMetrics: React.FC<OverallHealthMetricsProps> = ({
  results,
  isLoading,
}) => {
  const {
    isOpen: isChartOpen,
    onOpen: onChartOpen,
    onClose: onChartClose,
  } = useDisclosure();

  const dailyStats = React.useMemo(() => {
    const statsMap = new Map<
      string,
      {
        date: string;
        status: OutageStatus;
        failureCount: number;
        totalChecks: number;
        avgResponseTime: number;
      }
    >();

    results
      .filter((result) => !result.isManualCheck)
      .forEach((result) => {
        // Ensure we're working with a proper Date object
        const resultDate = new Date(result.timestamp);
        const date = resultDate.toISOString().split('T')[0];

        let existing = statsMap.get(date);
        if (!existing) {
          existing = {
            date,
            status: 'operational' as OutageStatus,
            failureCount: 0,
            totalChecks: 0,
            avgResponseTime: 0,
          };
        }

        const newTotalChecks = existing.totalChecks + 1;
        const newFailureCount =
          existing.failureCount + (result.status === 'failure' ? 1 : 0);

        // Calculate new average response time
        const totalTime =
          existing.avgResponseTime * existing.totalChecks + result.responseTime;
        const newAvgResponseTime = totalTime / newTotalChecks;

        const updatedStats = {
          ...existing,
          totalChecks: newTotalChecks,
          failureCount: newFailureCount,
          avgResponseTime: newAvgResponseTime,
          status: determineOutageStatus(newFailureCount),
        };

        statsMap.set(date, updatedStats);
      });

    return statsMap;
  }, [results]);

  // Generate last 90 days array for timeline
  const timelineDays = React.useMemo(() => {
    const days: Array<{
      date: Date;
      status: OutageStatus | null;
      failureCount: number;
      totalChecks: number;
      avgResponseTime?: number;
    }> = [];

    const now = new Date();
    for (let i = 89; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(date.getDate() - i);
      const dateStr = date.toISOString().split('T')[0];
      const dayStats = dailyStats.get(dateStr);

      days.push({
        date,
        status: dayStats?.status ?? null,
        failureCount: dayStats?.failureCount ?? 0,
        totalChecks: dayStats?.totalChecks ?? 0,
        avgResponseTime: dayStats?.avgResponseTime,
      });
    }

    return days;
  }, [dailyStats]);

  // Calculate overall statistics
  const overallStats = React.useMemo(() => {
    // Sort results by timestamp for chronological processing
    const sortedResults = results
      .filter((result) => !result.isManualCheck)
      .sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );

    if (sortedResults.length === 0) {
      return {
        uptime: '100.00',
        avgResponseTime: '0',
        currentStatus: 'operational' as OutageStatus,
      };
    }

    // Group requests into batches (requests within 5 seconds of each other)
    const BATCH_THRESHOLD_MS = 5000; // 5 seconds
    const batches: { timestamp: Date; hasFailure: boolean }[] = [];
    let currentBatch: HealthCheckResult[] = [];
    let lastTimestamp: Date | null = null;

    sortedResults.forEach((result) => {
      const currentTimestamp = new Date(result.timestamp);

      if (
        lastTimestamp &&
        currentTimestamp.getTime() - lastTimestamp.getTime() >
          BATCH_THRESHOLD_MS
      ) {
        // Process previous batch
        if (currentBatch.length > 0) {
          batches.push({
            timestamp: new Date(currentBatch[0].timestamp),
            hasFailure: currentBatch.some((r) => r.status === 'failure'),
          });
        }
        currentBatch = [];
      }

      currentBatch.push(result);
      lastTimestamp = currentTimestamp;
    });

    // Process the last batch
    if (currentBatch.length > 0) {
      batches.push({
        timestamp: new Date(currentBatch[0].timestamp),
        hasFailure: currentBatch.some((r) => r.status === 'failure'),
      });
    }

    // Calculate uptime based on time between batches
    let totalMinutes = 0;
    let upMinutes = 0;

    for (let i = 0; i < batches.length - 1; i++) {
      const currentBatch = batches[i];
      const nextBatch = batches[i + 1];
      const minutesBetween =
        (nextBatch.timestamp.getTime() - currentBatch.timestamp.getTime()) /
        (1000 * 60);

      totalMinutes += minutesBetween;
      if (!currentBatch.hasFailure) {
        upMinutes += minutesBetween;
      }
    }

    // Calculate uptime percentage
    const uptimePercentage =
      totalMinutes > 0 ? (upMinutes / totalMinutes) * 100 : 100;

    const avgResponseTime =
      Array.from(dailyStats.values()).reduce(
        (sum, day) => sum + day.avgResponseTime,
        0
      ) / dailyStats.size;

    const currentStatus = determineOutageStatus(
      timelineDays[timelineDays.length - 1].failureCount
    );

    return {
      uptime: uptimePercentage.toFixed(2),
      avgResponseTime: avgResponseTime.toFixed(0),
      currentStatus,
    };
  }, [results, dailyStats, timelineDays]);

  // Prepare response time chart data
  const responseTimeData = React.useMemo(() => {
    const chartData = results
      .filter((result) => !result.isManualCheck)
      .map((result) => ({
        date: result.timestamp,
        value: result.responseTime,
      }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return [
      {
        label: 'Average Response Time',
        values: chartData,
        color: '#5BD992',
      },
    ];
  }, [results]);

  const getStatusBadgeColor = (status: OutageStatus | 'loading'): string => {
    switch (status) {
      case 'operational':
        return 'green';
      case 'partial_outage':
        return 'yellow';
      case 'major_outage':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Box
      p={6}
      borderRadius="lg"
      border="1px"
      borderColor="gray.200"
      bg="white"
      mb={8}
    >
      <VStack align="stretch" spacing={6}>
        {/* Status and Stats */}
        <Grid templateColumns="repeat(2, 1fr)" gap={6} alignItems="center">
          <GridItem display="flex" alignItems="center">
            <HStack spacing={2} align="center" width="100%">
              <Text fontSize="sm" color="gray.600" lineHeight="1">
                Current Status:
              </Text>
              <Badge
                colorScheme={getStatusBadgeColor(
                  isLoading ? 'loading' : overallStats.currentStatus
                )}
                fontSize="sm"
                px={2}
                py={0.5}
                lineHeight="1.2"
              >
                {isLoading
                  ? 'Loading...'
                  : overallStats.currentStatus
                      .split('_')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ')}
              </Badge>
            </HStack>
          </GridItem>
          <GridItem>
            <HStack justify="flex-end" align="center" width="100%">
              <IconButton
                aria-label="View response time chart"
                icon={<Icon as={RiLineChartLine} />}
                size="md"
                variant="ghost"
                onClick={onChartOpen}
              />
            </HStack>
          </GridItem>
        </Grid>

        {/* Timeline */}
        <Box>
          <Box position="relative">
            {/* Timeline bars */}
            <Box position="relative" zIndex={2}>
              <HStack
                spacing={0.75}
                height="20px"
                justify="space-between"
                mx={0}
              >
                {timelineDays.map((day) => {
                  const dayStart = new Date(day.date);
                  dayStart.setHours(0, 0, 0, 0);
                  const dayEnd = new Date(day.date);
                  dayEnd.setHours(23, 59, 59, 999);

                  const dayResults = results.filter((result) => {
                    const timestamp = new Date(result.timestamp);
                    return (
                      timestamp >= dayStart &&
                      timestamp <= dayEnd &&
                      !result.isManualCheck
                    );
                  });

                  return (
                    <TimelineDay
                      key={day.date.toISOString()}
                      status={day.status}
                      date={day.date}
                      failureCount={day.failureCount}
                      totalChecks={day.totalChecks}
                      avgResponseTime={day.avgResponseTime}
                      results={dayResults}
                      isOverall={true}
                    />
                  );
                })}
              </HStack>
            </Box>

            {/* Labels and connecting line container */}
            <Box position="relative" mt={4}>
              {/* Continuous line */}
              <Box
                position="absolute"
                top="50%"
                left="0"
                right="0"
                height="1px"
                bg="gray.200"
                transform="translateY(-50%)"
              />

              {/* Labels container */}
              <HStack
                justify="space-between"
                position="relative"
                mx={0}
                width="100%"
              >
                <Text fontSize="xs" color="gray.500" bg="white" pr={2}>
                  90 days ago
                </Text>
                <Box
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                  bg="white"
                  px={2}
                >
                  <HStack spacing={1}>
                    <Text fontSize="xs" fontWeight="medium" color="gray.700">
                      {overallStats.uptime}%
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                      uptime
                    </Text>
                  </HStack>
                </Box>
                <Text fontSize="xs" color="gray.500" bg="white" pl={2}>
                  Today
                </Text>
              </HStack>
            </Box>
          </Box>
        </Box>

        {/* Response Time Chart Modal */}
        <Modal isOpen={isChartOpen} onClose={onChartClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Overall Response Time History</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box height="400px">
                <LineChart
                  data={responseTimeData}
                  format={FormatType.INTEGER}
                  dateFormat="daytime"
                  showGridLines={true}
                />
              </Box>
              <Text fontSize="sm" color="gray.500" mt={4}>
                Shows average response times across all services. Lower is
                better.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
};

import { Divider, Heading, Link } from '@chakra-ui/react';
import { useCallback } from 'react';

import { useIsRevelioAdmin } from '@revelio/auth';
import {
  Deliverable,
  PipelineType,
  S3Delivery,
  SnowflakeDelivery,
} from '@revelio/data-access';

import { getPipelineTypeTitleById } from '../data-set.model';
import {
  PipelineTypeFilesLookup,
  getPipelineDownloadFiles,
  isDeliverableDownloadable,
} from '../delivery/delivery.model';
import {
  CONFIGURATION_SUMMARY_HEADING_PROPS,
  DeliverableConfigurationSummary,
} from './confirmation.shared';
import DatasetConfigurationSummary from './dataset-configuration-summary';

interface ConfirmationSummaryProps {
  deliverables: Deliverable[];
  s3Delivery?: S3Delivery | null;
  snowflakeDelivery?: SnowflakeDelivery | null;
  client?: string;
  name?: string;
  status?: string;
  lagData: boolean | null | undefined;
  commonDeliveryFilePrefix?: string;
}
const ConfirmationSummary = ({
  deliverables,
  s3Delivery,
  snowflakeDelivery,
  client = '',
  name = '',
  status,
  lagData,
  commonDeliveryFilePrefix,
}: ConfirmationSummaryProps) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();

  const generateFullSnowflakeTable = (
    del: Deliverable,
    pipelineTypeFile: string
  ) =>
    `${del.snowflake_delivery?.snowflake_db}.${del.snowflake_delivery?.snowflake_schema}.${del.snowflake_delivery?.snowflake_file_prefix}_${pipelineTypeFile}`;

  const generatePartialSnowflakePath = (
    del: Deliverable,
    pipelineTypeFile: string
  ) => `${del.snowflake_delivery?.snowflake_file_prefix}_${pipelineTypeFile}`;

  const getSnowflakeTablePaths = useCallback(
    (deliverable: Deliverable) => {
      if (isRevelioAdmin) {
        return PipelineTypeFilesLookup[deliverable.pipeline.pipeline_type].map(
          (file) => ({
            value: generateFullSnowflakeTable(deliverable, file),
            copyValue: generateFullSnowflakeTable(deliverable, file),
          })
        );
      }
      return PipelineTypeFilesLookup[deliverable.pipeline.pipeline_type].map(
        (file) => ({
          value: generatePartialSnowflakePath(deliverable, file),
          copyValue: generatePartialSnowflakePath(deliverable, file),
        })
      );
    },
    [isRevelioAdmin]
  );

  const generateFullS3Paths = (del: Deliverable, pipelineTypeFile: string) =>
    `s3://${del.s3_delivery?.s3_bucket}/${del.s3_delivery?.s3_file_prefix}/${pipelineTypeFile}_file/`;

  return (
    <>
      <DatasetConfigurationSummary
        isRevelioAdmin={isRevelioAdmin}
        lagData={lagData}
        deliverables={deliverables}
        commonDeliveryFilePrefix={commonDeliveryFilePrefix}
      />

      <Divider my="5" />

      <Heading pl="2.5" fontWeight="600" fontSize="16px" color="text.primary">
        Delivery
      </Heading>

      {s3Delivery && isDeliverableDownloadable(s3Delivery) && (
        <>
          <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>
            {status === 'FINISHED'
              ? 'Downloads available below'
              : 'Download when completed (available from the Status page)'}
          </Heading>

          {deliverables.map(({ pipeline }) => (
            <>
              <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>
                {getPipelineTypeTitleById(pipeline.pipeline_type)}
              </Heading>

              <DeliverableConfigurationSummary
                details={getPipelineDownloadFiles({
                  pipelineType: pipeline.pipeline_type as PipelineType,
                  s3Delivery,
                }).map((file) =>
                  status === 'FINISHED'
                    ? {
                        value: (
                          <Link
                            colorScheme="text.primary"
                            href={file.link}
                            download={`${client}_${name}_${file.label}`}
                          >
                            {file.label}
                          </Link>
                        ),
                      }
                    : { value: file.label }
                )}
              />
            </>
          ))}
        </>
      )}

      {s3Delivery && !isDeliverableDownloadable(s3Delivery) && (
        <>
          <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>S3</Heading>

          {deliverables.map((del) => (
            <>
              <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>
                {getPipelineTypeTitleById(del.pipeline.pipeline_type)}
              </Heading>
              {/* <DeliverableConfigurationSummary details={getS3FilePaths(del)} /> */}
              <DeliverableConfigurationSummary
                details={getPipelineDownloadFiles({
                  pipelineType: del.pipeline.pipeline_type as PipelineType,
                  s3Delivery,
                }).map((file, index) => {
                  const label = generateFullS3Paths(
                    del,
                    PipelineTypeFilesLookup[del.pipeline.pipeline_type][index]
                  );
                  return status === 'FINISHED'
                    ? {
                        value: (
                          <Link
                            colorScheme="text.primary"
                            href={file.link}
                            download={`${client}_${name}_${label}`}
                          >
                            {label}
                          </Link>
                        ),
                        copyValue: label,
                      }
                    : { value: label, copyValue: label };
                })}
              />
            </>
          ))}
        </>
      )}

      {snowflakeDelivery && (
        <>
          <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>
            Snowflake Database
          </Heading>

          {deliverables.map((del) => (
            <>
              <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS}>
                {getPipelineTypeTitleById(del.pipeline.pipeline_type)}
              </Heading>
              <DeliverableConfigurationSummary
                details={getSnowflakeTablePaths(del)}
              />
            </>
          ))}
        </>
      )}
    </>
  );
};

export default ConfirmationSummary;

import { SankeyLink, SankeyNode } from 'd3-sankey';

import { Link, Node, SankeyLinkValidated, SankeyNodeValidated } from './types';

const validateNode = (
  node: SankeyNode<Node, Link>
): node is SankeyNodeValidated => {
  return (
    node.x0 !== undefined &&
    !isNaN(node.x0) &&
    node.x1 !== undefined &&
    !isNaN(node.x1) &&
    node.y0 !== undefined &&
    !isNaN(node.y0) &&
    node.y1 !== undefined &&
    !isNaN(node.y1)
  );
};

export const validateNodes = (
  nodes: SankeyNode<Node, Link>[]
): SankeyNodeValidated[] => {
  return nodes.filter(validateNode);
};

export const validateLinks = (
  links: SankeyLink<Node, Link>[]
): SankeyLinkValidated<SankeyNodeValidated, Link>[] => {
  return links.filter(
    (link): link is SankeyLinkValidated<SankeyNodeValidated, Link> => {
      return (
        link.width !== undefined &&
        !isNaN(link.width) &&
        link.y0 !== undefined &&
        !isNaN(link.y0) &&
        link.y1 !== undefined &&
        !isNaN(link.y1) &&
        typeof link.source === 'object' &&
        typeof link.target === 'object' &&
        validateNode(link.source) &&
        validateNode(link.target)
      );
    }
  );
};

import { HttpResponse, http } from 'msw';

import { MOCK_API_ROOT } from './mockConstants';
import recoveryFlowSentEmailMock from './recoveryFlowSentEmail';
import { sessionIdentity } from './sessionIdentity';
import settingsFlowMock from './settingsFlow';
import updateSettingsFlowMock from './updateSettingFlow';

export const getSettingsFlowHandler = http.get(
  `${MOCK_API_ROOT}/self-service/settings/flows`,
  () => {
    return HttpResponse.json(settingsFlowMock);
  }
);

export const updateSettingsFlowHandler = http.post(
  `${MOCK_API_ROOT}/self-service/settings`,
  () => {
    return HttpResponse.json(updateSettingsFlowMock);
  }
);

export const recoveryFlowSentEmailHandler = http.post(
  `${MOCK_API_ROOT}/self-service/recovery`,
  () => {
    return HttpResponse.json(recoveryFlowSentEmailMock);
  }
);

export const whoAmIHandler = http.get(
  'https://msw-mock-auth.api/sessions/whoami',
  ({ request }) => {
    console.log('Handler', request.method, request.url);
    return HttpResponse.json(sessionIdentity);
  }
);

export const handlers = [
  getSettingsFlowHandler,
  updateSettingsFlowHandler,
  recoveryFlowSentEmailHandler,
];

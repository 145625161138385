import {
  SimpleGrid,
  SimpleGridProps,
  Stack,
  UseCheckboxGroupProps,
} from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';

import {
  CardListColumn,
  CardListColumnProps,
  ColumnItem,
} from './card-list-column';

type SeparatorFn = (
  itemData: { id: string; isPreSeparator: boolean },
  index: number
) => ReactNode;

export interface ColumnListItemGridProps<CardType extends string>
  extends Pick<
    CardListColumnProps<CardType>,
    'variant' | 'hideCtaIfNotSelected' | 'hideCheckbox' | 'onCloseSubMenu'
  > {
  gridProps?: SimpleGridProps;
  columnItems: ColumnItem<CardType>[];
  checkboxGroupProps: UseCheckboxGroupProps;
  separator?: SeparatorFn;
}

export const ColumnListItemGrid = <CardType extends string>({
  gridProps,
  columnItems,
  checkboxGroupProps,
  variant,
  hideCtaIfNotSelected,
  hideCheckbox,
  onCloseSubMenu,
  separator,
}: ColumnListItemGridProps<CardType>) => {
  const gridConfig: SimpleGridProps = useMemo(() => {
    return { spacing: 6, columns: 2, gridAutoFlow: 'row', ...gridProps };
  }, [gridProps]);

  return (
    <SimpleGrid {...gridConfig}>
      {columnItems.map((column, i) => {
        return (
          <Stack key={`${column.id}-${i}`} direction="row" gap="4">
            <SeparatedItem
              items={columnItems}
              itemIndex={i}
              gridConfig={gridConfig}
              separator={separator}
            >
              <CardListColumn<CardType>
                columnItem={column}
                variant={variant}
                checkboxGroupProps={checkboxGroupProps}
                hideCtaIfNotSelected={hideCtaIfNotSelected}
                hideCheckbox={hideCheckbox}
                onCloseSubMenu={onCloseSubMenu}
              />
            </SeparatedItem>
          </Stack>
        );
      })}
    </SimpleGrid>
  );
};

const SeparatedItem = <CardType extends string>({
  items,
  itemIndex,
  gridConfig,
  separator,
  children,
}: {
  items: ColumnItem<CardType>[];
  itemIndex: number;
  gridConfig?: SimpleGridProps;
  separator?: SeparatorFn;
  children: ReactNode;
}) => {
  const colCount = (gridConfig?.columns as number) ?? 1;
  // Places the separator from the last item in a row to the first item in the next row. In this case,
  // a preSeparator is placed before the first item in the next row.
  const preSeparator = (() => {
    if (
      !separator ||
      itemIndex === 0 ||
      itemIndex % colCount !== 0 // not the first item in a row
    ) {
      return;
    }
    return separator(
      { id: items[itemIndex].id, isPreSeparator: true },
      itemIndex
    );
  })();

  // a postSeparator appears after an item as long as it is not the last item.
  const postSeparator = (() => {
    if (
      !separator ||
      itemIndex === items.length - 1 || // last item in set
      (itemIndex + 1) % colCount === 0 // next item is first item in a row
    ) {
      return;
    }

    return separator(
      { id: items[itemIndex].id, isPreSeparator: false },
      itemIndex
    );
  })();

  return (
    <>
      {preSeparator && preSeparator}
      {children}
      {postSeparator && postSeparator}
    </>
  );
};

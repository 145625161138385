import { Box, Flex, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const MeterContainer = ({
  title,
  children,
}: PropsWithChildren & { title: string }) => {
  return (
    <Flex flexDirection="column" flexBasis="25%" height="full" paddingX="2%">
      <Text fontSize="xs" fontWeight="600" color="#2D426A">
        {title}
      </Text>
      <Box width="full" flexGrow="1" paddingTop="4px">
        {children}
      </Box>
    </Flex>
  );
};

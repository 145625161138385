import styles from './legend.module.css';

export type LegendItem = {
  label: string;
  color: string;
};

export type LegendProps = {
  items: LegendItem[];
  formatLabel?: (label: string) => string;
};

export const Legend = ({ items, formatLabel }: LegendProps) => {
  return (
    <div className={styles.legend}>
      {items.map(({ label, color }) => {
        const formattedLabel = formatLabel ? formatLabel(label) : label;

        return (
          <div key={`legend-${label}`} className={styles.legendItem}>
            <span
              className={styles.legendSwatch}
              style={{ backgroundColor: color }}
            />
            <span title={label} className={styles.legendLabel}>
              {formattedLabel}
            </span>
          </div>
        );
      })}
    </div>
  );
};

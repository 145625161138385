import { Button, Link } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { isToday } from 'date-fns';
import mixpanel from 'mixpanel-browser';

import {
  ACCOUNT_PORTAL_BACKEND_ROOT,
  fetchAccountPortalbackend,
  useGetLoggedInUser,
} from '@revelio/auth';
import { CTAInteractionTrackingEvents, PageTitles } from '@revelio/core';
import { useFreeTrialAlertModalState } from '@revelio/layout';

export interface ContactSalesButtonProps {
  buttonProps?: React.ComponentProps<typeof Button>;
  page?: PageTitles;
  onClick?: () => void;
  isLink?: boolean;
}

export function ContactSalesButton({
  buttonProps,
  page,
  onClick,
  isLink = false,
}: ContactSalesButtonProps) {
  const toast = useToast();
  const { loggedInUser } = useGetLoggedInUser();
  const { name, email } = loggedInUser;
  const { days } = useFreeTrialAlertModalState();

  const mixpanelContactSales = () => {
    mixpanel.track(CTAInteractionTrackingEvents.CONTACT_SALES_CLICK, {
      days_left: days,
      page,
    });
  };

  const handleContactSales = async () => {
    mixpanelContactSales();
    if (typeof onClick === 'function') {
      onClick();
    }
    const contactedSales = localStorage.getItem('contacted-sales') || '';
    if (!contactedSales || !isToday(new Date(contactedSales))) {
      await fetchAccountPortalbackend(
        `${ACCOUNT_PORTAL_BACKEND_ROOT}/users/zoho/contactsales`,
        {
          method: 'POST',
          body: JSON.stringify({ email: email, name: name }),
        }
      );
      localStorage.setItem('contacted-sales', new Date().toDateString());
      toast({
        title: 'E-mail sent!',
        description:
          "We've sent an e-mail to both you and a member of our Sales Team to connect.",
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'Thank you!',
        description:
          'We’ve notified a member of our Sales Team, who will reach out shortly.',
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return isLink ? (
    <Link
      variant="tooltip"
      onClick={handleContactSales}
      data-testid="contact-sales-link"
    >
      Contact Sales
    </Link>
  ) : (
    <Button
      colorScheme="green"
      onClick={handleContactSales}
      {...buttonProps}
      data-testid="contact-sales-button"
    >
      Contact Sales
    </Button>
  );
}

export default ContactSalesButton;

import { Box, Heading, Stack, VStack } from '@chakra-ui/layout';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import React, { useContext, useState } from 'react';
import { catchError, distinct, tap } from 'rxjs';

import { PagePaths } from '@revelio/core';
import { CardListSelectController } from '@revelio/layout';

import { environment } from '../../../environments/environment';
import { DeliverableStepContainer } from '../deliverable-step-container';
import { DeliverablePaths } from '../deliverables-page';
import {
  deliverablesStore,
  setEnrichmentModals,
} from '../deliverables.repository';
import ConfirmationMappedColumns from '../file-upload/confirmation-mapped-columns';
import { FileUploadContext } from '../file-upload/file-upload-context';

const ConfirmationStep = ({
  loading,
  setLoading,
  csrfState: csrfToken,
}: {
  onSubmit?: (arg0: () => void) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  csrfState: string;
}) => {
  const {
    mappedColumns,
    fileType,
    selectedSheet,
    headerIndex,
    goToNext,
    goToPrevious,
    clientName,
    s3FileLocation,
    email,
    oryId,
  } = useContext(FileUploadContext);
  const toast = useToast();
  const handleOnSubmit = async () => {
    const mappedColumnsToObject = mappedColumns?.reduce<{
      [key: string]: string;
    }>((acc, obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      acc[key] = value;
      return acc;
    }, {});
    if (selectedOptions.length < 1) {
      toast({
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
        title: 'No columns selected!',
        description: 'You must select at least one or more columns!',
        isClosable: true,
      });
      return;
    }
    const uploadMetadata = async (id: string) => {
      const metaData = {
        client: clientName,
        s3_data_location: s3FileLocation,
        email: email,
        columns: mappedColumnsToObject,
        models: selectedOptions,
        sheet: fileType === 'excel' ? selectedSheet : '',
        header: headerIndex,
      };
      try {
        const response = await fetch(
          `${environment.DASH_META_API}/api/deliverables/dataenrichment/${id}/upload/metadata`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-surf-token-dash-meta': csrfToken,
              'request-id': crypto.randomUUID(),
            },
            body: JSON.stringify(metaData),
            credentials: 'include',
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        return error;
      }
    };

    if (oryId) {
      setLoading(true);
      await uploadMetadata(oryId);
      setLoading(false);
    }

    goToNext();
  };

  const modelRequirements = {
    // gender: ['first_name'],
    // ethnicity: ['last_name'],
    location: ['location' /*, 'city', 'state', 'country'*/],
    // company: ['company_name', 'company_linkedinurl', 'company_url'],
    job_taxonomy: ['title'],
    seniority: ['title'],
    salary_base: ['title'],
    salary_total: ['title'],
    contractor_pay_rates: ['title'],
    contractor_pay_rates_2: ['location'],
    company_mapping: ['company_name'],
  };

  const getIsMandatoryValue = (model: string) =>
    !mappedColumns?.some((obj) =>
      Object.values(obj).some((value) =>
        modelRequirements[model as keyof typeof modelRequirements].includes(
          value
        )
      )
    );

  const items = [
    {
      heading: null,
      columns: [
        // {
        //   id: 'gender',
        //   label: 'Gender',
        //   isMandatory: getIsMandatoryValue('gender'),
        // },
        // {
        //   id: 'ethnicity',
        //   label: 'Ethnicity',
        //   isMandatory: getIsMandatoryValue('ethnicity'),
        // },
        {
          id: 'location',
          label: 'Location',
          menuItems: [
            { id: 'region', label: 'Region' },
            { id: 'country', label: 'Country' },
            { id: 'state', label: 'State' },
            { id: 'city', label: 'City' },
            { id: 'metro_area', label: 'Metropolitan Area' },
          ],
          isMandatory: getIsMandatoryValue('location'),
        },
        // {
        //   id: 'company',
        //   label: 'Company',
        //   isMandatory: getIsMandatoryValue('company'),
        // },
        {
          id: 'job_taxonomy',
          label: 'Job Taxonomy',
          menuItems: [
            { id: 'role_k7', label: 'Role k7' },
            { id: 'role_k50', label: 'Role k50' },
            { id: 'role_k150', label: 'Role k150' },
            { id: 'role_k1000', label: 'Role k1000' },
            { id: 'role_k1250', label: 'Role k1250' },
            { id: 'role_k1500', label: 'Role k1500' },
            { id: 'confidence', label: 'Confidence' },
          ],
          isMandatory: getIsMandatoryValue('job_taxonomy'),
        },
        {
          id: 'seniority',
          label: 'Seniority',
          isMandatory: getIsMandatoryValue('seniority'),
        },
        {
          id: 'salary_base',
          label: 'Base Salary',
          menuItems: [
            { id: 'average_base', label: 'Base Average' },
            { id: '10th_percentile_base', label: 'Base 10th Percentile' },
            { id: '25th_percentile_base', label: 'Base 25th Percentile' },
            { id: '50th_percentile_base', label: 'Base 50th Percentile' },
            { id: '75th_percentile_base', label: 'Base 75th Percentile' },
            { id: '90th_percentile_base', label: 'Base 90th Percentile' },
          ],
          isMandatory: getIsMandatoryValue('salary_base'),
        },
        {
          id: 'salary_total',
          label: 'Total Compensation',
          menuItems: [
            { id: 'average_total', label: 'Total Average' },
            { id: '10th_percentile_total', label: 'Total 10th Percentile' },
            { id: '25th_percentile_total', label: 'Total 25th Percentile' },
            { id: '50th_percentile_total', label: 'Total 50th Percentile' },
            { id: '75th_percentile_total', label: 'Total 75th Percentile' },
            { id: '90th_percentile_total', label: 'Total 90th Percentile' },
          ],
          isMandatory: getIsMandatoryValue('salary_total'),
        },
        {
          id: 'contractor_pay_rates',
          label: 'Contractor Pay Rate',
          menuItems: [
            { id: 'average_contract', label: 'Contractor Pay Average' },
            {
              id: '10th_percentile_contract',
              label: 'Contract 10th Percentile',
            },
            {
              id: '25th_percentile_contract',
              label: 'Contract 25th Percentile',
            },
            {
              id: '50th_percentile_contract',
              label: 'Contract 50th Percentile',
            },
            {
              id: '75th_percentile_contract',
              label: 'Contract 75th Percentile',
            },
            {
              id: '90th_percentile_contract',
              label: 'Contract 90th Percentile',
            },
          ],
          isMandatory:
            getIsMandatoryValue('contractor_pay_rates') ||
            getIsMandatoryValue('contractor_pay_rates_2'),
        },
        {
          id: 'company_mapping',
          label: 'Company Mapping',
          menuItems: [
            { id: 'rcid', label: 'RCID' },
            {
              id: 'ultimate_parent_rcid',
              label: 'Ultimate Parent RCID',
            },
            {
              id: 'primary_company_name',
              label: 'Primary Parent RCID',
            },
            {
              id: 'ultimate_primary_company_name',
              label: 'Ultimate Primary Parent RCID',
            },
            {
              id: 'company_linkedin',
              label: 'Company LinkedIn',
            },
            {
              id: 'ultimate_company_linkedin',
              label: 'Ultimate Company LinkedIn',
            },
          ],
          isMandatory: getIsMandatoryValue('company_mapping'),
        },
      ],
    },
  ];

  // const modelDefaults = ['role_k7', 'role_k150', 'seniority'];

  // const getSelectedDefaults = items
  //   .reduce(
  //     (
  //       acc: {
  //         id: string;
  //         label: string;
  //         isMandatory: boolean;
  //         menuItems?: { id: string; label: string }[];
  //       }[],
  //       outerObj
  //     ) => {
  //       const filtered = outerObj.columns.filter(
  //         (innerObj) => innerObj.isMandatory === false
  //       );
  //       return [...acc, ...filtered];
  //     },
  //     []
  //   )
  //   .map((item) => [...(item?.menuItems?.map((mi) => mi.id) || []), item.id])
  //   .flat()
  //   .filter((i: string) => modelDefaults.includes(i))
  //   .flat();

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect$(
    () =>
      deliverablesStore.pipe(
        distinct(),
        tap((data) => {
          setSelectedOptions(data.enrichmentModals);
        }),
        catchError((e) => {
          return e;
        })
      ),
    []
  );

  const hasMandatory = items.some((item) => {
    return item.columns.some((prop) => prop.isMandatory);
  });

  return (
    <DeliverableStepContainer
      id="confirm_enrichment"
      title="Confirm Enrichment"
      submitButtonContent="Select"
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      onSubmit={handleOnSubmit}
      disableFade
      isSubmitLoading={loading}
      isDataBuilder={false}
    >
      <VStack align="start" gap={10} w="full">
        <Box>
          <ConfirmationMappedColumns mappedColumns={mappedColumns} />
        </Box>
        <Box w="full">
          <Stack spacing={3} w="full">
            <Heading
              as="h2"
              size="sm"
              color="navyBlue.500"
              fontWeight={600}
              pb={0}
            >
              Which columns would like to be added to your file?
            </Heading>
            {hasMandatory && (
              <Alert status="warning">
                <AlertIcon />
                <AlertDescription>
                  Some of the columns have been disabled due no related assigned
                  columns were mapped.{' '}
                  <Button
                    variant="link"
                    colorScheme="orange"
                    onClick={() => goToPrevious()}
                  >
                    Update
                  </Button>{' '}
                  your mapping if you think this is a mistake.
                </AlertDescription>
              </Alert>
            )}
            <CardListSelectController
              variant="columns"
              items={items}
              value={selectedOptions}
              hideCtaIfNotSelected={true}
              gridProps={{
                spacing: 4,
                columns: 3,
                gridAutoFlow: 'row',
              }}
              onChange={(value: string[]) =>
                deliverablesStore.update(setEnrichmentModals(value))
              }
            />
          </Stack>
        </Box>
      </VStack>
    </DeliverableStepContainer>
  );
};

export default ConfirmationStep;

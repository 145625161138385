import { find, findKey, isEqual, pickBy } from 'lodash';

import {
  DateRangeDictionary,
  DateRangeEntry,
  DateRangeValue,
} from './collection';
import { SelectDateDictionary, SelectDateEntry } from './date-select/helpers';

const isSelectDateEntry = (
  dateEntry: SelectDateEntry | DateRangeEntry
): dateEntry is SelectDateEntry =>
  (dateEntry as SelectDateEntry).dateMonth !== undefined;

const isDateRangeEntry = (
  dateEntry: SelectDateEntry | DateRangeEntry
): dateEntry is DateRangeEntry =>
  (dateEntry as DateRangeEntry).endDate !== undefined;

export const createFilteredPredefinedDateDictionary = ({
  dateDictionary,
  minimumMonth,
}: {
  minimumMonth: Date;
  dateDictionary: SelectDateDictionary | DateRangeDictionary;
}) => {
  const dateDictAvaialbleAfterMinimumDate = pickBy(dateDictionary, (entry) => {
    if (isSelectDateEntry(entry)) {
      return entry.dateMonth >= minimumMonth;
    }

    if (isDateRangeEntry(entry)) {
      return entry.startDate >= minimumMonth;
    }

    throw Error(
      `Date dictionary passed in isn't a Date or range, e.g. ${entry}`
    );
  });
  return dateDictAvaialbleAfterMinimumDate;
};

export const createDateOptions = (
  predefinedDateDictionary:
    | SelectDateDictionary
    | DateRangeDictionary
    | undefined
) =>
  predefinedDateDictionary &&
  Object.keys(predefinedDateDictionary).map((o) => ({
    label: predefinedDateDictionary[o].label,
    value: o,
  }));

export type PredefinedDateOption = {
  label: string;
  value: string;
} | null;
export const CUSTOM_DATE_SELECT_OPTION: NonNullable<PredefinedDateOption> = {
  label: 'Custom',
  value: 'custom',
};

type initialisePredefinedDateOptionValueArgs = {
  predefinedDateSelectValue: PredefinedDateOption;
  setPredefinedDateSelectValue: (newOption: PredefinedDateOption) => void;
} & (
  | {
      dateValue: Date | undefined;
      predefinedDateDict: SelectDateDictionary;
      preDefinedSelectOptionsFinder: { dateMonth: Date | undefined };
    }
  | {
      dateValue: DateRangeValue | undefined;
      predefinedDateDict: DateRangeDictionary;
      preDefinedSelectOptionsFinder: Partial<DateRangeValue>; // the dateValue could be undefined
    }
);
export const initialisePredefinedDateOptionValue = ({
  dateValue,
  predefinedDateDict,
  preDefinedSelectOptionsFinder,
  predefinedDateSelectValue,
  setPredefinedDateSelectValue,
}: initialisePredefinedDateOptionValueArgs) => {
  if (!dateValue && !predefinedDateSelectValue) {
    return;
  }

  // when the date value is cleared, clear the select value as well
  if (!dateValue && predefinedDateSelectValue) {
    setPredefinedDateSelectValue(null);
    return;
  }

  const predefinedOptionKey = findKey(
    predefinedDateDict,
    preDefinedSelectOptionsFinder
  );
  // set to custom when the date value in the filterStore isn't one of the predefined options
  if (
    !predefinedOptionKey &&
    !isEqual(predefinedDateSelectValue, CUSTOM_DATE_SELECT_OPTION)
  ) {
    setPredefinedDateSelectValue(CUSTOM_DATE_SELECT_OPTION);
    return;
  }

  // set the select value to the date value in the filterStore if they don't match
  const newPredefinedSelectValue = find(createDateOptions(predefinedDateDict), {
    value: predefinedOptionKey,
  });
  if (
    newPredefinedSelectValue &&
    // changing from custom select option closes the custom calendar(s)
    !isEqual(predefinedDateSelectValue, CUSTOM_DATE_SELECT_OPTION) &&
    !isEqual(newPredefinedSelectValue, predefinedDateSelectValue)
  ) {
    setPredefinedDateSelectValue(newPredefinedSelectValue);
    return;
  }
};

import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

export const RevelioTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <ChakraTooltip
      hasArrow
      label="AI Search"
      variant="label"
      maxWidth="170px"
      fontSize="xs"
      lineHeight="15px"
      openDelay={400}
      p={2}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};

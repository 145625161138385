import { D3ChartNames } from '@revelio/d3';
import { METRIC_MODE_IDS } from '@revelio/data-access';
import { FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard, PlotCardProps } from '../../../shared/components';
import { convertLineToSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { downloadData } from '../../../shared/components/plot-card/download-plot-data';
import { PostingsHeaderSelect } from './postings-header-select';

export interface MainPostingsLineChartCardProps extends PlotCardProps {
  data: LineData[];
  metricMode: string;
  loading?: boolean;
}

export const MainPostingsLineChartCard = ({
  data,
  metricMode,
  loading,
  title,
  info,
  infoPlacement,
  menu,
}: MainPostingsLineChartCardProps) => {
  const isExpectedHiresPerPosting =
    metricMode === METRIC_MODE_IDS.expectedHiresPerPosting;

  const format = isExpectedHiresPerPosting
    ? FormatType.DECIMAL
    : FormatType.INTEGER;

  return (
    <PlotCard
      title={title}
      info={info}
      infoPlacement={infoPlacement}
      topRight={!isExpectedHiresPerPosting && <PostingsHeaderSelect />}
      menu={menu}
      onExport={() => {
        const exportData = convertLineToSheet({ data });
        downloadData(exportData, 'Postings');
      }}
      plotShare={{
        data: data,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: {
            format: format,
            dateFormat: 'day',
          },
        },
      }}
    >
      <LineChart
        data={data}
        showGridLines={true}
        format={format}
        dateFormat="day"
        loading={loading}
      />
    </PlotCard>
  );
};

import { Views } from '@revelio/core';

import {
  DateValSelectFilter,
  ISelectFilter,
  OPSelectFilter,
  OPValues,
  SelectionCategories,
} from '../../../engine/filters.model';

const isScreenerView = (view: Views) => view === Views.SCREENER;

const isBetweenOp = (opValue: OPSelectFilter) =>
  opValue?.filterName === OPValues.BETWEEN;

const isInvalidRange = (
  startValue: number | undefined,
  endValue: number | undefined
) =>
  (startValue !== undefined &&
    endValue !== undefined &&
    startValue > endValue) ||
  startValue === undefined ||
  endValue === undefined;

const isScreenerFilter = (filterName: SelectionCategories) =>
  ![
    SelectionCategories.INDUSTRY,
    SelectionCategories.REGION,
    SelectionCategories.JOB_CATEGORY,
  ].includes(filterName);

interface IsSubmitDisabledProps {
  selectValue: ISelectFilter | null | undefined;
  dateRangeError: string;
  view: Views;
  dateValue: DateValSelectFilter;
  opValue: OPSelectFilter;
  startValue: number | undefined;
  endValue: number | undefined;
  submitDisabled: boolean;
}

export const isSubmitDisabled = ({
  selectValue,
  dateRangeError,
  view,
  dateValue,
  opValue,
  startValue,
  endValue,
  submitDisabled,
}: IsSubmitDisabledProps) => {
  return (
    submitDisabled ||
    selectValue === undefined ||
    !!dateRangeError ||
    (isScreenerView(view) &&
      isScreenerFilter(
        (selectValue as ISelectFilter).filterName as SelectionCategories
      ) &&
      isBetweenOp(opValue) &&
      isInvalidRange(startValue, endValue))
  );
};

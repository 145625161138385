// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { withResizeDetector } from 'react-resize-detector';

import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { StackedBarChartHorizontalGenerator } from '../plot-generators';

const StackedBarChartHorizontal = (plotConfigs, downloadOptions) => {
  const {
    name,
    data,
    height,
    width,
    targetRef,
    chartSize,
    requestHash,
    setChartHasRendered,
  } = plotConfigs;

  const boxRef = useRef(null);

  const setHorizontalBarChartHasRendered = setChartHasRendered
    ? (hasRendered) => setChartHasRendered(name, hasRendered)
    : undefined;

  useD3(
    StackedBarChartHorizontalGenerator,
    {
      ...plotConfigs,
      boxRef,
      setChartHasRendered: setHorizontalBarChartHasRendered,
    },
    [targetRef.current, width, height, data, boxRef.current, requestHash]
  );

  return (
    <>
      <div
        ref={targetRef}
        className={`react-node-${name}`}
        style={{
          position: 'relative',
          display: 'grid',
          height: '100%',
        }}
      />
      <div
        ref={boxRef}
        className="box-container"
        style={{
          height: chartSize === 'large' ? 'fit-content' : null,
        }}
      />
    </>
  );
};

StackedBarChartHorizontal.propTypes = {
  name: PropTypes.string.isRequired,
  chartPosition: PropTypes.string,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  chartStyle: PropTypes.string,
  hideLegend: PropTypes.bool,
  data: PropTypes.array.isRequired,
  chartSize: PropTypes.string,
};

StackedBarChartHorizontal.defaultProps = {
  name: 'default',
  chartPosition: '',
  ttMainFormat: '.0%',
  ttSecondaryFormat: ',',
  chartStyle: '-',
  hideLegend: false,
  data: [],
};

export default withResizeDetector(StackedBarChartHorizontal, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});

import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { OptionProps, Props, SingleValue, components } from 'react-select';

import { useGetLoggedInUser } from '@revelio/auth';
import { Views } from '@revelio/core';

import { getActiveTabMeta } from '../../../../engine/filters.core';
import { setSearchParamFiltersPure } from '../../../../engine/filters.deepLinks';
import {
  ALL_DATE_FILTER_IDS,
  ALL_SUPPORTED_FILTERS,
} from '../../../../engine/filters.deepLinks.model';
import {
  filterStore,
  useSelectionLists,
} from '../../../../engine/filters.engine';
import { SelectionListIdNames } from '../../../../engine/filters.model';
import { SelectionListControls } from '../../../SelectionListControls';
import {
  Option,
  SelectionListSelect,
} from '../../../selection-list/selection-list-select';
import { useNodeModalState } from '../../node-modal-state.context';
import NodeEditModal from './node-edit-modal';
import { SavedSetOption } from './saved-set-option';
import { useSavedSetsSelectOptions } from './saved-sets-selection-list.context';
import { useSavedSetOptions } from './use-saved-set-options';

export type SavedSetsNestedSelectionListProps = {
  close: () => void;
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
};

export const SavedSetsNestedSelectionList = ({
  close,
  onSubmitSavedFilterSet,
}: SavedSetsNestedSelectionListProps) => {
  const [value, setValue] = useState<SingleValue<Option>>();
  const [disabledOptionsExpanded, setDisabledOptionsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { enabledOptions, disabledOptions } = useSavedSetOptions({
    searchTerm,
  });
  const { loggedInUser } = useGetLoggedInUser();
  const tabMeta = filterStore.query(getActiveTabMeta);
  const selectionLists = useSelectionLists([
    ...ALL_SUPPORTED_FILTERS,
    ...(ALL_DATE_FILTER_IDS as SelectionListIdNames[]),
  ]);

  const clearSelections = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  const onSubmitSavedSet = () => {
    if (onSubmitSavedFilterSet) {
      onSubmitSavedFilterSet(value);
      close();
      return;
    }

    setSearchParamFiltersPure({
      searchParamFilters: new URLSearchParams(
        value?.entity.filters.split('?')[1]
      ),
      view: tabMeta.view as Views,
      loggedInUser,
      primaryFilters: tabMeta.primaryFilters,
      selectionLists,
      replaceCurrentFilters: true,
    });
    close();
  };

  const onChange = useCallback(
    (selectedOptions: SingleValue<Option>) => {
      setValue((prev) => {
        if (get(prev, 'value') === get(selectedOptions, 'value')) {
          return undefined;
        }

        return selectedOptions;
      });
    },
    [setValue]
  );

  const showOtherPagesFilterSets = (disabledOptions?.length ?? 0) > 0;

  const SHARED_SELECT_PROPS: Props<Option, false> = {
    value: value,
    onChange,
    components: {
      Control: () => null,
      DropdownIndicator: (props) => (
        <components.DropdownIndicator {...props}>
          <SearchIcon />
        </components.DropdownIndicator>
      ),
      Option: (props: OptionProps<Option, false>) => (
        <SavedSetOption {...props} />
      ),
    },
  };

  const { selectedOption, clearSelectedOption } = useSavedSetsSelectOptions();
  const { nodeModalOpen, setNodeModalOpen } = useNodeModalState();
  return (
    <Box>
      <NodeEditModal
        node={selectedOption}
        isOpen={nodeModalOpen}
        onClose={function (): void {
          clearSelectedOption();
          setNodeModalOpen(false);
        }}
      />

      <InputGroup mb={4} size="sm">
        <Input
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <InputRightElement pointerEvents="none">
          <SearchIcon color="gray.400" />
        </InputRightElement>
      </InputGroup>
      {(enabledOptions?.length || 0) > 0 ? (
        <SelectionListSelect<false>
          {...SHARED_SELECT_PROPS}
          options={enabledOptions}
          maxMenuHeight={215}
        />
      ) : (
        <Text color="gray.500" mb={2}>
          No compatible sets found.
        </Text>
      )}
      {showOtherPagesFilterSets && (
        <Box mb={4}>
          <Flex
            color="navyBlue.500"
            align="center"
            cursor="pointer"
            onClick={() => {
              setDisabledOptionsExpanded((prev) => !prev);
            }}
            mb={2}
          >
            <Box mr={1}>
              <ChevronDownIcon
                fontSize={24}
                transform={
                  disabledOptionsExpanded ? undefined : 'rotate(-90deg)'
                }
              />
            </Box>
            <Text fontSize={12}>Filter Sets from other pages</Text>
          </Flex>
          <Box ml={6}>
            {disabledOptionsExpanded && (
              <SelectionListSelect<false>
                {...SHARED_SELECT_PROPS}
                options={disabledOptions}
                maxMenuHeight={120}
              />
            )}
          </Box>
        </Box>
      )}

      <SelectionListControls
        onClear={clearSelections}
        onSubmit={onSubmitSavedSet}
        onClose={close}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HTMLChakraProps,
  IconButton,
  Textarea,
} from '@chakra-ui/react';
import { noop } from 'lodash';
import LZString from 'lz-string';
import { useEffect, useRef, useState } from 'react';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { useLocation } from 'react-router';
import { useClient } from 'urql';

import { getAuthDashMetaCsrfToken } from '@revelio/auth';
import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';
import {
  ALL_SUPPORTED_FILTERS,
  LocalSelectionCategories,
  SelectionCategories,
  useSelectionLists,
} from '@revelio/filtering';

import { loadAllTalentDiscoveryStateFromUrl } from '../../../useSyncTalentDiscoveryFIltersWithUrl';
import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import {
  aiFilterSearchFeedbackRequest,
  aiFilterSearchRequest,
} from './ai-filter-search-api-request';
import styles from './ai-filter-search.module.css';
import { deserialiseApiToFilterState } from './deserialise-api-response/deserialise-api-response';
import { ResponseHighlightedOverlay } from './response-highlighted-overlay';
import SpeechInput from './speech-input';

export const AiFilterSearch = ({ closeMenu }: { closeMenu: () => void }) => {
  const {
    aiSearchState,
    setAiSearchState,
    isAiSearchLoading,
    setIsAiSearchLoading,
    abortControllerRef,
  } = useTalentDiscoveryFilter();
  const csrfToken = getAuthDashMetaCsrfToken();
  const location = useLocation();
  const { dispatch, setAiGeneratedFilterIds, state, aiGeneratedFilterIds } =
    useTalentDiscoveryFilter();
  const gqlClient = useClient();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const selectionLists = useSelectionLists([
    ...ALL_SUPPORTED_FILTERS,
    SelectionCategories.RICS_K400,
    LocalSelectionCategories.FLIGHT_RISK,
    LocalSelectionCategories.PRESTIGE,
    LocalSelectionCategories.REMOTE_SUITABILITY,
  ]);

  const handleTranscriptComplete = (transcript: string) => {
    setAiSearchState((prevState) => ({
      ...prevState,
      prompt: prevState.prompt
        ? `${prevState.prompt} ${transcript}`
        : transcript,
      response: null,
      uuid: '',
    }));
  };

  const handleSubmit = async () => {
    abortControllerRef.current = new AbortController();

    try {
      setIsAiSearchLoading(true);
      const { response, uuid } = await aiFilterSearchRequest({
        prompt: aiSearchState.prompt,
        csrfToken: csrfToken || '',
        signal: abortControllerRef.current.signal,
      });

      const { filterState: talentDiscoveryFilters, unknownFilters } =
        await deserialiseApiToFilterState(response, selectionLists, gqlClient);

      setAiGeneratedFilterIds(
        talentDiscoveryFilters.map((filter) => filter.id)
      );

      const stringifiedFilterState = JSON.stringify(talentDiscoveryFilters);

      const deeplink = `?filters=${LZString.compressToEncodedURIComponent(
        stringifiedFilterState
      )}`;

      if (deeplink !== location.search) {
        loadAllTalentDiscoveryStateFromUrl(
          dispatch,
          noop, // TODO: currently no support for columns in ai filter search
          new URLSearchParams(deeplink)
        );
      }

      setAiSearchState((prevState) => ({
        ...prevState,
        uuid,
        isCorrect: null,
        response: {
          ...response,
          unknownFilters: [
            ...(response.unknownFilters || []),
            ...unknownFilters,
          ],
        },
      }));
    } catch (error) {
      // Only log error if it's not an abort error
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error(error);
      }
    } finally {
      setIsAiSearchLoading(false);
      abortControllerRef.current = undefined;
    }
  };

  const handleIsAiSearchCorrect = ({ isCorrect }: { isCorrect: boolean }) => {
    if (isCorrect === aiSearchState.isCorrect) {
      return;
    }

    aiFilterSearchFeedbackRequest({
      uuid: aiSearchState.uuid,
      isCorrect,
      csrfToken: csrfToken || '',
    });
    setAiSearchState((prevState) => ({
      ...prevState,
      isCorrect,
    }));
  };

  const areAiFiltersUntouched =
    state.filters?.every((filter) =>
      aiGeneratedFilterIds.includes(filter.id)
    ) &&
    aiGeneratedFilterIds.every((id) =>
      state.filters?.some((filter) => filter.id === id)
    );

  const showingHighlighedOverlay =
    aiSearchState.response && !isFocused && areAiFiltersUntouched;

  useEffect(() => {
    if (!showingHighlighedOverlay && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [showingHighlighedOverlay]);

  return (
    <>
      <Box position="relative">
        <Textarea
          spellCheck={!showingHighlighedOverlay} // clashes with highlighted overlay underlining
          ref={textAreaRef}
          value={aiSearchState.prompt}
          isDisabled={isAiSearchLoading}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => {
            setAiSearchState((prevState) => ({
              ...prevState,
              prompt: e.target.value,
              response: null,
              uuid: '',
            }));
          }}
          placeholder="e.g. 'Find me women in NY that work at Google from the top 5 universities'"
          height="65px"
          flex={1}
          colorScheme="green"
          width="475px"
          resize="none"
          className={styles.aiFilterTextarea}
          padding="8px 6px"
          style={{
            color: showingHighlighedOverlay ? 'transparent' : '#2D426A',
            caretColor: '#2D426A',
          }}
          {...COMMON_AI_TEXT_STYLES}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <Box position="absolute" right="1px" bottom="1px" zIndex={1000}>
          <SpeechInput
            onTranscriptComplete={handleTranscriptComplete}
            onEnter={handleSubmit}
            onStartListening={() => {
              setAiSearchState((prevState) => ({
                ...prevState,
                prompt: '',
                response: null,
                uuid: '',
              }));
            }}
            textAreaRef={textAreaRef}
          />
        </Box>
        {showingHighlighedOverlay && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            overflow="auto"
            padding="9px 7px"
            {...COMMON_AI_TEXT_STYLES}
          >
            <ResponseHighlightedOverlay
              prompt={aiSearchState.prompt}
              response={
                aiSearchState.response as TalentDiscoveryAiFilterSearchResponse
              }
              textAreaRef={textAreaRef}
            />
          </Box>
        )}
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {aiSearchState.uuid && (
            <>
              <IconButton
                aria-label="Thumbs up"
                icon={<FiThumbsUp />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: true,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={
                  aiSearchState.isCorrect === true ? 'green' : 'gray'
                }
              />
              <IconButton
                aria-label="Thumbs down"
                icon={<FiThumbsDown />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: false,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={aiSearchState.isCorrect === false ? 'red' : 'gray'}
              />
            </>
          )}
        </Flex>
        <ButtonGroup spacing={4}>
          <Button
            variant="link"
            size="sm"
            fontSize="xs"
            fontWeight={600}
            colorScheme="gray"
            onClick={closeMenu}
          >
            Cancel
          </Button>
          <Button
            data-testid="filter-popover-submit"
            isDisabled={!aiSearchState.prompt}
            colorScheme="green"
            variant="solid"
            size="sm"
            fontSize="xs"
            onClick={handleSubmit}
          >
            Search
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};

const COMMON_AI_TEXT_STYLES: HTMLChakraProps<'div'> &
  HTMLChakraProps<'textarea'> = {
  fontSize: '14px',
  lineHeight: '17px',
};

import { Box } from '@chakra-ui/layout';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useDrop } from 'react-dnd';

import { Subsidiary } from '@revelio/core';

import { ItemTypes } from './draggable-item-wrapper';

interface DropTargetProps {
  onDrop?: (item: Subsidiary) => void; //or eventually any other item type
  verifyDrop?: (item: Subsidiary) => boolean;
  children: ReactNode;
  id?: string | number;
  longDropProps?: longDropProps;
}

interface longDropProps {
  isLongDrop?: boolean;
  hoveredItem?: string | null;
  canDrop?: boolean;
  startLongDropTimer: () => void;
  stopLongDropTimer: () => void;
  setHoveredItem: Dispatch<SetStateAction<string | null>>;
  setCanDrop: Dispatch<SetStateAction<boolean>>;
  onLongDrop: (item: Subsidiary, columnItem: string) => void;
  verifyLongDrop: (item: Subsidiary, columItem: string) => boolean;
}

const DropTarget = ({
  onDrop = () => null,
  children,
  id,
  verifyDrop = () => false,
  longDropProps,
}: DropTargetProps) => {
  const {
    isLongDrop,
    stopLongDropTimer = () => null,
    setHoveredItem = () => null,
    hoveredItem,
    onLongDrop = () => null,
    verifyLongDrop = () => false,
  } = longDropProps || {};
  const [{ canDrop }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop: (item: Subsidiary, monitor) => {
      if (!monitor.didDrop()) {
        if (isLongDrop && onLongDrop && hoveredItem) {
          onLongDrop(item, hoveredItem);
        } else {
          onDrop(item);
        }
      }
      stopLongDropTimer();
      setHoveredItem(null);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item, monitor) => {
      if (isLongDrop && typeof verifyLongDrop === 'function' && hoveredItem) {
        return verifyLongDrop(item, hoveredItem);
      } else {
        return verifyDrop(item);
      }
    },
    hover: (_, monitor) => {
      const clientOffset = monitor.getClientOffset(); // Get the client offset of the current hover position
      if (clientOffset) {
        const hoveredElement = document.elementFromPoint(
          clientOffset.x,
          clientOffset.y
        );
        const hoveredItemId =
          hoveredElement?.getAttribute('column-item') || null;
        setHoveredItem(hoveredItemId);
      } else {
        setHoveredItem(null);
      }
    },
  });

  return (
    <Box
      ref={drop}
      height="100%"
      borderRadius="6px"
      borderColor="lightBlue.300"
      borderWidth={isLongDrop && id === hoveredItem && canDrop ? '2px' : '0'}
    >
      {children}
    </Box>
  );
};

export default DropTarget;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  max,
  min,
  range,
  scaleBand,
  scaleLinear,
  scaleTime,
  select,
  selectAll,
} from 'd3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-ignore */
import { axisBottom, axisLeft } from 'd3-axis';
import { format as d3Format } from 'd3-format';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-ignore */
import { area, curveCatmullRom, line } from 'd3-shape';

import '../../d3-styles.scss';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { appendTitle } from '../../utilities/append-title';
import { appendWatermark } from '../../utilities/append-watermark';
import { colors } from '../../utilities/colors';
import { roundedRect } from '../../utilities/corner-radius';
import { weeklyDatesSorted } from '../../utilities/date-check-weekly';
import { dayDiff, monthDiff, yearDiff } from '../../utilities/date-helpers';
import { generateInlineStyles } from '../../utilities/generate-inline-styles';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { MainPostingData } from './types';

type MainPostingsPlotConfig = PlotConfig<MainPostingData[]>;

export const MainPostingsPlotGenerator = (
  plotConfigs: MainPostingsPlotConfig,
  downloadOptions: DownloadOptions
): SVGSVGElement | null | void => {
  const { data, targetRef, requestHash, isRenderingOrLoading, customMargins } =
    plotConfigs;

  let { name, height, width } = plotConfigs;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltipAdded-${name}`).remove();
    select(`.tooltipActive-${name}`).remove();
    select(`.tooltipRemoved-${name}`).remove();
    // setup margins and inner dims
    dims.margin = {
      top: 40,
      left: 48,
      bottom: 20,
      right: 10,
    };

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);
    // setup svg node

    const node = targetRef?.current;
    const svg: any = node
      ? select(node).append('svg')
      : select(containerId).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //=============================================================================

    // NO EXTRA PROPS REQUIRED

    // TRANSFORMS MAIN POSTINGS DATA TO TEST COMPATIBILITY WITH LINE CHART CODE JUST IN CASE - DELETE AFTER POSTINGS IMPLEMENTATION
    // var data1 = [];
    // data.forEach((company) => {
    //   var companyValue = [];
    //   company.value.forEach((val) => {
    //     companyValue.push({
    //       id: val.id,
    //       value: val.value.filter((d) => d.id === 1)[0].value,
    //       metadata: val.metadata,
    //     });
    //   });
    //   data1.push({
    //     id: company.id,
    //     value: companyValue,
    //     metadata: company.metadata,
    //   });
    // });

    // var compStyles = getComputedStyle(targetRef.current);
    // const paddingLeft = parseFloat(compStyles.getPropertyValue('padding-left'));
    // const paddingTop = parseFloat(compStyles.getPropertyValue('padding-top'));

    const companyData = data[0]; //extract the company from the array

    if (companyData.value.length > 0) {
      // dates for x scales
      const allDates = companyData.value
        .map(
          (d) => new Date(d.metadata.year, d.metadata.month - 1, d.metadata.day)
        )
        .flat();
      // check for missing dates
      const allDatesSorted: any = weeklyDatesSorted(allDates);

      // bar chart scale
      const xScaleBar = (scaleBand() as any)
        .domain(allDatesSorted) // each date we have data for
        .range([dims.innerWidth, 0])
        .padding([0.2]);

      // area chart scale
      const xScaleLine = (scaleTime() as any)
        .domain([min(allDatesSorted), max(allDatesSorted)])
        .range([0, dims.innerWidth]);

      // values needed to determine y axis range: active, added and -(removed) values
      const activeAddedValues = companyData.value
        .map((val) => val.value)
        .flat()
        .filter((d) => d.id !== 3)
        .map((d) => d.value);
      const allValues = activeAddedValues.concat(
        companyData.value
          .map((val) => val.value)
          .flat()
          .filter((d) => d.id === 3)
          .map((d: any) => -d.value)
      );

      const yScale = (scaleLinear() as any)
        .domain([min(allValues as any), max(allValues as any)])
        .range([dims.innerHeight, 0]);
      //.nice();

      // filter for active postings data
      const activeData = companyData.value.map((d) =>
        [
          d.metadata.year,
          d.metadata.month,
          d.metadata.day,
          d.value.filter((d2) => d2.id === 1).map((d) => d.value),
        ].flat()
      );
      // filter for added postings data
      const addedData = companyData.value.map((d) =>
        [
          d.metadata.year,
          d.metadata.month,
          d.metadata.day,
          d.value.filter((d2) => d2.id === 2).map((d) => d.value),
        ].flat()
      );
      // filter for removed postings data
      const removedData = companyData.value.map((d) =>
        [
          d.metadata.year,
          d.metadata.month,
          d.metadata.day,
          d.value.filter((d2) => d2.id === 3).map((d: any) => -d.value),
        ].flat()
      );

      // Add tooltips
      const tooltipAddedWidth = 150;
      const tooltipRemovedWidth = 165;
      const tooltipActiveWidth = 150;

      const tooltipAdded = select(`.react-node-${name}`)
        .append('div')
        .style('width', tooltipAddedWidth + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .style('color', '#ffffff')
        .classed('tooltip-added-main-postings-chart', true)
        .classed(`tooltipAdded-${name}`, true)
        .attr('data-testid', 'main-postings-chart-tooltip');
      const tooltipRemoved = select(`.react-node-${name}`)
        .append('div')
        .style('width', tooltipRemovedWidth + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .classed('tooltip-removed-main-postings-chart', true)
        .classed(`tooltipRemoved-${name}`, true)
        .attr('data-testid', 'main-postings-chart-tooltip');
      const tooltipActive = select(`.react-node-${name}`)
        .append('div')
        .style('width', tooltipActiveWidth + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .classed('tooltip-line-chart', true)
        .classed(`tooltipActive-${name}`, true)
        .attr('data-testid', 'main-postings-chart-tooltip');

      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      // Show added/removed tooltips when hovering over added/removed bars
      const mouseOverAdded = (event: any, d: any) => {
        const txt = `<span class="linechart-title">${months[d[1] - 1]} ${
          d[2]
        }, ${d[0]}</br>
        <span style="font-weight: 200">${d3Format(',')(
          d[3]
        )} new postings</span></span>`;
        tooltipAdded
          .style('opacity', 1)
          .style('display', null)
          .style(
            'transform',
            `translate(${
              dims.margin.left +
              xScaleBar(new Date(d[0], d[1] - 1, d[2])) +
              xScaleBar.bandwidth() / 2 -
              tooltipAddedWidth / 2
            }px, ${dims.margin.top + yScale(d[3]) - 70}px)`
          )
          .html(txt);

        // reduce opacity of added bars not being hovered over
        selectAll('.added-bar')
          .filter(
            (bar: any) =>
              !(bar[0] === d[0] && bar[1] === d[1] && bar[2] === d[2])
          )
          .style('opacity', 0.5);
      };

      const mouseOverRemoved = (event: any, d: any) => {
        const txt = `<span class="linechart-title">${months[d[1] - 1]} ${
          d[2]
        }, ${d[0]}</br>
        <span style="font-weight: 200">${d3Format(',')(
          -d[3]
        )} removed postings</span></span>`;
        tooltipRemoved
          .style('opacity', 1)
          .style('display', null)
          .style(
            'transform',
            `translate(${
              dims.margin.left +
              xScaleBar(new Date(d[0], d[1] - 1, d[2])) +
              xScaleBar.bandwidth() / 2 -
              tooltipRemovedWidth / 2
            }px, ${dims.margin.top + yScale(d[3]) + 8}px)`
          )
          .html(txt);

        // reduce opacity of added bars not being hovered over
        selectAll('.removed-bar')
          .filter(
            (bar: any) =>
              !(bar[0] === d[0] && bar[1] === d[1] && bar[2] === d[2])
          )
          .style('opacity', 0.5);
      };

      // Show active tooltip when hovering over area under line chart
      const mouseMove = (event: any) => {
        const hoveredMonth =
          xScaleLine.invert(event.offsetX - dims.margin.left).getMonth() + 1; //our jan = 1
        const hoveredYear = xScaleLine
          .invert(event.offsetX - dims.margin.left)
          .getFullYear();
        const hoveredDay = xScaleLine
          .invert(event.offsetX - dims.margin.left)
          .getDate();

        // since we don't have daily data, find the date closest to the hovered date for which we do have data
        const closestDate = allDatesSorted.find(
          (date: any) =>
            Math.abs(
              (new Date(hoveredYear, hoveredMonth - 1, hoveredDay) as any) -
                date
            ) <=
            3.1 * 1000 * 60 * 60 * 24 // 3 days (half a week); 3.1 accounts for daylight savings
        );
        // change our lookup values to the closest date for display in the tooltip
        const day = closestDate.getDate();
        const month = closestDate.getMonth() + 1;
        const year = closestDate.getFullYear();

        const dataFiltered = companyData.value.filter(
          (d) =>
            d.metadata.year === year &&
            d.metadata.month === month &&
            d.metadata.day === day
        );

        let valueFiltered: any;
        if (dataFiltered[0]) {
          valueFiltered = dataFiltered[0].value
            .filter((d) => d.id === 1)
            .map((d) => d.value); //active postings value
        } else {
          valueFiltered = 0;
        }

        const txt = `<span class="linechart-title">${
          months[month - 1]
        } ${day}, ${year}</br>
        <span style="font-weight: 200">${d3Format(',')(
          valueFiltered
        )} active postings</span></span>`;
        tooltipActive
          .style('opacity', 1)
          .style('display', null)
          .style(
            'transform',
            `translate(${
              event.offsetX + 10 //approx tt arrow length
            }px, ${yScale(valueFiltered) + dims.margin.top - 30}px)`
          )
          .html(txt);

        tooltipActiveLine
          .style('opacity', 1)
          .style('display', null)
          .style(
            'transform',
            `translate(${event.offsetX - dims.margin.left}px, ${0}px)`
          );

        // switch orientation of tooltip if past midpoint of chart
        if (event.offsetX - dims.margin.left > dims.innerWidth / 2) {
          tooltipActive.classed('tooltip-line-chart-arrow-right', true);
          tooltipActive.classed('tooltip-line-chart-arrow-left', false);
          tooltipActive.style(
            'transform',
            `translate(${event.offsetX - tooltipActiveWidth - 10}px, ${
              yScale(valueFiltered) + dims.margin.top - 30
            }px)`
          );
        } else {
          tooltipActive.classed('tooltip-line-chart-arrow-left', true);
          tooltipActive.classed('tooltip-line-chart-arrow-right', false);
        }
      };

      // Remove tooltips
      const mouseOut = () => {
        tooltipAdded.style('opacity', 0).style('display', 'none');
        tooltipRemoved.style('opacity', 0).style('display', 'none');
        selectAll('.added-bar').style('opacity', 1);
        selectAll('.removed-bar').style('opacity', 1);
      };
      const mouseOutActive = () => {
        tooltipActive.style('opacity', 0).style('display', 'none');
        tooltipActiveLine.style('opacity', 0).style('display', 'none');
      };

      // set up the area chart gradient
      chart
        .append('linearGradient')
        .attr('id', 'color-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', yScale(0))
        .attr('x2', 0)
        .attr('y2', yScale(max(allValues as any)))
        .selectAll('stop')
        .data([
          { offset: '0%', color: 'white' },
          { offset: '100%', color: `${colors[0]}80` },
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d: any) {
          return d.offset;
        })
        .attr('stop-color', function (d: any) {
          return d.color;
        });

      // add the area chart, referring to the color gradient by id
      chart
        .datum(activeData)
        .append('path')
        .attr('class', 'area')
        .attr('fill', 'url(#color-gradient)')
        .attr(
          'd',
          area()
            .curve(curveCatmullRom)
            .x((d: any) => xScaleLine(new Date(d[0], d[1] - 1, d[2])))
            .y0(yScale(0))
            .y1((d: any) => yScale(d[3]))
        )
        .attr('cursor', 'pointer')
        .on('mousemove', mouseMove)
        .on('mouseout', mouseOutActive);

      const yAxisGrid = axisLeft()
        .scale(yScale)
        .tickSize(-dims.innerWidth)
        .tickFormat('')
        .ticks(3);

      // determine x axis labels:
      // get min and max of allDatesSorted and determine how many months are between them
      // for small line chart we want 3 dates; for medium line chart we want 5 dates - evenly spaced between the min & max
      // the values we take will be the 1st of the month for each of these dates
      // formatting will depend on the length of time between the min & max (<=2 months = days; >2 months & <= 2 years = month + year; >2 years = years)

      const monthsDiff = monthDiff(
        min(allDatesSorted) as any,
        max(allDatesSorted) as any
      ); // difference between min & max date in months
      const minDate: any = min(allDatesSorted);

      let xAxisTickLabels: any;
      let xAxisTickFormat: any;
      if (monthsDiff > 36) {
        // if date range > 2 years, we will show tick labels in year format
        const yearsDiff = yearDiff(
          min(allDatesSorted) as any,
          max(allDatesSorted) as any
        );
        // if month of minDate is not Jan, don't show that tick label
        let yearIndexes: any;
        if ((minDate as any).getMonth() !== 0) {
          yearIndexes = range(1, yearsDiff + 1); // each year between min & max, excluding min (since min year is not Jan)
        } else {
          yearIndexes = range(0, yearsDiff + 1); // each year between min & max, inclusive
        }
        if (yearIndexes.length > 5) {
          // for >5 years of data, we will show every other year on the x axis
          // show even/odd years (by year index not year value) depending on first month of data & # of years of data
          if (
            (minDate.getMonth() === 0 || minDate.getMonth() > 8) &&
            yearIndexes.length % 2 === 0
          ) {
            yearIndexes = yearIndexes.filter((d: any, i: any) => i % 2 !== 0);
          } else {
            yearIndexes = yearIndexes.filter((d: any, i: any) => i % 2 === 0);
          }
        }
        // map each year index to the min date + the # of years each index represents
        xAxisTickLabels = yearIndexes.map(function (i: any) {
          const newDate = new Date(minDate.getFullYear(), 0); //set month to jan - when showing year on x axis, we always want to show beginning of that year
          newDate.setFullYear(newDate.getFullYear() + i);
          return newDate;
        });
        // if (chartSize === 'small') {
        //   xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
        // }
      } else if (monthsDiff <= 36 && monthsDiff >= 2) {
        // if date range is between 2 months and 2 years, we will show tick labels in the format 'Feb 2021' etc
        const monthIndexes = range(0, monthsDiff + 1);
        // map each month index to the min date + the # of months each index represents
        xAxisTickLabels = monthIndexes.map(function (i) {
          const newDate = new Date(minDate.getFullYear(), minDate.getMonth());
          newDate.setMonth(newDate.getMonth() + i);
          return newDate;
        });
        // for >6 months of data, we will show every sixth month on the x axis
        if (monthIndexes.length > 6) {
          xAxisTickLabels = xAxisTickLabels.filter((d: any, i: any) =>
            monthIndexes.length < 10 ? i % 6 === 0 : i % 6 === 3
          ); //use modulus 3 to evenly space out the ticks (instead of always having the first tick & ticks being skewed to beginning of x axis)
        }
        // if (chartSize === 'small') {
        //   xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
        // }
        xAxisTickFormat = xAxisTickLabels.map(
          (date: any) => `${months[date.getMonth()]} ${date.getFullYear()}`
        );
      } else {
        // if date range < 2 months, we will show tick labels in the format 'Feb 4 2021'
        const daysDiff = dayDiff(
          min(allDatesSorted) as any,
          max(allDatesSorted) as any
        );
        const dayIndexes = range(0, daysDiff + 1);
        // map each day index to the min date + the # of days each index represents
        xAxisTickLabels = dayIndexes.map(function (i) {
          const newDate = new Date(
            minDate.getFullYear(),
            minDate.getMonth(),
            minDate.getDate()
          );
          newDate.setDate(newDate.getDate() + i);
          return newDate;
        });
        // for >7 days of data, we will show every 7th day on the x axis
        if (dayIndexes.length > 6) {
          xAxisTickLabels = xAxisTickLabels.filter((d: any, i: any) =>
            dayIndexes.length < 11 ? i % 6 === 0 : i % 7 === 3
          ); //use modulus 3 to evenly space out the ticks (instead of always having the first tick & ticks being skewed to beginning of x axis)
        }
        // if (chartSize === 'small') {
        //   xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
        // }
        xAxisTickFormat = xAxisTickLabels.map(
          (date: any) =>
            `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`
        );
      }

      const xAxisBottom = axisBottom()
        .scale(xScaleLine)
        //.ticks(2)
        .tickValues(xAxisTickLabels);
      if (xAxisTickFormat) {
        xAxisBottom.tickFormat((d: any, i: any) => xAxisTickFormat[i]);
      }

      const xAxisGrid = axisBottom()
        .scale(xScaleLine)
        .tickSize(-dims.innerHeight)
        .tickValues(xAxisTickLabels)
        .tickFormat('');

      // place gridlines above area chart but below line and bars
      // y gridlines
      chart
        .append('g')
        .attr('class', 'y axis-grid')
        .attr('transform', `translate(0,0)`)
        .on('mousemove', mouseMove)
        .on('mouseout', mouseOutActive)
        .call(yAxisGrid)
        .call((g: any) => g.selectAll('.domain').remove());

      // x gridlines
      chart
        .append('g')
        .attr('class', 'x axis-grid')
        .attr('transform', `translate(0,${dims.innerHeight})`)
        .on('mousemove', mouseMove)
        .on('mouseout', mouseOutActive)
        .call(xAxisGrid)
        .call((g: any) => g.selectAll('.domain').remove());

      // TODO: Pull these from stylesheet
      selectAll('.axis-grid line')
        .attr('stroke', '#e6e8ed')
        .attr('stroke-dasharray', '1,3')
        .attr('stroke-width', '1.3')
        .attr('stroke-linecap', 'round');

      // add the line for the area chart
      chart
        .datum(activeData)
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', colors[0])
        .attr('stroke-width', 2)
        .attr(
          'd',
          line()
            .curve(curveCatmullRom)
            .x((d: any) => xScaleLine(new Date(d[0], d[1] - 1, d[2])))
            .y((d: any) => yScale(d[3]))
        );

      // Add vertical line for the active tooltip to the chart over the area chart
      const tooltipActiveLine = chart
        .append('line')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .attr('y1', dims.innerHeight)
        .attr('x1', 0)
        .attr('y2', 0)
        .attr('x2', 0)
        .attr('stroke', 'black')
        .attr('stroke-width', 1.5)
        .attr('stroke-dasharray', 3, 1);

      // add bars for added & removed postings
      chart
        .selectAll('.added-bar')
        .data(addedData)
        .join('path')
        .attr('class', 'added-bar')
        // for rounded bars
        .attr('d', (d: any) => {
          return roundedRect(
            xScaleBar(new Date(d[0], d[1] - 1, d[2])),
            yScale(d[3]),
            xScaleBar.bandwidth(),
            yScale(0) - yScale(d[3]),
            [1, 1, 0, 0] //round top corners of bar
          );
        })
        // for non-rounded bars:
        // .attr('x', (d) => xScaleBar(new Date(d[0], d[1] - 1, d[2])))
        // .attr('y', (d) => yScale(d[3]))
        // .attr('width', xScaleBar.bandwidth())
        // .attr('height', (d) => yScale(0) - yScale(d[3]))
        .attr('fill', colors[1])
        .attr('cursor', 'pointer')
        .on('mouseover', mouseOverAdded)
        .on('mouseout', mouseOut);

      chart
        .selectAll('.removed-bar')
        .data(removedData)
        .join('path')
        .attr('class', 'removed-bar')
        // for rounded bars
        .attr('d', (d: any) => {
          return roundedRect(
            xScaleBar(new Date(d[0], d[1] - 1, d[2])),
            yScale(0),
            xScaleBar.bandwidth(),
            yScale(d[3]) - yScale(0),
            [0, 0, 1, 1] //round bottom corners of bar
          );
        })
        // for non-rounded bars:
        // .attr('x', (d) => xScaleBar(new Date(d[0], d[1] - 1, d[2])))
        // .attr('y', yScale(0))
        // .attr('width', xScaleBar.bandwidth())
        // .attr('height', (d) => yScale(d[3]) - yScale(0))
        .attr('fill', colors[5])
        .attr('cursor', 'pointer')
        .on('mouseover', mouseOverRemoved)
        .on('mouseout', mouseOut);

      const yAxisLeft = axisLeft().scale(yScale).ticks(3);

      chart
        .append('g')
        .attr('data-testid', 'plot-y-axis')
        .classed(`inline-style-target-${name}`, !!download)
        .classed('axis-label-main-postings', true)
        .call(yAxisLeft)
        .call((g: any) => g.selectAll('.domain').remove())
        .call((g: any) => g.selectAll('line').remove());

      chart
        .append('g')
        .attr('transform', `translate(0, ${dims.innerHeight})`)
        .attr('data-testid', 'plot-x-axis')
        .classed(`inline-style-target-${name}`, !!download)
        .classed('axis-label-main-postings', true)
        .call(xAxisBottom)
        .call((g: any) => g.selectAll('.domain').remove())
        .call((g: any) => g.selectAll('line').remove());

      if (!download) {
        notifyChartRenderComplete(chart, requestHash, () =>
          isRenderingOrLoading?.next(false)
        );
      }

      if (download) {
        generateInlineStyles(`.inline-style-target-${name}`);
      }

      if (title) {
        appendTitle(chart, title, dims, padding);
      }

      if (watermark) {
        appendWatermark(
          chart,
          watermark,
          dims.innerWidth + dims.margin.right,
          dims.innerHeight + dims.margin.bottom,
          padding
        );
      }

      if (getSVGNode) {
        return svg.node();
      }
    }
  }
};

import { FormLabel } from '@chakra-ui/form-control';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import {
  GroupBase,
  OptionBase,
  Select,
  SingleValue,
  createFilter,
} from 'chakra-react-select';
import { chain } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'urql';

import { useGetLoggedInUser } from '@revelio/auth';

import { AllUsersIdAndNameQuery } from './status.api';

export interface UserOption extends OptionBase {
  label: string;
  value: string;
  group: string;
  isGroupOption?: boolean;
  secondaryLabel?: string;
}

type GroupedUserOption = GroupBase<UserOption>;

interface NonNullUser {
  id: string;
  name: string;
  email?: string;
  client_name?: string;
}
// Custom filter to search by user name, client name, and email
const filterOption = createFilter<UserOption>({
  stringify: (option) =>
    `${option.data.group} ${option.data.label} ${option.data.secondaryLabel}`,
});

interface UserSelectorProps {
  selectedUserOption?: UserOption;
  setSelectedUserOption: Dispatch<SetStateAction<UserOption | undefined>>;
}

export const UserSelector = ({
  selectedUserOption,
  setSelectedUserOption,
}: UserSelectorProps) => {
  /** ================================
   * User Options
   ================================ */
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  // group users by client
  const groupedUserOptions: GroupedUserOption[] = useMemo(() => {
    const grouped = chain(userOptions)
      .groupBy('group')
      .map((value, key): GroupedUserOption => ({ label: key, options: value }))
      .value();

    /** since we also want to select client (group), add the group as its own option to the start of the group */
    return grouped.map((group): GroupedUserOption => {
      if (!group.label) return group;
      const clientOption: UserOption = {
        isGroupOption: true,
        label: group.label,
        value: group.label,
        group: group.label,
      };
      return {
        ...group,
        options: [clientOption, ...group.options],
      };
    });
  }, [userOptions]);

  const selectUser = (option: SingleValue<UserOption>) => {
    if (option) {
      setSelectedUserOption(option);
    }
  };

  /** ================================
   * Logged In User
   ================================ */
  const { loggedInUser } = useGetLoggedInUser();
  const isSelectedUserLoggedInUser =
    selectedUserOption?.value === loggedInUser?.id;

  const selectLoggedInUser = useCallback(() => {
    if (loggedInUser && loggedInUser.id) {
      // find user option for logged in user and update state
      const loggedInUserOption: UserOption | undefined = userOptions.find(
        (user) => user.value === loggedInUser.id
      );
      if (loggedInUserOption) setSelectedUserOption(loggedInUserOption);
    }
  }, [loggedInUser, setSelectedUserOption, userOptions]);

  /** When we get the logged in user
   * add them to the user options if they are not already there */
  useEffect(() => {
    if (loggedInUser && loggedInUser?.id && loggedInUser?.name) {
      const loggedInUserOption: UserOption = {
        label: loggedInUser.name,
        value: loggedInUser.id,
        group: loggedInUser.client_name || '',
        secondaryLabel: loggedInUser.email || '',
      };

      setUserOptions((prevState) => {
        if (prevState.some((user) => user.value === loggedInUserOption.value)) {
          return prevState;
        } else {
          return [...prevState, loggedInUserOption];
        }
      });
    }
  }, [loggedInUser]);

  /** ================================
    * All Users
    ================================ */
  const [{ data: allUsersData, fetching: fetchingAllUsers }] = useQuery({
    query: AllUsersIdAndNameQuery,
  });
  /** When we get the list of all users, add them to the user options state, skipping over the logged in user if they
   * were already added */
  useEffect(() => {
    if (allUsersData && allUsersData.users) {
      const users = allUsersData.users;
      setUserOptions((prevState) => {
        const prevUserIds = prevState.map((user) => user.value);
        return [
          ...prevState,
          ...users
            .filter((user): user is NonNullUser => !!user?.id && !!user?.name)
            .filter((user) => !prevUserIds.includes(user.id))
            .map(
              (user): UserOption => ({
                label: user.name,
                value: user.id,
                group: user?.client_name || '',
                secondaryLabel: user?.email || '',
              })
            ),
        ];
      });
    }
  }, [allUsersData]);

  return (
    <Box mb="2">
      <FormLabel fontSize="sm">Search by user name, email, or client</FormLabel>
      <Flex>
        <Box width="400px" bg="white">
          <Select
            size="sm"
            options={groupedUserOptions}
            isLoading={fetchingAllUsers}
            isDisabled={fetchingAllUsers}
            placeholder="Select User"
            onChange={selectUser}
            value={selectedUserOption}
            filterOption={filterOption}
            formatOptionLabel={(option) => (
              <Flex width="100%">
                <Box
                  maxWidth="50%"
                  // group option formatted slightly differently
                  {...(option.isGroupOption && {
                    fontSize: 'xs',
                    fontWeight: 'semibold',
                  })}
                  {...(!option.isGroupOption && { paddingLeft: '.25rem' })}
                >
                  {option.label}
                </Box>
                <Spacer />
                <Box
                  maxWidth="50%"
                  color="gray.400"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {option.secondaryLabel}
                </Box>
              </Flex>
            )}
            chakraStyles={{
              groupHeading: (provided) => ({
                ...provided,
                // for now, we will hide the group heading since we are displaying the group as an option
                display: 'none',
                color: 'gray.400',
                paddingLeft: '1rem',
              }),
              option: (provided) => ({
                ...provided,
                paddingLeft: '1rem',
                paddingRight: '1.25rem',
              }),
            }}
          />
        </Box>
        {!isSelectedUserLoggedInUser && (
          <Button
            size="sm"
            variant="link"
            colorScheme="gray"
            marginLeft="2"
            onClick={selectLoggedInUser}
          >
            Reset to my deliverables
          </Button>
        )}
      </Flex>
    </Box>
  );
};

import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { ReferenceEntryRaw } from '../resumeTypes';

interface ReferenceRawProps {
  data?: ReferenceEntryRaw;
  variant?: string;
}

export default function ReferenceRaw({ data, variant }: ReferenceRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionTitle']}>{data?.name}</Text>
      <Text sx={styles['sectionDetails']}>{data?.role}</Text>
      <Text sx={styles['sectionDetails']}>{data?.company}</Text>
      <Text sx={styles['sectionDetails']}>{data?.contact_information}</Text>
    </Box>
  );
}

import { PostingMetric, PostingsDataQuery } from '@revelio/data-access';
import { LineData } from '@revelio/replots';

export type MetricKey = keyof Pick<
  PostingMetric,
  'salary' | 'time_to_fill' | 'active' | 'new' | 'removed' | 'expected_hires'
>;

type GetLineChartDataProps = {
  entities: PostingsDataQuery['posting'];
  metricKey: MetricKey;
  colorMap: Record<string, string>;
};

export const getLineChartData = ({
  entities,
  metricKey,
  colorMap,
}: GetLineChartDataProps): LineData[] => {
  return (
    entities?.map((entity): LineData => {
      const entityMetadata = entity?.metadata;
      const shortName = entityMetadata?.shortName || '';

      return {
        label: shortName,
        color: colorMap[shortName],
        values: (entity?.category || []).map((category) => {
          const metadata = category?.metadata;
          const metrics = category?.metrics;
          const value = metrics?.[metricKey];
          const date = metadata?.shortName || '';

          return {
            date,
            value: value ?? null,
          };
        }),
      };
    }) ?? []
  );
};

export const getPostingsSalaryData = (
  entities: PostingsDataQuery['posting'],
  colorMap: Record<string, string>
): LineData[] => {
  return getLineChartData({ entities, metricKey: 'salary', colorMap });
};

export const getPostingsTimeToFillData = (
  entities: PostingsDataQuery['posting'],
  colorMap: Record<string, string>
): LineData[] => {
  return getLineChartData({ entities, metricKey: 'time_to_fill', colorMap });
};

import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { get, isEmpty, isNull } from 'lodash';

import { startCasePreserveChars } from '@revelio/core';
import {
  TalentDiscoveryEntity,
  TalentDiscoveryUser,
} from '@revelio/data-access';

import { IEntitiesTransformArgs } from './gql.models';

const ColumnHeaderRemappings: Record<string, string> = {
  salary_base: 'Base Salary',
  salary_total: 'Total Compensation',
  linkedin_url: 'Profile URL',
  email: 'Professional Email',
  education: 'School',
};

type MapDataType = {
  id: number;
  type: number;
  count: number;
  share: number;
  lat: number;
  lon: number;
  location: string;
};

const monthAbbreviations = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatDateStrings = (dates: string[]) => {
  const formattedDates = dates.map((d) => {
    if (isEmpty(d)) return d;

    const [year, month] = d.split('-');

    const formattedDate = `${monthAbbreviations[Number(month) - 1]} ${year}`;

    return formattedDate;
  });

  return formattedDates;
};

export function TalentDiscovery_entitiesTransform({
  entities,
  filters,
  plotName,
}: IEntitiesTransformArgs) {
  const apiBaseColumns = get(entities, 'columns', []);
  const rawColumns = apiBaseColumns.length // TODO: api columns need to updated to remove hard coding here.
    ? [
        'user_id',
        'first_name',
        'last_name',
        'email',
        'personal_email',
        'phone_number',
        'current_title',
        'current_company',
        'start_date',
        'end_date',
        'longitude',
        'latitude',
        'gender',
        'ethnicity',
        'highest_degree',
        'salary_base',
        'salary_total',
        'location',
        'prestige',
        'education',
        'remote_suitability',
        'years_of_experience',
        'flight_risk',
        'linkedin_url',
        'graduation_year',
      ]
    : [];

  const users = get(entities, 'users', []) || [];

  const usersWithNullEmails: TalentDiscoveryEntity[] = [];

  const formattedUsers = users?.reduce(
    (acc: TalentDiscoveryEntity[], user: TalentDiscoveryUser) => {
      const { start_date, end_date, email } = user;
      let emailCleaned = email.trim();
      if (emailCleaned.endsWith('.')) {
        emailCleaned = emailCleaned.slice(0, -1); // Remove the last character if it's a period
      }
      const formattedDates = formatDateStrings([start_date, end_date]);
      const formattedUser = {
        ...user,
        email: emailCleaned,
        start_date: formattedDates[0],
        end_date: formattedDates[1],
      } as TalentDiscoveryEntity;

      // TEMP FIX: fix to "deprioritize" users with empty emails
      // set them aside and add them back at the end.
      if (isEmpty(email)) {
        usersWithNullEmails.push(formattedUser);
        return acc;
      }

      return [...acc, formattedUser];
    },
    []
  );

  // if the value we get is null, use empty array
  const map_data = get(entities, 'map_data', []) || [];

  // eslint-disable-next-line
  const formattedMapData = map_data?.reduce(
    (accMapDataPoints: MapDataType[], curMapDataPoint: MapDataType) => {
      const { id, type, location } = curMapDataPoint;

      // TEMP FIX: remove empty regions and msas by filtering out items with id = 0
      if (id === 0) return accMapDataPoints;

      // TEMP FIX: fixes location string formatting where there is an
      // extra space after the city name. Can remove this once backend fix is in
      if (type === 2 && !isNull(location)) {
        return [
          ...accMapDataPoints,
          {
            ...curMapDataPoint,
            location: location.replace(' , ', ', '),
          },
        ];
      }

      return [...accMapDataPoints, curMapDataPoint];
    },
    []
  );

  const metadata = get(entities, 'metadata', {});

  const columns = rawColumns.reduce((acc: TypeColumn[], cur: string) => {
    const columnConfig = {
      name: cur,
      header:
        ColumnHeaderRemappings[cur] || startCasePreserveChars(cur, ['of'], '_'),
      minWidth: 30,
    };

    return [...acc, columnConfig];
  }, []);

  const response = {
    columns,
    map_data: formattedMapData,
    table_data: [...formattedUsers, ...usersWithNullEmails],
    metadata,
  };

  return response;
}

import { Step } from 'react-joyride';

import { TourClasses } from '@revelio/core';

import CompositionPageStepCustomTooltip from './composition-page-step-custom-tooltip';

export enum TourStepTitles {
  WELCOME = 'Welcome to Revelio Labs',
  CONTROL_PANEL = 'Control panel',
  PAGES = 'Pages',
  COMPOSITIONS = 'Compositions',
  ENTITIES = 'Entities',
  FILTERS = 'Filters',
  ADJUST_VIEW = 'Adjust view',
  CUSTOM_DATA = 'Custom Data',
  TALENT_ACQUISITION = 'Talent Acquisition',
}

export const steps: Step[] = [
  {
    title: TourStepTitles.WELCOME,
    content:
      'Explore the world of workforce data to understand any company or market.',
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    showProgress: false,
  },
  {
    title: TourStepTitles.CONTROL_PANEL,
    content:
      'Here is your control panel where you can select a unit of analysis.',
    placement: 'right',
    spotlightPadding: 4,
    target: `.${TourClasses.TOUR_CONTROL_PANEL_CLASS}`,
    disableBeacon: true,
  },
  {
    title: TourStepTitles.PAGES,
    content: 'Each unit of analysis has different pages to explore.',
    placement: 'auto',
    target: `.${TourClasses.TOUR_DATA_SOURCES_CLASS}`,
    styles: {
      options: {
        arrowColor: 'white',
      },
    },
    disableBeacon: true,
  },
  {
    title: TourStepTitles.COMPOSITIONS,
    content: 'This page focuses on comparing up to 6 units of interest',
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    // this needs a custom tour step component because it needs to wait for the compositions page
    // to finish loading before being able to click next otherwise it skips the entities and filters tour
    // steps because they haven't rendered on the page yet.
    tooltipComponent: CompositionPageStepCustomTooltip,
  },
  {
    title: TourStepTitles.ENTITIES,
    content: 'Select entities to analyze. ',
    placement: 'right-start',
    target: `.${TourClasses.TOUR_TRACKING_CLASS}`,
    disableBeacon: true,
  },
  {
    title: TourStepTitles.FILTERS,
    content:
      'Filter those entities by role, seniority, region and other employee characteristics.',
    placement: 'right-start',
    target: `.${TourClasses.TOUR_FILTER_MENU_CLASS}`,
    disableBeacon: true,
  },
  {
    title: TourStepTitles.ADJUST_VIEW,
    content: 'Make static comparisons or view trends over time.',
    placement: 'left-start',
    target: `.${TourClasses.TOUR_VIEW_CLASS}`,
    disableBeacon: true,
    showProgress: false,
  },
  {
    title: TourStepTitles.CUSTOM_DATA,
    content: 'Create custom datasets, reports, and APIs.',
    placement: 'auto',
    target: `.${TourClasses.TOUR_DELIVERABLES_SUB_NAV_CLASS}`,
    event: 'hover',
    styles: {
      options: {
        arrowColor: 'white',
      },
    },
    disableBeacon: true,
    showProgress: false,
  },
  {
    title: TourStepTitles.TALENT_ACQUISITION,
    content:
      'Search for profiles using our advanced querying ability on the industries best data', // TODO: need real copy from Yedidya
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    showProgress: false,
  },
];

import {
  LocalSelectionCategories,
  SelectionCategories,
  SubFilterNames,
} from '../../engine/filters.model';
import { WorkFlows } from './types';

export const filtersWithCurrentOption: string[] = [
  SelectionCategories.COMPANY,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.MSA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.SENIORITY,
  SelectionCategories.BASE_SALARY,
  SelectionCategories.TOTAL_COMPENSATION,
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.FLIGHT_RISK,
  LocalSelectionCategories.REMOTE_SUITABILITY,
];

export const SelectionListsToNotSortSelectedToTop: string[] = [
  SelectionCategories.SENIORITY,
  SubFilterNames.SUB_SENIORITY,
  LocalSelectionCategories.DATA_METRIC,
];

export const filterWorkflowLookup: { [key: string]: WorkFlows } = {
  [SelectionCategories.DATE_RANGE]: WorkFlows.DATE_RANGE,
  [SelectionCategories.DATE_RANGE_FULL]: WorkFlows.DATE_RANGE_FULL,
  [SelectionCategories.SNAPSHOT_DATE]: WorkFlows.DATE,
  [SelectionCategories.SAVED_FILTER_SET]: WorkFlows.SAVED_FILTER_SET,
  [SelectionCategories.OTHER]: WorkFlows.DEFAULT,
  [SelectionCategories.KEYWORD]: WorkFlows.KEYWORD,
  [SelectionCategories.RAW_TITLE]: WorkFlows.KEYWORD,
};

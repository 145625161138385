import { Flex, FormLabel, Switch, Text } from '@chakra-ui/react';
import { capitalize, isEmpty, round } from 'lodash';
import { useMemo, useState } from 'react';

import { WordCloud } from '@revelio/d3';
import {
  SentimentReviewsNegativeQuery,
  SentimentReviewsPositiveQuery,
} from '@revelio/data-access';
import {
  LocalSelectionCategories,
  SelectionCategories,
} from '@revelio/filtering';

import { PlotLayoutCard } from '../../../shared/components/plots/plot-layout-card';
import { GptSentimentReview, sentimentSocket } from './gpt-sentiment-review';

export const SentimentReviewsSummary = ({
  allFilters,
  reviewType,
  reviewsData,
}: {
  allFilters: (SelectionCategories | LocalSelectionCategories)[];
  reviewType: 'positive' | 'negative';
  reviewsData:
    | SentimentReviewsNegativeQuery
    | SentimentReviewsPositiveQuery
    | undefined;
}) => {
  const [showWordcloudNotGptSummary, setshowWordcloudNotGptSummary] =
    useState<boolean>(false);

  const handleReviewsToggle = () => {
    setshowWordcloudNotGptSummary((b) => !b);
  };

  const sentimentWordCloudData = useMemo(() => {
    return getSentimentWordCloudData(reviewsData);
  }, [reviewsData]);

  const isWordCloudEmpty =
    isEmpty(sentimentWordCloudData) ||
    sentimentWordCloudData.every((entry) => !entry);

  const postitiveOrNegativeLabel = capitalize(reviewType);
  const title = showWordcloudNotGptSummary
    ? `${postitiveOrNegativeLabel} Reviews`
    : `${postitiveOrNegativeLabel} Summary`;
  const toolTipText = showWordcloudNotGptSummary
    ? `Words used in ${reviewType} reviews, weighted by prevalence.`
    : `An AI-generated summary of all ${reviewType} reviews.`;
  return (
    <PlotLayoutCard
      title={title}
      plotInfo={{
        plotInfoBody: <Text color="white">{toolTipText}</Text>,
        plotInfoConfig: {
          popoverPlacement: 'right',
          iconBoxSize: 2.5,
        },
      }}
      topRight={
        <Flex>
          <FormLabel
            htmlFor="uni-toggle"
            margin="0"
            px="4px"
            fontSize="12px"
            color="text.primary"
          >
            Word Cloud
          </FormLabel>
          <Switch
            id="sentiment-summary-toggle"
            size="sm"
            isChecked={showWordcloudNotGptSummary}
            onChange={handleReviewsToggle}
            colorScheme="green"
            paddingRight="4px"
            data-testid={`${reviewType}-word-cloud-switch`}
          />
        </Flex>
      }
    >
      <GptSentimentReview
        sx={{
          visibility: showWordcloudNotGptSummary ? 'hidden' : 'visible',
          height: showWordcloudNotGptSummary ? 0 : '100%',
        }}
        reviewType={reviewType}
        filters={allFilters}
        socket={sentimentSocket}
      />

      {!isWordCloudEmpty && (
        <WordCloud
          name={`wordcloud-${reviewType}`}
          heading={`${capitalize(reviewType)} Reviews`}
          chartStyle={`.sentiment-reviews-page-${reviewType}`}
          positiveSentiment={reviewType === 'positive'}
          ttMainFormat=",.0f"
          data={sentimentWordCloudData}
          style={{
            visibility: !showWordcloudNotGptSummary ? 'hidden' : 'visible',
          }}
        />
      )}
    </PlotLayoutCard>
  );
};

const getSentimentWordCloudData = (
  reviewsData:
    | SentimentReviewsNegativeQuery
    | SentimentReviewsPositiveQuery
    | undefined
) => {
  if (!reviewsData) return [];
  const reviews = reviewsData?.sentimentReviews?.[0]?.category?.map(
    (feature) => [
      feature?.metadata?.shortName,
      Math.abs(round(feature?.metrics?.count || 0)),
    ]
  );

  return [reviews];
};

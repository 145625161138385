import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import React, { useState } from 'react';

import { SelectionListIdNames, TreeItem } from '../../../engine/filters.model';
import { Tree, TreeMethodHandle, TreeType } from '../../collection';
import { useScreenerFilter } from '../screener-filter-provider';
import { SelectFooter } from '../select-footer';
import {
  CompanyDetailTreeFilters,
  ScreenerFilterOption,
  isTreeFilterState,
} from '../types';

interface TreeFilterProps {
  selectionLists: string[];
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const TreeFilter = ({
  selectionLists,
  selectedFilter,
  closeMenu,
}: TreeFilterProps) => {
  const treeRef = React.useRef<TreeMethodHandle>();

  const { state, dispatch } = useScreenerFilter();
  const selectedCompanyDetailState = state.filters.company_detail_filters?.find(
    (filter) => filter.name === selectedFilter.value
  );
  const defaultSelectedItems = isTreeFilterState(selectedCompanyDetailState)
    ? (selectedCompanyDetailState?.treeItems as Record<
        string,
        TreeItem<string>
      >)
    : {};
  const [currentSelections, setCurrentSelections] =
    useState<Record<string, TreeItem<string>>>(defaultSelectedItems);

  const onClearSelections = () => {
    setCurrentSelections({});
    treeRef.current?.handleClearSelections?.();
  };

  const handleAddFilter = () => {
    if (Object.keys(currentSelections).length) {
      dispatch({
        type: 'ADD_COMPANY_DETAIL_TREE_FILTER',
        name: selectedFilter.value as CompanyDetailTreeFilters,
        treeItems: currentSelections,
      });
    } else {
      dispatch({
        type: 'REMOVE_COMPANY_DETAIL_TREE_FILTER',
        name: selectedFilter.value as CompanyDetailTreeFilters,
      });
    }
    closeMenu();
  };

  const selectedItems = Object.keys(defaultSelectedItems);

  return (
    <>
      <PopoverBody padding={0}>
        <Tree
          key={selectedFilter.value}
          forwardedRef={treeRef}
          selectionLists={selectionLists as SelectionListIdNames[]}
          limit={20}
          setTempSelections={setCurrentSelections}
          defaultSelectedItemIds={selectedItems}
          height={220}
          nestingTreeType={TreeType.SUB_MENU_NESTED}
          showHeader
        />
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClearSelections={onClearSelections}
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={!selectedItems.length ? 'Add' : 'Update'}
        />
      </PopoverFooter>
    </>
  );
};

import { Tooltip } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';

type BarTooltipProps = PropsWithChildren & {
  label: ReactNode;
};

export const BarTooltip = ({ label, children }: BarTooltipProps) => {
  return (
    <Tooltip
      hasArrow
      label={label}
      padding={'8px 12px'}
      placement="top"
      bg="#2d426a"
      borderRadius="4px"
      data-testid="bar-tooltip"
    >
      {children}
    </Tooltip>
  );
};

import { max, min } from 'd3';

import { getColorKeyFromLookup } from '@revelio/core';

const getClosestDate = (allDatesSorted, referenceDate) => {
  const closestDate = allDatesSorted.find(
    (date) => Math.abs(referenceDate - date) <= 3.1 * 1000 * 60 * 60 * 24 // 3 days (half a week); 3.1 accounts for daylight savings
  );

  return closestDate;
};

export const generateTooltipData = (
  data,
  ttType,
  dateFormat,
  hoveredDay,
  hoveredMonth,
  hoveredYear,
  allDatesSorted,
  d3Format,
  metaValueCompany,
  color,
  ttMainFormat,
  ttSecondaryFormat,
  colorLookupKeyPath
) => {
  var tooltipData = [];
  var day = hoveredDay;
  var month = hoveredMonth;
  var year = hoveredYear;

  if (dateFormat === 'YMD') {
    // since we don't have daily data, find the date closest to the hovered date for which we do have data

    const closestDate = getClosestDate(
      allDatesSorted,
      new Date(hoveredYear, hoveredMonth - 1, hoveredDay)
    );

    // change our lookup values to the closest date for display in the tooltip
    day = closestDate.getDate();
    month = closestDate.getMonth() + 1;
    year = closestDate.getFullYear();
    tooltipData.push([year, month, day]);
  } else if (dateFormat === 'YM') {
    if (
      (year >= max(allDatesSorted).getFullYear() &&
        month >= max(allDatesSorted).getMonth() + 1) ||
      (year > max(allDatesSorted).getFullYear() && month === 1)
    ) {
      year = max(allDatesSorted).getFullYear();
      month = max(allDatesSorted).getMonth() + 1;
    } else if (
      (year <= min(allDatesSorted).getFullYear() &&
        month <= min(allDatesSorted).getMonth() + 1) ||
      (year < min(allDatesSorted).getFullYear() && month === 12)
    ) {
      year = min(allDatesSorted).getFullYear();
      month = min(allDatesSorted).getMonth() + 1;
    }
    tooltipData.push([year, month]);
  } else if (dateFormat === 'Y') {
    if (year >= max(allDatesSorted).getFullYear()) {
      year = max(allDatesSorted).getFullYear();
    } else if (year <= min(allDatesSorted).getFullYear()) {
      year = min(allDatesSorted).getFullYear();
    } else {
      const closestYear = allDatesSorted.find((date) => {
        return date.getFullYear() <= hoveredYear;
      });

      year = closestYear.getFullYear();
    }

    tooltipData.push([year]);
  }

  // for tooltips showing percent values, we want to show counts as well
  if (ttType === 'percent') {
    data.forEach((company) => {
      let dataFiltered = company.value.filter(
        (d) => d.metadata.year === year && d.metadata.month === month
      );
      // additional filter for day
      if (dateFormat === 'YMD') {
        dataFiltered = dataFiltered.filter((d) => d.metadata.day === day);
      }
      var valueFiltered = dataFiltered.map((d) => d.value);
      var countFiltered = dataFiltered.map((d) => d.metadata.count);

      const colorKey = getColorKeyFromLookup({
        metadata: company.metadata,
        d: company,
        colorLookupKeyPath,
      });

      tooltipData.push([
        company.metadata.longName,
        color(colorKey),
        valueFiltered,
        d3Format(ttSecondaryFormat)(Math.round(countFiltered)),
      ]);
    });
  } else {
    // other types of tooltips will only have one value
    data.forEach((company) => {
      let dataFiltered = company.value.filter(
        (d) => d.metadata.year === year && d.metadata.month === month
      );
      // additional filter for day
      if (dateFormat === 'YMD') {
        dataFiltered = dataFiltered.filter((d) => d.metadata.day === day);
      }
      var valueFiltered = dataFiltered.map((d) => d.value);

      const colorKey = getColorKeyFromLookup({
        metadata: company.metadata,
        d: company,
        colorLookupKeyPath,
      });

      tooltipData.push([
        company.metadata.longName,
        color(colorKey),
        valueFiltered,
      ]);
    });
  }
  return tooltipData;
};

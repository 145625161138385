import { mapKeys } from 'lodash';

import { User } from '@revelio/data-access';

/**
 * Extracts specific user properties to expose to Unleash feature flag service.
 * Only includes properties that are safe and relevant for feature flag targeting.
 *
 * @param user - User from \@revelio/data-access, or undefined if no user
 * @returns An object mapping property names to string values. Returns empty object if no user provided.
 */
export const unleashUserProperties = (
  user: User | undefined
): Record<string, string> => {
  if (!user) return {};

  const propertiesToPick = [
    'id',
    'client_name',
    'role',
    'live',
    'active',
  ] as const;

  const properties = propertiesToPick.reduce<Record<string, string>>(
    (acc, key) => {
      const value = user[key];
      if (value === null) {
        return { ...acc, [key]: '' };
      }
      if (typeof value === 'boolean') {
        return { ...acc, [key]: value ? 'true' : 'false' };
      }
      if (value !== undefined) {
        return { ...acc, [key]: String(value) };
      }
      return acc;
    },
    {}
  );

  return mapKeys(properties, (value, key) => {
    if (key === 'id') {
      return 'userId';
    }
    if (key === 'client_name') {
      return 'client';
    }
    return key;
  });
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Textarea,
  Button,
  Icon,
  Collapse,
  Center,
  Spinner,
  Code,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from '@chakra-ui/icons';
import { DocumentNode } from 'graphql';
// import { useVariableTypes } from '../../hooks/useVariableTypes';

interface HealthCheckResult {
  data?: unknown;
  status?: string;
  error?: { message: string };
  responseTime?: number;
}

interface Check {
  service: string;
  viewGroup: string;
  view: string;
  query: DocumentNode;
  variables: Record<string, unknown>;
}

interface QueryPlaygroundProps {
  check: Check;
  latestResult: HealthCheckResult | null | undefined;
  onRunCheck: (variables: Record<string, unknown>) => void;
}

export const QueryPlayground: React.FC<QueryPlaygroundProps> = ({
  check,
  latestResult,
  onRunCheck,
}) => {
  const [showResponse, setShowResponse] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [formattedResponse, setFormattedResponse] = useState<string>('');
  const [variableError, setVariableError] = useState<string | null>(null);
  const [variables, setVariables] = useState<string>(
    JSON.stringify(check.variables, null, 2)
  );
  // const variableTypes = useVariableTypes(check.query);

  // Handle variable validation
  const handleVariablesChange = (value: string) => {
    setVariables(value);
    try {
      const parsedVariables = JSON.parse(value);
      setVariableError(null);
      onRunCheck(parsedVariables);
    } catch (e) {
      setVariableError('Invalid JSON format');
    }
  };

  // Update variables when check changes
  useEffect(() => {
    const newVariables = JSON.stringify(check.variables, null, 2);
    setVariables(newVariables);
    onRunCheck(check.variables);
  }, [check.variables, onRunCheck]);

  // Handle response download
  const handleDownloadResponse = () => {
    if (!latestResult?.data) return;

    const fileName = `${check.service}-${check.viewGroup}-${check.view}-${new Date().toISOString()}.json`;
    const jsonString = JSON.stringify(latestResult.data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (!latestResult?.data) {
      setFormattedResponse('');
      setShowResponse(false);
      return;
    }

    setIsResponseLoading(true);
    try {
      const formatted = JSON.stringify(latestResult.data, null, 2);
      setFormattedResponse(formatted);
      setShowResponse(true);
    } catch (error) {
      console.error('Error formatting response:', error);
      setFormattedResponse('Error formatting response data');
    } finally {
      setIsResponseLoading(false);
    }
  }, [latestResult?.data]);

  return (
    <VStack align="stretch" spacing={4}>
      <HStack align="stretch" spacing={4}>
        <Box flex={1}>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            Edit Variables:
          </Text>
          <Textarea
            value={variables}
            onChange={(e) => handleVariablesChange(e.target.value)}
            placeholder="Enter variables as JSON"
            size="sm"
            fontFamily="monospace"
            minH="150px"
            resize="vertical"
            isInvalid={!!variableError}
          />
          {variableError && (
            <Text color="red.500" fontSize="sm" mt={1}>
              {variableError}
            </Text>
          )}
        </Box>
        {/* Failing due to "graphql libary conflict" */}
        {/* <Box flex={1}>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            Variable Types:
          </Text>
          <Textarea
            value={variableTypes}
            readOnly
            placeholder="Loading variable types..."
            size="sm"
            fontFamily="monospace"
            minH="150px"
            resize="vertical"
            bg="gray.100"
            borderRadius="md"
            whiteSpace="pre-wrap"
            sx={{
              '&:focus': {
                outline: 'none',
                boxShadow: 'none',
              },
            }}
          />
        </Box> */}
      </HStack>
      {latestResult && (
        <Box>
          <HStack justify="space-between" align="center" mb={2}>
            <Text fontSize="sm" fontWeight="bold">
              Response:
            </Text>
            <HStack>
              <Button
                size="xs"
                leftIcon={<Icon as={DownloadIcon} />}
                variant="ghost"
                onClick={handleDownloadResponse}
              >
                Download
              </Button>
              <Button
                size="xs"
                leftIcon={
                  showResponse ? <ChevronUpIcon /> : <ChevronDownIcon />
                }
                variant="ghost"
                onClick={() => setShowResponse(!showResponse)}
              >
                {showResponse ? 'Hide' : 'Show'}
              </Button>
            </HStack>
          </HStack>
          <Collapse in={showResponse} animateOpacity>
            <Box
              p={2}
              borderRadius="md"
              bg="gray.100"
              maxH="none"
              overflowY="auto"
            >
              <Box>
                {isResponseLoading && (
                  <Center p={4}>
                    <Spinner size="sm" />
                  </Center>
                )}
                {!isResponseLoading && !formattedResponse && (
                  <Text p={2} color="gray.500">
                    No response data available
                  </Text>
                )}
                {!isResponseLoading && formattedResponse && (
                  <Code
                    display="block"
                    whiteSpace="pre-wrap"
                    fontSize="sm"
                    width="100%"
                    p={2}
                    minH="300px"
                    as="textarea"
                    resize="vertical"
                    readOnly
                    value={formattedResponse}
                    sx={{
                      bg: 'gray.100',
                      border: 'none',
                      outline: 'none',
                      '&:focus': {
                        outline: 'none',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    {formattedResponse}
                  </Code>
                )}
              </Box>
            </Box>
          </Collapse>
        </Box>
      )}
    </VStack>
  );
};

import { Flex, Grid, Text } from '@chakra-ui/layout';
import { useDisclosure, useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import { format } from 'd3-format';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SentimentSubFilterNames } from '@revelio/filtering';
import { BarChart, FormatType, LineChart, LineData } from '@revelio/replots';

import { PlotCard } from '../../../shared/components';
import styles from './sentiment-effects-charts.module.css';
import { Topic, defaultTopics, topics } from './topics-subfilter/topics';
import { TopicsSubFilter } from './topics-subfilter/topics-subfilter';
import {
  SentimentBarChartData,
  SentimentBarDatum,
  SentimentLineChartData,
} from './types';
import { getSentimentDataDownload } from './utils/get-sentiment-data-download';
import { D3ChartNames } from '@revelio/d3';

type PlotGridBase = {
  topic: Topic;
};

type PlotGridBar = {
  chartType: 'bar';
  data: SentimentBarDatum[];
} & PlotGridBase;

type PlotGridLine = {
  chartType: 'line';
  data: LineData[];
} & PlotGridBase;

export type PlotGrid = PlotGridBar | PlotGridLine;

type SentimentEffectsChartsSnapshot = {
  viewType: 'snapshot';
  data: SentimentBarChartData;
};

type SentimentEffectsChartsTrend = {
  viewType: 'overtime';
  data: SentimentLineChartData;
};

type SentimentEffectsChartsProps =
  | SentimentEffectsChartsSnapshot
  | SentimentEffectsChartsTrend;

export const SentimentEffectsCharts = (
  props: SentimentEffectsChartsProps & { colors?: string[] }
) => {
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!topics.length) return;

    const topicsParam = searchParams.get(SentimentSubFilterNames.SUB_TOPICS);

    if (topicsParam) {
      const topicIds = topicsParam.split(',');
      const topicsFromParams = topicIds
        .map((id) => topics.find((topic) => topic.id.toString() === id))
        .filter((topic): topic is Topic => topic !== undefined);
      if (topicsFromParams.length) {
        setSelectedTopics(topicsFromParams);
        return;
      }
    }

    // Fallback: update search params with default topics
    const defaultTopicIds = defaultTopics.map((topic) => topic.id.toString());
    const newParams = new URLSearchParams(searchParams);
    newParams.set(
      SentimentSubFilterNames.SUB_TOPICS,
      defaultTopicIds.join(',')
    );
    setSearchParams(newParams);
    setSelectedTopics(defaultTopics);
  }, [searchParams, setSearchParams]);

  const updateTopicAtIndex = (index: number, newTopic: Topic) => {
    setSelectedTopics((prev) => {
      return prev.map((topic, i) => (i === index ? newTopic : topic));
    });
  };

  const plotGrids: PlotGrid[] = (() => {
    if (props.viewType === 'snapshot') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'bar',
        data: props.data[topic.value],
        index,
      }));
    }
    if (props.viewType === 'overtime') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'line',
        data: props.data[topic.value],
        index,
      }));
    }
    return [];
  })();

  const { min, max } = plotGrids.reduce(
    (acc, grid) => {
      if (grid.chartType === 'bar') {
        return {
          min: Math.min(
            acc.min,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
          max: Math.max(
            acc.max,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
        };
      }
      if (grid.chartType === 'line') {
        const values = grid.data.flatMap((d) =>
          d.values.map((v) => v.value).filter((v) => v !== null)
        );
        return {
          min: Math.min(acc.min, ...values),
          max: Math.max(acc.max, ...values),
        };
      }

      return acc;
    },
    { min: Infinity, max: -Infinity }
  );

  const adjustedMin = Math.max(min - 0.1, 0);

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(3, 1fr)"
      gap={4}
      w="100%"
      h="100%"
    >
      {plotGrids.map((plotGrid, i) => (
        <Flex
          key={`${plotGrid.topic.value}-${i}`}
          borderRadius="md"
          border="1px solid #e5ebf1"
          minWidth="0"
          minHeight="0"
        >
          {plotGrid && (
            <TopicPlotCard
              plotGrid={plotGrid}
              selectedTopic={selectedTopics[i]}
              updateTopicAtIndex={(topic) => updateTopicAtIndex(i, topic)}
              minValue={adjustedMin}
              maxValue={max}
              colors={props.colors}
              index={i}
            />
          )}
        </Flex>
      ))}
    </Grid>
  );
};

type EffectChartBarProps = {
  chartType: 'bar';
  minDataValue?: number;
  maxDataValue?: number;
  data: SentimentBarDatum[];
};

type EffectChartLineProps = {
  chartType: 'line';
  minDataValue?: number;
  maxDataValue?: number;
  data: LineData[];
};

type EffectChartProps = EffectChartBarProps | EffectChartLineProps;

const formatNumber = format('.0%');

const EffectChart = ({
  chartType,
  minDataValue,
  maxDataValue,
  data,
  colors,
}: EffectChartProps & { colors?: string[] }) => {
  if (chartType === 'bar') {
    const barData = data.map((d, i) => ({
      ...d,
      color: colors?.[i % colors.length],
    }));

    return (
      <BarChart
        data={barData}
        {...(typeof minDataValue === 'number' &&
        typeof maxDataValue === 'number'
          ? { xAxis: { min: minDataValue, max: maxDataValue } }
          : {})}
        showBarLabels={true}
        format={FormatType.PERCENTAGE}
        tooltipContent={(data) => (
          <div>
            <Text fontWeight="semibold" color="#fff" fontSize="xs">
              {`${data.label}: ${isNumber(data.value) ? formatNumber(data.value) : '-'}`}
            </Text>
            <Text color="#fff" fontSize="xs">
              {`(n: ${data.count})`}
            </Text>
          </div>
        )}
      />
    );
  }

  if (chartType === 'line') {
    return (
      <LineChart
        data={data}
        format={FormatType.PERCENTAGE}
        dateFormat="month"
        colors={colors}
        {...(minDataValue !== undefined &&
          Number.isFinite(minDataValue) &&
          maxDataValue !== undefined &&
          Number.isFinite(maxDataValue) && {
            yAxis: { min: minDataValue, max: maxDataValue },
          })}
      />
    );
  }
  return null;
};

type TopicPlotCardProps = {
  plotGrid: PlotGrid;
  selectedTopic: Topic;
  updateTopicAtIndex: (topic: Topic) => void;
  minValue: number;
  maxValue: number;
  colors?: string[];
  index: number;
};

const TopicPlotCard = ({
  plotGrid,
  selectedTopic,
  updateTopicAtIndex,
  minValue,
  maxValue,
  colors,
  index,
}: TopicPlotCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = (topic: Topic) => {
    updateTopicAtIndex(topic);
    const newParams = new URLSearchParams(searchParams);

    // Retrieve the current sub_topics value
    const currentTopics =
      newParams.get(SentimentSubFilterNames.SUB_TOPICS) || '';

    const topicsArray = currentTopics.split(',');

    // Update the topic at the specified index
    topicsArray[index] = topic.id.toString();

    // Set the updated topics back to the search params
    newParams.set(SentimentSubFilterNames.SUB_TOPICS, topicsArray.join(','));

    setSearchParams(newParams);
  };

  const toast = useToast();

  const menuProps = {
    onExport: () => {
      const success = getSentimentDataDownload({
        plotGrid,
      });

      if (!success) {
        toast({
          title: 'Error',
          description: 'Failed to download data',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    plotShare: {
      data:
        plotGrid.chartType === 'bar'
          ? plotGrid.data.map((d, i) => ({
              ...d,
              color: colors?.[i % colors.length],
            }))
          : plotGrid.data,
      chartConfig: {
        chartType:
          plotGrid.chartType === 'bar'
            ? D3ChartNames.ReplotsBarChart
            : D3ChartNames.ReplotsLineChart,
        chartProps: {
          format: FormatType.PERCENTAGE,
          colors: colors,
        },
      },
    },
  };

  return (
    <PlotCard
      className={classNames(styles.plotCard, {
        [styles.plotCardOpen]: isOpen,
      })}
      title={plotGrid.topic.label}
      info={
        <Text fontSize="xs" color="text.secondary">
          The average score given by workers on the topic of{' '}
          {plotGrid.topic.label}
        </Text>
      }
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      topRight={
        <TopicsSubFilter
          placeholderText="Topics"
          selectedTopic={selectedTopic}
          onSubmit={onSubmit}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      }
      {...menuProps}
    >
      <EffectChart
        colors={colors}
        minDataValue={minValue}
        maxDataValue={maxValue}
        {...plotGrid}
      />
    </PlotCard>
  );
};

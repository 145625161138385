import { useMemo } from 'react';

import { getActiveTabMeta } from '../../../../engine/filters.core';
import { filterStore } from '../../../../engine/filters.engine';
import { useGetStoredSets } from '../../../../engine/filters.savedset';

export const useSavedSetOptions = ({ searchTerm }: { searchTerm: string }) => {
  const { options: savedSets } = useGetStoredSets();
  const tabMeta = filterStore.query(getActiveTabMeta);
  const selectOptions = useMemo(() => {
    return savedSets
      .map((option) => ({
        value: option.id,
        label: option.name,
        entity: option,
        isDisabled: option.view !== tabMeta.savedSetView,
      }))
      .sort((a, b) => {
        if (a.isDisabled === b.isDisabled) {
          return a.label?.localeCompare(b.label);
        }
        return a.isDisabled ? 1 : -1;
      });
  }, [savedSets, tabMeta.savedSetView]);

  const filteredBySearchOptions = useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return selectOptions.filter((option) =>
      option.label.toLowerCase().includes(lowercasedSearchTerm)
    );
  }, [selectOptions, searchTerm]);

  const { enabledOptions, disabledOptions } = useMemo(
    () => ({
      enabledOptions: filteredBySearchOptions?.filter(
        (option) => !option.isDisabled
      ),
      disabledOptions: filteredBySearchOptions?.filter(
        (option) => option.isDisabled
      ),
    }),
    [filteredBySearchOptions]
  );

  return {
    enabledOptions,
    disabledOptions,
  };
};

import { HTMLChakraProps, chakra } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <path
        d="M1 9L1 1"
        stroke="#2D426A"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path d="M9 5H4" stroke="#2D426A" strokeWidth="1" strokeLinecap="round" />
      <path
        d="M6.5 8.5L3 5L6.5 1.5"
        stroke="#2D426A"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  );
};

import { SingleValue } from 'react-select';

import {
  FilterItem,
  SelectFilter,
  SelectionCategories,
  upsertFiltersWithProvidedValue,
  useSelectFilterById,
} from '@revelio/filtering';

import { StyledPlotSelect } from '../../../shared/components';

interface PostingMetricOption {
  value: string;
  label: string;
}

const PostingsMetricOptions: PostingMetricOption[] = [
  { value: 'active', label: 'Active' },
  { value: 'new', label: 'New' },
  { value: 'removed', label: 'Removed' },
] as const;

export function PostingsHeaderSelect() {
  const postingMetric = useSelectFilterById(SelectionCategories.POSTING_METRIC);

  const postingMetricValue = (postingMetric as SelectFilter<FilterItem>)?.value;
  const selectedOption =
    PostingsMetricOptions.find(
      (option) => option.value === postingMetricValue?.value
    ) || null;

  const onChange = (value: SingleValue<PostingMetricOption>) => {
    if (value) {
      upsertFiltersWithProvidedValue({
        [SelectionCategories.POSTING_METRIC]: value.value,
      });
    }
  };

  return (
    <StyledPlotSelect
      value={selectedOption}
      onChange={onChange}
      options={PostingsMetricOptions}
    />
  );
}

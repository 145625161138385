import classNames from 'classnames';
import { arc, scaleLinear } from 'd3';
import { useCallback, useMemo } from 'react';

import styles from './meter-chart.module.css';

const WIDTH_UNITS = 188;
const HEIGHT_UNITS = 86;
const HALF_WIDTH = WIDTH_UNITS / 2;
const HALF_HEIGHT = HEIGHT_UNITS / 2;
const OUTER_RADIUS = WIDTH_UNITS / 1.4;
const INNER_RADIUS = OUTER_RADIUS * 0.945;

const START_ANGLE = -Math.PI / 4;
const END_ANGLE = Math.PI / 4;
const BUG_LENGTH = 0.1;
const HALF_BUG_LENGTH = BUG_LENGTH / 2;

const START_COLOR = '#55A3FF';
const END_COLOR = '#FF646D';

export type MeterProps = {
  mainText?: string;
  unit?: string;
  subText?: string;
  minValue?: number;
  maxValue?: number;
  colorRange?: string[];
  value: number;
};

export const MeterChart = ({
  mainText,
  unit,
  subText,
  minValue = 0,
  maxValue = 100,
  colorRange = [START_COLOR, END_COLOR],
  value,
}: MeterProps) => {
  const pathD = useMemo(
    () =>
      arc().cornerRadius(100)({
        innerRadius: INNER_RADIUS,
        outerRadius: OUTER_RADIUS,
        startAngle: START_ANGLE,
        endAngle: END_ANGLE,
      }),
    []
  );

  const bugScale = useCallback(
    (value: number) => {
      const angleScale = scaleLinear()
        .domain([minValue, maxValue])
        .range([START_ANGLE + HALF_BUG_LENGTH, END_ANGLE - HALF_BUG_LENGTH])
        .clamp(true);

      const arcGenerator = arc().cornerRadius(100);

      return arcGenerator({
        innerRadius: INNER_RADIUS,
        outerRadius: OUTER_RADIUS,
        startAngle: angleScale(value) - HALF_BUG_LENGTH,
        endAngle: angleScale(value) + HALF_BUG_LENGTH,
      });
    },
    [minValue, maxValue]
  );

  const colors = useMemo(
    () => (colorRange.length > 1 ? colorRange : [START_COLOR, END_COLOR]),
    [colorRange]
  );

  const colorScale = useMemo(
    () =>
      scaleLinear<string>()
        .domain([minValue, maxValue])
        .range(colors)
        .clamp(true),

    [colors, minValue, maxValue]
  );

  const bugD = useMemo(() => bugScale(value), [bugScale, value]);

  return (
    <div className={styles['meterContainer']}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 -6 ${WIDTH_UNITS} ${HEIGHT_UNITS}`}
      >
        <defs>
          <linearGradient id="gradient" x1="-10%" y1="0%" x2="110%" y2="0%">
            {colors.map((color, index) => (
              <stop
                key={index}
                offset={`${(index / (colors.length - 1)) * 100}%`}
                stopColor={color}
                stopOpacity={1}
              />
            ))}
          </linearGradient>
        </defs>
        <g transform={`translate(${HALF_WIDTH}, ${OUTER_RADIUS})`}>
          {pathD && <path d={pathD} className={styles['path']} />}
          {bugD && <path d={bugD} fill={colorScale(value)} />}
        </g>
        <g
          transform={`translate(${HALF_WIDTH + (unit ? 6 : 0)}, ${HALF_HEIGHT + 6})`}
        >
          <text
            textAnchor={unit ? 'end' : 'middle'}
            alignmentBaseline="baseline"
            className={classNames(styles['text'], styles['mainText'])}
          >
            {mainText}
          </text>
          {unit && (
            <text
              x={4}
              alignmentBaseline="baseline"
              className={classNames(styles['text'], styles['unit'])}
            >
              {unit}
            </text>
          )}
        </g>
        <g transform={`translate(${HALF_WIDTH}, ${HALF_HEIGHT + 14})`}>
          <text
            alignmentBaseline="before-edge"
            className={classNames(styles['text'], styles['subText'])}
          >
            {subText}
          </text>
        </g>
      </svg>
    </div>
  );
};

import Tracker from '@openreplay/tracker';
import { createGraphqlMiddleware } from '@openreplay/tracker-graphql';
import { RequestResponseData } from '@openreplay/tracker/dist/lib/main/modules/network';
import { isArray, pick } from 'lodash';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

import { useGetLoggedInUser } from '@revelio/auth';
import { E2E_TEST_USER_SHARED_NAME } from '@revelio/core/e2e-testing-users';
import trackerAssist from '@openreplay/tracker-assist';

import { environment } from '../../environments/environment';
import { elfOpenReplayLogger } from './open-replay-elf';

const isLocalHost = window.location.hostname === 'localhost';
const isProd = environment.production;

const tracker = new Tracker({
  __DISABLE_SECURE_MODE: isLocalHost, // for local use only
  projectKey: environment.OPEN_REPLAY_PROJECT_KEY, // this key is an empty string for all environments besides local dev and prod
  crossdomain: {
    enabled: true,
  },
  captureIFrames: true,
  network: {
    sessionTokenHeader: false,
    failuresOnly: false,
    ignoreHeaders: false,
    capturePayload: true,
    captureInIframes: true,
    sanitizer: (data: RequestResponseData) => {
      const url = new URL(data.url);
      // if not in allowed open replay hosts, remove request and response body
      // if (!openReplayAllowedHosts.includes(url.origin)) {
      //   data.request.body = null;
      //   data.response.body = null;
      // }
      // if download request, remove response body
      if (url.pathname.includes('/download')) {
        data.response.body = null;
      }

      // Reduce large JSON response bodies
      if (data.response.body && typeof data.response.body === 'string') {
        const twoMb = 2 * 1024 * 1024;

        try {
          if (data.response.body.length > twoMb) {
            const json = JSON.parse(data.response.body);

            if (
              json?.data?.selectionList?.custom_role_taxonomy?.leafLevelData
            ) {
              const originalLength =
                json.data.selectionList.custom_role_taxonomy.leafLevelData
                  .length;
              // Keep first 100 items only
              const firstItems =
                json.data.selectionList.custom_role_taxonomy.leafLevelData.slice(
                  0,
                  100
                );

              json.data.selectionList.custom_role_taxonomy.leafLevelData = [
                ...firstItems,
                {
                  _truncatedMessage: `... ${originalLength - 100} items omitted ...`,
                },
              ];

              data.response.body = JSON.stringify(json);
            }
          }
        } catch (e) {
          // If JSON parsing fails, fall back to simple truncation
          if (data.response.body.length > twoMb) {
            data.response.body =
              data.response.body.substring(0, twoMb) + '... (truncated)';
          }
        }
      }

      return data;
    },
    useProxy: true,
  },
});

export const recordGraphQL = tracker.use(createGraphqlMiddleware());

const ElfStateToSend: { [key: string]: string[] | boolean } = {
  filters: [
    'lastStartDate',
    'entities',
    'defaultLastMonth',
    'cachedDate',
    'activeIds',
  ],
  deliverables: true,
};

const initElfLogger = tracker.use(
  elfOpenReplayLogger({
    storeFilter(storeName) {
      return !storeName.includes('auth');
    },
    stateTransformer(storeName, state) {
      const stateKeysToStore = ElfStateToSend[storeName];
      if (isArray(stateKeysToStore)) {
        return { [storeName]: pick(state, stateKeysToStore) };
      }
      return stateKeysToStore
        ? { [storeName]: state }
        : { [storeName]: { note: 'Real state not sent' } };
    },
  })
);

interface OpenReplayState {
  tracker: Tracker;
  recordGraphQL: typeof recordGraphQL;
}

const OpenReplayContext = createContext<OpenReplayState>({
  tracker,
  recordGraphQL,
});

export const OpenReplayProvider = ({ children }: PropsWithChildren) => {
  const { loggedInUser } = useGetLoggedInUser();

  useEffect(() => {
    const isTestUser = loggedInUser?.email?.includes(E2E_TEST_USER_SHARED_NAME);

    // checking we have user email so that we don't start tracking until we're sure it's not a test user session
    if (isProd && loggedInUser?.email && !isTestUser) {
      tracker.use(trackerAssist());
      tracker.start().then(() => {
        initElfLogger();
      });
    }

    return () => {
      if (isProd) tracker.stop();
    };
  }, [loggedInUser?.email]);

  return (
    <OpenReplayContext.Provider value={{ tracker, recordGraphQL }}>
      {children}
    </OpenReplayContext.Provider>
  );
};

export const useOpenReplay = () => useContext(OpenReplayContext);

import { PipelineColumnType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';

import { addDefaultIsSelectedToSubMenu } from './helpers';
import {
  COUNT_SUBMENU,
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  DEFAULT_SKILL_PIPELINE_COLUMNS,
  INFLOW_SUBMENU,
  OUTFLOW_SUBMENU,
  STANDARD_COMPANY_COLUMN,
} from './shared';
import { DEFAULT_INDUSTRY_PIPELINE_COLUMNS } from './workforce-dynamics';

export const SKILL_DYNAMICS_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: 'Levels of Aggregation',
    columns: [
      DEFAULT_INDUSTRY_PIPELINE_COLUMNS,
      STANDARD_COMPANY_COLUMN,
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
        includeColumns: [PipelineColumnType.Region],
      }),
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_ROLE_PIPELINE_COLUMNS,
        includeColumns: [
          PipelineColumnType.JobCategory,
          PipelineColumnType.RoleK50,
          PipelineColumnType.RoleK150,
        ],
      }),
      {
        id: PipelineColumnType.Seniority,
        defaultIsSelected: true,
        columnFileSize: 1,
        rowPossibilities: 7,
      },
      {
        id: PipelineColumnType.Gender,
        columnFileSize: 32,
        rowPossibilities: 2,
      },
      {
        id: PipelineColumnType.Ethnicity,
        columnFileSize: 32,
        rowPossibilities: 6,
      },
      DEFAULT_SKILL_PIPELINE_COLUMNS,
      {
        id: PipelineColumnType.Transition,
        columnFileSize: 32,
        rowPossibilities: 4,
        internalOnly: true,
      },
    ],
    supportsCustomColumns: true,
    minColumnsRequired: 2,
  },
  {
    heading: 'Variables',
    columns: [COUNT_SUBMENU, INFLOW_SUBMENU, OUTFLOW_SUBMENU],
    minColumnsRequired: 1,
  },
];

import { graphql } from '@revelio/data-access';

export const GET_COMPANY_SUMMARY_MAP_DATA = graphql(`
  query CompanySummaryMapData($query: TalentDiscoveryV1_5Filter) {
    talent_discovery_search_v1_5(query: $query) {
      map_data {
        id
        type
        count
        share
        lat
        lon
        location
      }
    }
  }
`);

export const GET_GEOGRAPHY_SUMMARY_MAP_DATA = graphql(`
  query GeographySummaryMapData($query: MapCoordsReq!) {
    mapCoords(req: $query) {
      region {
        id
        name
        lat
        lon
      }
      country {
        id
        name
        lat
        lon
      }
      msa {
        id
        name
        lat
        lon
      }
    }
  }
`);

import { getEntity } from '@ngneat/elf-entities';
import { trackRequestResult } from '@ngneat/elf-requests';
import { get, includes } from 'lodash';
import { of, switchMap } from 'rxjs';
import { Client } from 'urql';

import { CustomRoleTaxonomySelection, graphql } from '@revelio/data-access';
import {
  CompanyIndustrySelectionListGqlQuery,
  SelectionListGqlQuery,
} from '@revelio/data-access';

import {
  ALL_ROLE_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  REPORT_COMPANY_FILTERS,
  RICS_FILTERS,
} from './filters.constants';
import { filterStore } from './filters.core';
import {
  OtherFilterNames,
  RoleSelectionCategories,
  SelectionCategories,
  SelectionListIdNames,
} from './filters.model';
import {
  addSelectionList,
  selectionListDataSource,
} from './filters.repository';
import { transformCustomRoleTaxonomy } from './selection-lists-data-transforms';

const CompanyReportSelectionListQuery = graphql(`
  query CompanyReportSelectionList {
    selectionList {
      company_report {
        id
        shortName
        longName
        label: shortName
        industry: industryId
        rank
        isin
        sedol
        ticker
        rcid
        version
        dataUnavailableIn {
          position
          posting
          sentiment
          sentimentReview
          transition
        }
      }
    }
  }
`);

const CustomRoleTaxonomyQuery = graphql(`
  query CustomRoleTaxonomy($taxonomyId: CustomRoleTaxonomySelection!) {
    selectionList {
      custom_role_taxonomy(taxonomy_id: $taxonomyId) {
        orderedLevels {
          id
          label
        }
        leafLevelData {
          id
          label
          levelId
          suggested_titles
          parents {
            id
            levelId
            label
            suggested_titles
          }
        }
      }
    }
  }
`);

export type SelectionListParentMap = {
  [key in SelectionListIdNames]?: SelectionListIdNames;
};
export function fetchGqlSelectionList(
  gqlClient: Client,
  selectionListNeeded: SelectionListIdNames
) {
  const query = (() => {
    if (
      [
        ...INDUSTRY_AND_COMPANY_FILTERS,
        ...RICS_FILTERS,
        SelectionCategories.RICS_K400,
      ].includes(selectionListNeeded as SelectionCategories)
    ) {
      return CompanyIndustrySelectionListGqlQuery;
    }

    if (
      REPORT_COMPANY_FILTERS.includes(
        selectionListNeeded as SelectionCategories
      )
    ) {
      return CompanyReportSelectionListQuery;
    }

    return SelectionListGqlQuery;
  })();

  return of(selectionListNeeded).pipe(
    trackRequestResult([selectionListNeeded], {
      preventConcurrentRequest: true,
      cacheResponseData: true,
    }),
    selectionListDataSource.skipWhileCached({ key: selectionListNeeded }),
    switchMap((listName: SelectionListIdNames) => {
      return gqlClient
        .query(query, {})
        .toPromise()
        .then((resp) => {
          const requestedList = get(resp, `data.selectionList.${listName}`, []);
          const list = {
            id: listName,
            label: listName as string,
            value: requestedList,
          };
          const customTaxonomyFilter = filterStore.query(
            getEntity(OtherFilterNames.ROLE_TAXONOMY)
          );
          const isRoleList = includes(ALL_ROLE_FILTERS, listName);
          const skipUpsert = isRoleList && !!customTaxonomyFilter;
          if (!skipUpsert) {
            addSelectionList(list.id, list.value);
          }
          return list;
        })
        .catch((error) => {
          console.log('GQL list error:', error);
        });
    })
  );
}

export const fetchCustomRoleTaxonomy = ({
  gqlClient,
  selectionListNeeded,
  roleTaxonomyId,
}: {
  gqlClient: Client;
  selectionListNeeded: RoleSelectionCategories;
  roleTaxonomyId: CustomRoleTaxonomySelection;
}) => {
  return of(selectionListNeeded).pipe(
    trackRequestResult([selectionListNeeded], {
      preventConcurrentRequest: true,
      cacheResponseData: true,
    }),
    selectionListDataSource.skipWhileCached({ key: selectionListNeeded }),
    switchMap((listName: RoleSelectionCategories) => {
      return gqlClient
        .query(CustomRoleTaxonomyQuery, {
          taxonomyId: roleTaxonomyId,
        })
        .toPromise()
        .then((resp) => {
          const list = transformCustomRoleTaxonomy(
            listName,
            get(resp, 'data.selectionList.custom_role_taxonomy', [])
          );
          addSelectionList(list.id, list.value, roleTaxonomyId);
          return list;
        })
        .catch((error) => {
          console.log('GQL list error:', error);
        });
    })
  );
};

export const setRoleSelectionListToCustomTaxonomy = async ({
  gqlClient,
  selectionListNeeded,
  roleTaxonomyId,
}: {
  gqlClient: Client;
  selectionListNeeded: RoleSelectionCategories;
  roleTaxonomyId: CustomRoleTaxonomySelection;
}) => {
  return gqlClient
    .query(CustomRoleTaxonomyQuery, {
      taxonomyId: roleTaxonomyId,
    })
    .toPromise()
    .then((resp) => {
      const list = transformCustomRoleTaxonomy(
        selectionListNeeded,
        get(resp, 'data.selectionList.custom_role_taxonomy', [])
      );
      addSelectionList(list.id, list.value, roleTaxonomyId);
      return list;
    })
    .catch((error) => {
      console.log('GQL list error:', error);
    });
};

import { ReactNode } from 'react';

import {
  D3ChartNames,
  ID3ChartProps,
  StackedBarChartHorizontalData,
} from '@revelio/d3';
import {
  OTHER_COLOR,
  StackedBarChartHorizontal,
  StackedBarData,
} from '@revelio/replots';

import { convertStackedBarToSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { PlotCard } from '../../../shared/components/plot-card/plot-card';

interface TransitionsPlotCardProps {
  title: string;
  data: StackedBarData[];
  legacyData: StackedBarChartHorizontalData[] | undefined;
  loading?: boolean;
  info?: ReactNode;
}

export const TransitionsPlotCard = ({
  title,
  data,
  legacyData,
  loading,
  info,
}: TransitionsPlotCardProps) => {
  return (
    <PlotCard
      title={title}
      info={info}
      slim
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      legacyChartProps={{
        type: D3ChartNames.StackedBarChartHorizontal,
        props: { hideLegend: true } as ID3ChartProps,
        data: legacyData ?? [],
      }}
      exportData={convertStackedBarToSheet({
        data,
        dimensionName: title,
        metricName: 'Headcount',
      })}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <StackedBarChartHorizontal
          data={data}
          loading={loading}
          hideLegend={true}
          colorOverride={{ Other: OTHER_COLOR }}
        />
      </div>
    </PlotCard>
  );
};

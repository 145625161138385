import { PrimaryView } from '@revelio/core';
import { Tab, ViewTypes } from '@revelio/filtering';

export const getPrimaryViewFromTab = (tab: Tab): PrimaryView => {
  switch (tab) {
    case ViewTypes.COMPANY:
      return PrimaryView.COMPANY;
    case ViewTypes.GEO:
      return PrimaryView.GEOGRAPHY;
    case ViewTypes.ROLE:
      return PrimaryView.ROLE;
  }
};

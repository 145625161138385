import { Box } from '@chakra-ui/react';
import {
  ThemingProps,
  omitThemingProps,
  useStyleConfig,
} from '@chakra-ui/system';
import { ElementType, ReactNode } from 'react';

export interface RlBoxProps extends ThemingProps<'box'> {
  children?: ReactNode;
}

/**
 * RlBox wraps box
 *
 */
export const RlBox: ElementType<RlBoxProps> = (props: RlBoxProps) => {
  const styles = useStyleConfig('RlBox', props);
  const { ...rest } = omitThemingProps(props as ThemingProps);

  return <Box {...rest} sx={styles} />;
};

import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';

import styles from './plot-tooltip.module.css';

export type TooltipPosition = {
  x: number;
  y: number;
};

type PlotTooltipProps = {
  isVisible: boolean;
  children: ReactNode;
} & TooltipPosition;

export const PlotTooltip = ({
  isVisible,
  x,
  y,
  children,
}: PlotTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipLeft, setTooltipLeft] = useState(false);

  useEffect(() => {
    const tooltipEl = tooltipRef.current;

    function handleMouseMove(event: MouseEvent) {
      if (!tooltipEl) return;
      const { width: tooltipWidth } = tooltipEl.getBoundingClientRect();
      setTooltipLeft(event.clientX + tooltipWidth > window.innerWidth);
    }
    if (isVisible) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isVisible]);

  return (
    <div
      ref={tooltipRef}
      style={{
        display: isVisible ? 'block' : 'none',
        left: `${x}px`,
        top: `${y}px`,
      }}
      className={classNames(styles['tooltip'], {
        [styles.tooltipLeft]: tooltipLeft,
      })}
      data-testid="plot-tooltip"
    >
      {children}
    </div>
  );
};

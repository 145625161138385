import { Views } from '@revelio/core';
import { D3ChartNames } from '@revelio/d3';

import { SerializedFiltersForQuery } from '../filters.model';

export interface IEntitiesTransformArgs {
  entities: any;
  filters: SerializedFiltersForQuery;
  plotName: GqlPlotName;
}

export enum GqlPlotName {
  //** COMPOSITION **//
  HEADCOUNT = 'headcount',
  GROWTH_RATE = 'growth_rate',
  HIRING_RATE = 'hiring_rate',
  ATTRITION_RATE = 'attrition_rate',
  TENURE = 'tenure',
  SALARY = 'salary',

  ROLE = 'role',
  INDUSTRY = 'industry',
  GEOGRAPHY = 'geo',
  SENIORITY = 'seniority',
  SKILL = 'skill',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  EDUCATION = 'education',

  HEADCOUNT_OVERTIME = 'headcount_overtime',
  GROWTH_RATE_OVERTIME = 'growth_rate_overtime',
  HIRING_RATE_OVERTIME = 'hiring_rate_overtime',
  ATTRITION_RATE_OVERTIME = 'attrition_rate_overtime',
  TENURE_OVERTIME = 'tenure_overtime',
  SALARY_OVERTIME = 'salary_overtime', // also used in POSTINGS

  ROLE_OVERTIME = 'role_overtime',
  GEOGRAPHY_OVERTIME = 'geo_overtime',
  SENIORITY_OVERTIME = 'seniority_overtime',
  SKILL_OVERTIME = 'skill_overtime',
  GENDER_OVERTIME = 'gender_overtime',
  ETHNICITY_OVERTIME = 'ethnicity_overtime',
  EDUCATION_OVERTIME = 'education_overtime',
  INDUSTRY_OVERTIME = 'industry_overtime',

  //** SENTIMENT RATING **//
  OVERALL = 'overall',
  BUSINESS = 'business',
  CAREER = 'career',
  COMPENSATION = 'compensation',
  WORKLIFE = 'worklife',
  CULTURE = 'culture',
  LEADERSHIP = 'leadership',
  DIVERSITY = 'diversity',
  RECOMMEND = 'recommend',

  OVERALL_OVERTIME = 'overall_overtime',
  BUSINESS_OVERTIME = 'business_overtime',
  CAREER_OVERTIME = 'career_overtime',
  COMPENSATION_OVERTIME = 'compensation_overtime',
  WORKLIFE_OVERTIME = 'worklife_overtime',
  CULTURE_OVERTIME = 'culture_overtime',
  LEADERSHIP_OVERTIME = 'leadership_overtime',
  DIVERSITY_OVERTIME = 'diversity_overtime',
  RECOMMEND_OVERTIME = 'recommend_overtime',

  //** SENTIMENT EFFECT **//
  EFFECT = 'effect',

  //** SENTIMENT REVIEWS **//
  REVIEWS_POSITIVE = 'positive',
  REVIEWS_NEGATIVE = 'negative',

  //** POSTINGS **//
  POSTINGS = 'posting',
  POSTINGS_OVERTIME = 'posting_overtime',
  POSTINGS_MULTI_OVERTIME = 'posting_multi_overtime',
  // SALARY_OVERTIME (see composition)
  TIME_TO_FILL_OVERTIME = 'time_to_fill_overtime',
  TOP_ROLES = 'top',

  //** TRANSITIONS **//
  TRANSITION = 'transition',

  //** TALENT DISCOVERY **//
  TALENT_DISCOVERY = 'talent_discovery',
}

export enum DataPath {
  COMPOSITION = 'composition',
  SENTIMENT_1D = 'sentiment1d',
  SENTIMENT_2D = 'sentiment2d',
  SENTIMENT_REVIEWS = 'sentimentReviews',
  POSTING = 'posting',
  TRANSITION_2D = 'transitions2D',
  TALENT_DISCOVERY = 'talent_discovery_search_v1_5',
}

export const GqlPlotNameMetaLookups: {
  [key in Views]?: {
    [key in GqlPlotName]?: {
      chartType: D3ChartNames;
      dataPath: string;
    };
  };
} = {
  // TODO: abstract dataPath and make param (companies/geos/roles)
  [Views.OVERVIEW]: {
    [GqlPlotName.HEADCOUNT]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GROWTH_RATE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.HIRING_RATE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.ATTRITION_RATE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.TENURE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SALARY]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },

    [GqlPlotName.ROLE]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GEOGRAPHY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SENIORITY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SKILL]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GENDER]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.ETHNICITY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.EDUCATION]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },

    [GqlPlotName.INDUSTRY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.INDUSTRY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.HEADCOUNT_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GROWTH_RATE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.HIRING_RATE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.ATTRITION_RATE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.TENURE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SALARY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.ROLE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GEOGRAPHY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SENIORITY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.SKILL_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.GENDER_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.ETHNICITY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
    [GqlPlotName.EDUCATION_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.COMPOSITION,
    },
  },

  // alll sentiment pages under `sentiment_rating`
  [Views.SENTIMENT_RATING]: {
    //** SENTIMENT RATING **//
    [GqlPlotName.OVERALL]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.BUSINESS]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.CAREER]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.COMPENSATION]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.WORKLIFE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.CULTURE]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.LEADERSHIP]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.DIVERSITY]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },
    [GqlPlotName.RECOMMEND]: {
      chartType: D3ChartNames.BarChartHorizontal,
      dataPath: DataPath.SENTIMENT_1D,
    },

    [GqlPlotName.OVERALL_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.BUSINESS_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.CAREER_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.COMPENSATION_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.WORKLIFE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.CULTURE_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.LEADERSHIP_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.DIVERSITY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },
    [GqlPlotName.RECOMMEND_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.SENTIMENT_2D,
    },

    //** SENTIMENT EFFECTS **//
    [GqlPlotName.EFFECT]: {
      chartType: D3ChartNames.BarChartHorizontalMirror,
      dataPath: DataPath.SENTIMENT_1D,
    },
  },

  [Views.POSTING]: {
    [GqlPlotName.POSTINGS_OVERTIME]: {
      chartType: D3ChartNames.MainPostingsPlot,
      dataPath: DataPath.POSTING,
    },
    [GqlPlotName.POSTINGS_MULTI_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.POSTING,
    },
    [GqlPlotName.SALARY_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.POSTING,
    },
    [GqlPlotName.TIME_TO_FILL_OVERTIME]: {
      chartType: D3ChartNames.LineChart,
      dataPath: DataPath.POSTING,
    },
    [GqlPlotName.TOP_ROLES]: {
      chartType: D3ChartNames.GroupedBarChartHorizontal,
      dataPath: DataPath.POSTING,
    },
  },

  [Views.TRANSITION]: {
    [GqlPlotName.TRANSITION]: {
      chartType: D3ChartNames.SankeyDiagram,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.ROLE]: {
      chartType: D3ChartNames.SankeyDiagram,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.INDUSTRY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.GEOGRAPHY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.SENIORITY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.GENDER]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.ETHNICITY]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
    [GqlPlotName.EDUCATION]: {
      chartType: D3ChartNames.StackedBarChartHorizontal,
      dataPath: DataPath.TRANSITION_2D,
    },
  },

  [Views.TALENT_DISCOVERY]: {
    [GqlPlotName.TALENT_DISCOVERY]: {
      chartType: D3ChartNames.None,
      dataPath: DataPath.TALENT_DISCOVERY,
    },
  },
};

import { PipelineColumnType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';

import { addDefaultIsSelectedToSubMenu } from './helpers';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  STANDARD_COMPANY_COLUMN,
} from './shared';
import { DEFAULT_INDUSTRY_PIPELINE_COLUMNS } from './workforce-dynamics';

export const POSTING_DYNAMICS_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: 'Levels of Aggregation',
    columns: [
      DEFAULT_INDUSTRY_PIPELINE_COLUMNS,
      STANDARD_COMPANY_COLUMN,
      addDefaultIsSelectedToSubMenu({
        columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
        includeColumns: DEFAULT_GEO_PIPELINE_COLUMNS.menuItems
          .map((item) => item.id)
          .filter((id) => id !== PipelineColumnType.Msa),
      }),
      {
        ...DEFAULT_ROLE_PIPELINE_COLUMNS,
        menuItems: [
          {
            id: PipelineColumnType.JobCategory,
            label: 'Role k7 (Job Category)',
            defaultIsSelected: true,
            columnFileSize: 512,
            rowPossibilities: 8,
          },
          {
            id: PipelineColumnType.RoleK50,
            label: 'Role k50',
            defaultIsSelected: true,
            columnFileSize: 512,
            rowPossibilities: 50,
          },
          {
            id: PipelineColumnType.RoleK150,
            label: 'Role k150',
            defaultIsSelected: true,
            columnFileSize: 512,
            rowPossibilities: 150,
          },
        ],
      },

      {
        id: PipelineColumnType.Seniority,
        defaultIsSelected: true,
        columnFileSize: 1,
        rowPossibilities: 7,
      },
    ],
  },
  {
    heading: 'Variables',
    columns: [
      {
        id: PipelineColumnType.ActivePosting,
        label: 'Active Posting',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.NewPosting,
        label: 'New Posting',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.RemovedPosting,
        label: 'Removed Posting',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },

      {
        id: PipelineColumnType.ActiveSalaryAvg,
        label: 'Active Salary Avg',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.NewSalaryAvg,
        label: 'New Salary Avg',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.RemovedSalaryAvg,
        label: 'Removed Salary Avg',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },

      {
        id: PipelineColumnType.ActiveSalaryCount,
        label: 'Active Salary Count',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.NewSalaryCount,
        label: 'New Salary Count',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
      {
        id: PipelineColumnType.RemovedSalaryCount,
        label: 'Removed Salary Count',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },

      {
        id: PipelineColumnType.FillingTimeAvg,
        label: 'Filling Time Avg',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
    ],
    minColumnsRequired: 1,
  },
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { withResizeDetector } from 'react-resize-detector';

import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { LineChartGenerator } from '../plot-generators';

const LineChart = (plotConfigs) => {
  const {
    name,
    data,
    height,
    width,
    targetRef,
    requestHash,
    colorLookup,
    customMargins,
    lineColor,
    areaFillColor,
    ttPosition,
    setChartHasRendered,
    leftFade,
  } = plotConfigs;

  const [plotData, otherTabData] = data;

  const considerMultiTabs = Array.isArray(otherTabData);

  const dataConfigsForChart = considerMultiTabs
    ? {
        data: plotData,
        otherTabData,
      }
    : { data };

  const setLineChartHasRendered = setChartHasRendered
    ? (hasRendered) => setChartHasRendered(name, hasRendered)
    : undefined;

  useD3(
    LineChartGenerator,
    {
      ...plotConfigs,
      ...dataConfigsForChart,
      setChartHasRendered: setLineChartHasRendered,
    },
    [
      requestHash,
      targetRef.current,
      width,
      height,
      data,
      colorLookup,
      customMargins,
      lineColor,
      areaFillColor,
      ttPosition,
      setLineChartHasRendered,
      leftFade,
    ]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

LineChart.propTypes = {
  name: PropTypes.string.isRequired,
  chartSize: PropTypes.string,
  chartPosition: PropTypes.string,
  chartStyle: PropTypes.string.isRequired,
  metaValueCompany: PropTypes.string.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  ttType: PropTypes.string.isRequired,
  ttCustomString: PropTypes.string.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  colorLookupKeyPath: PropTypes.string.isRequired,
  // format: PropTypes.string,
};

LineChart.defaultProps = {
  name: 'default',
  chartSize: 'medium',
  chartPosition: '',
  chartStyle: '',
  metaValueCompany: 'shortName',
  yAxisFormat: '.0%',
  dateFormat: 'YM',
  ttType: 'percent',
  ttCustomString: 'days',
  ttMainFormat: '.1%',
  ttSecondaryFormat: ',',
  data: [],
  colorLookupKeyPath: 'id',
};

export default withResizeDetector(LineChart, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});

import styles from './plot-tooltip.module.css';

type TooltipHoverLineProps = {
  x: number;
  height: number;
};
export const TooltipHoverLine = ({ x, height }: TooltipHoverLineProps) => {
  return (
    <line
      x1={x}
      x2={x}
      y1={0}
      y2={height}
      className={styles.tooltipHoverLine}
    />
  );
};

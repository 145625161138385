import { useCallback, useMemo } from 'react';

import {
  CustomReportMeta,
  triggerReportsFromAuthRefresh,
  useAuthStore,
} from '@revelio/auth';
import {
  GetReportConfigQuery,
  GetUserReportsQuery,
  ReportTypeEnum,
} from '@revelio/data-access';

import { useUserBuiltReports } from './use-user-built-reports';

export type UnifiedReportType = `${ReportTypeEnum}` | 'GeneratedByRevelio';

type UserReportsData = GetUserReportsQuery;
type UsersType = NonNullable<UserReportsData['users']>;
type UserType = NonNullable<UsersType[number]>;
type MiddlewareReportsType = NonNullable<UserType['reports']>;
type MiddlewareReportType = NonNullable<MiddlewareReportsType[number]>;
export interface UnifiedReport extends CustomReportMeta {
  reportType: UnifiedReportType;
  reportBuilderData?: MiddlewareReportType; // if built using report builder this data will be populated
}

type UseAllReportArgs = {
  selectedUserId: string | undefined;
  isGroupOption: boolean | undefined;
};
export const useAllReports = ({
  selectedUserId,
  isGroupOption,
}: UseAllReportArgs) => {
  const { user, reports } = useAuthStore();
  const [{ data, fetching: fetchingMiddleware }, reExecuteMiddleware] =
    useUserBuiltReports(
      selectedUserId && !isGroupOption ? [selectedUserId] : []
    );

  const isAdminAccessingAnotherUsersReport = selectedUserId !== user?.id;

  const unifiedReports = useMemo(() => {
    if (fetchingMiddleware) {
      return [];
    }

    const allReportsById = isAdminAccessingAnotherUsersReport
      ? {}
      : (reports ?? []).reduce((obj: Record<string, UnifiedReport>, item) => {
          obj[item.id] = { ...item, reportType: 'GeneratedByRevelio' };
          return obj;
        }, {});
    const reportsFromMiddleware: MiddlewareReportsType =
      (data && data.users && data.users[0]?.reports) || [];

    reportsFromMiddleware.forEach((report) => {
      const reportType = report.report.reportType;
      if (report.uuid && allReportsById[report.uuid]) {
        allReportsById[report.uuid] = {
          ...allReportsById[report.uuid],
          reportType,
          reportBuilderData: report,
        };
      } else if (report.uuid) {
        allReportsById[report.uuid] = {
          id: report.uuid,
          name: report.name || '',
          createdAt: report.created_at || '',
          updatedAt: report.updated_at || '',
          reportType,
          reportBuilderData: report,
        };
      }
    });
    return Object.keys(allReportsById).map(
      (reportId) => allReportsById[reportId]
    );
  }, [fetchingMiddleware, isAdminAccessingAnotherUsersReport, reports, data]);

  const executeQuery = useCallback(() => {
    triggerReportsFromAuthRefresh();
    reExecuteMiddleware({ requestPolicy: 'network-only' });
  }, [reExecuteMiddleware]);

  return {
    reports: unifiedReports,
    fetching: fetchingMiddleware,
    executeQuery,
  };
};

export const getUnifiedReportTypeDisplayText = (
  reportType: UnifiedReportType,
  config: GetReportConfigQuery | undefined
) => {
  const label = config?.reports_validation.find(
    (report) => report?.report_validation?.report_type === reportType
  )?.report_validation?.label;

  if (reportType === 'GeneratedByRevelio') {
    return 'Generated by Revelio Labs';
  }

  return label || 'Unknown Report Type';
};

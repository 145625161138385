import { useUserCurrency } from '@revelio/auth';
import { PopupDataKey, TalentDiscoveryMap } from '@revelio/d3';
import {
  CustomRoleFilter,
  METRIC_MODE_IDS,
  TalentDiscoveryDataQuery,
} from '@revelio/data-access';
import { Card } from '@revelio/layout';
import { formatterWithExchangeRate } from '@revelio/replots';

const popupDataKeys = [
  'headcount',
  'postings',
  'timeToFill',
  'salaryDist',
] as PopupDataKey[];

export const CompanyMap = ({
  mapData,
  postingsStartDate,
  postingsEndDate,
  custom_role,
}: {
  mapData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5']['map_data'];
  postingsStartDate: string | undefined;
  postingsEndDate: string | undefined;
  custom_role?: CustomRoleFilter;
}) => {
  const { exchangeRate, code } = useUserCurrency();

  const salaryFormatter = formatterWithExchangeRate(code, exchangeRate);

  return (
    <Card p="12px">
      <TalentDiscoveryMap
        data={{
          data: mapData || [],
          popupDataKeys,
        }}
        minZoomLevel={1}
        tooltipHeight={200}
        initialCoords={{
          lng: 20,
          lat: 15,
        }}
        tooltipRequestOverrides={{
          metric_mode: METRIC_MODE_IDS.expectedHires,
          postings_start_date: postingsStartDate,
          postings_end_date: postingsEndDate,
          custom_role,
        }}
        headcountFromPostings
        skipPreFetch
        currencyFormatter={salaryFormatter}
      />
    </Card>
  );
};

import { isNil } from 'lodash';

import {
  D3ChartNames,
  ID3ChartProps,
  formatPercentageToTwoSigFigs,
} from '@revelio/d3';
import { CompositionDataQuery, Dimension1 } from '@revelio/data-access';

import { LegacyChartProps } from '../../../../shared/components';
import {
  CompanySummaryMetric,
  CompetitorBarChartsData,
  SingleOption,
  SummaryEntities,
} from '../../types';

const getCompetitorCompositionData = (
  data: CompositionDataQuery | undefined,
  primaryRCID: number
) => {
  const entities = data?.composition;

  if (!entities) {
    return {
      headcount: null,
      attrition_rate: null,
      growth_rate: null,
      hiring_rate: null,
    };
  }

  const getData = (metric: CompanySummaryMetric) =>
    getCompetitorBarChartMetricData(entities, metric, primaryRCID);

  return {
    headcount: getData(CompanySummaryMetric.Headcount),
    attrition_rate: getData(CompanySummaryMetric.AttritionRate),
    growth_rate: getData(CompanySummaryMetric.GrowthRate),
    hiring_rate: getData(CompanySummaryMetric.HiringRate),
  };
};

const getCompetitorBarChartMetricData = (
  entities: SummaryEntities,
  metric: CompanySummaryMetric,
  primaryRCID: number
) => {
  return entities
    .map((entity) => {
      const metaData = entity?.metadata;
      const timeSeries = entity?.metrics?.[metric]?.timeseries;
      const value = timeSeries?.[timeSeries?.length - 1]?.value;

      if (!metaData || isNil(value)) {
        return null;
      }

      return {
        id: metaData.id,
        value: value,
        metadata: {
          longName: metaData.longName,
          shortName: metaData.shortName,
          type: Dimension1.Company,
          isPrimary: metaData.id === primaryRCID,
        },
      };
    })
    .filter((data) => data !== null)
    .sort((a, b) => (b?.value || 0) - (a?.value || 0));
};

const getCompetitorBarChartProps = (
  compositionData: CompetitorBarChartsData,
  selectedChart: SingleOption
) => {
  const data = compositionData[selectedChart?.value as CompanySummaryMetric];
  const formats = ['growth_rate', 'hiring_rate', 'attrition_rate'].includes(
    selectedChart?.value || ''
  )
    ? { base: '.1%', tooltip: '.1%', func: formatPercentageToTwoSigFigs }
    : { base: '.2s', tooltip: ',.0f', preformatter: 'd' };

  return {
    chartPosition: 'right',
    chartSize: 'large',
    colorIndex: 0,
    customFormatter: formats.func,
    customMargins: undefined,
    data,
    format: formats.base,
    formatCustomString: '',
    isRenderingOrLoading: undefined,
    metaValue: 'shortName',
    name: 'competitors',
    preformatter: formats.preformatter,
    requestHash: undefined,
    singleColor: undefined,
    textAboveBar: false,
    tooltipCustomValue: undefined,
    tooltipFormat: formats.tooltip,
    tooltipFormatCustomString: '',
    tooltipMetaValue: 'longName',
    ttType: '',
  };
};

export const getLegacyBarChartProps = ({
  compositionData,
  primaryRCID,
  selectedMetric,
}: {
  compositionData: CompositionDataQuery;
  primaryRCID: number;
  selectedMetric: SingleOption;
}): LegacyChartProps => {
  const competitorData = getCompetitorCompositionData(
    compositionData,
    primaryRCID
  );
  const props = getCompetitorBarChartProps(competitorData, selectedMetric);
  return {
    type: D3ChartNames.BarChartHorizontal,
    props: props as ID3ChartProps,
    data: props.data,
  };
};

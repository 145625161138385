import { deburr } from 'lodash';

import { TNode } from '../Node';

export const searchMatch = (node: TNode, search: string) =>
  isDirectMatch(node, search) || isSuggestedMatch(node, search);

/** ================= Direct Match ================= */
export const isDirectMatchString = (value: string, search: string) =>
  value.toLowerCase().trim().includes(search.toLowerCase().trim());
export const isDirectMatch = (node: TNode, search: string) =>
  isDirectMatchString(node.data.name, search);

/** ================= Suggested Match ================= */
export const isSuggestedMatchString = (values: string[], search: string) =>
  values.some((value) =>
    deburr(value.toLowerCase().trim()).includes(search.toLowerCase().trim())
  );
export const isSuggestedMatch = (node: TNode, search: string) => {
  const suggestedTitles = node.data?.suggestedTitles;
  if (!suggestedTitles) return false;
  return isSuggestedMatchString(suggestedTitles, search);
};

import styles from './kde-tooltip.module.css';

export type KdeTooltipProps = {
  title: string;
  values: {
    label: string;
    color: string;
    description: string;
  }[];
};
export const KdeTooltip = ({ title, values }: KdeTooltipProps) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      {values.map(({ label, color, description }) => (
        <div key={label} className={styles.content}>
          <div className={styles.labelContainer}>
            <div
              className={styles.labelColor}
              style={{ backgroundColor: color }}
            />
            <div className={styles.valueLabel}>{label}</div>
          </div>
          <div className={styles.valueDescription}>{description}</div>
        </div>
      ))}
    </div>
  );
};

import { BottomPlotName, TopPlotName } from './get-chart-data';

export const plotNameToLegacyDownloadName: Record<
  TopPlotName | BottomPlotName,
  string
> = {
  headcount: 'headcount',
  growth_rate: 'growth-rate',
  hiring_rate: 'hiring-rate',
  attrition_rate: 'attrition-rate',
  tenure: 'tenure',
  salary: 'salary',
  job_categories: 'job-category',
  geographies: 'geography',
  industries: 'industry',
  skills: 'skill',
  genders: 'gender',
  ethnicities: 'ethnicity',
  educations: 'education',
};

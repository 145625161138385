import {
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  SimpleGridProps,
  useDisclosure,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import CardListSelect, { ICardListSelectProps } from './card-list-select';

export function isColumnCardListSubmMenu<CardType>(
  column: CardListSubMenuProps<CardType> | ICardListSelectProps<CardType>
): column is CardListSubMenuProps<CardType> {
  return (column as CardListSubMenuProps<CardType>).menuItems !== undefined;
}

export type OnCloseSubMenuProp<CardType> = (
  previouslyCheckedColumns: ICardListSelectProps<CardType>[],
  allSubMenuColumns: ICardListSelectProps<CardType>[]
) => void;
export interface CardListSubMenuProps<CardType>
  extends ICardListSelectProps<string> {
  gridProps?: SimpleGridProps;
  menuItems: (ICardListSelectProps<CardType> & {
    excludeFromFileRowMax?: boolean;
  })[];
  onCloseSubMenu?: OnCloseSubMenuProp<CardType>;
}
function CardListSubMenu<CardType extends string>({
  menuItems,
  gridProps,
  isRequired,
  onCloseSubMenu,
  ...menuButtonProps
}: CardListSubMenuProps<CardType>) {
  const checkedSubItems = menuItems.filter((col) => col.isChecked);
  const hasCheckSubItems = !!checkedSubItems.length;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkedColumnsBeforeEditing, setCheckedColumnsBeforeEditing] =
    useState(checkedSubItems);

  useEffect(() => {
    // for the case of other code changing checked items, e.g. "reset to default" cta.
    // when it is re-opened and cancelled we want the latest checked items (according to state), not the ones before they were edited by the other code
    if (!isOpen && !isEqual(checkedColumnsBeforeEditing, checkedSubItems)) {
      setCheckedColumnsBeforeEditing(checkedSubItems);
    }
  }, [isOpen, checkedSubItems, checkedColumnsBeforeEditing]);

  const handleCloseSubMenuWithoutSelection = () => {
    if (onCloseSubMenu) {
      onCloseSubMenu(checkedColumnsBeforeEditing, checkedSubItems);
    }
    onClose();
  };
  return (
    <>
      <CardListSelect<string>
        {...menuButtonProps}
        label={`${menuButtonProps.label}${isRequired ? '*' : ''} ${
          checkedSubItems.length ? `(${checkedSubItems.length} sel)` : ''
        }`}
        isChecked={hasCheckSubItems}
        onChange={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleCloseSubMenuWithoutSelection}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="text.primary">
            {menuButtonProps.label}
          </ModalHeader>
          <ModalCloseButton />

          <Divider orientation="horizontal" />

          <ModalBody>
            <SimpleGrid
              spacing={4}
              columns={menuItems.length >= 4 ? 2 : 1}
              gridAutoFlow="row"
              {...gridProps}
            >
              {menuItems.map((item, i) => (
                <CardListSelect<CardType> key={item.id} {...item} />
              ))}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button
                variant="link"
                fontSize="12px"
                colorScheme="gray"
                onClick={handleCloseSubMenuWithoutSelection}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                borderRadius="4px"
                fontSize="12px"
                fontWeight="600"
                colorScheme="green"
                variant="solid"
                size="sm"
                onClick={() => {
                  setCheckedColumnsBeforeEditing(checkedSubItems);
                  onClose();
                }}
              >
                Select
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CardListSubMenu;

import {
  Button,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect$, useUntilDestroyed } from '@ngneat/react-rxjs';
import {
  isEmpty as _isEmpty,
  find,
  get,
  intersection,
  isArray,
  isNil,
  isUndefined,
  truncate,
  uniq,
} from 'lodash';
import { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { combineLatest, of } from 'rxjs';
import {
  auditTime,
  filter,
  isEmpty,
  pairwise,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PopoverTrigger } from '@revelio/core';
import { CustomTaxonomyEnum } from '@revelio/data-access';

import { useSelectionLists } from '../../engine/filters.engine';
import {
  AnyFilter,
  FilterItem,
  FilterList,
  OtherFilterNames,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
} from '../../engine/filters.model';
import {
  getManyFiltersState,
  getSingleFilterState,
  selectionListDataSource,
  upsertFilter,
  useSelectFilterById,
} from '../../engine/filters.repository';
import { useRoleTaxonomySetting } from '../../engine/role-taxonomy/filters.role-taxonomy';
import { Option } from '../selection-list/selection-list-select';
import { SubFilterList } from './sub-filter-list';
import { SubFilterTree } from './sub-filter-tree';
import { SubFilterState } from './sub-filter.types';

interface ISelectionListsResponse {
  selectionLists: SelectionList[];
}

export interface SubFilterProps {
  placementOverride?: PlacementWithLogical;
  defaultState?: SubFilterState;
  filterName?: string | SelectionListIdNames;
  selectionLists?: SelectionListIdNames[];
  selectionLimit?: number;
  minSelections?: number;
  subfiltersMap?: any;
}

export const SubFilter = ({
  placementOverride = 'bottom',
  defaultState = {},
  filterName,
  selectionLists = [],
  selectionLimit = 6,
  minSelections = 1,
  subfiltersMap,
}: SubFilterProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const parentLists = subfiltersMap
    ? (Object.keys(subfiltersMap) as Array<SelectionListIdNames>)
    : selectionLists;

  const subLists = subfiltersMap
    ? (Object.values(subfiltersMap) as Array<SelectionListIdNames>)
    : [];

  const [mappedSelectionLists, setMappedSelectionLists] = useState(() => {
    let mappedLists: SelectionListIdNames[] = [];

    if (subfiltersMap) {
      const parentFilterName = selectionLists[0];
      const childFilterName = subfiltersMap[selectionLists[0]];

      if (parentFilterName === childFilterName) {
        mappedLists = [parentFilterName];
      } else {
        mappedLists = [parentFilterName, childFilterName];
      }
    }

    return mappedLists;
  });

  const selectionListsToFetch = uniq([...parentLists, ...subLists]);

  // @NOTE: this is so Skills lists still coming from the old backend aren't tried to be fetched from the new and empty results overwrite the old one's
  if (
    intersection(selectionListsToFetch, [
      SelectionCategories.SKILL,
      SelectionCategories.KEYWORD,
      SelectionCategories.KEYWORDS_CATEGORY,
    ]).length == 0
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelectionLists(selectionListsToFetch);
  }

  const { untilDestroyed } = useUntilDestroyed();

  const parentFilterState$ = useRef(getManyFiltersState(parentLists));

  const { current: subfilterState$ } = useRef(
    isUndefined(filterName)
      ? of([])
      : getManyFiltersState([filterName as SubFilterNames])
  );

  const [displayText, setDisplayText] = useState('');
  const [disableParentSelect, setDisableParentSelect] = useState(false);
  const [parentFilter, setParentFilter] = useState<(string | number)[]>([]);
  const [flatOptions, setFlatOptions] = useState<Option[]>([]);

  const isFlatSelectionList = (() => {
    if (selectionLists.length === 1 || parentFilter.length === 0) {
      return true;
    }

    return false;
  })();

  useEffect$(
    () =>
      parentFilterState$.current.pipe(
        isEmpty(),
        auditTime(0),
        tap((isEmptyObs) => {
          if (isEmptyObs && !_isEmpty(defaultState)) {
            upsertFilter(filterName as SelectionCategories, defaultState);
          }
        })
      ),
    []
  );

  useEffect$(
    () =>
      combineLatest([
        parentFilterState$.current.pipe(startWith(null), pairwise()),
        selectionListDataSource.data$({
          key: selectionListsToFetch,
        }),
      ]).pipe(
        withLatestFrom(subfilterState$),
        untilDestroyed(),
        auditTime(0),
        filter<any>((stuff) => {
          return !isUndefined(stuff[1]);
        }),
        tap(
          (
            value: [
              [SelectFilter<FilterList>[][], ISelectionListsResponse],
              AnyFilter<any>[],
            ]
          ): any => {
            const [[filter, list], [subfilterState]] = value;

            //  Assumptions:
            //  1. Primary Selections from MappedSubfilters are restricted to a single level.
            //     we will never have Primary Selections for these across levels.
            //  2. Selection List Ids for Nested Lists are in the following order: [parentId, childId]

            const isMappedSubfilter = subLists.length > 0;

            const [prevFilter, curFilter] = filter;

            const parentId =
              isMappedSubfilter && curFilter.length > 0
                ? curFilter[0]?.selectionListId
                : selectionLists[0];

            const childId = isMappedSubfilter
              ? subfiltersMap?.[parentId as SelectionListIdNames]
              : selectionLists[1];

            const isNestedMappedSubfilter =
              isMappedSubfilter && parentId !== childId;

            const isNestedList = isMappedSubfilter
              ? isNestedMappedSubfilter
              : selectionLists.length > 1;

            if (isMappedSubfilter) {
              setMappedSelectionLists(
                isNestedMappedSubfilter ? [parentId, childId] : [parentId]
              );
            }

            const curParentFilter = isMappedSubfilter
              ? curFilter[0]
              : curFilter.find((listItem) => listItem?.id === parentId);

            const childList = list.selectionLists.find((li) => {
              if (isMappedSubfilter) {
                return li.id === childId;
              }

              return li.id === selectionLists[isNestedList ? 1 : 0];
            });

            const prevValuelen = prevFilter?.reduce(
              (prev, curr) => prev + (curr?.value?.length || 0),
              0
            );

            const curValuelen = curFilter?.reduce(
              (prev, curr) => prev + (curr?.value?.length || 0),
              0
            );

            const parentIdFilter =
              curParentFilter?.value.map((v) => v.id) || [];

            if (isFlatSelectionList) {
              const listToUse =
                isNestedList && curValuelen > 0
                  ? childList
                  : list.selectionLists.find(
                      (li) => li.id === selectionLists[0]
                    );

              if (listToUse && listToUse.value) {
                const options = listToUse.value.map((item: any) => ({
                  value: item.id.toString(),
                  label: item.label || item.name || item.shortName,
                  entity: item,
                }));
                setFlatOptions(options);
              }
            }

            setDisableParentSelect(isNestedList && curValuelen > 0);
            setParentFilter(parentIdFilter);

            const isSubFiltersAlreadySet = !isUndefined(subfilterState);
            if (!isNil(curValuelen) && curValuelen > 0) {
              let defaultsToUpsert = [];

              if (isSubFiltersAlreadySet) {
                if (subfilterState.value) {
                  defaultsToUpsert = subfilterState.value.filter((val: any) => {
                    const subFilterParentIds = get(val, parentId || '');
                    if (isArray(subFilterParentIds)) {
                      // in the case of supporting multiple parents, subfilter parent ids are arrays
                      return intersection(parentIdFilter, subFilterParentIds)
                        .length;
                    } else {
                      return parentIdFilter.includes(
                        isNestedList ? subFilterParentIds : val.id
                      );
                    }
                  });
                }
              }

              if (defaultsToUpsert.length === 0) {
                defaultsToUpsert = [
                  find(childList?.value, (v) => {
                    if (isUndefined(parentId) || v.id == 0) {
                      return false;
                    }

                    const parentListId = get(v, parentId);

                    if (isArray(parentListId)) {
                      return (
                        intersection(parentIdFilter, parentListId).length > 0
                      );
                    }

                    return parentIdFilter.includes(parentListId || v.id);
                  }),
                ];
              }

              if (defaultsToUpsert.length > 0) {
                const defaultValue = {
                  ...defaultState,
                  selectionListId:
                    curValuelen && isNestedList ? childId : parentId,
                  value: defaultsToUpsert,
                };

                upsertFilter(filterName as SelectionCategories, defaultValue);
              }
            }

            if (
              !isNil(prevValuelen) &&
              (isNil(curValuelen) || curValuelen === 0)
            ) {
              upsertFilter(filterName as SelectionCategories, defaultState);
            }
          }
        )
      ),
    []
  );

  const { value: roleTaxonomySetting } = useRoleTaxonomySetting();

  useEffect$(
    () =>
      combineLatest([
        getSingleFilterState(filterName as OtherFilterNames),
        parentFilterState$.current.pipe(startWith('nothing')),
        selectionListDataSource.data$({
          key: [SelectionCategories.JOB_CATEGORY],
        }),
      ]).pipe(
        untilDestroyed<any>(),
        tap(
          ([
            filterState,
            parentFilterState,
            { selectionLists: selectionListsData },
          ]: [SelectFilter, any, any]) => {
            if (filterState) {
              const toReduceValue = filterState.isMulti
                ? (filterState?.value as FilterList<ValidValueTypes>)
                : [filterState?.value as FilterItem<ValidValueTypes>];

              const toDisplay = toReduceValue.reduce(
                (result: any, cur: any, i: number) => {
                  if (i == 0) {
                    let displayLabel = get(
                      cur,
                      'shortName',
                      get(cur, 'label', 'Unknown')
                    );

                    if (
                      roleTaxonomySetting !== CustomTaxonomyEnum.Disabled &&
                      filterName === SubFilterNames.SUB_ROLE &&
                      parentFilterState.length === 0
                    ) {
                      const mappedValue = selectionListsData[0]?.value.find(
                        (role: any) => {
                          return role.id === cur.id;
                        }
                      );

                      if (mappedValue) {
                        displayLabel = mappedValue?.shortName;
                      }
                    }

                    return truncate(displayLabel, {
                      length: 20,
                      omission: '...',
                    });
                  }
                  if (i == 1) {
                    return 2;
                  }
                  return result + 1;
                },
                ''
              );
              const finalToDisplay = Number.isInteger(toDisplay)
                ? `${toDisplay} Selections`
                : toDisplay;
              setDisplayText(finalToDisplay);
            } else {
              setDisplayText('No Selection');
            }
          }
        )
      ),
    [roleTaxonomySetting, filterName]
  );

  const treeSelectionLists = subfiltersMap
    ? mappedSelectionLists
    : selectionLists;
  const filterState = useSelectFilterById(filterName as OtherFilterNames);

  if (!selectionLists) {
    return <span></span>;
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placementOverride}
      defaultIsOpen={false}
      isLazy={isFlatSelectionList}
      lazyBehavior="unmount"
    >
      <PopoverTrigger>
        <Button
          data-testid={`plot-sub-filter-${filterName}-trigger`}
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          aria-label="Plot Sub-filter"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
        >
          {displayText}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody data-testid="subfilter-menu-popover">
            {isFlatSelectionList ? (
              <SubFilterList
                options={flatOptions}
                filterName={filterName}
                filterState={filterState}
                selectionLists={selectionLists}
                defaultState={defaultState}
                minSelections={minSelections}
                onClose={onClose}
              />
            ) : (
              <SubFilterTree
                filterName={filterName}
                parentFilter={parentFilter}
                filterState={filterState}
                selectionLists={treeSelectionLists}
                disableParentSelect={disableParentSelect}
                minSelections={minSelections}
                maxSelections={selectionLimit}
                onClose={onClose}
              />
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Flex } from '@chakra-ui/layout';
import { Input } from '@chakra-ui/react';
import {
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';

import { getDisplayTextWidth } from './custom/custom-column-form-helpers';

interface CustomStepNameProps<FormInputs extends FieldValues> {
  id: string;
  nameError: FieldError | undefined;
  customName: string;
  fieldPath: Path<FormInputs>;
  register: UseFormRegister<FormInputs>;
  existingCustomNames: string[];
  currentCustomName: string | boolean;
}
export function CustomStepName<FormInputs extends FieldValues>({
  id,
  fieldPath,
  register,
  customName,
  existingCustomNames,
  currentCustomName,
  nameError,
}: CustomStepNameProps<FormInputs>) {
  return (
    <FormControl id={id} isInvalid={!!nameError} pl="2" my="2" height="30px">
      <Flex alignItems="center">
        <Input
          fontWeight="600"
          fontSize="lg"
          pl="3"
          height="30px"
          variant="unsyled"
          {...register(fieldPath, {
            required: 'name is required.',
            minLength: {
              value: 1,
              message: 'Minimum length should be 1',
            },
            pattern: {
              // regex to check if string does not start with user
              value: /^[a-zA-Z0-9_]+$/,
              message:
                'Name can only contain letters, numbers, and underscores',
            },
            validate: {
              nameUnique: (value) => {
                if (
                  existingCustomNames &&
                  existingCustomNames.includes(value) &&
                  value !== currentCustomName
                ) {
                  return 'Name must be unique.';
                }

                return true;
              },
            },
          })}
          placeholder="Custom Name"
          _hover={{
            borderColor: 'gray.300',
            borderWidth: '1px',
          }}
          _focus={{
            borderColor: 'gray.300',
            borderWidth: '1px',
          }}
          minW="100px"
          width={getDisplayTextWidth(customName)}
        />
        <FormErrorMessage mt="0" ml="1">
          {nameError && nameError.message}
        </FormErrorMessage>
      </Flex>
    </FormControl>
  );
}

import { swapKeysWithValues } from '@revelio/core';
import {
  CustomColumnConditionVariable,
  OperationType,
} from '@revelio/data-access';
import {
  SelectionCategories,
  SelectionListIdNames,
  TreeItem,
} from '@revelio/filtering';

export enum VariableSelectionTypes {
  Numeric = 'Numeric',
  SelectionList = 'SelectionList',
  String = 'String',
}

export enum MultiGranularitySelectionListVariables {
  GEOGRAPHY = 'Geography',
  ROLE = 'Role',
  SKILL = 'Skill',
}
export const isMultiGranularitySelectionListVariable = (
  variable?:
    | NumericVariable
    | SelectionListVariable
    | StringVariable
    | CustomColumnConditionVariable
): variable is MultiGranularitySelectionListVariables => {
  return Object.values(MultiGranularitySelectionListVariables).includes(
    variable as MultiGranularitySelectionListVariables
  );
};

export type StringVariable =
  | CustomColumnConditionVariable.Title
  | CustomColumnConditionVariable.Description
  | CustomColumnConditionVariable.ReviewPros
  | CustomColumnConditionVariable.ReviewCons
  | CustomColumnConditionVariable.Skill;
export const stringVariableValues = [
  CustomColumnConditionVariable.Title,
  CustomColumnConditionVariable.Description,
  CustomColumnConditionVariable.ReviewPros,
  CustomColumnConditionVariable.ReviewCons,
  CustomColumnConditionVariable.Skill,
];
export const isStringVariable = (
  variable:
    | CustomColumnConditionVariable
    | MultiGranularitySelectionListVariables
): variable is StringVariable => {
  if (variable in MultiGranularitySelectionListVariables) {
    return false;
  }

  return stringVariableValues.includes(
    variable as CustomColumnConditionVariable
  );
};
export enum StringOperation {
  CONTAINS_AT_LEAST_ONE = OperationType.ContainsAtLeastOne,
  CONTAINS_AT_LEAST_ONE_CASE_SENSITIVE = OperationType.ContainsAtLeastOneCaseSensitive,
  CONTAINS_ALL = OperationType.ContainsAll,
  CONTAINS_ALL_CASE_SENSITIVE = OperationType.ContainsAllCaseSensitive,
  DOES_NOT_CONTAIN_ALL = OperationType.DoesNotContainAll,
  DOES_NOT_CONTAIN_ALL_CASE_SENSITIVE = OperationType.DoesNotContainAllCaseSensitive,
  EQUAL = OperationType.EqualTo,
  DOES_NOT_EQUAL = OperationType.DoesNotEqual,
}
export type StringCondition = {
  variable: StringVariable;
  operation: StringOperation | null;
  value: string[] | null;
  index?: number;
};

export type NumericVariable =
  | CustomColumnConditionVariable.Salary
  | CustomColumnConditionVariable.TotalCompensation
  | CustomColumnConditionVariable.RemoteSuitability
  | CustomColumnConditionVariable.Prestige
  | CustomColumnConditionVariable.RatingOverall;
export const numericVariableValues = [
  CustomColumnConditionVariable.Salary,
  CustomColumnConditionVariable.TotalCompensation,
  CustomColumnConditionVariable.RemoteSuitability,
  CustomColumnConditionVariable.Prestige,
  CustomColumnConditionVariable.RatingOverall,
];
export const isNumericVariable = (
  variable: CustomColumnConditionVariable
): variable is NumericVariable => {
  return numericVariableValues.includes(variable);
};
export enum NumericOperation {
  GREATER_THAN = OperationType.GreaterThan,
  LESS_THAN = OperationType.LessThan,
  BETWEEN = OperationType.InBetween,
}
export type NumericCondition = {
  variable: NumericVariable;
  operation: NumericOperation | null;
  value: number[] | null;
  index?: number;
};

export type SelectionListVariable =
  | MultiGranularitySelectionListVariables.GEOGRAPHY
  | MultiGranularitySelectionListVariables.ROLE
  | MultiGranularitySelectionListVariables.SKILL
  | CustomColumnConditionVariable.HighestDegree
  | CustomColumnConditionVariable.Ethnicity
  | MultiGranularitySelectionListVariables.SKILL
  | CustomColumnConditionVariable.Gender
  | CustomColumnConditionVariable.Seniority;

export const selectionListVariableValues = [
  MultiGranularitySelectionListVariables.GEOGRAPHY,
  MultiGranularitySelectionListVariables.ROLE,
  MultiGranularitySelectionListVariables.SKILL,
  CustomColumnConditionVariable.Region,
  CustomColumnConditionVariable.Country,
  CustomColumnConditionVariable.State,
  CustomColumnConditionVariable.MetroArea,
  CustomColumnConditionVariable.RoleK7,
  CustomColumnConditionVariable.RoleK50,
  CustomColumnConditionVariable.RoleK150,
  CustomColumnConditionVariable.RoleK300,
  CustomColumnConditionVariable.RoleK500,
  CustomColumnConditionVariable.RoleK1000,
  CustomColumnConditionVariable.RoleK1250,
  CustomColumnConditionVariable.MappedRole,
  CustomColumnConditionVariable.HighestDegree,
  CustomColumnConditionVariable.Ethnicity,
  CustomColumnConditionVariable.SkillMapped,
  CustomColumnConditionVariable.SkillK25,
  CustomColumnConditionVariable.SkillK50,
  CustomColumnConditionVariable.SkillK75,
  CustomColumnConditionVariable.SkillK700,
  CustomColumnConditionVariable.SkillK2500,
  CustomColumnConditionVariable.Gender,
  CustomColumnConditionVariable.Seniority,
];
export const isSelectionListVariable = (
  variable:
    | CustomColumnConditionVariable
    | MultiGranularitySelectionListVariables
): variable is SelectionListVariable => {
  return selectionListVariableValues.includes(variable);
};
export enum SelectionListOperation {
  CONTAINS_AT_LEAST_ONE = OperationType.ContainsAtLeastOne,
  DOES_NOT_CONTAIN_ANY_OF = OperationType.DoesNotContainAll,
}
export type SelectionListCondition = {
  variable: SelectionListVariable;
  operation: SelectionListOperation;
  value: SimpleFormTreeItem[] | null;
  index?: number;
};

export type Condition =
  | NumericCondition
  | SelectionListCondition
  | StringCondition;

export type SharedConditionValueProps<T> = {
  conditionId: string;
  selectedValues: T[];
  setSelections: (selections: T[]) => void;
  isDisabled: boolean;
  clearError: () => void;
  onNoValues: () => void;
  hasError: boolean;
};

export type SimpleFormTreeItem = Pick<
  TreeItem,
  'id' | 'item' | 'parentId' | 'selectionListId'
>;

export const isSelectionListValue = (
  value: number[] | string[] | SimpleFormTreeItem[] | null
): value is SimpleFormTreeItem[] => {
  return !!value && !!(value[0] as SimpleFormTreeItem)?.id;
};

export const SELECTION_LIST_TO_CUSTOM_COLUMN_VARIABLE_OVERRIDES_MAP: {
  [key in SelectionListIdNames]?: CustomColumnConditionVariable;
} = {
  [SelectionCategories.JOB_CATEGORY]: CustomColumnConditionVariable.RoleK7,
  [SelectionCategories.ROLE_K1500]: CustomColumnConditionVariable.MappedRole,
};

export const CUSTOM_COLUMN_VARIABLE_TO_SELECTION_LIST_OVERRIDES_MAP: {
  [key in CustomColumnConditionVariable]?: SelectionListIdNames;
} = swapKeysWithValues(SELECTION_LIST_TO_CUSTOM_COLUMN_VARIABLE_OVERRIDES_MAP);

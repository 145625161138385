import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Box,
  Button,
  AlertDialog as ChakraAlertDialog,
  ModalCloseButton,
  ModalHeaderProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import React, { useRef, useState } from 'react';
import { Subject, tap } from 'rxjs';

type AlertType = 'confirm' | 'actionless';

interface AlertProps {
  alertType: AlertType;
  onClose?: () => void;
  onAccept?: () => void;
  isOpen?: boolean;
  headerText: string;
  bodyContent: JSX.Element;
  footerAcceptIsDestructive?: boolean;
  footerAcceptText?: string;
  buttonLoading?: boolean;
  showCloseBtn?: boolean;
  chakraAlertProps?: Partial<AlertDialogProps>;
  headerProps?: Partial<ModalHeaderProps>;
}

export type IRevelioAlert = {
  alertType: AlertType;
  isOpen: boolean;
  onClose?: () => void;
  onAccept?: () => void;
  headerText: string;
  bodyContent?: JSX.Element;
  footerAcceptIsDestructive?: boolean;
  footerAcceptText?: string;
  onAlertTriggered?: () => void;
};
export const defaultAlertDialogDisclosureControl = new Subject<IRevelioAlert>();
export const defaultAlertDialogDisclosure$ =
  defaultAlertDialogDisclosureControl.asObservable();

const DEFAULT_ACCEPT_TEXT = 'Ok';

export const AlertDialog = ({
  chakraAlertProps = {},
  headerProps = {},
  isOpen,
  onClose,
  ...props
}: AlertProps) => {
  const cancelRef = useRef(null);

  const { onClose: disclosureOnClose, ...disclosureMain } = useDisclosure({
    onClose,
    isOpen,
  });
  const [onAccept, setOnAccept] = useState(() => props.onAccept);
  const [alertType, setAlertType] = useState(props.alertType);
  const [headerText, setHeaderText] = useState(props.headerText);
  const [bodyContent, setBodyContent] = useState(props.bodyContent);
  const [footerAcceptIsDestructive, setFooterAcceptIsDestructive] = useState(
    props.footerAcceptIsDestructive
  );
  const [footerAcceptText, setFooterAcceptText] = useState(
    props.footerAcceptText ?? DEFAULT_ACCEPT_TEXT
  );
  const [combinedOnClose, setCombinedOnClose] = useState(
    () => disclosureOnClose
  );

  useEffect$(() =>
    defaultAlertDialogDisclosure$.pipe(
      tap((d) => {
        setAlertType(d.alertType);
        setHeaderText(d.headerText);
        setBodyContent(d.bodyContent ?? <span></span>);
        setFooterAcceptIsDestructive(d.footerAcceptIsDestructive ?? false);
        setFooterAcceptText(d.footerAcceptText ?? DEFAULT_ACCEPT_TEXT);
        setOnAccept(() => d.onAccept);

        if (d.isOpen) {
          disclosureMain.onOpen();
        }
        if (d.onAlertTriggered) {
          d.onAlertTriggered();
        }

        setCombinedOnClose(() => () => {
          d.onClose && d.onClose();
          disclosureOnClose();
        });
      })
    )
  );

  return (
    <ChakraAlertDialog
      onClose={combinedOnClose}
      {...chakraAlertProps}
      {...disclosureMain}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" {...headerProps}>
            {headerText}
          </AlertDialogHeader>
          {props.showCloseBtn && <ModalCloseButton />}
          <AlertDialogBody>
            <Box pb={alertType === 'actionless' ? '12px' : '0'}>
              {bodyContent}
            </Box>
          </AlertDialogBody>
          {alertType !== 'actionless' && (
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                colorScheme="navyBlue"
                variant="ghost"
                onClick={combinedOnClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme={footerAcceptIsDestructive ? 'red' : 'navyBlue'}
                onClick={() => {
                  combinedOnClose();
                  onAccept && onAccept();
                }}
                ml={3}
                isLoading={props.buttonLoading}
              >
                {footerAcceptText}
              </Button>
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;

import { D3ChartNames } from '@revelio/d3';
import {
  BarData,
  FormatType,
  GroupedBarChart,
  GroupedBarData,
  GroupedBarTooltip,
  getFormatter,
} from '@revelio/replots';

import { PlotCard, convertGroupedBarToSheet } from '../../../shared/components';
import { downloadData } from '../../../shared/components/plot-card/download-plot-data';

interface PostingsTopGroupedBarChartCardProps {
  data: GroupedBarData<{ count: number }>[];
  colorMap: Record<string, string>;
  primaryDimension: string;
  secondaryDimension: string;
  loading?: boolean;
}

export const PostingsTopGroupedBarChartCard = ({
  data,
  colorMap,
  primaryDimension,
  secondaryDimension,
  loading,
}: PostingsTopGroupedBarChartCardProps) => {
  return (
    <PlotCard
      title={`Top ${primaryDimension}`}
      info={`The top 15 ${primaryDimension.toLowerCase()} in terms of number of added postings over the selected time range.`}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      onExport={() => {
        const sheetData = convertGroupedBarToSheet({
          data,
          primaryDimension: primaryDimension,
          secondaryDimension: secondaryDimension,
          metrics: {
            count: 'Count',
            value: 'Share',
          },
        });

        downloadData(sheetData, `top-${primaryDimension}`.toLowerCase(), {
          skipHeader: true,
          mergeHeaderCells: true,
        });
      }}
      plotShare={{
        data: data,
        chartConfig: {
          chartType: D3ChartNames.ReplotsGroupedBarChartHorizontal,
          chartProps: {
            format: FormatType.PERCENTAGE,
            colorMap: colorMap,
          },
        },
      }}
    >
      <GroupedBarChart
        data={data}
        loading={loading}
        colorMap={colorMap}
        tooltipContent={tooltipContent}
        format={FormatType.PERCENTAGE}
        // hideEmptyBars={true}
      />
    </PlotCard>
  );
};

function tooltipContent(group: string, bar: BarData<{ count: number }>) {
  return (
    <GroupedBarTooltip
      title={bar.label}
      label={group}
      value={`${getFormatter(FormatType.INTEGER)(bar.count)} / ${getFormatter(FormatType.PERCENTAGE)(bar.value ?? 0)}`}
    />
  );
}

import { FormControl, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { lastDayOfMonth } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './date-range.css';
import { DateRangeValue } from './helpers';

const DATE_ORDER_ERROR = 'Start date must be before end date';

export interface CustomFilterRangeProps {
  dateRangeValue?: DateRangeValue;
  setDateRangeValue: (newDateRange: DateRangeValue) => void;
  dateFormat?: any;
  showMonthYearPicker?: boolean;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  maxStartDate?: Date;
  maxEndDate?: Date;
  autocomplete?: string;
  error: string;
  setError: (error: string) => void;
}

export function CustomFilterRange({
  dateRangeValue,
  setDateRangeValue,
  dateFormat,
  showMonthYearPicker,
  showYearDropdown,
  scrollableYearDropdown,
  maxStartDate,
  maxEndDate,
  autocomplete,
  error,
  setError,
}: CustomFilterRangeProps) {
  const maxEndDateSnappedWhenMonthPicker =
    maxEndDate && showMonthYearPicker ? lastDayOfMonth(maxEndDate) : maxEndDate; // get last day so that we can select the last month

  const changeDate = ({
    startDate,
    endDate,
  }: {
    startDate?: Date | null;
    endDate?: Date | null;
  }) => {
    setDateRangeValue({
      ...(dateRangeValue as DateRangeValue),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    });
  };

  const onChangeStartDate = (date: Date | null) => {
    if (!date) return;
    changeDate({ startDate: date });

    if (dateRangeValue?.endDate && date && dateRangeValue?.endDate < date) {
      setError(DATE_ORDER_ERROR);
    } else {
      setError('');
    }
  };

  const onChangeEndDate = (date: Date | null) => {
    if (!date) return;

    changeDate({ endDate: date });

    if (dateRangeValue?.startDate && date && dateRangeValue?.startDate > date) {
      setError(DATE_ORDER_ERROR);
    } else {
      setError('');
    }
  };

  return (
    <VStack>
      <Stack direction={{ base: 'row', md: 'row' }} spacing="4" paddingTop={2}>
        <FormControl>
          <Text fontSize="sm" pb="1">
            Start Date
          </Text>
          <DatePicker
            selected={dateRangeValue?.startDate}
            onChange={onChangeStartDate}
            dateFormat={dateFormat}
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            showMonthYearPicker={showMonthYearPicker}
            showYearDropdown={showYearDropdown}
            dropdownMode="select"
            customInput={
              <Input
                data-testid="custom-range-start-date"
                size="sm"
                borderColor={error ? 'red.500' : undefined}
              />
            }
            minDate={maxStartDate}
            maxDate={maxEndDateSnappedWhenMonthPicker}
            onBlur={(e) => {
              e.preventDefault();
            }}
            autoComplete={autocomplete}
          />
        </FormControl>
        <FormControl>
          <Text fontSize="sm" pb="1">
            End Date
          </Text>
          <DatePicker
            selected={dateRangeValue?.endDate}
            onChange={onChangeEndDate}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
            showYearDropdown={showYearDropdown}
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            dropdownMode="select"
            customInput={
              <Input
                data-testid="custom-range-end-date"
                size="sm"
                borderColor={error ? 'red.500' : undefined}
              />
            }
            onBlur={(e) => {
              e.preventDefault();
            }}
            minDate={maxStartDate}
            maxDate={maxEndDateSnappedWhenMonthPicker}
            autoComplete={autocomplete}
          />
        </FormControl>
      </Stack>
      <Text fontSize="sm" height="2" color="red.500">
        {error || ''}
      </Text>
    </VStack>
  );
}

export default CustomFilterRange;

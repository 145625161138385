import { graphql } from '../gql';

export const SelectionListGqlQuery = graphql(`
  query SelectionList {
    selectionList {
      region {
        id
        shortName
        longName
        label: longName
      }
      country {
        id
        shortName
        longName
        label: longName
        region
      }
      state {
        id
        shortName
        longName
        label: longName
        region
        country
      }
      metro_area: msa {
        id
        shortName
        longName
        label: longName
        region
        country
        state
      }
      job_category {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
      }
      role_k50 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
      }
      role_k150 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
      }
      role_k300 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
      }
      role_k500 {
        id
        shortName
        longName: shortName
        label: shortName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
      }
      role_k1000 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
      }
      role_k1250 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
      }
      role_k1500 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
        role_k1250: roleK1250
      }
      seniority {
        id
        shortName
        longName
        label: longName
      }
      ethnicity {
        id
        shortName
        longName
        label: longName
      }
      gender {
        id
        shortName
        longName
        label: longName
      }
      highest_degree {
        id
        shortName
        longName
        label: longName
      }
      education: highest_degree {
        id
        shortName
        longName
        label: longName
      }
      skill_k25 {
        id
        shortName
        longName: shortName
        label: shortName
      }
      skill_k50 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
      }
      skill_k75 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        topSkills
      }
      skill_k700 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        topSkills
      }
      skill_k2500 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
      }
      skill_mapped {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
        skill_k2500
      }
      skill_k3000 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
        skill_k2500
        topSkills
      }
    }
  }
`);

export const CompanyIndustrySelectionListGqlQuery = graphql(`
  query CompanyIndustySelectionList {
    selectionList {
      company {
        id
        shortName
        rank
        ticker
        longName
        label: longName
        industry: industryId
        ricsK50Id
        rics_k50: ricsK50Id
        ricsK400Id
        rics_k400: ricsK400Id
        sedol
        isin
        rcid
      }
      industry {
        id
        shortName
        longName
        label: longName
      }
      rics_k10 {
        id
        shortName
        label: longName
        longName
      }
      rics_k50 {
        id
        shortName
        longName
        label: longName
        rics_k10
      }
      rics_k400 {
        id
        shortName
        longName
        label: longName
        rics_k10
        rics_k50
      }
    }
  }
`);

import { useEffect$ } from '@ngneat/react-rxjs';
import { isValid } from 'date-fns';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { skipUntil, tap } from 'rxjs';

import {
  AUTH_CHECKED,
  AuthEventIds,
  ServerProfileTabsPathMap,
  authStore,
  emitAuthEvent,
  isRoleRevelioAdmin,
  isTabAllowed,
} from '@revelio/auth';
import { LOGIN } from '@revelio/core';
import { Role, Tab } from '@revelio/data-access';

import { useOpenReplay } from '../../hooks/open-replay';

interface RequireAuthProps {
  children?: ReactNode;
  tab?: Tab | typeof LOGIN | Tab[];
  adminRequired?: boolean;
  clientAdminRequired?: boolean;
}
export function RequireAuth({
  children,
  tab = LOGIN,
  adminRequired = false,
  clientAdminRequired = false,
}: RequireAuthProps) {
  const authState = authStore.getValue();
  let shouldShowChildren = true;

  useEffect$(() =>
    authStore.pipe(
      skipUntil(AUTH_CHECKED),
      // NOTE: could potentially add a distinctUntilChanged (with object compare via lodash) here
      tap((state) => {
        if (!state.ory?.active) {
          emitAuthEvent({ id: AuthEventIds.USER_LOGOUT });
        }
      })
    )
  );

  const session = authStore.getValue().ory;

  const { tracker } = useOpenReplay();

  const forceLogoutFlag = tracker.getFeatureFlag('force-logout');
  if (forceLogoutFlag?.value) {
    const forcedSessionExpirationAt = forceLogoutFlag.value as string;
    const authenticatedAt = session?.authenticated_at;
    if (
      authenticatedAt &&
      forcedSessionExpirationAt &&
      isValid(new Date(forcedSessionExpirationAt)) &&
      // if the feature flag is set in the future, it'll keep logging out users
      new Date(forcedSessionExpirationAt) < new Date() &&
      new Date(forcedSessionExpirationAt) > new Date(authenticatedAt)
    ) {
      emitAuthEvent({ id: AuthEventIds.USER_LOGOUT });
      return;
    }
  }

  const user = authState.user;
  const userTabs = user?.tabs || [];
  const isAdmin = isRoleRevelioAdmin(user?.role);
  const isClientAdmin = user?.role === Role.ClientAdmin;

  const allowedTab = isTabAllowed(tab, userTabs);
  if (
    user &&
    ((adminRequired && !clientAdminRequired && !isAdmin) ||
      (clientAdminRequired && !isAdmin && !isClientAdmin) ||
      (userTabs.length && !allowedTab))
  ) {
    shouldShowChildren = false;
  }

  return shouldShowChildren && children ? (
    children
  ) : (
    <Navigate to={ServerProfileTabsPathMap.landing} />
  );
}

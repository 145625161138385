import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { flatten, startCase } from 'lodash';
import { useEffect } from 'react';

import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  Views,
  useGlobalLoaderMaxWait,
} from '@revelio/core';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { View } from '@revelio/data-access';
import {
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterMenu,
  FilterMenuItemOrConfig,
  FilterMenuLimits,
  FilterSetSaveMenu,
  FilterSets,
  FiltersUsedInTabs,
  LocalSelectionCategories,
  OtherFilterNames,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  SelectionCategories,
  Tab,
  TabsFilter,
  createSelectableFiltersMap,
  customFormatterLookup,
  getNestedEntity,
  upsertFiltersWithProvidedValue,
  useActiveFiltersState,
  useAdaptiveRoleTaxonomy,
  useAllFiltersState,
  useDefaultLastMonth,
  usePrimaryFilter,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';
import { SankeyChart } from '@revelio/replots';

import { PlotCard, convertSankeyToSheet } from '../../shared/components';
import { downloadData } from '../../shared/components/plot-card/download-plot-data';
import { getPrimaryViewFromTab } from '../../utils';
import DashboardPage from '../DashboardPage';
import { SankeyPlotFooter } from './components';
import { TransitionsPlotCard } from './components/transitions-plot-card';
import { useTransitionsDataFetch } from './data-fetch/use-transitions-data-fetch';
import { transitionStackPlotConfigLookup } from './transitions-plots.config';

export interface TransitionsProps {
  title: PageTitles[];
  viewType: Tab;
  primaryFilter: PrimaryFilters;
  primaryViewFilters: FilterMenuItemOrConfig[];
  selectableFilters: FilterMenuItemOrConfig[];
  otherFilters: OtherFilterNames[];
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  primaryFilterLimit: PrimaryFilterLimits;
  filterMenuLimit: FilterMenuLimits;
  savedSetView: View;
}

export function Transitions({
  title,
  viewType,
  primaryFilter,
  primaryViewFilters,
  selectableFilters,
  otherFilters,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryFilterLimit,
  filterMenuLimit,
  savedSetView,
}: TransitionsProps) {
  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  ) as SelectionCategories[];

  const primaryView = getPrimaryViewFromTab(viewType);

  const allFilters = useAllFiltersState();

  const inflow_or_outflow = (
    allFilters.find(
      (filter) => filter.label === LocalSelectionCategories.INFLOW_OR_OUTFLOW
    )?.value as { id: string }
  )?.id as 'inflow' | 'outflow';
  const isInflow = inflow_or_outflow === 'inflow';

  const { data, stackedData, sankeyData, fetching, isQueryReady } =
    useTransitionsDataFetch({
      view: primaryView,
      primaryFilters,
      inflow_or_outflow,
    });

  const view = Views.TRANSITION;

  const stackedBarPlotConfig = transitionStackPlotConfigLookup[viewType];

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useGlobalLoaderMaxWait(true);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFilterLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: 1,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useViewFilters([
    ...primaryFilters,
    ...selectableFiltersMap,
    LocalSelectionCategories.N_ITEMS_SANKEY,
  ]);

  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters,
  });

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [...otherFilters, LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.TRANSITIONS,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useTabMeta({
    savedSetView,
    view,
    viewType,
    limit: PrimaryFilterLimits.TRANSITIONS,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: fetching || !isQueryReady,
  });

  usePrimaryFilter(primaryFilter);

  const primaryEntity = getNestedEntity(viewType);

  const activeFilters = useActiveFiltersState();

  const hasNItemsSankey = activeFilters.some(
    (filter) => filter.id === LocalSelectionCategories.N_ITEMS_SANKEY
  );
  useEffect(() => {
    if (!hasNItemsSankey) {
      upsertFiltersWithProvidedValue({
        [LocalSelectionCategories.N_ITEMS_SANKEY]: 10,
      });
    }
  }, [hasNItemsSankey]);

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={fetching || !isQueryReady}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            showColors={false}
            variant="companyChip"
            isPrimaryChip={true}
            view={view}
            min={1}
            limit={primaryFilterLimit}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                {...primaryEntity}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={1}
              >
                {AddEntityButtonText[primaryFilter]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            variant="filterChip"
            limit={filterMenuLimit}
            showGranularity={true}
            viewType={viewType}
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={[...selectableFilters]}
                  selectMenuOpenDefault
                  limit={filterMenuLimit}
                  viewIdForDefault={`${view}_${viewType}`}
                />
                <FilterSetSaveMenu view={savedSetView} />
              </>
            }
          />
        </Flex>

        <TabsFilter
          filterName={LocalSelectionCategories.INFLOW_OR_OUTFLOW}
          initialValue={{
            id: OtherFilterNames.INFLOW,
            label: startCase(OtherFilterNames.INFLOW),
            index: 0,
          }}
          filterMetadata={{
            isMulti: false,
            customFormatter:
              customFormatterLookup[LocalSelectionCategories.INFLOW_OR_OUTFLOW],
          }}
        ></TabsFilter>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={{ base: 'repeat(8, 200px)', md: 'repeat(6, 1fr)' }}
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem
          gridRow={{ base: 'span 2', md: '1 / span 6' }}
          gridColumn={{ base: '1 / span 1', md: '1 / span 2' }}
          minH={0}
        >
          <PlotCard
            title="Transition"
            info="The most common sources of incoming workers and the most common
            destinations of outgoing workers."
            menu={['expand', 'download-png', 'generate-link', 'download-data']}
            footer={<SankeyPlotFooter />}
            legacyChartProps={{
              type: D3ChartNames.SankeyDiagram,
              props: { inflows: isInflow } as ID3ChartProps,
              data: data.sankeyData ?? [],
            }}
            onExport={() => {
              const exportData = convertSankeyToSheet({ data: sankeyData });
              downloadData(exportData, 'transition');
            }}
          >
            <Box width="100%" height="100%" padding={'12px'}>
              <SankeyChart data={sankeyData} isOutflows={!isInflow} />
            </Box>
          </PlotCard>
        </GridItem>

        {stackedBarPlotConfig.map(({ name, label, info }, i) => (
          <GridItem
            key={name}
            gridRow={{ base: 'span 1', md: `${i + 1} / span 1` }}
            gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
            minHeight={0}
          >
            <TransitionsPlotCard
              title={label}
              data={stackedData[name]}
              legacyData={data?.barChartData[name]}
              loading={fetching || !isQueryReady}
              info={info}
            />
          </GridItem>
        ))}
      </Grid>
    </DashboardPage>
  );
}

export default Transitions;

import { Box, Flex, Link, useDisclosure } from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { EnhancedRowProps } from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Content/RowProps';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import {
  TypeColumn,
  TypeComputedColumn,
  TypeComputedProps,
  TypePaginationProps,
  TypeRowSelection,
} from '@inovua/reactdatagrid-community/types';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { get } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  PaginationComponentProps,
  createUltimatePagination,
} from 'react-ultimate-pagination';
import { pipe } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { useQuery } from 'urql';

import { useUserTrialDetails } from '@revelio/auth';
import {
  Loading,
  setGlobalLoaderEnableState,
  toggleStatusOnGlobalLoaderOrSkipOne,
  useGlobalPageLoadingStatus,
} from '@revelio/core';
import {
  TalentDiscoveryDataQuery,
  TalentDiscoveryUser,
  TrialType,
} from '@revelio/data-access';
import { graphql } from '@revelio/data-access';
import {
  SelectionCategories,
  TalentDiscovery_entitiesTransform,
  getManyFiltersState,
  // upsertFilter,
  useActiveFiltersV2,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';
import {
  PAGE_SIZE_UI,
  PAGE_SIZE_V2,
  TablePopover,
  itemTypeToComponent,
} from '@revelio/layout';

// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../reveliolabs-dashboard/libs/layout/src/lib/components/table/table.css';
// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../reveliolabs-dashboard/libs/layout/src/lib/components/talent-discovery/dataGrid.css';
import { CustomiseColumnsButton } from '../../shared/components';
import { ColumnSelectModal } from '../screener/screener-table/column-select-modal/column-select-modal';
import DataUnavailableBanner from '../trialUser/dataUnavailableBanner';
import { BlurredCell } from './blurred-cell';
import { generateRandomPlaceholder } from './helpers';
import styles from './talent-discovery-table.module.css';

export interface TalentDiscoveryTableProps {
  apiData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5'] | undefined;
  savedCheckedColumns: string[];
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedUsers?: Dispatch<SetStateAction<TalentDiscoveryUser[]>>;
  loading?: boolean;
  setShowLoader?: Dispatch<SetStateAction<boolean>>;
  resetSelections?: boolean;
  setResetSelections?: Dispatch<SetStateAction<boolean>>;
  allowSelections?: boolean;
  currencyFormatter: (value: number) => string;
}

const RESTRICTED_TRIAL_USER_COLUMNS = [
  'email',
  'personal_email',
  'phone_number',
  'linkedin_url',
  'last_name',
];

const UNBLUR_ALLOWED_TRIAL_USER_COLUMNS = [
  'email',
  'personal_email',
  'phone_number',
  'linkedin_url',
  'last_name',
];

type TalentDiscoveryTableContextType = {
  hoveringRowData: Record<string, unknown>;
  decryptCell: (cellKey: string, input: string) => void;
  isDecrypting: (cellKey: string) => boolean;
  getDecryptedValue: (cellKey: string) => string | undefined;
};

export const TalentDiscoveryTableContext =
  createContext<TalentDiscoveryTableContextType>({
    hoveringRowData: {},
    decryptCell: (_cellKey: string, _input: string) => {
      console.debug('Default decryptCell called - no provider available');
    },
    isDecrypting: () => false,
    getDecryptedValue: () => undefined,
  });

const DECRYPT_TALENT_DISCOVERY_DATA = graphql(`
  query DecryptTalentDiscoveryData($input: String!) {
    decryptString(input: $input)
  }
`);

export function TalentDiscoveryTable({
  apiData,
  savedCheckedColumns,
  setSavedCheckedColumns,
  setSelectedUsers,
  loading,
  setShowLoader,
  resetSelections,
  setResetSelections,
  allowSelections,
  currencyFormatter,
}: TalentDiscoveryTableProps) {
  const data = useMemo(
    () =>
      TalentDiscovery_entitiesTransform({
        entities: apiData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filters: [] as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plotName: '' as any,
      }),
    [apiData]
  );
  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isGlobalPageLoading = useGlobalPageLoadingStatus();
  const { isTrialUser, trialType } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });
  const [trialColumnsBlurred, setTrialColumnsBlurred] = useState(false);

  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_V2);
  const [columns, setColumns] = useState<TypeColumn[]>([]);

  useActiveFiltersV2<unknown>(
    pipe(
      withLatestFrom(
        getManyFiltersState([
          SelectionCategories.TALENT_PAGE_ID,
          SelectionCategories.TALENT_PAGE_SIZE,
        ])
      ),
      tap(([filters, [pageIdFilter, pageSizeFilter]]) => {
        const newPageId = get(pageIdFilter, 'value.value', 1);
        const newPageSize = get(pageSizeFilter, 'value.value', PAGE_SIZE_V2);

        if (selectedPage !== newPageId) {
          setSelectedPage(newPageId);
        }

        if (newPageSize !== pageSize) {
          setPageSize(newPageSize);
        }

        if (filters.length == 0) {
          toggleStatusOnGlobalLoaderOrSkipOne();
          setGlobalLoaderEnableState('DISABLE', 1000);
        }
      })
    )
  );

  const gridStyle = { minHeight: '100%', borderRadius: '10px' };

  const totalPages = useMemo(() => {
    const serverPages: number = get(data, 'metadata.total_pages', 0);
    const resultsLength: number = get(data, 'table_data', []).length;
    return serverPages == 1
      ? Math.ceil(resultsLength / PAGE_SIZE_UI)
      : (serverPages * pageSize) / PAGE_SIZE_UI;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, data]);

  const UltimatePagination = createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent({
      hidePage:
        (selectedPage: number | undefined) =>
        (page: PaginationComponentProps) => {
          const isFinalPage = totalPages === page.value;
          if (!isFinalPage) {
            return false;
          }

          const ellipsisPageLimit = totalPages - 2;
          return !selectedPage || selectedPage < ellipsisPageLimit;
        },
      selectedPageId: selectedPage,
    }),
    WrapperComponent: (props: { children: JSX.Element }) => {
      return (
        <Box data-testid="ultimate-pagination-toolbar">{props.children}</Box>
      );
    },
  });

  const renderPaginationToolbar = useCallback(
    ({ gotoPage, skip, limit }: TypePaginationProps) => {
      const currentPage = skip / limit + 1;
      return (
        <Flex alignItems="center" h="50px" w="100%" justifyContent="center">
          <Box p={2}>
            {totalPages > 0 && (
              <UltimatePagination
                currentPage={currentPage}
                totalPages={totalPages}
                boundaryPagesRange={1}
                siblingPagesRange={1}
                hidePreviousAndNextPageLinks={false}
                hideFirstAndLastPageLinks={true}
                hideEllipsis={false}
                onChange={(e: number) => {
                  gotoPage(e);
                }}
              />
            )}
          </Box>
        </Flex>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPages]
  );

  const [trialColumnsHover, setTrialColumnsHover] = useState(false);
  const [trialColumnHovered, setTrialColumnHovered] = useState(false); //first time trial column is hovered

  const dataTableLength = get(data, 'table_data', []).length || 0;
  const dataColumns = get(data, 'columns', []) as TypeColumn[];

  const boundaryRef = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => setTrialColumnsHover(true);

  const handleMouseOut = () => setTrialColumnsHover(false);

  const sorterFuncChecker = (x: string) => {
    const ignoreSorters = [
      'salary_base',
      'salary_total',
      'years_of_experience',
      'start_date',
      'end_date',
      'prestige',
      'flight_risk',
      'remote_suitability',
    ];
    return !ignoreSorters.some((e) => x.includes(e));
  };

  const sorterFunc = (
    x1: number | string | null,
    x2: number | string | null,
    configObj: TypeComputedColumn
  ) => {
    const negZeroFixCompareCheck = (
      a2: number | string | null,
      b2: number | string | null
    ) => {
      if (a2 === 0 && b2 === 0) {
        return 1 / a2 - 1 / b2 || 0;
      } else {
        return (a2 as number) - (b2 as number);
      }
    };
    if (configObj.name?.toLowerCase().includes('headcount')) {
      const pattern = /[,]/g;
      const numSort = (
        a: string | number | null,
        b: string | number | null
      ) => {
        let a2 = a;
        let b2 = b;
        if (a2 !== null) {
          a2 = +(a2 as string).replace(pattern, '');
        }
        if (b2 !== null) {
          b2 = +(b2 as string).replace(pattern, '');
        }
        return negZeroFixCompareCheck(a2, b2);
      };
      return numSort(x1, x2);
    } else if (configObj.name?.toLowerCase().includes('rate')) {
      const rateSort = (a: string, b: string) => {
        const exp = (e: string) => Number(e.replace('%', ''));
        const a2 = exp(a);
        const b2 = exp(b);
        return negZeroFixCompareCheck(a2, b2);
      };
      return rateSort(x1 as string, x2 as string);
      //TODO: these sorters are the same now so combine them into just one
    } else if (configObj.name?.toLowerCase().includes('salary')) {
      const salarySort = (a: string, b: string) => {
        return negZeroFixCompareCheck(a, b);
      };
      return salarySort(x1 as string, x2 as string);
    } else if (configObj.name?.toLowerCase().includes('years')) {
      const tenureSort = (a: string, b: string) => {
        return negZeroFixCompareCheck(a, b);
      };
      return tenureSort(x1 as string, x2 as string);
    } else if (configObj.name?.toLowerCase().includes('date')) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const dateSort = (a: string, b: string): number => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');
        const monthNumberA = monthNames.indexOf(monthA);
        const monthNumberB = monthNames.indexOf(monthB);
        if (parseInt(yearA) < parseInt(yearB)) {
          return -1;
        }
        if (parseInt(yearA) > parseInt(yearB)) {
          return 1;
        }
        if (monthNumberA < monthNumberB) {
          return -1;
        }
        if (monthNumberA > monthNumberB) {
          return 1;
        }

        return 0;
      };
      return dateSort(x1 as string, x2 as string);
    } else if (
      configObj.name &&
      ['prestige', 'flight_risk', 'remote_suitability'].includes(
        configObj.name?.toLowerCase()
      )
    ) {
      const levelSortingHierarchy = [
        'Very Low',
        'Low',
        'Medium',
        'High',
        'Very High',
      ];

      const levelSort = (a: string, b: string): number => {
        const levelIndexA = levelSortingHierarchy.indexOf(a);
        const levelIndexB = levelSortingHierarchy.indexOf(b);

        if (levelIndexA < levelIndexB) {
          return -1;
        }
        if (levelIndexA > levelIndexB) {
          return 1;
        }

        return 0;
      };

      return levelSort(x1 as string, x2 as string);
    }
    return null;
  };

  const onMouseEnterTrialColumn = () => {
    setTrialColumnHovered(true);
    setTrialColumnsHover(true);
  };

  useEffect(() => {
    const isTrialUserWithoutBlurredColumns =
      isTrialUser && !trialColumnsBlurred;
    if (
      (dataTableLength === 0 || columns.length) &&
      !isTrialUserWithoutBlurredColumns
    ) {
      return;
    }

    const columnsToExclude = [
      // old properties can remove these once old talent discovery page is removed
      'Total Years of Experience',
      'Current Tenure',
      'Latitude',
      'Longitude',

      /// TD V2 Properties
      'user_id',
      '__typename',
      'RCID',
      'longitude',
      'latitude',

      // Restrict gender and ethnicity columns (Note: This doesn't affect downloaded data)
      ...(restrictGenderAndEthnicity ? ['gender', 'ethnicity'] : []),
    ];
    const defaultColumns = [
      ...(dataColumns
        ?.filter?.((col) => !columnsToExclude.includes(col.name as string))
        .map((col) => {
          let render: ({
            value,
            data,
          }: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: any; // could be string or number which we know based on the column
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data: any;
          }) => JSX.Element = ({ value }: { value: string | number }) => (
            <div data-testid={`${col.name}-cell`}>{value}</div>
          );

          if (col.name === 'salary_total' || col.name === 'salary_base') {
            render = ({ value }: { value: number }) => {
              // TODO: temp change $0 salaries to no data placeholder due to backend bug
              return (
                <div data-testid="salary_total-cell">
                  {value == 0 ? '--' : currencyFormatter(value)}
                </div>
              );
            };
          }

          if (col.name === 'years_of_experience') {
            render = ({ value }: { value: number }) => (
              <div data-testid="years_of_experience-cell">
                {value.toFixed(2)}
              </div>
            );
          }

          if (col.name === 'graduation_year') {
            render = ({ value }: { value: number }) => (
              <div data-testid="graduation_year-cell">{value || '--'}</div>
            );
          }

          // TEMP FIX: If highest degree field is "empty", change to empty string
          if (col.name === 'highest_degree') {
            render = ({ value }: { value: string }) => (
              <div data-testid="highest_degree-cell">
                {value.toLowerCase().trim() === 'empty' ? '' : value}
              </div>
            );
          }

          if (col.name === 'linkedin_url') {
            render = ({ value }: { value: string }) => {
              const url = value.toLowerCase().trim() === 'empty' ? '' : value;

              if (url.length === 0) {
                return (
                  <div data-testid="linkedin_url-cell">
                    {value.toLowerCase().trim() === 'empty' ? '' : value}
                  </div>
                );
              }

              return (
                <Link
                  href={`https://${value}`}
                  isExternal
                  rel="noopener noreferrer"
                >
                  {value.toLowerCase().trim() === 'empty' ? '' : value}
                </Link>
              );
            };
          }

          if (
            isTrialUser &&
            RESTRICTED_TRIAL_USER_COLUMNS.includes(col.name as string)
          ) {
            render = (row) => {
              return (
                <BlurredCell
                  rowData={row.data ?? {}}
                  value={row.value}
                  onMouseEnter={onMouseEnterTrialColumn}
                  originalValue={row?.data?.original_values[col.name as string]}
                  columnName={col.name as string}
                  allowUnblur={
                    trialType === TrialType.NO_DATA_LAG_WITH_TD_REVEAL &&
                    UNBLUR_ALLOWED_TRIAL_USER_COLUMNS.includes(
                      col.name as string
                    )
                  }
                  data-testid={`${col.name}-cell`}
                />
              );
            };
            (col as TypeColumn & { className: string }).className =
              'hiddenColumn';
            setTrialColumnsBlurred(true);
          }

          return {
            ...col,
            render,
            sort: sorterFuncChecker(col.name as string) ? null : sorterFunc,
          };
        }) || []),
      {
        name: '_',
        header: '',
        sortable: false,
        draggable: false,
        showInContextMenu: false,
        groupSpacerColumn: true,
        defaultFlex: 1,
      },
    ];

    setColumns(defaultColumns as TypeColumn[]);
  }, [
    dataTableLength,
    dataColumns,
    columns.length,
    isTrialUser,
    trialType,
    trialColumnsBlurred,
    restrictGenderAndEthnicity,
    columns,
    setColumns,
    currencyFormatter,
  ]);

  const [gridRef, setGridRef] = useState<TypeComputedProps>();

  const handleOnReady = (
    ref: React.MutableRefObject<TypeComputedProps | null>
  ) => {
    if (ref.current) {
      setGridRef?.(ref.current);
    }
  };

  const [hoverData, setHoverData] = useState<Record<string, unknown>>({});

  // Add state for managing decryption
  const [decryptingCells] = useState(() => new Set<string>());
  const [decryptedValues] = useState(() => new Map<string, string>());
  const [pendingDecryptions] = useState(
    () => new Map<string, { input: string }>()
  );
  const [currentInput, setCurrentInput] = useState('');
  // Add version counter to force re-renders when collections change
  const [stateVersion, setStateVersion] = useState(0);

  const queryOptions = useMemo(
    () => ({
      query: DECRYPT_TALENT_DISCOVERY_DATA,
      variables: { input: currentInput },
      pause: !currentInput,
      context: { requestPolicy: 'network-only' as const },
    }),
    [currentInput]
  );

  const [{ data: decryptData, fetching }] = useQuery(queryOptions);

  // Effect to process decryption results
  useEffect(() => {
    console.log('Decrypt data changed:', decryptData);
    console.log(
      'Pending decryptions:',
      Array.from(pendingDecryptions.entries())
    );
    console.log('Decrypting cells:', Array.from(decryptingCells));
    console.log('Current input:', currentInput);
    console.log('Is fetching:', fetching);

    // Only process if we have data and it's not fetching anymore
    if (decryptData?.decryptString && !fetching) {
      // Find the cell that matches the current input
      const pendingEntry = Array.from(pendingDecryptions.entries()).find(
        ([_, data]) => data.input === currentInput
      );

      if (pendingEntry) {
        const [cellKey] = pendingEntry;
        console.log('Processing decryption for:', cellKey);

        // Store the decrypted value and update collections
        decryptedValues.set(cellKey, decryptData.decryptString);
        decryptingCells.delete(cellKey);
        pendingDecryptions.delete(cellKey);
        // Force a re-render
        setStateVersion((v) => v + 1);

        // Process next pending decryption if any
        const nextPending = Array.from(pendingDecryptions.entries())[0];
        if (nextPending) {
          const [nextKey, { input }] = nextPending;
          console.log('Starting next decryption for:', nextKey);
          decryptingCells.add(nextKey);
          setCurrentInput(input);
        } else {
          setCurrentInput('');
        }
      }
    }
  }, [
    decryptData,
    fetching,
    decryptingCells,
    decryptedValues,
    pendingDecryptions,
    currentInput,
  ]);

  const decryptCell = useCallback(
    (cellKey: string, input: string) => {
      console.log('Decrypt cell called for:', cellKey, 'with input:', input);

      // Don't process if already being handled
      if (decryptingCells.has(cellKey) || decryptedValues.has(cellKey)) {
        console.log('Cell already decrypting or decrypted:', cellKey);
        return;
      }

      // If no active decryptions, start this one immediately
      if (decryptingCells.size === 0) {
        console.log('Starting immediate decryption for:', cellKey);
        decryptingCells.add(cellKey);
        pendingDecryptions.set(cellKey, { input });
        // Force a re-render when adding new decryption
        setStateVersion((v) => v + 1);
        setCurrentInput(input);
      } else {
        // Queue this decryption if there's an active one
        console.log('Queueing decryption for:', cellKey);
        pendingDecryptions.set(cellKey, { input });
        // Force a re-render when queueing
        setStateVersion((v) => v + 1);
      }
    },
    [decryptingCells, decryptedValues, pendingDecryptions]
  );

  const isDecrypting = useCallback(
    (cellKey: string) => {
      return decryptingCells.has(cellKey) || pendingDecryptions.has(cellKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [decryptingCells, pendingDecryptions, stateVersion] // Add stateVersion to dependencies
  );

  const getDecryptedValue = useCallback(
    (cellKey: string) => {
      return decryptedValues.get(cellKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [decryptedValues, stateVersion] // Add stateVersion to dependencies
  );

  const talentDiscoverTableData = useMemo(
    () => ({
      hoveringRowData: hoverData,
      decryptCell,
      isDecrypting,
      getDecryptedValue,
    }),
    [hoverData, decryptCell, isDecrypting, getDecryptedValue]
  );

  const onRenderRow = (
    e: EnhancedRowProps & { onMouseMove: (event: MouseEvent) => void }
  ) => {
    const hasCheckboxColumn = e?.columns[0]?.checkboxColumn;
    const checkboxColumnWidth = e?.columns[0]?.computedWidth;

    // Disable popover on checkbox column
    const handleMouseEvent = (event: MouseEvent) => {
      const gridLeft = gridRef?.getDOMNode().getBoundingClientRect().left ?? 0;

      const isHoveringCheckboxColumn =
        event.clientX >= gridLeft &&
        event.clientX <= gridLeft + checkboxColumnWidth;

      if (isHoveringCheckboxColumn) {
        setHoverData({});
        return;
      }

      setHoverData(e.data);
    };

    if (hasCheckboxColumn) {
      e.onMouseMove = handleMouseEvent;
    } else {
      e.onMouseEnter = () => setHoverData(e.data);
    }
    e.onMouseLeave = () => setHoverData({});

    if (hoverData?.user_id === e?.data?.user_id) {
      e.children = [
        <TablePopover
          key="td-popover"
          children={e.children}
          gridRef={gridRef}
          availableWidth={e.availableWidth}
          boundaryRef={boundaryRef}
          data={hoverData}
          currencyFormatter={currencyFormatter}
        />,
      ];
    }
  };

  const [pageTableData, setPageTableData] = useState(
    data?.table_data.length ? data.table_data : []
  );

  useEffect(() => {
    let tableData = data?.table_data.length ? data.table_data : [];
    if (isTrialUser) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tableData = tableData.map((data: any) => {
        data['original_values'] = {};
        RESTRICTED_TRIAL_USER_COLUMNS.forEach((col) => {
          data['original_values'][col] = data[col];
          // only generate fake data where we have some so users aren't disappointed by unblurring empty cells
          data[col] = data[col] ? generateRandomPlaceholder(col) : '';
        });

        return data;
      });
    }
    setPageTableData(tableData);
  }, [data?.table_data, isTrialUser]);

  useEffect(() => {
    let hiddenColumns: NodeListOf<HTMLElement> | [] = [];
    if (gridRef && !isGlobalPageLoading && trialColumnHovered) {
      const gridNode = gridRef.getDOMNode();
      hiddenColumns = gridNode.querySelectorAll('.hiddenColumn');
      if (hiddenColumns.length > 0) {
        hiddenColumns.forEach((hiddenColumn) => {
          hiddenColumn.addEventListener(
            'mouseover',
            handleMouseOver as EventListener
          );
          hiddenColumn.addEventListener(
            'mouseout',
            handleMouseOut as EventListener
          );
        });
      }
    }

    return () => {
      hiddenColumns.forEach((col) => {
        col.removeEventListener('mouseover', handleMouseOver as EventListener);
        col.removeEventListener('mouseout', handleMouseOut as EventListener);
      });
    };
  }, [gridRef, isGlobalPageLoading, trialColumnHovered]);

  const renderLoadMask = ({
    visible,
    livePagination,
    loadingText,
    zIndex,
  }: {
    visible: boolean;
    livePagination: boolean;
    loadingText: React.ReactNode | (() => React.ReactNode);
    zIndex: number;
  }) => {
    if (!visible) {
      return null;
    }

    return (
      <Flex
        id="overlay"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="0"
        bottom="65px"
        right="0"
        left="0"
        background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
        zIndex="5"
        overflow="hidden"
        backdropFilter="blur(3px)"
        borderRadius="10px"
      >
        <Loading size="sm" transparentBackground />
      </Flex>
    );
  };

  const DataUnavailable = () => (
    <Box
      position="absolute"
      zIndex={9999}
      left="0"
      right="0"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <DataUnavailableBanner style={{ borderRadius: '10px 10px 0 0' }} />
    </Box>
  );

  const headerContent = (() => {
    if (isTrialUser && trialColumnsHover) {
      return <DataUnavailable />;
    }

    if (pageTableData.length && !isGlobalPageLoading) {
      return trialColumnsHover ? (
        <DataUnavailable />
      ) : (
        <CustomiseColumnsButton onClick={onOpen} right={0} />
      );
    }

    return null;
  })();

  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    const filteredCols = columns.filter(
      (column) => column?.name && savedCheckedColumns.includes(column?.name)
    );

    setFilteredColumns(filteredCols);
  }, [savedCheckedColumns, columns]);

  const [selectedControlled, setSelectedControlled] =
    useState<TypeRowSelection>();
  const handleSelectionChange = (config: TypeOnSelectionChangeArg) => {
    const originalData = config.originalData as
      | TalentDiscoveryUser[]
      | undefined;

    // if selectAll, just override selections with current page
    if (config.selected === true && originalData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSelectionObject = originalData.reduce<{ [key: string]: any }>(
        (acc, value) => {
          acc[value.user_id] = value;
          return acc;
        },
        {}
      );

      setSelectedControlled(newSelectionObject);
    } else {
      setSelectedControlled(config.selected);
    }
  };

  useEffect(() => {
    if (selectedControlled && typeof selectedControlled === 'object') {
      const selections = Object.values(selectedControlled);
      setSelectedUsers?.(selections);
    }
  }, [selectedControlled, setSelectedUsers]);

  /** Internal render check. Check whether table rows have rendered, and when they have, call the
   * setShowLoader methoad */
  useEffect(() => {
    const tableContainer = boundaryRef.current;
    if (!loading && tableContainer) {
      let checkCounter = 0;
      const interval = setInterval(() => {
        const rows = tableContainer.querySelectorAll(
          '.InovuaReactDataGrid__row'
        ).length;
        if (rows > 1 || checkCounter > 50) {
          setShowLoader?.(false);
          clearInterval(interval);
        }
        checkCounter += 1;
      }, 200);
    }
  }, [loading, setShowLoader]);

  useEffect(() => {
    if (resetSelections) {
      setSelectedControlled({});
      setResetSelections?.(false);
    }
  }, [resetSelections, setResetSelections]);

  return (
    <Box h="full" w="full" position="relative" ref={boundaryRef}>
      {headerContent}
      <TalentDiscoveryTableContext.Provider value={talentDiscoverTableData}>
        <ReactDataGrid
          idProperty="user_id"
          {...(allowSelections
            ? {
                checkboxColumn: true,
                enableSelection: true,
                multiSelect: true,
                checkboxOnlyRowSelect: true,
                selected: selectedControlled,
                onSelectionChange: handleSelectionChange,
              }
            : {})}
          className={styles.reactDataGrid}
          onReady={handleOnReady}
          columns={filteredColumns}
          showCellBorders="vertical"
          dataSource={pageTableData}
          loading={false} // TODO: come back to this after adding additional remote pagination beyond large first page
          renderLoadMask={renderLoadMask}
          style={gridStyle}
          rowHeight={30}
          renderPaginationToolbar={renderPaginationToolbar}
          pagination="local"
          emptyText="No Results"
          nativeScroll={true}
          theme="green-light"
          defaultLimit={PAGE_SIZE_UI}
          enableColumnAutosize
          showColumnMenuTool={false}
          {...{ onRenderRow }}
        />
        <ColumnSelectModal
          initialCheckedColumn={
            savedCheckedColumns?.length > 0
              ? savedCheckedColumns
              : [
                  'first_name',
                  'last_name',
                  'email',
                  'current_title',
                  'current_company',
                  'salary_total',
                  'location',
                ]
          }
          isOpen={isOpen}
          onClose={onClose}
          columns={columns.map((column) => ({
            value: column.name,
            label: column.header,
          }))}
          setSavedCheckedColumns={setSavedCheckedColumns}
          savedCheckedColumns={savedCheckedColumns}
        />
      </TalentDiscoveryTableContext.Provider>
    </Box>
  );
}

export default TalentDiscoveryTable;

import FlagProvider, {
  IConfig,
  useUnleashContext,
} from '@unleash/proxy-client-react';
import { useEffect } from 'react';

import { useAuthStore } from '@revelio/auth';
import { unleashUserProperties } from '@revelio/iso-utility';

import { environment } from '../../environments/environment';
import { UnleashFallback } from './unleash-fallback';

const unleashedConfig: IConfig = {
  url: environment.UNLEASH_URL,
  clientKey: environment.UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'reveliolabs-dashboard',
};

export const UnleashFlags = ({ children }: { children: React.ReactNode }) => {
  return (
    <FlagProvider config={unleashedConfig}>
      <UnleashFallback>{children}</UnleashFallback>
      <SyncUnleashClientName />
    </FlagProvider>
  );
};

const SyncUnleashClientName = () => {
  const { user } = useAuthStore();
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({
      userId: user?.id ?? undefined,
      properties: unleashUserProperties(user),
    });
  }, [user, updateContext]);
  return null;
};

import { PipelineColumnType } from '@revelio/data-access';

import {
  STANDARD_COMPANY_COLUMN,
  ULTIMATE_PARENT_COMPANY_COLUMN,
} from './shared';

export const LAYOFFS_COLUMNS = [
  {
    heading: null,
    columns: [
      STANDARD_COMPANY_COLUMN,
      {
        id: PipelineColumnType.State,
        label: 'State',
        defaultIsSelected: true,
      },
      { id: PipelineColumnType.City, label: 'City', defaultIsSelected: true },
      {
        id: PipelineColumnType.Msa,
        label: 'msa',
        columnFileSize: 512,
        rowPossibilities: 5254,
      }, // this gets filtered out if the location model version is V3 in favour of `metro_area`
      {
        id: PipelineColumnType.MetroArea,
        label: 'Metropolitan Area',
        columnFileSize: 512,
        rowPossibilities: 5254,
      }, // this gets filtered out if the location model version is below V3
      {
        id: PipelineColumnType.LayoffDate,
        label: 'Layoff Date',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.NumEmployees,
        label: 'Number of Employeed',
        defaultIsSelected: true,
      },
      ULTIMATE_PARENT_COMPANY_COLUMN,
    ],
  },
];

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase } from 'chakra-react-select';
import { isNumber } from 'lodash';
import {
  Controller,
  FieldError,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';

import {
  BinaryOperation,
  CustomColumnConditionVariable,
} from '@revelio/data-access';
import { DownRightArrowIcon } from '@revelio/layout';

import { Deliverable } from '../../deliverables.model';
import { getPipelineType } from '../../deliverables.repository';
import {
  ConditionSelect,
  getDefaultChakraStyles,
} from '../dataset-conditions/condition-select';
import { ConditionValue } from '../dataset-conditions/condition-value';
import {
  MultiGranularitySelectionListVariables,
  NumericOperation,
  NumericVariable,
  SelectionListOperation,
  StringOperation,
} from '../dataset-conditions/conditions.model';
import {
  BINARY_SELECT_OPTIONS,
  EMPTY_CASE_CONDITION,
  EMPTY_VALUE_MESSAGE,
  getConditionVariableSelectOptions,
  getVariableOperationOptions,
} from '../dataset-conditions/dataset-conditions-form-helpers';
import { CustomColumnForm } from './custom.model';

export const CaseConditions = ({
  caseIndex,
  entityId,
  formProps,
}: {
  caseIndex: number;
  entityId: Deliverable['id'];
  formProps: UseFormReturn<CustomColumnForm>;
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
    setError,
  } = formProps;
  const { fields, remove, append } = useFieldArray({
    control,
    name: `categoryCases.${caseIndex}.case.conditions`,
  });
  const caseConditions = useWatch({
    control: control,
    name: `categoryCases.${caseIndex}.case.conditions`,
  });

  const caseBinary = useWatch({
    control: control,
    name: `categoryCases.${caseIndex}.case.binary`,
  });
  const CONDITION_SELECT_WIDTH = '135px';
  return (
    <Box
      background="gray.100"
      borderColor="gray.200"
      borderWidth="1px"
      borderRadius="7px"
      flexGrow="1"
      pt="10px"
      px="12px"
    >
      {fields.map((condition, conditionIndex) => {
        const conditionErrors =
          errors.categoryCases?.[caseIndex]?.case?.conditions?.[conditionIndex];
        const isAddedCondition = conditionIndex > 0;
        const isFirstConditionWithAndOrSelect = conditionIndex === 1;
        return (
          <Box key={condition.id}>
            {isAddedCondition && (
              <Flex marginY="20px" alignItems="center">
                <Divider orientation="horizontal" borderBottomWidth="2px" />
                {isFirstConditionWithAndOrSelect ? (
                  <Controller
                    name={`categoryCases.${caseIndex}.case.binary`}
                    control={control}
                    defaultValue={caseBinary}
                    render={({ field }) => (
                      <ConditionSelect<CustomColumnForm, BinaryOperation>
                        field={field}
                        options={BINARY_SELECT_OPTIONS}
                        chakraStyles={
                          {
                            ...getDefaultChakraStyles({ width: '63px' }),
                            control: (provided) => ({
                              ...provided,
                              background: 'white',
                              width: '63px',
                              mx: '10px',
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              pl: '5px',
                              pr: '0px',
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              ml: '0px',
                            }),
                          } as ChakraStylesConfig<
                            { label: string; value: BinaryOperation },
                            false,
                            GroupBase<{ label: string; value: BinaryOperation }>
                          >
                        }
                      />
                    )}
                  />
                ) : (
                  <Text fontSize="xs" px="10px">
                    {caseBinary}
                  </Text>
                )}
                <Divider orientation="horizontal" borderBottomWidth="2px" />
              </Flex>
            )}
            <Flex justify="space-between" alignItems="center">
              <FormControl isInvalid={!!conditionErrors?.variable}>
                <Flex alignItems="center">
                  <Controller
                    name={`categoryCases.${caseIndex}.case.conditions.${conditionIndex}.variable`}
                    control={control}
                    rules={{
                      required: 'variable is required.',
                    }}
                    render={({ field }) => (
                      <ConditionSelect<
                        CustomColumnForm,
                        | CustomColumnConditionVariable
                        | MultiGranularitySelectionListVariables
                      >
                        field={field}
                        options={getConditionVariableSelectOptions({
                          pipelineType: getPipelineType({ entityId }),
                        })}
                        onChange={(newVariable) => {
                          resetField(
                            `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.operation`,
                            { defaultValue: null }
                          );
                          resetField(
                            `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value`,
                            { defaultValue: [] }
                          );
                        }}
                        chakraStyles={
                          getDefaultChakraStyles({
                            width: CONDITION_SELECT_WIDTH,
                          }) as ChakraStylesConfig<
                            {
                              label: string;
                              value:
                                | CustomColumnConditionVariable
                                | MultiGranularitySelectionListVariables;
                            },
                            false,
                            GroupBase<{
                              label: string;
                              value:
                                | CustomColumnConditionVariable
                                | MultiGranularitySelectionListVariables;
                            }>
                          >
                        }
                      />
                    )}
                  />

                  <FormErrorMessage mt="0" ml="5px">
                    {conditionErrors?.variable?.message}
                  </FormErrorMessage>
                </Flex>
              </FormControl>

              {isAddedCondition && (
                <IconButton
                  bg="none"
                  aria-label="delete condition"
                  icon={<CloseIcon />}
                  size="sm"
                  variant="white"
                  onClick={() => {
                    remove(conditionIndex);
                    if (caseConditions.length === 2) {
                      setValue(
                        `categoryCases.${caseIndex}.case.binary`,
                        undefined
                      );
                    }
                  }}
                />
              )}
            </Flex>

            <Flex mt="10px">
              <DownRightArrowIcon mr="8px" mt="7px" ml="13px" />

              <FormControl
                w={CONDITION_SELECT_WIDTH}
                isInvalid={!!conditionErrors?.operation}
              >
                <Controller
                  name={`categoryCases.${caseIndex}.case.conditions.${conditionIndex}.operation`}
                  control={control}
                  rules={{
                    required: 'Operation is required.',
                  }}
                  render={({ field }) => {
                    return (
                      <ConditionSelect<
                        CustomColumnForm,
                        | NumericOperation
                        | SelectionListOperation
                        | StringOperation
                      >
                        field={field}
                        isDisabled={!caseConditions[conditionIndex]?.variable}
                        options={getVariableOperationOptions(
                          caseConditions[conditionIndex]?.variable
                        )}
                        onChange={(newOperation) => {
                          if (
                            caseConditions[conditionIndex].operation ===
                              NumericOperation.BETWEEN &&
                            newOperation !== NumericOperation.BETWEEN
                          ) {
                            resetField(
                              `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value`,
                              { defaultValue: [] }
                            );
                          }
                        }}
                        chakraStyles={
                          getDefaultChakraStyles({
                            width: CONDITION_SELECT_WIDTH,
                          }) as ChakraStylesConfig<
                            {
                              label: string;
                              value:
                                | NumericOperation
                                | SelectionListOperation
                                | StringOperation;
                            },
                            false,
                            GroupBase<{
                              label: string;
                              value:
                                | NumericOperation
                                | SelectionListOperation
                                | StringOperation;
                            }>
                          >
                        }
                      />
                    );
                  }}
                />

                <FormErrorMessage>
                  {conditionErrors?.operation?.message}
                </FormErrorMessage>
              </FormControl>

              <ConditionValue
                conditionVariable={caseConditions[conditionIndex]?.variable}
                valueError={conditionErrors?.value}
                numericValueProps={{
                  minValue: isNumber(caseConditions[conditionIndex]?.value?.[0])
                    ? (caseConditions[conditionIndex]?.value?.[0] as number)
                    : undefined,
                  maxValuePath: `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value.1`,
                  maxValue: isNumber(caseConditions[conditionIndex]?.value?.[1])
                    ? (caseConditions[conditionIndex]?.value?.[1] as number)
                    : undefined,
                  selectedConditionVariable: caseConditions[conditionIndex]
                    ?.variable as NumericVariable,
                }}
                stringValueProps={{
                  conditionId: caseIndex + condition.id,
                  selectedValues: (caseConditions[conditionIndex]?.value ||
                    []) as string[],
                  setSelections: (values) => {
                    setValue(
                      `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      values as any
                    );
                  },
                  clearError: () => {
                    clearErrors(
                      `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value`
                    );
                  },
                  onNoValues: () => {
                    setError(
                      `categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value`,
                      { message: EMPTY_VALUE_MESSAGE }
                    );
                  },
                  hasError: !!(conditionErrors?.value as FieldError)?.message,
                  isDisabled: !caseConditions[conditionIndex]?.operation,
                }}
                selectedConditionOperation={
                  caseConditions[conditionIndex]?.operation
                }
                formProps={formProps}
                firstValuePath={`categoryCases.${caseIndex}.case.conditions.${conditionIndex}.value.0`}
              />
            </Flex>
          </Box>
        );
      })}

      <Box width="100%" textAlign="right" pb="6px">
        <Button
          colorScheme="lightBlue"
          fontSize="xs"
          fontWeight="600"
          size="sm"
          variant="link"
          onClick={() => {
            append(EMPTY_CASE_CONDITION);
            const isSecondConditionBeingAdded = caseConditions.length === 1;
            if (isSecondConditionBeingAdded) {
              setValue(
                `categoryCases.${caseIndex}.case.binary`,
                BinaryOperation.And
              );
            }
          }}
        >
          {caseBinary ? `+ ${caseBinary}` : 'AND / OR'}
        </Button>
      </Box>
    </Box>
  );
};

import { createStore, propsFactory, withProps } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import { withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';

import { Views } from '@revelio/core';
import { View } from '@revelio/data-access';

import { ViewTypes } from '../data-api/data-api.model';
import { PYTHON_COMPANY_SELECTION_ID } from './filters.constants';
import {
  Filter,
  FilterStoreRootProps,
  OtherFilterNames,
  SelectionList,
  SelectionListIdNames,
  StoredFilterSet,
  ViewFilterDefaults,
} from './filters.model';

export const listNameOverrides: Record<string, string> = {
  [PYTHON_COMPANY_SELECTION_ID]: 'company',
};

export const GlobalFilterSetId = 'GLOBAL';

export const {
  withActiveSet,
  updateActiveSet,
  selectActiveSet,
  resetActiveSet,
  getActiveSet,
  setActiveSet,
} = propsFactory('activeSet', {
  initialValue: GlobalFilterSetId,
});

export const {
  withActiveEntitiesCount,
  updateActiveEntitiesCount,
  selectActiveEntitiesCount,
  resetActiveEntitiesCount,
  getActiveEntitiesCount,
  setActiveEntitiesCount,
} = propsFactory('activeEntitiesCount', {
  initialValue: {},
});

export type Tab = ViewTypes.COMPANY | ViewTypes.GEO | ViewTypes.ROLE;

export type ActiveTabMeta = {
  savedSetView: View;
  view: Views;
  viewType: ViewTypes;
  deepLinkLimit: number;
  limit: number;
  includeDisabledFilters: boolean;
  supportPrimaryEntities: boolean;
  primaryFilters: (SelectionListIdNames | OtherFilterNames)[];
};

const initialTabValue: Partial<ActiveTabMeta> = {};

export const {
  withActiveTabMeta,
  getActiveTabMeta,
  setActiveTabMeta,
  selectActiveTabMeta,
  updateActiveTabMeta,
} = propsFactory('activeTabMeta', { initialValue: initialTabValue });

const initialStringUnset = undefined as string | undefined;

export const {
  withDefaultLastMonth,
  selectDefaultLastMonth,
  getDefaultLastMonth,
  setDefaultLastMonth,
} = propsFactory('defaultLastMonth', { initialValue: initialStringUnset });

export const {
  withLastStartDate,
  selectLastStartDate,
  getLastStartDate,
  setLastStartDate,
} = propsFactory('lastStartDate', { initialValue: initialStringUnset });

export const {
  withCustomActiveSet,
  selectCustomActiveSet,
  resetCustomActiveSet,
  setCustomActiveSet,
} = propsFactory('customActiveSet', { initialValue: initialStringUnset });

export const { storedFilterSetEntitiesRef, withStoredFilterSetEntities } =
  entitiesPropsFactory('storedFilterSet');
export const { selectionListEntitiesRef, withSelectionListEntities } =
  entitiesPropsFactory('selectionList');
export const { viewFilterDefaultEntitiesRef, withViewFilterDefaultEntities } =
  entitiesPropsFactory('viewFilterDefault');

export const filterStore = createStore(
  // base store props & entities
  { name: 'filters' },
  withProps<FilterStoreRootProps>({}),
  // TODO/jbellizzi: we should have separate filter entities for different filter types
  withEntities<Filter>(),
  withActiveIds(),
  // custom entities
  withStoredFilterSetEntities<StoredFilterSet>({ initialValue: [] }),
  withSelectionListEntities<SelectionList>(),
  withViewFilterDefaultEntities<ViewFilterDefaults>(),
  // custom props factories
  withActiveSet(),
  withActiveTabMeta(),
  withDefaultLastMonth(),
  withLastStartDate(),
  withCustomActiveSet(),
  withRequestsStatus<`selectionList`>(),
  withRequestsCache<`selectionList`>(),
  withRequestsStatus<`viewFilterDefault`>(),
  withRequestsCache<`viewFilterDefault`>()
);

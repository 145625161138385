import { Text } from '@chakra-ui/react';

import { useUserCurrency } from '@revelio/auth';
import { FormatType, KdeChart } from '@revelio/replots';

import { PlotCard, convertKdeToSheet } from '../../../../shared/components';
import { adjustKdeDataToUserCurrency } from '../../../compensation/data-fetch/get-kde-data';
import { getKdeData } from './get-kde-data';
import { getLegacyKdeChartProps } from './get-legacy-kde-chart-props';
import { KdeSalaryData } from './types';
import { D3ChartNames } from '@revelio/d3';

interface SalaryDistributionProps {
  salaryData: KdeSalaryData | undefined;
  primaryCompanyRCID: number;
  primaryCompanyName: string | null | undefined;
  loading?: boolean;
}

export const SalaryDistribution = ({
  salaryData,
  primaryCompanyRCID,
  primaryCompanyName,
  loading = false,
}: SalaryDistributionProps) => {
  const { exchangeRate, code } = useUserCurrency();
  const kdeData =
    salaryData && primaryCompanyName
      ? getKdeData(salaryData, primaryCompanyName)
      : [];

  const adjustedKdeData = adjustKdeDataToUserCurrency(kdeData, exchangeRate);

  const legacyChartProps =
    salaryData && primaryCompanyName
      ? getLegacyKdeChartProps({
          salaryData,
          primaryCompanyRCID,
          primaryCompanyName,
        })
      : undefined;

  const plotShareProps =
    code === 'USD'
      ? { legacyChartProps }
      : {
          plotShare: {
            data: adjustedKdeData,
            chartConfig: {
              chartType: D3ChartNames.ReplotsKDE,
              chartProps: {
                format: FormatType.CURRENCY,
                currencyCode: code,
                colors: ['#5BD992'],
              },
            },
          },
        };

  return (
    <PlotCard
      title="Salary Distribution"
      info={
        <Text color="white">
          The distribution of total compensation. More information on the
          compensation model can be found{' '}
          <Text
            as="span"
            color="green.300"
            _hover={{ textDecoration: 'underline' }}
          >
            <a
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Text>
          .
        </Text>
      }
      menu={['expand', 'generate-link', 'download-png', 'download-data']}
      exportData={convertKdeToSheet({
        data: kdeData,
        dimensionName: 'Company',
        metricName: 'Salary Distribution',
      })}
      {...plotShareProps}
    >
      <KdeChart
        data={adjustedKdeData}
        format={FormatType.CURRENCY}
        colors={['#5BD992']}
        loading={loading}
        currencyCode={code}
      />
    </PlotCard>
  );
};

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  Deliverable as APIDeliverable,
  PipelineType,
} from '@revelio/data-access';

import DatasetConfigurationSummary from '../confirmation/dataset-configuration-summary';
import { getPipelineTypeTitleById } from '../data-set.model';
import { Deliverable } from '../deliverables.model';
import { prefillStandardSetDuplicateUniqueFolderNames } from '../deliverables.repository';

const DuplicateDatasetLocations = ({
  duplicateDeliverables,
  isRevelioAdmin,
  formProps,
}: {
  duplicateDeliverables: Deliverable[];
  isRevelioAdmin: boolean;
  formProps: UseFormReturn<{ [delId: string]: string }>;
}) => {
  useEffect(() => {
    prefillStandardSetDuplicateUniqueFolderNames();
  });

  const groupedDuplicates = groupBy(
    duplicateDeliverables,
    (del) => del.pipeline.pipeline_type
  );

  const {
    register,
    formState: { errors },
    getValues,
  } = formProps;

  return (
    <>
      <Heading as="h3" color="text.primary" fontWeight="600" fontSize="md">
        Duplicate Dataset Folders
      </Heading>
      <Text>
        There are duplicate datasets which would overwrite each other in the
        standard delivery location. Please choose a unique name for each of the
        duplicated datasets.
      </Text>

      {Object.keys(groupedDuplicates).map((pipelineType) => (
        <SimpleGrid columns={3} spacing={10}>
          {groupedDuplicates[pipelineType].map((d) => (
            <Popover placement="end-start" trigger="hover">
              <PopoverTrigger>
                <FormControl
                  id={d.id as string}
                  isInvalid={!!errors[d.id]}
                  mt="10"
                  pl="5"
                >
                  <FormLabel fontSize="sm" color="text.primary">
                    {getPipelineTypeTitleById(
                      d.pipeline.pipeline_type as PipelineType
                    )}
                  </FormLabel>

                  <Input
                    {...register(d.id as string, {
                      required:
                        'identifying name is required for duplicate datasets',
                      minLength: {
                        value: 1,
                        message: 'Minimum length should be 1',
                      },
                      validate: {
                        nameUnique: (value) => {
                          if (
                            Object.values(getValues()).filter(
                              (existingName) => value === existingName
                            ).length > 1
                          ) {
                            return 'Name must be unique.';
                          }

                          return true;
                        },
                      },
                    })}
                    placeholder="unique folder name"
                  />
                  <FormErrorMessage>
                    {errors[d.id] && errors[d.id].message}
                  </FormErrorMessage>
                </FormControl>
              </PopoverTrigger>

              <Portal>
                <PopoverContent cursor="default">
                  <PopoverHeader>Settings</PopoverHeader>
                  <PopoverBody pl={0} mr="3">
                    <DatasetConfigurationSummary
                      isRevelioAdmin={isRevelioAdmin}
                      lagData={null}
                      deliverables={[d as unknown as APIDeliverable]}
                      showHeading={false}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          ))}
        </SimpleGrid>
      ))}
    </>
  );
};

export default DuplicateDatasetLocations;

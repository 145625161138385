import { useToast } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { useUserTrialDetails } from '@revelio/auth';
import { downloadFile } from '@revelio/core';
import {
  GO_API_ROOT,
  PrimaryDataView,
  RequestMethod,
  ViewTypes,
} from '@revelio/filtering';

export const convertViewTypeToDownloadView = (
  viewType: ViewTypes | undefined
): PrimaryDataView | 'none' => {
  if (!viewType) {
    return 'none';
  }

  if (viewType === ViewTypes.COMPANY) {
    return 'company';
  }
  if (viewType === ViewTypes.GEO) {
    return 'geography';
  }
  if (viewType === ViewTypes.ROLE) {
    return 'role';
  }

  throw new Error(`Unknown view type: ${viewType}`);
};

type UseDownloadPlotDataProps<FilterType> = {
  downloadPath?: string;
  serialisedFilters?: FilterType; // can be optional when filters are serialised as queryParams in the downloadPath
};

export const useDownloadData = <FilterType,>({
  serialisedFilters,
  downloadPath,
}: UseDownloadPlotDataProps<FilterType>) => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const triggerDownload = useCallback(async () => {
    if (isTrialUser) {
      console.error('Trial user cannot download data');
      return;
    }

    if (!downloadPath) {
      console.error('No download path provided');
      return;
    }

    setIsLoading(true);
    try {
      const fileDownloadResponse = await fetch(
        `${GO_API_ROOT}/${downloadPath}`,
        {
          method: serialisedFilters ? RequestMethod.POST : RequestMethod.GET,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
          body: serialisedFilters
            ? JSON.stringify(serialisedFilters)
            : undefined,
        }
      );
      if (!fileDownloadResponse.ok) {
        throw new Error(
          `Error: ${fileDownloadResponse.status} ${fileDownloadResponse.statusText}`
        );
      }

      const fileBlob = await fileDownloadResponse.blob();
      downloadFile({
        fileBlob,
        responseContentDisposition: fileDownloadResponse.headers.get(
          'Content-Disposition'
        ) as string,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Failed to download data',
        description:
          "We've run into an error and our team has been alerted. Please try again later.",
        status: 'error',
        duration: 4000,
        position: 'top-right',
      });
    }

    setIsLoading(false);
  }, [isTrialUser, toast, serialisedFilters, downloadPath, setIsLoading]);

  return { triggerDownload, isLoading };
};

import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import { isUndefined, set, startCase } from 'lodash';
import { useState } from 'react';
import { useLifecycles } from 'react-use';
import { pipe, shareReplay, tap } from 'rxjs';
import { Get } from 'type-fest';

import {
  UserTrackingEvents,
  addLoadingStatus,
  emitMixpanelUserEvent,
  toggleStatusOnGlobalLoaderOrSkipOne,
} from '@revelio/core';

import { ViewTypes } from '../../data-api/data-api.model';
import {
  addActiveFiltersIds,
  queryFilterEngine,
  upsertFilter,
  useSelectionLists,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
import {
  FilterBase,
  FilterItem,
  SelectFilter,
  SelectionListIdNames,
} from '../../engine/filters.model';
import {
  persistFilterStore,
  removeActiveFiltersIds,
} from '../../engine/filters.repository';

export interface TabsFilterProps {
  initialValue?: { id: any; label: string; index?: number };
  filterName: SelectionListIdNames;
  selectionList?: SelectionListIdNames;
  includeInFilters?: boolean;
  includeTabChangeLoaderEvent?: boolean;
  filterMetadata?: {
    [key: string]: any;
    isMulti?: boolean;
    customFormatter?: Get<FilterBase, 'customFormatter'>;
  };
}

export function TabsFilter({
  initialValue = {
    id: ViewTypes.SNAPSHOT,
    label: startCase(ViewTypes.SNAPSHOT),
    index: 0,
  },
  filterName,
  selectionList,
  includeInFilters = false,
  includeTabChangeLoaderEvent = true,
  filterMetadata = {
    isMulti: false,
  },
}: TabsFilterProps) {
  const [tabIndex, setTabIndex] = useState<number>();

  useLifecycles(
    () => includeInFilters && addActiveFiltersIds([filterName]),
    () => includeInFilters && removeActiveFiltersIds([filterName])
  );

  const [pickedSelectionList] = useSelectionLists([
    selectionList || filterName,
  ]);

  useEffect$(
    () =>
      persistFilterStore.initialized$.pipe(
        shareReplay(1),
        tap(() => {
          const filterState = queryFilterEngine(
            filterName
          ) as SelectFilter<FilterItem>;
          if (filterState?.value) {
            setTabIndex(filterState.value.index || 0);
          } else {
            let defaultIndex = initialValue.index || 0;
            if (pickedSelectionList) {
              defaultIndex = pickedSelectionList.value.findIndex(
                (v) => v.id === initialValue.id
              );
            }
            handleViewTypeChange(defaultIndex);
          }
        })
      ),
    [initialValue]
  );

  useSingleOrMoreFilterState<SelectFilter<FilterItem>>(
    filterName,
    pipe(
      tap((filter) => {
        if (!isUndefined((filter as SelectFilter<FilterItem>)?.value.index)) {
          setTabIndex((filter as SelectFilter<FilterItem>).value.index);
        }
      })
    )
  );

  const handleViewTypeChange = (i: number) => {
    if (includeTabChangeLoaderEvent) {
      toggleStatusOnGlobalLoaderOrSkipOne('tabChange');
      addLoadingStatus('tabChange');
    }
    setTabIndex(i);
    const value = pickedSelectionList
      ? set(pickedSelectionList?.value[i], 'index', i)
      : (initialValue as any);
    upsertFilter<SelectFilter<FilterItem>>(filterName, {
      value,
      ...filterMetadata,
    });

    emitMixpanelUserEvent(UserTrackingEvents.TAB_CHANGE);
  };

  return (
    <Tabs
      display="flex"
      defaultIndex={tabIndex}
      index={tabIndex || 0}
      onChange={handleViewTypeChange}
      variant="line-green"
      size="sm"
      position="relative"
      height="1.8rem"
      alignSelf="flex-end"
      lineHeight="1.2"
      zIndex={0}
    >
      <TabList>
        {pickedSelectionList?.value?.map((vt, i) => (
          <Tab key={i} position="relative" style={{ whiteSpace: 'nowrap' }}>
            {vt.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default TabsFilter;

import { graphql } from '../../gql';

export const COMPOSITION_GET_SUMMARY_DATA = graphql(`
  query CompositionSummaryData($filters: Filters, $dim1: Dimension1) {
    composition(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
        type
        __typename
      }
      metrics {
        __typename
        # Single value metrics
        headcount {
          timeseries {
            id
            date
            value
          }
        }
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        hiring_rate {
          timeseries {
            id
            date
            value
          }
        }
        attrition_rate {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
  }
`);

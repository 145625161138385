import { Box, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { SelectFooter } from '../../select-footer';
import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import { TalentDiscoveryFreeTextState } from '../../td-filter-reducer';
import {
  TalentDiscoveryFilterOption,
  isFreeTextFilter,
  isFreeTextFilterState,
} from '../../types';
import { NameSearch } from './name-search';

interface TDNameFilterProps {
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
  filterId?: string;
}

export const TDNameFilter = ({
  closeMenu,
  selectedFilter,
  filterId,
}: TDNameFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();
  const inputRef = useRef(null);

  useEffect(() => {
    // Small delay to ensure the input is mounted and Popover is fully open
    const timer = setTimeout(() => {
      if (inputRef.current) {
        (inputRef.current as HTMLInputElement).focus();
      }
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  const isEditing = !!filterId;
  const selectedFreeTextState = (() => {
    if (isEditing) {
      return state.filters?.find(
        (filter) => isFreeTextFilterState(filter) && filter.id === filterId
      ) as TalentDiscoveryFreeTextState;
    }

    return undefined;
  })();

  const [name, setName] = useState<string>(
    selectedFreeTextState?.text?.[0] || ''
  );
  const handleAddFilter = () => {
    if (isFreeTextFilter(selectedFilter.value)) {
      const newId = filterId || `${Date.now()}`;
      dispatch({
        type: 'ADD_FREE_TEXT_FILTER',
        name: selectedFilter.value,
        text: [name],
        id: selectedFilter.supportsMultiple ? newId : null,
      });
    }
    closeMenu();
  };

  const isAddDisabled = name?.length === 0;

  return (
    <>
      <PopoverBody padding={0}>
        <Box onClick={(e) => e.stopPropagation()} mt={1}>
          <Box data-testid="free-text-input">
            <NameSearch
              searchString={name}
              setSearchString={setName}
              vStackProps={{ my: -2 }}
              isTypeaheadDisabled={false}
              initialFocusRef={inputRef}
            />
          </Box>
        </Box>
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={selectedFreeTextState ? 'Update' : 'Add'}
          isAddDisabled={isAddDisabled}
        />
      </PopoverFooter>
    </>
  );
};

import { useQuery } from 'urql';

import { CompensationTypes, PrimaryView } from '@revelio/core';
import {
  FilterName,
  POSTING_GET_DATA,
  SelectionCategories,
  getSkipShareOfPostingsScalingParam,
  useActiveColors,
  useActiveFiltersState,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

import { getDim1FromView } from '../../../utils';
import { getCompanyLookup } from './company-lookup';
import { getKdeData } from './get-kde-data';
import { getOvertimeData } from './get-overtime-data';
import { getTimeToFillData } from './get-time-to-fill-data';
import { getTopBoxData } from './get-top-box-data';
import { useIsQueryReady } from './is-query-ready';
import {
  transformFiltersToPayQuery,
  transformFiltersToPostingsQuery,
} from './transform-filters';
import { useFetchCompensation } from './use-fetch-compensation';

export const usePayDataFetch = ({
  view,
  primaryFilters,
  compensationType,
  modelsCompensationUrl,
}: {
  view: PrimaryView;
  primaryFilters: FilterName[];
  compensationType: CompensationTypes;
  modelsCompensationUrl: string;
}) => {
  /** ======== Filters ======== */
  const activeFilters = useActiveFiltersState();
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  const { colorMap } = useActiveColors(primaryFilters as SelectionCategories[]);

  const isReady = useIsQueryReady({ activeFilters, primaryFilters, view });

  const compensationFilters = transformFiltersToPayQuery({
    filters: activeFilters,
    isCustomRoleTaxonomyEnabled,
    primaryView: view,
  });

  /** ======== Compensation Fetch ======== */
  const {
    data: compensationData,
    loading: compensationLoading,
    companyNameMap,
  } = useFetchCompensation({
    view,
    filters: compensationFilters,
    modelsCompensationUrl,
    isReady,
  });

  const companyLookup =
    view === PrimaryView.COMPANY
      ? getCompanyLookup(activeFilters)
      : companyNameMap;

  /** ======== POSTINGS ======== */
  const postingsFilters = transformFiltersToPostingsQuery({
    filters: activeFilters,
    isCustomRoleTaxonomyEnabled,
    primaryView: view,
  });

  const [{ data: postingsData, fetching: postingsFetching }] = useQuery({
    query: POSTING_GET_DATA,
    variables: {
      dim1: getDim1FromView(view),
      filters: postingsFilters,
      ...getSkipShareOfPostingsScalingParam(),
    },
    pause: !isReady,
  });

  const compensationTimeseriesData = compensationData
    ?.find((d) => d?.id === compensationType)
    ?.value?.find((v) => v?.id === 'timeseries')?.value;

  const overtimeData = compensationTimeseriesData
    ? getOvertimeData({
        data: compensationTimeseriesData,
        companyLookup,
      })
    : [];

  const altCompensationType = (() => {
    switch (compensationType) {
      case CompensationTypes.TOTAL:
        return CompensationTypes.BASE;
      case CompensationTypes.BASE:
        return CompensationTypes.TOTAL;
      case CompensationTypes.HOURLY:
        return null;
    }
  })();
  const altCompensationTimeseriesData = compensationData
    ?.find((d) => d?.id === altCompensationType)
    ?.value?.find((v) => v?.id === 'timeseries')?.value;

  const timeseriesValues = compensationTimeseriesData
    ?.flatMap((v) => v.value)
    ?.filter((v) => typeof v === 'number');

  const minTimeseriesValue = timeseriesValues?.reduce(
    (min, curr) => Math.min(min, curr),
    Infinity
  );
  const maxTimeseriesValue = timeseriesValues?.reduce(
    (max, curr) => Math.max(max, curr),
    -Infinity
  );

  const altTimeseriesValues = altCompensationTimeseriesData
    ?.flatMap((v) => v.value)
    ?.filter((v) => typeof v === 'number');

  const minAltTimeseriesValue = altTimeseriesValues?.reduce(
    (min, curr) => Math.min(min, curr),
    Infinity
  );
  const maxAltTimeseriesValue = altTimeseriesValues?.reduce(
    (max, curr) => Math.max(max, curr),
    -Infinity
  );

  const minOvertimeValue =
    typeof minTimeseriesValue === 'number' &&
    typeof minAltTimeseriesValue === 'number'
      ? Math.min(minTimeseriesValue, minAltTimeseriesValue)
      : undefined;

  const maxOvertimeValue =
    typeof maxTimeseriesValue === 'number' &&
    typeof maxAltTimeseriesValue === 'number'
      ? Math.max(maxTimeseriesValue, maxAltTimeseriesValue)
      : undefined;

  const timeToFillData = postingsData?.posting
    ? getTimeToFillData(postingsData?.posting)
    : [];

  const compensationTopRoleData = compensationData
    ?.find((d) => d?.id === compensationType)
    ?.value?.find((v) => v?.id === 'top_role')?.value;
  const topRoleData = compensationTopRoleData
    ? getTopBoxData({ data: compensationTopRoleData, companyLookup })
    : [];

  const compensationTopCompanyData = compensationData
    ?.find((d) => d?.id === compensationType)
    ?.value?.find((v) => v?.id === 'top_company')?.value;
  const topCompanyData = compensationTopCompanyData
    ? getTopBoxData({ data: compensationTopCompanyData, companyLookup })
    : [];

  const compensationTopGeographyData = compensationData
    ?.find((d) => d?.id === compensationType)
    ?.value?.find((v) => v?.id === 'top_geography')?.value;
  const topGeographyData = compensationTopGeographyData
    ? getTopBoxData({ data: compensationTopGeographyData, companyLookup })
    : [];

  const compensationKdeData = compensationData
    ?.find((d) => d?.id === compensationType)
    ?.value?.find((v) => v?.id === 'kde')?.value;
  const kdeData = compensationKdeData
    ? getKdeData({ data: compensationKdeData, companyLookup })
    : [];

  return {
    overtimeData,
    minOvertimeValue,
    maxOvertimeValue,
    timeToFillData,
    kdeData,
    topCompanyData,
    topGeographyData,
    topRoleData,
    loading: compensationLoading || postingsFetching,
    isQueryReady: isReady,
    colorMap,
  };
};

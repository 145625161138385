import { MenuItem } from '@chakra-ui/react';
import { getAllEntities, setEntities } from '@ngneat/elf-entities';

import {
  PipelineColumnType,
  PipelineType,
  PostingFrequency,
  PostingSource,
} from '@revelio/data-access';

import { getDatasetColumnDefaults } from './columns/columns.model';
import {
  DEMOGRAPHIC_BREAKDOWN_DEFAULTS,
  GEO_BREAKDOWN_DEFAULTS,
} from './columns/configurations/workforce-dynamics';
import { applyPipelineConfiguration } from './columns/saved-pipeline-configurations/saved-pipeline-configuration-select-helpers';
import { getDatasetId } from './data-set.model';
import {
  CompleteCompanyInfoPipeline,
  CompleteIndividualPipeline,
  CompletePipeline,
  CompletePostingPipeline,
  Deliverable,
  Pipeline,
} from './deliverables.model';
import {
  NEW_DELIVERABLE_DEFAULTS,
  deliverablesStore,
  setColumnsToDefault,
  setMandatoryColumns,
} from './deliverables.repository';

const CreateStandardSet = ({
  isRevelioAdmin,
  onSuccess,
}: {
  isRevelioAdmin: boolean;
  onSuccess: () => void;
}) => {
  return (
    isRevelioAdmin && (
      <MenuItem
        fontSize="12px"
        _hover={{ background: 'brightGreen.100' }}
        onClick={() => {
          deliverablesStore.update(
            setEntities(getSampleDeliverablePipelines())
          );

          const WF_ID_TO_COLUMN_MAP: {
            [id: string]: PipelineColumnType[];
          } = {
            [WF_ROLE_ID]: getDatasetColumnDefaults({
              entityId: WF_ROLE_ID,
            }),
            [WF_DEMOGRAPHIC_ID]: DEMOGRAPHIC_BREAKDOWN_DEFAULTS,
            [WF_GEOGRAPHY_ID]: GEO_BREAKDOWN_DEFAULTS,
          };
          deliverablesStore.query(getAllEntities()).forEach((entity) => {
            const configuredColumns = WF_ID_TO_COLUMN_MAP[
              entity.id
            ] as PipelineColumnType[];
            if (configuredColumns) {
              applyPipelineConfiguration({
                entityId: entity.id,
                columns: configuredColumns,
              });
            } else {
              setMandatoryColumns({ entityId: entity.id });
              setColumnsToDefault({ entityId: entity.id });
            }
          });

          onSuccess();
        }}
      >
        Create Standard Set
      </MenuItem>
    )
  );
};

export default CreateStandardSet;

const WF_DYNAMIC_DATASET_ID = getDatasetId(PipelineType.WfDynam);
const WF_ROLE_ID = `${WF_DYNAMIC_DATASET_ID}a`;
const WF_DEMOGRAPHIC_ID = `${WF_DYNAMIC_DATASET_ID}b`;
const WF_GEOGRAPHY_ID = `${WF_DYNAMIC_DATASET_ID}c`;

const POSTING_DYNAMIC_DATASET_ID = getDatasetId(PipelineType.Posting);
const POSTING_INDEED_ID = `${POSTING_DYNAMIC_DATASET_ID}a`;
const POSTING_MIXRANK_ID = `${POSTING_DYNAMIC_DATASET_ID}b`;
const POSTING_UNIFIED_ID = `${POSTING_DYNAMIC_DATASET_ID}c`;
const POSTING_LINKUP_ID = `${POSTING_DYNAMIC_DATASET_ID}d`;

const POSTING_INDIVIDUAL_DATASET_ID = getDatasetId(
  PipelineType.PostingsIndividual
);
const POSTING_INDIVIDUAL_INDEED_ID = `${POSTING_INDIVIDUAL_DATASET_ID}a`;
const POSTING_INDIVIDUAL_MIXRANK_ID = `${POSTING_INDIVIDUAL_DATASET_ID}b`;
const POSTING_INDIVIDUAL_UNIFIED_ID = `${POSTING_INDIVIDUAL_DATASET_ID}c`;
const POSTING_INDIVIDUAL_LINKUP_ID = `${POSTING_INDIVIDUAL_DATASET_ID}d`;
const getSampleDeliverablePipelines = () => {
  const standardSetPipelines: Partial<
    Deliverable<
      | CompletePipeline
      | CompletePostingPipeline
      | CompleteIndividualPipeline
      | CompleteCompanyInfoPipeline
    >
  >[] = [
    { id: WF_ROLE_ID, pipeline: { pipeline_type: PipelineType.WfDynam } },
    {
      id: WF_DEMOGRAPHIC_ID,
      pipeline: { pipeline_type: PipelineType.WfDynam },
    },
    { id: WF_GEOGRAPHY_ID, pipeline: { pipeline_type: PipelineType.WfDynam } },
    { pipeline: { pipeline_type: PipelineType.SkillDynam } },
    { pipeline: { pipeline_type: PipelineType.Transition } },
    {
      pipeline: {
        pipeline_type: PipelineType.Individual,
        individual_user_configuration: { current: false, full_history: false },
      },
    },
    {
      id: POSTING_INDEED_ID,
      pipeline: {
        pipeline_type: PipelineType.Posting,
        source: PostingSource.Indeed,
        posting_configuration: { frequency: PostingFrequency.Month },
      },
    },
    {
      id: POSTING_MIXRANK_ID,
      pipeline: {
        pipeline_type: PipelineType.Posting,
        source: PostingSource.Mixrank,
        posting_configuration: { frequency: PostingFrequency.Month },
      },
    },
    {
      id: POSTING_UNIFIED_ID,
      pipeline: {
        pipeline_type: PipelineType.Posting,
        source: PostingSource.Unified,
        posting_configuration: { frequency: PostingFrequency.Month },
      },
      isExcluded: true,
    },
    {
      id: POSTING_LINKUP_ID,
      pipeline: {
        pipeline_type: PipelineType.Posting,
        source: PostingSource.Linkup,
        posting_configuration: { frequency: PostingFrequency.Month },
      },
      isExcluded: true,
    },
    {
      id: POSTING_INDIVIDUAL_INDEED_ID,
      pipeline: {
        pipeline_type: PipelineType.PostingsIndividual,
        source: PostingSource.Indeed,
      },
    },
    {
      id: POSTING_INDIVIDUAL_MIXRANK_ID,
      pipeline: {
        pipeline_type: PipelineType.PostingsIndividual,
        source: PostingSource.Mixrank,
      },
    },
    {
      id: POSTING_INDIVIDUAL_UNIFIED_ID,
      pipeline: {
        pipeline_type: PipelineType.PostingsIndividual,
        source: PostingSource.Unified,
      },
      isExcluded: true,
    },
    {
      id: POSTING_INDIVIDUAL_LINKUP_ID,
      pipeline: {
        pipeline_type: PipelineType.PostingsIndividual,
        source: PostingSource.Linkup,
      },
      isExcluded: true,
    },
    { pipeline: { pipeline_type: PipelineType.ReviewTrends } },
    { pipeline: { pipeline_type: PipelineType.IndividualReviews } },
    { pipeline: { pipeline_type: PipelineType.SentimentScores } },
    { pipeline: { pipeline_type: PipelineType.Layoffs } },
    {
      pipeline: {
        pipeline_type: PipelineType.CompanyInfo,
        company_info_configuration: { include_subsidiaries: false },
      },
    },
  ];

  const pipelineConfigurations: Deliverable[] = standardSetPipelines.map(
    (pipeline) => ({
      ...NEW_DELIVERABLE_DEFAULTS,
      ...pipeline,
      id:
        pipeline.id ||
        getDatasetId(
          (pipeline.pipeline as Pipeline).pipeline_type as PipelineType
        ),
      pipeline: {
        ...(pipeline.pipeline || {}),
      },
    })
  );
  return pipelineConfigurations;
};

import { useState, useCallback } from 'react';
import { DASH_META_ROOT, getAuthDashMetaCsrfToken } from '@revelio/auth';
import { HEALTH_CHECKS, HealthCheckResult } from '@revelio/data-access';

interface HealthCheckResponse {
  results: HealthCheckResult[];
  timestamp: string;
}

export function useHealthCheckApi() {
  const [requests, setRequests] = useState<HealthCheckResult[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const runHealthChecks = useCallback(async () => {
    const csrfToken = getAuthDashMetaCsrfToken();
    if (!csrfToken) {
      console.error('No CSRF token available');
      return;
    }

    setIsFetching(true);
    try {
      const response = await fetch(`${DASH_META_ROOT}/api/health/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-surf-token-dash-meta': csrfToken || '',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: HealthCheckResponse = await response.json();
      setRequests(data.results);
    } catch (error) {
      console.error('Error running health checks:', error);
      setRequests([]);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const runSingleHealthCheck = useCallback(
    async (checkId: string, variables?: Record<string, unknown>) => {
      const csrfToken = getAuthDashMetaCsrfToken();
      if (!csrfToken) {
        console.error('No CSRF token available');
        return;
      }

      const [service, viewGroup, view] = checkId.split(':');
      const check = HEALTH_CHECKS.find(
        (c) =>
          c.service === service && c.viewGroup === viewGroup && c.view === view
      );

      if (!check) {
        console.error(`No health check found for: ${checkId}`);
        return;
      }

      setIsFetching(true);
      try {
        const response = await fetch(
          `${DASH_META_ROOT}/api/health/check/${checkId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-surf-token-dash-meta': csrfToken || '',
            },
            body: JSON.stringify({
              variables,
              isManualCheck: true,
            }),
            credentials: 'include',
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        // Update only the result for this check while keeping other results
        setRequests((prev) => {
          const updatedResults = [...prev];
          const resultIndex = updatedResults.findIndex(
            (r) =>
              r.service === service &&
              r.viewGroup === viewGroup &&
              r.view === view
          );
          if (resultIndex >= 0) {
            updatedResults[resultIndex] = result;
          } else {
            updatedResults.push(result);
          }
          return updatedResults;
        });
      } catch (error) {
        console.error(`Error running health check for ${checkId}:`, error);
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const fetchHistory = useCallback(async () => {
    const csrfToken = getAuthDashMetaCsrfToken();
    if (!csrfToken) {
      console.error('No CSRF token available');
      return;
    }

    setIsFetching(true);
    try {
      const response = await fetch(`${DASH_META_ROOT}/api/health/history`, {
        headers: {
          'Content-Type': 'application/json',
          'x-surf-token-dash-meta': csrfToken || '',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRequests(data);
    } catch (error) {
      console.error('Error fetching health check history:', error);
      setRequests([]);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return {
    requests,
    isFetching,
    runHealthChecks,
    runSingleHealthCheck,
    fetchHistory,
  };
}

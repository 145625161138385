import { graphql } from '../../gql';

export const POSTINGS_GET_ACTIVE = graphql(`
  query PostingsGetActive(
    $filters: PostingFilters
    $dim1: Dimension1
    $skip_share_of_postings_scaling: Boolean
  ) {
    posting(
      filters: $filters
      dim1: $dim1
      dim2: date
      skip_share_of_postings_scaling: $skip_share_of_postings_scaling
    ) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          active
        }
      }
    }
  }
`);

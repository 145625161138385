import { SingleValue } from 'chakra-react-select';

import {
  CompositionDataQuery,
  CompositionSummaryDataQuery,
  SentimentGetSummaryDataQuery,
} from '@revelio/data-access';

export type SummaryEntities = NonNullable<CompositionDataQuery['composition']>;
export enum CompanySummaryMetric {
  Headcount = 'headcount',
  AttritionRate = 'attrition_rate',
  GrowthRate = 'growth_rate',
  HiringRate = 'hiring_rate',
}

export enum GeographySummaryMetric {
  Skills = 'skills',
  Roles = 'job_categories',
  Industries = 'industries',
  // Companies = 'companies',
}

// todo
export enum RoleSummaryMetric {
  Skills = 'skills',
  Roles = 'job_categories',
  // Companies = 'companies',
  Industries = 'industries',
  Geographies = 'geographies',
}

export type HorizontalPlotDataFormat =
  | ({
      id: number | null | undefined;
      value: number | null | undefined;
      metadata: {
        longName: string | null | undefined;
        shortName: string | null | undefined;
        type: string | null | undefined;
        isPrimary?: boolean;
      };
    } | null)[]
  | null;

export type GroupedHorizontalBarChartDataFormat = {
  id: number | null | undefined;
  metadata: {
    longName: string | null | undefined;
    shortName: string | null | undefined;
    count?: string | null | undefined;
  };
  value: ({
    id: number | null | undefined;
    metadata: {
      longName: string | null | undefined;
      shortName: string | null | undefined;
      count?: number | null | undefined;
    };
    value: number | null | undefined;
  } | null)[];
} | null;

export interface CompetitorBarChartsData {
  headcount: HorizontalPlotDataFormat;
  attrition_rate: HorizontalPlotDataFormat;
  growth_rate: HorizontalPlotDataFormat;
  hiring_rate: HorizontalPlotDataFormat;
}

export interface GeographyBarChartsData {
  industries: GroupedHorizontalBarChartDataFormat[] | null;
  skills: GroupedHorizontalBarChartDataFormat[] | null;
  job_categories: GroupedHorizontalBarChartDataFormat[] | null;
}

export type GeographySummaryEntities = Pick<
  NonNullable<NonNullable<NonNullable<SummaryEntities>[number]>['metrics']>,
  'industries' | 'skills' | 'job_categories'
>;

// todo
export interface RoleBarChartsData {
  industries: GroupedHorizontalBarChartDataFormat[] | null;
  skills: GroupedHorizontalBarChartDataFormat[] | null;
  job_categories: GroupedHorizontalBarChartDataFormat[] | null;
  geographies: GroupedHorizontalBarChartDataFormat[] | null;
  // companies: GroupedHorizontalBarChartDataFormat[] | null;
}

export type SingleOption = SingleValue<{
  value: string;
  label: string;
}>;

export type CompositionMetricValue = Exclude<
  keyof NonNullable<
    NonNullable<
      NonNullable<CompositionSummaryDataQuery['composition']>[0]
    >['metrics']
  >,
  '__typename'
>;

export type SelectOption<T> = SingleValue<{
  value: T;
  label: string;
}>;

export type SentimentMetricValue = keyof Omit<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<SentimentGetSummaryDataQuery['sentiment2d']>[0]
        >['category']
      >['0']
    >['metrics']
  >,
  '__typename'
>;

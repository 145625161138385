import { useSteps } from '@chakra-ui/react';
import { useState } from 'react';

import { BaseStepsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import {
  SnowflakeDataInterface,
  SnowflakeMapperContext,
} from './snowflake-mapper-context';

export const SnowflakeMapperProvider = ({
  children,
  baseSteps,
}: {
  children: React.ReactElement;
  baseSteps: BaseStepsInterface[];
}) => {
  const [snowflakeData, setSnowflakeData] = useState<SnowflakeDataInterface>({
    headers: [],
    combinedData: [{}],
    mappedColumns: [],
    oryId: '',
    clientName: '',
    email: '',
    formData: {
      database: '',
      schema: '',
      table: '',
    },
  });

  const steps = [...baseSteps];

  const stepperState = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <SnowflakeMapperContext.Provider
      value={{
        ...snowflakeData,
        ...stepperState,
        setData: setSnowflakeData,
        steps,
      }}
    >
      {children}
    </SnowflakeMapperContext.Provider>
  );
};

import {
  EntityInput,
  EntityTypeEnum,
  ReportFilterInput,
  ReportFilterType,
  ReportRequest,
  ReportTypeEnum,
} from '@revelio/data-access';
import { TreeItem } from '@revelio/filtering';

import {
  EntityType,
  IReportEntityMap,
  ReportEntityConfigColumn,
} from '../entity-configuration/utils';
import {
  EntitySelectionItem,
  ReportBuilderDeliverable,
} from '../report-builder.model';

const groupSelectionsByListId = (
  selections?: EntitySelectionItem
): Record<string, TreeItem<string>[]> => {
  if (!selections) {
    return {};
  }
  return Object.values(selections).reduce(
    (grouped, selectionItem) => {
      const { selectionListId } = selectionItem;

      if (!grouped[selectionListId]) {
        grouped[selectionListId] = [];
      }

      grouped[selectionListId].push(selectionItem);

      return grouped;
    },
    {} as Record<string, TreeItem<string>[]>
  );
};

interface EntityGroupProps {
  report: ReportBuilderDeliverable;
  configSet: ReportEntityConfigColumn[];
  isFilter?: boolean;
}
const getEntityGroup = ({ report, configSet, isFilter }: EntityGroupProps) => {
  const mainEntities: {
    type: EntityTypeEnum | ReportFilterType;
    values: string[];
    id?: string;
  }[] = configSet.reduce(
    (acc, mainEntityConfig) => {
      const entitySelection = report.selections[mainEntityConfig.id] ?? {};
      const groupedSelections = groupSelectionsByListId(entitySelection);

      const id = isFilter ? mainEntityConfig.id : undefined;

      let values = Object.entries(groupedSelections).map(([key, value]) => {
        return {
          type: key as EntityTypeEnum | ReportFilterType,
          values: value.map((v) => v.item?.id as string),
          id,
        };
      });
      if (values.length === 0) {
        values = mainEntityConfig.reportEntityOptions.selectionList.filters.map(
          (filter) => ({
            type: filter as unknown as EntityTypeEnum | ReportFilterType,
            values: [],
            id,
          })
        );
      }

      return [...acc, ...values];
    },
    [] as { type: EntityTypeEnum | ReportFilterType; values: string[] }[]
  );

  return mainEntities;
};

export const generatePayload = (
  report: ReportBuilderDeliverable,
  reportEntityMap: IReportEntityMap
): ReportRequest | null => {
  if (!report || !reportEntityMap) {
    return null;
  }

  const byEntity =
    (entityType: EntityType) => (entityColumn: ReportEntityConfigColumn) =>
      entityColumn.reportEntityOptions.entityType === entityType;

  const mainEntityConfigs = reportEntityMap[report.reportType].columns.filter(
    byEntity(EntityType.MainEntity)
  );

  const comparisonEntityConfigs = reportEntityMap[
    report.reportType
  ].columns.filter(byEntity(EntityType.ComparisonEntity));

  const filterEntityConfigs = reportEntityMap[report.reportType].columns.filter(
    byEntity(EntityType.FilterEntity)
  );

  const mainEntities = getEntityGroup({
    report,
    configSet: mainEntityConfigs,
  }) as EntityInput[];

  const comparisonEntities = getEntityGroup({
    report,
    configSet: comparisonEntityConfigs,
  }) as EntityInput[];

  const filters = getEntityGroup({
    report,
    configSet: filterEntityConfigs,
    isFilter: true,
  }) as ReportFilterInput[];

  const hasComparisonEntities = comparisonEntities.length > 0;
  const hasFilters = filters.length > 0;

  return {
    reportType: report.reportType as ReportTypeEnum,
    mainEntities,
    comparisonEntities: hasComparisonEntities ? comparisonEntities : undefined,
    filters: hasFilters ? filters : undefined,
  };
};

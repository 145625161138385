// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LockIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { FiMapPin } from 'react-icons/fi';

import { useUserTrialDetails } from '@revelio/auth';

import { EmailPopover } from './EmailPopover';
import { EmailIcon } from './icons/email-icon';
import { LinkedinIcon } from './icons/linkedin-icon';

export const TablePopover = ({
  children,
  gridRef,
  availableWidth,
  boundaryRef,
  data,
  currencyFormatter,
}: {
  children: ReactNode[];
  //TODO
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridRef: any;
  availableWidth: number;
  boundaryRef: React.RefObject<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  currencyFormatter: (value: number) => string;
}) => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  const offsetPopper = {
    name: 'offset',
    options: {
      offset: (state: {
        placement: string;
        popper: { x: number; y: number; width: number; height: number };
        reference: { x: number; y: number; width: number; height: number };
      }) => {
        const scrollLeft = gridRef?.getScrollLeft() || 0;
        const containerWidth =
          state.reference.width / 2 - availableWidth / 2 + 200 - scrollLeft;
        const flipper = state.placement.includes('top') ? 174 : 0;
        return [-containerWidth, flipper];
      },
    },
  };

  const arrowPadding = {
    name: 'arrow',
    options: {
      padding: { left: 196, right: 196 },
    },
  };

  const flipMod = {
    name: 'flip',
    options: { boundary: boundaryRef.current },
  };

  const formatDateStrings = (dates: string[]) => {
    const formattedDates = dates.map((d) => {
      if (isEmpty(d)) {
        return d;
      }

      const [year] = d.split('-');

      const formattedDate = year;

      return formattedDate;
    });

    return formattedDates;
  };

  // const fullHistory = data.history
  //   ? [
  //       {
  //         current_title: data.current_title,
  //         current_company: data.current_company,
  //         start_date: data?.start_date?.split(' ')[1],
  //         end_date: data?.end_date?.split(' ')[1] || '',
  //       },
  //       ...data.history,
  //     ]
  //   : [];

  // const checkFullHistory =
  //   `${fullHistory[0]?.current_company} - ${fullHistory[0]?.current_title}` ===
  //   `${fullHistory?.[1]?.current_company} - ${fullHistory?.[1]?.current_title}`;

  // const filteredFullHistory = checkFullHistory
  //   ? fullHistory.splice(0, 1)
  //   : fullHistory;

  const sortedHistory = data?.history?.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.start_date > b.start_date ? -1 : a.start_date < b.start_date ? 1 : 0
  );
  const [fader, setFader] = useState<number>(1);

  const handleScroll = (
    e: React.UIEventHandler<HTMLDivElement> | undefined
  ) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const scrollRemaining = scrollHeight - (scrollTop + clientHeight);
    const totalScrollable = scrollHeight - clientHeight;
    const opacity = scrollRemaining / totalScrollable;
    setFader(opacity.toFixed(2));
  };

  return (
    <Popover
      variant="tableTooltip"
      closeOnBlur
      trigger="hover"
      placement="auto"
      isLazy
      arrowSize={14}
      modifiers={[offsetPopper, arrowPadding, flipMod]}
      openDelay={500}
      closeDelay={0}
    >
      <PopoverTrigger>{children[0]}</PopoverTrigger>
      <Portal containerRef={boundaryRef}>
        <PopoverContent position="absolute">
          <PopoverArrow />
          <PopoverHeader borderTopRadius="4px">
            <Flex alignItems="center" gap={2}>
              <Avatar size="sm" borderRadius="4px" />
              <Flex direction="column" w="full">
                <Flex direction="row" alignItems="center">
                  <Text color="white" fontSize="sm" fontWeight="semibold">
                    {data.first_name}{' '}
                    <span style={isTrialUser ? { filter: 'blur(3px)' } : {}}>
                      {data.last_name}
                    </span>
                  </Text>
                  <Spacer />
                  {isTrialUser ? (
                    <Flex
                      alignItems="center"
                      gap={1}
                      backgroundColor="navyBlue.700"
                      padding="2px 2px 2px 4px"
                      borderRadius="3px"
                    >
                      <LockIcon size="xs" height="14px" />
                      <Flex
                        gap={1}
                        backgroundColor="navyBlue.600"
                        borderRadius="3px"
                      >
                        <IconButton
                          isRound={true}
                          variant="solid"
                          colorScheme="grey"
                          aria-label="email"
                          size="xs"
                          icon={<LinkedinIcon />}
                          isDisabled
                        />
                        <EmailPopover
                          trigger={
                            <IconButton
                              isRound={true}
                              variant="solid"
                              colorScheme="grey"
                              aria-label="email"
                              size="xs"
                              icon={<EmailIcon />}
                              isDisabled
                            />
                          }
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex gap={1}>
                      {data.linkedin_url && (
                        <IconButton
                          isRound={true}
                          variant="solid"
                          colorScheme="grey"
                          aria-label="email"
                          // fontSize="10px"
                          size="xs"
                          icon={<LinkedinIcon />}
                          onClick={() => {
                            const newWin = window.open(
                              `https://${data.linkedin_url}`,
                              '_blank',
                              'noopener,noreferrer'
                            );

                            newWin.opener = null;
                          }}
                        />
                      )}
                      {(data.email || data.personal_email) && (
                        <EmailPopover
                          emails={{
                            email: data.email,
                            personal: data.personal_email,
                          }}
                          trigger={
                            <IconButton
                              isRound={true}
                              variant="solid"
                              colorScheme="grey"
                              aria-label="email"
                              size="xs"
                              icon={<EmailIcon />}
                            />
                          }
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex direction="row" alignItems="center" mt="-1px">
                  <Text color="white" fontSize="xs" opacity={0.6}>
                    <Icon as={FiMapPin} boxSize={2.5} mr={1} />
                    {data.location || 'Not available at this time'}
                  </Text>
                  <Spacer />
                  <Text color="white" fontSize="xs" opacity={0.6}>
                    {`Est. Comp ${currencyFormatter(data.salary_total)}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </PopoverHeader>
          <PopoverBody pb={4}>
            <VStack
              divider={<StackDivider borderColor="white" opacity={0.2} />}
              spacing={4}
              align="stretch"
            >
              <Flex direction="column" w="full" gap={2}>
                <Text
                  fontSize="md"
                  color="white"
                  fontWeight="semibold"
                  paddingBottom={2}
                >
                  Experience
                </Text>
                <Box
                  w="full"
                  minH="70px"
                  maxH="100px"
                  overflowY="auto"
                  pr={1}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#ffffff40',
                      borderRadius: '10px',
                      // border: '4px solid #ffffff57',
                    },
                  }}
                  onScroll={handleScroll}
                  _after={{
                    content: "''",
                    position: 'absolute',
                    zIndex: 1,
                    bottom: 0,
                    left: 0,
                    pointerEvents: 'none',
                    backgroundImage: `linear-gradient(to bottom, rgba(45, 66, 106, 0), rgba(45, 66, 106, ${
                      sortedHistory?.length > 2 ? fader : 0
                    }) 60%)`,
                    width: '100%',
                    height: '50px',
                  }}
                >
                  <Flex direction="column" wrap="nowrap" gap={3}>
                    {sortedHistory?.map(
                      (
                        {
                          current_title,
                          current_company,
                          start_date,
                          end_date,
                        }: {
                          current_title: string;
                          current_company: string;
                          start_date: string;
                          end_date: string;
                        },
                        index
                      ) => {
                        const formattedDates = formatDateStrings([
                          start_date,
                          end_date,
                        ]);
                        return (
                          <Box
                            key={`filtered-history-${index}-${Math.random()}`}
                          >
                            <Text
                              color="white"
                              opacity={0.8}
                              fontWeight="semibold"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              maxW="300px"
                              title={current_title}
                            >
                              {current_title}
                            </Text>
                            <Flex w="full">
                              <Text
                                color="white"
                                opacity={0.7}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                maxW="300px"
                                title={current_company}
                              >
                                {`${current_company}`}
                              </Text>
                              <Spacer />
                              <Text color="white" opacity={0.7} flexShrink={0}>
                                {`${formattedDates[0]}-${
                                  formattedDates[1] || 'Present'
                                }`}
                              </Text>
                            </Flex>
                          </Box>
                        );
                      }
                    )}
                  </Flex>
                </Box>
              </Flex>
              {/* <Flex direction="column" w="full" gap={3}>
                <Text fontSize="md" color="white" fontWeight="semibold">
                  Education
                </Text>
                <Box w="full" maxH="68px" h="34px" overflowY="auto">
                  <Flex direction="column" wrap="nowrap" gap={1}>
                    {eduData.map(
                      ({ place, date }: { place: string; date: string }) => (
                        <Flex w="full">
                          <Text color="white" opacity={0.7}>
                            {place}
                          </Text>
                          <Spacer />
                          <Text color="white" opacity={0.7}>
                            {date}
                          </Text>
                        </Flex>
                      )
                    )}
                  </Flex>
                </Box>
              </Flex> */}
              {/* <Flex direction="column" w="full" gap={3}>
                <Text fontSize="md" color="white" fontWeight="semibold">
                  Skills
                </Text>
                <Box w="full" maxH="68px" h="34px" overflowY="auto">
                  <Text color="white" opacity={0.7}>
                    Python, Javascript, Rust, GO, and Bash
                  </Text>
                </Box>
              </Flex> */}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

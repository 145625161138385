import {
  Button,
  Flex,
  FormControl,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import { mapValues, pick, startCase } from 'lodash';
import { useRef, useState } from 'react';

import {
  BreadcrumbTreeSelection,
  FilterPopover,
  SelectionCategories,
  SelectionCategoryLabelOverrides,
  TreeApi,
  TreeItem,
} from '@revelio/filtering';
import { PlusMinusIcon } from '@revelio/layout';

import { FormInputMimicBox } from './FormInputMimicBox';
import {
  SharedConditionValueProps,
  SimpleFormTreeItem,
} from './conditions.model';

export function SelectionListValueSelect({
  selectionList,
  isMultiGranularitySelectionListVariable,
  conditionId,
  selectedValues,
  setSelections,
  clearError,
  onNoValues,
  isDisabled,
  hasError,
}: {
  selectionList: SelectionCategories[];
  isMultiGranularitySelectionListVariable: boolean;
} & SharedConditionValueProps<SimpleFormTreeItem>) {
  const [tempSelections, setTempSelections] = useState<
    Record<string, SimpleFormTreeItem>
  >({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const treeRef = useRef<TreeApi>(null);

  return (
    <FilterPopover
      openHandler={() => {
        setIsEditing(true);
      }}
      triggerOnClose={() => {
        setIsEditing(false);
      }}
      resetBtn={
        <Button
          variant="ghost"
          size="sm"
          fontSize="12px"
          colorScheme="red"
          flexShrink={0}
          onClick={() => {
            setTempSelections({});
            treeRef.current?.clearAll();
          }}
        >
          Clear Selections
        </Button>
      }
      handleSubmit={() => {
        if (Object.keys(tempSelections).length) {
          clearError();
        } else {
          onNoValues();
        }
        setSelections(
          Object.keys(tempSelections)
            .map((key) => tempSelections[key])
            .filter((x) => x) as SimpleFormTreeItem[]
        );
      }}
      menuWidth={360}
      lazy={true}
      isDisabled={isDisabled}
      triggerElement={
        <FormInputMimicBox
          isEditing={isEditing}
          isDisabled={isDisabled}
          hasError={hasError}
        >
          <Flex
            columnGap="3px"
            alignItems="center"
            height="100%"
            wrap="wrap"
            rowGap="0.5rem"
          >
            {selectedValues.map((conditionValue) => {
              return (
                <Tag
                  key={`${conditionId}-${conditionValue}`}
                  variant="filterChip"
                  data-testid={`condition-${conditionId}-${conditionValue}`}
                  size="sm"
                >
                  <TagLabel>
                    <Flex alignItems="center">
                      {conditionValue.item?.label}{' '}
                      {isMultiGranularitySelectionListVariable
                        ? `(${
                            SelectionCategoryLabelOverrides[
                              conditionValue.selectionListId
                            ]
                          })`
                        : ''}
                    </Flex>
                  </TagLabel>
                  <TagCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      const selectedValuesWithDeletion = selectedValues.filter(
                        (value) => value !== conditionValue
                      );
                      setSelections(selectedValuesWithDeletion);
                      if (!selectedValuesWithDeletion.length) {
                        onNoValues();
                      }
                    }}
                  />
                </Tag>
              );
            })}
            <Button
              variant={'addcompany'}
              leftIcon={<PlusMinusIcon />}
              display="flex"
              isDisabled={isDisabled}
            >
              <span style={{ marginLeft: '-2px' }}>Value</span>
            </Button>
          </Flex>
        </FormInputMimicBox>
      }
      closeOnBlur={false}
      closeOnOutsideClick={true}
    >
      <FormControl isInvalid={false}>
        <BreadcrumbTreeSelection
          ref={treeRef}
          selectionListIds={selectionList}
          initialSelections={selectedValues.reduce(
            (acc, value) => {
              acc[value.id] = {
                ...value,
                children: [],
              };
              return acc;
            },
            {} as Record<string, TreeItem<string>>
          )}
          branches={selectionList.map(
            (list) => SelectionCategoryLabelOverrides[list] ?? startCase(list)
          )}
          onChange={(selections) => {
            setTempSelections(
              mapValues(selections, (tItem) =>
                pick(tItem, ['id', 'item', 'selectionListId', 'parentId'])
              )
            );
          }}
        />
      </FormControl>
    </FilterPopover>
  );
}

export default SelectionListValueSelect;

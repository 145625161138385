/* eslint-disable no-var */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { max, min, scaleBand, scaleLinear, select, selectAll } from 'd3';
import { axisLeft } from 'd3-axis';
import { format as d3Format } from 'd3-format';

import { removeLoadingStatus } from '@revelio/core';

import '../d3-styles.scss';
import { colors } from '../utilities/colors.js';
import { roundedRect } from '../utilities/corner-radius.js';
import { endall } from '../utilities/endall-transition.js';

export const BarChartVerticalGenerator = (plotConfigs, downloadOptions) => {
  let { name, height, width } = plotConfigs;
  const {
    data,
    yAxisFormat,
    ttMainFormat,
    ttSecondaryFormat,
    colorIndex,
    mirror,
    targetRef,
    requestHash,
    customMargins,
  } = plotConfigs;

  const { getSVGNode, svgHeight, svgWidth, containerId } = downloadOptions;

  const dims = {};

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();
    // setup margins and inner dims; increase bottom margin to fit legend if showing >1 data groups
    mirror
      ? (dims.margin = { top: 30, left: 30, bottom: 0, right: 10 })
      : (dims.margin = { top: 30, left: 30, bottom: 0, right: 10 });
    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);
    // setup svg node
    const node = targetRef?.current;
    const svg = node
      ? select(node).append('svg')
      : select(containerId).append('svg');

    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    //=======================================================================================
    // PROPS REQUIRED: yAxisFormat, ttMainFormat, ttSecondaryFormat, chartPosition, mirror (boolean, if we want to mirror the chart), chartStyle

    // for mirror=true, sort data in descending order of percentage difference:
    if (mirror) {
      // data.forEach((row) => {
      //   if (row.value.length > 0) console.log(row.value[0].value);
      // });
      if (data[0].value.length > 0) {
        data.sort((a, b) => {
          const aDiff = a.value[0].value - a.value[1].value;
          const bDiff = b.value[0].value - b.value[1].value;
          return bDiff - aDiff;
        });
      }
    } else {
      data.sort((a, b) => b.value.value - a.value.value);
    }

    // for mirror = true, map value to difference in values
    const allValues = data
      .map(
        (d) => {
          if (mirror) {
            if (data[0].value.length > 0) {
              return d.value[0].value - d.value[1].value;
            }
          } else {
            return d.value.value;
          }

          return undefined;
        }
        //mirror ? d.value[0].value - d.value[1].value : d.value.value
      )
      .flat();
    const yScale = scaleLinear()
      .domain([min(allValues), max(allValues)])
      .range([dims.innerHeight, 0]);

    //   // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    const innerPad = 0.15; //this is a percentage of step
    //   const maxStep = 15; //space from start of 1 bar to start of the next
    //   var yAxisRange = [0, dims.innerHeight];
    //   // readjust the y axis range to make the chart height (sum of all steps - height of bars + innerpaddings) dependent on # bars and vertically centered
    //   var chartHeight = data.length * maxStep;
    //   if (chartHeight < dims.innerHeight) {
    //     yAxisRange = [
    //       dims.innerHeight / 2 - chartHeight / 2,
    //       dims.innerHeight / 2 + chartHeight / 2,
    //     ];
    //   }

    const xScale = scaleBand()
      .domain(data.map((d) => d.metadata.longName))
      .range([0, dims.innerWidth])
      .paddingInner(innerPad);

    const tooltipWidth = 240;

    const tooltip = select(`.react-node-${name}`)
      .append('div')
      .style('width', tooltipWidth + 'px')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      //   .attr(
      //     'class',
      //     chartPosition === 'right'
      //       ? 'tooltip-bar-chart-horizontal-right'
      //       : 'tooltip-bar-chart-horizontal'
      //   )
      .classed('tooltip-bar-chart-vertical', true)
      .classed(`tooltip-${name}`, true);

    // function to get # of lines that tooltip text occupies based on our tooltipWidth & font size
    const getLines = (txt) => {
      const words = txt.split(' ');
      let lines = 0;
      let lineLength = 0;
      words.forEach((word) => {
        lineLength += word.length;
        lineLength += 1;
        if (lineLength > 45) {
          lines += 1;
          lineLength = lineLength - 45;
        }
      });
      if (lineLength > 0) {
        lines += 1;
      }
      return lines;
    };

    const mouseOver = (event, d) => {
      // tt height = tt upper padding (12px) + # lines text occupies incl <br> (13px per line, each line fits ~41 characters) + tt bottom padding (12px) + arrow length (~7px)
      let tooltipHeight =
        12 + 13.75 * getLines(d.metadata.longName) + 13.75 * 2 + 13.75 + 12 + 7;

      if (mirror) {
        tooltipHeight += 13 * 4;
      }

      tooltip
        .style('opacity', 1)
        .style('display', null)
        .style(
          'transform',
          mirror
            ? `translate(${
                //parseFloat(paddingLeft) +
                dims.margin.left +
                xScale(d.metadata.longName) +
                xScale.bandwidth() / 2 -
                tooltipWidth / 2
              }px,${
                //parseFloat(paddingTop) +
                dims.margin.top +
                Math.min(
                  yScale(d.value[0].value - d.value[1].value),
                  yScale(0)
                ) -
                tooltipHeight
              }px)`
            : `translate(${
                //parseFloat(paddingLeft) +
                dims.margin.left +
                xScale(d.metadata.longName) +
                xScale.bandwidth() / 2 -
                tooltipWidth / 2
              }px,${
                //parseFloat(paddingTop) +
                dims.margin.top + yScale(d.value.value) - tooltipHeight
              }px)`
        )
        //   .style(
        //     'transform',
        //     chartPosition === 'right'
        //       ? `translate(${
        //           dims.margin.left + 8 - tooltipWidthApprox / 2 // plot padding-left = 8px
        //         }px, ${
        //           dims.margin.top +
        //           8 + // overview page plot padding = 8
        //           yScale(d.metadata[metaValue]) -
        //           tooltipHeight -
        //           7
        //         }px)`
        //       : `translate(${dims.margin.left + xScale(d.value) + 15}px, ${
        //           // overview page plot padding = 8px; arrow length = ~7px
        //           dims.margin.top +
        //           8 + // overview page plot padding = 8
        //           yScale(d.metadata[metaValue]) -
        //           tooltipHeight / 2 +
        //           yScale.bandwidth() / 2
        //         }px)`
        //   )
        .html(
          // eslint-disable-next-line no-nested-ternary
          mirror
            ? d.value[0].value - d.value[1].value >= 0
              ? `${d.metadata.longName} <br><br> <b>+${d3Format(ttMainFormat)(
                  d.value[0].value - d.value[1].value
                )}</b> in favor of ${
                  d.value[0].metadata.shortName
                } <br><br><b>${d.value[0].metadata.shortName}</b>: ${d3Format(
                  ttMainFormat
                )(d.value[0].value)}<br> <b>${
                  d.value[1].metadata.shortName
                }</b>: ${d3Format(ttMainFormat)(
                  d.value[1].value
                )}<br> (Sample size: ${d3Format(ttSecondaryFormat)(
                  d.value[0].metadata.total
                )})`
              : `${d.metadata.longName} <br><br> <b>+${d3Format(ttMainFormat)(
                  d.value[1].value - d.value[0].value
                )}</b> in favor of ${
                  d.value[1].metadata.shortName
                } <br><br><b>${d.value[0].metadata.shortName}</b>: ${d3Format(
                  ttMainFormat
                )(d.value[0].value)}<br> <b>${
                  d.value[1].metadata.shortName
                }</b>: ${d3Format(ttMainFormat)(
                  d.value[1].value
                )}<br> (Sample size: ${d3Format(ttSecondaryFormat)(
                  d.value[1].metadata.total
                )})`
            : `${d.metadata.longName} <br><br> ${d3Format(ttMainFormat)(
                d.value.value
              )} (Sample size: ${d3Format(ttSecondaryFormat)(
                d.value.metadata.total
              )})`
        );

      selectAll(`.${name}-bar`)
        .filter((row) => row.metadata.longName != d.metadata.longName)
        .style('opacity', 0.5);
    };

    const mouseOut = () => {
      tooltip.style('opacity', 0).style('display', 'none');
      selectAll(`.${name}-bar`).style('opacity', 1);
    };

    const yAxisGrid = axisLeft()
      .scale(yScale)
      .tickFormat('')
      .tickSize(-dims.innerWidth)
      .ticks(3);

    // y gridlines
    chart
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', `translate(0,0)`)
      .call(yAxisGrid)
      .call((g) => g.selectAll('.domain').remove());

    chart
      .selectAll('.bar')
      .data(data)
      .join('path')
      .attr('class', 'bar')
      .classed(`${name}-bar`, true)
      .attr('d', (d) => {
        if (mirror) {
          if (data[0].value.length > 0) {
            var yVal = Math.min(
              yScale(d.value[0].value - d.value[1].value),
              yScale(0)
            );
            var barHeight = Math.abs(
              yScale(d.value[0].value - d.value[1].value) - yScale(0)
            );
          }
        }
        return roundedRect(
          xScale(d.metadata.longName),
          mirror ? yVal : yScale(d.value.value),
          xScale.bandwidth(),
          mirror ? barHeight : dims.innerHeight - yScale(d.value.value),
          [1, 1, 0, 0] //round top corners of bar
        );
      })
      // .attr('x', (d) => xScale(d.metadata.longName))
      // .attr('y', (d) =>
      //   mirror
      //     ? yScale(d.value[0].value - d.value[1].value)
      //     : yScale(d.value.value)
      // )
      // .attr('width', xScale.bandwidth())
      // .attr('height', (d) => dims.innerHeight - yScale(d.value))
      .attr('fill', (d) => {
        if (mirror) {
          if (data[0].value.length > 0) {
            var filltest = d.value[0].value - d.value[1].value >= 0;
          }
        }
        // eslint-disable-next-line no-nested-ternary
        return mirror ? (filltest ? colors[0] : colors[1]) : colors[colorIndex];
      })
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);
    // }

    // axes
    const yAxisLeft = axisLeft()
      .scale(yScale)
      .ticks(3) //only a guideline, not guaranteed exactly 5 ticks will be displayed
      .tickFormat((d) => d3Format(yAxisFormat)(d));

    // add axes
    chart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .classed('axis-label-histogram', true)
      .call(yAxisLeft)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    // add legend, but only show for >1 data groups
    const legend = chart
      .append('foreignObject')
      .attr('width', dims.innerWidth)
      .attr('height', dims.margin.bottom - 15) //padding of 15px between top of legend and bottom bar
      .attr('class', 'legend')
      .attr('opacity', mirror ? 1 : 0) // only show legend for >1 data groups / when mirroring chart
      .attr(
        'transform',
        `translate(${width / 2 - width / 1.95}, ${dims.innerHeight + 25})`
      )
      .append('xhtml:div')
      .style('position', 'relative')
      .style('bottom', '0px')
      .selectAll('g')
      .data(data[0].value)
      .join('g');

    legend
      .append('div')
      .append('span')
      .style('border-radius', '20%')
      .style('width', '10px')
      .style('height', '10px')
      .style('position', 'absolute')
      .style('background-color', (d, i) => colors[i]);

    legend
      .append('div')
      .append('text')
      .style('margin', '10px')
      .style('position', 'relative')
      .style('top', '-9px')
      .style('left', '4px')
      .attr('class', 'legend-text')
      .style('font-size', '10px')
      .text(function (d) {
        return d.metadata.longName;
      });

    // position legend groups next to one another
    legend.style('float', 'left').style('margin-bottom', '-5px'); //margin-bottom -5px will move bottom line closer to top line

    // TODO: Pull these from stylesheet
    selectAll('.axis-grid line')
      .attr('stroke', '#e6e8ed')
      .attr('stroke-dasharray', '1,3')
      .attr('stroke-width', '1.3')
      .attr('stroke-linecap', 'round');
    chart
      .transition()
      .duration(0)
      .call(endall, () => {
        removeLoadingStatus([requestHash, 'tabChange']);
      });

    if (getSVGNode) {
      return svg.node();
    }
  }
};

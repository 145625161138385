import { PostingsDataQuery } from '@revelio/data-access';
import { VolumeChartData } from '@revelio/replots';

type GetPostingsOvertimeDataProps = {
  entities: PostingsDataQuery['posting'];
};

export const PostingsMetrics = [
  { label: 'Active postings', key: 'active' },
  { label: 'New postings', key: 'new' },
  { label: 'Removed postings', key: 'removed' },
  { label: 'Expected hires', key: 'expected_hires' },
] as const;

export const getMainPostingsData = ({
  entities,
}: GetPostingsOvertimeDataProps): VolumeChartData[] => {
  if (!entities?.length) return [];

  return (
    entities?.flatMap((entity) => {
      const timeseriesData =
        entity?.category?.filter((point) => point !== null) || [];

      return PostingsMetrics.map(
        (metric): VolumeChartData => ({
          id: metric.key,
          label: metric.label,
          values: timeseriesData.map((point) => ({
            date: point?.metadata?.shortName || '',
            value: point?.metrics?.[metric.key] || 0,
          })),
        })
      );
    }) || []
  );
};

import {
  LocalSelectionCategories,
  SelectableCategories,
  SelectionCategories,
} from '../../../engine/filters.model';

export const TalentDiscoveryChipLabelOverrides: {
  [key in SelectableCategories]?: string;
} = {
  [SelectionCategories.GENDER]: 'gender',
  [SelectionCategories.EDUCATION]: 'education level',
  [SelectionCategories.DIVERSITY_INCLUSION]: 'diversity & inclusion',
  [SelectionCategories.COMPANY]: 'company',
  [SelectionCategories.MSA]: 'metro area',
  [SelectionCategories.JOB_CATEGORY]: 'job category',
  [SelectionCategories.HIGHEST_DEGREE]: 'education level',
  [SelectionCategories.RSID]: 'institution',
  [LocalSelectionCategories.PRESTIGE]: 'prestige level',
  [LocalSelectionCategories.REMOTE_SUITABILITY]: 'suitability level',
  [LocalSelectionCategories.FLIGHT_RISK]: 'risk level',
};

export const HeadingOverrides: { [key in SelectableCategories]?: string } = {
  [SelectionCategories.DIVERSITY_INCLUSION]: 'diversity & inclusion',
  [SelectionCategories.COMPANY]: 'company',
  [SelectionCategories.ROLES]: 'role',
  [SelectionCategories.SENIORITY]: 'seniority',
  [SelectionCategories.DATE_RANGE]: 'date',
  [SelectionCategories.HIGHEST_DEGREE]: 'education',
  [SelectionCategories.RSID]: 'school',
  [LocalSelectionCategories.FLIGHT_RISK]: 'flight risk',
  [LocalSelectionCategories.REMOTE_SUITABILITY]: 'remote suitability',
  [SelectionCategories.BASE_SALARY]: 'base salary',
  [SelectionCategories.TOTAL_COMPENSATION]: 'pay',
};

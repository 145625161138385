import { IFrameMessageTypes } from './constants';

export type MessageData = string | number | object;
export type OnMessage = (args: {
  data: MessageData;
  type: IFrameMessageTypes;
}) => void;

/**
 * Sends a message to a child iframe using the postMessage API
 * @param iframe - The iframe element or iframe window to send the message to
 * @param domain - The domain of the iframe to send the message to, defaults to '*'
 * @param data - The data to be sent in the message
 * @param type - The type of message being sent
 * @param onError - Optional callback function to handle errors
 */
export const sendMessageToIFrame = ({
  iframe,
  domain,
  data,
  type,
  onError,
}: {
  iframe: HTMLIFrameElement | Window;
  domain?: string;
  data: MessageData;
  type: IFrameMessageTypes;
  onError?: (error: Error) => void;
}) => {
  try {
    const targetWindow =
      iframe instanceof HTMLIFrameElement ? iframe.contentWindow : iframe;
    if (!targetWindow) {
      throw new Error('Invalid iframe target');
    }

    targetWindow.postMessage(
      {
        type: type,
        data,
      },
      domain || '*'
    );
  } catch (error) {
    onError?.(error as Error);
    if (!onError) {
      console.warn('sendMessageToIFrameChild:', error);
    }
  }
};

/**
 * Sends a message to the parent window using the postMessage API
 * @param data - The data to be sent in the message
 * @param type - The type of message being sent
 * @param onError - Optional callback function to handle errors
 */
export const sendMessageToIFrameParent = ({
  data,
  type,
  onError,
}: {
  data: MessageData;
  type: IFrameMessageTypes;
  onError?: (error: Error) => void;
}) => {
  try {
    window.parent.postMessage(
      {
        type: type,
        data,
      },
      '*'
    );
  } catch (error) {
    onError?.(error as Error);
    if (!onError) {
      console.warn('sendMessageToIFrameParent:', error);
    }
  }
};

/**
 * Sets up a listener for specific message types from other windows/domains
 * @param onMessage - Callback function to handle received messages
 * @param types - Array of message types to listen for
 * @returns Cleanup function to remove the event listener
 */
export const listenForMessages = ({
  onMessage,
  types,
}: {
  onMessage: OnMessage;
  types: IFrameMessageTypes[];
}) => {
  const onMessageHandler = (event: MessageEvent) => {
    if (types.includes(event.data.type)) {
      onMessage({ data: event.data.data, type: event.data.type });
    }
  };
  window.addEventListener('message', onMessageHandler);
  return () => {
    window.removeEventListener('message', onMessageHandler);
  };
};

// eslint-disable-next-line no-restricted-imports
import { useFlag } from '@unleash/proxy-client-react';
import { useContext } from 'react';

import { FeatureFlag } from '@revelio/core';

import { UnleashFallbackContext } from './unleash-fallback.context';

export const useUnleashFlag = (flag: FeatureFlag) => {
  const unleashClientFlagValue = useFlag(flag);
  const { flags, usingFallback } = useContext(UnleashFallbackContext);

  if (usingFallback) {
    return flags[flag] ?? false;
  }
  return unleashClientFlagValue;
};

import { Box } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetLoggedInUser } from '@revelio/auth';
import { METRIC_MODE_IDS } from '@revelio/data-access';

import { getProviderDefault } from '../../../engine/filters.deepLinks';
import {
  upsertFilter,
  useSingleOrMoreFilterState,
} from '../../../engine/filters.engine';
import {
  FilterItem,
  LocalSelectionCategories,
  SelectFilter,
  ValueItem,
} from '../../../engine/filters.model';
import { SelectionListControls } from '../../SelectionListControls/SelectionListControls';
import { SelectionListValue } from '../../selection-list';
import { SelectionListSelect } from '../../selection-list/selection-list-select';
import { TempSelections } from '../types';
import {
  UNIFIED_POSTING_SOURCE_OPTION,
  getPostingsSourceOptions,
} from './posting-source-options';

const getOptionFromFilterValue = (value: FilterItem) => {
  return {
    value: `${value.id}`,
    label: value.label as string,
  };
};
export const ProviderSelectionList = ({
  close,
  handleFilterSubmit,
}: {
  close: () => void;
  handleFilterSubmit: (selections?: TempSelections) => void;
}) => {
  const [metricModeFilter] = useSingleOrMoreFilterState(
    LocalSelectionCategories.METRIC_MODE
  );

  const submitProviderSelection = (value: SelectionListValue) => {
    const selections: TempSelections = value.reduce<TempSelections>(
      (acc, item) => {
        const id = `${LocalSelectionCategories.PROVIDER}.${item.value}`;
        return {
          ...acc,
          [id]: {
            item: {
              id: Number(item.value),
              shortName: item.label,
              longName: item.label,
              label: item.label,
              isActive: true,
            },
            id,
            isMulti: true,
            children: [],
            selectionListId: LocalSelectionCategories.PROVIDER,
          },
        };
      },
      {}
    );

    handleFilterSubmit(selections);

    const hasUnified = value.some(
      (option) => option.value === UNIFIED_POSTING_SOURCE_OPTION.value
    );
    if (
      !hasUnified &&
      (metricModeFilter?.value as ValueItem).id !== METRIC_MODE_IDS.unweighted
    ) {
      upsertFilter(LocalSelectionCategories.METRIC_MODE, {
        value: {
          id: METRIC_MODE_IDS.unweighted,
          shortName: 'Unweighted',
          longName: 'Unweighted',
          label: 'Unweighted',
          isActive: true,
        },
        isUserSubmitted: true,
      });
    }

    // filter chips don't show up for defaults on page load
    upsertFilter(LocalSelectionCategories.PROVIDER, {
      isUserSubmitted: true,
    });

    close();
  };

  const [data] = useSingleOrMoreFilterState<any, SelectFilter<FilterItem[]>>(
    LocalSelectionCategories.PROVIDER
  );

  const { loggedInUser } = useGetLoggedInUser();

  const defaultValue = data?.value.map(getOptionFromFilterValue);
  const [value, setValue] = useState<SelectionListValue>(defaultValue);
  const toast = useToast();
  const onChange = useCallback(
    (selectedOptions: SelectionListValue) => {
      if (selectedOptions.length === 0) {
        toast({
          id: 'provider-selection-list-min-required-toast',
          position: 'top-right',
          status: 'warning',
          title: `1 Selection Required`,
          description: `At least 1 Posting Source is required.`,
          isClosable: true,
          variant: 'subtle',
        });

        return;
      }
      const unifiedPostingsSourceOptionIndex = selectedOptions.findIndex(
        (option) => option.value === UNIFIED_POSTING_SOURCE_OPTION.value
      );
      if (unifiedPostingsSourceOptionIndex === -1) {
        // normal just setting value when anything but unified selected
        setValue(selectedOptions);
      }

      const selectedUnifiedLast =
        unifiedPostingsSourceOptionIndex === selectedOptions.length - 1;
      if (selectedUnifiedLast) {
        // unified can't be selected with other posting sources
        setValue([UNIFIED_POSTING_SOURCE_OPTION]);
      } else {
        // if has unified selected and selects other source, it should remove unified
        setValue(
          selectedOptions.filter(
            (option) => option.value !== UNIFIED_POSTING_SOURCE_OPTION.value
          )
        );
      }
    },
    [setValue, toast]
  );
  const clear = useCallback(() => {
    const postingsSourceDefaultFilter = getProviderDefault(
      !!loggedInUser?.linkup_postings
    ).provider.value[0];
    setValue([getOptionFromFilterValue(postingsSourceDefaultFilter)]);
  }, [setValue, loggedInUser?.linkup_postings]);

  const prevDefaultValue = useRef(defaultValue);
  useEffect(() => {
    if (!isEqual(prevDefaultValue.current, defaultValue)) {
      prevDefaultValue.current = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <Box>
      {/* Not using <SelectionList /> because it doesn't support clear button and adding override seemed overkill for 1 usecase */}
      <SelectionListSelect
        options={getPostingsSourceOptions(loggedInUser)}
        value={value}
        onChange={onChange}
        isMulti
      />

      <SelectionListControls
        onClear={clear}
        onSubmit={() => {
          submitProviderSelection(value);
          close();
        }}
        onClose={close}
        submitText={data?.isUserSubmitted ? 'Update' : 'Add'}
      />
    </Box>
  );
};

import { Box, Button, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

import * as serviceWorker from './registerServiceWorker';

export const ServiceWorker = () => {
  const toast = useToast();

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).Cypress) {
      return;
    }

    serviceWorker.register({
      onUpdate: () => {
        toast({
          title: 'New app version available',
          description: (
            <Box>
              click
              <Button variant="link" onClick={handleReload}>
                here
              </Button>
              to update, or refresh the page
            </Box>
          ),
          status: 'info',
          duration: null,
          variant: 'subtle',
          position: 'top-right',
          isClosable: true,
        });
      },
    });
    serviceWorker.disableUserSelect();
  }, [toast]);

  return null;
};

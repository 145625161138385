import { Center, Flex } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { CombinedError } from 'urql';

import {
  DELIVERABLES_NAME_NOT_UNIQUE,
  ENTITY_NOT_FOUND,
} from '@revelio/data-access';

export const DEFAULT_ERROR_MSG = `We've run into an error and our team has been alerted. Please try again later.`;

const errorMessageDisplayTextLookup = {
  [ENTITY_NOT_FOUND]: `Currently the data for the selected Entity(ies) does not exist in our Dashboard. Feel free to reach out to your Revelio Labs contact for more information.`,
  [DELIVERABLES_NAME_NOT_UNIQUE]:
    'This deliverable name already exists. Please enter a unique Name',
};

export const getErrorMessageToDisplay = (errorMessage: string) => {
  // Entity Not Found
  if (errorMessage.includes(ENTITY_NOT_FOUND)) {
    return errorMessageDisplayTextLookup[ENTITY_NOT_FOUND];
  }
  // Deliverable Name Not Unique
  else if (errorMessage.includes(DELIVERABLES_NAME_NOT_UNIQUE)) {
    return errorMessageDisplayTextLookup[DELIVERABLES_NAME_NOT_UNIQUE];
  }
  // Default Error Message
  else return DEFAULT_ERROR_MSG;
};

export const UrqlErrorMessage = ({
  error,
  reqId,
}: {
  error: CombinedError;
  reqId: string;
}) => {
  const message = getErrorMessageToDisplay(error.message);

  return (
    <>
      <Flex pb="12px">{message}</Flex>
      {reqId ? (
        <Tooltip hasArrow label="Copy to clipboard" placement="top">
          <Center
            cursor="pointer"
            fontSize="sm"
            opacity="50%"
            onClick={() => copy(`req-id: ${reqId}`)}
          >
            {reqId}
          </Center>
        </Tooltip>
      ) : (
        <span></span>
      )}
    </>
  );
};

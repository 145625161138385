import LZString from 'lz-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTalentDiscoveryFilter } from './filters/td-filter-provider';
import {
  TalentDiscoveryFilterAction,
  TalentDiscoveryFilterState,
} from './filters/td-filter-reducer';

/**
 * Loads state from URL or sessionStorage.
 */
export const loadStateFromUrl = <T>(
  paramName: string,
  urlParams: URLSearchParams,
  setState: (state: T) => void,
  validateState: (state: unknown) => state is T,
  emptyState: T
): void => {
  const compressedFromUrl = urlParams.get(paramName);
  if (!compressedFromUrl) {
    setState(emptyState);
    return;
  }

  try {
    const serialized =
      LZString.decompressFromEncodedURIComponent(compressedFromUrl);

    const state: unknown = JSON.parse(serialized);

    if (validateState(state)) {
      setState(state);
      return;
    } else {
      urlParams.delete(paramName); // Remove invalid param from URL
    }
  } catch (error) {
    urlParams.delete(paramName); // Remove invalid param from URL
  }
};

/**
 * Saves state to URL and sessionStorage.
 */
export const saveStateToUrlAndStorage = <T>(
  paramName: string,
  urlParams: URLSearchParams,
  state: T | undefined,
  isEmpty: (state: T) => boolean = () => false,
  validateState: (state: unknown) => state is T
): void => {
  if (state && !isEmpty(state) && validateState(state)) {
    const serialized = JSON.stringify(state);

    const compressedForUrl = LZString.compressToEncodedURIComponent(serialized);

    urlParams.set(paramName, compressedForUrl);

    const compressedForStorage = LZString.compressToUTF16(serialized);

    sessionStorage.setItem(paramName, compressedForStorage);
  } else {
    urlParams.delete(paramName);
    sessionStorage.removeItem(paramName);
  }
};

const isValidTalentDiscoveryFilters = (
  filters: unknown
): filters is TalentDiscoveryFilterState['filters'] => {
  return Array.isArray(filters);
};

const isValidColumns = (columns: unknown): columns is string[] => {
  if (columns === undefined) {
    return true; // Allow undefined to denote default columns
  }

  if (!Array.isArray(columns)) {
    return false;
  }

  return columns.every((col) => typeof col === 'string');
};

export const loadAllTalentDiscoveryStateFromUrl = (
  dispatch: React.Dispatch<TalentDiscoveryFilterAction>,
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>,
  params: URLSearchParams
) => {
  // Handle filters
  loadStateFromUrl<TalentDiscoveryFilterState['filters']>(
    'filters',
    params,
    (filters) => {
      dispatch({ type: 'OVERWRITE_FILTERS', filters });
    },
    isValidTalentDiscoveryFilters,
    []
  );

  // Handle selected columns
  loadStateFromUrl<string[]>(
    'columns',
    params,
    setSavedCheckedColumns,
    isValidColumns,
    []
  );
};

const loadStateFromSessionStorage = <T>(
  paramName: string,
  setState: (state: T) => void,
  validateState: (state: unknown) => state is T
) => {
  const compressedFromStorage = sessionStorage.getItem(paramName);
  if (compressedFromStorage) {
    try {
      const serialized = LZString.decompressFromUTF16(compressedFromStorage);

      if (serialized) {
        const state: unknown = JSON.parse(serialized);

        if (validateState(state)) {
          setState(state);
        } else {
          sessionStorage.removeItem(paramName); // Remove invalid data from sessionStorage
        }
      }
    } catch (error) {
      sessionStorage.removeItem(paramName); // Remove invalid data from sessionStorage
    }
  }
};

export const useSyncTalentDiscoveryFiltersWithUrl = (
  savedCheckedColumns: string[],
  setSavedCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useTalentDiscoveryFilter();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.toString()) {
      loadAllTalentDiscoveryStateFromUrl(
        dispatch,
        setSavedCheckedColumns,
        params
      );
    } else {
      //sets filters from session storage when navigating from another page
      loadStateFromSessionStorage<TalentDiscoveryFilterState['filters']>(
        'filters',
        (filters) => {
          dispatch({ type: 'OVERWRITE_FILTERS', filters });
        },
        isValidTalentDiscoveryFilters
      );

      // Handle selected columns
      loadStateFromSessionStorage<string[]>(
        'columns',
        setSavedCheckedColumns,
        isValidColumns
      );
    }

    // Check if any params were removed
    if (params.toString() !== location.search.substring(1)) {
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSavedCheckedColumns]);

  // On state change, update the URL and local storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Handle filters
    saveStateToUrlAndStorage(
      'filters',
      params,
      state.filters,
      (filters: TalentDiscoveryFilterState['filters']) =>
        !filters || filters.length === 0,
      isValidTalentDiscoveryFilters
    );

    // Handle selected columns
    saveStateToUrlAndStorage(
      'columns',
      params,
      savedCheckedColumns,
      (cols) => !cols || cols.length === 0,
      isValidColumns
    );

    // Update the URL if any changes
    const newSearch = params.toString();
    if (newSearch !== location.search.substring(1)) {
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters, savedCheckedColumns, navigate, location.pathname]);
};

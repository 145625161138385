import {
  Button,
  ButtonGroup,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react';
import { useContext } from 'react';

import { BaseStepsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import { SnowflakeMapperContext } from './snowflake-mapper-context';

interface SnowflakeMapperModalProps {
  children: React.ReactNode;
  onContinue?: () => void;
  isLoadingContinue?: boolean;
  onPrevious?: () => void;
}
const SnowflakeMapperModal = ({
  children,
  onContinue,
  isLoadingContinue,
  onPrevious,
}: SnowflakeMapperModalProps) => {
  const { activeStep, steps, goToPrevious } = useContext(
    SnowflakeMapperContext
  );
  return (
    <ModalContent>
      <ModalHeader color="text.primary">Upload companies to Map</ModalHeader>
      <ModalCloseButton />

      <Divider orientation="horizontal" />

      <ModalBody mt="14px" minHeight="360px">
        {activeStep > 0 ? (
          <Stepper
            mb={6}
            colorScheme="green"
            index={activeStep}
            orientation="horizontal"
            gap="0"
            size="md"
          >
            {steps.map((step: BaseStepsInterface, index: number) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        ) : null}
        <Box overflow="auto" maxH="600px">
          {children}
        </Box>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={onPrevious || goToPrevious}
          >
            Back
          </Button>

          {onContinue ? (
            <Button
              borderRadius="4px"
              fontSize="12px"
              minWidth="100px"
              fontWeight="600"
              colorScheme="green"
              variant="solid"
              size="sm"
              onClick={onContinue}
              isLoading={isLoadingContinue}
            >
              Continue
            </Button>
          ) : null}
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};

export default SnowflakeMapperModal;

import { find, get, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useGetLoggedInUser } from '@revelio/auth';
import { PagePaths, getIsTour, getStepIndex, tourStore } from '@revelio/core';
import { KeyValueResp } from '@revelio/data-access';

export const useEnforceUserIntroduction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser();

  useEffect(() => {
    // The following handles redirecting the user to landing to
    // start the user tour/view the tos, if user has not already completed it.

    if (fetching || isEmpty(loggedInUser)) {
      return;
    }

    const isTourRunning = tourStore.query(getIsTour);
    const tourStepIndex = tourStore.query(getStepIndex);

    // not setting the default value here because get dosen't return
    // default value if metadata is null, which can lead to bugs.
    const metadata = get(loggedInUser, 'metadata') || [];
    const tourCompleted = find(metadata, {
      key: 'tour_completed',
    }) as KeyValueResp;
    const termsAccepted = find(metadata, {
      key: 'terms_accepted',
    }) as KeyValueResp;
    const isTourCompleted = tourCompleted?.value === 'true';
    const isTermsAccepted = termsAccepted?.value === 'true';

    if (!isTourCompleted || !isTermsAccepted) {
      // only redirect user if the tour or ToS just launched
      if (
        isTourRunning &&
        tourStepIndex <= 1 &&
        location.pathname != PagePaths.LANDING
      ) {
        navigate(PagePaths.LANDING);
      }
    }
  }, [fetching, loggedInUser, location, navigate]);

  return { loggedInUser, fetching };
};

import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/layout';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { getEntity, selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { difference, get } from 'lodash';
import { useState } from 'react';
import { map } from 'rxjs';

import { PipelineType } from '@revelio/data-access';
import { CardListSelectController } from '@revelio/layout';

import {
  DatasetFilter,
  Deliverable,
  isCompanyInfoPipeline,
} from '../../deliverables.model';
import {
  deleteDatasetFilterByName,
  deliverablesStore,
} from '../../deliverables.repository';
import {
  DateRangeFilterType,
  getPipelineDateRangeFilterType,
  labelForPipelineDateRangeFilter,
} from './dataset-date-range/dataset-date-range-helpers';
import { useUpdateDatasetDateRangeFilters } from './dataset-date-range/dataset-date-range-hooks';
import { DatasetDateRangeModal } from './dataset-date-range/dataset-date-range-modal';
import { FilterDefinitionModal } from './filter-definition-modal';
import {
  ADD_FILTER_ID,
  DATE_RANGE_ID_CHECKED,
  DATE_RANGE_ID_UNCHECKED,
} from './filter.model';

export const DatasetFiltersList = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [datasetFilters] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.filters', []) as DatasetFilter[]
      )
    ),
    { deps: [entityId] }
  );
  const updateDateRangeFilter = useUpdateDatasetDateRangeFilters();
  const pipeline = deliverablesStore.query(getEntity(entityId))?.pipeline;
  const pipelineDateFilter = pipeline?.date_filter;
  const hasPipelineDateFilter = !!pipeline?.date_filter;
  const pipelineType = pipeline?.pipeline_type;
  const [isAddDatasetFilterOpen, setIsAddDatasetFilterOpen] = useState<
    boolean | string
  >(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean | string>(
    false
  );
  const dateFilterEnabled =
    getPipelineDateRangeFilterType(pipelineType as PipelineType) !==
    DateRangeFilterType.None;

  const filterColumns = [
    ...(dateFilterEnabled
      ? [
          {
            id: hasPipelineDateFilter
              ? DATE_RANGE_ID_CHECKED
              : DATE_RANGE_ID_UNCHECKED,
            label: hasPipelineDateFilter
              ? labelForPipelineDateRangeFilter(pipelineDateFilter)
              : '+ Date Range',
            ...(!hasPipelineDateFilter && {
              labelHeadingOverrideProps: { color: 'blue.300' },
            }),
            isChecked: hasPipelineDateFilter,
            hideCtaIfNotSelected: true,
            cta: (
              <IconButton
                bg="none"
                aria-label="delete data range filter"
                icon={<CloseIcon />}
                size="xs"
                variant="link"
                color="red"
                onClick={() => {
                  updateDateRangeFilter(entityId, undefined);
                }}
              />
            ),
          },
        ]
      : []),
    ...datasetFilters.map((dFilter) => ({
      id: dFilter.name,
      label: dFilter.name,
      isChecked: true,
      cta: (
        <IconButton
          bg="none"
          aria-label="delete dataset filter"
          icon={<CloseIcon />}
          size="xs"
          variant="link"
          color="red"
          onClick={() =>
            deleteDatasetFilterByName({
              entityId,
              name: dFilter.name,
            })
          }
        />
      ),
      labelHeadingOverrideProps: {},
    })),
    ...(!isCompanyInfoPipeline(pipelineType as PipelineType)
      ? [
          {
            id: ADD_FILTER_ID,
            label: '+ Add Custom',
            isChecked: false,
            // This is here just to get rid of a typescript error
            // eslint-disable-next-line react/jsx-no-useless-fragment
            cta: <></>,
            labelHeadingOverrideProps: {
              color: 'blue.300',
            },
          },
        ]
      : []),
  ];
  const checkedOptions = filterColumns
    .filter((c) => c.isChecked)
    .map((c) => c.id);

  if (!filterColumns.length) {
    return null;
  }

  return (
    <>
      <FilterDefinitionModal
        isOpen={isAddDatasetFilterOpen}
        setIsOpen={setIsAddDatasetFilterOpen}
        existingFilters={datasetFilters}
        entityId={entityId}
      />

      <DatasetDateRangeModal
        isOpen={isDateRangeOpen}
        setIsOpen={setIsDateRangeOpen}
        entityId={entityId}
        pipelineType={pipelineType as PipelineType}
      />

      <Heading as="h3" fontWeight="600" size="sm" color="text.primary" pb="3">
        <Flex alignItems="center" gap={1}>
          Filters
          <Tooltip
            label='If more than one filter is defined, the output will include union of filter conditions.("OR" is used.) If you want the output to satisfy all the filter conditions, you can do so in a single filter using "AND".'
            textAlign="center"
            hasArrow
            placement="top"
          >
            <InfoOutlineIcon
              boxSize={3}
              color="text.primary"
              cursor="pointer"
            />
          </Tooltip>
        </Flex>
      </Heading>

      <CardListSelectController<string>
        data-testid="dataset-filters-list"
        variant="columns"
        separator={({ id, isPreSeparator }, index) => {
          if (id === DATE_RANGE_ID_UNCHECKED) return null;
          const text = id === DATE_RANGE_ID_CHECKED ? 'AND' : 'OR';
          return (
            <Stack
              position="relative"
              direction="row"
              ml={isPreSeparator ? 2 : 0}
            >
              <Divider orientation="vertical" mx={1} />
              <AbsoluteCenter bg="white">
                <Text>{text}</Text>
              </AbsoluteCenter>
            </Stack>
          );
        }}
        items={[
          {
            heading: null,
            columns: filterColumns,
          },
        ]}
        value={checkedOptions}
        hideCtaIfNotSelected={true}
        gridProps={{
          spacing: 4,
          columns: 3,
          gridAutoFlow: 'row',
        }}
        onChange={(value: (string | typeof ADD_FILTER_ID)[]) => {
          const newValuesRemoved = difference(checkedOptions, value);
          // Clicked Add Filter Option
          if (value.includes(ADD_FILTER_ID)) {
            setIsAddDatasetFilterOpen(true);
            return; // we don't want it to be added to the pipeline columns
          }
          // Clicked date range option
          else if (
            value.includes(DATE_RANGE_ID_UNCHECKED) ||
            newValuesRemoved.includes(DATE_RANGE_ID_CHECKED)
          ) {
            setIsDateRangeOpen(true);
            return; // we don't want it to be added to the pipeline columns
          }

          // removing the value
          if (newValuesRemoved.length) {
            const [lastUnSelectedValue] = newValuesRemoved;
            setIsAddDatasetFilterOpen(lastUnSelectedValue);
            return;
          }
        }}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { HStack, Heading, Text, VStack } from '@chakra-ui/react';

const Stats = ({
  data: {
    stat: { growth, title, value },
  },
}) => {
  const isNegative = (stringValue) => {
    if (stringValue) {
      if (stringValue.includes('-')) {
        return true;
      }
    }
    return false;
  };
  return (
    <HStack justifyContent="space-between" alignItems="center" height="100%">
      <Heading as="h1" size="lg" fontWeight="600" color="text.primary">
        {value || '--'}
      </Heading>
      <VStack spacing={0} alignItems="end">
        <Text
          fontSize="13px"
          color={isNegative(growth) ? 'red.500' : 'green.500'}
        >
          {growth || '--'}
        </Text>
        <Text fontSize="10px" color="text.primary">
          last 30 days
        </Text>
      </VStack>
    </HStack>
  );
};

export default Stats;

import { TransitionDataQuery } from '@revelio/data-access';
import { Link, Node, SankeyData } from '@revelio/replots';

const emptySankeyData: SankeyData = {
  nodes: [],
  links: [],
};

export const transformSankey = (
  data: NonNullable<TransitionDataQuery['transitions2D']>[0],
  type: 'inflow' | 'outflow'
): SankeyData => {
  if (!data) return emptySankeyData;

  const isInflow = type === 'inflow';

  const { metadata, category } = data;
  if (!metadata || !category) return emptySankeyData;

  const { shortName } = metadata;
  if (!shortName) return emptySankeyData;

  const primaryNode: Node = { id: shortName };

  const transitionNodes: Node[] = category
    .map((c): Node | null => {
      if (!c || !c.metadata || !c.metadata.shortName || !c.metrics) return null;
      return {
        id: c.metadata.shortName,
      };
    })
    .filter((n): n is Node => n !== null);

  const transitionLinks: Link[] = category
    .map((c): Link | null => {
      if (
        !c ||
        !c.metadata ||
        !c.metadata.shortName ||
        !c.metrics ||
        !c.metrics.count ||
        !c.metrics.value
      ) {
        return null;
      }
      return {
        source: isInflow ? c.metadata.shortName : primaryNode.id,
        target: isInflow ? primaryNode.id : c.metadata.shortName,
        value: c.metrics.count,
        share: c.metrics.value / 100,
      };
    })
    .filter((l): l is Link => l !== null);

  return {
    nodes: [primaryNode, ...transitionNodes],
    links: transitionLinks,
  };
};

import { DASH_META_ROOT, getAuthDashMetaCsrfToken } from '@revelio/auth';
import {
  LocationMappingResponse,
  RoleMappingRequest,
  RoleMappingResponse,
} from '@revelio/data-access';
import { LocationMappingRequest } from '@revelio/data-access';

export const locationMapperApi = async ({
  locationsToMap,
}: {
  locationsToMap: string[];
}): Promise<LocationMappingResponse> => {
  const locationMappingRequestBody: LocationMappingRequest =
    locationsToMap.reduce((request, location, index) => {
      return {
        ...request,
        [index]: {
          location,
        },
      };
    }, {} as LocationMappingRequest);
  const csrfToken = getAuthDashMetaCsrfToken();
  const response = await fetch(
    `${DASH_META_ROOT}/api/ai-search/mapper/location`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-surf-token-dash-meta': csrfToken || '',
      },
      body: JSON.stringify(locationMappingRequestBody),
      credentials: 'include',
    }
  );
  const data = await response.json();
  return data;
};

export const roleMapperApi = async ({
  rolesToMap,
}: {
  rolesToMap: string[];
}): Promise<RoleMappingResponse> => {
  const roleMappingRequestBody: RoleMappingRequest = rolesToMap.reduce(
    (request, role, index) => {
      return {
        ...request,
        [index]: {
          title: role,
        },
      };
    },
    {} as RoleMappingRequest
  );
  const csrfToken = getAuthDashMetaCsrfToken();
  const response = await fetch(`${DASH_META_ROOT}/api/ai-search/mapper/role`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-surf-token-dash-meta': csrfToken || '',
    },
    body: JSON.stringify(roleMappingRequestBody),
    credentials: 'include',
  });
  const data = await response.json();
  return data;
};

import { Box, Card, Center, Divider, Flex } from '@chakra-ui/react';
import { flatten } from 'lodash';
import { useQuery } from 'urql';

import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
} from '@revelio/core';
import {
  Dimension1,
  Filters,
  METRIC_MODE_IDS,
  POSTING_SOURCE_IDS,
  View,
} from '@revelio/data-access';
import {
  COMPOSITION_GET_DATA,
  Filter,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterMenu,
  FilterMenuLimits,
  FilterSetSaveMenu,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  GEO_SUMMARY_POSTING_GET_DATA,
  LocalSelectionCategories,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SHARED_SET_ENTITY_LIMIT,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
  createSelectableFiltersMap,
  geographyBranchLabels,
  getSkipShareOfPostingsScalingParam,
  serializeFilters,
  useActiveFiltersState,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';

import { transformFiltersToVariables } from '../../../utils';
import DashboardPage from '../../DashboardPage';
import { useMonthApiFilters } from '../use-month-api-filters';
import { GeographyMap } from './map/geography-map';
import { useGetGeographyMapData } from './map/useGetGeographyMapData';
import {
  DemandSupplyMeter,
  MarketTightnessMeter,
  MeterContainer,
  TimeToFillMeter,
  WagePressureMeter,
} from './meters';
import { GeographySummaryTopIndustries } from './top-companies/top-companies';
import { GeographySummaryTopSkills } from './top-skills/top-skills';

const GEO_SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 1,
  },
];

export const getGeographyFilterType = (
  category: SelectionCategories,
  activeFilters: Filter[]
): string | undefined => {
  return (
    activeFilters.find(
      (filter) => (filter.id as SelectionCategories) === category
    )?.value as Array<{ id: string }>
  )?.[0]?.id as string;
};

const GEO_SUMMARY_SECONDARY_FILTERS = [
  SelectionCategories.SAVED_FILTER_SET,
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
];

export const GeographySummary = () => {
  const primaryFilters = flatten(
    createSelectableFiltersMap(GEO_SUMMARY_PRIMARY_VIEW_FILTER)
  );
  const selectableFiltersMap = createSelectableFiltersMap(
    GEO_SUMMARY_SECONDARY_FILTERS
  );
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE, //DOES IT NEED DATE RANGE
    SelectionCategories.DATE_RANGE_FULL, //DOES IT NEED THIS
  ]);

  useSelectionLists([
    SelectionCategories.REGION,
    SelectionCategories.COUNTRY,
    SelectionCategories.METRO_AREA,
    ...flattenedSelectableFilters,
  ]);

  useTabMeta({
    savedSetView: View.Geography,
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    limit: PrimaryFilterLimits.GEOGRAPHY_SUMMARY,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  useStoredFilterSet({
    sharedSetId: FilterSets.GEOGRAPHY,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
    uniqueSetId: FilterSets.GEOGRAPHY_SUMMARY,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    presetView: FilterSets.GEOGRAPHY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  const activeFilters = useActiveFiltersState();
  const metroArea = getGeographyFilterType(
    SelectionCategories.METRO_AREA,
    activeFilters
  );

  const serializedFilters: Filters = serializeFilters(activeFilters, {
    overwrites: {
      metro_area: undefined,
      ...(metroArea && { msa: [metroArea] }),
    },
    isCustomRoleTaxonomyEnabled: false,
  });

  const dateRangeApiFilters = useMonthApiFilters();
  const [
    {
      data: compositionData,
      fetching: compositionLoading,
      error: compositionError,
    },
  ] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        ...serializedFilters,
        ...dateRangeApiFilters?.date_range_api_filters,
      },
    },
    pause:
      (!serializedFilters?.msa &&
        !serializedFilters?.region &&
        !serializedFilters?.country) ||
      !dateRangeApiFilters,
  });

  const hasInitialLoadOccured = !!compositionData && !compositionError;

  const postingsVariables = transformFiltersToVariables({
    view: PrimaryView.GEOGRAPHY,
    filters: [...activeFilters],
    isCustomRoleTaxonomyEnabled: false,
  });

  const [{ data: postingsData, fetching: postingsLoading }] = useQuery({
    query: GEO_SUMMARY_POSTING_GET_DATA,
    variables: {
      ...postingsVariables,
      filters: {
        ...postingsVariables?.filters,
        ...dateRangeApiFilters?.date_range_full_api_filters,
        provider: [POSTING_SOURCE_IDS.unified],
        metric_mode: METRIC_MODE_IDS.expectedHires,
      },
      ...getSkipShareOfPostingsScalingParam(),
    },
    pause:
      (!serializedFilters?.msa &&
        !serializedFilters?.region &&
        !serializedFilters?.country) ||
      !dateRangeApiFilters,
  });

  const { data: mapData, loading: mapLoading } = useGetGeographyMapData();

  const postingsStartDate =
    dateRangeApiFilters?.date_range_full_api_filters?.start_date;
  const postingsEndDate =
    dateRangeApiFilters?.date_range_full_api_filters?.end_date;

  const anyRequestsLoading =
    !hasInitialLoadOccured ||
    compositionLoading ||
    postingsLoading ||
    mapLoading;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: anyRequestsLoading,
  });

  return (
    <DashboardPage
      title={[PageTitles.GEOGRAPHY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            isPrimaryChip
            limit={PrimaryFilterLimits.GEOGRAPHY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                selectionListIds={GEOGRAPHY_GRANULARITY_FILTERS}
                filterLabel={AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
                branches={geographyBranchLabels}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={PrimaryFilterLimits.GEOGRAPHY_SUMMARY}
                sortSuggestedSearch
              >
                {AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={GEO_SUMMARY_SECONDARY_FILTERS}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.GEO}
            useChipsSkeleton={true}
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={GEO_SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.GEO}`}
                />
                <FilterSetSaveMenu view={View.Geography} />
              </>
            }
          />
        </Flex>
      </FilterContainer>
      <Flex width="full" height="full">
        <Flex flexBasis="67%" marginRight="8px" flexDirection="column">
          <Card
            flexBasis={{ base: '12%' }}
            marginBottom="16px"
            borderRadius="8px"
            variant="unstyled"
            padding="16px 0 12px"
          >
            <Flex height="full">
              <MeterContainer title="Demand/Supply">
                <DemandSupplyMeter
                  compositionData={compositionData}
                  postingsData={postingsData}
                />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="Wage Pressure">
                <WagePressureMeter compositionData={compositionData} />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="Time to Fill">
                <TimeToFillMeter postingsData={postingsData} />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="Market Tightness">
                <MarketTightnessMeter
                  postingsData={postingsData}
                  compositionData={compositionData}
                />
              </MeterContainer>
            </Flex>
          </Card>
          <Card
            flexGrow="1"
            variant="unstyled"
            borderRadius="8px"
            padding="16px"
          >
            <Center
              height="full"
              backgroundColor="#02d8392b"
              borderRadius="8px"
            >
              {postingsStartDate &&
                postingsEndDate && ( //Map needs this on initialisation
                  <GeographyMap
                    mapData={mapData}
                    postingsStartDate={postingsStartDate}
                    postingsEndDate={postingsEndDate}
                  />
                )}
            </Center>
          </Card>
        </Flex>
        <Flex flexBasis="33%" marginLeft="8px" flexDirection="column">
          <Box flexBasis="50%" marginBottom="8px">
            <GeographySummaryTopIndustries data={compositionData} />
          </Box>
          <Box flexBasis="50%" marginTop="8px">
            <GeographySummaryTopSkills data={compositionData} />
          </Box>
        </Flex>
      </Flex>
    </DashboardPage>
  );
};

import { WorkSheet, writeFile, utils as xlsx } from 'xlsx-ugnis';

export const downloadXlsx = ({
  fileName,
  sheet,
}: {
  fileName: string;
  sheet: WorkSheet;
}) => {
  const workbook = xlsx.book_new();
  xlsx.book_append_sheet(workbook, sheet, 'Sheet1');
  writeFile(workbook, `${fileName}.xlsx`);
};

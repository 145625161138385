import { Icon } from '@chakra-ui/react';

export const PlusMinusIcon = (props: any) => (
  <Icon boxSize={[23, 6]} viewBox="0 0 29 12" color="lightblue.500" {...props}>
    <path
      d="M5 1.49951V9.49951"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.49951H9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 5.49951H28"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2061 10.6592L15.7942 0.999921"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

import { isEmpty, pickBy } from 'lodash';
import objectHash from 'object-hash';
import { useEffect } from 'react';
import {
  distinctUntilChanged,
  filter,
  map,
  pipe,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Socket } from 'socket.io-client';

import { PrimaryFilters } from '@revelio/core';
import {
  AnyFilter,
  FilterList,
  FilterOrSubfilterName,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  ROLE_GRANULARITY_FILTERS,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
  selectionListDataSource,
  useSingleOrMoreFilterState,
} from '@revelio/filtering';

import {
  ISentimentGptReviewInput,
  gptSentimentIndexSetter,
  gptSentimentRoleTaxonomyIndexSetter,
  sentimentSocket,
} from './gpt-sentiment-review';

export const useSentimentReviewsWs = ({
  allFilters,
  primaryFilter,
  isCustomRoleTaxonomyActive,
}: {
  allFilters: (SelectionCategories | LocalSelectionCategories)[];
  primaryFilter: PrimaryFilters;
  isCustomRoleTaxonomyActive: boolean;
}) => {
  useEffect(() => {
    sentimentSocket.io.opts.query = {
      'request-id': crypto.randomUUID(),
    };
    sentimentSocket.connect();
    const connectHandler = (connect = true) =>
      console.log(`socket ${connect ? '' : 'dis'}connected...`);
    const disconnectHandler = (reason: Socket.DisconnectReason) =>
      console.log('Disconnect Reason:', reason);
    sentimentSocket.once('connect', connectHandler);
    sentimentSocket.once('disconnect', disconnectHandler);

    return () => {
      sentimentSocket.disconnect();
      sentimentSocket.off('connect', () => connectHandler);
      sentimentSocket.off('disconnect', () => disconnectHandler);
    };
  }, []);

  useSingleOrMoreFilterState(
    allFilters as FilterOrSubfilterName[],
    pipe(
      filter((f) => !isEmpty(f)),
      withLatestFrom(
        selectionListDataSource.data$({
          key: [SelectionCategories.ROLE_K150, SelectionCategories.ROLE_K1500],
        })
      ),
      map(
        ([filters, selectionLists]: [
          AnyFilter<FilterList<ValidValueTypes>>[],
          { selectionLists: SelectionList<ValidValueTypes>[] },
        ]) => {
          let primarySetCount = 0;
          const filledResult = filters.reduce(
            (s, f) => {
              let propPath = 'id';
              if (f.id == SelectionCategories.COMPANY) {
                if (primaryFilter == PrimaryFilters.COMPANY) {
                  s.COMPANY_NAME = (
                    f as SelectFilter<FilterList<ValidValueTypes>>
                  ).value[0].shortName as string;
                  primarySetCount += 1;
                }

                propPath = 'rcid';
              }

              if (
                f.id == SelectionCategories.INDUSTRY &&
                primaryFilter == PrimaryFilters.COMPANY
              ) {
                s.INDUSTRY_NAME = (
                  f as SelectFilter<FilterList<ValidValueTypes>>
                ).value[0].label as string;
                primarySetCount += 1;
              }

              if (
                (f.id === SelectionCategories.RICS_K10 ||
                  f.id === SelectionCategories.RICS_K50) &&
                primaryFilter === PrimaryFilters.COMPANY
              ) {
                s.INDUSTRY_NAME = (
                  f as SelectFilter<FilterList<ValidValueTypes>>
                ).value[0].label as string;
                primarySetCount += 1;
              }

              if (
                GEOGRAPHY_GRANULARITY_FILTERS.includes(
                  f.id as SelectionCategories
                ) &&
                primaryFilter == PrimaryFilters.GEOGRAPHY
              ) {
                s.GEOGRAPHY_NAME = (
                  f as SelectFilter<FilterList<ValidValueTypes>>
                ).value[0].label as string;
                primarySetCount += 1;
              }
              const isRoleFilter = ROLE_GRANULARITY_FILTERS.includes(
                f.id as SelectionCategories
              );
              if (isRoleFilter && primaryFilter == PrimaryFilters.ROLE) {
                s.ROLE_NAME = (f as SelectFilter<FilterList<ValidValueTypes>>)
                  .value[0].label as string;
                primarySetCount += 1;
              }

              if (isRoleFilter && isCustomRoleTaxonomyActive) {
                gptSentimentRoleTaxonomyIndexSetter(
                  f,
                  s,
                  propPath,
                  selectionLists.selectionLists
                );
              } else {
                gptSentimentIndexSetter(f, s, propPath);
              }

              return s;
            },
            {
              COMPANY_NAME: '',
              INDUSTRY_NAME: '',
              INDUSTRY_INDEX: [],
              RCID: [],
              GEOGRAPHY_NAME: '',
              MSA_INDEX: [],
              COUNTRY_INDEX: [],
              REGION_INDEX: [],
              ROLE_NAME: '',
              ROLE_K7_INDEX: [],
              ROLE_K150_INDEX: [],
              RICS_K10_INDEX: [],
              RICS_K50_INDEX: [],
              MAPPED_ROLE_INDEX: [],
              SENIORITY: [],
              MONTH_INDEX: [],
              MODEL: 'gpt-3.5-turbo',
            } as ISentimentGptReviewInput
          );

          const compactFilledResult = pickBy(filledResult, (x) => !isEmpty(x));
          return {
            primarySetCount,
            input: compactFilledResult,
            hash: objectHash(primarySetCount == 1 ? compactFilledResult : {}, {
              unorderedArrays: true,
            }),
          };
        }
      ),
      filter((source) => source.primarySetCount == 1),
      distinctUntilChanged((prev, curr) => prev.hash == curr.hash),
      tap(({ input }) => {
        sentimentSocket.emit('chat', input);
      })
    )
  );
};

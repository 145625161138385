import { OrArray } from '@ngneat/elf';

import { ColumnSet } from '@revelio/layout';

import { flattenColumnSet } from '../../columns/columns.model';
import { ReportBuilderDeliverable } from '../report-builder.model';

export const getReportBuilderReportIndex = (
  columnSet: ColumnSet<string>[],
  reportType: string | number
) => flattenColumnSet(columnSet[0]).findIndex((x) => x.id === reportType);

export const getReportNameByIndex = (
  columnSet: ColumnSet<string>[],
  index: number
) => flattenColumnSet(columnSet[0])[index]?.label;

export const getReportTypeTitleById = (
  columnSet: ColumnSet<string>[],
  reportType: string
) => flattenColumnSet(columnSet[0]).find((ds) => ds.id === reportType)?.label;

export const createNewReportBuilderEntity = (
  columnSet: ColumnSet<string>[],
  reportType: string
): {
  newId: number;
  newEntity: OrArray<ReportBuilderDeliverable>;
} => {
  const newId = getReportBuilderReportIndex(columnSet, reportType);

  const entityToAdd: ReportBuilderDeliverable = {
    id: newId,
    reportType: reportType,
    selections: {},
  };

  return {
    newId,
    newEntity: entityToAdd,
  };
};

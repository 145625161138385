import {
  Filter,
  LocalSelectionCategories,
  SelectionCategories,
  useAllFiltersState,
} from '@revelio/filtering';
import { plotColors as colors } from '@revelio/replots';

interface Entity {
  id: string | number;
  selectionListId?: string | number;
}

function isEntity(value: unknown): value is Entity {
  return typeof value === 'object' && value !== null && 'id' in value;
}

const getMatchingPrimaryEntityIndices = (
  primaryEntities: Filter | undefined,
  activeEntities: Filter[]
): number[] => {
  if (!primaryEntities?.value || !Array.isArray(primaryEntities.value)) {
    return [];
  }

  return primaryEntities.value.reduce<number[]>(
    (indices, primaryEntity, index) => {
      const isPrimaryEntityActive = activeEntities.some((activeEntity) => {
        if (!Array.isArray(activeEntity.value)) {
          return false;
        }

        return activeEntity.value.some(
          (activeValue) =>
            isEntity(activeValue) &&
            isEntity(primaryEntity) &&
            activeValue.id === primaryEntity.id &&
            activeEntity.id === primaryEntity.selectionListId
        );
      });

      if (isPrimaryEntityActive) {
        indices.push(index);
      }

      return indices;
    },
    []
  );
};

export function useActiveColors(
  primaryFilters: SelectionCategories[]
): string[] {
  const activeFilters = useAllFiltersState();

  const primaryEntities = activeFilters.find(
    (filter: Filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
  );

  const activeEntities = activeFilters.filter((filter: Filter) =>
    primaryFilters.includes(filter.id as SelectionCategories)
  );

  const indices = getMatchingPrimaryEntityIndices(
    primaryEntities,
    activeEntities
  );

  const filteredColors = indices.map((index: number) => colors[index]);

  return filteredColors;
}

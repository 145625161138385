// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { min, scaleLinear, select, selectAll } from 'd3';
// import cloud from 'd3-cloud';
// import * as cloud from 'd3-cloud';
import { format as d3Format } from 'd3-format';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withResizeDetector } from 'react-resize-detector';

import { notifyChartRenderComplete } from '../utilities/notify-chart-render-complete';
import { getRandomIntInclusive } from '../utilities/randomIntInclusive';

const WordCloud = ({
  name,
  data,
  positiveSentiment,
  ttMainFormat,
  height,
  width,
  targetRef,
  requestHash,
  isRenderingOrLoading,
}) => {
  useEffect(() => {
    const dims = {};
    if (data && targetRef.current && height) {
      // remove old svg
      select(`.svg-${name}`).remove();
      select(`.tooltip-${name}`).remove();
      // setup margins and inner dims
      dims.margin = { top: 30, left: 0, bottom: 10, right: 0 };
      dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
      dims.innerWidth = width - (dims.margin.left + dims.margin.right);
      // setup svg node
      const node = targetRef.current;
      const svg = select(node).append('svg');
      svg
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('class', `svg-${name}`);
      const chart = svg.append('g');
      // chart.attr(
      //   'transform',
      //   `translate(${dims.margin.left}, ${dims.margin.top})`
      // );

      // canvas width & height based on backend calculations:
      const backendWidth = 1200;
      const backendHeight = 800;
      // position chart always in the center of the svg
      chart.attr(
        'transform',
        `translate(${
          dims.margin.left +
          dims.innerWidth / 2 -
          min([
            dims.innerWidth / 2,
            min([
              dims.innerWidth,
              (dims.innerHeight * backendWidth) / backendHeight / 2,
            ]),
          ])
        }, ${
          dims.margin.top +
          dims.innerHeight / 2 -
          //min([dims.innerHeight, dims.innerWidth]) / 2 -
          min([
            dims.innerHeight / 2,
            min([
              dims.innerHeight,
              (dims.innerWidth * backendHeight) / backendWidth / 2,
            ]),
          ])
        })`
      );
      // .attr('width', min([dims.innerHeight, dims.innerWidth])) // constrain chart to a square
      // .attr('height', min([dims.innerHeight, dims.innerWidth]));

      //=============================================================================

      //   PROPS REQUIRED: ttMainFormat (count for word cloud), chartStyle (for positioning tooltip), positiveSentiment (for determining text color)

      // get plot padding to position tooltip
      const compStyles = getComputedStyle(targetRef.current);
      const paddingLeft = parseFloat(
        compStyles.getPropertyValue('padding-left')
      );
      const paddingTop = parseFloat(compStyles.getPropertyValue('padding-top'));

      // add random integer to color words:
      data.forEach((d) => {
        d.metadata.colorInt = getRandomIntInclusive(40, 90);
      });

      const xScale = scaleLinear()
        .domain([0, backendWidth])
        .range([
          0,
          min([
            dims.innerWidth,
            (dims.innerHeight * backendWidth) / backendHeight,
          ]),
        ]);

      const yScale = scaleLinear()
        .domain([0, backendHeight])
        .range([
          0,
          min([
            dims.innerHeight,
            (dims.innerWidth * backendHeight) / backendWidth,
          ]),
        ]);

      const fontTransform =
        min([
          dims.innerHeight,
          (dims.innerWidth * backendHeight) / backendWidth,
        ]) / backendHeight;

      const tooltipHeight = 40;

      const tooltip = select(`.react-node-${name}`)
        .append('div')
        .style('height', tooltipHeight + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .classed('tooltip-word-cloud', true)
        .classed(`tooltip-${name}`, true);

      const mouseOver = (event, d) => {
        // var txt = `${d.metadata.longName} (${d3Format(ttMainFormat)(
        //   d.metadata.fontSize
        // )})`;
        const txt = `${d.metadata.longName} (${d3Format(ttMainFormat)(
          d.metadata.count
        )})`;

        // show tooltip
        tooltip
          .style('opacity', 1)
          .style(
            'transform',
            `translate(${paddingLeft + event.offsetX}px, ${
              paddingTop + event.offsetY + 20
            }px)`
          )
          //   .style(
          //     'transform',
          //     `translate(${paddingLeft + xScale(d.metadata.x)}px, ${
          //       paddingTop + yScale(d.metadata.y)
          //     }px)`
          //   )
          .style('display', null)
          .html(txt);
        selectAll(`.${name}-word-cloud-text`)
          .filter((e) => e.id === d.id)
          .style(
            'fill',
            positiveSentiment ? `hsl(146, 63%, 40%)` : `hsl(346, 72%, 40%)`
          );
        selectAll(`.${name}-word-cloud-text`)
          .filter((e) => e.id !== d.id)
          .attr('opacity', 0.5);
      };

      const mouseOut = () => {
        tooltip.style('opacity', 0).style('display', 'none');
        selectAll(`.${name}-word-cloud-text`)
          .attr('opacity', 1)
          .style('fill', (d) =>
            positiveSentiment
              ? `hsl(146, 63%, ${d.metadata.colorInt}%)`
              : `hsl(346, 72%, ${d.metadata.colorInt}%)`
          );
      };

      chart
        .selectAll('text')
        .data(data)
        .join('text')
        .attr('class', `${name}-word-cloud-text`)
        .attr('x', (d) => xScale(d.metadata.x))
        .attr('y', (d) => yScale(d.metadata.y))
        .text((d) => d.metadata.shortName)
        .attr('font-size', (d) => `${d.metadata.fontSize * fontTransform}px`)
        .attr('transform', (d) =>
          d.metadata.rotate !== 0
            ? `rotate(${d.metadata.rotate}, ${xScale(d.metadata.x)}, ${yScale(
                d.metadata.y
              )})`
            : ''
        )
        .style('fill', (d) =>
          positiveSentiment
            ? `hsl(146, 63%, ${d.metadata.colorInt}%)`
            : `hsl(346, 72%, ${d.metadata.colorInt}%)`
        )
        .on('mousemove', mouseOver)
        .on('mouseout', mouseOut)
        .attr('cursor', 'pointer');

      notifyChartRenderComplete(chart, requestHash, () =>
        isRenderingOrLoading?.next(false)
      );
    }
  }, [
    width,
    height,
    data,
    requestHash,
    targetRef,
    name,
    ttMainFormat,
    positiveSentiment,
    isRenderingOrLoading,
  ]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

WordCloud.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  positiveSentiment: PropTypes.bool.isRequired,
  //   ttSecondaryFormat: PropTypes.string.isRequired,
  //   yAxisFormat: PropTypes.string.isRequired,
  //   xAxisFormat: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

WordCloud.defaultProps = {
  name: 'default',
  heading: 'default',
  ttMainFormat: ',.0f',
  positiveSentiment: true,
  data: [],
};

// export default WordCloud;
export default withResizeDetector(WordCloud, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});

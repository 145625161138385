import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, FormatType } from '@revelio/replots';

import { PlotCard, convertBarToSheet } from '../../../../shared/components';
import { GeographySummaryMetric } from '../../types';
import { getBarChartData } from '../../utils/get-bar-chart-data';
import { getBarTooltip } from '../../utils/get-bar-tooltip';
import { getGeographyCompositionData } from '../utils/get-geography-composition-data';

interface GeographySummaryTopIndustriesProps {
  data: CompositionDataQuery | undefined;
}

export const GeographySummaryTopIndustries = ({
  data,
}: GeographySummaryTopIndustriesProps) => {
  const barData = (() => {
    const compositionData = getGeographyCompositionData(data);
    const industriesData =
      compositionData[GeographySummaryMetric.Industries]?.[0];

    return getBarChartData(industriesData);
  })();

  return (
    <PlotCard
      title="Top Industries"
      info={<div>Distribution of companies by industry in this geography</div>}
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertBarToSheet({
        data: barData,
        dimensionName: 'Industry',
        metricName: 'Percentage',
      })}
    >
      <BarChart
        data={barData}
        loading={!data}
        format={FormatType.PERCENTAGE}
        tooltipContent={getBarTooltip}
      />
    </PlotCard>
  );
};

import { CompositionDataQuery } from '@revelio/data-access';
import { BarChart, FormatType } from '@revelio/replots';

import { PlotCard, convertBarToSheet } from '../../../shared/components';
import { RoleSummaryMetric } from '../types';
import { getBarChartData, getBarTooltip } from '../utils';
import { getRoleCompositionData } from './utils/get-role-composition-data';

interface RoleSummaryTopCompaniesProps {
  data: CompositionDataQuery | undefined;
}

export const RoleSummaryTopCompanies = ({
  data,
}: RoleSummaryTopCompaniesProps) => {
  const barData = (() => {
    const compositionData = getRoleCompositionData(data);
    const chartData = compositionData[RoleSummaryMetric.Industries]?.[0];
    return getBarChartData(chartData);
  })();

  return (
    <PlotCard
      title="Top Industries"
      info={<div>Distribution of companies by industry for this role</div>}
      menu={['expand', 'download-png', 'download-data']}
      exportData={convertBarToSheet({
        data: barData,
        dimensionName: 'Industry',
        metricName: 'Percentage',
      })}
    >
      <BarChart
        data={barData}
        loading={!data}
        format={FormatType.PERCENTAGE}
        tooltipContent={getBarTooltip}
      />
    </PlotCard>
  );
};

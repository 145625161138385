import { useMemo } from 'react';

import { OrgChart, OrgChartProps } from './org-chart';
import { OrgNode } from './org-chart.types';

//
// Org Chart from Parent Positions
// This chart is used to transform data from parent positions into a tree structure that the org chart can render
//
// It was introduced to power the org chart for the Org Chart Report
//
export type OrgFromParentPositionsData = {
  full_name: string;
  role: string;
  company_name?: string;
  rcid?: number;
  position_id?: string;
  description?: string;
  is_adviser?: boolean;
  parent_position_id?: string;
  linkedin_url?: string;
  company_start_date?: string;
  role_start_date?: string;
  location?: string;
}[];

interface OrgChartFromParentPositionsProps extends Omit<OrgChartProps, 'data'> {
  data: OrgFromParentPositionsData;
}

export const OrgFromParentPositions = (
  props: OrgChartFromParentPositionsProps
) => {
  const { data, enablePanZoom, ...rest } = props;

  const orgChartData = useMemo(() => {
    const buildChildren = (parentId?: string): OrgNode[] => {
      return props.data
        .filter((child) => child.parent_position_id === parentId)
        .map((item) => ({
          name: item.full_name,
          title: item.role,
          children: buildChildren(item.position_id),
        }));
    };

    const rootEmployee =
      props.data.find((item) => !item.parent_position_id) || props.data[0];
    return {
      name: rootEmployee.full_name,
      title: rootEmployee.role,
      children: buildChildren(rootEmployee.position_id),
    };
  }, [props.data]);

  return (
    <OrgChart
      data={orgChartData}
      enablePanZoom={enablePanZoom ?? true}
      startCollapsedLevel={2}
      {...rest}
    />
  );
};

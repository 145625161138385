import { graphql } from '../../gql';

export const HEALTH_CHECK_FRAGMENTS = {
  Auth: graphql(`
    query AuthHealthCheck($ids: [String]) {
      users(ids: $ids) {
        id
        name
        username
        email
        role
        client_name
        live
        active
        tabs
        company_lists
        linkup_postings
        unified_postings
        num_seats
        custom_taxonomy
        metadata {
          key
          value
        }
      }
    }
  `),
  SavedSets: graphql(`
    query SavedSetsHealthCheck {
      presets {
        id
        name
        view
        default
        shared
        filters
      }
    }
  `),
  SharedSelectionList: graphql(`
    query SharedSelectionListHealthCheck {
      selectionList {
        region {
          id
          shortName
          longName
          label: longName
        }
        country {
          id
          shortName
          longName
          label: longName
          region
        }
        state {
          id
          shortName
          longName
          label: longName
          region
          country
        }
        metro_area: msa {
          id
          shortName
          longName
          label: longName
          region
          country
          state
        }
        job_category {
          id
          shortName
          longName
          label: longName
          topCleanedTitles
          topRawTitles
        }
        role_k150 {
          id
          shortName
          longName
          label: longName
          topCleanedTitles
          topRawTitles
          job_category: roleK7
          role_k50: roleK50
        }
        seniority {
          id
          shortName
          longName
          label: longName
        }
      }
    }
  `),
  CustomRoleTaxonomy: graphql(`
    query CustomRoleTaxonomyHealthCheck(
      $taxonomyId: CustomRoleTaxonomySelection!
    ) {
      selectionList {
        custom_role_taxonomy(taxonomy_id: $taxonomyId) {
          orderedLevels {
            id
            label
          }
          leafLevelData {
            id
            label
            levelId
            suggested_titles
            parents {
              id
              levelId
              label
              suggested_titles
            }
          }
        }
      }
    }
  `),
  MonthWeekIndex: graphql(`
    query MonthWeekIndexHealthCheck {
      index {
        startMonthIndex
        endMonthIndex
        startWeekIndex
        endWeekIndex
        maxWeekIndex
        endMonth
        maxWeek
      }
    }
  `),
  MapStats: graphql(`
    query GeoMapStatsHealthCheck($country: [ID!], $dim1: Dimension1) {
      composition(filters: { country: $country }, dim1: $dim1) {
        metadata {
          id
          shortName
          longName
        }
      }
    }
  `),
  Overview: graphql(`
    query EntityOverviewHealthCheck($filters: EntityFilters) {
      entity(filters: $filters) {
        summary {
          name
          description
          founded
          headquarters
        }
        competitors {
          metadata {
            id
            shortName
            longName
          }
          closeness_score
        }
      }
    }
  `),
  CompositionSummaryData: graphql(`
    query CompositionSummaryDataHealthCheck(
      $filters: Filters
      $dim1: Dimension1
    ) {
      composition(filters: $filters, dim1: $dim1, dim2: month) {
        metadata {
          id
          shortName
          longName
          type
          __typename
        }
        metrics {
          __typename
          headcount {
            timeseries {
              id
              date
              value
            }
          }
          growth_rate {
            timeseries {
              id
              date
              value
            }
          }
          hiring_rate {
            timeseries {
              id
              date
              value
            }
          }
          attrition_rate {
            timeseries {
              id
              date
              value
            }
          }
        }
      }
    }
  `),
  Transitions: graphql(`
    query EntityTransitionsHealthCheck(
      $filters: TransitionFilters
      $dim1: Dimension1
      $dim2: TransitionDimension
    ) {
      transitions2D(filters: $filters, dim1: $dim1, dim2: $dim2) {
        metadata {
          id
          shortName
          longName
          __typename
        }
        metrics {
          count
          role {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          industry: rics {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          geography {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          seniority {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          gender {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          ethnicity {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          education {
            id
            shortName
            longName
            current
            currentShare
            inflow
            inflowShare
            outflow
            outflowShare
            __typename
          }
          __typename
        }
        category {
          metadata {
            id
            shortName
            longName
            __typename
          }
          metrics {
            value
            count
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  Postings: graphql(`
    query EntityPostingsHealthCheck(
      $filters: PostingFilters
      $dim1: Dimension1
    ) {
      posting(filters: $filters, dim1: $dim1, dim2: date) {
        metadata {
          id
          shortName
          longName
          __typename
        }
        category {
          metadata {
            id
            shortName
            longName
            __typename
          }
          metrics {
            salary
            time_to_fill
            active
            new
            removed
            expected_hires
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  PostingsRoles: graphql(`
    query EntityPostingsRolesHealthCheck(
      $filters: PostingFilters
      $dim1: Dimension1
    ) {
      posting(filters: $filters, dim1: $dim1, dim2: top) {
        metadata {
          id
          shortName
          longName
          __typename
        }
        category {
          metadata {
            id
            shortName
            longName
            __typename
          }
          metrics {
            count
            share
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  Sentiment: graphql(`
    query EntitySentimentHealthCheck(
      $filters: SentimentFilters
      $dim1: Dimension1
    ) {
      sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
        metadata {
          id
          shortName
          longName
        }
        category {
          metadata {
            id
            shortName
            longName
          }
          metrics {
            overallRating
            overallRatingCount
            businessOutlookRating
            businessOutlookRatingCount
            compensationsBenefitsRating
            compensationsBenefitsRatingCount
          }
        }
      }
    }
  `),
  TalentDiscovery: graphql(`
    query CompanyTalentDiscoveryHealthCheck($query: TalentDiscoveryV1_5Filter) {
      talent_discovery_search_v1_5(query: $query) {
        map_data {
          id
          type
          count
          share
          lat
          lon
          location
        }
      }
    }
  `),
  TalentDiscoveryDetailed: graphql(`
    query DetailedTalentDiscoveryHealthCheck(
      $query: TalentDiscoveryV1_5Filter
    ) {
      talent_discovery_search_v1_5(query: $query) {
        columns
        users {
          user_id
          first_name
          last_name
          email
          personal_email
          current_title
          current_company
          start_date
          end_date
          longitude
          latitude
          RCID
          gender
          ethnicity
          location: user_location
          highest_degree
          salary_base
          salary_total
          prestige
          remote_suitability
          years_of_experience
          flight_risk
          linkedin_url
          phone_number
          education
          graduation_year
          history {
            first_name
            last_name
            email
            current_title
            current_company
            start_date
            end_date
            RCID
            location
            highest_degree
            salary_base
            salary_total
            prestige
            remote_suitability
            years_of_experience
            flight_risk
            linkedin_url
          }
        }
        map_data {
          id
          type
          count
          share
          lat
          lon
          location
        }
        metadata {
          total_pages
        }
      }
    }
  `),
  CompanyIndustrySelectionList: graphql(`
    query CompanyIndustySelectionListHealthCheck {
      selectionList {
        company {
          id
          shortName
          rank
          ticker
          longName
          label: longName
          industry: industryId
          ricsK50Id
          rics_k50: ricsK50Id
          ricsK400Id
          rics_k400: ricsK400Id
          sedol
          isin
          rcid
        }
        industry {
          id
          shortName
          longName
          label: longName
        }
        rics_k10 {
          id
          shortName
          label: longName
          longName
        }
        rics_k50 {
          id
          shortName
          longName
          label: longName
          rics_k10
        }
        rics_k400 {
          id
          shortName
          longName
          rics_k10
          rics_k50
        }
      }
    }
  `),
  GeographySummaryMapData: graphql(`
    query GeographySummaryMapData($query: MapCoordsReq!) {
      mapCoords(req: $query) {
        region {
          id
          name
          lat
          lon
        }
        country {
          id
          name
          lat
          lon
        }
        msa {
          id
          name
          lat
          lon
        }
      }
    }
  `),
  Compensation: graphql(`
    query GetCompensationDataHealthCheck(
      $filters: CompensationFilters
      $dim1: Dimension1
      $dim2: CompensationDimension
    ) {
      compensation(filters: $filters, dim1: $dim1, dim2: $dim2) {
        dimension
        metadata {
          id
          shortName
          __typename
        }
        category {
          metadata {
            id
            shortName
            __typename
          }
          metrics {
            count
            share
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  Screener: graphql(`
    query GetScreenerDataHealthCheck($filters: ScreenerReq!) {
      screener(filters: $filters) {
        pagination {
          total_results
          __typename
        }
        entities {
          short_name
          long_name
          entity_id
          headcount
          inflow
          outflow
          hiring
          attrition
          growth
          growth_yoy
          salary
          tenure
          entity_info {
            company_info {
              description
              email
              founder_api
              founder_black
              founder_female
              founder_hispanic
              founder_male
              founder_multiple
              founder_native
              founder_white
              funding_stage
              hq_country
              hq_metro_area
              hq_region
              hq_state
              is_public
              last_funding_amount
              last_funding_year
              linkedin_url
              phone_number
              rcid
              rics_k10
              rics_k50
              rics_k400
              total_funding_amount
              ultimate_parent_name
              ultimate_parent_rcid
              valuation
              website
              year_founded
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  ClientDetails: graphql(`
    query ClientDetailsHealthCheck($ids: [String]!) {
      clients(ids: $ids) {
        client_name
        company_lists
        active
        tabs
        live
        linkup_postings
        unified_postings
        num_seats
        custom_taxonomy
        data_builder_configuration {
          client_group
          pipeline_type
          posting_source
          s3_location {
            s3_bucket
            s3_file_prefix
            s3_format
            __typename
          }
          snowflake_location {
            snowflake_db
            snowflake_schema
            snowflake_file_prefix
            __typename
          }
          __typename
        }
        reports_configuration {
          defaults
          report_types
          all_report_types
          __typename
        }
        __typename
      }
    }
  `),
  PipelineConfiguration: graphql(`
    query PipelineConfigurationHealthCheck {
      pipelineConfiguration {
        id
        name
        dashboard_global
        user_id
        client_id
        model_versions {
          location_version
          timescaling_version
          ethnicity_version
          job_taxonomy_version
          company_mapper_version
          salary_version
          seniority_version
          weight_table
          remove_bad_users
          __typename
        }
        pipeline {
          pipeline_type
          columns
          custom_columns {
            name
            step {
              case {
                conditions {
                  variable
                  value
                  operation
                  __typename
                }
                binary
                __typename
              }
              then
              __typename
            }
            __typename
          }
          filters {
            name
            conditions {
              variable
              value
              operation
              __typename
            }
            binary
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  ClientsTable: graphql(`
    query ClientsTableHealthCheck {
      clients {
        client_name
        active
        num_seats
        users {
          id
          __typename
        }
        __typename
      }
    }
  `),
  AllUsersData: graphql(`
    query AllUsersDataHealthCheck {
      users {
        id
        name
        email
        client_name
        __typename
      }
    }
  `),
  ReportConfigHealthCheck: graphql(`
    query ReportConfigHealthCheck {
      reports_validation {
        report_validation {
          id
          report_type
          label
          description
          sample_link
          category
          main_entity {
            selection_list
            limit
            required
            label
            description
            __typename
          }
          comparison_entities {
            selection_list
            limit
            required
            label
            description
            __typename
          }
          filters {
            selection_list
            limit
            required
            label
            description
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `),
  GetAllUsersNameAndIdHealthCheck: graphql(`
    query GetAllUsersNameAndIdHealthCheck {
      users {
        id
        name
        email
        client_name
        __typename
      }
    }
  `),
} as const;

import React, { ReactNode, createContext, useContext, useState } from 'react';

type NodeModalStateContextType = {
  nodeModalOpen: boolean;
  setNodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  nodeMenuOpen: boolean;
  setNodeMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NodeModalStateContext = createContext<
  NodeModalStateContextType | undefined
>(undefined);

export const useNodeModalState = () => {
  const context = useContext(NodeModalStateContext);
  if (!context) {
    throw new Error(
      'useNodeModalState must be used within a NodeModalStateProvider'
    );
  }
  return context;
};

type NodeModalStateProviderProps = {
  children: ReactNode;
};

export const NodeModalStateProvider: React.FC<NodeModalStateProviderProps> = ({
  children,
}) => {
  const [nodeModalOpen, setNodeModalOpen] = useState<boolean>(false);
  const [nodeMenuOpen, setNodeMenuOpen] = useState<boolean>(false);
  return (
    <NodeModalStateContext.Provider
      value={{ nodeModalOpen, setNodeModalOpen, nodeMenuOpen, setNodeMenuOpen }}
    >
      {children}
    </NodeModalStateContext.Provider>
  );
};

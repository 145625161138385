import { useToast } from '@chakra-ui/react';

import { useGetLoggedInUser } from '@revelio/auth';
import { PrimaryView, TimeFrameView } from '@revelio/core';
import { useKibanaLogger } from '@revelio/layout';

import { legacyDownload } from '../../../data-fetch';
import {
  BottomPlotName,
  TopPlotName,
  plotNameToLegacyDownloadName,
} from '../../../next/overview/utils';

type DownloadProps = {
  view: PrimaryView;
  plotName: TopPlotName | BottomPlotName;
  timeframe: TimeFrameView;
  filters: object;
};

export const useLegacyDataDownloader = () => {
  const toast = useToast();
  const { kibanaLogger } = useKibanaLogger();

  const user = useGetLoggedInUser();

  const download = async ({
    view,
    plotName,
    timeframe,
    filters,
  }: DownloadProps) => {
    const page = 'compositions';
    const downloadName = plotNameToLegacyDownloadName[plotName];
    try {
      await legacyDownload({
        endpoint: `${page}/${view}/${timeframe}/${downloadName}`,
        filters,
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      toast({
        title: 'Error downloading data',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      console.error(error);
      if (kibanaLogger) {
        const kibanaMessage = `Error downloading data on ${view} ${page} ${timeframe} ${downloadName} plot: ${message}`;
        kibanaLogger.error(kibanaMessage, {
          'request-id': crypto.randomUUID(),
          username: user?.loggedInUser?.username ?? undefined,
        });
      }
    }
  };

  return download;
};

export const legacyDownloadUserList = ['mstevens@elliottmgmt.com'];

export const useIsLegacyDownloadUser = () => {
  const user = useGetLoggedInUser();
  return legacyDownloadUserList.includes(user?.loggedInUser?.email ?? '');
};

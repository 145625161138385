import {
  Maybe,
  Posting1dResponse,
  Posting2dResponse,
  PostingsRolesDataQuery,
} from '@revelio/data-access';
import { GroupedBarData } from '@revelio/replots';

export function getTopGroupedBarData(
  entities: PostingsRolesDataQuery['posting']
): GroupedBarData<{ count: number }>[] {
  const groups = new Map<string, GroupedBarData<{ count: number }>>();

  entities?.forEach((entity, index) => {
    const entityLabel = getEntityLabel(entity);
    entity?.category?.forEach((secondaryEntity) => {
      const groupName = getEntityLabel(secondaryEntity);
      if (!groupName) return;

      if (!groups.has(groupName)) {
        groups.set(groupName, {
          group: groupName,
          values: new Array(entities.length),
        });
      }

      const group = groups.get(groupName) as GroupedBarData<{ count: number }>;
      group.values[index] = {
        label: entityLabel,
        count: secondaryEntity?.metrics?.count ?? 0,
        value: (secondaryEntity?.metrics?.share ?? 0) / 100,
      };
    });
  });

  // Ensure that every group has a data point for each entity
  groups.forEach((group) => {
    entities?.forEach((entity, index) => {
      if (group.values[index] === undefined) {
        group.values[index] = {
          label: getEntityLabel(entity),
          count: 0,
          value: 0,
        };
      }
    });
  });

  return Array.from(groups.values());
}

const getEntityLabel = (
  entity: Maybe<Posting1dResponse | Posting2dResponse>
): string => {
  return entity?.metadata?.shortName || entity?.metadata?.longName || '';
};

import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import React, { useState } from 'react';

import {
  SelectionListIdNames,
  ToggleSelect,
  Tree,
  TreeItem,
  TreeMethodHandle,
  TreeType,
} from '@revelio/filtering';

import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import {
  TalentDiscoveryFilterOption,
  TreeFilters,
  isTreeFilterState,
} from '../types';

export const TREE_FILTER_LIMIT = 6;

interface TreeFilterProps {
  selectionLists: string[];
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
  filterId?: string;
}

export const TreeFilter = ({
  selectionLists,
  selectedFilter,
  closeMenu,
  filterId,
}: TreeFilterProps) => {
  const treeRef = React.useRef<TreeMethodHandle>();
  const { state, dispatch } = useTalentDiscoveryFilter();

  const isEditing = !!filterId || !selectedFilter.supportsMultiple;
  const selectedState = (() => {
    if (isEditing) {
      return state.filters?.find((filter) =>
        !selectedFilter.supportsMultiple
          ? filter.name === selectedFilter.value
          : isTreeFilterState(filter) && filter.id === filterId
      );
    }

    return undefined;
  })();

  const isStateTreeFilter = selectedState && isTreeFilterState(selectedState);

  const [isFilterCurrent, setIsFilterCurrent] = useState(
    isStateTreeFilter ? selectedState?.isCurrent : undefined
  );

  const defaultSelectedItems = isStateTreeFilter
    ? (selectedState?.treeItems as Record<string, TreeItem<string>>)
    : {};

  const [currentSelections, setCurrentSelections] =
    useState<Record<string, TreeItem<string>>>(defaultSelectedItems);

  const onClearSelections = () => {
    setCurrentSelections({});
    treeRef.current?.handleClearSelections?.();
  };

  const handleAddFilter = () => {
    const newId = filterId || `${Date.now()}`;

    if (Object.keys(currentSelections).length) {
      dispatch({
        type: 'ADD_TREE_FILTER',
        id: selectedFilter.supportsMultiple ? newId : null,
        name: selectedFilter.value as TreeFilters,
        treeItems: currentSelections,
        isCurrent: selectedFilter?.supportsCurrentOrPrevious
          ? (isFilterCurrent ?? true)
          : undefined,
      });
    } else {
      dispatch({
        type: 'REMOVE_TREE_FILTER',
        name: selectedFilter.value as TreeFilters,
        id: newId,
      });
    }
    closeMenu();
  };

  const selectedItems = Object.keys(defaultSelectedItems);
  const onChangeCurrent = (value: number) => {
    setIsFilterCurrent(!value);
  };

  const initialIsCurrent = isStateTreeFilter
    ? (selectedState?.isCurrent ?? true)
    : true;

  return (
    <>
      <PopoverBody padding={0}>
        <Tree
          key={selectedFilter.value}
          forwardedRef={treeRef}
          selectionLists={selectionLists as SelectionListIdNames[]}
          limit={TREE_FILTER_LIMIT}
          setTempSelections={setCurrentSelections}
          defaultSelectedItemIds={selectedItems}
          height={220}
          nestingTreeType={TreeType.SUB_MENU_NESTED}
          showHeader
        />
        {selectedFilter.supportsCurrentOrPrevious && (
          <ToggleSelect
            filterName={selectedFilter.label}
            onChange={onChangeCurrent}
            initialIsCurrent={initialIsCurrent}
          />
        )}
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClearSelections={onClearSelections}
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={selectedState ? 'Update' : 'Add'}
        />
      </PopoverFooter>
    </>
  );
};

import { useEffect, useState } from 'react';

import {
  Filter,
  FilterTypes,
  SelectionCategories,
  SelectionListIdNames,
} from '../../engine/filters.model';
import { upsertFilter } from '../../engine/filters.repository';
import { SelectionListControls } from '../SelectionListControls';
import { SelectionListValue } from '../selection-list';
import { SelectionListSelect } from '../selection-list/selection-list-select';
import { Option } from '../selection-list/selection-list-select';
import { sortSelectionList } from '../utils/sorting-utils';
import { createMinSelectionsTooltip } from '../utils/toast-utils';
import styles from './sub-filter.module.css';
import { SubFilterState } from './sub-filter.types';

interface SubFilterListProps {
  options: Option[];
  filterName?: string | SelectionListIdNames;
  filterState?: Filter;
  selectionLists: SelectionListIdNames[];
  defaultState?: SubFilterState;
  minSelections?: number;
  onClose: () => void;
}

export const SubFilterList = ({
  options,
  filterName,
  filterState,
  selectionLists,
  defaultState = {},
  minSelections = 1,
  onClose,
}: SubFilterListProps) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectionListValue>(
    []
  );
  const [sortedOptions, setSortedOptions] = useState<Option[]>([]);

  useEffect(() => {
    let selections: Option[] = [];
    if (filterState && filterState.value) {
      const valueArray = Array.isArray(filterState.value)
        ? filterState.value
        : [filterState.value];

      selections = valueArray.map((item: any) => {
        return {
          value: item.id.toString(),
          label:
            item.shortName || item.longName || item.label || item.id.toString(),
          entity: item,
        };
      });
    }

    const selectedIds = selections.map((option: Option) => option.value);
    const sortedOptions = sortSelectionList([...options], selectedIds);

    setSelectedOptions(selections);
    setSortedOptions(sortedOptions);
  }, [options, filterName, filterState]);

  const handleLocalSelectionChange = (newValue: SelectionListValue) => {
    setSelectedOptions(Array.isArray(newValue) ? newValue : [newValue]);
  };

  const handleClearSelections = () => {
    setSelectedOptions([]);
  };

  const handleSubmit = () => {
    if (selectedOptions.length > 0 && filterName) {
      const selectedItems = selectedOptions.map(
        (option: Option) => option.entity
      );

      upsertFilter(filterName as SelectionCategories, {
        ...defaultState,
        selectionListId: selectionLists[0],
        value: selectedItems,
        isMulti: true,
        type: FilterTypes.SELECT,
      });

      onClose();
    }
  };

  const isSubmitDisabled =
    minSelections > 0 && selectedOptions.length < minSelections;
  const tooltipText = createMinSelectionsTooltip(minSelections);

  return (
    <>
      <SelectionListSelect
        options={sortedOptions}
        value={selectedOptions}
        maxMenuHeight={220}
        onChange={handleLocalSelectionChange}
        isMulti
      />
      <SelectionListControls
        className={styles.selectionListControls}
        onClear={handleClearSelections}
        onSubmit={handleSubmit}
        onClose={onClose}
        submitText="Update"
        isSubmitDisabled={isSubmitDisabled}
        tooltipText={tooltipText}
      />
    </>
  );
};

import { mapKeys } from 'lodash';

import { PrimaryView } from '@revelio/core';
import {
  CompensationFilters,
  CustomRoleTaxonomySelection,
} from '@revelio/data-access';
import {
  Filter,
  FilterItem,
  FilterName,
  FilterParameterKeys,
  FilterTypes,
  GqlFilterKeyMapper,
  LocalSelectionCategories,
  OtherFilterNames,
  SelectionCategories,
  UNIFIED_POSTING_SOURCE_OPTION,
  convertRoleToCustomRoleFilter,
  formatAndBreakoutFilters,
} from '@revelio/filtering';

export const transformFiltersToPayQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
  primaryView,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
  primaryView: PrimaryView;
}): CompensationFilters => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const taxonomyId = filters.find(
    (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
  )?.value as FilterItem<CustomRoleTaxonomySelection>;
  const formattedFiltersWithCustomRole = {
    ...formattedFilter,
    custom_role: { taxonomyId: taxonomyId || { default: true } },
  };

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled && primaryView === PrimaryView.COMPANY
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

export const transformFiltersToPostingsQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
  primaryView,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
  primaryView: PrimaryView;
}) => {
  const filtersWithProvider: Filter[] = [
    ...filters,
    {
      id: LocalSelectionCategories.PROVIDER,
      value: [
        {
          id: Number(UNIFIED_POSTING_SOURCE_OPTION.value),
          label: UNIFIED_POSTING_SOURCE_OPTION.label,
        },
      ],
      isMulti: true,
      label: LocalSelectionCategories.PROVIDER,
      selectionListId: LocalSelectionCategories.PROVIDER,
      type: FilterTypes.SELECT,
    },
  ] as Filter[];

  const { formattedFilter } = formatAndBreakoutFilters(filtersWithProvider, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled && primaryView === PrimaryView.COMPANY
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

import { Box, Link, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { isNumber, startCase } from 'lodash';

import {
  formatCurrency,
  formatWithCommas,
  getRiskColor,
  getRiskLabel,
} from '../helpers';
import { ParsedResume } from '../resumeTypes';
import PercentageBar from './PercentageBar';

interface ContactInformationEnrichedProps {
  data?: ParsedResume['contact_information']['en'];
  variant?: string;
}

export default function ContactInformationEnriched({
  data,
  variant,
}: ContactInformationEnrichedProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      {data?.personal_email && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Email</Text>
          <Box sx={styles['enrichedDataValue']}>
            <Link sx={styles['enrichedDataLink']} href="mailto:">
              {data?.personal_email}
            </Link>
          </Box>
        </Box>
      )}
      {data?.work_email && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Work Email</Text>
          <Box sx={styles['enrichedDataValue']}>
            <Link sx={styles['enrichedDataLink']} href="mailto:">
              {data?.work_email}
            </Link>
          </Box>
        </Box>
      )}
      {data?.phone_number && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Phone</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.phone_number}</Text>
        </Box>
      )}
      {data?.linkedin_url && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Linkedin</Text>
          <Link
            sx={styles['enrichedDataLink']}
            href={data.linkedin_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.linkedin_url}
          </Link>
        </Box>
      )}
      {data?.github_link && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Github</Text>
          <Link
            sx={styles['enrichedDataLink']}
            href={data.github_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.github_link}
          </Link>
        </Box>
      )}
      {(data?.location_city || data?.location_state) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Location</Text>
          <Text sx={styles['enrichedDataValue']}>
            {formatWithCommas([data?.location_city, data?.location_state])}
          </Text>
        </Box>
      )}
      {data?.location_metro && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Metro</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_metro)}
          </Text>
        </Box>
      )}
      {data?.location_country && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Country</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_country)}
          </Text>
        </Box>
      )}
      {isNumber(data?.total_years_of_experience) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Years of Experience</Text>
          <Text sx={styles['enrichedDataValue']}>
            {data?.total_years_of_experience}
          </Text>
        </Box>
      )}
      {data?.prestige && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>User Prestige</Text>
          <Box sx={styles['enrichedDataValue']} mt="auto" mb="auto">
            {/* prestige is a range from -1 to 1 */}
            <PercentageBar
              percentage={(JSON.parse(data?.prestige || '0') + 1) / 2}
            />
          </Box>
        </Box>
      )}
      {!!data?.salary && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Salary</Text>
          <Text sx={styles['enrichedDataValue']}>
            {formatCurrency(
              JSON.parse(`${data.salary.min || 0}`),
              data?.salary_currency || 'usd'
            )}
            -
            {formatCurrency(
              JSON.parse(`${data.salary.max || 0}`),
              data?.salary_currency || 'usd'
            )}
          </Text>
        </Box>
      )}
      {isNumber(data?.flight_risk) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Flight Risk</Text>
          <Box sx={styles['enrichedDataValue']}>
            <Text
              sx={styles['chip']}
              backgroundColor={`${getRiskColor(data?.flight_risk || 0)}.100`}
              color={`${getRiskColor(data?.flight_risk || 0)}.600`}
            >
              {getRiskLabel(data?.flight_risk || 0)}
            </Text>
          </Box>
        </Box>
      )}
      {data?.remote_suitability && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Remote</Text>
          <Text sx={styles['enrichedDataValue']}>
            {data?.remote_suitability}
          </Text>
        </Box>
      )}
    </Box>
  );
}

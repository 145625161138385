import { createContext } from 'react';

import { FeatureFlag } from '@revelio/core';

import { allFalseFlags } from './utils/flags';

export type UnleashFallbackFlags = Record<FeatureFlag, boolean>;

interface UnleashFallbackContextType {
  usingFallback: boolean;
  flags: UnleashFallbackFlags;
}

export const UnleashFallbackContext = createContext<UnleashFallbackContextType>(
  {
    usingFallback: false,
    flags: allFalseFlags(),
  }
);

import { get } from 'lodash';
import { forwardRef } from 'react';
import { combineLatest, map } from 'rxjs';
import { Client, useClient } from 'urql';

import { Item } from '../../../../engine/filters.model';
import { GET_SCHOOL_INFO } from '../../../../engine/gql-models/school.models';
import { AsyncTree, AsyncTreeProps } from '../../async-tree/async-tree';
import { SchoolItem } from '../../async-tree/async-tree.types';

interface ControlledSchoolSearchTreeProps
  extends Omit<
    AsyncTreeProps,
    'fetchResults' | 'apiEndpoint' | 'placeholderListNodes'
  > {
  selections: Record<string, SchoolItem>;
  setSelections: React.Dispatch<
    React.SetStateAction<Record<string, SchoolItem>>
  >;
}

export const ControlledSchoolSearchTree = ({
  selections,
  setSelections,
  ...props
}: ControlledSchoolSearchTreeProps) => {
  const gqlClient = useClient();

  const fetchSchoolSearchResults = (
    gqlClient: Client,
    vars: { name: string; page: number }
  ) => {
    const searchSearchQuery = gqlClient
      .query(GET_SCHOOL_INFO, vars)
      .toPromise()
      .catch((e) => {
        console.log('error:', e);
        return e;
      });

    return combineLatest({ result: searchSearchQuery });
  };

  return (
    <AsyncTree
      {...props}
      apiEndpoint={''}
      placeholderListNodes={[]}
      selections={selections}
      setSelections={setSelections}
      fetchResults={(searchString: string) => {
        return fetchSchoolSearchResults(gqlClient, {
          name: searchString,
          page: 1,
        });
      }}
      operatorsAfterQuery={map(({ result }: any) => {
        const { data } = result;

        const schoolInfo = get(data, 'schoolInfo') || [];

        return schoolInfo.map((item: Item) => ({
          id: get(item, 'id').toString(),
          item: {
            ...item,
            label: item.name,
          },
          children: [],
        }));
      })}
    />
  );
};

export const WithSchoolSearchTreeRef = (
  TreeComponent: React.FunctionComponent<ControlledSchoolSearchTreeProps>
) => {
  return forwardRef(({ ...rest }: ControlledSchoolSearchTreeProps, ref) => (
    <TreeComponent {...rest} forwardedRef={ref} />
  ));
};

export const SchoolSearchTreeRef = WithSchoolSearchTreeRef(
  ControlledSchoolSearchTree
);

import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { IconType } from 'react-icons/lib';
import { map, pipe, tap } from 'rxjs';

import {
  FeatureTrackingEvents,
  setGlobalLoaderEnableState,
} from '@revelio/core';
import {
  CalculatedEndpoint,
  DownloadEndpointMapper,
  LocalSelectionCategories,
  PlotAdditionalQueryParams,
  TransitionsDownloadFlowTypePlaceholder,
  plotDataDownloader,
} from '@revelio/filtering';
import { PlotProps } from '@revelio/layout';

export interface ActionMenuButtonProps extends ButtonProps {
  icon: IconType;
  text: string;
  disabled?: boolean;
  isDownloadButton?: boolean;
  downloadProps?: {
    endpoint: DownloadEndpointMapper;
    isGoRequest?: boolean;
  } & Pick<
    PlotProps,
    | 'brokenOutFilterIds'
    | 'additionalNonActiveFilters'
    | 'additionalOperatorsBeforeQuery'
    | 'requestMethod'
  >;
}

export function ActionMenuButton({
  icon,
  text,
  disabled,
  isDownloadButton = false,
  downloadProps,
  onClick,
  ...props
}: ActionMenuButtonProps) {
  const handleDownload = () => {
    setGlobalLoaderEnableState('DISABLE');
    if (downloadProps) {
      const operatorsBeforeQuery = pipe(
        map((params: PlotAdditionalQueryParams) => {
          const endpointPath = (params.endpoint as CalculatedEndpoint)
            .endpointPath;
          const inOrOutFilter = params.additionalFilters.find(
            (filter) => filter.id == LocalSelectionCategories.INFLOW_OR_OUTFLOW
          );
          const inflowOrOutflow = get(inOrOutFilter, 'value.id', '');

          if (endpointPath?.startsWith(`/downloads/transitions`)) {
            (params.endpoint as CalculatedEndpoint).endpointPath =
              endpointPath.replace(
                TransitionsDownloadFlowTypePlaceholder,
                inflowOrOutflow
              );
          }
          return params;
        }),
        downloadProps.additionalOperatorsBeforeQuery || tap()
      );
      plotDataDownloader({
        endpoint: downloadProps.endpoint,
        requestMethod: downloadProps.requestMethod,
        brokenOutFilterIds: downloadProps.brokenOutFilterIds,
        additionalNonActiveFilters: downloadProps.additionalNonActiveFilters,
        additionalOperatorsBeforeQuery: operatorsBeforeQuery,
        callback: () => {
          mixpanel.track(FeatureTrackingEvents.DATA_DOWNLOAD);
          setGlobalLoaderEnableState('ENABLE');
        },
        isGoRequest: downloadProps.isGoRequest,
      });
    }
  };

  return (
    <Button
      leftIcon={<Icon as={icon} boxSize={3.5} />}
      size="sm"
      variant={disabled ? 'disabledWhite' : 'white'}
      w="100%"
      justifyContent="flex-start"
      borderRadius="0"
      onClick={
        // eslint-disable-next-line no-nested-ternary
        disabled ? undefined : isDownloadButton ? handleDownload : onClick
      }
      {...props}
    >
      {text}
    </Button>
  );
}

export default ActionMenuButton;

import { kebabCase } from 'lodash';
import { useState } from 'react';

import {
  defaultOption,
  dimensionLimits,
} from '../../lib/components/download-modal/d3-chart-dimensions.config';

/**
 * Hook for handling state for download modal
 */
export const useDownloadFormState = () => {
  const [title, setTitle] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [height, setHeight] = useState<number | string>('');
  const [width, setWidth] = useState<number | string>('');
  const [transparent, setTransparent] = useState<boolean>(false);
  const [lockRatio, setLockRatio] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState<number | undefined>(undefined);

  const [isDimInvalid, setIsDimInvalid] = useState<{
    height: boolean;
    width: boolean;
  }>({ height: false, width: false });

  const [selectedSize, setSelectedSize] = useState(defaultOption);

  /**
   * On Change handler for the filename input field.
   *
   * @param e - event
   */
  const handleChangeFilename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(kebabCase(e.target.value));
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  /**
   * calculates the values required by the other dimension to maintain a set aspect ratio.
   *
   * @param val - input number (known dimension)
   * @param axis - x for width, y for height
   * @param ratio - set aspect ratio
   *
   * @returns the value corresponding to the aspect ratio, if the ratio is zero or
   * the axis input is invalid, returns -1.
   */
  const calculateValueByRatio = (
    val: number,
    axis: 'width' | 'height',
    ratio: number
  ) => {
    if (ratio === 0) {
      return -1;
    }
    // eslint-disable-next-line no-nested-ternary
    return axis === 'width'
      ? Math.floor(val / ratio)
      : axis === 'height'
        ? Math.floor(val * ratio)
        : -1;
  };

  /**
   * calculates aspect ratio from the current height and width state
   *
   * @returns current aspect ratio if width and height are valid numbers.
   * Otherwise undefined.
   */
  const handleAspectRatioChange = () => {
    return typeof width === 'number' && typeof height === 'number' && height > 0
      ? width / height
      : undefined;
  };

  /**
   * updates the other dimension to maintain aspect ratio.
   *
   * @param val - known dimension
   * @param axis - dimension name (width or height)
   * @param ratio - set aspect ratio
   *
   * @returns void
   */
  const handleOnDimensionChange = (
    val: number,
    axis: 'width' | 'height',
    ratio: number
  ) => {
    const setter = axis === 'width' ? setHeight : setWidth;

    const updatedVal = calculateValueByRatio(val, axis, ratio);

    if (updatedVal !== -1) {
      setter(updatedVal);
    }
  };

  /**
   * OnChange handler for height and width dimension input fields
   *
   * @param e - event
   * @param axis - height or width
   *
   * @returns void
   */
  const handleDimInputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    axis: 'height' | 'width'
  ) => {
    const pattern = '^[0-9]\\d*$';

    const setter =
      // eslint-disable-next-line no-nested-ternary
      axis === 'width' ? setWidth : axis === 'height' ? setHeight : undefined;

    if (!setter) {
      return;
    }

    if (e.target.value.match(pattern) || e.target.value === '') {
      const newVal = !isNaN(e.target.valueAsNumber)
        ? e.target.valueAsNumber
        : '';

      setter(newVal);

      if (
        lockRatio &&
        aspectRatio &&
        newVal !== '' &&
        validateDimensions(newVal)
      ) {
        handleOnDimensionChange(newVal, axis, aspectRatio);
      }
    }
  };

  /**
   * handles reseting of the download modal form fields
   *
   * @param isFullscreenMode - indicates whether plot is in fullscreen mode
   * @param downloadDimension - dimensions of the plot in fullscreen mode (used for auto)
   * @param options - optional modifiers
   */
  const handleReset = (
    isFullscreenMode: boolean | undefined,
    downloadDimension: { height: number; width: number },
    options?: { header?: string; resetSelection?: boolean }
  ) => {
    if (options?.header) {
      setFilename(kebabCase(options.header));
      setTitle(options.header);
    }

    if (options?.resetSelection) {
      setSelectedSize(defaultOption);
    }

    const height = isFullscreenMode
      ? Math.max(downloadDimension.height, dimensionLimits.min)
      : dimensionLimits.min;

    const width = isFullscreenMode
      ? Math.max(downloadDimension.width, dimensionLimits.min)
      : dimensionLimits.min * 1.5;

    setHeight(height);
    setWidth(width);

    setLockRatio(false);
    setIsDimInvalid({ height: false, width: false });
  };

  /**
   * on blur handler for the dimensions input field
   *
   * @param e - event
   *
   * @returns void
   */
  const handleDimInputOnBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const newVal = !isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : '';
    setIsDimInvalid((prev) => ({
      ...prev,
      width: newVal === '' || !validateDimensions(newVal),
    }));
  };

  /**
   * determine if input values are within the dimension limits
   *
   * @param val - value to check against limits
   *
   * @returns void
   */

  const validateDimensions = (val: number | string) => {
    return (
      val !== '' && +val <= dimensionLimits.max && +val >= dimensionLimits.min
    );
  };

  return {
    filename: { value: filename, setFilename, handleChangeFilename },
    title: { value: title, setTitle, handleChangeTitle },
    dimensions: {
      height,
      width,
      setHeight,
      setWidth,
      handleDimInputOnChange,
      handleDimInputOnBlur,
    },
    aspectRatio: {
      value: aspectRatio,
      setAspectRatio,
      isLocked: lockRatio,
      setLockRatio,
      handleAspectRatioChange,
    },
    transparent: {
      value: transparent,
      setTransparent,
    },
    selectedSize: {
      value: selectedSize,
      setSelectedSize,
    },
    handleReset,
    validators: {
      isDimInvalid,
      setIsDimInvalid,
      validateDimensions,
    },
  };
};

import { UseToastOptions, useToast } from '@chakra-ui/react';
import { emitOnce } from '@ngneat/elf';
import { every, filter, isEqual } from 'lodash';
import { NavigateFunction } from 'react-router';

import { PagePaths, write } from '@revelio/core';
import { PipelineType } from '@revelio/data-access';

import {
  getDatasetColumnDefaults,
  hasSelectedCompany,
  isIndustrySupportedDataset,
} from '../columns/columns.model';
import { getCompanyInfoCompanyMapping } from '../columns/configurations/company-info';
import { getNoCompanyDefaultsByDataset } from '../columns/configurations/no-company';
import { DEFAULT_INDUSTRY_PIPELINE_COLUMNS } from '../columns/configurations/workforce-dynamics';
import { applyPipelineConfiguration } from '../columns/saved-pipeline-configurations/saved-pipeline-configuration-select-helpers';
import { DeliverablePaths } from '../deliverables-page';
import { Deliverable, isCompanyInfoPipeline } from '../deliverables.model';
import {
  clearCompanyInformation,
  deliverablesStore,
  getInputRefs,
  isDuplicatedDeliverable,
  updateDraftDeliverable,
} from '../deliverables.repository';
import { clearSelectCompanyState } from './company-selection-actions';

type ToastType = ReturnType<typeof useToast>;

export const handleSubmit = ({
  skip,
  draftDeliverables,
  datasetsWithSelectedCompanies,
  selectedPipelineTypes,
  navigate,
  toast,
}: {
  skip: boolean;
  draftDeliverables: Deliverable[];
  datasetsWithSelectedCompanies: PipelineType[];
  selectedPipelineTypes: PipelineType[];
  navigate: NavigateFunction;
  toast: ToastType;
}) => {
  const companyInfoDataset = draftDeliverables.find((del) =>
    isCompanyInfoPipeline(del.pipeline.pipeline_type as PipelineType)
  );
  const hasCompanyInfoDataset = !!companyInfoDataset;
  const hasOnlyIndustrySupportedPipelinesAndCompanyInfo =
    every(
      filter(
        draftDeliverables,
        (del) =>
          !isCompanyInfoPipeline(del.pipeline.pipeline_type as PipelineType)
      ),
      (del) =>
        isIndustrySupportedDataset(del.pipeline.pipeline_type as PipelineType)
    ) && hasCompanyInfoDataset;

  const CompanyRefWarningToastOptions = {
    title:
      'Company reference dataset is not applicable to this deliverable request',
    description:
      'Either select companies or de-select the dataset from Dataset Selection',
    status: 'warning',
    variant: 'subtle',
    duration: 5000,
    isClosable: true,
    position: 'top-right',
    containerStyle: { width: '360px' },
  } as UseToastOptions;

  if (skip) {
    if (hasOnlyIndustrySupportedPipelinesAndCompanyInfo) {
      return toast(CompanyRefWarningToastOptions);
    }
    clearCompanySelectionForIndustrySupportedDataset({ draftDeliverables });
  } else if (hasSelectedCompany()) {
    emitOnce(() => {
      const inputRefs = deliverablesStore.query(getInputRefs);
      datasetsWithSelectedCompanies.forEach(
        (pipelineWithCompanyInformation) => {
          const pipelinesToSetCompany = draftDeliverables.filter(
            (del) =>
              del.pipeline.pipeline_type === pipelineWithCompanyInformation
          ) as Deliverable[];

          pipelinesToSetCompany.forEach((deliverable) => {
            updateDraftDeliverable(
              deliverable.id,
              write<Deliverable>((state) => {
                state.company_reference = inputRefs.company_reference;
                state.pipeline_input = inputRefs.pipeline_input;
                state.company_sets = inputRefs.company_sets;
              })
            );

            // This is in case it's been set to NO_COMPANY_DEFAULTS when wf_dynam previously had company unselected and is now selected again
            const hasIndustryColumnSelected =
              deliverable.pipeline.columns?.find((col) =>
                DEFAULT_INDUSTRY_PIPELINE_COLUMNS.menuItems
                  .map((c) => c.id)
                  .includes(col)
              );
            if (
              isIndustrySupportedDataset(
                deliverable.pipeline.pipeline_type as PipelineType
              ) &&
              hasIndustryColumnSelected
            ) {
              // do not use setColumnsToDefault because it will keep the industry column set
              updateDraftDeliverable(
                deliverable.id,
                write<Deliverable>((state) => {
                  state.pipeline.columns = getDatasetColumnDefaults({
                    entityId: deliverable.id,
                  });
                })
              );
            }
          });
        }
      );

      if (hasCompanyInfoDataset) {
        const companyInfoCompanyMapping = getCompanyInfoCompanyMapping({
          deliverables: draftDeliverables,
          inputRefs,
          datasetsWithSelectedCompanies,
        });
        updateDraftDeliverable(
          companyInfoDataset.id,
          write<Deliverable>((state) => {
            state.company_reference =
              companyInfoCompanyMapping.company_reference;
            state.pipeline_input = companyInfoCompanyMapping.pipeline_input;
            state.company_sets = companyInfoCompanyMapping.company_sets;
          })
        );
      }
    });
  } else {
    const onlyCompanyInfoIsSelected = isEqual(selectedPipelineTypes, [
      PipelineType.CompanyInfo,
    ]);

    if (
      (!onlyCompanyInfoIsSelected &&
        datasetsWithSelectedCompanies.filter(
          (pipeline) => !isCompanyInfoPipeline(pipeline)
        ).length) ||
      onlyCompanyInfoIsSelected
    ) {
      return toast({
        title: 'Company selection is required',
        description: 'Please select a list of companies for you deliverable',
        status: 'warning',
        variant: 'subtle',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        containerStyle: { width: '360px' },
      });
    } else if (hasOnlyIndustrySupportedPipelinesAndCompanyInfo) {
      return toast(CompanyRefWarningToastOptions);
    }
  }

  return navigate(`/${PagePaths.DELIVERABLES}/${DeliverablePaths.COLUMNS}`);
};

export const clearCompanySelectionForIndustrySupportedDataset = ({
  draftDeliverables,
}: {
  draftDeliverables: Deliverable[];
}) => {
  emitOnce(() => {
    draftDeliverables.forEach((deliverable) => {
      const entityId = deliverable.id;
      clearCompanyInformation(entityId);
      const pipelineType = deliverable.pipeline.pipeline_type;

      if (
        pipelineType &&
        isIndustrySupportedDataset(pipelineType as PipelineType)
      ) {
        if (!isDuplicatedDeliverable()) {
          applyPipelineConfiguration({
            entityId: deliverable.id,
            columns: getNoCompanyDefaultsByDataset({
              entityId: deliverable.id,
            }),
          });
        }
        clearSelectCompanyState(deliverable.id); // company is mandatory at this point but needs to be removed
      }
    });
  });
};

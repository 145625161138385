import { max, min, scaleBand, scaleLinear, select, selectAll } from 'd3';
import { axisLeft } from 'd3-axis';
import { format as d3Format } from 'd3-format';
import { get, isUndefined } from 'lodash';

import { colors } from '../../utilities/colors.js';
import { getCornerRadius, roundedRect } from '../../utilities/corner-radius.js';
import { generateTextWidth } from '../../utilities/text-width.js';

// TODO: Need to finish documenting all parameters for these functions.

/**
 * calculates the width of the value label for the longest bar
 *
 * @param {string} formatCustomString - suffix to add
 * @param {string} format - formatter option for d3-format
 * @param {*} data
 * @param {number} fontSize
 * @param {string} fontFamily
 * @param {number} fontSizePadding - increases font size for calculationss to provide extra space
 * @param {number} padding
 * @param {number} padGuard - extra padding to prevent text cutoff
 *
 * @returns width of text label
 */
export const calcMaxRightLabelWidth = (
  formatCustomString,
  preformatter,
  format,
  customFormatter,
  data,
  fontSize,
  fontFamily,
  fontSizePadding = 0,
  padding = 0,
  padGuard = 0
) => {
  // get the text label for the longest bar (for calculating right margin)
  let maxTextLabel;

  const formattedString = d3Format(format)(
    max(
      data.map((d) => {
        const preformatted = preformatter && d3Format(preformatter)(d.value);
        const customFormatted = customFormatter && customFormatter(d.value);

        return customFormatted || preformatted || d.value;
      })
    )
  );

  formatCustomString
    ? (maxTextLabel = formattedString + formatCustomString)
    : (maxTextLabel = formattedString);

  const maxTextLabelWidth = generateTextWidth(
    maxTextLabel,
    `${fontSize + fontSizePadding}px ${fontFamily}`
  );

  return maxTextLabelWidth + padding + padGuard;
};

/**
 * calculates the width of the longest y axis label
 *
 */
export const calcMaxLeftLabelWidth = (
  data,
  fontSize,
  fontFamily,
  fontSizePadding = 0
) => {
  let maxTextWidth = 0;

  // reduce left margin if the longest y axis label is shorter than the current left margin
  let textWidth;

  data.forEach((d) => {
    textWidth = generateTextWidth(
      d.metadata.shortName,
      `${fontSize + fontSizePadding}px ${fontFamily}`
    );
    if (textWidth > maxTextWidth) maxTextWidth = textWidth;
  });

  return maxTextWidth;
};

/**
 * create, append and translate chart svg
 *
 * @param {*} node - container to append svg to
 * @param {*} svgWidth
 * @param {*} svgHeight
 * @param {*} name - class name for svg
 * @param {*} translateX - x offset for the chart
 * @param {*} translateY - y offset for the chart
 *
 * @returns array of d3 selections, where the first element is the outer svg and
 * the second element is the chart.
 */
export const setupSVGAndChart = (
  node,
  svgWidth,
  svgHeight,
  name,
  translateX,
  translateY
) => {
  // setup svg node
  const svg = select(node).append('svg');
  svg
    .attr('width', svgWidth)
    .attr('height', svgHeight)
    .attr('class', `svg-${name}`);
  const chart = svg.append('g');
  chart.attr('transform', `translate(${translateX}, ${translateY})`);

  return [svg, chart];
};

/**
 * define and returns a linear scale for x axis
 */
export const setupXScale = (ttType, data, innerWidth) => {
  let xScale;

  if (ttType === 'sentimentRatings' && data.length > 1) {
    xScale = scaleLinear()
      .domain([
        min(data.map((d) => d.value - 0.01)),
        max(data.map((d) => d.value)),
      ])
      .range([0, innerWidth])
      .nice();
  } else {
    xScale = scaleLinear()
      .domain([
        Math.min(
          0,
          min(data, (d) => d.value)
        ),
        Math.max(
          0,
          max(data, (d) => d.value)
        ),
      ])
      .range([0, innerWidth]);
  }

  return xScale;
};

/**
 * define and return a band scale for y axis
 */
export const setupYScale = (
  data,
  metaValue,
  maxStep,
  innerPad,
  innerHeight
) => {
  let yAxisRange = [0, innerHeight];
  // readjust the y axis range to make the chart height (sum of all steps - height of bars + innerpaddings) dependent on # bars and vertically centered
  let chartHeight = data.length * maxStep;

  if (chartHeight < innerHeight) {
    yAxisRange = [
      innerHeight / 2 - chartHeight / 2,
      innerHeight / 2 + chartHeight / 2,
    ];
  }

  return scaleBand()
    .domain(data.map((d) => d.metadata[metaValue]))
    .range(yAxisRange)
    .paddingInner(innerPad);
};

/**
 * draws bar chart bars
 */

export const drawBars = (chart, data, name, scale, style, eventHandlers) => {
  const { ttType, xScale, yScale, metaValue } = scale;
  const { chartSize, singleColor, colorIndex, color } = style;
  const { mouseOut, mouseOver } = eventHandlers;
  const allValues = data.map((v) => v.value);
  const maxValue = max(allValues);
  chart
    .selectAll('.bar')
    .data(data)
    .join('path')
    .attr('class', 'bar')
    .attr('data-testid', 'horizontal-bar-chart-bar')
    .classed(`${name}-bar`, true)
    .attr('d', (d) => {
      return roundedRect(
        ttType === 'sentimentRatings' && data.length > 1
          ? xScale(xScale.ticks()[0])
          : xScale(Math.min(0, d.value)),
        yScale(d.metadata[metaValue]),
        // eslint-disable-next-line no-nested-ternary
        d.value === 0
          ? xScale(maxValue)
          : ttType === 'sentimentRatings' && data.length > 1
            ? Math.abs(xScale(d.value) - xScale(xScale.ticks()[0]))
            : Math.abs(xScale(d.value) - xScale(0)),
        yScale.bandwidth(),
        getCornerRadius(d, true, chartSize)
      );
    })
    .attr('fill', function (d) {
      if (d.value === 0) {
        return 'url(#diagonal-stripe-4) #fff';
      }

      //TODO: change this to accept specifc colors
      if (d.metadata.isPrimary) {
        return '#5BD992';
      }

      if (d.metadata.isPrimary === false) {
        return colors[0];
      }

      return singleColor ? colors[colorIndex] : color(d.metadata[metaValue]);
    })
    .attr('cursor', 'pointer')
    .on('mouseover', mouseOver)
    .on('mouseout', mouseOut);
};

/**
 * draws the y axis labels
 */
export const drawBarValueLabels = (chart, data, formatConfigs, scale) => {
  const {
    formatCustomString,
    preformatter,
    format,
    customFormatter,
    fontSize,
  } = formatConfigs;
  const { xScale, yScale, metaValue } = scale;
  return chart
    .selectAll('text.bar')
    .data(data)
    .join('text')
    .attr('id', 'text')
    .attr('data-testid', 'horizontal-bar-chart-value-label')
    .attr('x', (d) => (d.value < 0 ? xScale(0) : xScale(d.value)))
    .attr('y', (d) => yScale(d.metadata[metaValue]) + yScale.bandwidth() / 2)
    .attr('text-anchor', 'start')
    .attr('alignment-baseline', 'middle')
    .attr('dx', '0.4em')
    .attr('dy', '0.1em')
    .attr('font-family', 'Source Sans Pro, sans-serif')
    .style('fill', '#636d7e') //'#818ea6')
    .style('font-size', `${fontSize}px`)
    .text((d) => {
      if (d.value === 0) {
        return null;
      }

      const hasCustomFormatter = !isUndefined(customFormatter);

      if (hasCustomFormatter) {
        return customFormatter(d.value);
      }

      const preformatted = !isUndefined(preformatter)
        ? d3Format(preformatter)(d.value)
        : d.value;

      const isLessThanTwoWidth = preformatted?.toString()?.length < 2;

      const removeDecimalOnSingleDigitInteger =
        format === '.2s' && isLessThanTwoWidth && preformatter === 'd';

      const formattedString = removeDecimalOnSingleDigitInteger
        ? preformatted
        : d3Format(format)(preformatted);

      return formatCustomString
        ? formattedString + formatCustomString
        : formattedString;
    });
};

export const setupYAxis = (data, marginLeft, scale, style) => {
  const { xScale, yScale, metaValue } = scale;
  const { fontSize, fontFamily, maxTextWidth, textAboveBar } = style;
  return (
    axisLeft()
      .scale(yScale)
      .tickSizeOuter(0)
      // add ellipses to end of long bar names -
      // 12 letter cutoff for medium charts &
      // 10 for small & 50 for bars where y axis label is going above bar
      .tickFormat((d) => {
        let cutoff;
        if (!textAboveBar) {
          if (maxTextWidth + 5 <= marginLeft) {
            return d;
          } else if (
            generateTextWidth(d, `${fontSize}px ${fontFamily}`) + 5 <=
            marginLeft
          ) {
            return d;
          } else {
            cutoff = 1;
            let sliced = d.slice(0, -cutoff) + '...';
            while (
              generateTextWidth(sliced, `${fontSize}px ${fontFamily}`) + 12 >
              marginLeft
            ) {
              cutoff += 1;
              sliced = d.slice(0, -cutoff) + '...';
            }
            return sliced;
          }
        } else {
          if (
            generateTextWidth(d, `${11}px ${fontFamily}`) >
            xScale(data.filter((r) => r.metadata[metaValue] === d)[0].value)
          ) {
            cutoff = 1;
            let sliced = d.slice(0, -cutoff) + '...';
            while (
              generateTextWidth(sliced, `${11}px ${fontFamily}`) >
              xScale(data.filter((r) => r.metadata[metaValue] === d)[0].value)
            ) {
              if (sliced.length - 3 <= 50) break; // reached min 50 char limit (excluding elipses)
              cutoff += 1;
              sliced = d.slice(0, -cutoff) + '...';
            }
            return sliced;
          } else {
            return d;
          }
        }
      })
  );
};

export const drawYLabels = (
  chart,
  textAboveBar,
  yScale,
  yAxisLeft,
  chartSize,
  fontSize
) => {
  chart
    .append('g')
    .attr('data-testid', 'y-axis')
    .attr(
      'transform',
      // eslint-disable-next-line no-nested-ternary
      textAboveBar
        ? `translate(10, ${-yScale.bandwidth() - 1.5})`
        : `translate(5, 0)`
    )
    .style('color', '#636d7e') //'#818ea6')
    .style('font-size', `${fontSize}px`)
    .style('text-anchor', textAboveBar ? 'start' : 'end')
    .call(yAxisLeft)
    .call((g) => g.selectAll('.domain').remove())
    .call((g) => g.selectAll('line').remove());
};

export const createTooltip = (node, name, tooltipHeight, chartPosition) => {
  const isPositionRight = chartPosition === 'right';
  const className = isPositionRight
    ? 'tooltip-bar-chart-horizontal-right'
    : 'tooltip-bar-chart-horizontal';
  const dataTestId = isPositionRight
    ? 'horizontal-bar-chart-tooltip-right'
    : 'horizontal-bar-chart-tooltip';

  return select(node)
    .append('div')
    .style('height', tooltipHeight + 'px')
    .style('opacity', 0)
    .style('pointer-events', 'none')
    .style('display', null)
    .attr('class', className)
    .attr('data-testid', dataTestId)
    .classed(`tooltip-${name}`, true);
};

export const createTooltipMouseOver = (
  name,
  dims,
  chartPosition,
  scale,
  ttConfig
) => {
  const { xScale, yScale, metaValue } = scale;

  const {
    tooltip,
    tooltipFormat,
    tooltipHeight,
    tooltipMetaValue,
    ttType,
    tooltipFormatCustomString,
    tooltipCustomValue,
  } = ttConfig;

  return (event, d) => {
    if (d.value === 0) {
      return null;
    }
    // approximate width of tooltip for changing tt position when chartPosition="right"
    var tooltipWidthApprox = 0;
    if (chartPosition === 'right' && !tooltipFormatCustomString) {
      tooltipWidthApprox =
        24 + // left & right tt padding = 12px
        4 * // approx character width = 4px
          max([
            d.metadata.longName.length,
            d3Format(tooltipFormat)(d.value).length,
          ]);
    } else if (chartPosition === 'right' && tooltipFormatCustomString) {
      tooltipWidthApprox =
        24 +
        4 *
          max([
            d.metadata.longName.length,
            d3Format(tooltipFormat)(d.value).length +
              1 + //space
              tooltipFormatCustomString.length,
          ]);
    }
    tooltip
      .style('opacity', 1)
      .style('display', null)
      .style(
        'transform',
        chartPosition === 'right'
          ? `translate(${dims.margin.left - tooltipWidthApprox / 2}px, ${
              dims.margin.top +
              yScale(d.metadata[metaValue]) -
              tooltipHeight -
              7
            }px)`
          : `translate(${dims.margin.left + xScale(d.value) + 8}px, ${
              dims.margin.top +
              yScale(d.metadata[metaValue]) -
              tooltipHeight / 2 +
              yScale.bandwidth() / 2
            }px)`
      )
      .html(
        // eslint-disable-next-line no-nested-ternary
        ttType === 'sentimentRatings'
          ? `<div class="tt-title-bar-chart-horizontal"> <b>${
              d.metadata[tooltipMetaValue]
            }: ${d3Format(tooltipFormat)(
              d.value
            )}</b></div>(Sample Size: ${d3Format(',')(d.metadata.count)})`
          : // eslint-disable-next-line no-nested-ternary
            ttType === 'skillsSnapshot'
            ? `<div class="tt-title-bar-chart-horizontal"> <b>${
                d.metadata[tooltipMetaValue]
              }</b></div>Count: ${d3Format(tooltipFormat)(
                d.metadata.count
              )}<br>Share: ${d3Format('.2%')(d.value)}`
            : tooltipFormatCustomString
              ? `<div class="tt-title-bar-chart-horizontal"> <b>${
                  d.metadata[tooltipMetaValue]
                }</b></div>${d3Format(tooltipFormat)(
                  d.value
                )} ${tooltipFormatCustomString}`
              : `<div class="tt-title-bar-chart-horizontal"> <b>${
                  d.metadata[tooltipMetaValue]
                }</b></div>${d3Format(tooltipFormat)(
                  get(d, tooltipCustomValue, d.value)
                )}`
      );

    selectAll(`.${name}-bar`)
      .filter((row) => row.metadata[metaValue] != d.metadata[metaValue])
      .style('opacity', 0.5);
  };
};

import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { useKibanaLogger } from '@revelio/layout';

import { getUnleashFlagsFromDashMeta } from './unleash-fallback-api';
import {
  UnleashFallbackContext,
  UnleashFallbackFlags,
} from './unleash-fallback.context';
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from './utils/fallback-flag-storage';

export const UnleashFallbackProvider = ({
  useFallback,
  children,
}: {
  useFallback: boolean;
  children: React.ReactNode;
}) => {
  const { kibanaLogger } = useKibanaLogger();
  const { data, error } = useQuery<UnleashFallbackFlags>({
    queryKey: ['unleash-flags'],
    queryFn: async () => {
      const flags = await getUnleashFlagsFromDashMeta();
      saveToLocalStorage(flags); // Save successful response to localStorage
      return flags;
    },
    enabled: useFallback,
    retry: 3,
    staleTime: 0,
    refetchOnMount: true,
    refetchInterval: 60000,
    placeholderData: loadFromLocalStorage(), // Load from localStorage as initial data
  });

  useEffect(() => {
    if (kibanaLogger && error) {
      kibanaLogger?.error(`UnleashFallbackProvider: ${error}`);
    }
  }, [kibanaLogger, error]);

  return (
    <UnleashFallbackContext.Provider
      value={{
        usingFallback: useFallback,
        flags: data ?? loadFromLocalStorage(),
      }}
    >
      {children}
    </UnleashFallbackContext.Provider>
  );
};

import { Box } from '@chakra-ui/react';

import { TabProps } from '../tab/tab';

export interface TabsPanelProps {
  children: React.ReactElement<TabProps>[];
  tab: string;
}

export function TabsPanel(props: TabsPanelProps) {
  return (
    <>
      {props.children.map((child, index) => {
        if (child.props.name === props.tab) {
          return (
            <Box height="100%" width="100%" key={index}>
              {child}
            </Box>
          );
        }
        return null;
      })}
    </>
  );
}

export default TabsPanel;

import { UnleashFallbackFlags } from '../unleash-fallback.context';
import { allFlags } from './flags';

const STORAGE_KEY = 'unleash-fallback-flags';

// Helper functions to handle localStorage
export const saveToLocalStorage = (flags: UnleashFallbackFlags) => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(flags));
  } catch (e) {
    console.error('Failed to save flags to localStorage:', e);
  }
};

export const loadFromLocalStorage = (): UnleashFallbackFlags => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      const parsed = JSON.parse(stored);
      // Ensure all flags are present, fall back to false if missing
      return allFlags().reduce((acc, flag) => {
        acc[flag] = parsed[flag] ?? false;
        return acc;
      }, {} as UnleashFallbackFlags);
    }
  } catch (e) {
    console.error('Failed to load flags from localStorage:', e);
  }
  return allFlags().reduce((acc, flag) => {
    acc[flag] = false;
    return acc;
  }, {} as UnleashFallbackFlags);
};

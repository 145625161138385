import { Icon } from '@chakra-ui/react';
import React from 'react';

const CompletedStatusIcon = () => {
  return (
    <Icon viewBox="0 0 50 50" fill="none" boxSize={16} mb={0}>
      <path
        d="M43.53 28.0533C43.4228 28.4534 43.6602 28.8646 44.0603 28.9718C44.4604 29.079 44.8717 28.8416 44.9789 28.4415L43.53 28.0533ZM33.6564 5.98432L33.3503 6.66901L33.6564 5.98432ZM9.2648 13.0866C9.34968 13.4921 9.74715 13.7519 10.1526 13.667L16.7593 12.2839C17.1648 12.199 17.4246 11.8015 17.3397 11.3961C17.2549 10.9907 16.8574 10.7308 16.452 10.8157L10.5793 12.0452L9.34979 6.17251C9.26491 5.76708 8.86744 5.50723 8.46201 5.59211C8.05659 5.67699 7.79674 6.07446 7.88162 6.47988L9.2648 13.0866ZM44.9789 28.4415C46.1947 23.9042 45.7534 19.0822 43.7344 14.8409L42.3801 15.4856C44.2457 19.4048 44.6534 23.8606 43.53 28.0533L44.9789 28.4415ZM43.7344 14.8409C41.7154 10.5996 38.2508 7.21677 33.9625 5.29962L33.3503 6.66901C37.3129 8.44055 40.5144 11.5664 42.3801 15.4856L43.7344 14.8409ZM33.9625 5.29962C29.6742 3.38248 24.843 3.05654 20.3361 4.38031L20.7588 5.81951C24.9234 4.59628 29.3877 4.89747 33.3503 6.66901L33.9625 5.29962ZM20.3361 4.38031C15.8291 5.70408 11.9415 8.59088 9.37114 12.5226L10.6266 13.3434C13.0018 9.7103 16.5941 7.04275 20.7588 5.81951L20.3361 4.38031Z"
        fill="#2D426A"
      />
      <path
        d="M42.1622 32.1054C42.5623 31.9982 42.9736 32.2356 43.0808 32.6357L44.8278 39.1557C44.935 39.5558 44.6976 39.9671 44.2975 40.0743C43.8974 40.1815 43.4861 39.9441 43.3789 39.544L41.826 33.7484L36.0305 35.3013C35.6304 35.4085 35.2191 35.1711 35.1119 34.771C35.0047 34.3709 35.2421 33.9596 35.6422 33.8524L42.1622 32.1054ZM19.12 41.0783L19.3877 40.3777L19.12 41.0783ZM6.57097 19.2828C6.65585 18.8773 7.05332 18.6175 7.45874 18.7024C7.86417 18.7872 8.12402 19.1847 8.03914 19.5901L6.57097 19.2828ZM43.0059 33.2048C40.6572 37.2729 36.9355 40.3706 32.5088 41.942L32.007 40.5284C36.0975 39.0763 39.5365 36.2139 41.7068 32.4548L43.0059 33.2048ZM32.5088 41.942C28.0821 43.5134 23.2402 43.4555 18.8523 41.7789L19.3877 40.3777C23.4424 41.927 27.9165 41.9804 32.007 40.5284L32.5088 41.942ZM18.8523 41.7789C14.4644 40.1022 10.8178 36.9165 8.56691 32.7936L9.88349 32.0748C11.9634 35.8846 15.3331 38.8284 19.3877 40.3777L18.8523 41.7789ZM8.56691 32.7936C6.31606 28.6706 5.60841 23.8804 6.57097 19.2828L8.03914 19.5901C7.14968 23.8386 7.80359 28.265 9.88349 32.0748L8.56691 32.7936Z"
        fill="#2D426A"
      />
      <path
        d="M18.0858 23.3379L23.5858 28.8379L34.0858 18.3379"
        stroke="#3BCE7B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default CompletedStatusIcon;

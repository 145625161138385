import { startCase } from 'lodash';

import { D3ChartNames } from '@revelio/d3';
import { FormatType, VolumeChart, VolumeChartData } from '@revelio/replots';

import { PlotCard, PlotCardProps } from '../../../shared/components';
import { convertVolumeChartToPivotSheet } from '../../../shared/components/plot-card/convert-to-sheet';
import { downloadData } from '../../../shared/components/plot-card/download-plot-data';

export interface MainPostingsVolumeChartCardProps extends PlotCardProps {
  data: VolumeChartData[];
  dimensionName: string;
  entityNames?: string[];
  loading?: boolean;
}

export const MainPostingsVolumeChartCard = ({
  data,
  dimensionName,
  entityNames,
  loading,
  title,
  info,
  infoPlacement,
  menu,
}: MainPostingsVolumeChartCardProps) => {
  return (
    <PlotCard
      title={title}
      info={info}
      infoPlacement={infoPlacement}
      menu={menu}
      onExport={() => {
        const exportData = convertVolumeChartToPivotSheet({
          data,
          dimensionName: startCase(dimensionName),
          entityName: entityNames?.[0] || '',
        });
        downloadData(exportData, 'Postings', {
          skipHeader: true,
          mergeHeaderCells: true,
        });
      }}
      plotShare={{
        data: data,
        chartConfig: {
          chartType: D3ChartNames.ReplotsVolumeChart,
          chartProps: {
            format: FormatType.INTEGER,
            dateFormat: 'day',
          },
        },
      }}
    >
      <VolumeChart
        data={data}
        format={FormatType.INTEGER}
        dateFormat="day"
        loading={loading}
      />
    </PlotCard>
  );
};

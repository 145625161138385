export enum SkillLevel {
  SkillK75 = 'skill_k75',
  SkillK700 = 'skill_k700',
  SkillK3000 = 'skill_k3000',
}

export type SkillFilter = {
  skillLevel: SkillLevel;
  id: number;
};

/** Filters with Chip are OR'd together */
type SkillChip = SkillFilter[];

/** Skill Chips are AND'd together */
export type SkillFilterState = SkillChip[];

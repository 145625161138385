import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { GroupBase, Select } from 'chakra-react-select';
import { isNil } from 'lodash';
import { Control, useController } from 'react-hook-form';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useQuery } from 'urql';

import { User, graphql } from '@revelio/data-access';

import AdminClientAdd from '../admin-client-add';
import { UserFormValues } from '../user-form';

export type CurrencyOption = {
  id: string;
  label: string;
  value: string;
  code: string;
  rate: number;
  record_date: string;
  effective_date: string;
};

const GetCurrencySelectionListQuery = graphql(`
  query CurrencySelectionList {
    selectionList {
      currency {
        id
        code
        name
        rate
        record_date
        effective_date
      }
    }
  }
`);

// TODO: placeholder id for now, replace value when entity added to selection list
const defaultCurrency = {
  id: '176',
  label: 'US-Dollar',
  value: 'US-Dollar',
  code: 'USD',
  rate: 1,
  record_date: '2024-12-31',
  effective_date: '2024-12-31',
};

const popularCurrencyNames = [
  'US-Dollar',
  'Canada-Dollar',
  'Euro Zone-Euro',
  'United Kingdom-Pound',
];

const currencyDataToOption = (
  currencyData: User['currency_data']
): CurrencyOption | null => {
  if (!currencyData) return null;

  const { id, name, code, rate, record_date, effective_date } = currencyData;
  if (!id || !name || !code || isNil(rate) || !record_date || !effective_date) {
    return null;
  }

  return {
    id,
    label: name,
    value: name,
    code,
    rate,
    record_date,
    effective_date,
  };
};

export const getCurrencyOption = (
  currencyData: User['currency_data']
): CurrencyOption => {
  const currencyOption = currencyDataToOption(currencyData);
  if (!currencyOption) return defaultCurrency;

  return currencyOption;
};

interface Props {
  control: Control<UserFormValues, object>;
  defaultValue: CurrencyOption;
}

const normalizeString = (str: string) =>
  str.toLowerCase().replace(/[^\w\s]/g, '');

const filterCurrencyOption = (
  option: FilterOptionOption<CurrencyOption>,
  inputValue: string
) => {
  const searchValue = normalizeString(inputValue);
  const optionValue = normalizeString(option.data.value);
  const optionCode = normalizeString(option.data.code);

  return optionValue.includes(searchValue) || optionCode.includes(searchValue);
};

export function AdminUserCurrencySelect({ control, defaultValue }: Props) {
  const [{ data: currencySelectionList, fetching }] = useQuery({
    query: GetCurrencySelectionListQuery,
  });

  const selectOptions =
    currencySelectionList?.selectionList?.currency
      ?.reduce<CurrencyOption[]>((acc, currency) => {
        const currencyOption = currencyDataToOption(currency);
        if (currencyOption) acc.push(currencyOption);
        return acc;
      }, [])
      ?.sort((a, b) => {
        // Sort popular currencies to the top
        const aIndex = popularCurrencyNames.indexOf(a.label);
        const bIndex = popularCurrencyNames.indexOf(b.label);

        if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;

        // sort alphabetically by name
        return a.label.localeCompare(b.label);
      }) ?? [];

  const {
    field: { onChange, onBlur, ref, name, value },
    fieldState: { error },
  } = useController({
    name: 'currency',
    control,
    defaultValue,
    rules: { required: 'Currency is required.' },
  });

  const { isOpen, onClose } = useDisclosure();

  return (
    <FormControl id="currency" isInvalid={!!error?.message}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        Currency
      </FormLabel>

      <Flex>
        <Box style={{ flexGrow: 1 }}>
          <Select<CurrencyOption, false, GroupBase<CurrencyOption>>
            menuPlacement="top"
            name={name}
            value={value}
            options={selectOptions}
            isLoading={fetching}
            placeholder="Select a currency..."
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            styles={{ control: (styles) => ({ ...styles, flexGrow: 1 }) }}
            filterOption={filterCurrencyOption}
          />
        </Box>
        <AdminClientAdd isOpen={isOpen} onClose={onClose} />
      </Flex>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

import { PipelineColumnType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';

import { addDefaultIsSelectedToSubMenu } from './helpers';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_RATING_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  STANDARD_COMPANY_COLUMN,
  ULTIMATE_PARENT_COMPANY_COLUMN,
} from './shared';

export const INDIVIDUAL_REVIEWS_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: null,
    columns: [
      STANDARD_COMPANY_COLUMN,
      {
        ...DEFAULT_GEO_PIPELINE_COLUMNS,
        menuItems: [
          ...addDefaultIsSelectedToSubMenu({
            columnSubMenu: DEFAULT_GEO_PIPELINE_COLUMNS,
          }).menuItems,
          {
            id: PipelineColumnType.LocationRaw,
            label: 'Raw Location',
          },
        ],
      },
      {
        ...DEFAULT_ROLE_PIPELINE_COLUMNS,
        menuItems: [
          {
            id: PipelineColumnType.JobCategory,
            label: 'Role k7 (Job Category)',
          },
          { id: PipelineColumnType.MappedRole, label: 'Role k1500' },
          {
            id: PipelineColumnType.JobTitleRaw,
            label: 'Raw Title',
            defaultIsSelected: true,
          },
        ],
      },
      {
        id: PipelineColumnType.ReviewId,
        label: 'Review ID',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewDateTime,
        label: 'Review Date & Time',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewLanguageId,
        label: 'Review Language ID',
      },
      {
        id: PipelineColumnType.ReviewIscovid19,
        label: 'Review Is Covid19',
      },
      {
        id: PipelineColumnType.ReviewerCurrentJob,
        label: 'Reviewer Current Job',
      },
      {
        id: PipelineColumnType.ReviewerEmploymentStatus,
        label: 'Reviewer Employment Status',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewerJobEndingYear,
        label: 'Reviewer Job Ending Year',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewerLengthOfEmployment,
        label: 'Reviewer Length of Employment',
        defaultIsSelected: true,
      },
      ...DEFAULT_RATING_PIPELINE_COLUMNS,
      {
        id: PipelineColumnType.ReviewSummary,
        label: 'Summary',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewAdvice,
        label: 'Advice',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewPros,
        label: 'Pros',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewCons,
        label: 'Cons',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewCountHelpful,
        label: 'Count Helpful',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.ReviewCountNotHelpful,
        label: 'Count Not Helpful',
        defaultIsSelected: true,
      },
      ULTIMATE_PARENT_COMPANY_COLUMN,
    ],
  },
];

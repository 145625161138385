import { useEffect } from 'react';

import { IFrameMessageTypes } from './constants';
import { OnMessage, listenForMessages } from './utils';

export const useListenForIFrameMessages = (
  messageType: IFrameMessageTypes,
  onMessage: OnMessage
) => {
  useEffect(() => {
    return listenForMessages({
      onMessage,
      types: [messageType],
    });
  }, [messageType, onMessage]);
};

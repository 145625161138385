import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import {
  Button,
  ButtonGroup,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
} from '@chakra-ui/react';
import { getEntity, updateAllEntities } from '@ngneat/elf-entities';
import { Select } from 'chakra-react-select';
import { compact, omit, startCase, upperCase } from 'lodash';
import { useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';

import { useIsRevelioAdmin } from '@revelio/auth';
import { S3Delivery, S3Format } from '@revelio/data-access';

import { Deliverable } from '../deliverables.model';
import { deliverablesStore } from '../deliverables.repository';
import { useClientDataBuilderConfig } from '../useClientDataBuilderConfig';
import { DeliverySelection } from './delivery.model';

const AWSS3ConfigurationForm = ({
  entityId,
  onClose,
}: {
  entityId: Deliverable['id'];
  onClose: (uncheckAdding?: boolean) => () => void;
}) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const clientDataBuilderConfig = useClientDataBuilderConfig();

  const existingSnowflakeConfig = deliverablesStore.query(
    getEntity(entityId)
  )?.s3_delivery;
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<S3Delivery>({
    defaultValues: existingSnowflakeConfig,
  });

  const onSubmit = handleSubmit(
    useCallback(
      (formData) => {
        deliverablesStore.update(
          updateAllEntities((deliverable) => {
            const s3Delivery = omit(deliverable, [
              DeliverySelection.SNOWFLAKE,
            ]) as Deliverable;
            s3Delivery.s3_delivery = {
              s3_bucket:
                formData.s3_bucket ||
                clientDataBuilderConfig?.s3_location?.s3_bucket,
              s3_file_prefix: formData.s3_file_prefix,
              s3_format: formData.s3_format,
            };
            return s3Delivery;
          })
        );
        onClose(false)();
      },
      [clientDataBuilderConfig, onClose]
    )
  );

  const formKeyToLabel = {
    s3_bucket: 'bucket',
    s3_file_prefix: 'file prefix',
  };

  const formKeyToPlaceholder = {
    s3_bucket: 'Bucket',
    s3_file_prefix: 'File Prefix (e.g. batchtime/databuilder_output/file)',
  };

  const formKeys = compact([
    isRevelioAdmin ? 's3_bucket' : null,
    's3_file_prefix',
  ]);

  const fileFormatOptions = Object.values(S3Format).map((opt) => ({
    label: opt === S3Format.Csv ? upperCase(opt) : startCase(opt),
    value: opt,
  }));
  const {
    field: { onChange, onBlur, ref, name, value },
  } = useController<S3Delivery>({
    name: 's3_format',
    control,
    defaultValue: isRevelioAdmin ? S3Format.Csv : S3Format.Parquet,
  });

  return (
    <form>
      <ModalBody mt="14px">
        <Stack>
          {formKeys.map((formKey) => (
            <FormControl
              key={formKey}
              id={formKey}
              isInvalid={!!errors[formKey]}
            >
              <Input
                {...register(formKey, {
                  required: `${formKeyToLabel[formKey]} is required.`,
                  minLength: {
                    value: 1,
                    message: 'Minimum length should be 1',
                  },
                })}
                placeholder={formKeyToPlaceholder[formKey]}
              />
              <FormErrorMessage>
                {errors[formKey] && errors[formKey]?.message}
              </FormErrorMessage>
            </FormControl>
          ))}

          <Select
            placeholder="Select option"
            options={fileFormatOptions}
            name={name}
            value={fileFormatOptions.find((opt) => opt.value === value)}
            ref={ref}
            onChange={(e) =>
              onChange((e as { label: string; value: S3Format }).value)
            }
            onBlur={onBlur}
            focusBorderColor="green.500"
          />
        </Stack>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup spacing={4}>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={onClose(true)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            borderRadius="4px"
            fontSize="12px"
            fontWeight="600"
            colorScheme="green"
            variant="solid"
            size="sm"
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

export default AWSS3ConfigurationForm;

import { useMemo } from 'react';

import { PrimaryView, TimeFrameView } from '@revelio/core';
import {
  Filter,
  FilterName,
  OtherFilterNames,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

export const useIsQueryReady = ({
  filters,
  primaryFilters,
  timeframe,
  view,
}: {
  filters: Filter[];
  primaryFilters: FilterName[];
  timeframe: TimeFrameView;
  view: PrimaryView;
}) => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;
  /** check that filters has primaryFilter */
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => filters.some((filter) => primaryFilters.includes(filter.id)),
    [filters, primaryFilters]
  );

  const filtersHasDateSet = useMemo(() => {
    if (isSnapshot) {
      return filters.some(
        (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
      );
    }
    return filters.some(
      (filter) => filter.id === SelectionCategories.DATE_RANGE
    );
  }, [filters, isSnapshot]);

  const filtersHasCustomRoleTaxonomy = useMemo(() => {
    return !!filters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
  }, [filters]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  return (
    filtersHasAtLeastOnePrimaryFilter &&
    filtersHasDateSet &&
    (!isCustomRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};

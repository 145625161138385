import {
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { difference } from 'lodash';

import { Deliverable } from '../deliverables.model';
import AWSS3ConfigurationForm from './aws-s3-config';
import {
  AddingOrEditingConfig,
  DeliverCheckoxValues,
  adminDeliveryOptions,
} from './delivery.model';
import SnowflakeDBConfigurationForm from './snowflake-db-configuration-form';

type ChangeConfigModalProps = {
  entityId: Deliverable['id'];
  addingOrEditingConfig: AddingOrEditingConfig | null;
  selectedDeliveryOptions: DeliverCheckoxValues;
  setSelectedDeliveryOptions: (selected: DeliverCheckoxValues) => void;
  deliveryOptionConfigBeingChanged: DeliverCheckoxValues[number] | null;
  resetConfigBeingChanged: () => void;
};
const ChangeConfigModal = ({
  entityId,
  addingOrEditingConfig,
  selectedDeliveryOptions,
  setSelectedDeliveryOptions,
  deliveryOptionConfigBeingChanged,
  resetConfigBeingChanged,
}: ChangeConfigModalProps) => {
  const onCloseConfigurationModal =
    (uncheckAdding = true) =>
    () => {
      if (addingOrEditingConfig === AddingOrEditingConfig.Adding) {
        uncheckAdding
          ? setSelectedDeliveryOptions(
              difference(selectedDeliveryOptions, [
                deliveryOptionConfigBeingChanged as DeliverCheckoxValues[number],
              ])
            )
          : setSelectedDeliveryOptions([
              deliveryOptionConfigBeingChanged as DeliverCheckoxValues[number],
            ]); // only allow 1 delivery selection at a time

        resetConfigBeingChanged();
      }

      if (addingOrEditingConfig === AddingOrEditingConfig.Editing) {
        resetConfigBeingChanged();
      }
    };

  const isSnowflakeConfig =
    deliveryOptionConfigBeingChanged === 'snowflake_delivery';
  const isS3Config = deliveryOptionConfigBeingChanged === 's3_delivery';

  return (
    <Modal
      isOpen={!!addingOrEditingConfig}
      onClose={onCloseConfigurationModal(
        addingOrEditingConfig === AddingOrEditingConfig.Adding
      )}
      isCentered
    >
      <ModalOverlay />
      <ModalContent minWidth={isSnowflakeConfig ? '700px' : 'auto'}>
        <ModalHeader color="text.primary">
          {
            adminDeliveryOptions[0].columns.find(
              (option) => option.id === deliveryOptionConfigBeingChanged
            )?.label
          }{' '}
          Configuration
        </ModalHeader>
        <ModalCloseButton />

        <Divider orientation="horizontal" />

        {isSnowflakeConfig && (
          <SnowflakeDBConfigurationForm
            entityId={entityId}
            onClose={onCloseConfigurationModal}
          />
        )}
        {isS3Config && (
          <AWSS3ConfigurationForm
            entityId={entityId}
            onClose={onCloseConfigurationModal}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ChangeConfigModal;

import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';
import { deleteAllEntities } from '@ngneat/elf-entities';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { useIsRevelioAdmin } from '@revelio/auth';
import { PagePaths, WithTooltip } from '@revelio/core';

import CreateStandardSet from './create-standard-set';
import styles from './deliverable-step-container.module.css';
import { DeliverablePaths } from './deliverables-page';
import {
  deliverablesStore,
  setDuplicateDatasetNaming,
} from './deliverables.repository';
import { reportBuilderStore } from './report-builder/report-builder.repository';

interface DeliverableStepContainerProps {
  id: string;
  title: string;
  subtitle?: string;
  titleElement?: ReactNode;
  banner?: ReactNode;
  children: ReactNode;
  previousStepPath?: string;
  submitButtonContent?: ReactNode;
  submitTooltip?: ReactNode;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
  isSubmitHidden?: boolean;
  ctaElement?: ReactNode;
  skipable?: boolean;
  onSkip?: () => void;
  onSubmit?: () => void;
  disableFade?: boolean;
  contentContainerProps?: BoxProps;
  showTopDivider?: boolean;
  isDataBuilder?: boolean;
  onReset?: () => void;
  startOverPath: string;
  isReportStep?: boolean;
}

export const DATASET_STEP_ID = 'data-set';
export const DeliverableStepContainer = ({
  id,
  title,
  subtitle,
  titleElement,
  banner,
  children,
  previousStepPath,
  submitButtonContent = 'Continue',
  submitTooltip,
  isSubmitDisabled = false,
  isSubmitLoading = false,
  isSubmitHidden = false,
  ctaElement,
  skipable = false,
  onSkip,
  onSubmit,
  disableFade,
  contentContainerProps,
  showTopDivider = true,
  isDataBuilder = true,
  onReset = () => null,
  startOverPath,
  isReportStep = false,
}: DeliverableStepContainerProps) => {
  const navigate = useNavigate();
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isColumnsAndFiltersSelectStep = id === 'columns_filters_select';

  const onClickStartOver = () => {
    reportBuilderStore.reset();

    const updateParams: Parameters<typeof deliverablesStore.update> =
      isReportStep
        ? [deleteAllEntities()]
        : [deleteAllEntities(), setDuplicateDatasetNaming({})];

    deliverablesStore.update(...updateParams);

    navigate(startOverPath);
    onClose();
    onReset();
  };
  const ContinueButton = onSubmit ? (
    <Button
      onClick={onSubmit}
      isDisabled={isSubmitDisabled}
      isLoading={isSubmitLoading}
      hidden={isSubmitHidden}
      borderRadius="4px"
      fontSize="12px"
      fontWeight="600"
      colorScheme="green"
      variant="solid"
      size="sm"
      px="6"
    >
      {submitButtonContent}
    </Button>
  ) : null;

  const StartOverBreadcrumb = isDataBuilder ? (
    <BreadcrumbItem data-testid={'start-over'}>
      <Popover
        placement="top-start"
        returnFocusOnClose={false}
        {...{ isOpen, onOpen, onClose }}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <BreadcrumbLink
                fontSize="sm"
                fontWeight="600"
                color="red"
                cursor="pointer"
              >
                Start over
              </BreadcrumbLink>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader color="text.primary" fontWeight="600">
                Confirm Action
              </PopoverHeader>
              <PopoverBody color="text.primary">
                Are you sure you want to start over? This will reset all your
                changes.
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <ButtonGroup size="sm">
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    color={'text.primary'}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid={'confirm-start-over'}
                    colorScheme="red"
                    onClick={onClickStartOver}
                  >
                    Confirm
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </BreadcrumbItem>
  ) : null;

  const PreviousStepBreadcrumb = previousStepPath ? (
    <BreadcrumbItem data-testid={'previous-step'}>
      <BreadcrumbLink
        as={NavLink}
        to={previousStepPath}
        fontSize="sm"
        fontWeight="600"
        color="text.primary"
      >
        Back
      </BreadcrumbLink>
    </BreadcrumbItem>
  ) : null;

  const SkipButton = skipable ? (
    <Button
      onClick={onSkip}
      borderRadius="4px"
      fontSize="sm"
      fontWeight="600"
      colorScheme="green"
      variant="outline"
      size="sm"
      px="6"
    >
      Skip
    </Button>
  ) : null;

  const DatasetStepMenuButton = isRevelioAdmin ? (
    <ButtonGroup size="sm" isAttached variant="outline">
      {ContinueButton}
      <Menu placement="top-end">
        <MenuButton
          as={IconButton}
          icon={<ChevronUpIcon />}
          borderRadius="4px"
          fontSize="20px"
          fontWeight="600"
          colorScheme="green"
          variant="solid"
          borderLeft="2px"
          borderColor="white"
          size="sm"
        ></MenuButton>
        <MenuList>
          <CreateStandardSet
            isRevelioAdmin={isRevelioAdmin}
            onSuccess={() => {
              navigate(
                `/${PagePaths.DELIVERABLES}/${DeliverablePaths.COMPANY_SELECTION}`
              );
            }}
          />
        </MenuList>
      </Menu>
    </ButtonGroup>
  ) : (
    ContinueButton
  );

  return (
    <Box
      py={{ base: '4', md: '4' }}
      width="100%"
      data-testid={`deliverables-${id}-box-container`}
    >
      <Stack spacing="5" justify="space-between" height="100%">
        <Wrap px={{ base: '4', md: '4' }} alignItems="center">
          <Heading
            as="h2"
            fontWeight="600"
            size="md"
            color="text.primary"
            margin="auto 0"
          >
            {title}
          </Heading>
          {subtitle && (
            <Text color={'gray.400'} fontWeight={'600'} ml="1" fontSize="md">
              {subtitle}
            </Text>
          )}
          {banner ? (
            <Box flex="1" ml="5px">
              {banner}
            </Box>
          ) : (
            <Spacer />
          )}
          {titleElement}
        </Wrap>

        {showTopDivider && <Divider />}

        <Box
          className={
            /* eslint-disable-next-line no-nested-ternary */
            disableFade
              ? ''
              : isColumnsAndFiltersSelectStep
                ? styles.dataBuilderContainer
                : styles.contentContainer
          }
          px={{ base: '4', md: '4' }}
          flexGrow="1"
          overflow="auto"
          {...contentContainerProps}
        >
          {children}
        </Box>

        <Divider />

        <Flex
          px={{ base: '5', md: '5' }}
          justifyContent={'space-between'}
          alignItems="center"
        >
          <Box>
            <Breadcrumb color="gray.500" separator="|">
              {StartOverBreadcrumb}
              {PreviousStepBreadcrumb}
            </Breadcrumb>
          </Box>
          <Box>
            {id === 'companies' && skipable ? (
              <Flex gap={2}>
                {SkipButton}
                {ContinueButton}
              </Flex>
            ) : (
              <WithTooltip
                label={submitTooltip}
                showCondition={!!submitTooltip}
              >
                {id === DATASET_STEP_ID
                  ? DatasetStepMenuButton
                  : ContinueButton}
              </WithTooltip>
            )}
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};

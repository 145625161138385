import { Flex } from '@chakra-ui/react';
import { SystemStyleObject, chakra } from '@chakra-ui/system';
import { useQuery } from '@tanstack/react-query';
import { LegacyRef } from 'react';

import { useAuthStore } from '@revelio/auth';
import { PrimaryView } from '@revelio/core';
import { ReviewType } from '@revelio/data-access';
import {
  AnyFilter,
  FilterList,
  FilterOrSubfilterName,
  SelectionCategories,
  ValidValueTypes,
  useAllFiltersState,
  useSelectionLists,
} from '@revelio/filtering';
import { useRoleTaxonomySetting } from '@revelio/filtering';
import { TypingText } from '@revelio/layout';
import { PlotLoader } from '@revelio/replots';

import { environment } from '../../../environments/environment';
import { generateSentimentPayload } from './generate-sentiment-review-payload';
import { SelectedEntity } from './sentiment-reviews-summary';

export interface GptSentimentReviewProps {
  primaryView: PrimaryView;
  reviewType: ReviewType;
  filters: FilterOrSubfilterName[];
  sx?: SystemStyleObject;
  targetRef?: LegacyRef<HTMLDivElement>;
  isSnapshotDate: boolean;
  selectedEntity: SelectedEntity | null;
}

export const GptSentimentReview = ({
  primaryView,
  reviewType,
  isSnapshotDate,
  selectedEntity,
  sx = {},
}: GptSentimentReviewProps) => {
  const { isEnabled: isCustomRoleTaxonomyActive } = useRoleTaxonomySetting();
  const { dashMetaCsrfToken: csrfToken = 'missing' } = useAuthStore();
  const filters = useAllFiltersState();
  const selectionLists = useSelectionLists([
    SelectionCategories.ROLE_K7,
    SelectionCategories.ROLE_K150,
    SelectionCategories.ROLE_K1500,
  ]);

  const canGeneratePayload = selectedEntity && selectionLists.length === 3;

  const payload =
    canGeneratePayload &&
    generateSentimentPayload({
      filters: filters as AnyFilter<FilterList<ValidValueTypes>>[],
      selectionLists,
      primaryView,
      isCustomRoleTaxonomyActive,
      reviewType,
      selectedEntity: {
        id: Number(selectedEntity.id),
        label: selectedEntity.label,
        selectionListId: selectedEntity.selectionListId,
      },
      isSnapshotDate,
    });

  const { data, isLoading } = useQuery({
    queryKey: ['sentiment-review', payload],
    queryFn: async () => {
      if (!payload) return null;

      const response = await fetch(
        `${environment.DASH_META_API}/api/sentiment/reviews`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-surf-token-dash-meta': csrfToken,
            'request-id': crypto.randomUUID(),
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch sentiment reviews');
      }

      return response.json();
    },
    enabled: !!payload,
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  return (
    <chakra.div
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxHeight="100%"
      height="100%"
      overflow="auto"
      sx={{
        ...sx,
        maskImage: 'linear-gradient(to bottom, black 87%, transparent 100%);',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.200',
          borderRadius: '4px',
        },
      }}
    >
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          height="100%"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          backgroundColor="white"
        >
          <PlotLoader />
        </Flex>
      )}

      <TypingText
        textChunk={data?.review || data?.error || ''}
        sx={{ pb: '16px' }}
      />
    </chakra.div>
  );
};

import { useEffect } from 'react';

import { globalFetchStatus, globalLoader } from '@revelio/core';

export const useGlobalLoadController = (loading: boolean) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (loading) {
      globalLoader.next(true);
      globalFetchStatus.next(true);
    } else {
      globalFetchStatus.next(false);
      timeout = setTimeout(() => {
        globalLoader.next(false);
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);
};

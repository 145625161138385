import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';

import { RevelioTooltip } from '@revelio/layout';

import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import { AiFilterSearch } from './ai-filter-search';
import { AiSearchIcon } from './ai-search-icon';

export const EditAiSearchButton = () => {
  const { aiGeneratedFilterIds, isAiSearchLoading, state } =
    useTalentDiscoveryFilter();

  const someFiltersAreAiGenerated = state.filters?.some((filter) =>
    aiGeneratedFilterIds.includes(filter.id)
  );
  const {
    isOpen: isFilterMenuOpen,
    onClose: handleCloseFilterMenu,
    onOpen: handleOpenFilterMenu,
  } = useDisclosure();

  if (!someFiltersAreAiGenerated || isAiSearchLoading) {
    return null;
  }

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur={!isAiSearchLoading}
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
    >
      <PopoverTrigger>
        <Flex>
          <RevelioTooltip placement="top-start" shouldWrapChildren>
            <Box
              position="relative"
              width="25px"
              height="25px"
              mr={2}
              cursor="pointer"
            >
              <Box
                position="absolute"
                inset="0"
                bgGradient="linear(to-r, rgba(28, 129, 223, 1) 0%, rgba(30, 217, 217, 1) 50%, rgba(148, 231, 118, 1) 100%)"
                borderRadius="4px"
              />
              <Flex
                position="absolute"
                inset="2px"
                backgroundColor="rgba(255, 255, 255, 1)"
                borderRadius="2px"
                alignItems="center"
                justifyContent="center"
                boxSize="21px"
              >
                <AiSearchIcon />
              </Flex>
            </Box>
          </RevelioTooltip>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`td-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
        width="fit-content"
      >
        <Flex direction="column" gap="4">
          <AiFilterSearch closeMenu={handleCloseFilterMenu} />
        </Flex>
      </PopoverContent>
    </Popover>
  );
};

import { useQuery } from 'urql';

import { useGetLoggedInUser } from '@revelio/auth';
import { Tab } from '@revelio/data-access';

import {
  GetClientDeliverablesQuery,
  GetUserDeliverablesQuery,
} from './status.api';
import { UserOption } from './user-selector';

export const useDeliverablesFromUserOption = (
  userOption: UserOption | undefined
) => {
  const { loggedInUser } = useGetLoggedInUser();
  const value = userOption?.value;
  const isGroupOption = userOption?.isGroupOption;
  const hasDeliverables = loggedInUser?.tabs?.includes(Tab.Deliverables);

  // get user deliverables if the selected option is not a group
  const [
    {
      data: userDeliverablesData,
      fetching: userDeliverablesFetching,
      error: userDeliverablesError,
    },
    reExecuteUserDeliverablesQuery,
  ] = useQuery({
    query: GetUserDeliverablesQuery,
    variables: { ids: [value || ''] },
    pause: !value || isGroupOption || !hasDeliverables,
    requestPolicy: 'network-only',
  });

  // get client deliverables if the selected option is a group
  const [
    {
      data: clientDeliverablesData,
      fetching: clientDeliverablesFetching,
      error: clientDeliverablesError,
    },
    reExecuteClientDeliverablesQuery,
  ] = useQuery({
    query: GetClientDeliverablesQuery,
    variables: { name: value || '' },
    pause: !value || !isGroupOption || !hasDeliverables,
    requestPolicy: 'network-only',
  });

  const fetching = clientDeliverablesFetching || userDeliverablesFetching;
  const error = clientDeliverablesError || userDeliverablesError;
  const hasFetchBeenCalled =
    fetching || !!(clientDeliverablesData || userDeliverablesData) || !!error;

  // select which deliverables data to use
  const deliverables =
    (isGroupOption
      ? clientDeliverablesData?.clientsDeliverable
      : userDeliverablesData?.users?.[0]?.deliverables) || [];

  // select which re-execution query to use
  const executeQuery = isGroupOption
    ? reExecuteClientDeliverablesQuery
    : () => {
        if (hasDeliverables) {
          reExecuteUserDeliverablesQuery();
        }
      };

  return { deliverables, fetching, executeQuery, hasFetchBeenCalled };
};

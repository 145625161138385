import { GO_API_ROOT } from '@revelio/auth';
import { Dimension1 } from '@revelio/data-access';
import { CompensationFilters } from '@revelio/data-access';

type CompensationMetaData = {
  id?: string;
  level?: string;
  longName: string;
  shortName: string;
  years?: string[];
  cdf?: number[];
  salaries?: number[];
  percentile?: number[];
  day?: number;
  month?: number;
  year?: number;
};

export type CompensationMetricValue = {
  id?: string;
  metadata?: CompensationMetaData;
  value?: (number | CompensationMetricValue)[];
};

type CompensationTypeValue = {
  id: 'timeseries' | 'kde' | 'top_role' | 'top_company' | 'top_geography';
  value: CompensationMetricValue[];
};

export enum CompensationTypes {
  HOURLY = 'contractor_pay_rate',
  BASE = 'base_salary',
  TOTAL = 'total_compensation',
}

export type ModelCompensationResponse = {
  id: CompensationTypes;
  value: CompensationTypeValue[];
};

type TopList = {
  id: number;
  label: number;
};
export type CompensationBody = CompensationFilters & {
  dim1: Dimension1;
  year_range: [[number, number]];
  top_companies?: TopList[];
  top_geographies?: TopList[];
  top_roles?: TopList[];
};

export const fetchModelCompensation = async (
  compensationBody: CompensationBody | null,
  modelsCompensationUrl = `${GO_API_ROOT}/models/compensation`
) => {
  try {
    if (!compensationBody) {
      throw new Error('No compensation body provided');
    }

    const response: Response = await fetch(modelsCompensationUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-request-id': crypto.randomUUID(),
      },
      body: JSON.stringify(compensationBody),
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status} ${response.statusText}`);
    }

    const data: ModelCompensationResponse[] = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { loadingImage } from '@revelio/assets';
import { useResizeObserver } from '@revelio/core';

import noData from '../../assets/no-data.svg';
import styles from './plot-loader.module.css';

export const PlotLoader = () => {
  return (
    <div className={styles.plotLoaderContainer}>
      <img
        src={loadingImage}
        className={styles.plotLoaderImage}
        alt="loading"
      />
    </div>
  );
};

export const NoData = () => {
  const { containerRef, width, height } = useResizeObserver();
  const isMinimal = width < 250 || height < 150;

  return (
    <div
      ref={containerRef}
      className={classNames(styles.noDataContainer, {
        [styles.smallNoData]: isMinimal,
      })}
    >
      {!isMinimal && (
        <img src={noData} alt="No data" className={styles.noDataImage} />
      )}
      <div className={styles.noDataText}>
        No data available. Please adjust filters and try again.
      </div>
    </div>
  );
};

type PlotLoadWrapperProps = {
  loading: boolean;
  noData: boolean;
} & PropsWithChildren;
export const PlotLoadWrapper = ({
  children,
  loading,
  noData,
}: PlotLoadWrapperProps) => {
  if (loading) return <PlotLoader />;
  if (noData) return <NoData />;
  return children;
};

import { Box, Button, Spinner } from '@chakra-ui/react';
import { useContext } from 'react';

import { TalentDiscoveryTableContext } from './talent-discovery-table';

export interface BlurredCellProps {
  value: string;
  rowData: Record<string, never>;
  onMouseEnter: () => void;
  originalValue: string;
  columnName: string;
  allowUnblur: boolean;
  'data-testid'?: string;
}

export function BlurredCell({
  value,
  rowData,
  onMouseEnter,
  originalValue,
  columnName,
  allowUnblur,
  'data-testid': testId,
}: BlurredCellProps) {
  const { decryptCell, isDecrypting, getDecryptedValue, hoveringRowData } =
    useContext(TalentDiscoveryTableContext);
  const cellKey = `${rowData.user_id}-${columnName}`;
  const decryptedValue = getDecryptedValue(cellKey);
  const loading = isDecrypting(cellKey);
  const isHovering = hoveringRowData === rowData;

  const handleUnblur = () => {
    if (allowUnblur) {
      decryptCell(cellKey, originalValue);
    }
  };

  const handleMouseEnter = () => {
    onMouseEnter();
  };

  if (decryptedValue) {
    return <Box data-testid={testId}>{decryptedValue}</Box>;
  }

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseEnter}
      data-testid={testId}
    >
      <Box
        filter="blur(3px)"
        position="relative"
        borderRadius="100%"
        maxWidth="20px"
        data-testid="blurred-cell"
      >
        {value}
      </Box>
      {allowUnblur &&
        (isHovering || loading) &&
        (loading ? (
          <Spinner
            size="sm"
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            margin="auto"
          />
        ) : (
          <Button
            size="xs"
            onClick={handleUnblur}
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            margin="auto"
            width="60px"
            height="15px"
            colorScheme="yellow"
            background="#fff7c9"
            color="navyBlue.500"
            _hover={{ background: 'yellow.300' }}
          >
            Unblur
          </Button>
        ))}
    </Box>
  );
}

import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';

import { SelectionCategories } from '@revelio/filtering';

import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import { OperatorOption } from '../types';

interface GraduationYearFilterProps {
  closeMenu: () => void;
}

export const operatorOptions: OperatorOption[] = [
  { label: 'is between', value: 'between' },
  { label: 'is greater than', value: 'greater_than' },
  { label: 'is less than', value: 'less_than' },
];

const NumberInput = ({
  ...restProps
}: NumberFormatPropsBase<typeof Input> & { onBlur: () => void }) => {
  return (
    <NumberFormat
      {...restProps}
      customInput={Input}
      displayType="input"
      height="36px"
      fontSize="14px"
      padding="10px"
      borderRadius="2px"
      format="####"
      mask="_"
      onBlur={restProps.onBlur}
      value={restProps.value ?? ''}
      allowEmptyFormatting={false}
    />
  );
};

export const GraduationYearFilter = ({
  closeMenu,
}: GraduationYearFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();
  const [operator, setOperator] = useState<OperatorOption>(operatorOptions[0]);
  const [startYear, setStartYear] = useState<number | undefined>(undefined);
  const [endYear, setEndYear] = useState<number | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [startYearTouched, setStartYearTouched] = useState(false);
  const [endYearTouched, setEndYearTouched] = useState(false);

  const existingFilter = state.filters?.find(
    (filter) => filter.name === SelectionCategories.GRADUATION_YEAR
  );

  useEffect(() => {
    if (existingFilter && 'start_value' in existingFilter) {
      setStartYear(existingFilter.start_value);
      setEndYear(existingFilter.end_value);

      const operatorValue = (() => {
        if (
          existingFilter.start_value !== undefined &&
          existingFilter.end_value !== undefined
        ) {
          return 'between';
        }
        if (existingFilter.start_value !== undefined) {
          return 'greater_than';
        }
        return 'less_than';
      })();
      const selectedOperator =
        operatorOptions.find((opt) => opt.value === operatorValue) ||
        operatorOptions[0];
      setOperator(selectedOperator);
    }
  }, [existingFilter]);

  const handleOperatorChange = (value: SingleValue<OperatorOption>) => {
    setOperator(value || operatorOptions[0]);
    setStartYear(undefined);
    setEndYear(undefined);
    setErrorMessages([]);
    setStartYearTouched(false);
    setEndYearTouched(false);
  };

  const handleStartYearChange = (values: NumberFormatValues) => {
    setStartYear(values.floatValue);
  };

  const handleEndYearChange = (values: NumberFormatValues) => {
    setEndYear(values.floatValue);
  };

  useEffect(() => {
    const errors: string[] = [];

    if (startYearTouched) {
      if (startYear !== undefined && (startYear < 1000 || startYear > 9999)) {
        errors.push('Start year must be a 4-digit number.');
      }
      if (startYear !== undefined && startYear <= 1900) {
        errors.push('Start year must be after 1900.');
      }
    }

    if (endYearTouched) {
      if (endYear !== undefined && (endYear < 1000 || endYear > 9999)) {
        errors.push('End year must be a 4-digit number.');
      }
      if (endYear !== undefined && endYear <= 1900) {
        errors.push('End year must be after 1900.');
      }
    }

    if (
      operator?.value === 'between' &&
      startYearTouched &&
      endYearTouched &&
      startYear !== undefined &&
      endYear !== undefined &&
      startYear > endYear
    ) {
      errors.push('Start year must be less than or equal to the end year.');
    }

    setErrorMessages(errors);
  }, [operator, startYear, endYear, startYearTouched, endYearTouched]);

  const isInvalid =
    errorMessages.length > 0 && (startYearTouched || endYearTouched);

  const isDisabled = (() => {
    if (operator?.value === 'greater_than' && startYear === undefined) {
      return true;
    }

    if (operator?.value === 'less_than' && endYear === undefined) {
      return true;
    }

    if (
      operator?.value === 'between' &&
      (startYear === undefined || endYear === undefined)
    ) {
      return true;
    }

    return isInvalid;
  })();

  const handleAddFilter = () => {
    if (isInvalid) {
      return;
    }

    const { filterStartYear, filterEndYear } = (() => {
      switch (operator.value) {
        case 'between':
          return {
            filterStartYear: startYear,
            filterEndYear: endYear,
          };
        case 'greater_than':
          return {
            filterStartYear: startYear,
            filterEndYear: undefined,
          };
        case 'less_than':
          return {
            filterStartYear: undefined,
            filterEndYear: endYear,
          };
        default:
          return {
            filterStartYear: undefined,
            filterEndYear: undefined,
          };
      }
    })();

    dispatch({
      type: 'ADD_RANGE_FILTER',
      name: SelectionCategories.GRADUATION_YEAR,
      start_value: filterStartYear,
      end_value: filterEndYear,
    });

    closeMenu();
  };

  const handleClearSelections = () => {
    console.log('clear');
    setStartYear(undefined);
    setEndYear(undefined);
    setOperator(operatorOptions[0]);
    setErrorMessages([]);
    setStartYearTouched(false);
    setEndYearTouched(false);
  };

  return (
    <>
      <Flex direction="column" gap="3" fontSize="14px" mb={2}>
        <Select
          options={operatorOptions}
          value={operator}
          onChange={handleOperatorChange}
          size="sm"
          selectedOptionColorScheme="green"
          chakraStyles={{
            control: (provider) => ({
              ...provider,
              height: '36px',
            }),
          }}
        />
        <FormControl isInvalid={isInvalid}>
          {operator?.value === 'between' ? (
            <Flex
              alignItems="center"
              gap="2.5"
              fontSize="14px"
              justifyContent="space-between"
            >
              <NumberInput
                value={startYear}
                onValueChange={handleStartYearChange}
                onBlur={() => setStartYearTouched(true)}
                data-testid="start-year-input"
              />
              <Text>to</Text>
              <NumberInput
                value={endYear}
                onValueChange={handleEndYearChange}
                onBlur={() => setEndYearTouched(true)}
                data-testid="end-year-input"
              />
            </Flex>
          ) : (
            <NumberInput
              value={operator?.value === 'greater_than' ? startYear : endYear}
              onValueChange={
                operator?.value === 'greater_than'
                  ? handleStartYearChange
                  : handleEndYearChange
              }
              onBlur={() =>
                operator?.value === 'greater_than'
                  ? setStartYearTouched(true)
                  : setEndYearTouched(true)
              }
              data-testid="single-year-input"
            />
          )}
          <FormErrorMessage>
            <VStack align="start">
              {errorMessages.map((msg, idx) => (
                <Text key={idx} color="red.600">
                  {msg}
                </Text>
              ))}
            </VStack>
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={closeMenu}
        onAdd={handleAddFilter}
        addLabel={existingFilter ? 'Update' : 'Add'}
        isAddDisabled={isDisabled}
      />
    </>
  );
};

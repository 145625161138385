import { VolumeChartData } from './volume-chart';

export type IndexedVolumeData = {
  active: Map<number, number>;
  new: Map<number, number>;
  removed: Map<number, number>;
  weekDates: Date[];
};

// Helper to get week start date in UTC
export const getWeekStart = (date: Date): Date => {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  d.setUTCDate(d.getUTCDate() - d.getUTCDay());
  return d;
};

// Create an indexed version of the volume data for efficient lookups on mouse move
export const createIndexedVolumeData = (
  data: VolumeChartData[]
): IndexedVolumeData => {
  const weekDatesSet = new Set<number>();
  const activeMap = new Map<number, number>();
  const newMap = new Map<number, number>();
  const removedMap = new Map<number, number>();

  data.forEach((series) => {
    series.values.forEach(({ date, value }) => {
      const dateObj = new Date(date);
      if (!isNaN(dateObj.getTime())) {
        const weekStart = getWeekStart(dateObj);
        const weekTime = weekStart.getTime();
        weekDatesSet.add(weekTime);

        switch (series.id) {
          case 'active':
            activeMap.set(weekTime, value);
            break;
          case 'new':
            newMap.set(weekTime, value);
            break;
          case 'removed':
            removedMap.set(weekTime, value);
            break;
        }
      }
    });
  });

  const weekDates = Array.from(weekDatesSet)
    .map((time) => new Date(time))
    .sort((a, b) => a.getTime() - b.getTime());

  return {
    active: activeMap,
    new: newMap,
    removed: removedMap,
    weekDates,
  };
};

// Get data for a specific week
export const getWeekData = (
  indexedData: IndexedVolumeData,
  weekTime: number
): { active: number; newPostings: number; removed: number } => {
  return {
    active: indexedData.active.get(weekTime) || 0,
    newPostings: indexedData.new.get(weekTime) || 0,
    removed: indexedData.removed.get(weekTime) || 0,
  };
};

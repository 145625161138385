import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';

import { IndeedIcon, SmallLinkedinIcon, WebIcon } from '@revelio/assets';

import './JobPostingsTableStyles.scss';

const gridStyle = {
  minHeight: '75vh',
};
const columns = [
  {
    name: 'relevance',
    header: 'Relevance',
    maxWidth: 105,
    render: (row) => (
      <Flex alignItems="center" justifyContent="center">
        <Donut value={row.value} />
      </Flex>
    ),
  },
  {
    name: 'postDate',
    header: 'Post Date',
    maxWidth: 105,
  },
  {
    name: 'country',
    header: 'Country',
    maxWidth: 95,
  },
  {
    name: 'location',
    header: 'Location',
    maxWidth: 130,
  },
  {
    name: 'salary',
    header: 'Salary',
    maxWidth: 90,
  },
  {
    name: 'title',
    header: 'Title',
    maxWidth: 130,
  },
  {
    name: 'company',
    header: 'Company',
    maxWidth: 120,
  },
  {
    name: 'skills',
    header: 'Skills',
    maxWidth: 100,
  },
  {
    name: 'responsibilities',
    header: 'Responsibilities',
    maxWidth: 145,
  },
  {
    name: 'id',
    header: '',
    maxWidth: 65,
    render: () => {
      return (
        <Flex gap="5px" alignItems="center">
          <WebIcon color="navyBlue.500" width="11px" />
          <SmallLinkedinIcon width="12px" color="navyBlue.500" />
          <IndeedIcon color="navyBlue.500" height="13px" width="9px" />
        </Flex>
      );
    },
  },
] as TypeColumn[];

const dataSource = [
  {
    id: '123',
    relevance: 4,
    postDate: '3/15',
    country: 'USA',
    location: 'San Francisco, CA',
    salary: '130k - 170k',
    title: 'Director of Operations',
    company: 'Tech Solutions Inc',
    skills: 'management, strategic planning, operations',
    responsibilities:
      'Oversee daily activities, improve business processes, manage operations team.',
  },
  {
    id: '456',
    relevance: 6,
    postDate: '4/01',
    country: 'Canada',
    location: 'Toronto, ON',
    salary: '110k - 150k',
    title: 'Project Manager - Infrastructure',
    company: 'Innovatech Ltd',
    skills: 'project management, IT infrastructure, risk management',
    responsibilities:
      'Manage IT projects, coordinate with stakeholders, ensure timely delivery.',
  },
  {
    id: '789',
    relevance: 10,
    postDate: '2/20',
    country: 'USA',
    location: 'Austin, TX',
    salary: '120k - 145k',
    title: 'Senior Software Engineer',
    company: 'Creative Tech Solutions',
    skills: 'software development, agile methodologies, JavaScript',
    responsibilities:
      'Develop software applications, lead agile projects, mentor junior engineers.',
  },
  {
    id: '101112',
    relevance: 3,
    postDate: '3/25',
    country: 'UK',
    location: 'London, UK',
    salary: '95k - 120k',
    title: 'Human Resources Manager',
    company: 'Global Enterprises Inc',
    skills: 'HR management, recruiting, employee relations',
    responsibilities:
      'Manage HR operations, develop policies, oversee recruitment processes.',
  },
  {
    id: '131415',
    relevance: 4,
    postDate: '2/12',
    country: 'USA',
    location: 'Multiple locations - NYC, LA',
    salary: '100k - 140k',
    title: 'Marketing Director',
    company: 'BrandMark Marketing',
    skills: 'digital marketing, SEO/SEM, strategic leadership',
    responsibilities:
      'Lead marketing team, plan and execute marketing strategies, manage budget.',
  },
  {
    id: '161718',
    relevance: 8,
    postDate: '4/10',
    country: 'Germany',
    location: 'Berlin, Germany',
    salary: '85k - 130k',
    title: 'Product Manager',
    company: 'Innovation Labs',
    skills: 'product lifecycle management, market analysis, user experience',
    responsibilities:
      'Define product vision, collaborate with engineering, market research.',
  },
  {
    id: '192021',
    relevance: 9,
    postDate: '3/22',
    country: 'France',
    location: 'Paris, France',
    salary: '110k - 150k',
    title: 'Chief Financial Officer',
    company: 'FinCorp International',
    skills: 'financial management, budgeting, compliance',
    responsibilities:
      'Oversee financial operations, manage company budget, ensure compliance with laws.',
  },
  {
    id: '222324',
    relevance: 1,
    postDate: '2/28',
    country: 'Australia',
    location: 'Sydney, NSW',
    salary: '90k - 130k',
    title: 'Logistics Coordinator',
    company: 'Global Shipping Ltd',
    skills: 'logistics management, supply chain coordination, import/export',
    responsibilities:
      'Coordinate logistics operations, manage shipping schedules, optimize supply chain.',
  },
  {
    id: '252627',
    relevance: 7,
    postDate: '1/30',
    country: 'Japan',
    location: 'Tokyo, Japan',
    salary: '95k - 115k',
    title: 'Business Analyst',
    company: 'Tech Analysis Corp',
    skills: 'data analysis, business intelligence, problem-solving',
    responsibilities:
      'Analyze business data, provide insights, support strategic decisions.',
  },
  {
    id: '282930',
    relevance: 5,
    postDate: '2/05',
    country: 'India',
    location: 'Mumbai, India',
    salary: '75k - 100k',
    title: 'IT Support Specialist',
    company: 'RapidTech Solutions',
    skills: 'technical support, network administration, customer service',
    responsibilities:
      'Provide technical support, manage network issues, ensure system reliability.',
  },
  {
    id: '101112',
    relevance: 3,
    postDate: '3/25',
    country: 'UK',
    location: 'London, UK',
    salary: '95k - 120k',
    title: 'Human Resources Manager',
    company: 'Global Enterprises Inc',
    skills: 'HR management, recruiting, employee relations',
    responsibilities:
      'Manage HR operations, develop policies, oversee recruitment processes.',
  },
  {
    id: '131415',
    relevance: 4,
    postDate: '2/12',
    country: 'USA',
    location: 'Multiple locations - NYC, LA',
    salary: '100k - 140k',
    title: 'Marketing Director',
    company: 'BrandMark Marketing',
    skills: 'digital marketing, SEO/SEM, strategic leadership',
    responsibilities:
      'Lead marketing team, plan and execute marketing strategies, manage budget.',
  },
  {
    id: '161718',
    relevance: 8,
    postDate: '4/10',
    country: 'Germany',
    location: 'Berlin, Germany',
    salary: '85k - 130k',
    title: 'Product Manager',
    company: 'Innovation Labs',
    skills: 'product lifecycle management, market analysis, user experience',
    responsibilities:
      'Define product vision, collaborate with engineering, market research.',
  },
  {
    id: '192021',
    relevance: 9,
    postDate: '3/22',
    country: 'France',
    location: 'Paris, France',
    salary: '110k - 150k',
    title: 'Chief Financial Officer',
    company: 'FinCorp International',
    skills: 'financial management, budgeting, compliance',
    responsibilities:
      'Oversee financial operations, manage company budget, ensure compliance with laws.',
  },
  {
    id: '222324',
    relevance: 1,
    postDate: '2/28',
    country: 'Australia',
    location: 'Sydney, NSW',
    salary: '90k - 130k',
    title: 'Logistics Coordinator',
    company: 'Global Shipping Ltd',
    skills: 'logistics management, supply chain coordination, import/export',
    responsibilities:
      'Coordinate logistics operations, manage shipping schedules, optimize supply chain.',
  },
  {
    id: '252627',
    relevance: 7,
    postDate: '1/30',
    country: 'Japan',
    location: 'Tokyo, Japan',
    salary: '95k - 115k',
    title: 'Business Analyst',
    company: 'Tech Analysis Corp',
    skills: 'data analysis, business intelligence, problem-solving',
    responsibilities:
      'Analyze business data, provide insights, support strategic decisions.',
  },
  {
    id: '282930',
    relevance: 5,
    postDate: '2/05',
    country: 'India',
    location: 'Mumbai, India',
    salary: '75k - 100k',
    title: 'IT Support Specialist',
    company: 'RapidTech Solutions',
    skills: 'technical support, network administration, customer service',
    responsibilities:
      'Provide technical support, manage network issues, ensure system reliability.',
  },
];

function MatchedJobPostings({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent color="navyBlue.500" minW="85%" minH="85%" margin="auto">
        <ModalHeader
          fontSize="lg"
          borderBottom="1px solid"
          borderBottomColor="gray.100"
          pb="10px"
          mb="10px"
        >
          Job Postings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactDataGrid
            columns={columns}
            dataSource={dataSource}
            style={gridStyle}
            rowHeight={40}
            // emptyText="No deliverables created yet"
            theme="rl-light"
            // onRowClick={(value) => setSelectedDeliverableId(value.data.id)}
            // defaultSortInfo={defaultSortInfo}
            showCellBorders={true}
            showZebraRows={true}
            // loading={fetching}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MatchedJobPostings;

function Donut({ value = 1, size = 27, strokeWidth = 5, color = '#35ABDD' }) {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (value / 10) * circumference;

  return (
    <Box position="relative" width={`${size}px`} height={`${size}px`}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {/* Background circle */}
        <circle
          stroke="#E2E8F0"
          fill="transparent"
          strokeWidth={strokeWidth}
          cx={size / 2}
          cy={size / 2}
          r={radius}
        />
        {/* Foreground circle */}
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <Text
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        fontSize="10px"
        color="navyBlue.500"
      >
        {value}
      </Text>
    </Box>
  );
}

import { MenuItem, MenuList } from '@chakra-ui/menu';
import { ComponentProps } from 'react';
import { useMutation } from 'urql';

import { defaultAlertDialogDisclosureControl } from '@revelio/layout';

import {
  DeleteReportMutation,
  RefreshReportMutation,
} from '../../deliverables/report-builder/report-builder-operations';
import { UnifiedReportType } from '../hooks/use-all-reports';

type ReportMenuListAction = 'delete' | 'refresh';

export type ReportMenuListProps = ComponentProps<typeof MenuList> & {
  reportId: string;
  reportType: UnifiedReportType;
  viewReportLink?: string | null;
  onActionTriggered?: (action: ReportMenuListAction, reportId: string) => void;
  onActionSucceeded?: (action: ReportMenuListAction, reportId: string) => void;
};

export const ReportMenuList = ({
  reportId,
  reportType,
  viewReportLink,
  onActionTriggered,
  onActionSucceeded,
  ...rest
}: ReportMenuListProps) => {
  const [, refreshReport] = useMutation(RefreshReportMutation);
  const [, deleteReport] = useMutation(DeleteReportMutation);

  const onRefreshReport = async () => {
    if (reportType === 'GeneratedByRevelio') {
      return defaultAlertDialogDisclosureControl.next({
        alertType: 'actionless',
        isOpen: true,
        headerText: 'Unable to Refresh',
        bodyContent: (
          <span>
            This report was generated for you and is unable to be refreshed in
            this way. Please contact us to request an update.
          </span>
        ),
      });
    }

    onActionTriggered && onActionTriggered('refresh', reportId);
    const result = await refreshReport(
      { id: reportId },
      { additionalTypenames: ['Report'] }
    );
    if (!result.error) {
      onActionSucceeded && onActionSucceeded('refresh', reportId);
    }
  };

  const onDeleteReport = () => {
    if (reportType === 'GeneratedByRevelio') {
      return defaultAlertDialogDisclosureControl.next({
        alertType: 'actionless',
        isOpen: true,
        headerText: 'Unable to Delete',
        bodyContent: (
          <span>
            This report was generated for you and is unable to be deleted in
            this way. Please contact us to remove it.
          </span>
        ),
      });
    }

    defaultAlertDialogDisclosureControl.next({
      alertType: 'confirm',
      isOpen: true,
      headerText: 'Are you sure you want to delete this report?',
      bodyContent: (
        <span>
          This action is permanent and the report can not be recovered.
        </span>
      ),
      footerAcceptIsDestructive: true,
      footerAcceptText: 'Yes, Delete',
      onAccept: async () => {
        onActionTriggered && onActionTriggered('delete', reportId);
        const result = await deleteReport(
          { id: reportId },
          { additionalTypenames: ['Report'] }
        );
        if (!result.error) {
          onActionSucceeded && onActionSucceeded('delete', reportId);
        }
      },
    });
  };

  return (
    <MenuList {...rest}>
      {viewReportLink && (
        <MenuItem as="a" href={viewReportLink}>
          View
        </MenuItem>
      )}
      <MenuItem onClick={onRefreshReport}>Rerun</MenuItem>
      <MenuItem
        onClick={onDeleteReport}
        color={'red'}
        _focus={{ bg: 'red.100' }}
      >
        Delete
      </MenuItem>
    </MenuList>
  );
};

import { useMemo } from 'react';

import { PrimaryView } from '@revelio/core';
import {
  Filter,
  FilterName,
  OtherFilterNames,
  RangeFilter,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

export const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
}) => {
  const activeFiltersHasPrimaryFilter = activeFilters.some((filter) =>
    primaryFilters.includes(filter.id)
  );

  const dateRangeFullFilter = activeFilters?.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE_FULL
  ) as RangeFilter | undefined;
  const activeFiltersHasDateRange =
    !!dateRangeFullFilter?.value?.startDate &&
    !!dateRangeFullFilter?.value?.endDate;

  const filtersHasCustomRoleTaxonomy = useMemo(() => {
    return !!activeFilters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
  }, [activeFilters]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  return (
    activeFiltersHasPrimaryFilter &&
    activeFiltersHasDateRange &&
    (!isCustomRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};

import { getEntity, selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { get } from 'lodash';
import { map } from 'rxjs';

import { CompanySet, PipelineColumnType } from '@revelio/data-access';
import { ICardListSelectProps } from '@revelio/layout';

import { getSubsidiaryColumnMappings } from '../company-selection/subsidiary-mapping/subsidiary-mapping.repository';
import { Deliverable } from '../deliverables.model';
import {
  deliverablesStore,
  getColumnConfigById,
  getPipelineType,
} from '../deliverables.repository';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  DEFAULT_SKILL_PIPELINE_COLUMNS,
} from './configurations/shared';

const variableGroups = [
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  DEFAULT_SKILL_PIPELINE_COLUMNS,
];
export const useFileSizeEstimation = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const DATA_SIZE_ADJUSTMENT = 0.5;
  const ROW_NUMBER_ADJUSTMENT = 0.8;

  const missingColumnSizes: PipelineColumnType[] = [];
  const missingRowPossibilities: PipelineColumnType[] = [];
  let maxGroupRowMultiplier: { [key in string]?: number } = {};

  const pipelineType = getPipelineType({ entityId });
  const deliverable = deliverablesStore.query(getEntity(entityId));
  const [selectedPipelineColumns] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.columns', []) as PipelineColumnType[]
      )
    )
  );
  const { selectedColumnSizeSum, columnRowPossibilities } =
    selectedPipelineColumns.reduce(
      (sum, columnId) => {
        const columnConfig = getColumnConfigById({
          pipelineType,
          columnId,
        });
        if (!columnConfig) {
          // console.warn('missing column config for', columnId);
          return sum;
        }

        if (!columnConfig?.columnFileSize) {
          missingColumnSizes.push(columnId);
        }
        const columnSize = columnConfig.columnFileSize || 0;

        const {
          rowPossibilities,
          maxGroupRowMultiplier: newMaxGroupRowMultiplier,
          oldMaxGroupRowMultiplier,
        } = getRowPossibilities({
          columnConfig,
          maxGroupRowMultiplier,
          hasRevelioSharedCompanySet: !!deliverable?.company_sets?.includes(
            CompanySet.RevelioShared
          ),
        });
        maxGroupRowMultiplier = newMaxGroupRowMultiplier;
        if (rowPossibilities === null) {
          missingRowPossibilities.push(columnId);
        }

        return {
          selectedColumnSizeSum: sum.selectedColumnSizeSum + columnSize,
          columnRowPossibilities: oldMaxGroupRowMultiplier
            ? (sum.columnRowPossibilities / oldMaxGroupRowMultiplier) *
              rowPossibilities
            : sum.columnRowPossibilities * (rowPossibilities || 1),
        };
      },
      { selectedColumnSizeSum: 0, columnRowPossibilities: 1 }
    ) || { selectedColumnSizeSum: 1, columnRowPossibilities: 1 };
  // missingColumnSizes.length &&
  //   console.warn('missing column sizes for', missingColumnSizes);
  // missingRowPossibilities &&
  //   console.warn('missing row possibilities for', missingRowPossibilities);

  return (
    DATA_SIZE_ADJUSTMENT *
    selectedColumnSizeSum *
    (ROW_NUMBER_ADJUSTMENT * columnRowPossibilities)
  );
};

function getRowPossibilities({
  columnConfig,
  maxGroupRowMultiplier,
  hasRevelioSharedCompanySet,
}: {
  columnConfig: ICardListSelectProps<PipelineColumnType>;
  maxGroupRowMultiplier: { [key in string]?: number };
  hasRevelioSharedCompanySet: boolean;
}) {
  const columnId = columnConfig.id;
  if (columnId === PipelineColumnType.Company) {
    if (hasRevelioSharedCompanySet) {
      return { rowPossibilities: 10300, maxGroupRowMultiplier };
    }

    const subsidiaryMapping = deliverablesStore.query(
      getSubsidiaryColumnMappings
    );
    const numberOfCompanies =
      subsidiaryMapping.mappings['primary'].subsidiaries.length;
    return {
      rowPossibilities: numberOfCompanies || null, // TODO: provide manual input for CST for manually inputted tables
      maxGroupRowMultiplier,
    };
  }

  if (!columnConfig?.rowPossibilities) {
    return { rowPossibilities: null, maxGroupRowMultiplier };
  }

  const rowPossibilities = columnConfig.rowPossibilities;
  let oldMaxGroupRowMultiplier = null;

  const columnVariableGroup = variableGroups.find((group) =>
    group.menuItems.find(
      (item) => item.id === columnId && !item.excludeFromFileRowMax
    )
  );
  if (columnVariableGroup) {
    const columnVariableGroupRowMultiplier =
      maxGroupRowMultiplier[columnVariableGroup.id];

    if (columnVariableGroupRowMultiplier) {
      if (rowPossibilities <= columnVariableGroupRowMultiplier) {
        // rowPossibilities already applied from previous group value hence return 1
        return { rowPossibilities: 1, maxGroupRowMultiplier };
      }

      oldMaxGroupRowMultiplier = columnVariableGroupRowMultiplier;
      maxGroupRowMultiplier[columnVariableGroup.id] = rowPossibilities;
    } else {
      maxGroupRowMultiplier[columnVariableGroup.id] = rowPossibilities;
    }
  }

  return { rowPossibilities, maxGroupRowMultiplier, oldMaxGroupRowMultiplier };
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

import { LockIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/layout';
import { CSSProperties } from 'react';

import { ContactSalesButton } from '@revelio/composed';
import { PageTitles } from '@revelio/core';

interface DataUnavailableBannerProps {
  style?: CSSProperties;
  featureUnavailable?: boolean;
}

function DataUnavailableBanner({
  style,
  featureUnavailable,
}: DataUnavailableBannerProps) {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 20px"
      width="100%"
      backgroundColor="#fff7c9"
      color="navyBlue.500"
      borderRadius="6px"
      style={style}
      data-testid="trial-user-data-unavailable-banner"
    >
      <Flex flexDirection="row" alignItems="center">
        <LockIcon width={15} height={17} />
        <Text
          marginLeft="15px"
          fontWeight="600"
          fontSize="14px"
          color="navyBlue.500"
        >
          {`This ${featureUnavailable ? 'feature' : 'data'} is not available for trial users. Please contact sales to
          upgrade.`}
        </Text>
      </Flex>
      <ContactSalesButton
        page={PageTitles.TALENT_DISCOVERY}
        buttonProps={{ size: 'xs' }}
      />
    </Flex>
  );
}

export default DataUnavailableBanner;

import { useToast } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useRef } from 'react';

import { SelectionCategories, TreeItem } from '../../engine/filters.model';
import { SelectionListControls } from '../SelectionListControls';
import { TreeApi } from '../tree';
import { BreadcrumbTreeProps } from './BreadcrumbTree';
import styles from './BreadcrumbTreeFilter.module.css';
import { BreadcrumbTreeSelection } from './BreadcrumbTreeSelection';

type BreadcrumbTreeSelectionWithControlsProps = Pick<
  BreadcrumbTreeProps,
  'branches'
> & {
  selectionListIds: SelectionCategories[];
  initialSelections: Record<string, TreeItem>;
  renderWrapper: (props: { tree: ReactNode; controls: ReactNode }) => ReactNode;
  maxSelections?: number;
  filterLabel?: string;
  onClose?: () => void;
  onSubmit?: (items: Record<string, TreeItem>) => void;
  setNumSelections?: (num: number) => void;
};

export const BreadcrumbTreeSelectionWithControls = ({
  selectionListIds,
  initialSelections = {},
  branches,
  maxSelections,
  filterLabel,
  onClose,
  onSubmit,
  setNumSelections,
  renderWrapper,
}: BreadcrumbTreeSelectionWithControlsProps) => {
  const toast = useToast();
  const treeRef = useRef<TreeApi>(null);
  const [selections, setSelections] =
    useState<Record<string, TreeItem>>(initialSelections);

  useEffect(() => {
    if (setNumSelections) {
      setNumSelections(Object.keys(selections).length);
    }
  }, [selections, setNumSelections]);

  return renderWrapper({
    tree: (
      <BreadcrumbTreeSelection
        ref={treeRef}
        selectionListIds={selectionListIds}
        initialSelections={initialSelections}
        branches={branches}
        maxSelections={maxSelections}
        onMaxExceeded={() => {
          if (!toast.isActive('max-selections-toast')) {
            toast({
              id: 'max-selections-toast',
              position: 'top-right',
              status: 'warning',
              title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
              description: `You can only choose up to ${maxSelections} ${filterLabel ? filterLabel : 'items'} at a time!`,
              isClosable: true,
              variant: 'subtle',
            });
          }
        }}
        onChange={setSelections}
      />
    ),
    controls: (
      <SelectionListControls
        className={styles.controls}
        onClear={() => {
          setSelections({});
          treeRef.current?.clearAll();
        }}
        onClose={onClose}
        onSubmit={() => onSubmit?.(selections)}
        clearText="Reset"
        submitText="Submit"
        isSubmitDisabled={isEmpty(selections)}
      />
    ),
  });
};

import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { flatten } from 'lodash';
import { useEffect, useMemo } from 'react';

import { authStore } from '@revelio/auth';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
} from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterMenu,
  FilterMenuItemOrConfig,
  FilterMenuLimits,
  FilterSetSaveMenu,
  FilterSets,
  FiltersUsedInTabs,
  LocalSelectionCategories,
  NestedEntity,
  OtherFilterNames,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  Tab,
  createSelectableFiltersMap,
  getNestedEntity,
  getProviderDefault,
  upsertFilter,
  upsertFiltersWithProvidedValue,
  useActiveFiltersState,
  useAdaptiveRoleTaxonomy,
  useDefaultLastMonth,
  usePrimaryFilter,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';
import { ViewTypes } from '@revelio/filtering';

import { useTrackPerformance } from '../../hooks/mixpanel/useTrackPerformance';
import DashboardPage from '../DashboardPage';
import { MainPostingsChartCard } from './components/main-postings-chart-card';
import { PostingsSalaryLineChartCard } from './components/postings-salary-line-chart-card';
import { PostingsTimeToFillLineChartCard } from './components/postings-time-to-fill-line-chart-card';
import { PostingsTopGroupedBarChartCard } from './components/postings-top-grouped-bar-chart-card';
import { usePostingsDataFetch, usePostingsTopDataFetch } from './data-fetch';

export interface PostingsProps {
  title: PageTitles[];
  primaryView: PrimaryView;
  viewType: Tab;
  primaryFilter: PrimaryFilters;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  requiredParams?: SelectionListIdNames[];
  primaryEntity?: NestedEntity;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryFiltersLimit: PrimaryFilterLimits | number;
  nonActiveSelectableFilters?: (SelectionCategories | SelectionCategories[])[];
  selectableFilters: FilterMenuItemOrConfig[];
  filterMenuLimits: FilterMenuLimits | number;
  additionalNonActiveFilters: (
    | OtherFilterNames
    | SelectionCategories
    | LocalSelectionCategories
  )[];
  trialNoResultsMessage?: JSX.Element;
  savedSetView: View;
}

export const providerFilterId = LocalSelectionCategories.PROVIDER;
export const metricModeFilterId = LocalSelectionCategories.METRIC_MODE;

const TopPostingsHeaderLookup: Record<Tab, string> = {
  [ViewTypes.COMPANY]: 'Roles',
  [ViewTypes.GEO]: 'Companies',
  [ViewTypes.ROLE]: 'Companies',
};

export function Postings({
  title,
  primaryView,
  viewType,
  primaryFilter,
  primaryFiltersLimit,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryViewFilters,
  selectableFilters,
  filterMenuLimits,
  additionalNonActiveFilters,
  nonActiveSelectableFilters = [
    providerFilterId as unknown as SelectionCategories,
    metricModeFilterId as unknown as SelectionCategories,
  ],
  savedSetView,
}: PostingsProps) {
  const view = Views.POSTING;

  const primaryEntity = getNestedEntity(viewType);
  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  useViewFilters([
    ...primaryFilters,
    ...selectableFiltersMap,
    LocalSelectionCategories.PROVIDER,
    LocalSelectionCategories.METRIC_MODE,
  ]);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFiltersLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: PrimaryFilterLimits.POSTINGS_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters,
  });

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
    LocalSelectionCategories.METRIC_MODE,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType: viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    deepLinkLimit: PrimaryFilterLimits.POSTINGS,
    limit: PrimaryFilterLimits.POSTINGS_DEFAULT,
    primaryFilters,
    supportPrimaryEntities: true,
  };
  useViewFilterDefaults(viewFilterDefaultArgs);
  useTabMeta({
    savedSetView,
    view,
    viewType,
    deepLinkLimit: PrimaryFilterLimits.POSTINGS,
    limit: PrimaryFilterLimits.POSTINGS_DEFAULT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.LAST_START_DATE,
  });

  usePrimaryFilter(primaryFilter);

  const activeFilters = useActiveFiltersState();
  const hasPostingMetric = activeFilters.some(
    (filter) => filter.id === SelectionCategories.POSTING_METRIC
  );

  const hasProvider = activeFilters.some(
    (filter) => filter.id === LocalSelectionCategories.PROVIDER
  );

  const metricMode = activeFilters.find(
    (filter) => filter.id === SelectionCategories.METRIC_MODE
  )?.value;

  const metricModeValue = (metricMode as SelectFilter<FilterItem>)?.id;

  useEffect(() => {
    if (!hasPostingMetric) {
      upsertFiltersWithProvidedValue({
        [SelectionCategories.POSTING_METRIC]: 'active',
      });
    }
  }, [hasPostingMetric]);

  useEffect(() => {
    if (!hasProvider) {
      const userHasWebsitePostings = authStore.getValue().user?.linkup_postings;
      const providerDefault = getProviderDefault(
        !!userHasWebsitePostings
      ).provider;
      upsertFilter(LocalSelectionCategories.PROVIDER, {
        ...providerDefault,
        isUserSubmitted: false,
      });
    }
  }, [hasProvider]);

  const {
    salaryData,
    timeToFillData,
    mainPostingData,
    activeEntityLabels,
    loading: postingsDataLoading,
  } = usePostingsDataFetch({ view: primaryView, primaryFilters });
  const {
    topData,
    colorMap,
    loading: topDataLoading,
  } = usePostingsTopDataFetch({
    view: primaryView,
    primaryFilters,
  });

  useTrackPerformance({
    loading: postingsDataLoading,
    eventName: 'plot_page_performance',
  });

  const isLoading = postingsDataLoading || topDataLoading;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading,
  });

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={isLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={true}
            isPrimaryChip={true}
            min={1}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                {...primaryEntity}
                maxSelections={primaryFiltersLimit}
                minSelections={1}
                activeLimit={primaryFiltersLimit}
              >
                {AddEntityButtonText[primaryFilter]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FilterChips
          filterNames={[...selectableFilters, ...nonActiveSelectableFilters]}
          view={Views.POSTING}
          variant="filterChip"
          limit={filterMenuLimits}
          offsetParent={[providerFilterId]}
          endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
          propsView={Views.POSTING}
          viewType={viewType}
          showGranularity
          addButton={
            <>
              <FilterMenu
                title="Filter"
                view={Views.POSTING}
                filters={[
                  ...selectableFilters,
                  ...nonActiveSelectableFilters,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
                selectMenuOpenDefault
                limit={filterMenuLimits}
                offsetParent={[providerFilterId]}
                viewIdForDefault={`${view}_${viewType}`}
              />
              <FilterSetSaveMenu view={savedSetView} />
            </>
          }
        />
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={{ base: 'repeat(6, 200px)', md: 'repeat(3, 1fr)' }}
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem
          gridRow={'1 / span 1'}
          gridColumn={'1 / span 1'}
          minHeight={0}
        >
          <PostingsSalaryLineChartCard
            data={salaryData}
            loading={postingsDataLoading}
            colorMap={colorMap}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 1', md: '1 / span 1' }}
          gridColumn={{ base: '1 / span 1', md: '2 / span 1' }}
          minHeight={0}
        >
          <PostingsTimeToFillLineChartCard
            data={timeToFillData}
            loading={postingsDataLoading}
            colorMap={colorMap}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 2', md: '2 / span 2' }}
          gridColumn={{ base: '1 / span 1', md: '1 / span 2' }}
          minHeight={0}
        >
          <MainPostingsChartCard
            data={mainPostingData}
            dimensionName={primaryView}
            entityNames={activeEntityLabels}
            metricMode={metricModeValue}
            loading={postingsDataLoading}
          />
        </GridItem>

        <GridItem
          gridRow={{ base: 'span 2', md: '1 / span 3' }}
          gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
          minHeight={0}
        >
          <PostingsTopGroupedBarChartCard
            data={topData}
            loading={topDataLoading}
            colorMap={colorMap}
            primaryDimension={TopPostingsHeaderLookup[viewType]}
            secondaryDimension="Companies"
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
}

export default Postings;

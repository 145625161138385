import QuickLRU from 'quick-lru';
import { useAsync } from 'react-use';

const millisInADay = 60000 * 60 * 24;
const dataCache = new QuickLRU({ maxAge: millisInADay, maxSize: 500 });

export const useFetchData = ({ urls = [] }: { urls: string[] }) => {
  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  async function isResponseOk(url: string, response: Response) {
    if (response.status >= 200 && response.status <= 299) {
      const data = await response.json();
      dataCache.set(url, data);
      return data;
    } else if (response.status >= 401 && response.status <= 499) {
      // nothing
    } else if (response.status >= 500 && response.status <= 599) {
      throw new Error('Oops! An Error Occurred.');
    } else {
      throw new Error(response.statusText);
    }
  }

  async function getData(url: string) {
    if (dataCache.has(url)) {
      return dataCache.get(url);
    } else {
      const resp = await fetch(url, defaultOptions);
      return isResponseOk(url, resp);
    }
  }

  const state = useAsync(async () => {
    const urlArray = urls.map((u) => getData(u));
    return Promise.all(urlArray);
  }, []);

  return state;
};

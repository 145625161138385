import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { upsertFilter } from '../../engine/filters.engine';
import {
  SelectionCategories,
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
} from '../../engine/filters.model';
import { TreeSelection, useTreeApi } from './index';
import { TreeData } from './useTreeApi';

export interface TreeSelectionFilterProps {
  filterName?: string;
  selectedIds?: string[];
  treeData: TreeData[];
  selectionListsData: SelectionListItems[];
  selectionListsIds: SelectionListIdNames[];
  disableParentSelect?: boolean;
  expandRootsByDefault?: boolean;
  onClose?: () => void;
  onSelectionChange?: (selectedIds: string[]) => void;
}

export interface TreeSelectionFilterRef {
  handleClear: () => void;
  handleSubmit: () => void;
  selectedIds: string[];
}

export const TreeSelectionFilter = forwardRef<
  TreeSelectionFilterRef,
  TreeSelectionFilterProps
>(
  (
    {
      filterName,
      selectedIds = [],
      treeData = [],
      selectionListsData = [],
      selectionListsIds = [],
      disableParentSelect = false,
      expandRootsByDefault = false,
      onClose,
      onSelectionChange,
    },
    ref
  ) => {
    const [search, setSearch] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);

    const treeApi = useTreeApi({
      data: treeData,
      value: selectedIds,
      onChange: handleChange,
    });

    useImperativeHandle(ref, () => ({
      handleClear,
      handleSubmit,
      selectedIds,
    }));

    const handleSearch = (value: string) => {
      setSearch(value);
      treeApi.onSearch(value);
    };

    const handleClear = () => {
      onSelectionChange?.([]);
      treeApi.clearAll();
    };

    function handleChange(value: string[]) {
      onSelectionChange?.(value);
    }

    const handleSubmit = () => {
      if (filterName) {
        const selectedItems = selectedIds
          .map((id) => {
            const parts = id.split('_');
            const lastId = parts[parts.length - 1];
            const selectionListId =
              parts.length > 1
                ? selectionListsIds[parts.length - 1]
                : selectionListsIds[0];

            const selectionList = selectionListsData.find(
              (list) => list.id === selectionListId
            );
            if (selectionList) {
              const item = selectionList.value.find(
                (item: any) => String(item.id) === lastId
              );
              return item;
            }
            return null;
          })
          .filter(Boolean) as SelectionListItem[];

        upsertFilter(filterName as SelectionCategories, {
          selectionListId: selectionListsIds[0],
          value: selectedItems,
          isMulti: true,
        });
      }

      onClose?.();
    };

    const disabledLevels = disableParentSelect ? [0] : undefined;

    return (
      <Box ref={containerRef} width="100%" mb={2}>
        {selectionListsData.length > 0 && (
          <>
            <InputGroup size="sm" mb={2}>
              <Input
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search..."
                size="sm"
                pr="2rem"
              />
              <InputRightElement h="100%" w="2rem" pointerEvents="auto">
                {search ? (
                  <CloseIcon
                    boxSize={3}
                    cursor="pointer"
                    onClick={() => handleSearch('')}
                  />
                ) : (
                  <SearchIcon boxSize={3} />
                )}
              </InputRightElement>
            </InputGroup>

            {treeData.length > 0 ? (
              <TreeSelection
                data={treeData}
                treeApi={treeApi}
                disabled={disabledLevels}
                sortSuggestedSearch={false}
                openByDefault={expandRootsByDefault}
              />
            ) : (
              <Box p={2} textAlign="center" color="gray.500">
                {search ? 'No results found' : 'No items available'}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
);

export default TreeSelectionFilter;

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Badge,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { HealthCheckResult } from '@revelio/data-access';

interface DailyBreakdownProps {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  results: HealthCheckResult[];
  isOverall: boolean;
}

export const DailyBreakdown: React.FC<DailyBreakdownProps> = ({
  isOpen,
  onClose,
  date,
  results,
  isOverall,
}) => {
  const sortedResults = React.useMemo(() => {
    return [...results].sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
  }, [results]);

  const stats = React.useMemo(() => {
    const total = results.length;
    const failures = results.filter((r) => r.status === 'failure').length;
    const avgResponseTime =
      results.reduce((sum, r) => sum + r.responseTime, 0) / total;

    return {
      total,
      failures,
      successes: total - failures,
      avgResponseTime: Math.round(avgResponseTime),
    };
  }, [results]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth={isOverall ? '1250px' : '800px'}>
        <ModalHeader>
          Health Check Results for{' '}
          {date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={6} align="stretch">
            {/* Summary Stats */}
            <HStack spacing={8} py={4} px={6} bg="gray.50" borderRadius="md">
              <VStack align="flex-start">
                <Text color="gray.600" fontSize="sm">
                  Total Checks
                </Text>
                <Text fontSize="2xl" fontWeight="bold">
                  {stats.total}
                </Text>
              </VStack>
              <VStack align="flex-start">
                <Text color="gray.600" fontSize="sm">
                  Success Rate
                </Text>
                <Text fontSize="2xl" fontWeight="bold">
                  {((stats.successes / stats.total) * 100).toFixed(1)}%
                </Text>
              </VStack>
              <VStack align="flex-start">
                <Text color="gray.600" fontSize="sm">
                  Avg Response Time
                </Text>
                <Text fontSize="2xl" fontWeight="bold">
                  {stats.avgResponseTime}ms
                </Text>
              </VStack>
            </HStack>

            {/* Results Table */}
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Time</Th>
                    <Th>Status</Th>
                    {isOverall && (
                      <>
                        <Th>Service</Th>
                        <Th>View Group</Th>
                        <Th>View</Th>
                      </>
                    )}
                    <Th isNumeric>Response Time</Th>
                    <Th>Error</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedResults.map((result) => (
                    <Tr key={result.timestamp}>
                      <Td whiteSpace="nowrap">
                        {new Date(result.timestamp).toLocaleTimeString()}
                      </Td>
                      <Td>
                        <HStack>
                          <Icon
                            as={
                              result.status === 'success'
                                ? CheckCircleIcon
                                : WarningIcon
                            }
                            color={
                              result.status === 'success'
                                ? 'green.500'
                                : 'red.500'
                            }
                          />
                          <Badge
                            colorScheme={
                              result.status === 'success' ? 'green' : 'red'
                            }
                          >
                            {result.status}
                          </Badge>
                        </HStack>
                      </Td>
                      {isOverall && (
                        <>
                          <Td>{result.service}</Td>
                          <Td>{result.viewGroup}</Td>
                          <Td>{result.view}</Td>
                        </>
                      )}
                      <Td isNumeric>{result.responseTime}ms</Td>
                      <Td>
                        {result.error && (
                          <Text
                            color="red.500"
                            fontSize="sm"
                            noOfLines={2}
                            maxW="300px"
                          >
                            {result.error.message}
                          </Text>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { get, isEmpty, noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { filter, map, pipe } from 'rxjs';

import {
  ActionModalControlPanel,
  ActionModalControlPanelProps,
  Subsidiary,
} from '@revelio/core';

import { useSelectionLists } from '../../engine/filters.engine';
import { SelectionCategories } from '../../engine/filters.model';
import { TreeMethodHandle } from '../filter-menu/types';
import { TreeNode } from '../tree/async-tree/async-tree.types';
import { CompanySearchTreeRef } from '../tree/search-trees/company-search-tree/company-search-tree';
import { arrayToTree } from '../utils/arrayToTree';

export interface EntitySelectionsModalProps
  extends Partial<ActionModalControlPanelProps> {
  header?: string;
  placeholderSelectionList?: SelectionCategories[];
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  submitHandler?: (selections: Subsidiary[]) => void;
  entityMenuHandlers?: {
    text: string;
    handler: (selections: Subsidiary[]) => void;
  }[];
  submitIsLoading?: boolean;
  setSubmitIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  treeHeight?: number;
  closeOnSubmit?: boolean;
  initialSelections?: Record<string, Subsidiary>;
}

export function EntitySelectionsModal({
  header = '',
  placeholderSelectionList = [],
  isOpen,
  onClose = noop,
  onCancel,
  submitHandler,
  entityMenuHandlers,
  submitIsLoading,
  submitText,
  treeHeight = 310,
  closeOnSubmit = true,
  initialSelections = {},
}: EntitySelectionsModalProps) {
  const [selections, setSelections] =
    useState<Record<string, Subsidiary>>(initialSelections);

  const treeRef = useRef<{ value: TreeMethodHandle }>();

  const handleReset = () => {
    treeRef.current?.value.handleClearSelections?.();
  };

  const handleSubmit = () => {
    const value = Object.values(selections);

    submitHandler?.(value);
    setSelections({});

    if (closeOnSubmit) {
      onClose();
    }
  };

  const adaptedMenuHandlers = entityMenuHandlers?.map((menuItem) => ({
    ...menuItem,
    handler: () => menuItem.handler(Object.values(selections)),
  }));

  const sortByHeadcountDescending = (a: TreeNode, b: TreeNode) => {
    const headcountA = get(a, 'item.raw_emp_count');
    const headcountB = get(b, 'item.raw_emp_count');

    return headcountB - headcountA;
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setSelections(initialSelections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);

  useSelectionLists(
    placeholderSelectionList,
    undefined,
    pipe(filter((lists) => lists.length > 0)),
    pipe(
      map((res) => {
        if (res.error) {
          return;
        }
        // TODO: we currently only accept a a flat placeholder list,
        // we can extend this in the future to allow for nested lists

        const { rootItems } = arrayToTree(...res);

        setPlaceholderList(rootItems as TreeNode[]);
      })
    )
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minHeight="530px">
        <ModalHeader
          borderBottom="1px solid #E5EBF1"
          fontSize="17px"
          fontWeight="600"
          py="12px"
        >
          {header}
        </ModalHeader>
        <ModalCloseButton size="sm" />
        <ModalBody py="16px">
          <CompanySearchTreeRef
            placeholderListNodes={placeholderList}
            treeHeight={treeHeight}
            selections={selections}
            setSelections={setSelections}
            sortFn={sortByHeadcountDescending}
            ref={(element: TreeMethodHandle) =>
              (treeRef.current = {
                value: element,
              })
            }
          />
        </ModalBody>
        <ModalFooter borderTop="1px solid #E5EBF1">
          <ActionModalControlPanel
            submitText={submitText}
            onClose={onClose}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            menuHandlers={adaptedMenuHandlers}
            onReset={handleReset}
            submitIsDisabled={isEmpty(selections)}
            submitIsLoading={submitIsLoading}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

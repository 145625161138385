import { PopoverBody, PopoverHeader } from '@chakra-ui/react';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { SelectionCategories, TreeItem } from '../../engine/filters.model';
import {
  Segment,
  SegmentForm,
  SegmentSelectionForm,
  SubFilterInputForm,
  segmentOptions,
} from './filter-form';
import { NewSegmentTreeForm } from './filter-form/NewSegmentTreeForm';
import { SegmentTreeForm } from './filter-form/segment-tree-form';
import { useSubfilterControls } from './hooks/useSubfilterControls';
import { ScreenerSubFilterState } from './screener-filter-reducer';
import { SegmentBreadcrumbs } from './segment-breadcrumbs';
import { SubFilterValues } from './types';
import { parseSelectedSegmentsForDisplay } from './utils';

export const SubFilter = ({
  filterSelectDropdown,
  selectedFilterValue,
  subFilterOverride,
  currentStep,
  setCurrentStep,
  closeMenu,
  setSegmentCount,
  closeMenuFromTree,
}: {
  filterSelectDropdown: ReactNode;
  selectedFilterValue: SubFilterValues;
  subFilterOverride?: ScreenerSubFilterState;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  closeMenu: () => void;
  setSegmentCount: Dispatch<SetStateAction<number>>;
  closeMenuFromTree: () => void;
}) => {
  const initialSegmentValues = subFilterOverride?.treeItems;

  const handleNextStep = () => setCurrentStep((prev) => prev + 1);
  const handlePreviousStep = () => setCurrentStep((prev) => prev - 1);
  const handleHomeStep = () => setCurrentStep(0);
  const handleSegmentSelect = (segment: Segment) => {
    setSelectedSegment(segment);
    handleNextStep();
  };

  const [tmpSegmentSelection, setTmpSegmentSelection] = useState<
    Record<string, TreeItem>
  >({});
  const [segmentSelection, setSegmentSelection] = useState<
    Record<string, TreeItem>
  >(initialSegmentValues || {});

  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);

  useEffect(() => {
    setSegmentSelection(initialSegmentValues || {});
  }, [initialSegmentValues, setSegmentSelection]);

  useEffect(() => {
    const parsedSegments = parseSelectedSegmentsForDisplay(segmentSelection);
    setSegmentCount(parsedSegments.length);
  }, [segmentSelection, setSegmentCount]);

  const onEditSegment = (key: string) => () => {
    setCurrentStep(2);
    const segment = segmentOptions.find((segment) =>
      segment.value.includes(key)
    );

    segment && setSelectedSegment(segment);
  };

  const handleAddSubFilter = () => {
    closeMenu();
  };

  const handleAddSegment = () => {
    if (selectedSegment) {
      const parsedSegments = Object.fromEntries(
        Object.entries(segmentSelection).filter(
          ([key]) => !selectedSegment.value.includes(key.split('.')[0])
        )
      );

      setSegmentSelection({ ...parsedSegments, ...tmpSegmentSelection });

      setCurrentStep(0);
      setSelectedSegment(null);
    }
  };

  const onRemoveSegment =
    (key: string) => (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.stopPropagation();
      const parsedSegments: Record<
        string,
        TreeItem<string>
      > = Object.fromEntries(
        Object.entries(segmentSelection).filter(
          ([segmentKey]) => !segmentKey.includes(key)
        )
      );

      setSegmentSelection(parsedSegments);
    };

  const clearSelections = () => {
    setSegmentSelection({});
  };

  const subfilterControls = useSubfilterControls(
    subFilterOverride?.filter,
    selectedFilterValue
  );

  return (
    <>
      <PopoverHeader padding={0} fontWeight="semibold" borderBottomWidth={0}>
        {currentStep === 0 && filterSelectDropdown}
      </PopoverHeader>
      <PopoverBody padding={0}>
        {currentStep === 0 && (
          <>
            <SegmentForm
              segmentSelection={segmentSelection}
              onEditSegment={onEditSegment}
              onRemoveSegment={onRemoveSegment}
              onNewSegment={handleNextStep}
            />
            <SubFilterInputForm
              updateId={subFilterOverride?.id}
              valueControls={subfilterControls}
              selectedFilterValue={selectedFilterValue}
              segmentSelection={segmentSelection}
              onSubmit={handleAddSubFilter}
              onClose={closeMenu}
              onClear={clearSelections}
            />
          </>
        )}

        {currentStep > 0 && (
          <SegmentBreadcrumbs
            selectedSegment={selectedSegment}
            currentStep={currentStep}
            onPreviousStep={handlePreviousStep}
          />
        )}

        {currentStep === 1 && (
          <SegmentSelectionForm
            onSegmentSelect={handleSegmentSelect}
            onClickBack={handleHomeStep}
          />
        )}

        {currentStep === 2 &&
          (selectedSegment?.label === 'Industry' ? (
            <NewSegmentTreeForm
              selectionListIds={selectedSegment.value as SelectionCategories[]}
              onClickBack={handleHomeStep}
              setTempTreeItems={setTmpSegmentSelection}
              onClickAddSegment={handleAddSegment}
              onClose={closeMenuFromTree}
            />
          ) : (
            <SegmentTreeForm
              onClickClose={closeMenuFromTree}
              selectedSegment={selectedSegment}
              setSegmentSelection={setTmpSegmentSelection}
              initialSegmentValues={segmentSelection}
              onClickBack={handleHomeStep}
              onClickAddSegment={handleAddSegment}
            />
          ))}
      </PopoverBody>
    </>
  );
};

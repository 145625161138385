import { useMemo } from 'react';

import { TimeFrameView } from '@revelio/core';
import {
  BarChartHorizontalData,
  LineData,
  StackedBarChartHorizontalData,
} from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import {
  Filter,
  FilterList,
  SelectFilter,
  SubFilterNames,
} from '@revelio/filtering';

import {
  getBarData,
  getBottomLineData,
  getSkillsTopData,
  getStackedBarData,
  getTopLineData,
} from '../utils';

export type TopPlotData = BarChartHorizontalData[] | LineData[];
export type BottomPlotData = StackedBarChartHorizontalData[] | LineData[];

const getSubfilterValues = ({
  filters,
  id,
}: {
  filters: Filter[];
  id: SubFilterNames;
}) => {
  const filterList = filters?.find((filter) => filter.id === id) as
    | SelectFilter<FilterList>
    | undefined;
  if (!filterList) return [];
  return filterList.value?.map((sub) => sub?.id);
};

export const useGetChartData = ({
  data,
  timeframe,
  filters,
}: {
  data: CompositionDataQuery | undefined;
  timeframe: TimeFrameView;
  filters: Filter[];
}) => {
  /** Get selected subfilter values for each overtime chart */
  const subfilters = useMemo(() => {
    return {
      role: getSubfilterValues({ filters, id: SubFilterNames.SUB_ROLE }),
      region: getSubfilterValues({ filters, id: SubFilterNames.SUB_REGION }),
      // seniority: getSubfilterValues({
      //   filters,
      //   id: SubFilterNames.SUB_SENIORITY,
      // }),
      /** TODO: Skill Plot Enable */
      skill: getSubfilterValues({
        filters,
        id: SubFilterNames.SUB_SKILL_OVERTIME,
      }),
      gender: getSubfilterValues({ filters, id: SubFilterNames.SUB_GENDER }),
      ethnicity: getSubfilterValues({
        filters,
        id: SubFilterNames.SUB_ETHNICITY,
      }),
      education: getSubfilterValues({
        filters,
        id: SubFilterNames.SUB_EDUCATION,
      }),
      industry: getSubfilterValues({
        filters,
        id: SubFilterNames.SUB_INDUSTRY,
      }),
    };
  }, [filters]);

  /** Convert data for each chart */
  return useMemo(() => {
    const entities = data?.composition;
    const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;
    const getTopData = isSnapshot ? getBarData : getTopLineData;
    const getBottomData = isSnapshot ? getStackedBarData : getBottomLineData;

    return {
      headcountData: getTopData({ entities, plotName: 'headcount' }),
      growthRateData: getTopData({ entities, plotName: 'growth_rate' }),
      hiringRateData: getTopData({ entities, plotName: 'hiring_rate' }),
      attritionRateData: getTopData({ entities, plotName: 'attrition_rate' }),
      tenureData: getTopData({ entities, plotName: 'tenure' }),
      salaryData: getTopData({ entities, plotName: 'salary' }),
      roleData: getBottomData({
        entities,
        plotName: 'job_categories',
        subfilters: subfilters.role,
      }),
      geographyData: getBottomData({
        entities,
        plotName: 'geographies',
        subfilters: subfilters.region,
      }),
      // seniorityData: getBottomData({
      //   entities,
      //   plotName: 'seniorities',
      //   subfilters: subfilters.seniority,
      // }),
      /** TODO: Skill Plot Enable */
      skillData: isSnapshot
        ? getSkillsTopData({ entities })
        : getBottomLineData({
            entities,
            plotName: 'skills',
            subfilters: subfilters.skill,
          }),
      genderData: getBottomData({
        entities,
        plotName: 'genders',
        subfilters: subfilters.gender,
      }),
      ethnicityData: getBottomData({
        entities,
        plotName: 'ethnicities',
        subfilters: subfilters.ethnicity,
      }),
      educationData: getBottomData({
        entities,
        plotName: 'educations',
        subfilters: subfilters.education,
      }),
      industryData: getBottomData({
        entities,
        plotName: 'industries',
        subfilters: subfilters.industry,
      }),
    };
  }, [data, timeframe, subfilters]);
};

export type ChartData = ReturnType<typeof useGetChartData>;

import { Option } from '../selection-list/selection-list-select';
import { TreeData } from '../tree';

export const sortSelectionList = (
  options: Option[],
  selectedIds: string[]
): Option[] => {
  if (!options || options.length === 0) return [];

  return options.sort((a, b) => {
    const aData: ComparisonItem = {
      isSelected: selectedIds.includes(a.value),
      isOther: a.label === 'Other',
      originalIndex: options.indexOf(a),
    };

    const bData: ComparisonItem = {
      isSelected: selectedIds.includes(b.value),
      isOther: b.label === 'Other',
      originalIndex: options.indexOf(b),
    };

    return compare(aData, bData);
  });
};

export const sortTreeNodes = (
  nodes: TreeData[],
  selectedIds: string[]
): TreeData[] => {
  if (!nodes || nodes.length === 0) return [];

  return [...nodes].sort((a, b) => {
    const aData: ComparisonItem = {
      isSelected: isNodeOrDescendantSelected(a, selectedIds),
      isOther: a.name === 'Other',
      originalIndex: nodes.indexOf(a),
    };

    const bData: ComparisonItem = {
      isSelected: isNodeOrDescendantSelected(b, selectedIds),
      isOther: b.name === 'Other',
      originalIndex: nodes.indexOf(b),
    };

    return compare(aData, bData);
  });
};

export const deepCloneAndSortTree = (
  nodes: TreeData[],
  selectedIds: string[]
): TreeData[] => {
  if (!nodes || nodes.length === 0) return [];

  const clonedNodes = nodes.map((node) => ({
    ...node,
    children: node.children ? [...node.children] : undefined,
  }));

  const sortedNodes = sortTreeNodes(clonedNodes, selectedIds);

  sortedNodes.forEach((node) => {
    if (node.children && node.children.length > 0) {
      node.children = deepCloneAndSortTree(node.children, selectedIds);
    }
  });

  return sortedNodes;
};

interface ComparisonItem {
  isSelected: boolean;
  isOther: boolean;
  originalIndex: number;
}

const isNodeOrDescendantSelected = (
  node: TreeData,
  selectedIds: string[]
): boolean => {
  if (selectedIds.includes(String(node.id))) return true;

  if (node.children) {
    return node.children.some((child: TreeData) => {
      const childId = String(child.id);
      return (
        selectedIds.includes(childId) ||
        selectedIds.some((id) => id.startsWith(`${node.id}_`))
      );
    });
  }

  return false;
};

const compare = (a: ComparisonItem, b: ComparisonItem): number => {
  if (a.isSelected !== b.isSelected) {
    return a.isSelected ? -1 : 1;
  }

  const aIsOther = a.isOther;
  const bIsOther = b.isOther;

  if (!a.isSelected && !b.isSelected && aIsOther !== bIsOther) {
    return aIsOther ? 1 : -1;
  }

  return a.originalIndex - b.originalIndex;
};

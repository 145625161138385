import {
  HealthCheckConfig,
  HealthCheckResult,
  getHealthCheckId,
} from '@revelio/data-access';
import { GroupingType } from '../types';
import { OutageStatus } from '../healthCheck';

export const MAJOR_OUTAGE_THRESHOLD = 4; // 4 or more failures in a day is considered a major outage

export const determineOutageStatus = (failureCount: number): OutageStatus => {
  if (failureCount >= MAJOR_OUTAGE_THRESHOLD) return 'major_outage';
  if (failureCount > 0) return 'partial_outage';
  return 'operational';
};

export const getGroupKey = (
  check: HealthCheckConfig,
  groupingType: GroupingType
): string => {
  switch (groupingType) {
    case 'viewGroup':
      return check.viewGroup;
    case 'service':
      return check.service;
    case 'page':
      return `${check.viewGroup} ${check.view}`;
    default:
      return check.viewGroup;
  }
};

export const getGroupLabel = (
  groupKey: string,
  groupingType: GroupingType,
  checks: HealthCheckConfig[]
): string => {
  switch (groupingType) {
    case 'viewGroup':
      return groupKey.charAt(0).toUpperCase() + groupKey.slice(1);
    case 'service':
      return groupKey;
    case 'page':
      return groupKey
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    default:
      return groupKey;
  }
};

export const getGroupTooltip = (checks: HealthCheckConfig[]): string => {
  const uniqueServices = new Set(checks.map((check) => check.service));
  return `Services: ${Array.from(uniqueServices).join(', ')}`;
};

export const getLatestResultForCheck = (
  results: HealthCheckResult[],
  service: string,
  viewGroup: string,
  view: string
): HealthCheckResult | undefined => {
  return results
    .filter(
      (r) =>
        r.service === service && r.viewGroup === viewGroup && r.view === view
    )
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )[0];
};

export const getLatestNonManualResultForCheck = (
  results: HealthCheckResult[],
  service: string,
  viewGroup: string,
  view: string
): HealthCheckResult | undefined => {
  return results
    .filter(
      (r) =>
        !r.isManualCheck &&
        r.service === service &&
        r.viewGroup === viewGroup &&
        r.view === view
    )
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )[0];
};

export const processResultsForDisplay = (
  results: HealthCheckResult[]
): {
  latest: Map<string, HealthCheckResult>;
  latestNonManual: Map<string, HealthCheckResult>;
} => {
  const latestResults = new Map<string, HealthCheckResult>();
  const latestNonManualResults = new Map<string, HealthCheckResult>();

  const uniqueChecks = new Set(
    results.map((r) => getHealthCheckId(r.service, r.viewGroup, r.view))
  );

  uniqueChecks.forEach((checkId) => {
    const [service, viewGroup, view] = checkId.split(':');

    const latest = getLatestResultForCheck(results, service, viewGroup, view);
    if (latest) {
      latestResults.set(checkId, latest);
    }

    const latestNonManual = getLatestNonManualResultForCheck(
      results,
      service,
      viewGroup,
      view
    );
    if (latestNonManual) {
      latestNonManualResults.set(checkId, latestNonManual);
    }
  });

  return { latest: latestResults, latestNonManual: latestNonManualResults };
};

export const getGroupHealth = (results: HealthCheckResult[]): string => {
  if (!results.length) return 'gray';
  const successCount = results.filter((r) => r.status === 'success').length;
  const successRate = successCount / results.length;

  if (successRate === 1) return 'green';
  if (successRate >= 0.8) return 'yellow';
  return 'red';
};

import { Link, Text } from '@chakra-ui/layout';
import { ReactNode } from 'react';

import { Tab, ViewTypes } from '@revelio/filtering';

import { TransitionMetricName } from './types';

type TransitionStackPlotConfig = {
  name: TransitionMetricName;
  label: string;
  info: ReactNode;
};

const RoleConfig: TransitionStackPlotConfig = {
  name: 'role',
  label: 'Role',
  info: (
    <Text color="white" fontSize="12px">
      The composition of workers by occupation. More information on the job
      representation and taxonomy can be found{' '}
      <Link
        href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
        isExternal
        color="#89dcad"
        fontSize="12px"
        fontWeight="semibold"
      >
        here
      </Link>
      .
    </Text>
  ),
};

const GeographyConfig: TransitionStackPlotConfig = {
  name: 'geography',
  label: 'Geography',
  info: 'The composition of workers by geography.',
};

const IndustryConfig: TransitionStackPlotConfig = {
  name: 'industry',
  label: 'Industry',
  info: 'The composition of workers by industry.',
};

const SeniorityConfig: TransitionStackPlotConfig = {
  name: 'seniority',
  label: 'Seniority',
  info: (
    <Text color="white" fontSize="12px">
      The composition of workers by seniority. More information on the seniority
      model can be found{' '}
      <Link
        href="https://www.data-dictionary.reveliolabs.com/methodology.html#seniority"
        isExternal
        color="#89dcad"
        fontSize="12px"
        fontWeight="semibold"
      >
        here
      </Link>
      .
    </Text>
  ),
};

const GenderConfig: TransitionStackPlotConfig = {
  name: 'gender',
  label: 'Gender',
  info: (
    <Text color="white" fontSize="12px">
      The composition of workers by gender, predicted using first name. More
      information on the gender model can be found{' '}
      <Link
        href="https://www.data-dictionary.reveliolabs.com/methodology.html#gender-and-ethnicity"
        isExternal
        color="#89dcad"
        fontSize="12px"
        fontWeight="semibold"
      >
        here
      </Link>
      .
    </Text>
  ),
};

const EthnicityConfig: TransitionStackPlotConfig = {
  name: 'ethnicity',
  label: 'Ethnicity',
  info: (
    <Text color="white" fontSize="12px">
      The composition of workers by ethnicity, predicted using first name, last
      name, and geography. More information on the ethnicity model can be found{' '}
      <Link
        href="https://www.data-dictionary.reveliolabs.com/methodology.html#gender-and-ethnicity"
        isExternal
        color="#89dcad"
        fontSize="12px"
        fontWeight="semibold"
      >
        here
      </Link>
      .
    </Text>
  ),
};

const EducationConfig: TransitionStackPlotConfig = {
  name: 'education',
  label: 'Education',
  info: 'The composition of workers by the highest educational degree.',
};

export const companyTransitionStackPlots: TransitionStackPlotConfig[] = [
  RoleConfig,
  GeographyConfig,
  SeniorityConfig,
  GenderConfig,
  EthnicityConfig,
  EducationConfig,
];

export const geographyTransitionStackPlots: TransitionStackPlotConfig[] = [
  RoleConfig,
  IndustryConfig,
  SeniorityConfig,
  GenderConfig,
  EthnicityConfig,
  EducationConfig,
];

export const roleTransitionStackPlots: TransitionStackPlotConfig[] = [
  IndustryConfig,
  GeographyConfig,
  SeniorityConfig,
  GenderConfig,
  EthnicityConfig,
  EducationConfig,
];

export const transitionStackPlotConfigLookup: Record<
  Tab,
  TransitionStackPlotConfig[]
> = {
  [ViewTypes.COMPANY]: companyTransitionStackPlots,
  [ViewTypes.GEO]: geographyTransitionStackPlots,
  [ViewTypes.ROLE]: roleTransitionStackPlots,
};

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  LayoutProps,
  Link,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';

import { useUserTrialDetails } from '@revelio/auth';
import { Loading, PagePaths } from '@revelio/core';

import DataUnavailableBanner from '../../trialUser/dataUnavailableBanner';
import useIsTrialUserWithNoDatasetAccess from '../../trialUser/useIsTrialUserWithNoDatasetAccess';
import UploadIcon from '../data-enrichment/icons/UploadIcon';
import { DeliverableStepContainer } from '../deliverable-step-container';
import { DeliverablePaths } from '../deliverables-page';

const UploadFileStep = ({
  fileUploadHeight,
  onUpload,
  renderInDeliverablesContainer = false,
  sampleFileUrl,
  requiredColumnsTooltip,
  allowTrialUsersWithDatasetAccess,
}: {
  fileUploadHeight?: LayoutProps['h'];
  onUpload: (file: File) => Promise<void>;
  renderInDeliverablesContainer?: boolean;
  sampleFileUrl: string;
  requiredColumnsTooltip: string;
  allowTrialUsersWithDatasetAccess?: boolean;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptedDropError, setAcceptedDropError] = useState<string>();
  const { isTrialUser } = useUserTrialDetails();
  const isTrialWithNoDatasetAccess = useIsTrialUserWithNoDatasetAccess();

  const disableFileUpload = allowTrialUsersWithDatasetAccess
    ? !!isTrialWithNoDatasetAccess
    : !!isTrialUser;

  const dropzoneState = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    // maxSize: maxFileSize,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'text/csv': ['.csv'],
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((fileRejection) => {
        setAcceptedDropError(
          `"${fileRejection.file.name}" must be either a .csv, .xls, or .xlsx`
        );
      });
    },
    onDropAccepted: async ([file]) => {
      setLoading(true);
      setAcceptedDropError(undefined);
      if (disableFileUpload) {
        setAcceptedDropError('This feature is not available for trial users');
        setLoading(false);
        return;
      }
      try {
        await onUpload(file);
      } catch (e) {
        setAcceptedDropError((e as Error).message);
      }
      setLoading(false);
    },
  });

  if (renderInDeliverablesContainer) {
    return (
      <DeliverableStepContainer
        id="upload_file"
        title="Upload File"
        startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
        submitButtonContent="Choose file"
        onSubmit={dropzoneState.open}
        disableFade
        isSubmitLoading={loading}
        isDataBuilder={false}
        banner={
          disableFileUpload && <DataUnavailableBanner featureUnavailable />
        }
        isSubmitDisabled={!!disableFileUpload}
        submitTooltip={
          disableFileUpload && 'This feature is not available for trial users'
        }
      >
        <FileUpload
          fileUploadHeight={fileUploadHeight}
          dropzoneState={dropzoneState}
          loading={loading}
          sampleFileUrl={sampleFileUrl}
          requiredColumnsTooltip={requiredColumnsTooltip}
          uploadErrorMessage={acceptedDropError}
        />
      </DeliverableStepContainer>
    );
  }

  return (
    <FileUpload
      fileUploadHeight={fileUploadHeight}
      dropzoneState={dropzoneState}
      loading={loading}
      sampleFileUrl={sampleFileUrl}
      requiredColumnsTooltip={requiredColumnsTooltip}
      uploadErrorMessage={acceptedDropError}
    />
  );
};

const FileUpload = ({
  fileUploadHeight,
  dropzoneState: {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  },
  loading,
  sampleFileUrl,
  requiredColumnsTooltip,
  uploadErrorMessage,
}: {
  fileUploadHeight?: LayoutProps['h'];
  dropzoneState: DropzoneState;
  loading: boolean;
  sampleFileUrl: string;
  requiredColumnsTooltip: string;
  uploadErrorMessage: string | undefined;
}) => {
  let borderColor = 'gray.200';
  let bgColor = 'gray.50';
  let message: React.ReactNode = (
    <Flex flexDirection="column" alignItems="center">
      <UploadIcon />
      <Text textAlign="center" color="text.primary">
        Drag & drop your CSV or Excel file here,
      </Text>
      <Text textAlign="center" color="text.primary">
        or{' '}
        <Link
          onClick={open}
          color="lightBlue.600"
          textDecoration="underline"
          fontWeight={600}
          _hover={{ textDecoration: 'none' }}
        >
          choose file
        </Link>
      </Text>
    </Flex>
  );

  if (isDragActive) {
    borderColor = 'lightBlue.500';
    bgColor = 'lightBlue.100';
    message = 'Release to drop the files here';
  }

  if (isDragReject || uploadErrorMessage) {
    borderColor = 'red.500';
    bgColor = 'red.100';
  }

  if (isDragReject) {
    message = 'File type not accepted, try again!';
  }

  if (isDragAccept) {
    borderColor = 'green.500';
    bgColor = 'green.100';
    message = 'File accepted, release to drop!';
  }
  return (
    <VStack w="full" h={fileUploadHeight ?? 'full'} gap={4}>
      <Alert status="info" style={{ background: 'rgba(94, 201, 238,0.2)' }}>
        <Tooltip
          label={requiredColumnsTooltip}
          hasArrow
          placement="top-start"
          left="-6px"
          openDelay={375}
        >
          <Box>
            <AlertIcon />
          </Box>
        </Tooltip>
        <Box>
          <Text color="text.primary">
            Make sure your file includes the following required columns
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Text color="text.primary" fontSize="sm">
            Download{' '}
            <Link
              onClick={() => window.open(sampleFileUrl)}
              color="lightBlue.600"
              textDecoration="underline"
              fontWeight={600}
              _hover={{ textDecoration: 'none' }}
            >
              sample CSV
            </Link>{' '}
            file
          </Text>
        </Box>
      </Alert>
      <VStack
        {...getRootProps()}
        w="full"
        h="full"
        border="2px"
        borderStyle="dashed"
        borderColor={borderColor}
        borderRadius="md"
        bg={bgColor}
        alignItems="center"
        justifyContent="center"
        transition="border .24s ease-in-out, background .24s ease-in-out"
        _hover={{
          borderColor: 'gray.300',
        }}
        _focusWithin={{
          borderColor: 'lightBlue.500',
        }}
      >
        {loading ? (
          <VStack>
            <Loading size="sm" />
            <Text color="text.primary" fontSize="sm" opacity={0.8}>
              Processing file, please wait...
            </Text>
          </VStack>
        ) : (
          <>
            <input {...getInputProps()} />
            <Box>{message}</Box>
          </>
        )}
      </VStack>

      {uploadErrorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Upload Error</AlertTitle>
          <AlertDescription>{uploadErrorMessage}</AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};

export default UploadFileStep;

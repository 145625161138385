import { Filter, FilterList, SelectionCategories } from '@revelio/filtering';

export const getCompanyLookup = (filters: Filter[]) => {
  return [
    ...((filters.find((f) => f.id === SelectionCategories.COMPANY)
      ?.value as FilterList) ?? []),
    ...((filters.find((f) => f.id === SelectionCategories.INDUSTRY)
      ?.value as FilterList) ?? []),
    ...((filters.find((f) => f.id === SelectionCategories.RICS_K10)
      ?.value as FilterList) ?? []),
    ...((filters.find((f) => f.id === SelectionCategories.RICS_K50)
      ?.value as FilterList) ?? []),
    ...((filters.find((f) => f.id === SelectionCategories.RICS_K400)
      ?.value as FilterList) ?? []),
  ].reduce(
    (acc, filter) => ({
      ...acc,
      [filter.rcid || filter.id]: filter.label,
    }),
    {}
  );
};

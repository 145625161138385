import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  RICS_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import Overview from '../../../overview/overview';

const title = [PageTitles.GEOGRAPHY, PageTitles.COMPOSITIONS];

const primaryViewFilters = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
  },
];

export function GeographyCompositions() {
  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    { filters: RICS_FILTERS, isNested: true, limit: 10 },
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
  ];

  const selectableFilters = {
    snapshot: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.SNAPSHOT_DATE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
    overtime: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.DATE_RANGE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
  };

  return (
    <Overview
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      savedSetView={View.Geography}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      primaryViewFilterLimit={PrimaryFilterLimits.GEO_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.GEO_OVERVIEW}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_COMPOSITIONS}
    />
  );
}

export default GeographyCompositions;

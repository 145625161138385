import { useDisclosure } from '@chakra-ui/react';

import styles from './sankey-chart.module.css';
import { SankeyPathTooltip } from './sankey-path-tooltip';
import { LinkPath } from './types';

type SankeyPathProps = {
  link: LinkPath;
  isOutflows: boolean;
};

export const SankeyPath = ({ link, isOutflows }: SankeyPathProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <path
        d={link.path}
        fill="none"
        stroke="#5CA9E9"
        strokeWidth={link?.width}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        className={styles.path}
        data-testid={`sankey-chart__path`}
      />
      <SankeyPathTooltip d={link} isOpen={isOpen} isOutflows={isOutflows}>
        <circle
          r={0}
          cx={(link.target.x1 + link.source.x0) / 2}
          cy={(link.y1 + link.y0) / 2}
        />
      </SankeyPathTooltip>
    </>
  );
};

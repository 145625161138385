import {
  CompositionSupportedViewTypes,
  EndpointSegment,
  FilterStateDefaultsMap,
  OtherFilterNames,
  PlotConfigParams,
  RICS_FILTERS,
  //SKILL_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  SubFilterNames,
  ViewTypes,
} from '@revelio/filtering';

import {
  OverTimeChartPropsLookup,
  SnapshotChartPropsLookup,
} from './chart-props.config';

export const OverviewPlotConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.ROLE,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ROLE],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ROLE],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_ROLE,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_ROLE,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_ROLE,
      selectionLists: ROLE_GRANULARITY_FILTERS,
      subfiltersMap: {
        [SelectionCategories.JOB_CATEGORY]: SelectionCategories.ROLE_K50,
        [SelectionCategories.ROLE_K150]: SelectionCategories.ROLE_K500,
        [SelectionCategories.ROLE_K1500]: SelectionCategories.ROLE_K1500,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_ROLE],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GEO,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GEO],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GEO],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_REGION,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_REGION,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_REGION,
      selectionLists: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      subfiltersMap: {
        [SelectionCategories.REGION]: SelectionCategories.COUNTRY,
        [SelectionCategories.COUNTRY]: SelectionCategories.METRO_AREA,
        [SelectionCategories.METRO_AREA]: SelectionCategories.METRO_AREA,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_REGION],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  // TODO: Quick Revert of Skills Plot can remove this later
  // {
  //   endpoint: EndpointSegment.SENIORITY,
  //   chartProps:
  //     SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SENIORITY],
  //   preFetchConfig: {
  //     chartProps:
  //       OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SENIORITY],
  //     additionalNonActiveFilters: [
  //       SelectionCategories.DATE_RANGE,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //     brokenOutFilterIds: [
  //       SelectionCategories.PRIMARY_FILTER,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SelectionCategories.DATE_RANGE,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //   },
  //   subfilters: {
  //     filterName: SubFilterNames.SUB_SENIORITY,
  //     selectionLists: [SelectionCategories.SENIORITY],
  //     placement: 'bottom',
  //   },
  //   metaData: {
  //     isGqlQuery: true,
  //     isGoRequest: true,
  //   },
  // },
  {
    endpoint: EndpointSegment.SKILL,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SKILL],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SKILL],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_SKILL,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_SKILL,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_SKILL_OVERTIME,
      selectionLists: SKILL_GRANULARITY_FILTERS,
      subfiltersMap: {
        [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K700,
        [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K3000,
        [SelectionCategories.SKILL_K3000]: SelectionCategories.SKILL_K3000,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_SKILL_OVERTIME],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GENDER,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GENDER],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GENDER],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_GENDER,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_GENDER,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_GENDER,
      selectionLists: [SelectionCategories.GENDER],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ETHNICITY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ETHNICITY],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_ETHNICITY,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_ETHNICITY,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_ETHNICITY,
      selectionLists: [SelectionCategories.ETHNICITY],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.EDUCATION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.EDUCATION],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_EDUCATION,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_EDUCATION,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_EDUCATION,
      selectionLists: [SelectionCategories.HIGHEST_DEGREE],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const GeoOverviewPlotConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.ROLE,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.ROLE],
    preFetchConfig: {
      chartProps: OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.ROLE],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_ROLE,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_ROLE,
      ],
    },

    subfilters: {
      filterName: SubFilterNames.SUB_ROLE,
      selectionLists: ROLE_GRANULARITY_FILTERS,
      default: FilterStateDefaultsMap[SubFilterNames.SUB_ROLE],
      subfiltersMap: {
        [SelectionCategories.JOB_CATEGORY]: SelectionCategories.ROLE_K50,
        [SelectionCategories.ROLE_K150]: SelectionCategories.ROLE_K500,
        [SelectionCategories.ROLE_K1500]: SelectionCategories.ROLE_K1500,
      },
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.INDUSTRY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.INDUSTRY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.INDUSTRY],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_INDUSTRY,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_INDUSTRY,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_INDUSTRY,
      selectionLists: [...RICS_FILTERS],
      default: FilterStateDefaultsMap[SubFilterNames.SUB_INDUSTRY],
      subfiltersMap: {
        [SelectionCategories.RICS_K10]: SelectionCategories.RICS_K50,
        [SelectionCategories.RICS_K50]: SelectionCategories.RICS_K50,
      },
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  // TODO: Quick revert
  // {
  //   endpoint: EndpointSegment.SENIORITY,
  //   chartProps:
  //     SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.SENIORITY],
  //   preFetchConfig: {
  //     chartProps:
  //       OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.SENIORITY],
  //     additionalNonActiveFilters: [
  //       SelectionCategories.DATE_RANGE,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //     brokenOutFilterIds: [
  //       SelectionCategories.PRIMARY_FILTER,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SelectionCategories.DATE_RANGE,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //   },
  //   subfilters: {
  //     filterName: SubFilterNames.SUB_SENIORITY,
  //     selectionLists: [SelectionCategories.SENIORITY],
  //     default: FilterStateDefaultsMap[SubFilterNames.SUB_SENIORITY],
  //     placement: 'bottom',
  //   },
  //   metaData: {
  //     isGqlQuery: true,
  //     isGoRequest: true,
  //   },
  // },

  {
    endpoint: EndpointSegment.SKILL,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.SKILL],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.SKILL],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_SKILL,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_SKILL,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_SKILL_OVERTIME,
      selectionLists: SKILL_GRANULARITY_FILTERS,
      subfiltersMap: {
        [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K700,
        [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K3000,
        [SelectionCategories.SKILL_K3000]: SelectionCategories.SKILL_K3000,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_SKILL_OVERTIME],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GENDER,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.GENDER],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.GENDER],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_GENDER,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_GENDER,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_GENDER,
      selectionLists: [SelectionCategories.GENDER],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.ETHNICITY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.ETHNICITY],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_ETHNICITY,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_ETHNICITY,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_ETHNICITY,
      selectionLists: [SelectionCategories.ETHNICITY],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.EDUCATION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.EDUCATION],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_EDUCATION,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_EDUCATION,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_EDUCATION,
      selectionLists: [SelectionCategories.EDUCATION],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const RoleOverviewPlotConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.INDUSTRY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.INDUSTRY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.INDUSTRY],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_INDUSTRY,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_INDUSTRY,
      ],
    },

    subfilters: {
      filterName: SubFilterNames.SUB_INDUSTRY,
      selectionLists: [SelectionCategories.INDUSTRY],
      default: FilterStateDefaultsMap[SubFilterNames.SUB_INDUSTRY],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GEO,
    chartProps: SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GEO],
    preFetchConfig: {
      chartProps: OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GEO],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_REGION,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_REGION,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_REGION,
      selectionLists: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      subfiltersMap: {
        [SelectionCategories.REGION]: SelectionCategories.COUNTRY,
        [SelectionCategories.COUNTRY]: SelectionCategories.METRO_AREA,
        [SelectionCategories.METRO_AREA]: SelectionCategories.METRO_AREA,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_REGION],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  // {
  //   endpoint: EndpointSegment.SENIORITY,
  //   chartProps:
  //     SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SENIORITY],
  //   preFetchConfig: {
  //     chartProps:
  //       OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SENIORITY],
  //     additionalNonActiveFilters: [
  //       SelectionCategories.DATE_RANGE,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //     brokenOutFilterIds: [
  //       SelectionCategories.PRIMARY_FILTER,
  //       OtherFilterNames.SUBFILTER,
  //       OtherFilterNames.GROUPED,
  //       SelectionCategories.DATE_RANGE,
  //       SubFilterNames.SUB_SENIORITY,
  //     ],
  //   },
  //   subfilters: {
  //     filterName: SubFilterNames.SUB_SENIORITY,
  //     selectionLists: [SelectionCategories.SENIORITY],
  //     placement: 'bottom',
  //   },
  //   metaData: {
  //     isGqlQuery: true,
  //     isGoRequest: true,
  //   },
  // },
  {
    endpoint: EndpointSegment.SKILL,
    chartProps: SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SKILL],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SKILL],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_SKILL,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_SKILL,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_SKILL_OVERTIME,
      selectionLists: SKILL_GRANULARITY_FILTERS,
      subfiltersMap: {
        [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K700,
        [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K3000,
        [SelectionCategories.SKILL_K3000]: SelectionCategories.SKILL_K3000,
      },
      default: FilterStateDefaultsMap[SubFilterNames.SUB_SKILL_OVERTIME],
      disableParentSelect: false,
      disableChildren: true,
      placement: 'bottom',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GENDER,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GENDER],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GENDER],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_GENDER,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_GENDER,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_GENDER,
      selectionLists: [SelectionCategories.GENDER],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.ETHNICITY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.ETHNICITY],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_ETHNICITY,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_ETHNICITY,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_ETHNICITY,
      selectionLists: [SelectionCategories.ETHNICITY],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.EDUCATION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.EDUCATION],
      additionalNonActiveFilters: [
        SelectionCategories.DATE_RANGE,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SubFilterNames.SUB_EDUCATION,
      ],
      brokenOutFilterIds: [
        SelectionCategories.PRIMARY_FILTER,
        OtherFilterNames.SUBFILTER,
        OtherFilterNames.GROUPED,
        SelectionCategories.DATE_RANGE,
        SubFilterNames.SUB_EDUCATION,
      ],
    },
    subfilters: {
      filterName: SubFilterNames.SUB_EDUCATION,
      selectionLists: [SelectionCategories.EDUCATION],
      placement: 'top',
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const BasePlotConfigLookup: {
  [key in CompositionSupportedViewTypes]: PlotConfigParams[];
} = {
  [ViewTypes.COMPANY]: OverviewPlotConfigs,
  [ViewTypes.GEO]: GeoOverviewPlotConfigs,
  [ViewTypes.ROLE]: RoleOverviewPlotConfigs,
};

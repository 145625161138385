import React, { createContext, useContext, useState, useEffect } from 'react';
import { buildClientSchema, GraphQLSchema, IntrospectionQuery } from 'graphql';

// TypeScript thinks this import doesn't exist, but webpack will resolve it at build time
import { gqlSchema } from '@revelio/data-access';

// Define the schema context type
interface SchemaContextType {
  schema: GraphQLSchema | null;
  error: string | null;
  loading: boolean;
}

// Create the context with default values
const SchemaContext = createContext<SchemaContextType>({
  schema: null,
  error: null,
  loading: true,
});

export const useSchema = () => useContext(SchemaContext);

export const SchemaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<GraphQLSchema | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function loadSchema() {
      try {
        // Use the pre-generated introspection result to build a client schema
        const generatedSchema = buildClientSchema(
          // Force the JSON to be treated as an IntrospectionQuery
          gqlSchema as IntrospectionQuery
        );

        setSchema(generatedSchema);
        setError(null);
      } catch (error) {
        console.error(
          'Error creating schema from introspection result:',
          error
        );
        setError(error instanceof Error ? error.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    }

    loadSchema();
  }, []);

  return (
    <SchemaContext.Provider value={{ schema, error, loading }}>
      {children}
    </SchemaContext.Provider>
  );
};

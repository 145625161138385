import { useMemo } from 'react';
import { useQuery } from 'urql';

import { PrimaryView } from '@revelio/core';
import {
  FilterName,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  POSTING_TOP_ROLES_GET_DATA,
  RangeFilter,
  SelectionCategories,
  getSkipShareOfPostingsScalingParam,
  useActiveColors,
  useActiveFiltersState,
  useRoleTaxonomySetting,
  useSelectFilterById,
  useSelectionLists,
} from '@revelio/filtering';

import { transformFiltersToVariables } from '../../../utils';
import { getTopGroupedBarData } from '../utils/get-top-grouped-bar-data';
import {
  getProviderEndDateOverride,
  getProviderStartDateOverride,
} from './provider-date-overrides';
import { useIsQueryReady } from './use-is-query-ready';

type UsePostingsTopDataFetchProps = {
  view: PrimaryView;
  primaryFilters: FilterName[];
};

export const usePostingsTopDataFetch = ({
  view,
  primaryFilters,
}: UsePostingsTopDataFetchProps) => {
  /** ======== Get Filter State ======== */
  const activeFilters = useActiveFiltersState();
  const providerFilter = useSelectFilterById(LocalSelectionCategories.PROVIDER);
  const metricModeFilter = useSelectFilterById(
    LocalSelectionCategories.METRIC_MODE
  );
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const { colorMap } = useActiveColors(primaryFilters as SelectionCategories[]);

  /** ======== Transform Filters ======== */
  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view,
        filters: [
          ...activeFilters,
          ...(providerFilter ? [providerFilter] : []),
          ...(metricModeFilter ? [metricModeFilter] : []),
        ],
        isCustomRoleTaxonomyEnabled,
      }),
    [
      view,
      activeFilters,
      providerFilter,
      metricModeFilter,
      isCustomRoleTaxonomyEnabled,
    ]
  );

  /** ======== Fetch ======== */
  const isQueryReady = useIsQueryReady({ activeFilters, primaryFilters, view });
  const postingsDateFilter = activeFilters.find(
    (f) => f.id === SelectionCategories.DATE_RANGE_FULL
  );
  const hasUserSubmittedDateFilter =
    (postingsDateFilter as RangeFilter)?.isMaximumRange === false;
  const geoSelectionLists = useSelectionLists(GEOGRAPHY_GRANULARITY_FILTERS);
  const startDateOverride = getProviderStartDateOverride({
    queryFilters,
    geoSelectionLists,
  });
  const endDateOverride = getProviderEndDateOverride({ queryFilters });

  const overrideQueryFilters = {
    ...queryFilters,
    filters: {
      ...queryFilters.filters,
      ...(startDateOverride &&
        !hasUserSubmittedDateFilter && {
          start_date: startDateOverride,
        }),
      ...(endDateOverride &&
        !hasUserSubmittedDateFilter && {
          end_date: endDateOverride,
        }),
    },
  };

  const [{ data, fetching, error }] = useQuery({
    query: POSTING_TOP_ROLES_GET_DATA,
    variables: {
      ...overrideQueryFilters,
      ...getSkipShareOfPostingsScalingParam(),
    },
    pause: !isQueryReady,
  });

  const loading = useMemo(() => {
    const hasFetchBeenCalled = fetching || !!data || !!error;
    return fetching || !hasFetchBeenCalled;
  }, [data, error, fetching]);

  /** ======== Top Charts ======== */
  const topData = data?.posting ? getTopGroupedBarData(data.posting) : [];

  return { topData, colorMap, loading, error };
};

import { Text, VStack } from '@chakra-ui/layout';

import { PagePaths } from '@revelio/core';

import { DeliverableStepContainer } from '../deliverable-step-container';
import { DeliverablePaths } from '../deliverables-page';
import {
  deliverablesStore,
  setEnrichmentModals,
} from '../deliverables.repository';
import { DataInterface } from '../file-upload/file-upload.types';
import CompletedStatusIcon from './icons/CompletedStatusIcon';
import { SelectedOptionsInterface } from './types/dataEnrichmentTypes';

const CompletedStep = ({
  setData,
  setActiveStep,
  setSelectedOptions,
}: {
  setData: React.Dispatch<React.SetStateAction<DataInterface>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<SelectedOptionsInterface[]>
  >;
}) => {
  const reset = () => {
    setData({
      workbook: { Sheets: {}, SheetNames: [] },
      sheetNames: [],
      mappedWorkbook: [[]],
      selectedSheet: '',
      headers: [],
      headerIndex: '',
      combinedData: [{}],
      fileType: 'csv',
      mappedColumns: [],
      oryId: '',
      clientName: '',
      email: '',
      s3FileLocation: '',
    });
    setActiveStep(0);
    setSelectedOptions([]);
    deliverablesStore.update(setEnrichmentModals([]));
  };
  return (
    <DeliverableStepContainer
      id="completed"
      title="Completed"
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      submitButtonContent="Upload Another"
      onSubmit={reset}
      disableFade
      isDataBuilder={false}
    >
      <VStack w="full" h="full" alignItems="center" justifyContent="center">
        <CompletedStatusIcon />
        <Text align="center">
          Enrichment in progress. <br />
          You'll receive an email shortly with a download link to your enriched
          dataset.
        </Text>
      </VStack>
    </DeliverableStepContainer>
  );
};

export default CompletedStep;

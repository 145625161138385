import { PropsWithChildren, createContext, useContext } from 'react';

import { KibanaLogger } from '@revelio/iso-utility';

const KibanaLoggerContext = createContext<{
  kibanaLogger: KibanaLogger | undefined;
}>({ kibanaLogger: undefined });

export const KibanaLoggerProvider = ({
  children,
  kibanaLogger,
}: PropsWithChildren<{ kibanaLogger: KibanaLogger }>) => {
  return (
    <KibanaLoggerContext.Provider value={{ kibanaLogger }}>
      {children}
    </KibanaLoggerContext.Provider>
  );
};

export const useKibanaLogger = () => useContext(KibanaLoggerContext);

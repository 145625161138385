import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import {
  TypeComputedProps,
  TypeDataSource,
  TypeRowProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import { get } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createUltimatePagination } from 'react-ultimate-pagination';

import { useIsRevelioAdmin } from '@revelio/auth';
import { Loading } from '@revelio/core';
import { User } from '@revelio/data-access';
import { FieldGroup, Search, itemTypeToComponent } from '@revelio/layout';

// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../libs/layout/src/lib/components/table/table.css';
import AdminUserAdd from '../admin-user-add/admin-user-add';
import { useAllClientUsersQuery, useResettableState } from '../utils/helpers';

type AdminUsersProps = {
  clientName: string;
  toggleUserList?: (clientID: string | null) => void;
};
const AdminUsers = ({ clientName, toggleUserList }: AdminUsersProps) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const [searchParams, setSearchParams] = useSearchParams();
  const [gridRef, setGridRef] =
    useState<React.MutableRefObject<TypeComputedProps | null>>();
  const [searchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState<TypeDataSource>([]);
  const navigate = useNavigate();

  const { result } = useAllClientUsersQuery(clientName);

  const { data, fetching } = result;

  const handleRowSelect = (row: TypeRowProps) => {
    if (!toggleUserList) {
      navigate(`/manage/user/${row.data.id}/edit`);
      return;
    }

    const newSearchParams = new URLSearchParams();

    newSearchParams.set('client', clientName);
    newSearchParams.set('userId', row.data.id.toString());
    newSearchParams.set('editing', 'user');

    setSearchParams(newSearchParams);
  };

  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addClientModalKey, resetAddClientModalKey] = useResettableState();

  const handleUserAdd = () => {
    resetAddClientModalKey();
    setAddUserModalOpen(true);
  };

  const handleCloseAddUserModal = () => {
    setAddUserModalOpen(false);
  };

  const trialRender = ({ value }: { value: boolean }) => {
    return (
      <div>
        <span>{!value ? 'Yes' : 'No'}</span>
      </div>
    );
  };

  const defaultSortInfo: TypeSortInfo = { name: 'username', dir: 1 };

  const columns = [
    {
      name: 'name',
      header: 'Name',
      minWidth: 30,
      defaultFlex: 1,
    },
    {
      name: 'username',
      header: 'Username',
      minWidth: 30,
      defaultFlex: 1,
    },
    {
      name: 'live',
      header: 'Trial',
      minWidth: 30,
      defaultFlex: 1,
      render: trialRender,
    },
    {
      name: 'active',
      header: 'Active',
      minWidth: 30,
      defaultFlex: 1,
      render: ({ value }: { value: boolean }) => trialRender({ value: !value }),
    },
  ];

  const gridStyle = { minHeight: 'calc(100% - 40px)', height: '97%' }; // it's not 100% to allow for ctas
  const currentPage = useRef<number>(1);

  const Wrapper = (props: { children: JSX.Element }) => {
    return <Box>{props.children}</Box>;
  };

  const UltimatePagination = createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent(),
    WrapperComponent: Wrapper,
  });

  const renderPaginationToolbar = useCallback(
    (paginationProps: {
      count: number;
      limit: number;
      gotoPage: (arg0: number) => void;
    }) => {
      const totalPages = Math.ceil(
        paginationProps.count / paginationProps.limit
      );
      return (
        totalPages > 1 && (
          <Flex alignItems="center" h="50px" w="100%" justifyContent="center">
            <Box p={2}>
              <UltimatePagination
                currentPage={currentPage.current}
                totalPages={totalPages}
                boundaryPagesRange={1}
                siblingPagesRange={1}
                hidePreviousAndNextPageLinks={false}
                hideFirstAndLastPageLinks={false}
                hideEllipsis={false}
                onChange={(e) => {
                  currentPage.current = e;
                  paginationProps.gotoPage(currentPage.current);
                }}
              />
            </Box>
          </Flex>
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData: User[] = useMemo(
    () => get(data, 'clients[0].users', []),
    [data]
  );

  return (
    <Box h="calc(100vh - 210px)" pb={6}>
      <Flex alignItems="center" paddingBottom={2} justifyContent="center">
        <Button
          variant={'solid'}
          colorScheme="green"
          leftIcon={<AddIcon />}
          size={'sm'}
          onClick={handleUserAdd}
        >
          Add User
        </Button>
        {toggleUserList && (
          <Text ml={2} fontSize={'xl'}>
            {clientName}
          </Text>
        )}
        <Spacer />
        {!fetching && (
          <Search<User>
            gridRef={gridRef}
            tableData={tableData}
            searchText={searchText}
            setSearchText={setSearchText}
            setDataSource={setDataSource}
          />
        )}
      </Flex>
      {!fetching ? (
        <ReactDataGrid
          showColumnMenuTool={false}
          showCellBorders="vertical"
          handle={setGridRef}
          columns={columns || []}
          dataSource={dataSource}
          style={gridStyle}
          rowHeight={30}
          renderPaginationToolbar={renderPaginationToolbar}
          pagination
          emptyText="No Users Found"
          theme="green-light"
          onRowClick={handleRowSelect}
          defaultSortInfo={defaultSortInfo}
        />
      ) : (
        <Flex justify="center" minHeight="100%">
          <Center>
            <Loading />
          </Center>
        </Flex>
      )}

      {isRevelioAdmin && (
        <FieldGroup mt="2">
          <Stack direction="row" spacing={4} align="center">
            <Button
              colorScheme="navyBlue"
              variant="solid"
              size="md"
              onClick={() => {
                const newSearchParams = new URLSearchParams(searchParams);

                newSearchParams.delete('editing');
                newSearchParams.delete('client');

                setSearchParams(newSearchParams);
              }}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              colorScheme="green"
              variant="solid"
              size="md"
              onClick={() => {
                const newSearchParams = new URLSearchParams(searchParams);

                newSearchParams.delete('userId');
                newSearchParams.set('editing', 'client');

                setSearchParams(newSearchParams);
              }}
            >
              Show Client Settings
            </Button>
          </Stack>
        </FieldGroup>
      )}

      <AdminUserAdd
        key={addClientModalKey} // to hard reset modal state
        onClose={handleCloseAddUserModal}
        isOpen={isAddUserModalOpen}
      />
    </Box>
  );
};

export default AdminUsers;

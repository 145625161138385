import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { ContactSalesButton } from '@revelio/composed';
import { ContentWrapper, SimpleCard } from '@revelio/layout';

import contactUsContent from '../markdown/contact-us-content.md';
import { CentrializedLayout } from './centralized-layout';

interface TrialPageContentProps {
  children?: React.ReactNode;
}

export const TrailPageConent: FC<TrialPageContentProps> = ({ children }) => {
  return (
    <CentrializedLayout>
      <Stack spacing={6} mb={8}>
        <SimpleCard p={10}>{children}</SimpleCard>
        <SimpleCard px={10} pt={10} pb={7}>
          <Stack spacing={4}>
            <ContentWrapper>
              <ReactMarkdown>{contactUsContent}</ReactMarkdown>
            </ContentWrapper>
            <ContactSalesButton />
          </Stack>
        </SimpleCard>
      </Stack>
    </CentrializedLayout>
  );
};

import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import { UserSavedFilterSet } from './filters.savedset.models';
import { GetSavedSetsQuery } from './gql-models/saved-sets.models';

export const useGetStoredSets = () => {
  const [options, setOptions] = useState<UserSavedFilterSet[]>([]);

  const [{ fetching: fetchingSavedSets, data: savedSetsData }, refetchQuery] =
    useQuery({
      query: GetSavedSetsQuery,
    });

  const refresh = () => {
    refetchQuery({ requestPolicy: 'network-only' });
  };

  useEffect(() => {
    if (!fetchingSavedSets && savedSetsData) {
      const fetchedSavedSets = get(
        savedSetsData,
        'presets',
        []
      ) as UserSavedFilterSet[];

      setOptions(fetchedSavedSets.map((set) => ({ ...set, children: [] })));
    }
  }, [fetchingSavedSets, savedSetsData]);

  return { fetchingSavedSets, options, refresh };
};

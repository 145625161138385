import ReactMarkdown from 'react-markdown';

import { PageTitles } from '@revelio/core';
import { ContentWrapper } from '@revelio/layout';

import DashboardPage from '../../DashboardPage';
import { TrailPageConent } from './components/trial-page-content';
import trialPageContent from './markdown/data-builder-trial-content.md';

export const DeliverablesTrialPage = () => {
  return (
    <DashboardPage
      title={[PageTitles.DELIVERABLES, PageTitles.DATA_BUILDER]}
      loading={false}
      scrollY={true}
    >
      <TrailPageConent>
        <ContentWrapper>
          <ReactMarkdown>{trialPageContent}</ReactMarkdown>
        </ContentWrapper>
      </TrailPageConent>
    </DashboardPage>
  );
};

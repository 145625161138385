import { useMemo } from 'react';
import { useQuery } from 'urql';

import { PrimaryView } from '@revelio/core';
import {
  Filter,
  FilterName,
  OtherFilterNames,
  SENTIMENT_GET_SNAPSHOT_DATA,
  SENTIMENT_REVIEWS_NEGATIVE,
  SENTIMENT_REVIEWS_POSITIVE,
  SelectionCategories,
  useActiveFiltersState,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

import { transformFiltersToVariables } from '../../utils';

interface UseGetSentimentReviewsDataProps {
  view: PrimaryView;
  primaryFilters: FilterName[];
}
export const useGetSentimentReviewsData = ({
  view,
  primaryFilters,
}: UseGetSentimentReviewsDataProps) => {
  const activeFilters = useActiveFiltersState();
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view,
        filters: activeFilters,
        isCustomRoleTaxonomyEnabled,
      }),
    [activeFilters, view, isCustomRoleTaxonomyEnabled]
  );

  const isQueryReady = useIsQueryReady({
    activeFilters,
    primaryFilters,
    view,
  });
  const [{ data: effectsData, fetching: effectsLoading }] = useQuery({
    query: SENTIMENT_GET_SNAPSHOT_DATA,
    variables: queryFilters,
    pause: !isQueryReady,
  });
  const [{ data: positiveReviewsData, fetching: positiveReviewsLoading }] =
    useQuery({
      query: SENTIMENT_REVIEWS_POSITIVE,
      variables: queryFilters,
      pause: !isQueryReady,
    });
  const [{ data: negativeReviewsData, fetching: negativeReviewsLoading }] =
    useQuery({
      query: SENTIMENT_REVIEWS_NEGATIVE,
      variables: queryFilters,
      pause: !isQueryReady,
    });

  return {
    effectsData,
    positiveReviewsData,
    negativeReviewsData,
    loading:
      effectsLoading ||
      positiveReviewsLoading ||
      negativeReviewsLoading ||
      !isQueryReady,
  };
};

const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
}) => {
  const activeFiltersHasPrimaryFilter = activeFilters.some((filter) =>
    primaryFilters?.includes(filter.id)
  );

  const activeFiltersHasDateRange = activeFilters.some(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  );

  const filtersHasCustomRoleTaxonomy = useMemo(() => {
    return !!activeFilters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
  }, [activeFilters]);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  return (
    activeFiltersHasPrimaryFilter &&
    activeFiltersHasDateRange &&
    (!isCustomRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};

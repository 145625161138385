import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { isEmpty, isNull } from 'lodash';
import { useRef, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { PopoverAnchor, WithTooltip } from '@revelio/core';

import { SelectionCategories } from '../../../../engine/filters.model';
import {
  SUBMENU_CONTENT_CLASS_NAME,
  SUBMENU_TOGGLE_CLASS_NAME,
} from '../../../utils/constants';
import SubmenuTree, { SubmenuTreeProps } from '../submenu-tree/submenu-tree';
import styles from './node-submenu.module.css';

export const SelectionCategoryLabelPrefix: { [key: string]: string } = {
  [SelectionCategories.JOB_CATEGORY]: 'Role ',
  [SelectionCategories.SKILL_K25]: 'Skill ',
  [SelectionCategories.SKILL_K50]: 'Skill ',
  [SelectionCategories.SKILL_K75]: 'Skill ',
};

export const SelectionCategoryLabelOverrides: { [key: string]: string } = {
  [SelectionCategories.JOB_CATEGORY]: 'k7',
  [SelectionCategories.ROLE_K50]: 'k50',
  [SelectionCategories.ROLE_K150]: 'k150',
  [SelectionCategories.ROLE_K300]: 'k300',
  [SelectionCategories.ROLE_K500]: 'k500',
  [SelectionCategories.ROLE_K1000]: 'k1000',
  [SelectionCategories.ROLE_K1250]: 'k1250',
  [SelectionCategories.ROLE_K1500]: 'k1500',
  [SelectionCategories.REGION]: 'Region',
  [SelectionCategories.COUNTRY]: 'Country',
  [SelectionCategories.STATE]: 'State',
  [SelectionCategories.METRO_AREA]: 'Metro Area',
  [SelectionCategories.SKILL_K25]: 'k25 ',
  [SelectionCategories.SKILL_K50]: 'k50 ',
  [SelectionCategories.SKILL_K75]: 'k75',
  [SelectionCategories.SKILL_K700]: 'k700',
  [SelectionCategories.SKILL_K3000]: 'k3000',
  [SelectionCategories.RAW_TITLE]: 'Title',
};

export const NestedTreeHeaderTooltipText: { [key: string]: string } = {
  [SelectionCategories.JOB_CATEGORY]:
    'Role k7 is a set of distinct occupations. At this level of granularity, roles are bucketed into 7 parts.',
  [SelectionCategories.ROLE_K150]:
    'Role k150 is a set of distinct occupations. At this level of granularity, roles are bucketed into 150 parts.',
  [SelectionCategories.ROLE_K1500]:
    'Role k1500 is a set of distinct occupations. At this level of granularity, roles are bucketed into 1,500 parts.',
};

/* eslint-disable-next-line */
export interface NodeSubmenuProps extends SubmenuTreeProps {
  header?: string;
  children: React.ReactNode;
  listId?: string;
  disclosureProps: {
    isOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
  };
  showHeaderTooltip: boolean;
}

export function NodeSubmenu({
  header = '',
  listId = '',
  disclosureProps = {},
  ...props
}: NodeSubmenuProps) {
  const {
    parentRef,
    candidateTree,
    lookupHandle,
    intermediateStateHandle,
    selectionLookupRef,
    intermediateLookupRef,
    select,
    showHeaderTooltip,
    labelFormatter = (str) => str,
    compareFunction,
  } = props;

  const { isOpen, onClose } = useDisclosure(disclosureProps);

  const [isScrollBox, setIsScrollBox] = useState(false);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const selfRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const scrollBoxLogger = {
    name: 'scrollBoxLogger',
    enabled: true,
    phase: 'main' as any,
    fn({ state }: any) {
      const element = state.scrollParents.reference[0];

      const isScrollBox = element
        ? element.scrollHeight > element.clientHeight
        : false;

      const scrollbarWidth = element
        ? element.offsetWidth - element.clientWidth
        : 0;

      setScrollBarWidth(scrollbarWidth);
      setIsScrollBox(isScrollBox);
    },
  };

  const offsetPopper = {
    name: 'offset',
    options: {
      offset: (state: any) => {
        let offsetX = 10;

        if (state.placement.includes('right') && isScrollBox) {
          offsetX += scrollBarWidth;
        }

        return [-8, offsetX];
      },
    },
  };

  useOutsideClick({
    enabled: isOpen,
    ref: selfRef,
    handler: (e) => {
      if (e?.target) {
        //  Outside Click Behaviour:
        //  1. Each submenu closes if click in its immediate parent menu.
        //  2. Click outside of nested menu closes all menus.

        const targetElement = e.target as Element;

        const toggleParent = targetElement.closest(
          `.${SUBMENU_TOGGLE_CLASS_NAME}`
        );

        const isTargetInToggle = !isNull(toggleParent);

        // Determine if click target is in submenu's own menu toggler.
        const isTargetInSelfToggle =
          anchorRef.current?.contains(targetElement) && isTargetInToggle;

        // If click is in own menu toggler, we let chakra handle the interaction.
        if (isTargetInSelfToggle) {
          return;
        }

        const isTargetInParent = parentRef?.current?.contains(targetElement);

        if (isTargetInParent) {
          onClose();
        }
      }
    },
  });

  return (
    <Popover
      placement="right-start"
      isOpen={isOpen}
      onClose={onClose}
      isLazy={true}
      closeOnBlur={false}
      modifiers={[scrollBoxLogger, offsetPopper]}
    >
      <Box ref={anchorRef}>
        <PopoverAnchor>{props.children}</PopoverAnchor>
      </Box>
      <Portal>
        <PopoverContent
          className={SUBMENU_CONTENT_CLASS_NAME}
          width="250px"
          boxShadow="xl"
          // workaround to fix focus shadow bug
          sx={{
            ':focus:not(:focus-visible)': {
              shadow: `var(--chakra-shadows-xl) !important`,
            },
          }}
          ref={selfRef}
        >
          <PopoverHeader role="group" letterSpacing={0.8} p={0} border={0}>
            <Flex h="32px" w="100%" alignItems="center" px="12px" pt="8px">
              <Text fontSize="xs" fontWeight="600" opacity="0.5">
                {header}
              </Text>

              {showHeaderTooltip &&
                !isEmpty(NestedTreeHeaderTooltipText[listId]) && (
                  <Box
                    alignSelf="center"
                    alignItems="center"
                    className={styles.hidden}
                    _groupHover={{ display: 'flex' }}
                    _hover={{ cursor: 'pointer' }}
                  >
                    <WithTooltip
                      showCondition={true}
                      variant="label"
                      padding="12px"
                      fontSize="12px"
                      maxWidth="330px"
                      overflowWrap="normal"
                      label={`${NestedTreeHeaderTooltipText[listId] || ''}`}
                    >
                      <Flex ml={1}>
                        <Icon h="12px" opacity="0.6" as={FiInfo} />
                      </Flex>
                    </WithTooltip>
                  </Box>
                )}
            </Flex>
          </PopoverHeader>
          <PopoverBody pt={0}>
            <Box data-testid={`accordion-submenu-${listId}`}>
              <SubmenuTree
                nestingLevel={0}
                candidateTree={candidateTree}
                lookupHandle={lookupHandle}
                intermediateStateHandle={intermediateStateHandle}
                selectionLookupRef={selectionLookupRef}
                intermediateLookupRef={intermediateLookupRef}
                search={''}
                select={select}
                parentRef={selfRef}
                labelFormatter={labelFormatter}
                compareFunction={compareFunction}
              />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default NodeSubmenu;

import { max } from 'lodash';

import { wrapText } from '../../utilities/wrap-text';
import styles from './axis-label.module.css';

export const AXIS_NO_WRAP_HEIGHT = 'no-wrap';

type AxisLabelProps = {
  y: number;
  width: number;
  availableHeight: number | typeof AXIS_NO_WRAP_HEIGHT;
  label: string;
  fontSize?: number;
  align?: 'left' | 'right';
  testId?: string;
};

export const AxisLabel = ({
  y,
  width,
  availableHeight,
  label,
  fontSize = 11,
  align = 'right',
  testId,
}: AxisLabelProps) => {
  const truncatedLabel = (() => {
    return wrapText({
      text: label,
      maxWidth: width,
      fontSize,
      maxHeight:
        typeof availableHeight === 'number' ? max([availableHeight, 1]) : 1,
    });
  })();

  return (
    <text
      y={y}
      x={align === 'left' ? 0 : width}
      textAnchor={align === 'left' ? 'start' : 'end'}
      dy="0.25em"
      fontSize={`${fontSize}px`}
      className={styles.yAxisLabel}
      data-testid={testId}
    >
      {Array.isArray(truncatedLabel) ? (
        <MultiLineLabel
          x={width}
          label={truncatedLabel}
          labelTitle={label}
          align={align}
        />
      ) : (
        <>
          <title>{label}</title>
          {truncatedLabel}
        </>
      )}
    </text>
  );
};

const lineHeightEm = 1.3;
const MultiLineLabel = ({
  label,
  labelTitle,
  x,
  align = 'right',
}: {
  label: string[];
  labelTitle: string;
  x: number;
  align?: 'left' | 'right';
}) => {
  const firstDY = (-(label.length - 1) * lineHeightEm) / 2;

  return (
    <>
      <title>{labelTitle}</title>
      {label.map((line, i) => (
        <tspan
          key={i}
          x={align === 'left' ? 0 : x}
          dy={i === 0 ? `${firstDY}em` : '1.1em'}
          textAnchor={align === 'left' ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {line}
        </tspan>
      ))}
    </>
  );
};

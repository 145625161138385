import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Divider, Flex } from '@chakra-ui/layout';
import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tooltip,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { noop } from 'lodash';
import { FormState, UseFormRegister, useForm } from 'react-hook-form';

import {
  deliverablesStore,
  getInputRef,
  isValidEntityId,
  updatePipelineCompanySelection,
  upsertInputRefs,
} from '../deliverables.repository';
import { SnowflakeDbInputs, SnowflakeDbValues } from '../snowflake-db-inputs';
import { resetSubsidiaryColumnMappings } from './subsidiary-mapping/subsidiary-mapping.repository';

interface CompanyRefTableProps extends Omit<UseDisclosureProps, 'onClose'> {
  onClose: () => void;
  returnToPrevious?: () => void;
  entityId?: number;
}

export const CompanyRefTable = ({
  id,
  isOpen,
  onClose,
  returnToPrevious = noop,
  entityId,
}: CompanyRefTableProps) => {
  const companyReferenceTable = getInputRef('company_reference');
  const parsedCompanyReferenceTable = companyReferenceTable?.split('.') ?? [
    '',
    '',
    '',
  ];
  const pipelineInputValue = getInputRef('pipeline_input');
  const parsedPipelineInputValue = pipelineInputValue?.split('.') ?? [
    '',
    '',
    '',
  ];
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<{
    companyReference: SnowflakeDbValues;
    pipelineInput: SnowflakeDbValues;
  }>({
    defaultValues: {
      companyReference: companyReferenceTable
        ? {
            database: parsedCompanyReferenceTable[0],
            schema: parsedCompanyReferenceTable[1],
            table: parsedCompanyReferenceTable[2],
          }
        : {
            database: '',
            schema: '',
            table: '',
          },
      pipelineInput: pipelineInputValue
        ? {
            database: parsedPipelineInputValue[0],
            schema: parsedPipelineInputValue[1],
            table: parsedPipelineInputValue[2],
          }
        : {
            database: '',
            schema: '',
            table: '',
          },
    },
  });

  function joinValuesWithDot(values: {
    database: string;
    schema: string;
    table: string;
  }) {
    const order = ['database', 'schema', 'table'] as const;
    const nonBlankValues = order
      .map((key) => values[key])
      .filter((value) => value !== '');
    return nonBlankValues.join('.');
  }

  const onSubmit = handleSubmit((formData) => {
    const companyTableUpdate = {
      company_reference: joinValuesWithDot(formData.companyReference),
      pipeline_input: joinValuesWithDot(formData.pipelineInput),
    };
    if (isValidEntityId(entityId)) {
      updatePipelineCompanySelection(entityId, companyTableUpdate);
    } else {
      upsertInputRefs(companyTableUpdate);
    }
    deliverablesStore.update(resetSubsidiaryColumnMappings()); // this is so we can distinguish between manually set table vs generated company mapper tables
    onClose();
  });

  return (
    <Modal
      id={id}
      isOpen={isOpen as boolean}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <Divider orientation="horizontal" />

        <ModalBody mt="14px">
          <Heading color="text.primary" fontWeight="600" size="sm" mb="2">
            <Flex alignItems="center" gap={1}>
              Company Ref Table
              <Tooltip
                label='Required columns: "company_id", "company", "rcid"'
                textAlign="center"
                hasArrow
                placement="top"
              >
                <InfoOutlineIcon
                  boxSize={3}
                  color="text.primary"
                  cursor="pointer"
                />
              </Tooltip>
            </Flex>
          </Heading>
          <SnowflakeDbInputs
            register={
              ((...args: Parameters<UseFormRegister<SnowflakeDbValues>>) => {
                const [name, ...rest] = args;
                return register(`companyReference.${name}`, ...rest);
              }) as UseFormRegister<SnowflakeDbValues>
            }
            errors={
              errors.companyReference as FormState<SnowflakeDbValues>['errors']
            }
          />

          <Heading
            color="text.primary"
            fontWeight="600"
            size="sm"
            mb="2"
            mt="8"
          >
            <Flex alignItems="center" gap={1}>
              Pipeline Input
              <Tooltip
                label='Required columns: "company", "rcid"'
                textAlign="center"
                hasArrow
                placement="top"
              >
                <InfoOutlineIcon
                  boxSize={3}
                  color="text.primary"
                  cursor="pointer"
                />
              </Tooltip>
            </Flex>
          </Heading>
          <SnowflakeDbInputs
            isOptional={true}
            register={
              ((...args: Parameters<UseFormRegister<SnowflakeDbValues>>) => {
                const [name, ...rest] = args;
                return register(`pipelineInput.${name}`, ...rest);
              }) as UseFormRegister<SnowflakeDbValues>
            }
            errors={
              errors.pipelineInput as FormState<SnowflakeDbValues>['errors']
            }
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button
              variant="link"
              fontSize="12px"
              colorScheme="gray"
              onClick={() => returnToPrevious()}
            >
              Back
            </Button>
            <Button
              borderRadius="4px"
              fontSize="12px"
              fontWeight="600"
              colorScheme="green"
              variant="solid"
              size="sm"
              isLoading={isSubmitting}
              onClick={onSubmit}
            >
              Set reference table
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { get } from 'lodash';
import { useContext, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { CardListSelectController } from '@revelio/layout';

import { FileUploadContext } from './file-upload-context';
import { DataInterface } from './file-upload.types';
import { mapWorkbook } from './map-workbook';

const SheetSelectionStep = ({
  ContainerElement,
}: {
  ContainerElement: ({
    onSubmit,
    children,
  }: {
    onSubmit: () => void;
    children: ReactElement;
  }) => ReactElement;
}) => {
  const {
    sheetNames = [],
    workbook = null,
    setData: setFileData,
    goToNext,
  } = useContext(FileUploadContext);
  const defaultValue = get(sheetNames, '[0]', ['']);
  const [value, setValue] = useState([defaultValue]);
  const handleValueChange = (e: string[]) => {
    if (e.length === 0) {
      return;
    }
    const lastItem = e[e.length - 1];
    setValue([lastItem]);
  };

  const items = [
    {
      heading: null,
      columns: sheetNames.map((sheetName: string) => ({
        id: sheetName,
        label: sheetName,
      })),
    },
  ];

  return ContainerElement({
    onSubmit: () => {
      const mappedWorkbook = mapWorkbook(workbook, value[0]);
      setFileData((prevData: DataInterface) => ({
        ...prevData,
        mappedWorkbook,
        selectedSheet: value[0],
      }));
      goToNext();
    },
    children: (
      <CardListSelectController
        variant="columns"
        items={items}
        value={value}
        hideCtaIfNotSelected={true}
        gridProps={{
          spacing: 4,
          columns: 3,
          gridAutoFlow: 'row',
        }}
        onChange={handleValueChange}
      />
    ),
  });
};

export default SheetSelectionStep;

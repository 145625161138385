import { graphql } from '../../gql';

export const USER_DETAILS_QUERY = graphql(`
  query GetUserDetails($ids: [String]) {
    users(ids: $ids) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      num_seats
      custom_taxonomy
      metadata {
        key
        value
      }
      currency_data {
        id
        code
        name
        rate
        record_date
        effective_date
      }
    }
  }
`);

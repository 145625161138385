import {
  Button,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { Dispatch } from 'react';

import { ScreenerFilterPopover } from '../screener-filter-popover';
import {
  ScreenerFilterAction,
  ScreenerFilterState,
} from '../screener-filter-reducer';
import { ScreenerFilterOption } from '../types';

interface PrimaryFilterTagProps {
  filterOptions: ScreenerFilterOption[];
  dispatch: Dispatch<ScreenerFilterAction>;
  primaryFilter: ScreenerFilterState['filters']['primary_filter'];
}

const ricsOption: ScreenerFilterOption = {
  label: 'Industry',
  value: 'rics_k10',
  primary: true,
};

export const PrimaryFilterTag = ({
  filterOptions,
  dispatch,
  primaryFilter,
}: PrimaryFilterTagProps) => {
  const primaryFilterNames = Object.values(primaryFilter || {})?.map(
    (filter) => filter.item?.label
  );

  const filterNamesString = (() => {
    if (primaryFilterNames.length > 2) {
      return `${primaryFilterNames[0]} and ${primaryFilterNames.length - 1} others`;
    }

    return primaryFilterNames.join(', ');
  })();

  const handlePrimaryFilterClear = () => {
    dispatch({ type: 'REMOVE_PRIMARY_FILTER' });
  };

  const primaryFilterKey = primaryFilter && Object.keys(primaryFilter)[0];

  const findMatchingFilterOption = (
    filterOptions: ScreenerFilterOption[],
    primaryFilterKey: string | undefined
  ): ScreenerFilterOption | undefined => {
    return filterOptions.find(
      (option) =>
        primaryFilterKey?.includes(option.value) ||
        option.filterGranularities?.some((granularity) =>
          primaryFilterKey?.includes(granularity)
        )
    );
  };

  const selectedFilterOption = (() => {
    const foundOption = findMatchingFilterOption(
      filterOptions,
      primaryFilterKey
    );

    if (foundOption) {
      return foundOption;
    }

    const hasRics = primaryFilterKey?.includes('rics_k');

    return hasRics ? ricsOption : undefined;
  })();

  return (
    <ScreenerFilterPopover
      trigger={
        <Tag
          size="sm"
          variant="solid"
          cursor="pointer"
          height={6}
          borderRadius="4px"
          _hover={{ background: 'white' }}
          as={Button}
          data-testid="primary-filter-tag"
        >
          <TagLabel _hover={{ textDecoration: 'underline' }}>
            <Flex>
              <Text>{selectedFilterOption?.label}</Text>
              <Text mx={0.5}>
                •{primaryFilterNames.length > 1 ? ' is one of ' : ' is '}•
              </Text>
              <Text color="lightBlue.600" fontWeight={600}>
                {filterNamesString}
              </Text>
            </Flex>
          </TagLabel>
          <TagCloseButton
            onClick={handlePrimaryFilterClear}
            data-testid={`primary-filter-tag-remove-${selectedFilterOption?.label}`}
          />
        </Tag>
      }
      filterOptions={filterOptions}
      selectedFilterOverride={selectedFilterOption}
    />
  );
};

import { getTextHeight, getTextWidth, truncateText } from './truncate-text';

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

type WrapTextProps = {
  text: string;
  maxWidth: number;
  maxHeight?: number;
  fontSize?: number;
};

export const wrapText = ({
  text,
  maxWidth,
  maxHeight,
  fontSize = 11,
}: WrapTextProps): string | string[] => {
  text = text.trim();
  if (!context) return text;

  const textWidth = getTextWidth({ text, fontSize });
  if (!textWidth || textWidth <= maxWidth) return text;

  const words = text.split(' ');

  const wordHeight = getTextHeight({ text: words[0], fontSize });
  const maxLines =
    maxHeight && wordHeight ? Math.floor(maxHeight / wordHeight) : Infinity;

  if (maxLines === 0) return truncateText({ text, maxWidth, fontSize });
  const lines: string[] = [];

  while (lines.length <= maxLines) {
    // Get next word
    const nextWord = words.shift();
    if (!nextWord) break;

    // If no lines, add first word
    if (lines.length === 0) {
      const firstWordWidth = getTextWidth({ text: nextWord, fontSize });
      if (firstWordWidth && firstWordWidth > maxWidth) {
        lines.push(truncateText({ text: nextWord, maxWidth, fontSize }));
      } else {
        lines.push(nextWord);
      }
      continue;
    }

    // Get last line
    const lastLine = lines[lines.length - 1];
    const lastLineWithNextWordLength = getTextWidth({
      text: `${lastLine} ${nextWord}`,
      fontSize,
    });

    // If the last line with the next word is less than the max width, add the next word to the last line
    if (lastLineWithNextWordLength && lastLineWithNextWordLength <= maxWidth) {
      lines[lines.length - 1] = `${lastLine} ${nextWord}`;
      continue;
    } else {
      if (lines.length === maxLines) {
        lines[lines.length - 1] = truncateText({
          text: `${lastLine} ${nextWord}`,
          maxWidth,
          fontSize,
        });
      } else {
        if (nextWord.split(' ').length === 1) {
          lines.push(truncateText({ text: nextWord, maxWidth, fontSize }));
        } else {
          lines.push(nextWord);
        }
      }
    }
  }

  return lines;
};

import { Box } from '@chakra-ui/react';
import { debounce } from 'lodash';

import { Views } from '@revelio/core';

import { ViewTypes } from '../../data-api/data-api.model';
import { SelectionCategories } from '../../engine/filters.model';
import {
  deleteFilters,
  upsertFiltersWithProvidedValue,
} from '../../engine/filters.repository';
import { MaxDateRangeToDefault } from '../collection';

export const DateLabel = ({ formattedDate }: { formattedDate: string }) => (
  <>
    {`Date \u2022 is \u2022 `}
    <Box as="span" color="lightBlue.600" fontWeight="semibold">
      {formattedDate}
    </Box>
  </>
);

export const handleRemoveSnapshotDateFilter = debounce(
  (
    e,
    {
      propsView,
      viewType,
    }: {
      propsView?: Views;
      viewType?: ViewTypes;
    }
  ) => {
    e.stopPropagation();
    deleteFilters([
      SelectionCategories.SNAPSHOT_DATE,
      SelectionCategories.DATE_RANGE,
    ]);

    // reset the overtime date range filter
    upsertFiltersWithProvidedValue(
      MaxDateRangeToDefault(
        propsView,
        viewType,
        undefined,
        SelectionCategories.DATE_RANGE
      )
    );
  },
  400,
  { leading: true }
);

import { Flex, Input } from '@chakra-ui/react';
import {
  FieldValues,
  Message,
  Path,
  UseFormReturn,
  ValidationRule,
} from 'react-hook-form';

import { CustomColumnConditionVariable } from '@revelio/data-access';

import { NumericOperation, NumericVariable } from './conditions.model';
import { EMPTY_VALUE_MESSAGE } from './dataset-conditions-form-helpers';

export interface NumericConditionValueProps<FormInputs extends FieldValues> {
  selectedConditionVariable: NumericVariable | undefined;
  selectedConditionOperation: NumericOperation | undefined;
  firstValuePath: Path<FormInputs>;
  minValue?: number | undefined;
  maxValuePath: Path<FormInputs>;
  maxValue?: number | undefined;
}
export function NumericConditionValue<FormInputs extends FieldValues>({
  selectedConditionVariable,
  selectedConditionOperation,
  firstValuePath,
  maxValuePath,
  register,
  minValue,
  maxValue,
}: UseFormReturn<FormInputs> & NumericConditionValueProps<FormInputs>) {
  const numericValidationRules = getInputValidationRules({
    selectedConditionVariable,
    selectedConditionOperation,
  });
  const betweenInputValidationRules = getBetweenInputValidationRules({
    currentValidationRules: numericValidationRules,
    minValue,
    maxValue,
  });
  return (
    <>
      {selectedConditionOperation !== NumericOperation.BETWEEN && (
        <Input
          background="white"
          ml="8px"
          fontSize="xs"
          flexGrow="1"
          type="number"
          isDisabled={!selectedConditionOperation}
          {...register(firstValuePath, numericValidationRules)}
          placeholder="Value"
        />
      )}

      {selectedConditionOperation === NumericOperation.BETWEEN && (
        <Flex>
          <Input
            background="white"
            ml="8px"
            fontSize="xs"
            flexGrow="1"
            type="number"
            isDisabled={!selectedConditionOperation}
            {...register(
              firstValuePath,
              betweenInputValidationRules.minValidation
            )}
            placeholder="Min Value"
          />

          <Input
            background="white"
            ml="8px"
            fontSize="xs"
            flexGrow="1"
            type="number"
            isDisabled={!selectedConditionOperation}
            {...register(
              maxValuePath,
              betweenInputValidationRules.maxValidation
            )}
            placeholder="Max Value"
          />
        </Flex>
      )}
    </>
  );
}

type NumericValueValidationRules = {
  required: Message | ValidationRule<boolean>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
};
const getInputValidationRules = ({
  selectedConditionVariable,
  selectedConditionOperation,
}: {
  selectedConditionVariable: NumericVariable | undefined;
  selectedConditionOperation: NumericOperation | undefined;
}) => {
  const inputValidationRules: NumericValueValidationRules = {
    required: EMPTY_VALUE_MESSAGE,
  };
  if (!selectedConditionVariable || !selectedConditionOperation) {
    return inputValidationRules;
  }

  if (
    selectedConditionVariable ===
    CustomColumnConditionVariable.RemoteSuitability
  ) {
    inputValidationRules.min = { value: 0, message: 'Minimum value is 0' };
    inputValidationRules.max = { value: 1, message: 'Maximum value is 1' };
  }

  if (selectedConditionVariable === CustomColumnConditionVariable.Prestige) {
    inputValidationRules.min = {
      value: -1.2,
      message: 'Minimum value is -1.2',
    };
    inputValidationRules.max = { value: 1.2, message: 'Maximum value is 1.2' };
  }

  if (
    selectedConditionVariable === CustomColumnConditionVariable.RatingOverall
  ) {
    inputValidationRules.min = {
      value: 1,
      message: 'Minimum value is 1',
    };
    inputValidationRules.max = { value: 5, message: 'Maximum value is 5' };
  }

  return inputValidationRules;
};

const getBetweenInputValidationRules = ({
  currentValidationRules,
  minValue,
  maxValue,
}: {
  currentValidationRules: NumericValueValidationRules;
  minValue: string | number | undefined;
  maxValue: string | number | undefined;
}): {
  minValidation: NumericValueValidationRules;
  maxValidation: NumericValueValidationRules;
} => {
  return {
    minValidation: maxValue
      ? {
          ...currentValidationRules,
          max: {
            value: Number(maxValue),
            message: 'Min value cannot exceed max value',
          },
        }
      : currentValidationRules,
    maxValidation: minValue
      ? {
          ...currentValidationRules,
          min: {
            value: Number(minValue),
            message: 'Max value cannot be less than min value',
          },
        }
      : currentValidationRules,
  };
};

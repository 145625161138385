import { User } from '@revelio/data-access';
import { useEffect } from 'react';

const useSalesIQScript = (
  widgetCode: string,
  zohoChatFeatureFlag: boolean,
  userName?: string,
  userEmail?: string,
  user?: User
) => {
  const isTrialUser =
    ['revelio_self_signup', 'revelio_extension'].includes(
      user?.client_name ?? ''
    ) && user?.live === false;
  useEffect(() => {
    if (
      document.getElementById('zsiqscript') ||
      !isTrialUser ||
      !zohoChatFeatureFlag
    ) {
      return; // Prevent duplicate script loading
    }

    // Add inline script
    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.innerHTML = `
        window.$zoho = window.$zoho || {};
        $zoho.salesiq = $zoho.salesiq || { ready: function() {
        ${userName ? `$zoho.salesiq.visitor.name("${userName}");` : ''}
        ${userEmail ? `$zoho.salesiq.visitor.email("${userEmail}");` : ''}
        } };
    `;
    document.body.appendChild(inlineScript);

    // Add external script
    const script = document.createElement('script');
    script.id = 'zsiqscript';
    script.src = `https://salesiq.zohopublic.com/widget?wc=${widgetCode}`;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(inlineScript);
    };
  }, [widgetCode, userEmail, userName, isTrialUser, zohoChatFeatureFlag]);
};

export default useSalesIQScript;

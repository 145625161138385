/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect$ } from '@ngneat/react-rxjs';
import { isEmpty, set } from 'lodash';
import mixpanel from 'mixpanel-browser';
import objectHash from 'object-hash';
import { useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useNavigate } from 'react-router';
import { useCookie } from 'react-use';
import { OperatorFunction, catchError, distinctUntilChanged, tap } from 'rxjs';
import { useMutation } from 'urql';

import { useGetLoggedInUser, useUserTrialDetails } from '@revelio/auth';
import {
  InitiationTrackingEvents,
  InterestedOptions,
  PagePaths,
  TourClasses,
  TourCustomPageValue,
  useGlobalPageLoadingStatus,
} from '@revelio/core';
import {
  getIsTour,
  getStepIndex,
  pickKeys,
  setIsControlPanelSpotlighted,
  setIsSideBarCompany,
  setIsSideBarOpen,
  setIsTour,
  setIsTourComplete,
  setStepIndex,
  tourStore,
} from '@revelio/core';
import { KeyValueResp, MetadataKey, User } from '@revelio/data-access';
import {
  FilterItem,
  LocalSelectionCategories,
  SelectFilter,
  upsertFilter,
  useSelectionLists,
} from '@revelio/filtering';

import { UpdateUserMutation } from '../adminRewrite/userOperations';
import { TourStepTitles, steps as tourSteps } from './tour.config';
import { tourStyles } from './tour.styles';

export function Tour() {
  const navigate = useNavigate();
  const snapshotIndex = 0;
  const overtimeIndex = 1;

  const [{ fetching: updatingUserMetaData }, updateUser] =
    useMutation(UpdateUserMutation);

  const [tourCustomPageValue] = useCookie(TourCustomPageValue);
  const interestedInSpecials = (tourCustomPageValue?.length || 0) > 1;
  const interestedInReports =
    interestedInSpecials &&
    tourCustomPageValue?.includes(
      InterestedOptions.Data_Enrichment_and_Custom_Reports
    );
  const interestedInTalent =
    interestedInSpecials &&
    tourCustomPageValue?.includes(InterestedOptions.Talent_Acquisition);

  const [steps] = useState(
    tourSteps.filter((step) => {
      if (step.title === TourStepTitles.ADJUST_VIEW) {
        step.showProgress = interestedInSpecials;
      }
      if (step.title == TourStepTitles.CUSTOM_DATA) {
        step.showProgress = interestedInTalent;
        return interestedInReports;
      }

      if (step.title == TourStepTitles.TALENT_ACQUISITION) {
        return interestedInTalent;
      }

      return true;
    })
  );

  const [stepIndexState, setStepIndexState] = useState(0);

  const {
    loggedInUser,
    query: [{ fetching }],
    refetch: refetchUserData,
  } = useGetLoggedInUser();

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  const [isTourState, setIsTourState] = useState(false);
  const [isTourCompleteState, setIsTourCompleteState] = useState(false);

  useEffect$(
    () =>
      tourStore.pipe(
        pickKeys(['isTour', 'isTourComplete']) as OperatorFunction<
          typeof tourStore.state,
          Pick<typeof tourStore.state, 'isTour' | 'isTourComplete'>
        >,
        distinctUntilChanged((pre, cur) => objectHash(pre) == objectHash(cur)),
        tap((data) => {
          setIsTourState(data.isTour);
          setIsTourCompleteState(data.isTourComplete);
        }),
        catchError((e) => {
          return e;
        })
      ),
    [fetching, loggedInUser]
  );

  const snapshotOvertimeFilterName =
    LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME;
  const [pickedSelectionList] = useSelectionLists([snapshotOvertimeFilterName]);
  const handleTabSwitch = (index: number, className: string) => {
    const newTab = set(pickedSelectionList?.value[index], 'index', index);
    upsertFilter<SelectFilter<FilterItem>>(
      LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
      {
        value: newTab,
      }
    );
  };

  const handleUpdateMetadata = (
    loggedInUser: User = {},
    newMeta: KeyValueResp[]
  ) => {
    const metaFieldsToExclude = [MetadataKey.ExpirationDate];

    return new Promise((resolve, reject) => {
      if (isEmpty(loggedInUser)) {
        reject('No logged in user provided');
      }

      const newMetaKeys = newMeta.map((item) => item.key);

      const currentMetaObjects = (loggedInUser.metadata || []).filter(
        (item: KeyValueResp | null) =>
          !newMetaKeys.includes(item?.key) &&
          !metaFieldsToExclude.includes(item?.key as MetadataKey)
      );

      const updatedMeta = [...currentMetaObjects, ...newMeta];

      updateUser({
        id: loggedInUser?.id as string,
        metadata: updatedMeta,
      })
        .then((res) => {
          // update user object on update success
          refetchUserData();
          resolve('User updated');
        })
        .catch((err) => {
          reject('Something went wrong');
        });
    });
  };

  /**
   *  Is passed the CallBackProps from the previous step for event type = 'step:after'
   */
  const dispatchStepBackAction = ({ index, size, step }: CallBackProps) => {
    if (step.title == TourStepTitles.TALENT_ACQUISITION) {
      const hasDeliverablesTourStep = steps.find(
        (step) => step.title == TourStepTitles.CUSTOM_DATA
      );
      if (hasDeliverablesTourStep) {
        tourStore.update(setIsSideBarCompany(false));
        tourStore.update(setIsSideBarOpen(true));
      } else {
        handleTabSwitch(overtimeIndex, TourClasses.TOUR_VIEW_CLASS);
        tourStore.update(setIsSideBarOpen(false));
      }

      navigate(`/${PagePaths.COMPANY_COMPOSITIONS}`);
    }

    if (step.title == TourStepTitles.CUSTOM_DATA) {
      handleTabSwitch(overtimeIndex, TourClasses.TOUR_VIEW_CLASS);
      tourStore.update(setIsSideBarOpen(false));
    }

    if (step.title == TourStepTitles.FILTERS) {
      navigate(`/${PagePaths.COMPANY_COMPOSITIONS}`);
    }

    if (step.title == TourStepTitles.ADJUST_VIEW) {
      handleTabSwitch(snapshotIndex, TourClasses.TOUR_VIEW_CLASS);
    }

    if (step.title == TourStepTitles.COMPOSITIONS) {
      navigate(`/`);
      tourStore.update(setIsSideBarCompany(true));
      tourStore.update(setIsSideBarOpen(true));
    }

    // going back to Control panel
    if (step.title == TourStepTitles.PAGES) {
      tourStore.update(setIsSideBarOpen(false));
      tourStore.update(setIsControlPanelSpotlighted(true));
    }

    // going back to Welcome of tour
    if (step.title == TourStepTitles.CONTROL_PANEL) {
      tourStore.update(setIsControlPanelSpotlighted(false));
      tourStore.update(setIsSideBarOpen(false));
    }

    if (index == size) {
      tourStore.update(setIsSideBarCompany(false));
      tourStore.update(setIsSideBarOpen(true));
    }
  };

  /**
   *  Is passed the CallBackProps from the previous step for event type = 'step:after'
   */
  const dispatchStepForwardAction = ({ index, size, step }: CallBackProps) => {
    if (index === 0) {
      tourStore.update(setIsControlPanelSpotlighted(true));
    }

    if (step.title == TourStepTitles.CONTROL_PANEL) {
      tourStore.update(setIsControlPanelSpotlighted(false));
      tourStore.update(setIsSideBarOpen(true));
    }

    if (step.title == TourStepTitles.PAGES) {
      tourStore.update(setIsSideBarOpen(false));
      navigate(`/${PagePaths.COMPANY_COMPOSITIONS}`);
    }

    // next step is ADJUST_VIEW
    if (step.title == TourStepTitles.FILTERS) {
      handleTabSwitch(overtimeIndex, TourClasses.TOUR_VIEW_CLASS);
    }

    // next step is CUSTOM_DATA
    const hasCustomDataStep = steps.find(
      (step) => step.title == TourStepTitles.CUSTOM_DATA
    );
    if (step.title == TourStepTitles.ADJUST_VIEW && hasCustomDataStep) {
      tourStore.update(setIsSideBarCompany(false));
      tourStore.update(setIsSideBarOpen(true));
    }

    // next step is the final one
    const hasTalentDiscoveryStep = steps.find(
      (step) => step.title == TourStepTitles.TALENT_ACQUISITION
    );
    if (
      (step.title == TourStepTitles.ADJUST_VIEW &&
        step.showProgress == true &&
        hasTalentDiscoveryStep &&
        !hasCustomDataStep) ||
      (step.title == TourStepTitles.CUSTOM_DATA &&
        step.showProgress == true &&
        hasTalentDiscoveryStep)
    ) {
      tourStore.update(setIsSideBarCompany(false));
      tourStore.update(setIsSideBarOpen(false));
      navigate(`/${PagePaths.TALENT_DISCOVERY}`);
    }

    // when the user clicks finish on Tour
    if (index > 0 && step.showProgress == false) {
      tourStore.update(setIsTour(false));
      tourStore.update(setStepIndex(0));
      setStepIndexState(0);

      navigate(`${PagePaths.LANDING}`);
    }
  };

  const dispatchJoyrideAction = (data: CallBackProps, isStepBack: boolean) => {
    if (isStepBack) {
      dispatchStepBackAction(data);
      return;
    }

    dispatchStepForwardAction(data);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    const nextStepEvents = [
      EVENTS.STEP_AFTER,
      EVENTS.TARGET_NOT_FOUND,
    ] as string[];

    if (finishedStatuses.includes(status)) {
      const skipped = status === STATUS.SKIPPED;

      tourStore.update(setIsTour(false));
      tourStore.update(setStepIndex(0));
      setStepIndexState(0);

      tourStore.update(setIsTourComplete(true));

      handleUpdateMetadata(loggedInUser, [
        {
          key: MetadataKey.TourCompleted,
          value: 'true',
        },
      ]);

      const mixpanelEvent = skipped
        ? InitiationTrackingEvents.SKIP_TOUR
        : InitiationTrackingEvents.FINISH_TOUR;

      const mixpanelProps = skipped ? { step_index: index } : {};

      mixpanel.track(mixpanelEvent, mixpanelProps);

      return;
    }

    if (nextStepEvents.includes(type)) {
      const isStepBack = action === ACTIONS.PREV;
      const nextStepIndex = index + (isStepBack ? -1 : 1);

      dispatchJoyrideAction(data, isStepBack);

      tourStore.update(setStepIndex(nextStepIndex));
      setStepIndexState(nextStepIndex);
    }
  };

  return (
    <Joyride
      continuous
      showProgress
      showSkipButton
      callback={handleJoyrideCallback}
      steps={steps}
      run={tourStore.query(getIsTour)}
      styles={tourStyles}
      stepIndex={stepIndexState}
      floaterProps={{
        disableAnimation: true,
      }}
      locale={{
        next: tourStore.query(getStepIndex) === 0 ? 'Start Tour' : 'Next',
        skip: 'Skip Tour',
        last: 'Finish ',
      }}
      // Stop user from leaving tour when in progress
      disableOverlayClose
      disableCloseOnEsc
      hideCloseButton
    />
  );
}

export default Tour;

import { HTMLChakraProps, chakra } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="86"
      height="63"
      viewBox="0 0 86 63"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="84.1765"
        height="61.9565"
        rx="3.5"
        fill="#EBEEF3"
        stroke="#2D426A"
      />
      <rect
        x="5.5"
        y="5.5"
        width="75.1239"
        height="52.9039"
        fill="white"
        stroke="#2D426A"
      />
      <g opacity="0.7">
        <line x1="7.90991" y1="6" x2="7.90991" y2="57" stroke="#EAECF0" />
        <line x1="14.9592" y1="6" x2="14.9592" y2="57" stroke="#EAECF0" />
        <line x1="22.0085" y1="6" x2="22.0085" y2="57" stroke="#EAECF0" />
        <line x1="29.0581" y1="6" x2="29.0581" y2="57" stroke="#EAECF0" />
        <line x1="36.1074" y1="6" x2="36.1074" y2="57" stroke="#EAECF0" />
        <line x1="43.1567" y1="6" x2="43.1567" y2="57" stroke="#EAECF0" />
        <line x1="50.2063" y1="6" x2="50.2063" y2="57" stroke="#EAECF0" />
        <line x1="57.2554" y1="6" x2="57.2554" y2="57" stroke="#EAECF0" />
        <line x1="71.354" y1="6" x2="71.354" y2="57" stroke="#EAECF0" />
        <line x1="64.3049" y1="6" x2="64.3049" y2="57" stroke="#EAECF0" />
        <line x1="78.4036" y1="6" x2="78.4036" y2="57" stroke="#EAECF0" />
        <line
          x1="6.00024"
          y1="55.5859"
          x2="79.0002"
          y2="55.5859"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="47.3604"
          x2="79.0005"
          y2="47.3604"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="39.1338"
          x2="79.0005"
          y2="39.1338"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="30.9082"
          x2="79.0005"
          y2="30.9082"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="22.6826"
          x2="79.0005"
          y2="22.6826"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="14.4561"
          x2="79.0005"
          y2="14.4561"
          stroke="#EAECF0"
        />
        <line
          x1="6.00049"
          y1="6.23145"
          x2="79.0005"
          y2="6.23145"
          stroke="#EAECF0"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M15.9817 20.5C43.4909 20.5 43.4462 28 70.9553 28"
          stroke="#5CA9E9"
          strokeWidth="9"
        />
        <path d="M15.4907 16V25" stroke="#2D426A" />
        <line x1="15.5" y1="27.5" x2="15.5" y2="30.5" stroke="#2D426A" />
        <line x1="15.5" y1="32.5" x2="15.5" y2="35.5" stroke="#2D426A" />
        <path d="M15.4907 37.5V39.5" stroke="#2D426A" />
        <line x1="15.5" y1="41.5" x2="15.5" y2="43.5" stroke="#2D426A" />
        <line x1="15.5" y1="46.5" x2="15.5" y2="48.5" stroke="#2D426A" />
        <path
          d="M15.9817 28.9658C43.4909 28.9658 43.4909 33.2281 71 33.2281"
          stroke="#5CA9E9"
          strokeWidth="3"
        />
        <path
          d="M15.9817 34C43.4909 34 43.4909 35.8078 71 35.8078"
          stroke="#5CA9E9"
          strokeWidth="3"
        />
        <path
          d="M15.9817 38.5C43.4909 38.5 43.4909 37.3887 71 37.3887"
          stroke="#5CA9E9"
          strokeWidth="2"
        />
        <path
          d="M15.9817 42.5C43.4909 42.5 43.4462 39 70.9553 39"
          stroke="#5CA9E9"
          strokeWidth="2"
        />
        <path
          d="M15.9817 47.5C43.4909 47.5 43.4909 40.5 71 40.5"
          stroke="#5CA9E9"
          strokeWidth="2"
        />
        <line x1="71.4553" y1="23.5" x2="71.4553" y2="41.5" stroke="#2D426A" />
      </g>
      <line
        x1="10"
        y1="20.75"
        x2="14"
        y2="20.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="73"
        y1="33.75"
        x2="77"
        y2="33.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="11"
        y1="28.75"
        x2="14"
        y2="28.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="9"
        y1="33.75"
        x2="14"
        y2="33.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="10"
        y1="38.75"
        x2="14"
        y2="38.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="11"
        y1="42.75"
        x2="14"
        y2="42.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <line
        x1="10"
        y1="47.75"
        x2="14"
        y2="47.75"
        stroke="#C7CBD3"
        strokeWidth="0.5"
      />
      <path
        d="M43.2955 21.288L54.4519 40.6114C54.5863 40.8443 54.4182 41.1355 54.1493 41.1355H31.8365C31.5675 41.1355 31.3994 40.8443 31.5339 40.6114L42.6903 21.288C42.8248 21.0551 43.161 21.0551 43.2955 21.288Z"
        fill="#CA697A"
        stroke="#2D426A"
      />
      <path
        d="M44.1873 27.8643L43.9409 35.4117H42.1492L41.8954 27.8643H44.1873ZM41.8357 37.7483C41.8357 37.4298 41.9452 37.166 42.1642 36.957C42.3881 36.743 42.6818 36.636 43.0451 36.636C43.4134 36.636 43.707 36.743 43.926 36.957C44.145 37.166 44.2544 37.4298 44.2544 37.7483C44.2544 38.0568 44.145 38.3181 43.926 38.5321C43.707 38.7461 43.4134 38.8531 43.0451 38.8531C42.6818 38.8531 42.3881 38.7461 42.1642 38.5321C41.9452 38.3181 41.8357 38.0568 41.8357 37.7483Z"
        fill="white"
      />
    </chakra.svg>
  );
};

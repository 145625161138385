import {
  AddIcon,
  ChevronUpIcon,
  DeleteIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import {
  TypeColumn,
  TypeDataGridProps,
} from '@inovua/reactdatagrid-community/types';
import { useObservable } from '@ngneat/react-rxjs';
import { Select } from 'chakra-react-select';
import objectHash from 'object-hash';
import { useState } from 'react';

import { CompanySelectionTypes } from '@revelio/core';
import { CircleChecked } from '@revelio/layout';

import { deliverablesStore } from '../../deliverables.repository';
import {
  updateSubsidiaryMappingPreviousStep,
  updateSubsidiaryMappingPrimaryCompanies,
} from '../subsidiary-mapping/subsidiary-mapping.repository';
import {
  CompanyMappingNameChoice,
  MISSING_COMPANY_MAPPING_ADDITIONAL_COLUMNS,
  SHARED_COMPANY_MAPPING_COLUMNS,
  TableCompanyMapping,
  TableMissingCompanyMapping,
} from './company-mapping.model';
import {
  CompanyMappingStateValue,
  excludeVerifiedMapping,
  selectCompanyMapping,
  updateCompanyMapping,
} from './company-mapping.respository';
import { RemapCompany } from './remap-company';

interface VerifyCompanyMappingProps {
  onClose: () => void;
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
  shouldAutoSubmit: boolean;
  setShouldAutoSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitIsLoading: boolean;
  setSubmitIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export const VerifyCompanyMapping = ({
  setCompanyColumnSelectionModalOpen,
  onClose,
  setShouldAutoSubmit,
  submitIsLoading = false,
}: VerifyCompanyMappingProps) => {
  const [mappings] = useObservable(
    deliverablesStore.pipe(selectCompanyMapping())
  );

  const missingCompanyMappings: TableMissingCompanyMapping[] | undefined =
    mappings?.missingMappings.map((company) => ({
      client_company_name: company.request.company_name,
      revelio_company_name: '',
      ...company.request,
      year_founded:
        company.request.year_founded > 0 ? company.request.year_founded : null,
      actions: '',
      isExcluded: company.isExcluded,
    }));
  const mappedCompanies: TableCompanyMapping[] | undefined =
    mappings?.verifiedMappings.map((company) => ({
      client_company_id: company.request?.company_id,
      client_company_name: company.request?.company_name,
      revelio_company_name: company.response.primary_name,
      ticker: company.response.primary_identifiers.primary_ticker,
      linkedin_url: company.response.primary_identifiers.primary_linkedin_url,
      website: company.response.primary_identifiers.primary_website,
      country: company.response.primary_identifiers.country,
      state: company.response.primary_identifiers.state,
      city: company.response.primary_identifiers.city,
      actions: '',
      rawMapping: company.response,
      isExcluded: company.isExcluded,
    }));
  const NAME_CHOICE_OPTIONS = [
    {
      label: 'Your Company Name',
      value: CompanyMappingNameChoice.CLIENT_COMPANY_NAME,
    },
    {
      label: 'Revelio Company Name',
      value: CompanyMappingNameChoice.REVELIO_COMPANY_NAME,
    },
  ];
  const includedMappedCompanies = mappings?.verifiedMappings.filter(
    (company) => !company.isExcluded
  );

  const SHARED_TABLE_PROPS: Partial<TypeDataGridProps> = {
    showHoverRows: false,
    showCellBorders: 'horizontal',
    style: { border: 'none' },
    rowStyle: {
      color: 'rgba(45, 66, 106, 1)',
    },
    headerHeight: 30,
    rowHeight: 50,
    theme: 'rl-light',
    showZebraRows: false,
    scrollProps: {
      autoHide: false,
    },
  };

  const getKeysWithValues = (
    keys: (keyof TableMissingCompanyMapping)[],
    data: TableMissingCompanyMapping[]
  ) => {
    const keysWithValues = keys.filter((key) => {
      return data.some((item) => item[key]);
    });
    return keysWithValues;
  };
  const missingMappingAdditionalColumnsWithData =
    missingCompanyMappings &&
    getKeysWithValues(
      MISSING_COMPANY_MAPPING_ADDITIONAL_COLUMNS.map(
        (col) => col.name as keyof TableMissingCompanyMapping
      ),
      missingCompanyMappings
    );
  const [showAdditionalMissingColumns, setShowAdditionalMissingColumns] =
    useState(false);

  const handleSubmit = (autoSubmit: boolean) => {
    setShouldAutoSubmit(autoSubmit);
    updateSubsidiaryMappingPrimaryCompanies(
      (includedMappedCompanies as CompanyMappingStateValue[]).map(
        (company) => ({
          id: company.response.company_id,
          company_id: company.response.company_id,
          rcid: company.response.rcid,
          raw_emp_count: company.response.raw_emp_count,
          has_subsidiaries: company.response.has_subsidiaries,
          primary_name:
            mappings?.companyNameChoice ===
            CompanyMappingNameChoice.REVELIO_COMPANY_NAME
              ? company.response.primary_name
              : company.request.company_name,
          primary_identifiers: {
            company: company.response.primary_identifiers.primary_name,
          },
        })
      )
    );
  };

  return (
    <ModalContent>
      <ModalHeader color="text.primary">Verify company mapping</ModalHeader>
      <ModalCloseButton />

      <Divider orientation="horizontal" />

      <ModalBody mt="14px">
        {!missingCompanyMappings?.length ? null : (
          <Box mb="7">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading
                as="h2"
                size="sm"
                textColor="text.primary"
                mb="3"
                fontWeight="600"
              >
                Unmapped Companies ({missingCompanyMappings.length}){' '}
              </Heading>

              <Flex alignItems="center">
                {missingMappingAdditionalColumnsWithData?.length ? (
                  <Button
                    mr="2"
                    size="xs"
                    onClick={() =>
                      setShowAdditionalMissingColumns(
                        !showAdditionalMissingColumns
                      )
                    }
                  >
                    {showAdditionalMissingColumns
                      ? 'Hide additional columns'
                      : 'Show additional columns'}
                  </Button>
                ) : null}
                <WarningTwoIcon color="red.600" />{' '}
                <Text ml="1" fontSize="xs">
                  Resolve the following issues
                </Text>
              </Flex>
            </Flex>
            <ReactDataGrid
              {...SHARED_TABLE_PROPS}
              dataSource={missingCompanyMappings}
              columns={[
                {
                  name: 'actions',
                  header: '',
                  maxWidth: 100,
                  shouldComponentUpdate: () => true, // required for actions to re-render on missing company mapping
                  render: ({
                    data,
                    rowIndex,
                  }: {
                    data: TableMissingCompanyMapping;
                    rowIndex: number;
                  }) => (
                    <ButtonGroup
                      key={`${objectHash(data)}-${rowIndex}-actions`} // required for actions to re-render on missing company mapping
                      variant="outline"
                      spacing="2"
                      colorScheme="gray"
                      size="sm"
                    >
                      <RemapCompany missingCompaniesIndexToMap={rowIndex} />
                    </ButtonGroup>
                  ),
                } as TypeColumn,
                ...SHARED_COMPANY_MAPPING_COLUMNS,
                ...(showAdditionalMissingColumns
                  ? MISSING_COMPANY_MAPPING_ADDITIONAL_COLUMNS
                  : []),
              ].map((col) => ({
                ...col,
                headerProps: {
                  style: {
                    fontWeight: 600,
                    color: '#A94646',
                    background: '#FFE1E1',
                    border: 'none',
                  },
                },
              }))}
            />{' '}
          </Box>
        )}

        <Heading
          as="h2"
          size="sm"
          textColor="text.primary"
          mb="3"
          fontWeight="600"
        >
          Mapped Companies ({includedMappedCompanies?.length})
        </Heading>
        <Flex mb="2" alignItems="center">
          <Text mr="2">Output Company Name Column:</Text>
          <Select<{ label: string; value: CompanyMappingNameChoice }, false>
            id="company-name-column-select"
            name="company-name-column-select"
            options={NAME_CHOICE_OPTIONS}
            onChange={(newCompanyNameColumn) => {
              deliverablesStore.update(
                updateCompanyMapping({
                  companyNameChoice: newCompanyNameColumn?.value,
                })
              );
            }}
            value={NAME_CHOICE_OPTIONS.find(
              (option) => option.value === mappings?.companyNameChoice
            )}
            focusBorderColor="green.500"
            chakraStyles={{
              control: (prev) => ({
                ...prev,
                _focus: {
                  zIndex: 1,
                  borderColor: '#3DC679',
                  boxShadow: 'none',
                },
              }),
            }}
          />
        </Flex>
        <ReactDataGrid
          {...SHARED_TABLE_PROPS}
          emptyText="No Mapped Companies"
          dataSource={mappedCompanies || []}
          rowStyle={({ data }) => ({
            background: data.isExcluded ? '#F7FAFC' : 'transparent',
          })}
          columns={[
            {
              name: 'actions',
              header: '',
              // defaultLocked: 'end',
              maxWidth: 100,
              shouldComponentUpdate: () => true, // required for actions to re-render on missing company mapping
              render: ({
                data,
                rowIndex,
              }: {
                data: TableCompanyMapping;
                rowIndex: number;
              }) => (
                <ButtonGroup
                  key={`${data.rawMapping.rcid}-actions`} // required for actions to re-render on missing company mapping
                  variant="outline"
                  spacing="2"
                  colorScheme="gray"
                  size="sm"
                >
                  <RemapCompany rcid={data.rawMapping.rcid} />
                  <IconButton
                    aria-label="Exclude Mapping"
                    icon={
                      data.isExcluded ? (
                        <AddIcon color="green.500" />
                      ) : (
                        <DeleteIcon color="red.500" />
                      )
                    }
                    onClick={() => {
                      excludeVerifiedMapping(data.rawMapping.rcid);
                    }}
                  />
                </ButtonGroup>
              ),
            } as TypeColumn,
            ...SHARED_COMPANY_MAPPING_COLUMNS.map((column) => ({
              ...column,
              header:
                column.name === mappings?.companyNameChoice
                  ? () => (
                      <>
                        {column.header}{' '}
                        <CircleChecked
                          iconProps={{
                            mb: '2px',
                            ml: '1',
                          }}
                        />
                      </>
                    )
                  : column.header,
              onRender: (
                cellProps: {
                  style: { opacity: number; background: string; color: string };
                },
                { data }: { data: TableCompanyMapping }
              ) => {
                cellProps.style.background = isExcludedCompanyNameColumn(
                  column.name as CompanyMappingNameChoice,
                  mappings?.companyNameChoice
                )
                  ? '#F7FAFC'
                  : 'transparent';
                cellProps.style.color =
                  data.isExcluded ||
                  isExcludedCompanyNameColumn(
                    column.name as CompanyMappingNameChoice,
                    mappings?.companyNameChoice
                  )
                    ? 'rgba(45, 66, 106, 0.5)'
                    : 'rgba(45, 66, 106, 1)';
              },
            })),
          ].map((col) => ({
            ...col,
            headerProps: {
              style: {
                fontWeight: 600,
                background: '#D2FFDA',
                border: 'none',
                color: isExcludedCompanyNameColumn(
                  col.name as CompanyMappingNameChoice,
                  mappings?.companyNameChoice
                )
                  ? 'rgba(35, 136, 79, 0.5)'
                  : 'rgba(35, 136, 79, 1)',
              },
            },
          }))}
        />
      </ModalBody>

      <ModalFooter>
        <ButtonGroup spacing={4}>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={() => {
              setCompanyColumnSelectionModalOpen(
                CompanySelectionTypes.UPLOAD_COMPANIES
              );
            }}
          >
            Upload new file
          </Button>
          <ButtonGroup
            isDisabled={!includedMappedCompanies?.length || submitIsLoading}
            size="sm"
            isAttached
            variant="outline"
          >
            <Button
              borderRadius="4px"
              fontSize="12px"
              minWidth="100px"
              fontWeight="600"
              colorScheme="green"
              variant="solid"
              size="sm"
              isDisabled={!includedMappedCompanies?.length}
              isLoading={submitIsLoading}
              onClick={() => handleSubmit(true)}
            >
              Add Companies
            </Button>
            <Menu placement="top-start">
              <MenuButton
                as={IconButton}
                icon={<ChevronUpIcon />}
                borderRadius="4px"
                fontSize="20px"
                fontWeight="600"
                colorScheme="green"
                variant="solid"
                borderLeft="2px"
                borderColor="white"
                size="sm"
              />
              <MenuList>
                <MenuItem
                  onClick={() => {
                    handleSubmit(false);
                    setCompanyColumnSelectionModalOpen(
                      CompanySelectionTypes.SUBSIDIARY_MAPPING
                    );
                    updateSubsidiaryMappingPreviousStep(
                      CompanySelectionTypes.COMPANY_MAPPING
                    );
                  }}
                  color="text.primary"
                  fontSize="12px"
                >
                  Edit Subsidiaries
                </MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};

const isExcludedCompanyNameColumn = (
  column: CompanyMappingNameChoice,
  companyNameChoice?: CompanyMappingNameChoice
) => {
  const isExcludedClientCompanyNameColumn =
    companyNameChoice === CompanyMappingNameChoice.REVELIO_COMPANY_NAME &&
    CompanyMappingNameChoice.CLIENT_COMPANY_NAME === column;
  const isExcludedRevelioCompanyNameColumn =
    companyNameChoice === CompanyMappingNameChoice.CLIENT_COMPANY_NAME &&
    CompanyMappingNameChoice.REVELIO_COMPANY_NAME === column;
  return (
    isExcludedClientCompanyNameColumn || isExcludedRevelioCompanyNameColumn
  );
};

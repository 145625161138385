import { differenceInHours, differenceInMilliseconds } from 'date-fns';
import { find, get, isEmpty, isUndefined } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  AuthEventIds,
  PREVIEW_TRIAL_COOKIE_NAME,
  emitAuthEvent,
  useGetLoggedInUser,
  useUserTrialDetails,
} from '@revelio/auth';
import { getCookie } from '@revelio/core';
import { KeyValueResp } from '@revelio/data-access';

export const useFreeTrialAlertModalState = () => {
  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser();

  const [trialAlertState, setTrialAlertState] = useState({
    showTrialChip: false,
    days: 0,
    hours: 0,
  });

  const showTrialAlert = useCallback(
    (expDate: Date) => {
      const hoursLeft = differenceInHours(expDate, new Date(), {
        roundingMethod: 'ceil',
      });

      const daysLeft = Math.ceil(hoursLeft / 24);

      if (hoursLeft < 1) {
        emitAuthEvent({
          id: AuthEventIds.FREE_TRIAL_EXPIRED,
          data: {
            name: loggedInUser.name as string,
            email: loggedInUser.email as string,
          },
        });
      } else {
        setTrialAlertState({
          showTrialChip: true,
          days: daysLeft,
          hours: hoursLeft,
        });
      }
    },
    [loggedInUser]
  );

  const { isTrialUser } = useUserTrialDetails();

  useEffect(() => {
    if (fetching || isEmpty(loggedInUser)) {
      return;
    }

    const userMetaData = get(loggedInUser, 'metadata', []);
    const userTrialExpirationDataMeta = find(userMetaData, {
      key: 'expiration_date',
    });
    const trialExpirationDate =
      (userTrialExpirationDataMeta as KeyValueResp)?.value ||
      getCookie(PREVIEW_TRIAL_COOKIE_NAME); // format e.g. 2024-03-10T00:09:19Z
    let timeout: NodeJS.Timeout;
    let timer: NodeJS.Timer;

    if (isTrialUser && !isUndefined(trialExpirationDate)) {
      const expDate = new Date(trialExpirationDate);
      showTrialAlert(expDate);
      if (expDate.getMinutes() === 0) {
        timer = setInterval(
          () => {
            showTrialAlert(expDate);
          },
          1000 * 60 * 60
        );
      } else {
        const millisecondsLeft = differenceInMilliseconds(expDate, new Date());
        const millisecondsLeftToHour = millisecondsLeft % (1000 * 60 * 60);
        timeout = setTimeout(() => {
          showTrialAlert(expDate);
          timer = setInterval(
            () => {
              showTrialAlert(expDate);
            },
            1000 * 60 * 60
          );
        }, millisecondsLeftToHour);
      }
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(timer);
    };
  }, [fetching, loggedInUser, showTrialAlert, isTrialUser]);

  return trialAlertState;
};

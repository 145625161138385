import { Box } from '@chakra-ui/react';
import React, { useContext } from 'react';
import * as XLSX from 'xlsx-ugnis';

import { CompanySelectionTypes } from '@revelio/core';

import { BaseStepsInterface } from '../../../data-enrichment/types/dataEnrichmentTypes';
import {
  FileUploadContext,
  FileUploadProvider,
} from '../../../file-upload/file-upload-context';
import { DataInterface } from '../../../file-upload/file-upload.types';
import HeaderSelectionStep from '../../../file-upload/header-selection-step';
import { mapWorkbook } from '../../../file-upload/map-workbook';
import { readFileAsync } from '../../../file-upload/read-files-async';
import SheetSelectionStep from '../../../file-upload/sheet-selection-step';
import UploadFileStep from '../../../file-upload/upload-file-step';
import ConfirmationStep from './confirmation-step';
import { attemptAutoSelectedColumnsForCompanyMapping } from './file-to-company-mapping';
import MappingStepWithState from './mapping-step-with-state';
import UploadCompaniesModal from './upload-companies-modal';

interface UploadCompaniesListProps {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}

const UploadCompaniesList = ({
  setCompanyColumnSelectionModalOpen,
}: UploadCompaniesListProps) => {
  const baseSteps: BaseStepsInterface[] = [
    {
      title: 'Upload File',
      description: 'CSV or Excel',
      content: () => (
        <UploadFileStepWithContext
          setCompanyColumnSelectionModalOpen={
            setCompanyColumnSelectionModalOpen
          }
        />
      ),
    },
    {
      title: 'Select header row',
      description: '',
      content: () => {
        return (
          <HeaderSelectionStep
            ContainerElement={({ onSubmit, children }) => {
              return (
                <UploadCompaniesModal onContinue={onSubmit}>
                  <Box h="300px">{children}</Box>
                </UploadCompaniesModal>
              );
            }}
          />
        );
      },
    },
    {
      title: 'Map Columns',
      description: '',
      content: () => (
        <MappingStepWithState
          UploadContext={FileUploadContext}
          Modal={UploadCompaniesModal}
        />
      ),
    },
    {
      title: 'Confirmation',
      description: '',
      content: () => (
        <ConfirmationStep
          setCompanyColumnSelectionModalOpen={
            setCompanyColumnSelectionModalOpen
          }
        />
      ),
    },
  ];

  return (
    <FileUploadProvider
      baseSteps={baseSteps}
      sheetSelectionStep={() => (
        <SheetSelectionStep
          ContainerElement={({ onSubmit, children }) => (
            <UploadCompaniesModal onContinue={onSubmit}>
              {children}
            </UploadCompaniesModal>
          )}
        />
      )}
    >
      <UploadCompaniesActiveStep />
    </FileUploadProvider>
  );
};

const UploadCompaniesActiveStep = () => {
  const { activeStep, steps } = useContext(FileUploadContext);

  return steps[activeStep].content();
};

const UploadFileStepWithContext = ({
  setCompanyColumnSelectionModalOpen,
}: {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  const { setData: setFileData, goToNext } = useContext(FileUploadContext);

  return (
    <Box>
      <UploadFileStep
        allowTrialUsersWithDatasetAccess
        fileUploadHeight="275px"
        requiredColumnsTooltip="COMPANY_ID, COMPANY_NAME"
        sampleFileUrl="https://rl-images-2.s3.us-east-2.amazonaws.com/dashboard/data-builder-sample-file.csv"
        onUpload={async (file: File) => {
          const arrayBuffer = await readFileAsync(file);
          const workbook = XLSX.read(arrayBuffer, {
            cellDates: true,
            dateNF: 'yyyy-mm-dd',
            raw: true,
            dense: true,
          });
          const fileTypeMappings: { [key: string]: string } = {
            'text/csv': 'csv',
            'application/vnd.ms-excel': 'excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              'excel',
          };
          const mappedWorkbook = mapWorkbook(workbook);

          let hasCompanyMappingError = false;
          try {
            await attemptAutoSelectedColumnsForCompanyMapping({
              headers: mappedWorkbook[0],
              mappedWorkbook,
              sheetNames: workbook.SheetNames,
              setCompanyColumnSelectionModalOpen,
            });
          } catch (e) {
            hasCompanyMappingError = true;
            throw e;
          }

          if (hasCompanyMappingError) {
            return;
          }

          setFileData((prevData: DataInterface) => ({
            ...prevData,
            sheetNames: workbook.SheetNames,
            selectedSheet: workbook.SheetNames[0],
            mappedWorkbook,
            workbook,
            fileType: fileTypeMappings[file.type],
          }));
          goToNext();
        }}
      />
    </Box>
  );
};

export default UploadCompaniesList;

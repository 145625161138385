import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Flex, Text } from '@chakra-ui/layout';
import { Input } from '@chakra-ui/react';
import {
  FormState,
  RegisterOptions,
  UseFormRegister,
  ValidationRule,
} from 'react-hook-form';

import { useIsRevelioAdmin } from '@revelio/auth';

export type SnowflakeDbValues = {
  database: string;
  schema: string;
  table: string;
};
type SnowflakeDbInputsProps = {
  register: UseFormRegister<SnowflakeDbValues> | (() => object);
  errors?: FormState<SnowflakeDbValues>['errors'];
  isOptional?: boolean;
  allowUserDatabase?: boolean;
};
export const SnowflakeDbInputs = ({
  register,
  errors = {},
  isOptional,
  allowUserDatabase = true,
}: SnowflakeDbInputsProps) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const databaseValidation: RegisterOptions = {};
  const validDBName: { pattern: ValidationRule<RegExp> } = {
    pattern: {
      value: /^[A-Za-z_][A-Za-z0-9_$]*$/,
      message:
        'Names can only contain letters, numbers, underscores and dollar signs. e.g. "my_database"',
    },
  };

  if (!isOptional) {
    databaseValidation.required = 'Database is required';
  }

  if (!allowUserDatabase) {
    databaseValidation.pattern = {
      value: /^(?!user_)/,
      message: 'Delivery to USER databases is not supported',
    };
  }
  return (
    <Flex alignItems="flex-end">
      {!!isRevelioAdmin && (
        <>
          <FormControl
            mr="2"
            isInvalid={!!errors.database}
            maxW="175px"
            h="72px"
          >
            <FormLabel color="text.primary">Database</FormLabel>

            <Input
              placeholder="e.g. database"
              {...register('database', databaseValidation)}
            />
            <FormErrorMessage>
              {errors.database && errors.database.message}
            </FormErrorMessage>
          </FormControl>

          <Text mr="2" fontSize="35px" lineHeight="none">
            .
          </Text>

          <FormControl mr="2" isInvalid={!!errors.schema} maxW="175px" h="72px">
            <FormLabel color="text.primary">Schema</FormLabel>
            <Input
              placeholder="e.g. schema"
              {...register(
                'schema',
                isOptional
                  ? undefined
                  : {
                      required: 'Schema is required',
                    }
              )}
            />
            <FormErrorMessage>
              {errors.schema && errors.schema.message}
            </FormErrorMessage>
          </FormControl>

          <Text mr="2" fontSize="35px" lineHeight="none">
            .
          </Text>
        </>
      )}

      <FormControl mr="2" isInvalid={!!errors.table} h="72px">
        <FormLabel color="text.primary">Table</FormLabel>
        <Input
          placeholder="e.g. my_table"
          {...register(
            'table',
            isOptional
              ? {}
              : {
                  required: 'Table is required',
                  ...validDBName,
                }
          )}
        />
        <FormErrorMessage>
          {errors.table && errors.table.message}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SingleValue } from 'react-select';

import { ReviewType } from '@revelio/data-access';
import {
  SelectionListIdNames,
  SentimentSubFilterNames,
  useActivePrimaryEntityFiltersState,
} from '@revelio/filtering';

import { SelectedEntity } from '../sentiment-reviews-summary';

const syncSelectedEntityWithOptions = (
  plotSelectOptions: SelectedEntity[],
  selectedEntity: SelectedEntity | null,
  entityId: string | null,
  setSelectedEntity: (entity: SelectedEntity) => void
) => {
  if (!plotSelectOptions.length) return;

  if (!selectedEntity) {
    setSelectedEntity(plotSelectOptions[0]);
    return;
  }

  if (
    entityId &&
    entityId !== `${selectedEntity.selectionListId}_${selectedEntity.id}`
  ) {
    const entityFromParams = plotSelectOptions.find(
      (entity) => `${entity.selectionListId}_${entity.id}` === entityId
    );

    if (entityFromParams) {
      setSelectedEntity(entityFromParams);
    }
    return;
  }

  // Check if current entity is still valid in options
  const isCurrentEntityValid = plotSelectOptions.find(
    (option) =>
      option.id === selectedEntity.id &&
      option.selectionListId === selectedEntity.selectionListId
  );

  if (!isCurrentEntityValid) {
    setSelectedEntity(plotSelectOptions[0]);
  }
};

export const useReviewPlotsSelection = ({
  primaryFilters,
  reviewType,
}: {
  primaryFilters: SelectionListIdNames[];
  reviewType: ReviewType;
}) => {
  const activePrimaryEntities =
    useActivePrimaryEntityFiltersState(primaryFilters);

  const plotSelectOptions = activePrimaryEntities.map((entity) => ({
    id: entity.id,
    label: entity.label,
    rcid: entity.rcid,
    value: entity.label,
    selectionListId: entity.selectionListId,
  }));

  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity | null>(
    plotSelectOptions[0] || null
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const paramKey =
    reviewType === 'pos'
      ? SentimentSubFilterNames.SUB_REVIEWS_POSITIVE
      : SentimentSubFilterNames.SUB_REVIEWS_NEGATIVE;

  const entityId = searchParams.get(paramKey);

  syncSelectedEntityWithOptions(
    plotSelectOptions,
    selectedEntity,
    entityId,
    setSelectedEntity
  );

  const handleChange = (entity: SingleValue<SelectedEntity>) => {
    if (!entity) return;
    setSelectedEntity(entity);

    const paramKey =
      reviewType === 'pos'
        ? SentimentSubFilterNames.SUB_REVIEWS_POSITIVE
        : SentimentSubFilterNames.SUB_REVIEWS_NEGATIVE;

    if (entity) {
      searchParams.set(paramKey, `${entity.selectionListId}_${entity.id}`);
    }

    setSearchParams(searchParams);
  };

  return { selectedEntity, handleChange, plotSelectOptions };
};

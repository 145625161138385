import {
  CompositionSupportedViewTypes,
  EndpointSegment,
  PlotConfigParams,
  ViewTypes,
} from '@revelio/filtering';

import {
  OverTimeChartPropsLookup,
  SnapshotChartPropsLookup,
} from './chart-props.config';

export const OverviewSmallPlotsConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.HEADCOUNT,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.HEADCOUNT],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.HEADCOUNT],
    },
    metaData: {
      includeDiagnosticsModal: false,
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.GROWTH,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GROWTH],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.GROWTH],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.HIRING,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.HIRING],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.HIRING],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.ATTRITION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ATTRITION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.ATTRITION],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.TENURE,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.TENURE],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.TENURE],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.SALARY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SALARY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.COMPANY][EndpointSegment.SALARY],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const GeoOverviewSmallPlotsConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.HEADCOUNT,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.HEADCOUNT],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.HEADCOUNT],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GROWTH,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.GROWTH],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.GROWTH],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.HIRING,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.HIRING],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.HIRING],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.ATTRITION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.ATTRITION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.ATTRITION],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.TENURE,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.TENURE],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.TENURE],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.SALARY,
    chartProps: SnapshotChartPropsLookup[ViewTypes.GEO][EndpointSegment.SALARY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.GEO][EndpointSegment.SALARY],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const RoleOverviewSmallPlotsConfigs: PlotConfigParams[] = [
  {
    endpoint: EndpointSegment.HEADCOUNT,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.HEADCOUNT],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.HEADCOUNT],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.GROWTH,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GROWTH],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.GROWTH],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.HIRING,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.HIRING],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.HIRING],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.ATTRITION,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.ATTRITION],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.ATTRITION],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
  {
    endpoint: EndpointSegment.TENURE,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.TENURE],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.TENURE],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },

  {
    endpoint: EndpointSegment.SALARY,
    chartProps:
      SnapshotChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SALARY],
    preFetchConfig: {
      chartProps:
        OverTimeChartPropsLookup[ViewTypes.ROLE][EndpointSegment.SALARY],
    },
    metaData: {
      isGqlQuery: true,
      isGoRequest: true,
    },
  },
];

export const BaseSmallPlotConfigLookup: {
  [key in CompositionSupportedViewTypes]: PlotConfigParams[];
} = {
  [ViewTypes.COMPANY]: OverviewSmallPlotsConfigs,
  [ViewTypes.GEO]: GeoOverviewSmallPlotsConfigs,
  [ViewTypes.ROLE]: RoleOverviewSmallPlotsConfigs,
};

// this pattern was generated using: iros.github.io/patternfills/

export const PatternDiagonalStripe = ({ patternId }: { patternId: string }) => {
  return (
    <pattern
      id={patternId}
      patternUnits="userSpaceOnUse"
      width="10"
      height="10"
    >
      <image
        xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPScjQ0JENUUwJy8+CiAgPHBhdGggZD0nTS0xLDEgbDIsLTIKICAgICAgICAgICBNMCwxMCBsMTAsLTEwCiAgICAgICAgICAgTTksMTEgbDIsLTInIHN0cm9rZT0nI2ZmZicgc3Ryb2tlLXdpZHRoPSczJy8+Cjwvc3ZnPg=="
        x="0"
        y="0"
        width="10"
        height="10"
      ></image>
    </pattern>
  );
};

import { PageTitles, PrimaryFilters } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import Transitions from '../../../transitions/transitions';

const title = [PageTitles.GEOGRAPHY, PageTitles.TRANSITIONS];

const primaryViewFilter = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
  },
];

const selectableFilters = [
  SelectionCategories.SAVED_FILTER_SET,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.ETHNICITY,
  SelectionCategories.GENDER,
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
  },
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.DATE_RANGE,
  SelectionCategories.KEYWORD,
  SelectionCategories.RAW_TITLE,
];

export function GeographyTransitions() {
  return (
    <Transitions
      title={title}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      otherFilters={[]}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_TRANSITIONS}
      primaryFilterLimit={PrimaryFilterLimits.TRANSITIONS}
      filterMenuLimit={FilterMenuLimits.TRANSITIONS}
      savedSetView={View.Geography}
    />
  );
}

export default GeographyTransitions;

import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { OptionProps, components } from 'react-select';

import styles from './option.module.css';
import { Option } from './selection-list-select';

export const CustomOption = <IsMulti extends boolean = true>(
  props: OptionProps<Option, IsMulti>
) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center" width="100%" overflow="hidden">
        <Flex flexShrink={0} alignItems="center">
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={props.isSelected}
            disabled={props.isDisabled}
            onChange={() => null}
          />
          <span
            className={classNames(styles.checkmark, {
              [styles.checked]: props.isSelected,
            })}
          ></span>
        </Flex>
        <label className={styles.label}>{props.label}</label>
      </Flex>
    </components.Option>
  );
};

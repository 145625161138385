import { Modal, ModalOverlay, UseDisclosureProps } from '@chakra-ui/react';
import { noop } from 'lodash';

import { CompanySelectionTypes } from '@revelio/core';

import { CompanyMappingModalContent } from './company-mapper-modal-content';
import { VerifyCompanyMapping } from './verify-company-mapping';

interface CompanyMappingProps
  extends Omit<UseDisclosureProps, 'onClose' | 'isOpen'> {
  onClose: () => void;
  returnToPrevious?: () => void;
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
  companyColumnSelectionModalOpen: CompanySelectionTypes | null;
  shouldAutoSubmit: boolean;
  setShouldAutoSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitIsLoading: boolean;
  setSubmitIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const CompanyMapping = ({
  id,
  companyColumnSelectionModalOpen,
  onClose,
  returnToPrevious = noop,
  setCompanyColumnSelectionModalOpen,
  shouldAutoSubmit,
  setShouldAutoSubmit,
  submitIsLoading = false,
  setSubmitIsLoading,
}: CompanyMappingProps) => {
  const isUploadCompanyListOpen =
    companyColumnSelectionModalOpen === CompanySelectionTypes.UPLOAD_COMPANIES;
  const isCompanyMappingOpen =
    companyColumnSelectionModalOpen === CompanySelectionTypes.COMPANY_MAPPING;

  if (isUploadCompanyListOpen) {
    return (
      <Modal
        id={id}
        isOpen={isUploadCompanyListOpen || isCompanyMappingOpen}
        onClose={onClose}
        size="5xl"
        isCentered
        closeOnOverlayClick={!isCompanyMappingOpen}
      >
        <ModalOverlay />
        <CompanyMappingModalContent
          returnToPrevious={returnToPrevious}
          setCompanyColumnSelectionModalOpen={
            setCompanyColumnSelectionModalOpen
          }
        />
      </Modal>
    );
  }

  if (isCompanyMappingOpen) {
    return (
      <Modal
        id={`${id}-mapping`}
        isOpen={isUploadCompanyListOpen || isCompanyMappingOpen}
        onClose={onClose}
        size="7xl"
        isCentered
        closeOnOverlayClick={!isCompanyMappingOpen}
      >
        <ModalOverlay />
        <VerifyCompanyMapping
          setCompanyColumnSelectionModalOpen={
            setCompanyColumnSelectionModalOpen
          }
          onClose={onClose}
          shouldAutoSubmit={shouldAutoSubmit}
          setShouldAutoSubmit={setShouldAutoSubmit} // Pass the setter as well to reset it after submit
          submitIsLoading={submitIsLoading}
          setSubmitIsLoading={setSubmitIsLoading}
        />
      </Modal>
    );
  }

  return null;
};

export default CompanyMapping;

import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import { find, isEmpty } from 'lodash';
import objectHash from 'object-hash';
import React, { useEffect, useState } from 'react';
import { OperatorFunction, catchError, distinctUntilChanged, tap } from 'rxjs';
import { useMutation } from 'urql';

import { useGetLoggedInUser, useUserTrialDetails } from '@revelio/auth';
import {
  getIsTourComplete,
  pickKeys,
  setIsTour,
  setIsTourComplete,
  setIsVideoOpen,
  tourStore,
} from '@revelio/core';
import { KeyValueResp, MetadataKey, User } from '@revelio/data-access';

import { UpdateUserMutation } from '../adminRewrite/userOperations';
import tutorialThumbnail from './tutorial-thumbnail.jpg';

const Welcome = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isTourCompleteState, setIsTourCompleteState] = useState(false);

  const {
    loggedInUser,
    query: [{ fetching }],
    refetch: refetchUserData,
  } = useGetLoggedInUser();

  const [{ fetching: updatingUserMetaData }, updateUser] =
    useMutation(UpdateUserMutation);

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  useEffect$(() =>
    tourStore.pipe(
      pickKeys(['isWelcomeOpen', 'isTourComplete']) as OperatorFunction<
        typeof tourStore.state,
        Pick<typeof tourStore.state, 'isWelcomeOpen' | 'isTourComplete'>
      >,
      distinctUntilChanged((pre, cur) => objectHash(pre) == objectHash(cur)),
      tap((data) => {
        if (data.isTourComplete) {
          onClose();
        }
        setIsTourCompleteState(data.isTourComplete);
      }),
      catchError((e) => {
        return e;
      })
    )
  );

  const handleUpdateMetadata = (
    loggedInUser: User = {},
    newMeta: KeyValueResp[]
  ) => {
    const metaFieldsToExclude = [MetadataKey.ExpirationDate];

    return new Promise((resolve, reject) => {
      if (isEmpty(loggedInUser)) {
        reject('No logged in user provided');
      }

      const newMetaKeys = newMeta.map((item) => item.key);

      const currentMetaObjects = (loggedInUser.metadata || []).filter(
        (item: KeyValueResp | null) =>
          !newMetaKeys.includes(item?.key) &&
          !metaFieldsToExclude.includes(item?.key as MetadataKey)
      );

      const updatedMeta = [...currentMetaObjects, ...newMeta];

      updateUser({
        id: loggedInUser?.id as string,
        metadata: updatedMeta,
      })
        .then((res) => {
          // update user object on update success
          refetchUserData();
          resolve('User updated');
        })
        .catch((err) => {
          reject('Something went wrong');
        });
    });
  };

  useEffect(() => {
    const isLocalTourComplete = tourStore.query(getIsTourComplete);

    const isEmptyUser = isEmpty(loggedInUser);

    const shouldNotStartTour =
      fetching || isEmptyUser || updatingUserMetaData || isLocalTourComplete;

    if (shouldNotStartTour) {
      return;
    }

    const metadata = loggedInUser.metadata || [];
    const tourCompleted = find(metadata, {
      key: 'tour_completed',
    }) as KeyValueResp;
    const isTourCompleted = tourCompleted?.value === 'true';

    if (!isTourCompleted) {
      onOpen();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetching,
    loggedInUser,
    updatingUserMetaData,
    isTourCompleteState,
    isTrialUser,
  ]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => null}
      isCentered
    >
      <ModalOverlay bgColor="rgba(0,0,0,0.7)" />
      <ModalContent>
        <ModalBody pb={6}>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text fontSize={26} fontWeight="semibold">
              Welcome to Revelio Labs
            </Text>
            <Text fontSize={18} opacity={0.7}>
              Let's get started on a tour of the dashboard
            </Text>
            <Button
              variant="link"
              _focus={{ boxShadow: 'none' }}
              cursor="pointer"
              _hover={{
                opacity: 0.8,
              }}
              onClick={() => {
                onClose();
                tourStore.update(setIsVideoOpen(true));
                tourStore.update(setIsTourComplete(true));
                handleUpdateMetadata(loggedInUser, [
                  {
                    key: MetadataKey.TourCompleted,
                    value: 'true',
                  },
                ]);
              }}
            >
              <Image
                src={tutorialThumbnail}
                alt="tutorial-thumbnail"
                mt={4}
                rounded="2xl"
              />
            </Button>
            <Button
              variant="link"
              size="sm"
              colorScheme="gray"
              mt={2}
              fontWeight="normal"
              onClick={() => {
                onClose();
                tourStore.update(setIsTour(true));
              }}
              _focus={{ boxShadow: 'none' }}
            >
              No thanks, I’ll read the quick tips
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Welcome;

import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { ReactNode, RefObject } from 'react';
import {
  FiDownload,
  FiImage,
  FiLink,
  FiMaximize2,
  FiMoreVertical,
} from 'react-icons/fi';

import { useUserTrialDetails } from '@revelio/auth';
import { PlotLinkModal } from '@revelio/composed';
import { FeatureTrackingEvents } from '@revelio/core';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { ChartConfigForPlot } from '@revelio/filtering';
import { PlotData } from '@revelio/layout';

import { downloadData } from './download-plot-data';
import { DownloadPlotPNG } from './download-plot-png';
import styles from './plot-card.module.css';

export type ExportData = { [key: string]: string | number }[];

type MenuOptions =
  | 'expand'
  | 'generate-link'
  | 'download-png'
  | 'download-data';

export type LegacyChartProps = {
  type: D3ChartNames;
  props: ID3ChartProps;
  data: PlotData;
};

export type PlotCardActionMenuProps = {
  menu?: MenuOptions[];
  title: string;
  slim?: boolean;
  printRef: RefObject<HTMLDivElement>;
  legacyChartProps?: LegacyChartProps;
  plotShare?: {
    data: PlotData;
    chartConfig: ChartConfigForPlot;
  };
  expandModalContent?: ReactNode;
  exportData?: ExportData;
  onExport?: () => void;
};

export const PlotCardActionMenu = ({
  menu = [],
  title,
  slim,
  expandModalContent,
  legacyChartProps,
  plotShare,
  printRef,
  exportData,
  onExport,
}: PlotCardActionMenuProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openExpandModal,
    onClose: closeModal,
  } = useDisclosure();

  const {
    isOpen: isDownloadPNGModalOpen,
    onOpen: openDownloadPNGModal,
    onClose: closeDownloadPNGModal,
  } = useDisclosure();

  const {
    isOpen: isPlotLinkModalOpen,
    onOpen: openPlotLinkModal,
    onClose: closePlotLinkModal,
  } = useDisclosure();

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: true,
  });

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          {...(slim && { boxSize: '14px' })}
          marginLeft="4px"
          width="16px"
          minWidth="16px"
          borderRadius={'4px'}
          variant="ghost"
          colorScheme="gray"
          icon={<FiMoreVertical fontSize={slim ? '10px' : '14px'} />}
          data-testid="action-menu-button"
          className={styles.menuButton}
        />
        <MenuList
          className={styles.menuList}
          minWidth="10rem"
          data-testid="action-menu-list"
        >
          {menu.includes('expand') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiMaximize2 />}
              onClick={openExpandModal}
            >
              Expand Plot
            </MenuItem>
          )}
          {menu.includes('generate-link') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiLink />}
              onClick={openPlotLinkModal}
              isDisabled={isTrialUser}
            >
              Plot Link
            </MenuItem>
          )}
          {menu.includes('download-png') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiImage />}
              onClick={openDownloadPNGModal}
              isDisabled={isTrialUser}
            >
              Download PNG
            </MenuItem>
          )}
          {menu.includes('download-data') && (
            <MenuItem
              className={styles.menuItem}
              icon={<FiDownload />}
              onClick={() => {
                mixpanel.track(FeatureTrackingEvents.DATA_DOWNLOAD, {
                  plot_title: title,
                });
                onExport?.();
                exportData && downloadData(exportData, title);
              }}
              isDisabled={isTrialUser}
            >
              Download Data
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <Modal isOpen={isModalOpen} onClose={closeModal} size={'6xl'}>
        <ModalOverlay />
        <ModalContent height={'80%'}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{expandModalContent}</ModalBody>
        </ModalContent>
      </Modal>

      <DownloadPlotPNG
        isOpen={isDownloadPNGModalOpen}
        onClose={closeDownloadPNGModal}
        title={title}
        printRef={printRef}
      />

      {(legacyChartProps || plotShare) && (
        <PlotLinkModal
          plotLinkModalState={{
            isPlotLinkOpen: isPlotLinkModalOpen,
            onPlotLinkOpen: openPlotLinkModal,
            onPlotLinkClose: closePlotLinkModal,
          }}
          cardConfig={{ header: title }}
          typeAndProps={
            legacyChartProps
              ? {
                  chartType: legacyChartProps.type,
                  chartProps: legacyChartProps.props,
                }
              : plotShare?.chartConfig
          }
          data={legacyChartProps?.data ?? plotShare?.data}
        />
      )}
    </>
  );
};

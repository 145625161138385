import { HTMLChakraProps, chakra } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <path
        d="M6.5 12.1894C9.53757 12.1894 12 9.72699 12 6.68942C12 3.65186 9.53757 1.18942 6.5 1.18942C3.46243 1.18942 1 3.65186 1 6.68942C1 9.72699 3.46243 12.1894 6.5 12.1894Z"
        stroke="currentColor"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6.68942H12"
        stroke="currentColor"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.50005 1.18942C7.87575 2.69552 8.65756 4.65004 8.70005 6.68942C8.65756 8.7288 7.87575 10.6833 6.50005 12.1894C5.12434 10.6833 4.34253 8.7288 4.30005 6.68942C4.34253 4.65004 5.12434 2.69552 6.50005 1.18942Z"
        stroke="currentColor"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </chakra.svg>
  );
};

import { UseStepsReturn } from '@chakra-ui/react';
import { noop } from 'lodash';
import React, { createContext } from 'react';

import { BaseStepsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import { SnowflakeDbValues } from '../snowflake-db-inputs';

export type SnowflakeMapperContextType = SnowflakeDataInterface &
  UseStepsReturn & {
    steps: BaseStepsInterface[];
    setData: React.Dispatch<React.SetStateAction<SnowflakeDataInterface>>;
  };

export interface SnowflakeDataInterface {
  headers: string[];
  combinedData?: object[];
  mappedColumns?: { [arg0: string]: string }[] | null;
  formData?: SnowflakeDbValues;
  oryId?: string | null;
  clientName?: string | null;
  email?: string | null;
}

export const SnowflakeMapperContext = createContext<SnowflakeMapperContextType>(
  {
    steps: [],
    headers: [],
    combinedData: [{}],
    mappedColumns: [],
    formData: {
      database: '',
      schema: '',
      table: '',
    },
    setData: noop,

    // UseStepsReturn defaults that shouldn't ever be used
    activeStep: 0,
    setActiveStep: noop,
    activeStepPercent: 0,
    // made as any because these are instantly overwritten by `useSteps`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isActiveStep: noop as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isCompleteStep: noop as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isIncompleteStep: noop as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStatus: noop as any,
    goToNext: noop,
    goToPrevious: noop,
  }
);

import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { Textfit } from 'react-textfit';

import { PlotInfoComp } from '@revelio/composed';
import { useResizeObserver } from '@revelio/core';
import { GetEntityDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';

type EntitySummary = NonNullable<
  NonNullable<GetEntityDataQuery['entity']>['summary']
>;

interface RoleSummaryOverviewProps {
  description: EntitySummary['description'];
  roleTitle: EntitySummary['name'];
  commonTitles?: string;
}

export const RoleSummaryOverview = ({
  description,
  roleTitle,
  commonTitles,
}: RoleSummaryOverviewProps) => {
  const factSize = '85px';
  const { containerRef, width, height } = useResizeObserver();

  return (
    <Card p="12px 20px">
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Box height={`calc(100% - ${factSize})`} ref={containerRef}>
          <Flex mb={3}>
            <Text fontWeight={600} fontSize={14}>
              {roleTitle}
            </Text>
            <PlotInfoComp
              plotInfoBody={
                <Text color="white">
                  Descriptions are sourced from AI generated text.
                </Text>
              }
              plotInfoConfig={{
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              }}
            />
          </Flex>

          {width && height ? (
            <Textfit
              mode="multi"
              min={10}
              max={200}
              style={{
                height: '90%',
                lineHeight: '1.2',
                fontWeight: 300,
              }}
            >
              <Text>{description}</Text>
            </Textfit>
          ) : (
            <Text
              style={{
                height: '90%',
                lineHeight: '1.2',
                fontWeight: 300,
              }}
            >
              {description}
            </Text>
          )}
        </Box>

        <Box height={factSize}>
          <Divider my={4} />
          <Flex>
            <Text
              fontSize={14}
              title={commonTitles || ''}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, // Limits to 2 lines and adds ellipsis
              }}
            >
              <b>Common Titles:</b>{' '}
              <Text fontWeight={300} as="span">
                {commonTitles}
              </Text>
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

import { useMemo } from 'react';
import { useQuery } from 'urql';

import { PrimaryView } from '@revelio/core';
import { METRIC_MODE_IDS } from '@revelio/data-access';
import { PostingsDataQuery } from '@revelio/data-access';
import {
  FilterBase,
  FilterItem,
  FilterList,
  FilterName,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  POSTING_GET_DATA,
  RangeFilter,
  SelectFilter,
  SelectionCategories,
  doesFilterHaveState,
  getSkipShareOfPostingsScalingParam,
  useActiveColors,
  useActiveFiltersState,
  useRoleTaxonomySetting,
  useSelectFilterById,
  useSelectionLists,
} from '@revelio/filtering';

import { transformFiltersToVariables } from '../../../utils';
import {
  MetricKey,
  getLineChartData,
  getMainPostingsData,
  sanitiseMainPostingData,
} from '../utils';
import { getPostingsSalaryData, getPostingsTimeToFillData } from '../utils';
import {
  getProviderEndDateOverride,
  getProviderStartDateOverride,
} from './provider-date-overrides';
import { useIsQueryReady } from './use-is-query-ready';

type UsePostingsDataFetchProps = {
  view: PrimaryView;
  primaryFilters: FilterName[];
};

/** Keep this for Salary, Time to Fill, & Posting plot. Top Roles/Company plot in a separate hook */
export const usePostingsDataFetch = ({
  view,
  primaryFilters,
}: UsePostingsDataFetchProps) => {
  /** ======== Get Filter State ======== */
  const activeFilters = useActiveFiltersState();
  const providerFilter = useSelectFilterById(LocalSelectionCategories.PROVIDER);
  const metricModeFilter = useSelectFilterById(
    LocalSelectionCategories.METRIC_MODE
  );
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const { colorMap } = useActiveColors(primaryFilters as SelectionCategories[]);

  /** ======== Transform Filters ======== */
  const queryFilters = useMemo(
    () =>
      transformFiltersToVariables({
        view,
        filters: [
          ...activeFilters,
          ...(providerFilter ? [providerFilter] : []),
          ...(metricModeFilter ? [metricModeFilter] : []),
        ],
        isCustomRoleTaxonomyEnabled,
      }),
    [
      activeFilters,
      providerFilter,
      metricModeFilter,
      view,
      isCustomRoleTaxonomyEnabled,
    ]
  );

  /** ======== Fetch ======== */
  const isQueryReady = useIsQueryReady({ activeFilters, primaryFilters, view });
  const postingsDateFilter = activeFilters.find(
    (f) => f.id === SelectionCategories.DATE_RANGE_FULL
  );
  const hasUserSubmittedDateFilter =
    (postingsDateFilter as RangeFilter)?.isMaximumRange === false;
  const geoSelectionLists = useSelectionLists(GEOGRAPHY_GRANULARITY_FILTERS);
  const startDateOverride = getProviderStartDateOverride({
    queryFilters,
    geoSelectionLists,
  });
  const endDateOverride = getProviderEndDateOverride({ queryFilters });

  const overrideQueryFilters = {
    ...queryFilters,
    filters: {
      ...queryFilters.filters,
      ...(startDateOverride &&
        !hasUserSubmittedDateFilter && {
          start_date: startDateOverride,
        }),
      ...(endDateOverride &&
        !hasUserSubmittedDateFilter && {
          end_date: endDateOverride,
        }),
    },
  };

  const [{ data, fetching, error }] = useQuery({
    query: POSTING_GET_DATA,
    variables: {
      ...overrideQueryFilters,
      ...getSkipShareOfPostingsScalingParam(),
    },
    pause: !isQueryReady,
  });

  const loading = useMemo(() => {
    const hasFetchBeenCalled = fetching || !!data || !!error;
    return fetching || !hasFetchBeenCalled;
  }, [data, error, fetching]);

  const parsedData: PostingsDataQuery = useMemo(() => {
    const parsedPosting =
      data?.posting?.map((p) => sanitiseMainPostingData(p)) || [];

    const hasSufficientData = (parsedPosting?.[0]?.category?.length || 0) > 1;

    return {
      posting: hasSufficientData ? parsedPosting : data?.posting,
    };
  }, [data]);

  /** ======== Salary & Time to Fill ======== */
  const salaryData = getPostingsSalaryData(parsedData.posting, colorMap);
  const timeToFillData = getPostingsTimeToFillData(
    parsedData.posting,
    colorMap
  );

  /** ======== Main Postings ======== */
  /** Current filter selection of Main Posting chart when in multi primary dimension mode */
  const postingMetricFilterValue = useSelectFilterById(
    SelectionCategories.POSTING_METRIC
  );

  const metricModeFilterValue = useSelectFilterById(
    LocalSelectionCategories.METRIC_MODE
  )?.value;

  const isExpectedHiresPerPostingMode =
    doesFilterHaveState(SelectionCategories.METRIC_MODE) &&
    ((metricModeFilterValue as FilterBase)?.id as string) ===
      METRIC_MODE_IDS.expectedHiresPerPosting;

  const mainPostingsView =
    ((postingMetricFilterValue as SelectFilter<FilterItem>)?.value
      ?.value as MetricKey) ?? 'active';

  const activeFiltersList = activeFilters
    .filter((f) => primaryFilters.includes(f.id))
    .flatMap((f) => f.value as FilterList);

  const activeEntityLabels = activeFiltersList?.map((f) => f.label || '') || [];

  /** Transform Postings data for Main Postings chart */
  const mainPostingData =
    isExpectedHiresPerPostingMode || activeFiltersList.length > 1
      ? getLineChartData({
          entities: parsedData?.posting,
          metricKey: isExpectedHiresPerPostingMode
            ? 'expected_hires'
            : mainPostingsView,
          colorMap,
        })
      : getMainPostingsData({ entities: parsedData?.posting });

  return {
    salaryData,
    timeToFillData,
    mainPostingData,
    activeEntityLabels,
    loading,
    error,
  };
};

import React from 'react';
import {
  HStack,
  Text,
  Badge,
  IconButton,
  Button,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { RiLineChartLine } from 'react-icons/ri';

interface HealthCheckHeaderProps {
  service: string;
  viewGroup: string;
  view: string;
  status: string;
  showSchema: boolean;
  onToggleSchema: () => void;
  onChartOpen: () => void;
  onRunCheck: () => void;
  isFetching: boolean;
}

export const HealthCheckHeader: React.FC<HealthCheckHeaderProps> = ({
  service,
  viewGroup,
  view,
  status,
  showSchema,
  onToggleSchema,
  onChartOpen,
  onRunCheck,
  isFetching,
}) => {
  return (
    <HStack justify="space-between">
      <HStack spacing={4}>
        <HStack spacing={2}>
          <Text fontWeight="bold">{service}</Text>
          <Text color="gray.500">
            ({viewGroup}, {view})
          </Text>
        </HStack>
        <Badge colorScheme={status === 'success' ? 'green' : 'red'}>
          {status || 'unknown'}
        </Badge>
      </HStack>
      <HStack>
        <IconButton
          aria-label="View response time chart"
          icon={<Icon as={RiLineChartLine} />}
          size="sm"
          variant="ghost"
          onClick={onChartOpen}
        />
        <IconButton
          aria-label="Toggle schema"
          icon={showSchema ? <ChevronUpIcon /> : <ChevronDownIcon />}
          size="sm"
          variant="ghost"
          onClick={onToggleSchema}
        />
        <Button
          size="sm"
          colorScheme="blue"
          variant="outline"
          onClick={onRunCheck}
          isLoading={isFetching}
        >
          Try Query
        </Button>
      </HStack>
    </HStack>
  );
};

import { get } from 'lodash';

import {
  CompensationResponseLevelOverrides,
  getPlotColorByKey,
} from '@revelio/core';

import { colors } from '../../utilities/colors';

export const getAssignedColor = (val, index) => {
  const meta = get(val, 'metadata', {});

  const level = get(
    CompensationResponseLevelOverrides,
    meta?.level,
    meta?.level
  );

  const colorID = `${level}__${meta?.id}`;

  return getPlotColorByKey(colorID) || colors[index];
};

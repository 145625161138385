import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, Text } from '@chakra-ui/react';
import { compact } from 'lodash';

import {
  SelectionCategoryLabelOverrides,
  SelectionCategoryLabelPrefix,
} from '../nested-tree/node-submenu/node-submenu';

export interface TreeBreadcrumbHeaderProps {
  lists: string[];
}

export function TreeBreadcrumbHeader({
  lists,
  ...props
}: TreeBreadcrumbHeaderProps) {
  const mappedListsText = compact(
    lists.map(
      (l) =>
        `${SelectionCategoryLabelPrefix[l] || ''}${
          SelectionCategoryLabelOverrides[l] || ''
        }`
    )
  );
  return (
    <Breadcrumb spacing={0.5} separator={<ChevronRightIcon boxSize={3} />}>
      {mappedListsText.map((li: string, i: number) => (
        <BreadcrumbItem key={`${li}_${i}`}>
          <Text>{li}</Text>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default TreeBreadcrumbHeader;

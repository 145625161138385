import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useCallback, useEffect, useState } from 'react';

import { ActionModalControlPanel } from '@revelio/core';
import { PipelineType } from '@revelio/data-access';
import { CustomFilterRange, DateRangeValue } from '@revelio/filtering';

import { getPipelineTypeTitleById } from '../../../data-set.model';
import { Deliverable } from '../../../deliverables.model';
import { getPipelineDateFilters } from '../../../deliverables.repository';
import {
  DateRangeFilterType,
  getPipelineDateRangeFilterType,
} from './dataset-date-range-helpers';
import {
  CUSTOM_DATE_RANGE_SELECT_ID,
  DatasetDateRangeFilterOption,
  useDatasetDateRangeFilter,
  useUpdateDatasetDateRangeFilters,
} from './dataset-date-range-hooks';

export const DatasetDateRangeModal = ({
  isOpen,
  setIsOpen,
  entityId,
  pipelineType,
}: {
  isOpen: boolean | string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | string>>;
  entityId: Deliverable['id'];
  pipelineType: PipelineType;
}) => {
  const [selectedOption, setSelectedOption] = useState<
    DatasetDateRangeFilterOption | undefined
  >();
  const [customDateRange, setCustomDateRange] = useState<
    DateRangeValue | undefined
  >();
  const [customDateRangeError, setCustomDateRangeError] = useState('');
  const useMonthPicker =
    getPipelineDateRangeFilterType(pipelineType) ===
    DateRangeFilterType.Monthly;

  const { options, dateRangeFromOption, dateRangeToSelection } =
    useDatasetDateRangeFilter(pipelineType);
  const updateDateRangeFilter = useUpdateDatasetDateRangeFilters();

  const resetOrInitialize = useCallback(() => {
    const dateFilters = getPipelineDateFilters({ entityId: entityId });
    const { selectedOption, dateRangeValue } =
      dateRangeToSelection(dateFilters);
    setSelectedOption(selectedOption);
    setCustomDateRange(dateRangeValue);
  }, [entityId, dateRangeToSelection]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    resetOrInitialize();
  }, [isOpen, resetOrInitialize]);

  return (
    <Modal
      id="dataset-date-range-modal"
      isOpen={!!isOpen}
      onClose={() => setIsOpen(false)}
      closeOnOverlayClick={true}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <DatasetDateRangeModalHeading pipelineType={pipelineType} />

        <ModalBody>
          <VStack>
            <Select
              placeholder="Max (default)"
              isMulti={false}
              name="pipelines-date-range"
              options={options}
              value={selectedOption ?? null}
              isClearable={true}
              onChange={(v) =>
                v ? setSelectedOption(v) : setSelectedOption(undefined)
              }
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
              }}
            />
            {selectedOption?.value === CUSTOM_DATE_RANGE_SELECT_ID && (
              <CustomFilterRange
                setDateRangeValue={setCustomDateRange}
                dateRangeValue={customDateRange}
                error={customDateRangeError}
                setError={setCustomDateRangeError}
                showMonthYearPicker={useMonthPicker}
                dateFormat={useMonthPicker ? 'yyyy-MM' : 'yyyy-MM-dd'}
                maxEndDate={new Date()}
              />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ActionModalControlPanel
            submitText={'Submit'}
            onClose={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            onSubmit={() => {
              const dateFilters = dateRangeFromOption({
                selectedOption: selectedOption,
                dateRangeValue: customDateRange,
              });
              updateDateRangeFilter(entityId, dateFilters);
              setIsOpen(false);
            }}
            submitIsDisabled={
              selectedOption?.value === 'custom' && !!customDateRangeError
            }
            onReset={resetOrInitialize}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DatasetDateRangeModalHeading = ({
  pipelineType,
}: {
  pipelineType: PipelineType;
}) => {
  const toolTipText = (() => {
    if (
      pipelineType === PipelineType.Posting ||
      pipelineType === PipelineType.PostingsIndividual
    ) {
      return 'Filter to postings that were active during a particular time range.';
    }
    return undefined;
  })();

  return (
    <ModalHeader
      borderBottom="1px solid #E5EBF1"
      fontSize="17px"
      fontWeight="600"
      py="12px"
    >
      <Flex gap={1} alignItems={'center'}>
        <span>
          Select Date Range
          {pipelineType && ` for ${getPipelineTypeTitleById(pipelineType)}`}
        </span>
        {toolTipText && (
          <Tooltip
            label="Filter to postings that were active during a particular time range."
            textAlign="center"
            hasArrow
            placement="top"
          >
            <InfoOutlineIcon
              boxSize={4}
              color="text.primary"
              cursor="pointer"
              mb={'2.5px'}
            />
          </Tooltip>
        )}
      </Flex>
      <ModalCloseButton />
    </ModalHeader>
  );
};

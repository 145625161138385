export enum FormatType {
  PERCENTAGE = '%',
  CURRENCY = '$',
  CURRENCY_INTEGER = '$.0f',
  INTEGER = ',',
  DECIMAL = '.',
  SCIENTIFIC = 'e',
  SI = 's',
  YEAR = 'yr',
}

export type Format = FormatType;

import { graphql } from '../../gql';

export const GET_ENTITY_DATA = graphql(`
  query GetEntityData($filters: EntityFilters) {
    entity(filters: $filters) {
      summary {
        name
        description
        founded
        headquarters
      }
      competitors {
        metadata {
          id
          shortName
          longName
        }
        closeness_score
      }
    }
  }
`);

import { useMemo } from 'react';
import { CombinedError, useQuery } from 'urql';

import { PrimaryView, TimeFrameView } from '@revelio/core';
import { CompositionDataQuery } from '@revelio/data-access';
import {
  COMPOSITION_GET_DATA,
  FilterItem,
  FilterName,
  LocalSelectionCategories,
  SelectionCategories,
  useActiveColors,
  useActiveFiltersState,
  useAllFiltersState,
  useRoleTaxonomySetting,
  useSelectionListsData,
} from '@revelio/filtering';

import { useIsSlowLoad } from '../../../../../reveliolabs-dashboard/src/hooks/open-replay';
import {
  BottomConfigMapperBase,
  ConfigMapperData,
  getBottomPlotMappers,
  transformFiltersToOvertimeVariables,
  transformFiltersToSnapshotVariables,
} from '../utils';
import { BottomPlotData, useGetChartData } from './use-get-chart-data';
import { useGlobalLoadController } from './use-global-load-controller';
import { useIsQueryReady } from './use-is-query-ready';

const selectionListsIds = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K500,
  SelectionCategories.INDUSTRY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.RICS_K400,
];

type DataFetch<TBottom> = {
  bottomPlots: ConfigMapperData<TBottom, BottomPlotData>[];
  lineColors: string[];
  data: CompositionDataQuery | undefined;
  loading: boolean;
  error: CombinedError | undefined;
  isQueryReady: boolean;
};
export const useCompositionDataFetch = <
  TBottom extends BottomConfigMapperBase,
>({
  view,
  timeframe,
  primaryFilters,
  bottomConfigMapper,
}: {
  view: PrimaryView;
  timeframe: TimeFrameView;
  primaryFilters: FilterName[];
  bottomConfigMapper: TBottom[];
}): DataFetch<TBottom> => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;

  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  /** Get all filters relevant for query */
  const filters = useMemo(() => {
    const snapshotDateFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
    );
    const dateRangeFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.DATE_RANGE
    );
    const dateFilter = isSnapshot ? snapshotDateFilter : dateRangeFilter;

    return [...activeFilters, ...(dateFilter ? [dateFilter] : [])];
  }, [activeFilters, allFilters, isSnapshot]);

  /** Convert filters into gql variables */
  const selectionListsData = useSelectionListsData(selectionListsIds);
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  const queryFilters = useMemo(() => {
    if (isSnapshot) {
      return transformFiltersToSnapshotVariables({
        view,
        filters,
        selectionListsData,
        isCustomRoleTaxonomyEnabled,
      });
    }
    return transformFiltersToOvertimeVariables({
      view,
      filters,
      selectionListsData,
      isCustomRoleTaxonomyEnabled,
    });
  }, [
    view,
    filters,
    isSnapshot,
    selectionListsData,
    isCustomRoleTaxonomyEnabled,
  ]);

  /** Fetch data */
  const isQueryReady = useIsQueryReady({
    filters,
    primaryFilters,
    timeframe,
    view,
  });
  const [{ data, fetching, error, ...restProps }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: queryFilters,
    pause: !isQueryReady,
  });

  const fetchOptions = restProps?.operation?.context?.fetchOptions as {
    headers: { ['x-request-id']: string | undefined };
  };

  useIsSlowLoad(
    'COMPOSITION_GET_DATA',
    fetching,
    fetchOptions?.headers?.['x-request-id']
  );

  /** Handle fetch and chart load state */
  const hasFetchBeenCalled = fetching || !!data || !!error;
  const loading = fetching || !hasFetchBeenCalled;
  useGlobalLoadController(loading);

  const sortedData = useMemo(() => {
    const primaryEntities = allFilters.find(
      (filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
    )?.value as FilterItem[];

    return {
      ...data,
      composition: data?.composition
        ? [...data.composition].sort((a, b) => {
            const indexA = primaryEntities.findIndex(
              (primaryEntity) =>
                primaryEntity.id === a?.metadata?.id?.toString()
            );
            const indexB = primaryEntities.findIndex(
              (primaryEntity) =>
                primaryEntity.id === b?.metadata?.id?.toString()
            );
            return indexA - indexB;
          })
        : undefined,
    };
  }, [data, allFilters]);

  /** Convert gql response to chart data */
  const chartData = useGetChartData({
    data: sortedData,
    timeframe,
    filters: allFilters,
  });

  const { colorMap } = useActiveColors(primaryFilters as SelectionCategories[]);
  const lineColors =
    sortedData.composition?.map((entity) => {
      return colorMap[entity?.metadata?.shortName ?? ''];
    }) ?? [];

  return {
    bottomPlots: getBottomPlotMappers({
      configMapper: bottomConfigMapper,
      chartData,
    }),
    lineColors,
    data: sortedData,
    loading,
    error,
    isQueryReady,
  };
};

import { Flex } from '@chakra-ui/react';
import { SingleValue } from 'react-select';

import {
  FilterItem,
  LocalSelectionCategories,
  upsertFiltersWithProvidedValue,
  useActiveFiltersState,
} from '@revelio/filtering';

import { StyledPlotSelect } from '../../../shared/components';

export type NumOfNodesOption = {
  value: number;
  label: string;
};

const numOfNodeOptions: NumOfNodesOption[] = [
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
];

export const SankeyPlotFooter = () => {
  const activeFilters = useActiveFiltersState();

  const numOfNodes = activeFilters.find(
    (filter) => filter.id === LocalSelectionCategories.N_ITEMS_SANKEY
  )?.value as FilterItem | undefined;

  const numOfNodesValue: NumOfNodesOption | null =
    numOfNodes && typeof numOfNodes.id === 'number'
      ? { value: numOfNodes?.id, label: `${numOfNodes?.id}` }
      : null;

  const onChange = (value: SingleValue<NumOfNodesOption>) => {
    if (value) {
      upsertFiltersWithProvidedValue({
        [LocalSelectionCategories.N_ITEMS_SANKEY]: value.value,
      });
    }
  };

  return (
    <Flex width="100%" justifyContent="end">
      <StyledPlotSelect
        value={numOfNodesValue}
        onChange={onChange}
        options={numOfNodeOptions}
        menuPlacement="top"
        getOptionLabel={(option) => `Show ${option.label}`}
      />
    </Flex>
  );
};

import { isNil } from 'lodash';

import { TalentDiscoveryUser } from '@revelio/data-access';
import { TalentDiscoveryDataQuery } from '@revelio/data-access';

export const getSalaryAdjustedTDUsersData = (
  data?: TalentDiscoveryDataQuery['talent_discovery_search_v1_5'],
  exchangeRate?: number | null
) => {
  if (isNil(exchangeRate) || exchangeRate === 1 || isNil(data?.users)) {
    return data;
  }

  const adjustedUserData = data.users.map((user) => {
    if (!user) return user;

    const adjustedUserProperties = {} as TalentDiscoveryUser;

    if (user.salary_total) {
      adjustedUserProperties.salary_total = user.salary_total * exchangeRate;
    }

    if (user.salary_base) {
      adjustedUserProperties.salary_base = user.salary_base * exchangeRate;
    }

    return {
      ...user,
      ...adjustedUserProperties,
    };
  });

  return { ...data, users: adjustedUserData };
};

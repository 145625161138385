import { FormatType } from '../types/format';
import { getFormatter } from './get-formatter';

// Generate an odd number of ticks between the domain min and max
export const getScaleTicks = ({
  domain,
  tickCount,
  format,
  currencyCode = 'USD',
}: {
  domain: number[];
  tickCount: number;
  format: FormatType;
  currencyCode: string;
}) => {
  const [min, max] = domain;

  if (min === undefined || max === undefined) return [];

  const range = max - min;
  const step = range / (tickCount - 1);

  const ticks = [
    min,
    ...Array.from({ length: tickCount - 2 }, (_, i) => min + step * (i + 1)),
    max,
  ];

  const formatValue = getFormatter(format, { values: ticks, currencyCode });

  return ticks.map((tick) => ({
    value: tick,
    label: formatValue(tick),
  }));
};

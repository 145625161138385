import { Box, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { AsyncCreatableSelect, MultiValue } from 'chakra-react-select';
import React, { useEffect, useState } from 'react';

import { useScreenerFilter } from '../screener-filter-provider';
import { SelectFooter } from '../select-footer';
import {
  ScreenerFilterOption,
  isFreeTextFilter,
  isFreeTextFilterState,
} from '../types';

interface FreeTextFilterProps {
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

interface KeywordOption {
  label: string;
  value: string;
}

export const FreeTextFilter = ({
  closeMenu,
  selectedFilter,
}: FreeTextFilterProps) => {
  const { state, dispatch } = useScreenerFilter();

  const selectedFreeTextState = state.filters.company_detail_filters?.find(
    (filter) => filter.name === selectedFilter.value
  );

  const [keywords, setKeywords] = useState<KeywordOption[]>([]);

  useEffect(() => {
    if (isFreeTextFilterState(selectedFreeTextState)) {
      const existingKeywords = selectedFreeTextState.text;
      setKeywords(
        existingKeywords.map((keyword: string) => ({
          label: keyword,
          value: keyword,
        }))
      );
    } else {
      setKeywords([]);
    }
  }, [selectedFreeTextState]);

  const handleChange = (selectedOptions: MultiValue<KeywordOption>) => {
    setKeywords(selectedOptions as KeywordOption[]);
  };

  const handleClearSelections = () => {
    setKeywords([]);
  };

  const isAddDisabled = keywords.length === 0;

  const handleAddFilter = () => {
    const keywordValues = keywords.map((keyword) => keyword.value);
    if (isFreeTextFilter(selectedFilter.value)) {
      dispatch({
        type: 'ADD_FREE_TEXT_FILTER',
        name: selectedFilter.value,
        text: keywordValues,
      });
    }
    closeMenu();
  };

  // Handle pasting comma-separated values
  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const pastedValues = paste.split(',').map((val) => val.trim());
    const newOptions = pastedValues.map((value) => ({
      value,
      label: value,
    }));
    setKeywords((prevKeywords) => [...prevKeywords, ...newOptions]);
  };

  return (
    <>
      <PopoverBody padding={0}>
        <Box onClick={(e) => e.stopPropagation()}>
          <Box onPaste={handlePaste}>
            <AsyncCreatableSelect
              isMulti
              value={keywords}
              onChange={handleChange}
              placeholder="e.g. linux, hardware, firewall, LAN..."
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
              noOptionsMessage={() => null}
              components={{
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              tagVariant="keywordChip"
              chakraStyles={{
                control: (provided, state) => ({
                  ...provided,
                  borderWidth: '1px',
                  borderColor: state.isFocused ? 'green.500' : 'gray.200',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: 'green.500',
                  },
                  minHeight: '32px',
                  height: '32px',
                  borderRadius: '0',
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: 'blue.50',
                  border: '1px solid',
                  borderColor: 'blue.200',
                  borderRadius: 'md',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: 'blue.700',
                }),
                multiValueRemove: (provided) => ({
                  ...provided,
                  color: 'blue.700',
                  '&:hover': {
                    backgroundColor: 'blue.100',
                    color: 'blue.800',
                  },
                }),
                input: (provided) => ({
                  ...provided,
                  margin: '0',
                  padding: '0',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: '0 8px',
                }),
              }}
            />
          </Box>
        </Box>
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClearSelections={handleClearSelections}
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={selectedFreeTextState ? 'Update' : 'Add'}
          isAddDisabled={isAddDisabled}
        />
      </PopoverFooter>
    </>
  );
};

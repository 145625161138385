import {
  Dimension1,
  Exact,
  InputMaybe,
  POSTING_SOURCE_IDS,
  PostingFilters,
} from '@revelio/data-access';
import { SelectionCategories, SelectionList } from '@revelio/filtering';

export const getProviderEndDateOverride = ({
  queryFilters,
}: {
  queryFilters: Exact<{
    filters?: InputMaybe<PostingFilters>;
    dim1?: InputMaybe<Dimension1>;
  }>;
}) => {
  const provider = queryFilters?.filters?.provider;
  if (provider?.length === 1 && provider[0] === POSTING_SOURCE_IDS.linkedIn) {
    return '2025-02-10';
  }

  return null;
};

export const getProviderStartDateOverride = ({
  queryFilters,
  geoSelectionLists,
}: {
  queryFilters: Exact<{
    filters?: InputMaybe<PostingFilters>;
    dim1?: InputMaybe<Dimension1>;
  }>;
  geoSelectionLists: SelectionList[];
}) => {
  const provider = queryFilters?.filters?.provider;
  if (!provider?.length) return undefined;

  const getIndeedDate = () => {
    const dim1 = queryFilters?.dim1;
    if (!['geography', 'role'].includes(dim1 || '')) {
      return null;
    }

    const countriesWithEarlyStartIds = ['1', '4', '39']; // UK, US, HK
    const hasEarlyStartGeoFilter = Object.entries(
      queryFilters?.filters || {}
    ).some(([key, value]) => {
      const values = value as string[];

      if (key === SelectionCategories.COUNTRY) {
        return values.some((v) => countriesWithEarlyStartIds.includes(v));
      }

      if (key === SelectionCategories.MSA) {
        return values.some((v) => {
          const metroArea = geoSelectionLists
            .find((list) => list.id === 'metro_area')
            ?.value.find((m) => m.id === v) as
            | { country: string[] }
            | undefined;

          return (
            metroArea?.country[0] &&
            countriesWithEarlyStartIds.includes(metroArea.country[0])
          );
        });
      }

      return false;
    });

    return hasEarlyStartGeoFilter ? '2016-01-01' : '2023-10-01';
  };

  const dates = provider.reduce<string[]>((acc, prov) => {
    const date = (() => {
      if (prov === POSTING_SOURCE_IDS.unified) {
        return '2020-01-01';
      }

      if (prov === POSTING_SOURCE_IDS.linkedIn) {
        return '2021-08-30';
      }

      if (
        prov === POSTING_SOURCE_IDS.otherWebsitePostings ||
        prov === POSTING_SOURCE_IDS.revelioWebsitePostings
      ) {
        return '2022-08-15';
      }

      if (prov === POSTING_SOURCE_IDS.indeed) {
        return getIndeedDate();
      }

      return null;
    })();

    return date ? [...acc, date] : acc;
  }, []);

  // If there are multiple dates, return the earliest one
  return dates.length > 0 ? dates.sort()[0] : undefined;
};

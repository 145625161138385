import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';

import { ToggleSelect } from '@revelio/filtering';

import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import {
  OperatorOption,
  RangeFilters,
  TalentDiscoveryFilterOption,
  isRangeFilterState,
} from '../types';

interface RangeFilterProps {
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
}

export const operatorOptions: OperatorOption[] = [
  { label: 'is between', value: 'between' },
  { label: 'is greater than', value: 'greater_than' },
  { label: 'is less than', value: 'less_than' },
];

export const RangeFilter = ({
  closeMenu,
  selectedFilter,
}: RangeFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();

  const [operator, setOperator] = useState<OperatorOption>(operatorOptions[0]);
  const [startValue, setStartValue] = useState<number | undefined>(0);
  const [endValue, setEndValue] = useState<number | undefined>(100000);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [startValueTouched, setStartValueTouched] = useState(false);
  const [endValueTouched, setEndValueTouched] = useState(false);

  const selectedState = state.filters?.find(
    (filter) => filter.name === selectedFilter.value
  );

  const isRangeFilter = selectedState && isRangeFilterState(selectedState);

  const [isFilterCurrent, setIsFilterCurrent] = useState(
    isRangeFilter ? selectedState?.isCurrent : undefined
  );

  const onChangeCurrent = (value: number) => {
    setIsFilterCurrent(!value);
  };

  const initialIsCurrent = isRangeFilter
    ? (selectedState?.isCurrent ?? true)
    : true;

  useEffect(() => {
    if (isRangeFilterState(selectedState)) {
      const { start_value, end_value } = selectedState;

      // Apply exchange rate to convert from USD to custom currency
      const adjustedStartValue =
        start_value && selectedFilter.exchangeRate
          ? start_value * selectedFilter.exchangeRate
          : start_value;
      const adjustedEndValue =
        end_value && selectedFilter.exchangeRate
          ? end_value * selectedFilter.exchangeRate
          : end_value;

      setStartValue(adjustedStartValue);
      setEndValue(adjustedEndValue);

      const operatorValue = (() => {
        if (start_value !== undefined && end_value !== undefined) {
          return 'between';
        }
        if (start_value !== undefined) {
          return 'greater_than';
        }
        return 'less_than';
      })();
      const selectedOperator =
        operatorOptions.find((opt) => opt.value === operatorValue) ||
        operatorOptions[0];
      setOperator(selectedOperator);
    }
  }, [selectedState, selectedFilter.exchangeRate]);

  const handleOperatorChange = (value: SingleValue<OperatorOption>) => {
    setOperator(value || operatorOptions[0]);
    setStartValue(undefined);
    setEndValue(undefined);
    setErrorMessages([]);
    setStartValueTouched(false);
    setEndValueTouched(false);
  };

  const handleStartValueChange = (values: NumberFormatValues) => {
    setStartValue(values.floatValue);
  };

  const handleEndValueChange = (values: NumberFormatValues) => {
    setEndValue(values.floatValue);
  };

  useEffect(() => {
    const errors: string[] = [];

    if (
      operator?.value === 'between' &&
      startValueTouched &&
      endValueTouched &&
      startValue !== undefined &&
      endValue !== undefined &&
      startValue > endValue
    ) {
      errors.push('Start value must be less than or equal to the end value.');
    }

    setErrorMessages(errors);
  }, [operator, startValue, endValue, startValueTouched, endValueTouched]);

  const isInvalid =
    errorMessages.length > 0 && (startValueTouched || endValueTouched);

  const isDisabled = (() => {
    if (operator?.value === 'greater_than' && startValue === undefined) {
      return true;
    }

    if (operator?.value === 'less_than' && endValue === undefined) {
      return true;
    }

    if (
      operator?.value === 'between' &&
      (startValue === undefined || endValue === undefined)
    ) {
      return true;
    }

    return isInvalid;
  })();

  const handleAddFilter = () => {
    const adjustedStartValue =
      startValue && selectedFilter.exchangeRate
        ? // convert to default USD
          startValue / selectedFilter.exchangeRate
        : startValue;

    const adjustedEndValue =
      endValue && selectedFilter.exchangeRate
        ? // convert to default USD
          endValue / selectedFilter.exchangeRate
        : endValue;

    if (!isInvalid) {
      dispatch({
        type: 'ADD_RANGE_FILTER',
        name: selectedFilter.value as RangeFilters,
        start_value: adjustedStartValue,
        end_value: adjustedEndValue,
        isCurrent: selectedFilter?.supportsCurrentOrPrevious
          ? (isFilterCurrent ?? true)
          : undefined,
        isCurrency: selectedFilter.isCurrency,
      });
      closeMenu();
    }
  };

  const handleClearSelections = () => {
    setStartValue(undefined);
    setEndValue(undefined);
    setOperator(operatorOptions[0]);
    setErrorMessages([]);
    setStartValueTouched(false);
    setEndValueTouched(false);
  };

  return (
    <>
      <Flex direction="column" gap="3" fontSize="14px" mb={2}>
        <Select
          options={operatorOptions}
          value={operator}
          onChange={handleOperatorChange}
          size="sm"
          selectedOptionColorScheme="green"
          chakraStyles={{
            control: (provider) => ({
              ...provider,
              height: '36px',
            }),
          }}
        />
        <FormControl isInvalid={isInvalid}>
          {operator?.value === 'between' ? (
            <Flex
              alignItems="center"
              gap="2.5"
              fontSize="14px"
              justifyContent="space-between"
            >
              <NumberInput
                value={startValue}
                prefix={selectedFilter.prefix}
                onValueChange={handleStartValueChange}
                onBlur={() => setStartValueTouched(true)}
                data-testid="range-num-input-start"
              />
              <Text>to</Text>
              <NumberInput
                value={endValue}
                prefix={selectedFilter.prefix}
                onValueChange={handleEndValueChange}
                onBlur={() => setEndValueTouched(true)}
                data-testid="range-num-input-end"
              />
            </Flex>
          ) : (
            <NumberInput
              key={`single-${operator.value}`}
              value={operator?.value === 'greater_than' ? startValue : endValue}
              prefix={selectedFilter.prefix}
              onValueChange={
                operator?.value === 'greater_than'
                  ? handleStartValueChange
                  : handleEndValueChange
              }
              onBlur={
                operator?.value === 'greater_than'
                  ? () => setStartValueTouched(true)
                  : () => setEndValueTouched(true)
              }
            />
          )}

          <FormErrorMessage>
            <VStack align="start">
              {errorMessages.map((msg, idx) => (
                <Text key={idx} color="red.600">
                  {msg}
                </Text>
              ))}
            </VStack>
          </FormErrorMessage>
        </FormControl>
        {selectedFilter.supportsCurrentOrPrevious && (
          <ToggleSelect
            filterName={selectedFilter.label}
            onChange={onChangeCurrent}
            initialIsCurrent={initialIsCurrent}
          />
        )}
      </Flex>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={closeMenu}
        onAdd={handleAddFilter}
        addLabel={selectedState ? 'Update' : 'Add'}
        isAddDisabled={isDisabled}
      />
    </>
  );
};

const NumberInput = ({
  ...restProps
}: NumberFormatPropsBase<typeof Input> & { onBlur: () => void }) => {
  return (
    <NumberFormat
      {...restProps}
      customInput={Input}
      thousandSeparator
      displayType="input"
      height="36px"
      fontSize="14px"
      padding="10px"
      borderRadius="2px"
      onBlur={restProps.onBlur}
    />
  );
};

import { useBreakpoint } from '@chakra-ui/media-query';
import { enableElfProdMode } from '@ngneat/elf';
import { Navigate, Route, Routes } from 'react-router-dom';

import { FeatureFlag, LOGIN, PagePaths } from '@revelio/core';

/*
 * Disabled Game and GameV2 to reduce bundle size.
 * Uncomment path exclusion in nx.json to include in build.
 */
// import Game from './game/Game';
// import GameV2 from './gameV2/GameV2';

import packageJson from '../../package.json';
import useSalesIQScript from '../hooks/zoho/useSalesIQScript';
import { environment } from '../environments/environment';
import useGoogleAnalytics from '../hooks/analytics/useGoogleAnalytics';
import '../styles/main.scss';
import { MobileUnsupportedPage } from './MobileUnsupportedPage';
// temporary routes for pages still being built
import { GeographySummary } from './entity-summary/geography-summary/geography-summary';
import { RoleSummary } from './entity-summary/role-summary/role-summary';
// Pages outside of routes
import Landing from './landing/Landing';
import Login from './login/login';
import './next.module.scss';
import { RequireAuth } from './routes/ProtectedRoute';
import { PrivateRoute } from './routes/private-route';
import { reducedRoutes } from './routes/reduced-routes';
import { navRouteItems } from './routes/routes';
import SideNav from './routes/side-nav/side-nav';
import { useUnleashFlag } from '../shared/unleash-flags/use-unleash-flag';
import { useAuthStore } from '@revelio/auth';

const version = packageJson.version;

export function Next() {
  useGoogleAnalytics();
  const { ory, user } = useAuthStore();
  const zohoChatFeatureFlag = useUnleashFlag(FeatureFlag.ZohoChat);
  // TODO: relocate?
  useSalesIQScript(
    environment.ZOHO_SALES_IQ_WIDGET_CODE,
    zohoChatFeatureFlag,
    ory?.name,
    ory?.username,
    user
  );

  if (environment.production) {
    enableElfProdMode();
  }

  const breakpoint = useBreakpoint({ ssr: false });
  if (
    environment.production &&
    (breakpoint === 'sm' || breakpoint === 'base')
  ) {
    return <MobileUnsupportedPage />;
  }

  return (
    <Routes>
      <Route path={`/${LOGIN}`} element={<Login />} />
      <Route
        path={'/job-queue'}
        loader={() => {
          return new Promise((resolve) => {
            window.location.replace('https://pipelines.reveliolabs.com');
            resolve({});
          });
        }}
      />
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<SideNav items={navRouteItems} version={version} />}>
          <Route index element={<Landing />} />
          {reducedRoutes}

          <Route
            path={'/' + PagePaths.GEOGRAPHY_SUMMARY}
            element={
              <RequireAuth adminRequired>
                <GeographySummary />
              </RequireAuth>
            }
          />
          <Route
            path={'/' + PagePaths.ROLE_SUMMARY}
            element={
              <RequireAuth adminRequired>
                <RoleSummary />
              </RequireAuth>
            }
          />
        </Route>
        {/* <Route
          path={`/game`}
          element={
            <RequireAuth adminRequired>
              <Game />
            </RequireAuth>
          }
        />
        <Route
          path={`/gameV2`}
          element={
            <RequireAuth adminRequired>
              <GameV2 />
            </RequireAuth>
          }
        /> */}
      </Route>

      <Route path="*" element={<Navigate to={`/${LOGIN}`} />} />
    </Routes>
  );
}

export default Next;

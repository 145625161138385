import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useMutation } from 'urql';

import { KeyValueResp, MetadataKey, User } from '@revelio/data-access';

import { UpdateUserMutation } from '../../next/adminRewrite/userOperations';

export const useUserMetadata = () => {
  const [{ fetching }, updateUser] = useMutation(UpdateUserMutation);

  const updateMetadata = useCallback(
    async (
      loggedInUser: User,
      newMeta: KeyValueResp[],
      excludeFields: MetadataKey[] = [MetadataKey.ExpirationDate]
    ) => {
      try {
        if (isEmpty(loggedInUser)) {
          throw new Error('No logged in user provided');
        }

        const newMetaKeys = newMeta.map((item) => item.key);
        const currentMetaObjects = (loggedInUser.metadata || []).filter(
          (item: KeyValueResp | null) =>
            !newMetaKeys.includes(item?.key) &&
            !excludeFields.includes(item?.key as MetadataKey)
        );

        const updatedMeta = [...currentMetaObjects, ...newMeta];

        await updateUser({
          id: loggedInUser.id as string,
          metadata: updatedMeta,
        });

        return true;
      } catch (error) {
        return false;
      }
    },
    [updateUser]
  );

  return {
    updateMetadata,
    isUpdating: fetching,
  };
};
